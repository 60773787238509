import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import RoleSelect from "../../common/selects/specificSelects/RoleSelect";
import WizardModal from "../../common/modals/wizard-modal/WizardModal";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import AccessDataPasswordSelect from "./AccessDataPasswordSelect";
import { requestAddUser, requestRoles } from "../../../actions/actionUser";
import { Input, Switch, Form } from "antd";
import FormBuilder from "../../../managers/formBuilder";
import { getIdsFromGroupValues } from "../../../managers/groupManager";
import { useWizardSteps } from "../../../hooks/useModalStatus";
import { getRoleId, roles } from "../../../models/Role";
import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../../managers/authManager";
import { packages } from "../../../models/Package";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { WIZARD_TYPES } from "../../common/modals/wizard-modal/WizardModal";
import { useValidateForm } from "../../../hooks/useValidateForm";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../../common/modals/modal.utils";
import { useCustomValidation } from "../../../hooks/useDatePickersValidation";
import { validateInputs } from "../../common/inputs/inputs.utils";
import { useTranslation } from "react-i18next";

const DEFAULT_INPUT_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  groupValue: undefined,
  costcenterId: null,
  roleId: "",
  canCalibrate: false,
  passwordMode: "0",
  password: "",
};

const UserAddModal = (props) => {
  const [form] = Form.useForm();
  const [user, setUser] = useState({});
  const { step, nextStep, prevStep } = useWizardSteps();
  const { handleInputChange, fields } = useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { customRule, isValidated, setIsValidated} = useCustomValidation();
  const { t } = useTranslation();

  const { onClose, tree, userRoles } = props;
  const driver = getRoleId(userRoles, roles.Driver);
  const driverId = driver ? driver.id : undefined;
  const { submittable } = useValidateForm(form);

  const {
    firstName,
    lastName,
    email,
    groupValue,
    costcenterId,
    canCalibrate,
    roleId,
    passwordMode,
    password,
  } = fields;

  const me = useSelector((state) => state.users.me);

  useEffect(() => {
    props.requestRoles();
  }, []);

  useEffect(() => {
    password.length < 5 && passwordMode === "1"
      ? setIsValidated(false)
      : setIsValidated(true);
  }, [password, passwordMode]);

  function handleNext(values) {
    if (step === 2) {
      const groupIds = getIdsFromGroupValues(tree, groupValue);
      props.requestAddUser({ ...user, ...values, groupIds });
      onClose();
      return;
    }

    setUser({ ...user, ...values });
    nextStep();
  }

  function handleBack() {
    prevStep();
  }

  const page1 = {
    title: t("user.addmodal.page1.title"),
    inputs: [
      new FormBuilder.FormInput(
        t("user.addmodal.page1.firstname"),
        (
          <Input
            value={firstName}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />
        )
      )
        .withValue(firstName)
        .withKey("firstName")
        .withValidationMessage(
          t("user.addmodal.page1.firstnameValidation")
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        t("user.addmodal.page1.lastname"),
        (
          <Input
            value={lastName}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />
        )
      )
        .withValue(lastName)
        .withKey("lastName")
        .withValidationMessage(
        t("user.addmodal.page1.lastnameValidation")
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        t("user.addmodal.page1.email"),
        (
          <Input
            value={email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        )
      )
        .withValue(email)
        .withKey("email")
        .withType("email")
        .withValidationMessage(
          t("user.addmodal.page1.emailValidation")
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        t("user.addmodal.page1.groups"),
        (
          <TreeSelect
            value={groupValue}
            onChange={(value) => handleInputChange("groupValue", value)}
          />
        )
      )
        .withValue(groupValue)
        .withKey("groupValue")
        .build(),
    ],
  };

  const page2 = {
    title: t("user.addmodal.page2.title"),
    inputs: [
      new FormBuilder.FormInput(
        t("user.addmodal.page2.role"),
        (
          <RoleSelect
            value={roleId}
            onChange={(value) => handleInputChange("roleId", value)}
          />
        )
      )
        .withValue(roleId)
        .withKey("roleId")
        .withValidationMessage(
          t("user.addmodal.page2.rolesValidation")
        )
        .isRequired()
        .build(),
    ],
  };
  if (driverId && roleId && driverId === roleId) {
    page2.inputs.push(
      new FormBuilder.FormInput(
        t("user.addmodal.page2.allowCalibrate"),
        (
          <Switch
            checked={canCalibrate}
            onChange={(value) => handleInputChange("canCalibrate", value)}
          />
        )
      )
        .withValue(canCalibrate)
        .withKey("canCalibrate")
        .build()
    );
  }

  const page3 = {
    title: t("user.addmodal.page3.title"),
    inputs: [
      new FormBuilder.FormInput(
        t("user.addmodal.page3.passwordchooser"),
        (
          <AccessDataPasswordSelect
            value={passwordMode}
            onChange={(value) => handleInputChange("passwordMode", value)}
          />
        )
      )
        .withKey("passwordMode")
        .withValue(passwordMode)
        .withValidationMessage(
          t("user.addmodal.page3.passwordchooserValidation")
        )
        .isRequired()
        .build(),
    ],
  };

  if (passwordMode === "1") {
    page3.inputs.push(
      new FormBuilder.FormInput(
        t("user.addmodal.page3.password"),
        (
          <Input.Password
            value={password}
            onChange={(e) => handleInputChange("password", e.target.value)}
            autoComplete="new-password"
          />
        )
      )
        .withValue(password)
        .withKey("password")
        .withCustomRule(customRule)
        .withValidationMessage(
          t("user.addmodal.page3.passwordValidation")
        )
        .isRequired()
        .build()
    );
  }

  if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]))
    page1.inputs.push(
      new FormBuilder.FormInput(
        t("user.addmodal.page1.costCenter"),
        (
          <CostCenterSelect
            value={costcenterId}
            onChange={(value) => handleInputChange("costcenterId", value)}
            showArchived={false}
          />
        )
      )
        .withValue(costcenterId)
        .withKey("costcenterId")
        .build()
    );

  const pages = [page1, page2, page3];

  return (
    <WizardModal
      onClose={onClose}
      canBack={() => true}
      onNext={handleNext}
      onBack={handleBack}
      submittable={submittable}
      formType={MODAL_TYPES.addUser}
      roleId={roleId}
      form={form}
      title={t("user.addmodal.title")}
      pages={pages}
      step={step}
      wizardType={WIZARD_TYPES.user.name}
      isValidated={isValidated}
      validateInputs={ !isValidated && validateInputs }
      customRuleMessage={t("user.page.inputValidation.passwordInputValidation")}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ requestAddUser, requestRoles }, dispatch);
}

function mapStateToProp(state) {
  return {
    user: state.users.current,
    tree: state.tree,
    error: state.error,
    userRoles: state.users.roles,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(UserAddModal);
