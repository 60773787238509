const vehicleProfile = {
    CompanyVehicle: 0,
    PrivateVehicle: 1,
    PoolVehicle: 2,
};

const vehicleProfileArray = [
    { id: vehicleProfile.CompanyVehicle, name: "Firmenfahrzeuge" },
    { id: vehicleProfile.PrivateVehicle, name: "Privatfahrzeuge" },
    { id: vehicleProfile.PoolVehicle, name: "Poolfahrzeuge" },
];

export { vehicleProfile, vehicleProfileArray };
