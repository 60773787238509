class Comparator {
    constructor(keys, compareFkt) {
        this.keys = keys;
        this.compareFkt = compareFkt;
    }

    isValid(comparisonObject) {
        if (!comparisonObject) return false;
        const comparisonValues = this.keys.map(key => key.split(".").reduce((o, i) => (o ? o[i] : o), comparisonObject));
        return this.compareFkt(...comparisonValues);
    }
}

export default Comparator;
