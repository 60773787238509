import React, {useEffect, useState} from "react";
import FormModal from "../../common/modals/FormModal";
import FormBuilder from "../../../managers/formBuilder";
import {Checkbox, Input} from "antd";
import {modalActions} from "../../common/modals/CustomModal";
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import ReportSelect from "../selects/ReportSelect";
import SendToPersonGroupSelect from "../selects/SendToPersonGroupSelect";
import TimeRepetitionSelect from "../selects/TimeRepetitionSelect";
import CustomDateTimePicker from "../../common/selects/datePickers/CustomDateTimePicker";
import {
    mapPersonGroupToSendToAllServerEnum, mapSendToAllServerEnumToPersonGroup,
    possiblePersonGroup,
} from "../../../models/PossiblePersonGroup";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import FinishSelect from "../selects/FinishSelect";
import {finishModes} from "../../../models/FinishModes";
import PastTimeRangeSelect from "../selects/PastTimeRangeSelect";
import GroupingSelect from "../../common/selects/specificSelects/GroupingSelect";
import ExportSelect from "../../common/selects/specificSelects/ExportSelect";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import Label from "../../common/views/Label";
import {grouping, groupingArray, mapGroupingToServerGrouping, mapServerGroupingToGrouping} from "../../../models/Grouping";
import {getReportInformationById, reports} from "../../../managers/reportManager";
import {
    dayOfWeek,
    mapPastTimeRangeToPeriodServerEnum,
    mapPeriodToPastTimeRange,
    pastTimeRange
} from "../../../models/TimeRange";
import {
    mapIntervalServerEnumToTimeRepetition,
    mapTimeRepetitionToIntervalServerEnum, timeRepetition
} from "../../../models/TimeRepetition";
import {useDispatch, useSelector} from "react-redux";
import {
    requestAddScheduledReport,
    requestScheduledReportById,
    requestUpdateScheduledReport
} from "../../../actions/actionScheduledReport";
import {formatISO} from "date-fns";
import {getGroupValuesFromIds, getIdsFromGroupValues} from "../../../managers/groupManager";
import HourlySelect from "../selects/HourlySelect";
import CustomDatePicker from "../../common/selects/datePickers/CustomDatePicker";
import WeekPicker from "../../common/selects/datePickers/WeekPicker";
import DayOfTheWeekSelect from "../selects/DayOfTheWeekSelect";

import {allowedRoles, isAuthorized, isViewer} from "../../../managers/authManager";
import {packages} from "../../../models/Package";
import {roles} from "../../../models/Role";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";

import {addDays, getDay, subDays} from "date-fns";
import { useTranslation } from "react-i18next";

const DEFAULT_INPUT_VALUES = {
    name: null,
    report: null,
    sendTyp: possiblePersonGroup.individual,
    sendTypValue: undefined, 
    sentToUsers: undefined, 
    doNotSendToUsers: undefined,
    repeat: null,
    dayOfTheWeek: null, 
    startedAtHour: 8,
    startedAt: formatISO(new Date()), 
    finishTyp: finishModes.never,
    finishedAt: null,
    finishOccurrences: null,
    configTimeRange: pastTimeRange.month,
    configTimeRangeDays: null,
    reportOnAll: false,
    configVehicles: undefined,
    configDrivers: undefined,
    configCostCenters: null,
    configExport: undefined,
    configGrouping: grouping.vehicle,
    groupValue: undefined, 
  };


const ReportScheduleModal = props => {
    const {id, onClose, reportId} = props;
    const dispatch = useDispatch();

    // const [currentSettings, setCurrentSettings] = useState({report: reportId});
    const { handleInputChange, fields, setFields } = useInputChangeHandler({...DEFAULT_INPUT_VALUES, report: reportId});
    const tree = useSelector(state => state.tree);
    const { t } = useTranslation();

    const {
        name, report, repeat, finishedAt, finishOccurrences, sendTypValue, sendTyp, sentToUsers, doNotSendToUsers, finishTyp, reportOnAll,
        configTimeRangeDays, configTimeRange, configExport, configVehicles, configDrivers, configCostCenters, dayOfTheWeek, startedAtHour,
        configGrouping, groupValue, startedAt,
    } = fields;

    const scheduleReport = useSelector(state => state.scheduledReports.current);
    const me = useSelector(state => state.users.me);

    const subGroupGeneral = "subGroupGeneral";
    const subGroupRepetition = "subGroupRepetition";
    const subGroupReport = "subGroupReport";

    const collapseTranslation =
        {
            subGroupGeneral: "Allgemein",
            subGroupRepetition: "Wiederholungen",
            subGroupReport: "Berichtskonfiguration"
        }

    useEffect(() => {
        if (id && !scheduleReport || id && scheduleReport && scheduleReport.id !== id) dispatch(requestScheduledReportById(id));
    }, [id]);

    useEffect(() => {
        if (id && scheduleReport && scheduleReport.id === id) {
            const name = scheduleReport.name;
            const report = scheduleReport.type;
            const finishedAt = scheduleReport.finishedAt;
            const repeat = mapIntervalServerEnumToTimeRepetition(scheduleReport.interval);
            const finishOccurrences = scheduleReport.repetitions;
            const sendTypValue = scheduleReport.sendToGroups && scheduleReport.sendToGroups.length > 0
                ? getGroupValuesFromIds(tree, scheduleReport.sendToGroups.map(g => g.id)) : scheduleReport.sentToUsers.map(u => u.id);
            const sentToUsers = scheduleReport.sentToUsers.map(u => u.id);
            const doNotSendToUsers = scheduleReport.doNotSendToUsers.map(u => u.id);

            const configTimeRangeDays = scheduleReport.lastDays;
            const configExport = scheduleReport.format;
            const configVehicles = scheduleReport.onVehicles.map(v => v.id);
            const configDrivers = scheduleReport.onUsers.map(u => u.id);
          //  const configCostCenters = scheduleReport.onCostCenters.map(c => c.id);

            //send typ
            const loadedReport = {
                name,
                report,
                finishedAt,
                repeat,
                finishOccurrences,
                sendTypValue,
                sentToUsers,
                doNotSendToUsers,
                configTimeRangeDays,
                configExport,
                configVehicles,
                configDrivers,
                //configCostCenters
            };

            let sendTypNew = mapSendToAllServerEnumToPersonGroup(scheduleReport.sendToAll);

            if (!sendTypNew) {
                if (scheduleReport.sendToGroups && scheduleReport.sendToGroups.length > 0) sendTypNew = possiblePersonGroup.groups;
                else sendTypNew = possiblePersonGroup.individual;
            }

            if (repeat === timeRepetition.week) {
                let dayOfStarted = getDay(new Date(scheduleReport.startedAt));
                if (dayOfStarted === 0) dayOfStarted = dayOfWeek.sunday; // Map to sunday
                setFields({...fields, sendTyp: sendTypNew, configGrouping: mapServerGroupingToGrouping(scheduleReport.groupBy),
                    configTimeRange: mapPeriodToPastTimeRange(scheduleReport.period), reportOnAll: scheduleReport.reportOnAll, startedAt: scheduleReport.startedAt, 
                   startedAtHour: new Date(scheduleReport.startedAt).getHours(), dayOfTheWeek: dayOfStarted, ...loadedReport});
            } else {
                setFields({...fields, sendTyp: sendTypNew, configGrouping: mapServerGroupingToGrouping(scheduleReport.groupBy),
                    configTimeRange: mapPeriodToPastTimeRange(scheduleReport.period), reportOnAll: scheduleReport.reportOnAll, startedAt: scheduleReport.startedAt, 
                   startedAtHour: new Date(scheduleReport.startedAt).getHours(), ...loadedReport});
            }

            
        }
    }, [scheduleReport]);

    const handleSave = () => {
        const name = fields.name;
        const type = getReportInformationById(fields.report).serverName;
        const sendToAll = mapPersonGroupToSendToAllServerEnum(fields.sendTyp);
        const sentToUsers = fields.sendTyp === possiblePersonGroup.individual ? fields.sendTypValue : fields.sentToUsers;
        const doNotSendToUsers = fields.doNotSendToUsers;

        const sendToGroups = fields.sendTyp === possiblePersonGroup.groups ? getIdsFromGroupValues(tree, fields.sendTypValue) : [];

        let newStartedAt = new Date(fields.startedAt);
        newStartedAt.setHours(fields.startedAtHour);
        newStartedAt.setMinutes(0);
        if (repeat === timeRepetition.week) {
            let difference = fields.dayOfTheWeek - getDay(newStartedAt)
            if (difference <= 0)
                newStartedAt = subDays(newStartedAt, Math.abs(difference));
            else
                newStartedAt = addDays(newStartedAt, difference);
        }

        const startedAt = newStartedAt;
        const interval = mapTimeRepetitionToIntervalServerEnum(fields.repeat);
        const finishedAt = fields.finishedAt;
        const repetitions = fields.finishOccurrences;

        const period = mapPastTimeRangeToPeriodServerEnum(fields.configTimeRange);
        const lastDays = fields.configTimeRangeDays;
        const groupBy = mapGroupingToServerGrouping(fields.configGrouping);
        const format = fields.configExport || "pdf";

        const showPrivateTripTimes = false; //not supported yet

        const onVehicles = fields.configVehicles;
        const onUsers = fields.configDrivers;
        const groupValue = fields.groupValue; 
        //const onCostCenters = values.configCostCenters;

        const newReport = {
            name,
            type,
            sendToAll,
            sentToUsers,
            doNotSendToUsers,
            sendToGroups,
            startedAt,
            interval,
            finishedAt,
            repetitions,
            period,
            lastDays,
            groupBy,
            format,
            reportOnAll,
            onVehicles,
            onUsers,
            //onCostCenters,
            groupValue,
            showPrivateTripTimes
        }

        if (id)
            dispatch(requestUpdateScheduledReport({...newReport, id: id}))
        else
            dispatch(requestAddScheduledReport(newReport));
        onClose(); // this will close(unmount) the modal even when the request has not yet finished. so maybe a better way is to handle the onClose in another useEffect when the report changes
    }


    let inputs = [
        new FormBuilder.FormInput(t("report.reportScheduleModal.name"), <Input value={name} onChange={(e) => handleInputChange("name", e.target.value)} />)
            .withValue(name)
            .withKey("name")
            .withValidationMessage(t("report.reportScheduleModal.nameValidation"))
            .isRequired()
            .withSubGroup(subGroupGeneral)
            .build(),

        new FormBuilder.FormInput(t("report.reportScheduleModal.report"), <ReportSelect value={report} onChange={(value) => handleInputChange("report", value)} />)
            .withValue(report)
            .withKey("report")
            .withValidationMessage(t("report.reportScheduleModal.reportValidation"))
            .isRequired()
            .withSubGroup(subGroupGeneral)
            .build(),

        new FormBuilder.FormInput(t("report.reportScheduleModal.receiver"), <SendToPersonGroupSelect value={sendTyp} onChange={(value) => handleInputChange("sendTyp", value)} />)
            .withValue(sendTyp)
            .withKey("sendTyp")
            .withValidationMessage(t("report.reportScheduleModal.receiverValidation"))
            .isRequired()
            .withSubGroup(subGroupGeneral)
            .build()
    ];

    //#region sendTyp individual
    if (sendTyp === possiblePersonGroup.individual) {
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.persons"), <UserSelect value={sendTypValue} onChange={(value) => handleInputChange("sendTypValue", value)} multiple/>)
            .withValue(sendTypValue)
            .withKey("sendTypValue")
            .withValidationMessage(t("report.reportScheduleModal.personValidation"))
            .isRequired()
            .withSubGroup(subGroupGeneral)
            .build());
    }
    //#endregion


    //#region sendTyp driver groups groupleader and admins
    if (sendTyp === possiblePersonGroup.admins) {
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.additionalPerson"), <UserSelect value={sentToUsers} onChange={(value) => handleInputChange("sentToUsers", value)} multiple/>)
            .withValue(sentToUsers)
            .withKey("sentToUsers")
            .withSubGroup(subGroupGeneral)
            .build());
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.excludePerson"), <UserSelect value={doNotSendToUsers} onChange={(value) => handleInputChange("doNotSendToUsers", value)} multiple/>)
            .withValue(doNotSendToUsers)
            .withKey("doNotSendToUsers")
            .withSubGroup(subGroupGeneral)
            .build());
    }
    //#endregion

    //#region repetition
    inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.repetition"),
        <TimeRepetitionSelect value={repeat} onChange={(value) => handleInputChange("repeat", value)}/>)
        .withValue(repeat)
        .withKey("repeat")
        .withValidationMessage(t("report.reportScheduleModal.repetitionValidation"))
        .isRequired()
        .withSubGroup(subGroupRepetition)
        .build())

    if (repeat === timeRepetition.day) {
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.startedAt"),
            <CustomDatePicker disablePast disableFuture={false} value={startedAt} onChange={(value) => handleInputChange("startedAt", value)}/>)
            .withValue(startedAt)
            .withKey("startedAt")
            .withValidationMessage(t("report.reportScheduleModal.startedAtValidation"))
            .isRequired()
            .withSubGroup(subGroupRepetition)
            .build())
    }
    if (repeat === timeRepetition.week) {
        inputs.push(new FormBuilder.FormInput("Wochentag", <DayOfTheWeekSelect value={dayOfTheWeek} onChange={(value) => handleInputChange("dayOfTheWeek", value)}/>)
            .withValue(dayOfTheWeek)
            .withKey("dayOfTheWeek")
            .isRequired()
            .withSubGroup(subGroupRepetition)
            .build());

        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.startedAt"),
            <WeekPicker disableFuture={false} onDateChange={(value) => handleInputChange("startedAt", value)} value={startedAt}/>)
            .withValue(startedAt)
            .withKey("startedAt")
            .withValidationMessage(t("report.reportScheduleModal.startedAtValidation"))
            .isRequired()
            .withSubGroup(subGroupRepetition)
            .build())
    }

    if (repeat === timeRepetition.month) {

        inputs.push(new FormBuilder.FormInput("Tag im Monat",
            <CustomDatePicker disablePast value={startedAt} onChange={(value) => handleInputChange("startedAt", value)}/>)
            .withValue(startedAt)
            .withKey("startedAt")
            .withValidationMessage(t("report.reportScheduleModal.startedAtValidation"))
            .isRequired()
            .withSubGroup(subGroupRepetition)
            .build())
    }

    if (repeat === timeRepetition.day || repeat === timeRepetition.week || repeat === timeRepetition.month) {
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.startedAtHour"),
            <HourlySelect onChange={(value) => handleInputChange("startedAtHour", value)} value={startedAtHour}/>)
            .withValue(startedAtHour)
            .withKey("startedAtHour")
            .withValidationMessage(t("report.reportScheduleModal.startedAtHourValidation"))
            .isRequired()
            .withSubGroup(subGroupRepetition)
            .build());


        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.finish"), <FinishSelect value={finishTyp} onChange={(value) => handleInputChange("finishTyp", value)}/>)
            .withValue(finishTyp)
            .withKey("finishTyp")
            .withSubGroup(subGroupRepetition)
            .build());
        if (finishTyp === finishModes.date)
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.finishDate"),
                <CustomDateTimePicker value={finishedAt || formatISO(new Date())} onChange={(value) => handleInputChange("finishedAt", value)} disableFuture/>)
                .withValue(finishedAt || formatISO(new Date()))
                .withKey("finishedAt")
                .withValidationMessage(t("report.reportScheduleModal.finishDateValidation"))
                .isRequired()
                .withSubGroup(subGroupRepetition)
                .build());
        if (finishTyp === finishModes.occurrences)
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.finishOccurrences"),
                <Input value={finishOccurrences} onChange={(e) => handleInputChange("finishOccurrences", e.target.value)} />)
                .withValue(finishOccurrences)
                .withKey("finishOccurrences")
                .withValidationMessage(t("report.reportScheduleModal.finishOccurrencesValidation"))
                .isRequired()
                .withSubGroup(subGroupRepetition)
                .build());

    }


    //#endregion

    //#region reportconfig
    if (report === reports.day || report === reports.dayGraphic || report === reports.month){
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.period"), <PastTimeRangeSelect value={configTimeRange} onChange={(value) => handleInputChange("configTimeRange", value)}/>)
            .withValue(configTimeRange)
            .withKey("configTimeRange")
            .withValidationMessage(t("report.reportScheduleModal.periodValidation"))
            .isRequired()
            .withSubGroup(subGroupReport)
            .build())

        if (configTimeRange === pastTimeRange.day)
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.lastDays"), <Input value={configTimeRangeDays} onChange={(e) => handleInputChange("configTimeRangeDays", e.target.value)}  number/>)
                .withValue(configTimeRangeDays)
                .withKey("configTimeRangeDays")
                .withValidationMessage(t("report.reportScheduleModal.lastDaysValidation"))
                .isRequired()
                .withSubGroup(subGroupReport)
                .build())

        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.sendOnAll"), <Checkbox checked={reportOnAll} onChange={(e) => handleInputChange("reportOnAll", e.target.checked)}/>)
            .withValue(reportOnAll)
            .withKey("reportOnAll")
            .withValuePropName("checked")
            .withSubGroup(subGroupReport)
            .build())

        if (!reportOnAll)
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.vehicles"), <VehicleSelect value={configVehicles} onChange={(value) => handleInputChange("configVehicles", value)} multiple/>)
                .withValue(configVehicles)
                .withKey("configVehicles")
                .withValidationMessage(t("report.reportScheduleModal.vehiclesValidation"))
                .isRequired()
                .withSubGroup(subGroupReport)
                .build())
    }
    if (report === reports.logBook) {
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.period"), <PastTimeRangeSelect value={configTimeRange} onChange={(value) => handleInputChange("configTimeRange", value)}/>)
            .withValue(configTimeRange)
            .withKey("configTimeRange")
            .withValidationMessage(t("report.reportScheduleModal.periodValidation"))
            .isRequired()
            .withSubGroup(subGroupReport)
            .build())

        if (configTimeRange === pastTimeRange.day)
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.lastDays"), <Input value={configTimeRangeDays} onChange={(e) => handleInputChange("configTimeRangeDays", e.target.value)} number/>)
                .withValue(configTimeRangeDays)
                .withKey("configTimeRangeDays")
                .withValidationMessage(t("report.reportScheduleModal.lastDaysValidation"))
                .isRequired()
                .withSubGroup(subGroupReport)
                .build())

        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.export"), <ExportSelect value={configExport} onChange={(value) => handleInputChange("configExport", value)} />)
            .withValue(configExport)
            .withKey("configExport")
            .withValidationMessage(t("report.reportScheduleModal.exportValidation"))
            .isRequired()
            .withSubGroup(subGroupReport)
            .build())
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.grouping"), <GroupingSelect onChange={(value) => handleInputChange("configGrouping", value)} groupArray={groupingArray} value={configGrouping}/>)
            .withValue(configGrouping)
            .withKey("configGrouping")
            .withValidationMessage(t("report.reportScheduleModal.groupingValidation"))
            .isRequired()
            .withSubGroup(subGroupReport)
            .build())

        if ((configGrouping == grouping.costCenter && isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter])) || configGrouping == grouping.driver || configGrouping == grouping.vehicle)
        inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.sendOnAll"), <Checkbox value={reportOnAll} onChange={(value) => handleInputChange("reportOnAll", value)}/>)
            .withValue(reportOnAll)
            .withKey("reportOnAll")
            .withValuePropName("checked")
            .withSubGroup(subGroupReport)
            .build())

        if (!reportOnAll && configGrouping === grouping.vehicle)
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.vehicles"), <VehicleSelect value={configVehicles} onChange={(value) => handleInputChange("configVehicles", value)} multiple/>)
                .withValue(configVehicles)
                .withKey("configVehicles")
                .withValidationMessage(t("report.reportScheduleModal.vehiclesValidation"))
                .isRequired()
                .withSubGroup(subGroupReport)
                .build())

        if (!reportOnAll && configGrouping === grouping.driver)
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.drivers"), <UserSelect value={configDrivers} onChange={(value) => handleInputChange("configDrivers", value)}  multiple/>)
                .withValue(configDrivers)
                .withKey("configDrivers")
                .withValidationMessage(t("report.reportScheduleModal.driversValidation"))
                .isRequired()
                .withSubGroup(subGroupReport)
                .build())
        if (!reportOnAll && configGrouping === grouping.costCenter && isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]))
            inputs.push(new FormBuilder.FormInput(t("report.reportScheduleModal.costCenters"), <CostCenterSelect value={configCostCenters} onChange={(value) => handleInputChange("configCostCenters", value)} multiple showArchived={false}/>)
                .withValue(configCostCenters)
                .withKey("configCostCenters")
                .withValidationMessage(t("report.reportScheduleModal.costCentersValidation"))
                .isRequired()
                .withSubGroup(subGroupReport)
                .build())
        if (configGrouping === grouping.costCenter && !isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]))
            inputs.push(
                new FormBuilder.FormInput("Kostenstellen", <Label/>)
                .withValue("Das Modul Kostenstellen ist nur in DRiVEBOX BASIC+ und DRiVEBOX PRO verfügbar!")
                .withSubGroup(subGroupReport)
                .build())

         if(configGrouping === grouping.groups) {
            inputs.push(
                new FormBuilder.FormInput(t("user.addmodal.page1.groups"), <TreeSelect value={groupValue} onChange={(value) => handleInputChange("groupValue", value)} />)
                .withValue(groupValue)
                .withKey("groupValue")
                .withSubGroup(subGroupReport)
                .build())
        }
    }
    //#endregion

    return <FormModal
        loading={false}
        action={id ? modalActions.update : modalActions.add}
        onClose={onClose}
        onSave={handleSave}
        title="Bericht planen"
        inputs={inputs}
        showSubGroups
        collapseTranslation={collapseTranslation}
    />

}

export default ReportScheduleModal;