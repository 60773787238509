import React, {useState} from "react";
import CustomModal from "../../common/modals/CustomModal";
import Grid from "@material-ui/core/Grid";

import PropTypes from "prop-types";
import {DateTimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import {useDispatch, useSelector} from "react-redux";
import {requestAddTimeRecordings} from "../../../actions/actionTimeRecording";
import {Typography} from "@material-ui/core";
import {getNameOfEvent, timeLogEventType} from "../../../models/TimeLogEventType";
import GenericTable from "../../common/table/GenericTable";
import TableTypes from "../../../models/TableTypes";
import {makeStyles} from "@material-ui/core/styles";
import {subHours, subMinutes} from "date-fns";

const TimeRecordingAddModal = props => {
    const {onClose, timeLogId, userId} = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const [startDate, setStartDate] = useState(subHours(new Date(), 1));
    const [finishDate, setFinishDate] = useState(new Date());

    const [event, setEvent] = useState();
    const timeLog = useSelector(state => state.timeRecording.current);

    const mappedData = [];
    if (timeLog && timeLog.length > 0)
        timeLog.forEach(t => {
            mappedData.push({
                id: t.id,
                eventKey: t.event,
                event: getNameOfEvent(t.event),
                occurredAt: t.occurredAt
            })
        })
    const columnInformations = [
        {title: "Bezeichnung", key: "event"},
        {title: "Zeit", key: "occurredAt", type: TableTypes.dateTime},
    ];

    const handleSave = data => {
        dispatch(requestAddTimeRecordings({
            userId: timeLogId ? undefined : userId,
            timeLogEvent: event,
            occurredAt: new Date(startDate).toISOString(),
            finishedAt: new Date(finishDate).toISOString(),
            agent: 'Web',
            timeLogId
        }));
        // onClose();
        window.location.reload(); //TODO refactor this
    }

    const startOfWork = mappedData.find(m => m.eventKey === timeLogEventType.startOfWork);
    const endOfWork = mappedData.find(m => m.eventKey === timeLogEventType.endOfWork);

    const maxDates = [new Date(finishDate)];
    const minDates = [new Date(startDate)];
    if (timeLogId && mappedData.length > 1) {
        maxDates.push(new Date(endOfWork.occurredAt));
        minDates.push(new Date(startOfWork.occurredAt));
    }

    const isValid = (!timeLogId && new Date(startDate) < new Date(finishDate)) ||
        (timeLogId &&
            new Date(startDate) < new Date(finishDate) &&
            new Date(startDate) < new Date(endOfWork.occurredAt) &&
            new Date(finishDate) > new Date(startOfWork.occurredAt) &&
            new Date(finishDate) < new Date(endOfWork.occurredAt));


    const content = [
        <div>
            <Grid container spacing={1}>
                {timeLogId && <Grid item xs={12}>
                    <Typography color={"secondary"} variant={"h6"}>
                        Neue Ruhepause anlegen
                    </Typography>
                </Grid>}

                {!timeLogId && <Grid item xs={12}>
                    <Typography color={"secondary"} variant={"h6"}>
                        Neuen Arbeitstag anlegen
                    </Typography>
                </Grid>}


                <Grid item xs={12}>
                    <Typography color={"secondary"} variant={"body1"}>
                        Von
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <DateTimePicker
                        autoOk
                        ampm={false}
                        variant="inline"
                        openTo="hours"
                        minDateTime={mappedData.length > 0 && timeLogId ? new Date(startOfWork.occurredAt) : undefined}
                        maxDateTime={subMinutes(maxDates.reduce(function (a, b) {
                            return a < b ? a : b;
                        }), 1)}
                        value={startDate}
                        renderInput={props => <TextField style={{width: "100%"}} variant={"outlined"} {...props} />}
                        onChange={setStartDate}/>
                </Grid>

                <Grid item xs={12}>
                    <Typography color={"secondary"} variant={"body1"}>
                        Bis
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <DateTimePicker
                        autoOk
                        ampm={false}
                        variant="inline"
                        openTo="hours"
                        minDateTime={minDates.reduce(function (a, b) {
                            return a > b ? a : b;
                        })}
                        maxDateTime={mappedData.length > 0 && timeLogId ? new Date(endOfWork.occurredAt) : undefined}
                        value={finishDate}
                        renderInput={props => <TextField style={{width: "100%"}} variant={"outlined"} {...props} />}
                        onChange={setFinishDate}/>
                </Grid>

                <Grid item xs={12}/>
                <Grid item xs={12}/>

                {timeLogId && <Grid item xs={12} className={classes.informationSection}>
                    <Typography color={"secondary"} variant={"body1"}>
                        Alte Einträge
                    </Typography>
                </Grid>}

                {timeLogId && <Grid item xs={12}>
                    <GenericTable
                        data={mappedData}
                        columnInformations={columnInformations}
                        disableContainer
                        hover={false}
                    />
                </Grid>}

            </Grid>
        </div>
    ]

    return <CustomModal action={startDate && finishDate && isValid ? "confirm" : "cancel"}
                        onSave={handleSave}
                        onClose={onClose}
                        title={"Lenkprotokoll"}
                        contents={content}/>
}

const useStyles = makeStyles(theme => ({
    informationSection: {
        marginTop: "20px",
    }
}));

// TimeRecordingAddModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     timeLogEventId: PropTypes.object.isRequired,
//     timeLogId: PropTypes.object.isRequired,
//     userId: PropTypes.object,
// };

export default TimeRecordingAddModal;