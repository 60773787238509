import React from "react";

import { InboxOutlined } from "@ant-design/icons";
import { Result } from "antd";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const EmptyResult = ({ onClose, onAdd, title }) => {

  const { t } = useTranslation();
  
  return (
    <Result
      icon={<InboxOutlined />}
      title={title || t("common.view.emptyResult.noEntriesFound") }
      extra={[
        onClose && (
          <Button variant="contained" onClick={onClose} color="primary">
            {t("common.view.emptyResult.close")}
          </Button>
        ),
        onAdd && (
          <Button variant="contained" onClick={onAdd} color="primary">
            {t("common.view.emptyResult.add")}
          </Button>
        ),
      ]}
    />
  );
};

export default EmptyResult;
