import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useModalStatus, useSnackbar } from "../../hooks/useModalStatus";
import { usePagination } from "../../hooks/usePagination";
import Page from "../common/container/Page";
import FilterToolbar from "../common/toolbar/FilterToolbar";
import Snackbar from "../common/views/Snackbar";
import { menu } from "../../managers/pathManager";
import TripTable from "./TripTable";
import TripFilterModal from "./tripsModals/TripFilterModal";
import TripEditModal from "./tripsModals/TripEditModal";
import TripBulkEditModal from "./tripsModals/TripBulkEditModal";
import LogBookExportModal from "../reporting/modals/LogBookExportModal";
import TripMergeModal from "./tripsModals/TripMergeModal";
import TripAddModal from "./tripsModals/TripAddModal";
import TrackModal from "./tripsModals/TrackModal";
import HistoryModal from "../common/modals/HistoryModal";

import { PAGE_NAME } from "../common/container/Page";

import {
  requestTripMileageReport,
  requestTripReport,
  requestTripUsersReport,
  requestTripVehiclesReport,
  requestTripIdleTimeReport,
} from "../../actions/actionReport";
import {
  deselectAllTrips,
  requestDeleteTrip,
  requestUpdateTrips,
  requestLockTrips,
  requestUnlockTrips,
  requestTrips,
  selectAllTrips,
  selectTrip,
  loadTrip,
  toggleTripSuccessMsgVisibility,
} from "../../actions/actionTrips";
import {
  requestAllVehicles,
  requestVehicleById,
  requestOpenTrack,
} from "../../actions/actionVehicle";
import { requestAllUsers } from "../../actions/actionUser";

import {
  clearFilter,
  setText,
  setTextArray,
  updateFilterAPICall,
} from "../../actions/actionFilter";
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
} from "@material-ui/core";
import DashboardTripDistribution from "../dashboard/DashboardTripDistribution";
import DashboardKmHistory from "../dashboard/DashboardKmHistory";
import TripSummaryReports from "./charts/TripSummaryReports";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  settingKeys,
  userKey,
  vehicleKey,
} from "../../managers/localStorageManager";
import TripPageToolbar from "./toolbars/TripPageToolbar";
import TripUserStatisticTable from "./TripUserStatisticTable";
import { periods } from "../../models/Period";
import { useHotkeys } from "react-hotkeys-hook";
import DriverModal from "./tripsModals/DriverModal";
import VehicleModal from "./tripsModals/VehicleModal";
import OpenTrackModal from "./tripsModals/OpenTrackModal";
import TripSettingModal from "./tripsModals/TripSettingModal";
import { orderBy } from "../../models/OrderBy";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import { allowedRoles, isAuthorized } from "../../managers/authManager";
import { roles } from "../../models/Role";
import { filterOptions, getRequestedFilterOption } from "../common/selects/specificSelects/TripsFilterSelect";
import LoadingIndicator from "../common/LoadingIndicator";
import TripDeleteModal from "./tripsModals/TripDeleteModal";
import TripLockModal from "./tripsModals/TripLockModal";
import { modalType } from "../common/modals/CustomModal";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const views = {
  table: 1,
  statistic: 2,
};

const useStyles = makeStyles((theme) => ({
  vehicleCardContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  vehicleCard: {
    marginRight: "4px",
  },
  vehicleList: {
    overflowY: "auto",
    maxHeight: "calc( " + window.innerHeight + "px - 270px)",
    height: "calc( " + window.innerHeight + "px - 270px)",
  },
  selectList: {
    borderTop: "1px solid #ccc",
  },
  contentContainer: {
    marginLeft: "1px",
  },
  loadingWrapper: {
    display: "flex",
    minHeight: "50vh",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const TripPage = (props) => {
  const location = useLocation();
  const { selectedOversonsumedVehicle, filterOverconsumeVehicle } = location.state || {};
  const classes = useStyles();
  const [trip, setTrip] = useState("");
  const [view, setView] = useState(views.table);
  const [userMode, setMode] = useState(false);
  const [filterOption, setFilterOption] = useState(filterOptions.All);
  const { t } = useTranslation();

  const { value: settings, set: setSettings } = useLocalStorage(
    settingKeys.tripPage,
    {
      orderBy: orderBy.desc,
      showArchived: false,
    }
  );

  const { value: savedUser, set: setSavedUser } = useLocalStorage(userKey);
  const { value: savedVehicle, set: setSavedVehicle } =
    useLocalStorage(vehicleKey);
  const [user, setUser] = useState(savedUser ? savedUser : "");
  const [vehicle, setVehicle] = useState(
    props.filter.api.tripbookVehicle
      ? props.filter.api.tripbookVehicle
      : savedVehicle
      ? savedVehicle
      : ""
  );

  const {
    open: exportSnackbar,
    show: showExportSnackbar,
    hide: hideExportSnackbar,
  } = useSnackbar();
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const {
    modal: editModal,
    open: openEditModal,
    close: closeEditModal,
  } = useModalStatus();
  const {
    modal: trackModal,
    open: openTrackModal,
    close: closeTrackModal,
  } = useModalStatus();
  const {
    modal: filterModal,
    open: openFilterModal,
    close: closeFilterModal,
  } = useModalStatus();
  const {
    modal: bulkEditModal,
    open: openBulkEditModal,
    close: closeBulkEditModal,
  } = useModalStatus();
  const {
    modal: exportModal,
    open: openExportModal,
    close: closeExportModal,
  } = useModalStatus();
  const {
    modal: driverModal,
    open: openDriverModal,
    close: closeDriverModal,
  } = useModalStatus();
  const {
    modal: vehicleModal,
    open: openVehicleModal,
    close: closeVehicleModal,
  } = useModalStatus();
  const {
    modal: openTrackModalStatus,
    open: openOpenTrackModal,
    close: closeOpenTrackModal,
  } = useModalStatus();
  const {
    modal: addModal,
    open: openAddModal,
    close: closeAddModal,
  } = useModalStatus();
  const {
    modal: mergeModal,
    open: openMergeModal,
    close: closeMergeModal,
  } = useModalStatus();
  const {
    modal: historyModal,
    open: openHistoryModal,
    close: closeHistoryModal,
  } = useModalStatus();
  const {
    modal: settingModal,
    open: openSettingModal,
    close: closeSettingModal,
  } = useModalStatus();

  const {
    modal: deleteTripModal,
    open: openDeleteTripModal,
    close: closeDeleteTripModal,
  } = useModalStatus();

  const {
    modal: lockTripModal,
    open: openLockTripModal,
    close: closeLockTripModal,
  } = useModalStatus();

  const {
    selectTrip,
    selectAllTrips,
    deselectAllTrips,
    filter,
    me,
    vehicles,
    users,
    isSuccessMsgVisible,
  } = props;
  const { api, updatedAt } = filter;

  useHotkeys(
    "ctrl+alt+m",
    () => {
      for (let i = vehicles.length - 2; i >= 0; i--) {
        if (vehicles[i].id === vehicle) {
          setVehicle(vehicles[i + 1].id);
          break;
        }
      }
    },
    [vehicles, vehicle]
  );

  useHotkeys(
    "ctrl+alt+n",
    () => {
      for (let i = 1; i < vehicles.length; i++) {
        if (vehicles[i].id === vehicle) {
          setVehicle(vehicles[i - 1].id);
          break;
        }
      }
    },
    [vehicles, vehicle]
  );

  //Save vehicle/user in localStorage
  useEffect(() => {
    if (vehicle) setSavedVehicle(vehicle);
  }, [vehicle]);

  useEffect(() => {
    if (user) setSavedUser(user);
  }, [user]);

  //if current vehicle/user is not loaded, select first vehicle of list
  useEffect(() => {
    if (!vehicle && vehicles.length > 0) {
      setVehicle(vehicles[0].id);
    }
  }, [vehicles]);

  useEffect(() => {
    if (!user && users.length > 0) {
      setUser(users[0].id);
    }
  }, [users]);

  //Select the selected vehicle from the Dashboard
  useEffect(() => {
    if(selectedOversonsumedVehicle) {
      setVehicle(selectedOversonsumedVehicle)
      setFilterOption(filterOverconsumeVehicle)
    }
  }, [])

  useEffect(() => {
    if (typeof vehicle !== "undefined") {
      if (api.from && api.to && vehicle) {
        props.loadTrip();
        props.requestTripReport(api);
        props.requestOpenTrack(vehicle);
        props.requestTrips({
          ...api,
          page: page + 1,
          perPage: rowsPerPage,
          orderAsc: settings.orderBy === orderBy.asc,
          FilterOptions: getRequestedFilterOption(filterOption),
        });
      }
    }
  }, [view, page, rowsPerPage, api, settings, filterOption, filterOverconsumeVehicle]);

  useEffect(() => {
    if (view === views.statistic) {
      if (userMode) props.requestTripVehiclesReport(api);
      if (!userMode) props.requestTripUsersReport(api);

      props.requestTripMileageReport({
        ...api,
        month:
          api.period === periods.none.id ? new Date().toISOString() : api.from,
      });
    }
  }, [view, userMode, api]);

  useEffect(() => {
    return () => {
      deselectAllTrips();
    };
  }, []);

  //preload vehicle or userlist
  useEffect(() => {
    props.requestAllVehicles({
      page: 1,
      perPage: 99999,
      isArchived: settings.showArchived,
    });
    if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
      props.requestAllUsers({
        page: 1,
        perPage: 99999,
        isArchived: settings.showArchived,
      });
    }
  }, []);

  useEffect(() => {
    if (
      !vehicle &&
      (!api.vehicleIds || api.vehicleIds.length === 0) &&
      vehicles &&
      vehicles.length > 0
    ) {
      setVehicle(vehicles[0].id);
    }
  }, [updatedAt]);

  useEffect(() => {
    if (vehicle && !userMode)
      props.updateFilterAPICall({ vehicleIds: [vehicle], driverIds: [] });
    if (vehicle && !userMode) props.requestVehicleById(vehicle);
    if (vehicle && !userMode)
      props.requestAllVehicles({
        page: 1,
        perPage: 99999,
        isArchived: settings.showArchived,
      });
  }, [vehicle, userMode, settings]);

  useEffect(() => {
    if (user && userMode) {
      props.updateFilterAPICall({ vehicleIds: [], driverIds: [user] });
    }

    if (user && userMode)
      props.requestAllUsers({
        page: 1,
        perPage: 99999,
        isArchived: settings.showArchived,
      });
  }, [user, userMode, settings]);

  function handleLockTrip(trip) {
    setTrip(trip);
    openLockTripModal();
  }

  function handleLockTrips() {
    props.requestLockTrips({
      tripIds: props.trips.filter((t) => t.isSelected).map((tr) => tr.id),
    });
  }

  function handleUnlockTrip(trip) {
    props.requestUnlockTrips({ tripIds: [trip.id] });
  }

  const handleUpdateTrip = (values) => {
    const mergeStateWithUpdatedValues = { ...trip, ...values };
    props.requestUpdateTrips({
      type: 0,
      driverId: mergeStateWithUpdatedValues.driverId,
      description: mergeStateWithUpdatedValues.description,
      tripIds: [mergeStateWithUpdatedValues.id],
    });
  };

  const toolbar = (
    <TripPageToolbar
      views={views}
      view={view}
      setView={setView}
      onAdd={openAddModal}
      onExport={openExportModal}
      onMerge={openMergeModal}
      onLock={handleLockTrips}
      onBulkEdit={openBulkEditModal}
      settings={settings}
      onSetting={openSettingModal}
      user={user}
      setUser={setUser}
      vehicle={vehicle}
      setVehicle={setVehicle}
      setUserMode={setMode}
      usermode={userMode}
      tripsFilter={filterOption}
      setTripsFilter={setFilterOption}
    />
  );

  function renderTablePage() {
    return props.loading ? (
      <div className={classes.loadingWrapper}>
        <LoadingIndicator />
      </div>
    ) : (
      <TripTable
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={changePage}
        handleChangeRowsPerPage={changeRowPerPage}
        pageName={PAGE_NAME.trip}
        onSelect={selectTrip}
        onSelectAll={selectAllTrips}
        onLock={handleLockTrip}
        onUnlock={handleUnlockTrip}
        onTrack={(trip) => {
          if (trip.id !== undefined) {
            setTrip(trip);
            openTrackModal();
          } else {
            // trip is not finished yet
            openOpenTrackModal();
          }
        }}
        onHistory={(trip) => {
          setTrip(trip);
          openHistoryModal();
        }}
        onDelete={(trip) => {
          setTrip(trip);
          openDeleteTripModal();
        }}
        onToBusiness={(trip) => {
          handleUpdateTrip(trip);
        }}
        settings={settings}
      />
    );
  }

  function renderStatisticPage() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <DashboardTripDistribution
            title={t("dashboard.charts.tripDistribution.title")}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <DashboardKmHistory
            title={t("dashboard.charts.kmHistory.title")}
            endMonth={
              !api.period || api.period === periods.none.id
                ? new Date()
                : api.to
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TripUserStatisticTable userMode={userMode} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Page
      activePage={menu.trips.key}
      toolbar={toolbar}
      loading={false} //pageLoading is deactivated
      onAdd={openAddModal}
      isSuccessMsgVisible={isSuccessMsgVisible}
      successMsg={t("successToolbar.tripAdded")}
      toggleMsgVisibility={toggleTripSuccessMsgVisibility}
    >
      <FilterToolbar />
      <TripSummaryReports
        mode={userMode}
        onVehicle={openVehicleModal}
        onDriver={openDriverModal}
        onOpenTrack={openOpenTrackModal}
      />

      <Grid container>
        <Grid item md={2} xs={12} className={classes.vehicleCardContainer}>
          <Card className={classes.vehicleCard}>
            <List dense className={classes.vehicleList}>
              {!userMode &&
                vehicles.map((v, i) => (
                  <ListItem
                    className={classes.selectList}
                    button
                    key={"TripPage#li#" + i}
                    selected={v.id === vehicle}
                    onClick={() => {
                      if (vehicle !== v.id) {
                        props.loadTrip();
                        setVehicle(v.id);
                        changePage(null, 0);
                      }
                    }}
                  >
                    <ListItemText
                      primary={v.name || ""}
                      secondary={v.licensePlate || ""}
                    />
                    {!v.drivebox && (
                      <ListItemSecondaryAction>
                        <Tooltip
                          title={
                            t("trip.page.noDriveboxAssigned")
                          }
                          aria-label="warning"
                        >
                          <IconButton edge="end" aria-label="warning">
                            <FontAwesomeIcon fontSize={15} minWidth={20}>
                              far fa-exclamation-triangle
                            </FontAwesomeIcon>
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              {userMode &&
                users.map((v, i) => (
                  <ListItem
                    className={classes.selectList}
                    button
                    key={"TripPage#li#" + i}
                    selected={v.id === user}
                    onClick={() => {
                      if (user !== v.id) {
                        props.loadTrip();
                        setUser(v.id);
                        changePage(null, 0);
                      }
                    }}
                  >
                    <ListItemText
                      primary={v.firstName || ""}
                      secondary={v.lastName || ""}
                    />
                    <Divider variant="inset" component="li" />
                  </ListItem>
                ))}
            </List>
          </Card>
        </Grid>

        <Grid item md={10} xs={12}>
          <div className={classes.contentContainer}>
            {view === views.table && renderTablePage()}
            {view === views.statistic && renderStatisticPage()}
          </div>
        </Grid>
      </Grid>

      {trackModal && (
        <TrackModal
          id={trip.id}
          onEdit={openEditModal}
          onClose={closeTrackModal}
        />
      )}
      {editModal && <TripEditModal tripId={trip.id} onClose={closeEditModal} />}
      {driverModal && (
        <DriverModal onClose={closeDriverModal} vehicleId={vehicle} />
      )}
      {vehicleModal && (
        <VehicleModal onClose={closeVehicleModal} userId={user} />
      )}
      {openTrackModalStatus && <OpenTrackModal onClose={closeOpenTrackModal} />}
      {settingModal && (
        <TripSettingModal
          settings={settings}
          onSave={(setting) => {
            setSettings(setting);
            closeSettingModal();
          }}
          onClose={closeSettingModal}
        />
      )}
      {filterModal && <TripFilterModal onClose={closeFilterModal} />}
      {addModal && <TripAddModal onClose={closeAddModal} />}
      {historyModal && (
        <HistoryModal id={trip.id} onClose={closeHistoryModal} />
      )}
      {mergeModal && (
        <TripMergeModal
          page={page}
          rowsPerPage={rowsPerPage}
          orderAsc={settings.orderBy === orderBy.asc}
          onClose={() => {
            closeMergeModal();
            deselectAllTrips();
          }}
        />
      )}
      {bulkEditModal && (
        <TripBulkEditModal
          page={page}
          rowsPerPage={rowsPerPage}
          onClose={() => {
            closeBulkEditModal();
            deselectAllTrips();
          }}
          orderAsc={settings.orderBy === orderBy.asc}
        />
      )}

      {exportModal && (
        <LogBookExportModal
          onClose={closeExportModal}
          onExport={showExportSnackbar}
        />
      )}

      {deleteTripModal && (
        <TripDeleteModal
          onClose={closeDeleteTripModal}
          title={t("trip.deleteTripModal.deleteTripModalTitle")}
          data={trip}
          type={modalType.warnType}
        />
      )}

      {lockTripModal && (
        <TripLockModal
          onClose={closeLockTripModal}
          title={t("trip.lockTripModal.lockTripModalTitle")}
          data={trip}
          type={modalType.warnType}
        />
      )}

      <Snackbar
        translateKey="trip.page.snackbarExport"
        onClose={hideExportSnackbar}
        open={exportSnackbar}
      />
    </Page>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestTrips,
      requestLockTrips,
      requestUnlockTrips,
      requestDeleteTrip,
      requestUpdateTrips,
      selectTrip,
      selectAllTrips,
      deselectAllTrips,
      requestOpenTrack,
      setText,
      clearFilter,
      setTextArray,
      updateFilterAPICall,
      requestTripMileageReport,
      requestTripReport,
      requestTripUsersReport,
      requestTripVehiclesReport,
      requestVehicleById,
      requestAllVehicles,
      requestTripIdleTimeReport,
      requestAllUsers,
      loadTrip,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    trips: state.trips.entities,
    loading: state.trips.loading,
    error: state.trips.error,
    selected: state.trips.selected,
    filter: state.filter,
    me: state.users.me,
    vehicles: state.vehicles.allEntities,
    users: state.users.allEntities,
    costcenters: state.costcenters.allEntities,
    isSuccessMsgVisible: state.trips.isSuccessMsgVisible,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(TripPage);
