import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
        color: "white"
    },
    center: {
        margin: "auto",
    },
});

const LoadingIndicator = ({ classes, color="inherit" }) => {
    return (
        <div className={classes.center}>
            <CircularProgress className={classes.progress} style={{color: color}}/>
        </div>
    );
};

export default withStyles(styles)(LoadingIndicator);
