
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { Grid } from "@material-ui/core";
import { modalActions } from "../CustomModal";

import { customColors } from "../../../geofences/generic/StylingOptions";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    deleteButton: {
        backgroundColor: customColors.deleteDarkRed,
        color: "white",
        padding: 5,
        borderRadius: 3,
        width: 80
    }
});

const CustomModalActions = ({ action, onClose, onSave, applyButtonStyle, clearFilter, isFilterActive }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (action === modalActions.none) {
        return <div/>;
    } else if (action === modalActions.ok) {
        return (
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    ok
                </Button>
            </DialogActions>
        );
    } else {
        return (
            <DialogActions>
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                       {clearFilter && isFilterActive &&
                       <Button onClick={clearFilter} color="primary" style={applyButtonStyle && {backgroundColor: customColors.ilogsBlue, color: "white"}}>
                            {t("common.modal.generic.clearFilter")}
                        </Button>}
                    </Grid>
                    <Grid item>
                        {!clearFilter &&
                        <Button onClick={onClose} color="primary">
                            {t("common.modal.generic.cancel")}
                        </Button>}
                {action !== modalActions.cancel && (
                    <Button onClick={onSave} color="primary" autoFocus style={applyButtonStyle && {backgroundColor: customColors.ilogsBlue, color: "white"}}>
                        {action === modalActions.filter ? t("common.modal.generic.filter") : ""}
                        {action === modalActions.confirm ? t("common.modal.generic.confirm") : ""}
                        {action === modalActions.update ? t("common.modal.generic.update") : ""}
                        {action === modalActions.add ? t("common.modal.generic.add") : ""}
                        {action === modalActions.delete ?  <div className={classes.deleteButton}>{t("common.modal.generic.delete")}</div> : ""}
                    </Button>
                )}
                    </Grid>
                </Grid>
             
            </DialogActions>
        );
    }
}

export default CustomModalActions;