import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import { TripResponseWithMoreInfo } from "../types/trip/tripResponse.types";

import {
  RequestVehicles,
  ReceiveVehicles,
  RequestAllVehicles,
  ReceiveAllVehicles,
  RequestVehiclesLastPosition,
  ReceiveVehiclesLastPosition,
  RequestVehicleById,
  ReceiveVehicleById,
  RequestAddVehicle,
  ReceiveAddVehicle,
  RequestUpdateVehicle,
  ReceiveUpdateVehicle,
  RequestUpdateVehicles,
  ReceiveUpdateVehicles,
  RequestDeleteVehicle,
  ReceiveDeleteVehicle,
  RequestVehicleToggleArchive,
  ReceiveVehicleToggleArchive,
  RequestVehiclesToggleArchive,
  ReceiveVehiclesToggleArchive,
  RequestAddVehicleDriver,
  ReceiveAddVehicleDriver, 
  RequestDeleteVehicleDriver,
  ReceiveDeleteVehicleDriver,
  RequestOpenTrack,
  ReceiveOpenTrack,
  RequestCalibrations,
  ReceiveCalibrations,
  RequestAddCalibration,
  ReceiveAddCalibration,
  RequestDeleteCalibration,
  ReceiveDeleteCalibration,
  RequestTracksOfVehicle,
  ReceiveTracksOfVehicle,
  SelectVehicle,
  SelectAllVehicles,
  DeselectAllVehicle,
  LoadVehicle
} from "../types/actions/actionVehicle.types";

import {
  VehicleGetRequest,
  VehicleAddOrUpdateRequest,
  VehiclePatchRequest,
  VehicleDriverAddRequest,
  VehicleOpenTrackRequest,
  VehicleAddCalibrationRequest,
  VehicleDeleteCalibrationRequest,
  VehicleTracksRequest
} from "../types/vehicle/vehicleRequests.types";
import {
  VehicleResponse,
  VehicleLastPositionResponse,
  VehicleResponseInfo,
  VehicleDriverAddResponse,
  VehicleCalibrationResponse
} from "../types/vehicle/vehicleResponse.types";

import { EntityToggleArchiveRequest, EntitiesToggleArchiveRequest } from "../types/global.types";

export enum VEHICLE_ACTION_TYPES {
  REQUEST_VEHICLES = "REQUEST_VEHICLES",
  RECEIVE_VEHICLES = "RECEIVE_VEHICLES",

  REQUEST_ALL_VEHICLES = "REQUEST_ALL_VEHICLES",
  RECEIVE_ALL_VEHICLES = "RECEIVE_ALL_VEHICLES",

  REQUEST_VEHICLES_LAST_POSITON = "REQUEST_VEHICLES_LAST_POSITON",
  RECEIVE_VEHICLES_LAST_POSITION = "RECEIVE_VEHICLES_LAST_POSITION",

  REQUEST_VEHICLE_BY_ID = "REQUEST_VEHICLE_BY_ID",
  RECEIVE_VEHICLE_BY_ID = "RECEIVE_VEHICLE_BY_ID",

  REQUEST_ADD_VEHICLE = "REQUEST_ADD_VEHICLE",
  RECEIVE_ADD_VEHICLE = "RECEIVE_ADD_VEHICLE",

  REQUEST_UPDATE_VEHICLE = "REQUEST_UPDATE_VEHICLE",
  RECEIVE_UPDATE_VEHICLE = "RECEIVE_UPDATE_VEHICLE",

  REQUEST_UPDATE_VEHICLES = "REQUEST_UPDATE_VEHICLES",
  RECEIVE_UPDATE_VEHICLES = "RECEIVE_UPDATE_VEHICLES",

  REQUEST_DELETE_VEHICLE = "REQUEST_DELETE_VEHICLE",
  RECEIVE_DELETE_VEHICLE = "RECEIVE_DELETE_VEHICLE",

  REQUEST_VEHICLE_TOGGLE_ARCHIVE = "REQUEST_VEHICLE_TOGGLE_ARCHIVE",
  RECEIVE_VEHICLE_TOGGLE_ARCHIVE = "RECEIVE_VEHICLE_TOGGLE_ARCHIVE",

  REQUEST_VEHICLES_TOGGLE_ARCHIVE = "REQUEST_VEHICLES_TOGGLE_ARCHIVE",
  RECEIVE_VEHICLES_TOGGLE_ARCHIVE = "RECEIVE_VEHICLES_TOGGLE_ARCHIVE",

  REQUEST_ADD_VEHICLE_DRIVER = "REQUEST_ADD_VEHICLE_DRIVER",
  RECEIVE_ADD_VEHICLE_DRIVER = "RECEIVE_ADD_VEHICLE_DRIVER",
  REQUEST_DELETE_VEHICLE_DRIVER = "REQUEST_DELETE_VEHICLE_DRIVER",
  RECEIVE_DELETE_VEHICLE_DRIVER = "RECEIVE_DELETE_VEHICLE_DRIVER",

  REQUEST_OPEN_TRACK = "REQUEST_OPEN_TRACK",
  RECEIVE_OPEN_TRACK = "RECEIVE_OPEN_TRACK",

  // Calibration
  REQUEST_CALIBRATIONS = "REQUEST_CALIBRATIONS",
  RECEIVE_CALIBRATIONS = "RECEIVE_CALIBRATIONS",

  REQUEST_ADD_CALIBRATION = "REQUEST_ADD_CALIBRATION",
  RECEIVE_ADD_CALIBRATION = "RECEIVE_ADD_CALIBRATION",

  REQEUST_DELETE_CALIBRATION = "REQEUST_DELETE_CALIBRATION",
  RECEIVE_DELETE_CALIBRATION = "RECEIVE_DELETE_CALIBRATION",

  REQUEST_TRACKS_OF_VEHICLE = "REQUEST_TRACKS_OF_VEHICLE",
  RECEIVE_TRACKS_OF_VEHICLE = "RECEIVE_TRACKS_OF_VEHICLE",

  SELECT_VEHICLE = "SELECT_VEHICLE",
  SELECT_ALL_VEHICLE = "SELECT_ALL_VEHICLE",
  DESELECT_ALL_VEHICLE = "DESELECT_ALL_VEHICLE",
  LOAD_VEHICLE = "LOAD_VEHICLE",
}

export const requestVehicles = withMatcher(
  (data: VehicleGetRequest): RequestVehicles => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_VEHICLES, data);
  }
);
export const receiveVehicles = withMatcher(
  (data: VehicleResponse): ReceiveVehicles => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_VEHICLES, data);
  }
);

export const requestAllVehicles = withMatcher(
  (data: VehicleGetRequest): RequestAllVehicles => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_ALL_VEHICLES, data);
  }
);
export const receiveAllVehicles = withMatcher(
  (data: VehicleResponse): ReceiveAllVehicles => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_ALL_VEHICLES, data);
  }
);

export const requestVehiclesLastPosition = withMatcher(
  (): RequestVehiclesLastPosition => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_VEHICLES_LAST_POSITON);
  }
);
export const receiveVehiclesLastPosition = withMatcher(
  (data: VehicleLastPositionResponse[]): ReceiveVehiclesLastPosition => {
    return createAction(
      VEHICLE_ACTION_TYPES.RECEIVE_VEHICLES_LAST_POSITION,
      data
    );
  }
);

export const requestVehicleById = withMatcher(
  (vehicleId: string): RequestVehicleById => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_VEHICLE_BY_ID, vehicleId);
  }
);
export const receiveVehicleById = withMatcher(
  (data: VehicleResponseInfo): ReceiveVehicleById => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_VEHICLE_BY_ID, data);
  }
);

export const requestAddVehicle = withMatcher(
  (data: VehicleAddOrUpdateRequest): RequestAddVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_ADD_VEHICLE, data);
  }
);
export const receiveAddVehicle = withMatcher(
  (data: VehicleResponseInfo): ReceiveAddVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_ADD_VEHICLE, data);
  }
);

export const requestUpdateVehicle = withMatcher(
  (data: VehicleAddOrUpdateRequest): RequestUpdateVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_UPDATE_VEHICLE, data);
  }
);
export const receiveUpdateVehicle = withMatcher(
  (data: VehicleResponseInfo): ReceiveUpdateVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_UPDATE_VEHICLE, data);
  }
);

export const requestUpdateVehicles = withMatcher(
  (data: VehiclePatchRequest): RequestUpdateVehicles => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_UPDATE_VEHICLES, data);
  }
);
export const receiveUpdateVehicles = withMatcher(
  (data: VehicleResponseInfo[]): ReceiveUpdateVehicles => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_UPDATE_VEHICLES, data);
  }
);

export const requestDeleteVehicle = withMatcher(
  (vehicleId: string): RequestDeleteVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_DELETE_VEHICLE, vehicleId);
  }
);
export const receiveDeleteVehicle = withMatcher(
  (data: VehicleResponseInfo): ReceiveDeleteVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_DELETE_VEHICLE, data);
  }
);

export const requestVehicleToggleArchive = withMatcher(
  (data: EntityToggleArchiveRequest): RequestVehicleToggleArchive => {
    return createAction(
      VEHICLE_ACTION_TYPES.REQUEST_VEHICLE_TOGGLE_ARCHIVE,
      data
    );
  }
);
export const receiveVehicleToggleArchive = withMatcher(
  (data: VehicleResponseInfo): ReceiveVehicleToggleArchive => {
    return createAction(
      VEHICLE_ACTION_TYPES.RECEIVE_VEHICLE_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const requestVehiclesToggleArchive = withMatcher(
  (data: EntitiesToggleArchiveRequest): RequestVehiclesToggleArchive => {
    return createAction(
      VEHICLE_ACTION_TYPES.REQUEST_VEHICLES_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const receiveVehiclesToggleArchive = withMatcher(
  (data: VehicleResponseInfo[]): ReceiveVehiclesToggleArchive => {
    return createAction(
      VEHICLE_ACTION_TYPES.RECEIVE_VEHICLES_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const requestAddVehicleDriver = withMatcher(
  (data: VehicleDriverAddRequest): RequestAddVehicleDriver => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_ADD_VEHICLE_DRIVER, data);
  }
);

export const receiveAddVehicleDriver = withMatcher(
  (data: VehicleDriverAddResponse): ReceiveAddVehicleDriver => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_ADD_VEHICLE_DRIVER, data);
  }
);

export const requestDeleteVehicleDriver = withMatcher(
  (vehicleId: string): RequestDeleteVehicleDriver => {
    return createAction(
      VEHICLE_ACTION_TYPES.REQUEST_DELETE_VEHICLE_DRIVER,
      vehicleId
    );
  }
);

export const receiveDeleteVehicleDriver = withMatcher(
  (): ReceiveDeleteVehicleDriver => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_DELETE_VEHICLE_DRIVER);
  }
);

export const requestOpenTrack = withMatcher(
  (data: VehicleOpenTrackRequest): RequestOpenTrack => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_OPEN_TRACK, data);
  }
);

export const receiveOpenTrack = withMatcher(
  (data: TripResponseWithMoreInfo): ReceiveOpenTrack => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_OPEN_TRACK, data);
  }
);

export const requestCalibrations = withMatcher(
  (vehicleId: string): RequestCalibrations => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_CALIBRATIONS, vehicleId);
  }
);

export const receiveCalibrations = withMatcher(
  (data: VehicleCalibrationResponse): ReceiveCalibrations => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_CALIBRATIONS, data);
  }
);

export const requestAddCalibration = withMatcher(
  (data: VehicleAddCalibrationRequest): RequestAddCalibration => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_ADD_CALIBRATION, data);
  }
);

export const receiveAddCalibration = withMatcher(
  (data: VehicleResponseInfo): ReceiveAddCalibration => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_ADD_CALIBRATION, data);
  }
);

export const requestDeleteCalibration = withMatcher(
  (data: VehicleDeleteCalibrationRequest): RequestDeleteCalibration => {
    return createAction(VEHICLE_ACTION_TYPES.REQEUST_DELETE_CALIBRATION, data);
  }
);
export const receiveDeleteCalibration = withMatcher(
  (data: VehicleResponseInfo): ReceiveDeleteCalibration => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_DELETE_CALIBRATION, data);
  }
);

export const requestTracksOfVehicle = withMatcher(
  (data: VehicleTracksRequest): RequestTracksOfVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.REQUEST_TRACKS_OF_VEHICLE, data);
  }
);

export const receiveTracksOfVehicle = withMatcher(
  (data: TripResponseWithMoreInfo[]): ReceiveTracksOfVehicle => {
    return createAction(VEHICLE_ACTION_TYPES.RECEIVE_TRACKS_OF_VEHICLE, data);
  }
);

export const selectVehicle = withMatcher((data: VehicleResponseInfo): SelectVehicle => {
  return createAction(VEHICLE_ACTION_TYPES.SELECT_VEHICLE, data);
});

export const selectAllVehicles = withMatcher((): SelectAllVehicles => {
  return createAction(VEHICLE_ACTION_TYPES.SELECT_ALL_VEHICLE);
});

export const deselectAllVehicle = withMatcher((): DeselectAllVehicle => {
  return createAction(VEHICLE_ACTION_TYPES.DESELECT_ALL_VEHICLE);
});

export const loadVehicle = withMatcher((): LoadVehicle => {
  return createAction(VEHICLE_ACTION_TYPES.LOAD_VEHICLE);
});
