import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Timeline} from "antd";
import {requestCalibrations} from "../../../actions/actionVehicle";
import {format} from "date-fns";
import { useTranslation } from "react-i18next";

const VehicleCalibrationHistoryContent = props => {
    const {calibrations} = props;
    const { t } = useTranslation();

    useEffect(() => {
        props.requestCalibrations(props.id);
    }, []);


    if (calibrations.length === 0) {
        return <h3>{t("vehicle.calibrationModal.noCalibration")}</h3>;
    }

    return (
        <Timeline>
            {calibrations.map(entry => {
                return (
                    <Timeline.Item>
                        <p>{
                            t( "vehicle.calibrationModal.changeMessage" , {
                                mileageInMeter: entry.actualMileageInMeters / 1000,
                                newMileageInMeter: entry.calibratedMileageInMeters / 1000,
                                date: format(new Date(entry.calibratedAt), "dd.MM.yy H:mm"), 
                            })}
                            <br/>
                            {t("vehicle.calibrationModal.byMessage", {
                                date: format(new Date(entry.createdAt), "dd.MM.yy H:mm"),
                                user: entry.user ? entry.user.name : ""
                            })}
                        </p>
                    </Timeline.Item>
                );
            })}
        </Timeline>
    );

};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestCalibrations}, dispatch);
}

function mapStateToProp(state) {
    return {calibrations: state.vehicles.calibrations.entities};
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(VehicleCalibrationHistoryContent);
