import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EditModal from "../../../common/modals/CustomModal";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../common/inputs/InputField";
import MonthPicker from "../../../common/selects/datePickers/MonthPicker";
import ServiceModalTable from "./ServiceModalTable";
import VehicleKindSelect from "../../selects/VehicleKindSelect";
import CountrySelect from "../../../common/selects/specificSelects/CountrySelect";
import {
  requestUpdateVehicle,
  requestVehicleById,
} from "../../../../actions/actionVehicle";
import { requestNextInspection } from "../../../../actions/actionInspection";
import { requestNextVehicleService } from "../../../../actions/actionVehicleService";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ServiceInspectionAlert from "./ServiceInspectionAlert";
import {getIdsFromGroupValues} from "../../../../managers/groupManager";

const useStyles = makeStyles({
  defaultMargin: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  headline: {
    fontSize: "1.7em",
    marginTop: "5px",
    marginBottom: "25px",
    textAlign: "center",
  },
});

const ServiceModal = ({
  onAddInspection,
  onAddService,
  onClose,
  vehicleId,
}) => {
  const vehicle = useSelector((state) => state.vehicles.current);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [lPeriodicServiceDistanceInMeter, setLPeriodicServiceDistanceInMeter] =
    useState(0);
  const {
    country,
    registrationDate,
    vehicleServiceType,
    periodicServiceDistanceInMeter,
    inspections,
    services,
  } = vehicle;

  useEffect(() => {
    if (vehicle) {
      dispatch(requestNextInspection(vehicle.id));
      dispatch(requestNextVehicleService(vehicle.id));
    }
  }, [
    country,
    registrationDate,
    vehicleServiceType,
    periodicServiceDistanceInMeter,
    inspections,
    services,
  ]);

  useEffect(() => {
    dispatch(requestVehicleById(vehicleId));
  },[]);

  const handleVehicleChange = (newVehicle) => {
    dispatch(requestUpdateVehicle({...newVehicle, driveboxId: vehicle.drivebox?.id}));
  };

  useEffect(() => {
    if (periodicServiceDistanceInMeter !== lPeriodicServiceDistanceInMeter)
      setLPeriodicServiceDistanceInMeter(periodicServiceDistanceInMeter);
  }, [periodicServiceDistanceInMeter]);

  const handleSavePeriodicServiceDistanceInMeter = () => {
    handleVehicleChange({
      ...vehicle,
      periodicServiceDistanceInMeter: lPeriodicServiceDistanceInMeter,
    });
  };

  const hasNextInspection =
    typeof vehicle.country !== "undefined" &&
    vehicle.country !== null &&
    typeof vehicle.registrationDate !== "undefined" &&
    vehicle.registrationDate !== null &&
    typeof vehicle.vehicleServiceType !== "undefined" &&
    vehicleServiceType !== null;

  const isServiceOverdue = vehicle.nextService < vehicle.mileageInMeters;

  const contents = [
    <Grid container style={{ paddingRight: "5px" }}>
      <Grid item xs={12} className={classes.defaultMargin}>
        <ServiceInspectionAlert
          title={"Nächste Überprüfung"}
          hasError={!hasNextInspection}
          value={vehicle.nextInspection}
          showInspection
        />
      </Grid>

      <Grid className={classes.defaultMargin} item xs={6}>
        Erstzulassung
      </Grid>
      <Grid className={classes.defaultMargin} item xs={6}>
        <MonthPicker
          style={{ width: "100%" }}
          value={registrationDate}
          onDateChange={(d) => {
            handleVehicleChange({
              ...vehicle,
              registrationDate: new Date(
                Date.UTC(d.getFullYear(), d.getMonth(), 1)
              ).toISOString(), //Save value as UTC
            });
          }}
        />
      </Grid>
      <Grid className={classes.defaultMargin} item xs={6}>
        Fahrzeugart
      </Grid>
      <Grid className={classes.defaultMargin} item xs={6}>
        <VehicleKindSelect
          value={vehicleServiceType}
          onChange={(d) => {
            handleVehicleChange({ ...vehicle, vehicleServiceType: d });
          }}
        />
      </Grid>
      <Grid className={classes.defaultMargin} item xs={6}>
        Zulassungsland
      </Grid>
      <Grid className={classes.defaultMargin} item xs={6}>
        <CountrySelect
          value={country}
          onChange={(d) => {
            handleVehicleChange({ ...vehicle, country: d });
          }}
        />
      </Grid>

      <Grid className={classes.defaultMargin} item xs={12}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            Vergangene Gutachten
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ServiceModalTable showInspection />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>

      <Grid item xs={6} />
      <Grid className={classes.defaultMargin} item xs={6}>
        <Button
          onClick={onAddInspection}
          fullWidth
          color="primary"
          variant="contained"
        >
          Überprüfung hinzufügen
        </Button>
      </Grid>
    </Grid>,
    <>
      <Grid container style={{ paddingRight: "22px" }}>
        <Grid item xs={12} className={classes.defaultMargin}>
          <ServiceInspectionAlert
            title={
              !isServiceOverdue
                ? "Nächster Service in " +
                  (vehicle.nextService - vehicle.mileageInMeters) / 1000 +
                  " km"
                : "Service " +
                  (vehicle.mileageInMeters - vehicle.nextService) / 1000 +
                  " km überfällig"
            }
            hasError={
              typeof periodicServiceDistanceInMeter === "undefined" ||
              periodicServiceDistanceInMeter === null ||
              periodicServiceDistanceInMeter === 0
            }
            value={
              "Service beim KM-Stand: " + vehicle.nextService / 1000 + " km"
            }
          />
        </Grid>

        <Grid className={classes.defaultMargin} item xs={8}>
          <InputField
            number
            label=" Nach wie viel Km soll die nächste Überprüfung sein"
            value={lPeriodicServiceDistanceInMeter / 1000}
            onChange={(d) => {
              setLPeriodicServiceDistanceInMeter(d.target.value * 1000);
            }}
          />
        </Grid>
        <Grid className={classes.defaultMargin} item xs={4}>
          <Button
            size={"small"}
            style={{ top: "15px" }}
            onClick={handleSavePeriodicServiceDistanceInMeter}
            disabled={
              lPeriodicServiceDistanceInMeter === periodicServiceDistanceInMeter
            }
            fullWidth
            color="primary"
            variant="contained"
          >
            Speichern
          </Button>
        </Grid>

        <Grid className={classes.defaultMargin} item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Vergangene Servicetermine
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ServiceModalTable />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        <Grid item xs={6} />
        <Grid className={classes.defaultMargin} item xs={6}>
          <Button
            onClick={onAddService}
            fullWidth
            color="primary"
            variant="contained"
          >
            {" "}
            Service hinzufügen
          </Button>
        </Grid>
      </Grid>
    </>,
  ];

  return (
    <EditModal
      onClose={onClose}
      title={"Begutachtung / KM Service"}
      tabs={["Begutachtung (§57a)", " KM Services"]}
      contents={contents}
    />
  );
};

// ServiceModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   onAddService: PropTypes.func.isRequired,
//   onAddInspection: PropTypes.func.isRequired,
//   vehicleId: PropTypes.string.isRequired,
// };

export default ServiceModal;
