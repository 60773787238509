import React from "react";
import { Select } from "antd";
import CustomSelect from "../Select";
import { languageArray } from "../../../../models/Language";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const LanguageSelect = (props) => {
    const { onChange, value, disabled } = props;
    const { t } = useTranslation();

    return (
        <CustomSelect value={value}
                      placeholder={t("common.select.language")}
                      handleChange={onChange}
                      allowClear={false}
                      disabled={disabled}>
            {languageArray.map(l => (
                <Option key={l.id} value={l.id}>
                    {t(l.translateKey)}
                </Option>
            ))}
        </CustomSelect>
    );
};

LanguageSelect.defaultProps = {
    disabled: false,
};

export default LanguageSelect;
