import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'antd';

const SuccessToolbar = ({ successMsg, toggleMsgVisibility }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(toggleMsgVisibility(false));
    }, 5000);
  }, []);

  return (
    <Alert
      message={successMsg}
      type="success"
      closable
    />
  );
};

export default SuccessToolbar;