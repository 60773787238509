import {
  getEntities,
    getEntityById
  } from "../../managers/reducerManager";

import { receiveChargeStations, receiveChargeStationById, requestStartConnectionToEvo, requestStopConnectionToEvo } from "../../actions/qualityCharging/actionChargeStations";
  
  import { AnyAction } from "redux";
  
  export type ChargeStationState = {
      readonly entities: any[];
      readonly current: any;
      readonly hasEvoConnectionStarted: boolean;
      readonly loading: boolean;
    };
  
  const CHARGE_STATION_INITIAL_STATE: ChargeStationState = {
    entities: [],
    current: {connectors: []},
    hasEvoConnectionStarted: false,
    loading: false,
  };
  
  export const chargeStationsReducer = (state = CHARGE_STATION_INITIAL_STATE, action: AnyAction): ChargeStationState => {
  
      if(receiveChargeStations.match(action)) {
          return getEntities(state, action.data);
      }

      if(receiveChargeStationById.match(action)) {
        return getEntityById(state, action.data);
      }

      if(requestStartConnectionToEvo.match(action)) {
        return {...state, hasEvoConnectionStarted: true}
      }

      if(requestStopConnectionToEvo.match(action)) {
        return {...state, hasEvoConnectionStarted: false}
      }
  
      return state;
  };
  