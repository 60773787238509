import React, { Component } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withTranslation } from "react-i18next";


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConfirmDialog extends Component {
  render() {
    const { title, text, fullScreen, disabled, onClose, onConfirm, t } =
      this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        disableBackdropClick
        open
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onClose} color="primary">
            {t("common.modal.confirmModal.cancel")}
          </Button>
          <Button
            disabled={disabled}
            onClick={onConfirm}
            color="primary"
            autoFocus
          >
            {t("common.modal.confirmModal.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withTranslation()(ConfirmDialog));

