import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//common
import DriveboxSelect from "../../common/selects/specificSelects/DriveboxSelect";
import PeriodDatePicker from "../../common/selects/datePickers/PeriodDatePicker";

import FormModal from "../../common/modals/FormModal";
import FormBuilder from "../../../managers/formBuilder";
import { setFilter } from "../../../actions/actionFilter";
import { periods } from "../../../models/Period";
import { useCustomValidation } from "../../../hooks/useDatePickersValidation";
import { validateInputs } from "../../common/inputs/inputs.utils";
import { DATE_PICKER_TYPE } from "../../common/selects/datePickers/date-picker.utils";

const RawDataFilterModal = (props) => {
  const [filter, setFilter] = useState(props.filter.initial);
  const { onClose, onFilter } = props;
  const { customRule, setIsValidated, isValidated } =
    useCustomValidation("Date is required");

  useEffect(() => {
    !filter.period ||
    filter.period === 1 ||
    (filter.period === 60 && !filter.to)
      ? setIsValidated(false)
      : setIsValidated(true);
  }, [filter.period, filter.to]);

  const handleOnFilter = () => {
    if (isValidated) {
      props.setFilter({ ...filter }, { ...filter });
      onClose();
    }
  };

  const handleDriveboxChange = (value) => {
    setFilter({ ...filter, imei: value });
  };

  function handleDateChange(period, from, to) {
    setFilter({ ...filter, from: from, to: to, period: period });
  }

  const inputs = [
    new FormBuilder.FormInput(
      "Drivebox",
      (
        <DriveboxSelect
          value={filter.imei}
          onChange={handleDriveboxChange}
          isSuperUser
          imeiAsValue
        />
      )
    )
      .withValue(filter.imei)
      .withKey("imei")
      .isRequired()
      .withValidationMessage("You must set an imei ")
      .build(),
    new FormBuilder.FormInput(
      "Date",
      (
        <PeriodDatePicker
          startDate={filter.from}
          endDate={filter.to}
          datePickerType={DATE_PICKER_TYPE.rawDataFilterDatePicker}
          period={filter.period || periods.none.id}
          onlySmallRange
          onDateChange={handleDateChange}
        />
      )
    )
      .withCustomRule(customRule)
      .withValuePropName("from")
      .withTrigger("onDateChange")
      .build(),
  ];

  return (
    <FormModal
      action="filter"
      inputs={inputs}
      validateInputs={!isValidated && validateInputs}
      customRuleMessage={"Date is required"}
      onSave={handleOnFilter}
      onClose={onClose}
      title="Filter"
    />
  );
};

// RawDataFilterModal.propTypes = {
//     classes: PropTypes.object.isRequired,
//     theme: PropTypes.object.isRequired,
//     onClose: PropTypes.func.isRequired,
//     onFilter: PropTypes.func.isRequired,
//     filterProps: PropTypes.object,
// };

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setFilter,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return { filter: state.filter };
}

export default connect(mapStateToProp, mapDispatchToProps)(RawDataFilterModal);
