import React, {useEffect} from "react";
import CustomModal from "../../common/modals/CustomModal";
import GenericTable from "../../common/table/GenericTable";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {requestTimeRecordingById} from "../../../actions/actionTimeRecording";
import {getNameOfEvent, timeLogEventType} from "../../../models/TimeLogEventType";
import TableTypes from "../../../models/TableTypes";
import Comparator from "../../../models/Comparator";
import {setError} from "../../../actions/actionCommon";
import {format} from "date-fns";

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
    },
}));

const TimeRecordingModal = props => {
    const classes = useStyles();
    const {onClose, timeRecordingId, onEdit, onRemove, onAdd} = props;

    const isNotStartOrEndOrForbiddenTypeComperator = new Comparator(["eventKey"],
        (eventKey) =>
            eventKey !== timeLogEventType.startOfWork &&
            eventKey !== timeLogEventType.endOfWork &&
            eventKey !== timeLogEventType.StartOfTrip &&
            eventKey !== timeLogEventType.EndOfTrip);

    const isNotForbiddenTypeComperator = new Comparator(["eventKey"],
        (eventKey) =>
            eventKey !== timeLogEventType.StartOfTrip &&
            eventKey !== timeLogEventType.EndOfTrip);


    const dispatch = useDispatch();

    const timeLog = useSelector(state => state.timeRecording.current);


    useEffect(() => {
        if (timeRecordingId) dispatch(requestTimeRecordingById(timeRecordingId));
    }, [timeRecordingId]);

    useEffect(() => {
        //Timelogentry must be sorted by startedAt
        if (timeLog && timeLog.length >= 0) {
            let hasFinished = false;
            for (let i = 1; i < timeLog.length; i++) {
                if (timeLog[i].event === timeLogEventType.endOfWork) hasFinished = true;
                if (timeLog[i].event === timeLogEventType.EndOfRestBreak && timeLog[i - 1].event !== timeLogEventType.StartOfRestBreak)
                    dispatch(setError({message: "Die Ruhepause am " + format(new Date(timeLog[i].occurredAt), "H:mm") + " wurde beendet, ohne davor gestartet zu werden."}));
                if (timeLog[i].event === timeLogEventType.EndOfDrivingBreak && timeLog[i - 1].event !== timeLogEventType.StartOfDrivingBreak)
                    dispatch(setError({message: "Die Lenkpause  am" + format(new Date(timeLog[i].occurredAt), "H:mm") + " wurde beendet, ohne davor gestartet zu werden."}));
            }

            if (timeLog[0].event !== timeLogEventType.startOfWork) {
                dispatch(setError({message: "Es gibt Einträge vor dem Beginn des Arbeitstages"}));
            }
            if (timeLog[timeLog.length - 1].event !== timeLogEventType.endOfWork && hasFinished) {
                dispatch(setError({message: "Es gibt Einträge nach dem Ende des Arbeitstages"}));
            }
        }
    }, [timeLog])


    const mappedData = [];
    if (timeLog && timeLog.length > 0)
        timeLog.forEach(t => {
            mappedData.push({
                id: t.id,
                eventKey: t.event,
                event: getNameOfEvent(t.event),
                occurredAt: t.occurredAt
            })
        })


    const columnInformations = [
        {title: "Bezeichnung", key: "event"},
        {title: "Zeit", key: "occurredAt", type: TableTypes.dateTime},
    ];

    const actions = [
        {
            title: "Bearbeiten",
            event: onEdit,
            comparator: isNotForbiddenTypeComperator
        },
        {
            title: "Löschen",
            event: onRemove,
            comparator: isNotStartOrEndOrForbiddenTypeComperator
        }
    ]

    const content = [
        <div>
            <Grid container>
                <Grid item xs={12}>
                    {timeRecordingId && <> <GenericTable
                        data={mappedData}
                        columnInformations={columnInformations}
                        disableContainer
                        actions={actions}
                        showActions
                        onRowClick={onEdit}
                    />
                    </>
                    }
                    {!timeRecordingId && "Keine Aufzeichnungen an diesem Tag"}
                </Grid>
                <Grid item xs={6}/>
                <Grid item xs={6}>
                    <Button className={classes.button} fullWidth onClick={onAdd} color={"primary"}
                            variant={"contained"}>Neuer Eintrag</Button>
                </Grid>
            </Grid>
        </div>
    ]

    return <CustomModal onClose={onClose} title={"Lenkprotokoll"} contents={content}/>
}

// TimeRecordingModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     onEdit: PropTypes.func.isRequired,
//     onRemove: PropTypes.func.isRequired,
//     onAdd: PropTypes.func.isRequired,
//     timeRecordingId: PropTypes.object,
// };

export default TimeRecordingModal;