import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import FormModal from "../../common/modals/FormModal";
import FormBuilder from "../../../managers/formBuilder";
import {Input} from "antd";
import {requestUpdateMandatorSuperUser} from "../../../actions/actionMandators";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";

const getDefaultInputValues = (superUser) => ({
    firstName: superUser?.firstName,
    lastName: superUser?.lastName,
    email: superUser?.email,
});

const MandatorSuperUserModal = (props) => {
    const {onClose, superUser} = props;
    const { handleInputChange, fields } = useInputChangeHandler(getDefaultInputValues(superUser));

    const { firstName, lastName, email } = fields;

    const handleOnSave = () => {
        props.requestUpdateMandatorSuperUser({...fields, id: superUser.id});
        onClose();
    };

    const fullName = firstName + " " + lastName;

    const inputs = [
        new FormBuilder.FormInput("Firstname", <Input value={firstName} onChange={(e) => handleInputChange("firstName", e.target.value)} />)
            .withValue(firstName)
            .withKey("firstName")
            .withValidationMessage("Firstname must be set")
            .isRequired()
            .build(),
        new FormBuilder.FormInput("Lastname", <Input value={lastName} onChange={(e) => handleInputChange("lastName", e.target.value)} />)
            .withValue(lastName)
            .withKey("lastName")
            .withValidationMessage("Lastname must be set")
            .isRequired()
            .build(),
        new FormBuilder.FormInput("E-Mail", <Input value={email} onChange={(e) => handleInputChange("email", e.target.value)} />)
            .withValue(email)
            .withKey("email")
            .withType("email")
            .withValidationMessage("Email must be set")
            .isRequired()
            .build(),
    ];

    return <FormModal onSave={handleOnSave} onClose={onClose} title={fullName} inputs={inputs}/>;

}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestUpdateMandatorSuperUser,
        },
        dispatch
    );
}

function mapStateToProp(state) {
    return {};
}

// MandatorSuperUserModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     superUser: PropTypes.object.isRequired,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(MandatorSuperUserModal);