import React, {useEffect, useState} from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";

import {
    deselectAllUser,
    requestChangePasswordForUser,
    requestDeleteUser,
    requestUsers,
    requestUserToggleArchive,
    requestUsersToggleArchive,
    selectAllUser,
    selectUser,
} from "../../actions/actionUser";

import { requestTree, requestTreesCurrentUser } from "../../actions/actionGroups";

import {requestUserExportXlsx} from "../../actions/actionExport";
import {setTextArray, updateFilterAPICall} from "../../actions/actionFilter";

import {menu} from "../../managers/pathManager";
import FilterToolbar from "../common/toolbar/FilterToolbar";

import UserModal from "./userModals/UserModal";
import UserBulkEditModal from "./userModals/UserBulkEditModal";
import UserAddModal from "./userModals/UserAddModal";
import UserFilterModal from "./userModals/UserFilterModal";
import HistoryModal from "../common/modals/HistoryModal";
import ArchiveModal from "../common/modals/ArchiveModal";
import ChangePasswordModal from "../common/modals/ChangePasswordModal";
import Page from "../common/container/Page";
import Toolbar from "../common/toolbar/Toolbar";
import Snackbar from "../common/views/Snackbar";

import UserTable from "./UserTable";

import {useModalStatus, useSnackbar} from "../../hooks/useModalStatus";
import {usePagination} from "../../hooks/usePagination";
import {allowedRoles, isAuthorized} from "../../managers/authManager";
import {roles} from "../../models/Role";
import {makeStyles} from "@material-ui/core";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";

const useStyles = makeStyles(theme => ({
    contentContainer: {
        margin: "0 5px"
    }
}));

const UserListPage = props => {
    const dispatch = useDispatch();
    const [archive, setArchive] = useState(false);
    const [user, setUser] = useState(undefined);
    const tree = useSelector((state) => state.tree);
    const {page, rowsPerPage, changePage, changeRowPerPage} = usePagination();
    const {open: exportSnackbar, show: showExportSnackbar, hide: hideExportSnackbar} = useSnackbar();
    const {modal: editModal, open: openEditModal, close: closeEditModal} = useModalStatus();
    const {modal: bulkEditModal, open: openBulkEditModal, close: closeBulkEditModal} = useModalStatus();
    const {modal: addModal, open: openAddModal, close: closeAddModal} = useModalStatus();
    const {modal: filterModal, open: openFilterModal, close: closeFilterModal} = useModalStatus();
    const {modal: historyModal, open: openHistoryModal, close: closeHistoryModal} = useModalStatus();
    const {modal: changePasswordModal, open: openChangePasswordModal, close: closeChangePasswordModal} = useModalStatus();
    const {modal: archiveModal, open: openArchiveModal, close: closeArchiveModal} = useModalStatus();
    const {modal: bulkArchiveModal, open: openBulkArchiveModal, close: closeBulkArchiveModal} = useModalStatus();

    const classes = useStyles();

    const {filter, selectUser, selectAllUser, users, selected, me} = props;
    const {api, initial, updatedAt} = filter;

    useEffect(() => {
        const { groups, costcenters } = initial;
        const filterParams = [];
        const costcenterKeys = [];

        if (typeof groups !== "undefined" && groups.length > 0)
            filterParams.push("Gruppen: " + groups.map(g => g.label).join(", "));
        
        if (typeof costcenters !== "undefined" && costcenters.length > 0) {
            for (var i = 0; i < props.costcenters.length; i++)
                for (var j = 0; j < costcenters.length; j++)
                    if (costcenters[j] == props.costcenters[i].id)
                        costcenterKeys.push(props.costcenters[i].foreignId);
            filterParams.push("Kostenstellen: " + costcenterKeys.join(", "));
        }
        props.setTextArray(filterParams);
    }, [updatedAt]);

    useEffect(() => {
        if (isAuthorized(me, allowedRoles(roles.AdminViewer))) dispatch(requestTree());
        else dispatch(requestTreesCurrentUser());
    }, []);
 
    useEffect(() => {
        requestUsers();

    }, [updatedAt, archive, page, rowsPerPage]);

    function handleSearch(searchTerm) {
        props.updateFilterAPICall({ searchTerm }, false);
        changePage(null, 0);
    }

    function requestUsers() {
        props.requestUsers({...filter.api, isArchived: archive, page: page + 1, perPage: rowsPerPage});
    }

    function requestUserExport() {
        showExportSnackbar();
        props.requestUserExportXlsx({...filter.api, isArchived: archive});
    }

    function handleChangePasswordByAdmin(adminPassword, newPassword) {
        props.requestChangePasswordForUser({adminPassword, newPassword, id: user.id});
    }

    const actionButtons = [];
    const bulkActionButtons = [];
    if (isAuthorized(me, allowedRoles(roles.Admin))) {
        actionButtons.push({
            icon: <FontAwesomeIcon>far fa-plus</FontAwesomeIcon>,
            translateKey: "user.page.add",
            onClick: openAddModal,
        });
    }

    if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
        actionButtons.push({
            icon: <FontAwesomeIcon minWidth={26} fontSize={17}>far fa-download</FontAwesomeIcon>,
            translateKey: "user.page.export",
            onClick: requestUserExport,
        });
    }

    if (isAuthorized(me, allowedRoles(roles.Admin))) {
        bulkActionButtons.push({
            icon: <FontAwesomeIcon>far fa-pen</FontAwesomeIcon>,
            translateKey: "user.page.bulkedit",
            onClick: openBulkEditModal,
        });
    }

    if (isAuthorized(me, allowedRoles(roles.Admin))) {
        bulkActionButtons.push({
            icon: <FontAwesomeIcon>far fa-archive</FontAwesomeIcon>,
            translateKey: archive ? "user.page.bulkunarchive" : "user.page.bulkarchive",
            onClick: () => archive ?
                dispatch(requestUsersToggleArchive({
                    ids: users.filter(v => v.isSelected).map(v => v.id)
                })) : openBulkArchiveModal()
        });
    }

    const toggles = [
        {
            icon: <FontAwesomeIcon fontSize={19}
                                   color={archive ? "secondary" : undefined}>
                far fa-user</FontAwesomeIcon>,
            translateKey: "user.page.user",
            active: !archive,
            onClick: () => setArchive(false),
        },
        {
            icon: <FontAwesomeIcon fontSize={19}
                                   color={!archive ? "secondary" : undefined}>
                far fa-archive</FontAwesomeIcon>,
            translateKey: "user.page.archive",
            active: archive,
            onClick: () => setArchive(true),
        },
    ];

    const toolbar = (
        <Toolbar
            numberSelected={selected}
            toggles={toggles}
            actionButtons={actionButtons}
            bulkActionButtons={bulkActionButtons}
            onSearch={handleSearch}
            onFilter={openFilterModal}
            onDeselect={props.deselectAllUser}
        />
    );
    return (
        <Page activePage={menu.users.key} toolbar={toolbar}>
            <FilterToolbar/>
            <div className={classes.contentContainer}>
                <UserTable
                    page={page}
                    rowsPerPage={rowsPerPage}
                    archive={archive}
                    onSelect={selectUser}
                    onSelectAll={selectAllUser}
                    onPageChange={changePage}
                    onRowsPerPageChange={changeRowPerPage}
                    onEdit={user => {
                        setUser(user);
                        openEditModal();
                    }}
                    onHistory={user => {
                        setUser(user);
                        openHistoryModal();
                    }}
                    onArchive={user => {
                        setUser(user);
                        openArchiveModal();
                    }}
                    onUnArchive={user => {
                        setUser(user);
                        props.requestUserToggleArchive({id: user.id});
                    }}
                    onPasswordChangeByAdmin={user => {
                        setUser(user);
                        openChangePasswordModal();
                    }}
                />
            </div>
            {historyModal && <HistoryModal id={user.id} onClose={closeHistoryModal}/>}
            {filterModal && <UserFilterModal onClose={closeFilterModal}/>}
            {editModal && <UserModal tree={tree} user={user} onClose={closeEditModal} archive={archive}/>}
            {bulkEditModal && <UserBulkEditModal onClose={closeBulkEditModal}/>}
            {addModal && <UserAddModal onClose={closeAddModal}/>}
            {changePasswordModal && (
                <ChangePasswordModal onClose={closeChangePasswordModal} yourPasswordNeeded
                                     onConfirm={handleChangePasswordByAdmin}/>
            )}
            {archiveModal && (
                <ArchiveModal
                    title="User"
                    onClose={closeArchiveModal}
                    onArchive={comment => {
                        props.requestUserToggleArchive({id: user.id, comment});
                    }}
                />
            )}
            {bulkArchiveModal && <ArchiveModal
                title="User"
                onClose={closeBulkArchiveModal}
                onArchive={comment => {
                    dispatch(requestUsersToggleArchive({
                        ids: users.filter(v => v.isSelected).map(v => v.id),
                        comment
                    }))
                }}
            />}
            <Snackbar translateKey="user.page.snackbarExport"
                      onClose={hideExportSnackbar}
                      open={exportSnackbar}/>
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestUsers,
            requestDeleteUser,
            requestUserToggleArchive,
            selectUser,
            selectAllUser,
            deselectAllUser,
            requestUserExportXlsx,
            setTextArray,
            updateFilterAPICall,
            requestChangePasswordForUser,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        selected: state.users.selected,
        users: state.users.entities,
        costcenters: state.costcenters.entities,
        me: state.users.me,
        filter: state.filter,
    };
}

// UserListPage.propTypes = {};

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(UserListPage);
