import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { grouping, tripGeofenceGroupingArray } from "../../../../models/Grouping";
import { fromToByPeriod } from "../../../../managers/timeManager";
import { periods } from "../../../../models/Period";
import { updateFilterAPICall } from "../../../../actions/actionFilter";
import { requestCreateGeofenceAnalysis, requestEditGeofenceAnalysis } from "../../../../actions/actionGeofences";
import Grid from "@material-ui/core/Grid";
import PeriodDatePicker from "../../../common/selects/datePickers/PeriodDatePicker";
import VehicleSelect from "../../../common/selects/specificSelects/VehicleSelect";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EditModal from "../../../common/modals/CustomModal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import GeofenceSelect from "../../../common/selects/specificSelects/GeofenceSelect";
import { getTranslation } from '../../../../translations/translations.js';
import { useTranslation } from "react-i18next";

// Modal types
export const ADD_TYPE = 0;
export const EDIT_TYPE = 1;

const useStyles = makeStyles((theme) => ({
    createButton: {
        marginTop: theme.spacing(1),
    },
}));


const GeofencesAnalysisModal = (props) => {
    const todayDate = new Date();
    const modalType = props.modalType ? props.modalType : ADD_TYPE;
    const modalTitle = (modalType === ADD_TYPE) ? getTranslation("geofenceAnalysis.createAnalysis") : getTranslation("geofenceAnalysis.editAnalysis");
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.filter.api);
    const me = useSelector((state) => state.users.me);
    const { t } = useTranslation();

    const [chosenGrouping, setGrouping] = useState(grouping.vehicle);

    // GeofenceAnalysis data
    const gaId = props.geofenceAnalysis ? props.geofenceAnalysis.id : '';
    const gaTitle = props.geofenceAnalysis ? props.geofenceAnalysis.title : '';
    const gaFrom = props.geofenceAnalysis ? new Date(props.geofenceAnalysis.from) : new Date();
    const gaTo = props.geofenceAnalysis ? new Date(props.geofenceAnalysis.to) : new Date(todayDate.getFullYear(), todayDate.getMonth(), 0);
    const gaVehicleIds = props.geofenceAnalysis ? props.geofenceAnalysis.geofenceAnalysisVehicles.map(
        gav => (gav.id)) : [];
    const gaGeofenceIds = props.geofenceAnalysis ? props.geofenceAnalysis.geofenceAnalysisGeofences.map(gag => gag.id) : [];

    const [geofenceAnalysisTitle, setGeofenceAnalysisTitle] = useState(gaTitle);

    const { onClose } = props;

    const [selectedVehicleIds, setSelectedVehicleIds] = useState((state) => filter.vehicleIds);
    const [selectedGeofenceIds, setSelectedGeofenceIds] = useState((state) => filter.geofenceIds);
    const rangeStartDate = new Date();
    const rangeEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0);

    const currentDay = fromToByPeriod(periods.month.id, new Date());
    const [dateRange, setDateRange] = useState({
        period: periods.month.id,
        from: new Date(),
        to: (filter && filter.to && filter.period === periods.month.id) ? new Date(filter.to) : currentDay.to,
    });
    const { from, to, period } = dateRange;

    useEffect(() => {
        const dateOptions = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" };  

        // Init date range
        switch (modalType) {
            case ADD_TYPE:
                rangeStartDate.setDate(0);
                rangeStartDate.setMonth(rangeStartDate.getMonth() - 1);
                rangeStartDate.setHours(0, 0, 0, 0);

                rangeEndDate.setHours(23, 59, 59, 999);

                handleDateChange(periods.month.id, rangeStartDate.toISOString(), rangeEndDate.toISOString());
                filter.from = rangeStartDate;
                filter.to = rangeEndDate;
                break;

            case EDIT_TYPE:
                handleDateChange(periods.range.id,
                    getDateWithLocalTimezoneOffset(gaFrom),
                    getDateWithLocalTimezoneOffset(gaTo)
                );
                filter.from = getDateWithLocalTimezoneOffset(gaFrom);
                filter.to = getDateWithLocalTimezoneOffset(gaTo);
                //handleVehiclesChange(gaVehicleIds);
                break;

            default:
                break;

        }

    }, []);

    useEffect(() => {
        dispatch(updateFilterAPICall({ grouping: chosenGrouping }));
    }, [chosenGrouping]);

    function getDateWithLocalTimezoneOffset(utcTime) {
        var localtime = new Date(utcTime);
        localtime.setMinutes(localtime.getMinutes() - localtime.getTimezoneOffset());

        return localtime;
    }
    function handleCreateGeofencesAnalysis() {
        if (geofenceAnalysisTitle
            && selectedGeofenceIds
            && selectedGeofenceIds.length > 0
            && selectedVehicleIds
            && selectedVehicleIds.length > 0
            && from && to) {

            dispatch(
                requestCreateGeofenceAnalysis({
                    ...filter,
                    title: geofenceAnalysisTitle,
                    geofenceAnalysisGeofences: selectedGeofenceIds,
                    geofenceAnalysisVehicles: selectedVehicleIds,
                    from: from,
                    to: to,
                })
            );
            onClose();
            window.location.reload();
        }
         
    }

    function handleEditGeofencesAnalysis() {
        if (gaId
            && geofenceAnalysisTitle
            && selectedGeofenceIds
            && selectedGeofenceIds.length > 0
            && selectedVehicleIds
            && selectedVehicleIds.length > 0
            && from && to) {

            dispatch(
                requestEditGeofenceAnalysis({
                    ...filter,
                    id: gaId,
                    title: geofenceAnalysisTitle,
                    geofenceAnalysisGeofences: selectedGeofenceIds,
                    geofenceAnalysisVehicles: selectedVehicleIds,
                    from: from,
                    to: to,
                })
            );
            onClose();
            window.location.reload();
        }
    }

    function handleDateChange(period, from, to) {
        setDateRange({ period, from, to });
    }

    function handleVehiclesChange(vehicleIds) {
        setSelectedVehicleIds(vehicleIds);
        dispatch(updateFilterAPICall({ vehicleIds }));
    }

    function handleGeofenceChange(geofenceIds) {
        setSelectedGeofenceIds(geofenceIds);
        dispatch(updateFilterAPICall({ geofenceIds }));
    }

    let showVehiclesSelect = null,
        showGeofencesSelect = null,
        showDriversSelect = null;
    let isButtonDisabled = !selectedVehicleIds || selectedVehicleIds.length === 0
        || !selectedGeofenceIds || selectedGeofenceIds.length === 0 || geofenceAnalysisTitle === '';
    showGeofencesSelect = (
        <>
            <Grid item xs={3}>
                Geofences{" "}
            </Grid>
            <Grid item xs={9}>
                <GeofenceSelect
                    multiple
                    onChange={handleGeofenceChange}
                    value={selectedGeofenceIds}
                />
            </Grid>
        </>
    );

    showVehiclesSelect = (
        <>
            <Grid item xs={3}>
                Fahrzeuge{" "}
            </Grid>
            <Grid item xs={9}>
                <VehicleSelect
                    multiple
                    onChange={handleVehiclesChange}
                    value={selectedVehicleIds}
                />
            </Grid>
        </>
    );

    const content = (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                Titel
            </Grid>
            <Grid item xs={9}>
                <TextField
                    variant="outlined"
                    value={geofenceAnalysisTitle}
                    onChange={e => {
                        setGeofenceAnalysisTitle(e.target.value)
                    }} />
            </Grid>
            <Grid item xs={3}>
                Zeitraum
            </Grid>

            <Grid item xs={9}>
                <PeriodDatePicker
                    period={period || periods.month.id}
                    onDateChange={handleDateChange}
                    startDate={from}
                    endDate={to}
                    disableFuture={false}
                />
            </Grid>

            {showVehiclesSelect}

            {showGeofencesSelect}

            {showDriversSelect}

            <Grid item xs={12}>
                <Button
                    onClick={modalType === ADD_TYPE ? handleCreateGeofencesAnalysis : handleEditGeofencesAnalysis}
                    disabled={isButtonDisabled}
                    className={classes.createButton}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    {modalType === ADD_TYPE && (t("geofenceAnalysis.createAnalysis"))}
                    {modalType === EDIT_TYPE && (t("geofenceAnalysis.editAnalysis"))}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <EditModal
            onClose={onClose}
            title={modalTitle}
            contents={[content]}
        />
    );
};

// GeofencesAnalysisModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     graphicReport: PropTypes.bool,
// };

export default GeofencesAnalysisModal;
