import { Polygon } from "leaflet";
import {
    RECEIVE_GEOFENCES_NAME_ID,
    RECEIVE_GEOFENCE_SUBSCRIPTION_DATE,
    CHECK_LOCALLY_CLICKED_GEOFENCE_VEHICLE,
    RECEIVE_ASSIGNED_GEOFENCE_VEHICLES,
    RECEIVE_UNASSIGNED_GEOFENCE_VEHICLES,
    EXCHANGE_LOCALLY_GEOFENCE_VEHICLES,
    SEARCH_LOCALLY_GEOFENCE_VEHICLES,
    RECEIVE_GEOFENCES_OF_CURRENT_VEHICLE,
    RECEIVE_GEOFENCES_EVENTS_OF_CURRENT_TRIP,
    RECEIVE_GEOFENCES_OF_CURRENT_TRIP,
    RECEIVE_GEOFENCE_ANALYSES,
    RECEIVE_GEOFENCE_ANALYSIS_EVENTS,
    RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING,
    RECEIVE_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED,
    RECEIVE_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS,
    RECEIVE_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE,
    RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION,
    RECEIVE_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE,
    SEARCH_LOCALLY_GEOFENCES
} from "../actions/actionGeofences";
import { getPagedEntities } from "../managers/reducerManager";

/**
 * Geofence Reducer initial state elements
 */
const initialState = {
    /**
     * Contains a list of all geofence events created to current trip. 
     * @param {List<Object>} eventsOfCurrentTrip Contains geofence data.
     * @param {string} eventsOfCurrentTrip.geofenceid The id of the geofence.
     * @param {int} eventsOfCurrentTrip.count The count of trip events.
     * @param {string} eventsOfCurrentTrip.geofenceName The name of Geofence.
     * @param {string} eventsOfCurrentTrip.durationOfTripInGeofence Total duration of car in a geofence in seconds.
     * @param {Object} eventsOfCurrentTrip.tripEvents The events for current geofence (a set of entering/leaving event, one element => one ky of object).
     * @param {long} eventsOfCurrentTrip.tripEvents.durationSinceLastEnter Duration in seconds since the last entrance of car in geofence.
     * @param {Object} eventsOfCurrentTrip.tripEvents.enteringEventIntersection Contains the entering event (or started event if it started in this geofence).
     * @param {Object} eventsOfCurrentTrip.tripEvents.enteringEventIntersection.before Contains the data of the coordinate just before entering in geofence.
     * @param {Object} eventsOfCurrentTrip.tripEvents.enteringEventIntersection.intersection Contains the data of the coordinate that intersects the geofence.
     * @param {Object} eventsOfCurrentTrip.tripEvents.enteringEventIntersection.after Contains the data of the first coordinate after entering in geofence.
     * @param {long} eventsOfCurrentTrip.tripEvents.enteringEventIntersection.intersection.timestamp The timestamp when the intersection happened, the same is for before and after.
     * @param {double} eventsOfCurrentTrip.tripEvents.enteringEventIntersection.intersection.lat The latitude when the intersection happened, the same is for before and after.
     * @param {double} eventsOfCurrentTrip.tripEvents.enteringEventIntersection.intersection.long The longitude when the intersection happened, the same is for before and after.
     */
    eventsOfCurrentTrip: [],

    /**
     * Contains the geofences that were assigned to a particular trip (when trip happened). 
     * @param {List<Object>} tripGeofences Contains all geofences of particular trip.
     * @param {string} tripGeofences.id The id of the geofence.
     * @param {string} tripGeofences.Title The title of the geofence.
     * @param {List<List<double>>} tripGeofences.coordinates The longitude/lattitude coordinates.
     */
     geofencesOfCurrentTrip: [],

    /**
     * Contains the geofences that are assigned to a particular vehicle. 
     * @param {Object} vehicleGeofences Contains all geofences of particular vehicle.
     * @param {string} vehicleGeofences.vehicleId The id of vehicle.
     * @param {List<Object>} vehicleGeofences.geofences The list of geofences assigned to vehicle.
     * @param {string} vehicleGeofences.geofences.id The id of the geofence.
     * @param {string} vehicleGeofences.geofences.Title The title of the geofence.
     * @param {List<List<double>>} vehicleGeofences.geofences.coordinates The longitude/lattitude coordinates
     */
    geofencesOfCurrentVehicle: {},

    /** 
     * Contains the vehicles that are assigned to a particular geofence
     * @param {List<Object>} assignedGeofenceVehicles A list that contains assigned geofence vehicles
     * @param {string} id The id of the geofence-vehicle element 
     * @param {string} geofenceId The id of geofence
     * @param {string} vehicleId The id of vehicle
     * @param {string} vehicleName The name of vehicle
     * @param {boolean} isAssignedGeofenceVehicle Contains a boolean value if the current element is assigned or not in the React app view
    */
    assignedGeofenceVehicles: [],
    
    /** 
     * Contains the vehicles that are not assigned to a particular geofence
     * @param {List<Object>} unAssignedGeofenceVehicles A list that contains unassigned geofence vehicles     
     * @param {string} id The id of the geofence-vehicle element 
     * @param {string} geofenceId The id of geofence
     * @param {string} vehicleId The id of vehicle
     * @param {string} vehicleName The name of vehicle
     * @param {boolean} isAssignedGeofenceVehicle Contains a boolean value if the current element is assigned or not in the React app view
    */
    unAssignedGeofenceVehicles: [],
    
    /** 
     * Contains the vehicles that are assigned to a particular geofence
     * @param {List<Object>} geofenceNameIds A list that contains all geofence Names and Ids
     * @param {string} id The id of geofence
     * @param {string} name The name of geofence
    */
    geofencesNameId: [],

    /** 
     * Contains the subscription date of the current mandator
     * @param {string} geofenceSubscriptionDate The subscription date as a string
    */
    geofenceSubscriptionDate: [],

    /**
     * Contains a list of GeofenceVehicleView objects that represent the vehicles list which are actually 
     * entering geofences in a certain GeofenceAnalysis.
     * @param {List<Object>} geofenceAnalysisVehicesEntering GeofenceVehicleView objects which contain information about the entering vehicles.
    */
    geofenceAnalysisVehiclesEntering: [],

    /**
     * Contains a number representing the number of unique vehicles entering geofences in a particular geofence analysi.
     * @param {int} geofenceAnalysisUniqueVehiclesEntered the integer value.
    */
    geofenceAnalysisUniqueVehiclesEntered: [],

    /**
     * Contains a number representing all geofence entering events happening inside of a geofence analysis.
     * @param {int} geofenceAnalysisTotalEntryEvents the integer value.
    */
    geofenceAnalysisTotalEntryEvents: [],

    /**
     * Contains a datetime representing the combined time all vehicles were inside teh geofences of a geofence analysis.
     * @param {string} geofenceAnalysisTotalTimeIniside the integer value.
    */
    geofenceAnalysisTotalTimeInside: [],

    /**
     * Contains a List of objects representing information about a geofence analysis vehicles in regards to a geofence analysis
     * @param {List<Object>} geofenceAnalysisVehicesEnteringDuration VehiclesEnteringDurationView objects which contain information about the entering vehicles.
    */
    geofenceAnalysisVehiclesEnteringDuration: [],

    /**
     * Contains the GeofenceAnalysis to be updated.
     * @param {object} geofenceAnalysisToggleArchive the geofence analysis object to be changed form active to archived.
    */
    geofenceAnalysisToggleArchive: [],
    
    /**
     * Contains a list of geofence-analysis objects that are connected with a particular mandator
     * @param {List<Object>} geofenceAnalyses Contains geofence-analyses data.
     * @param {string} geofenceAnalyses.id The id of a geofence-analysis
     * @param {string} geofenceAnalyses.title The name of the geofence-analysis
     * @param {Object} geofenceAnalyses.from The start date of the timeframe of the geofence-analysis
     * @param {Object} geofenceAnalyses.to The end date of the timeframe of the geofence-analysis
     * @param {Object} geofenceAnalyses.isCalculated The date when the analysis calculation was compelted, null in case it is still pending
     * @param {string} geofenceAnalyses.userId The user id who the geofence-analysis belongs to
     * @param {List<Object>} geofenceAnalyses.geofenceAnalysisVehicles A list that contains all vehicles included in the geofence-analysis
     * @param {List<Object>} geofenceAnalyses.geofenceAnalysisGeofences A list that contains all geofences included in the geofence-analysis
    */
    geofenceAnalyses: [],

    /**
     * Contains the current geofence-analysis which needs to be persisted for a particular mandator.
     * @param {string} id The id of a geofence-analysis
     * @param {string} title The name of the geofence-analysis
     * @param {Object} from The start date of the timeframe of the geofence-analysis
     * @param {Object} to The end date of the timeframe of the geofence-analysis
     * @param {Object} isCalculated The date when the analysis calculation was compelted, null in case it is still pending
     * @param {List<Object>} geofenceAnalysisVehicles A list that contains all vehicles included in the geofence-analysis
     * @param {List<Object>} geofenceAnalysisGeofences A list that contains all geofences included in the geofence-analysis
    */
    geofenceAnalysis: [],

    /**
     * Contains all events covered by a geofence analysis in a list of GeofenceAnalysisEventsView objects.
     * @param {List<GeofenceAnalysisEventsView>} geofenceAnalysisEvents A list that contains all events covered by the last polled geofence analysis
    */
    geofenceAnalysisEvents: []
};

export const geofenceDetail = (state = initialState, { type, data }) => {
    let geofenceVehicles;
    let searchTerm;
    switch (type) {
        case SEARCH_LOCALLY_GEOFENCES:
            // refactor
            let geofences = state[data.module];
            searchTerm = data.searchTerm;

            if (data.module === "geofencesOfCurrentVehicle")
                geofences = geofences["geofences"]

            geofences = geofences.slice();

            geofences.map(g => {
                if (!g.title.toLowerCase().includes(searchTerm.toLowerCase()))
                    g.isHidden = true;
                else
                    g.isHidden = false;
                return g;
            });

            if (data.module === "geofencesOfCurrentVehicle")
                return {
                    ...state,
                    geofencesOfCurrentVehicle: {
                        ...state.geofencesOfCurrentVehicle,
                        geofences: geofences
                    }
                }

            return {
                ...state,
                [data.module]: geofences
            }
        case RECEIVE_GEOFENCES_OF_CURRENT_TRIP:
            return {
                ...state,
                geofencesOfCurrentTrip: data
            }
        case RECEIVE_GEOFENCES_EVENTS_OF_CURRENT_TRIP:
            return {
                ...state,
                eventsOfCurrentTrip: data
            }
        case RECEIVE_GEOFENCES_OF_CURRENT_VEHICLE:
            return {
                ...state,
                geofencesOfCurrentVehicle: data
            }
        case SEARCH_LOCALLY_GEOFENCE_VEHICLES:
            geofenceVehicles = state[data.module].slice();
            searchTerm = data.searchTerm;

            geofenceVehicles.map(gv => {
                // if it is an assigned vehicle and does not contain the search term, hide it. Otherwise, unhide it. 
                if (!gv.vehicleName.toLowerCase().includes(searchTerm.toLowerCase()))
                    gv.isHidden = true;
                else
                    gv.isHidden = false;
                return gv;
            });

            return {
                ...state,
                [data.module]: geofenceVehicles
            }
        case EXCHANGE_LOCALLY_GEOFENCE_VEHICLES:
            let countCheckedGeofenceVehicles = state[data.fromModule].filter(el => el.isLocallyChecked).length;
            if (countCheckedGeofenceVehicles == 0)
                return state;

            let fromGeofenceVehicles = state[data.fromModule].slice();
            let toGeofenceVehicles = state[data.toModule].slice();
            let geofenceVehiclesToDelete = [];

            fromGeofenceVehicles.forEach(el => {
                let newEl = { ...el };

                if (newEl.isLocallyChecked) {
                    newEl.isLocallyChecked = !newEl.isLocallyChecked;
                    newEl.isAssignedGeofenceVehicle = !newEl.isAssignedGeofenceVehicle;
                    geofenceVehiclesToDelete.push(el);
                    toGeofenceVehicles.push(newEl);
                };
            });

            fromGeofenceVehicles = fromGeofenceVehicles.filter(el => !geofenceVehiclesToDelete.includes(el));

            return {
                ...state,
                [data.fromModule]: fromGeofenceVehicles,
                [data.toModule]: toGeofenceVehicles
            };

        case CHECK_LOCALLY_CLICKED_GEOFENCE_VEHICLE:
            geofenceVehicles = state[data.module].slice();

            // if 'check all' button is clicked
            if (data.toCheck == 'checked' || data.toCheck == 'unchecked' || data.toCheck == 'indeterminate' ) {
                let updatedgeofenceVehicles = geofenceVehicles.map(gv => {
                    let newgv = { ...gv };
                    if (!gv.isHidden) {
                        if (data.toCheck == "unchecked") {
                            newgv.isLocallyChecked = true;
                        } else {
                            newgv.isLocallyChecked = false;
                        }
                    }
                    return newgv;
                })

                return {
                    ...state,
                    [data.module]: updatedgeofenceVehicles
                }
            }

            let indexOfVehicle = geofenceVehicles.findIndex(cv => cv.vehicleId == data.toCheck);
            let vehicle = { ...geofenceVehicles[indexOfVehicle] };

            vehicle.isLocallyChecked = !vehicle.isLocallyChecked;
            geofenceVehicles[indexOfVehicle] = vehicle;

            return {
                ...state,
                [data.module]: geofenceVehicles
            };
        case RECEIVE_GEOFENCES_NAME_ID:
            return {
                ...state,
                geofencesNameId: data
            }
        case RECEIVE_GEOFENCE_SUBSCRIPTION_DATE:
            return {
                ...state,
                geofenceSubscriptionDate: data
                }
        case RECEIVE_ASSIGNED_GEOFENCE_VEHICLES:
            return {
                ...state,
                assignedGeofenceVehicles: data,
            }
        case RECEIVE_UNASSIGNED_GEOFENCE_VEHICLES:
            return {
                ...state,
                unAssignedGeofenceVehicles: data,
            }
        case RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING:
            return {
                ...state,
                geofenceAnalysisVehiclesEntering: data,
            }
        case RECEIVE_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED:
            return {
                ...state,
                geofenceAnalysisUniqueVehiclesEntered: data,
            }
        case RECEIVE_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS:
            return {
                ...state,
                geofenceAnalysisTotalEntryEvents: data
            }
        case RECEIVE_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE:
            return {
                ...state,
                geofenceAnalysisTotalTimeInside: data
            }
        case RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION:
            return {
                ...state,
                geofenceAnalysisVehiclesEnteringDuration: data
            }
        case RECEIVE_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE:
            return {
                ...state,
                geofenceAnalysisToggleArchive: data
            }
        case RECEIVE_GEOFENCE_ANALYSES:
            return {
                ...state,
                geofenceAnalyses: data,
            }
        case RECEIVE_GEOFENCE_ANALYSIS_EVENTS:
            return { ...state,
                geofenceAnalysisEvents: getPagedEntities(state, data)
            }
        default:
            return state;
    }
}