import { useEffect } from "react";
import classNames from "classnames";

import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import EditModal from "../CustomModal";
import { Form } from "antd";
import ReservationWizardStepHeader from "../../../reservation/ReservationWizardStepHeader";
import ReservationStepsContent from "../../../reservation/newReservationAddModal/ReservationStepsContent";
import { DialogTitle } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { wizardformItemLayout, wizardModalStyles } from "./wizardModal.utils";
import { makeStyles, createStyles } from "@material-ui/core";
import { MODAL_TYPES } from "../modal.utils";
import { useTranslation } from "react-i18next";

export const WIZARD_TYPES = {
  trip: {
    name: "trip",
    numOfPages: 3,
  },
  reservation: {
    name: "reservation",
    numOfPages: 4,
  },
  vehicle: {
    name: "vehicle",
    numOfPages: 4,
  },
  mandator: {
    name: "mandator",
    numOfPages: 5,
  },
  mandatorSuperUser: {
    name: "mandatorSuperUser",
    numOfPages: 1,
  },
  user: {
    name: "user",
    numOfPages: 3,
  },
};

const useStyles = makeStyles((theme) => createStyles(wizardModalStyles(theme)));

const formItemLayout = wizardformItemLayout;

const WizardModal = (props) => {
  const {
    onClose,
    canBack,
    pages,
    submittable,
    title,
    onBack,
    canNext,
    isVehicleAddModalValidated,
    onNext,
    step,
    form,
    wizardType,
    isReservationChanged,
    classList,
    isValidated,
    reservationAddState,
    renderSteps,
    getPageTitle,
    validateInputs,
    customRuleMessage,
    formType,
    roleId,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    pages && pages[step] && pages[step].inputs && updateInputValues();
  }, [pages && [pages[step] && pages[step].inputs]]);

  const updateInputValues = () => {
    pages &&
      pages[step].inputs.forEach((input) => {
        if (typeof input.value !== "undefined") {
          form.setFieldsValue({ [input.key]: input.value });
        }
      });
  };

  const onHandleNext = async () => {
    if (submittable || (formType === MODAL_TYPES.addUser && step === 1 && roleId) || (isVehicleAddModalValidated && isVehicleAddModalValidated())) {
      const values = await form.getFieldsValue();
      onNext(values);
    } else {
      const { validateFields } = form;
      validateFields({});
    }
  };

  const IsDisabled = () => {
    if (wizardType === WIZARD_TYPES.reservation.name) {
      return !isReservationChanged;
    } else if (wizardType === WIZARD_TYPES.user.name) {
      return !isValidated;
    } else {
      return !canNext;
    }
  };

  const dynamicStepButton =
    WIZARD_TYPES[wizardType].numOfPages - 1 !== step ? (
      <Button size="small" onClick={onHandleNext}>
        {t("common.modal.wizardModal.next")}
        <KeyboardArrowRight />
      </Button>
    ) : (
      <Button
        variant="contained"
        color="primary"
        className={classes.completeButton}
        onClick={onHandleNext}
        disabled={IsDisabled()}
      >
        {t("common.modal.wizardModal.complete")}
      </Button>
    );

  const content = () => {
    if (wizardType === WIZARD_TYPES.reservation.name) {
      return (
        <Form form={form} className={classes.form} {...formItemLayout}>
          {renderSteps(step)}
        </Form>
      );
    } else {
      return (
        <Form form={form} className={classes.form} {...formItemLayout}>
          {pages[step] &&
            pages[step].inputs &&
            pages[step].inputs.map((input, idx) => {
              let rules = [];
              if (input.validation.type)
                rules.push({ type: input.validation.type });
              if (input.validation.required)
                rules.push({
                  required: input.validation.required,
                  message: input.validation.description,
                });
              if (validateInputs && input.customRule) {
                rules.push(input.customRule);
                return (
                  <Form.Item
                    name={input.key}
                    rules={rules}
                    className={classes.tripFormInput}
                    label={input && input.name}
                    key={idx}
                    value={input.value}
                    {...validateInputs(customRuleMessage, input.key=== "password" && "warning")}
                  >
                    {input.component}
                  </Form.Item>
                );
              }

              return (
                <Form.Item
                  name={input.key}
                  rules={rules}
                  className={classes.tripFormInput}
                  label={input && input.name}
                  key={idx}
                  value={input.value}
                >
                  {input.component}
                </Form.Item>
              );
            })}
        </Form>
      );
    }
  };

  const dynamicHeader = () => {
    switch (wizardType) {
      case WIZARD_TYPES.reservation.name:
        return (
          <ReservationWizardStepHeader step={step} title={getPageTitle(step)} />
        );
      case WIZARD_TYPES.user.name:
      case WIZARD_TYPES.mandatorSuperUser.name:
      case WIZARD_TYPES.mandator.name:
      case WIZARD_TYPES.vehicle.name:
      case WIZARD_TYPES.trip.name:
        return <DialogTitle>{pages[step] && pages[step].title}</DialogTitle>;
      default:
        throw new Error(
          `unhandled wizard type of ${wizardType} in WizardModal`
        );
    }
  };

  const basicDataContent = (
    <div className={classList ? classList.root : classes.root}>
      {dynamicHeader()}
      {wizardType === WIZARD_TYPES.reservation.name && step > 0 && (
        <ReservationStepsContent
          step={step}
          reservationAddState={reservationAddState}
        />
      )}
      <DialogContent
        className={classList ? classList.content : classes.content}
      >
        {content()}
      </DialogContent>
      <MobileStepper
        steps={WIZARD_TYPES[wizardType].numOfPages}
        position="bottom"
        activeStep={step}
        className={classes.mobileStepper}
        nextButton={dynamicStepButton}
        backButton={
          <div>
            <Button
              className={classNames({ [classes.hide]: step === 0 })}
              size="small"
              onClick={onBack}
              disabled={!canBack()}
            >
              <KeyboardArrowLeft />
              {t("common.modal.wizardModal.back")}
            </Button>
          </div>
        }
      />
    </div>
  );
  const contents = [basicDataContent];
  return <EditModal onClose={onClose} title={title} contents={contents} />;
};

WizardModal.defaultProps = {
  canNext: true,
};

export default WizardModal;
