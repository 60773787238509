import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Timeline from "./Timeline";
//actions
import { requestVehicles } from "../../actions/actionVehicle";
import { requestTripVehiclesReport } from "../../actions/actionReport";
import moment from "moment";
import ViewTypes from "./ViewTypes";
import {customTranslation, getTranslation} from "../../translations/customTranslation";
import { withTranslation } from "react-i18next";


const VehicleWeekTimeline = props => {
    const { filter, from, to, vehicles, trips, viewType, weeklyTrips, setTrip, onTrip, me } = props
    useEffect(() => {
        props.requestVehicles({ ...filter, IsArchived: false, PerPage: 1000 });
        props.requestTripVehiclesReport({ from, to });
    }, [filter, viewType, from, to]);
    const mappedVehicles = vehicles.map(v => {
        return { id: v.id, name: v.name };
    });
    let mappedTrips = [];
    if (weeklyTrips)
        weeklyTrips.forEach((vTrip, i) => {
            vTrip.days.forEach(day => {
                mappedTrips.push({
                    id: day.day + "#" + i,
                    resource: vTrip.vehicle.id,
                    text:
                        Math.round(day.mileageInMeters / 1000) + " km " +
                        "(" + day.privateTripCount + "/" + day.businessTripCount + "/" + day.driveToWorkTripCount + ")",
                    start: moment(day.day)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(day.day)
                        .local()
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss"),
                    showPopover: false,
                    bubbleHtml: `${props.t("model.triptype.private")}: ${day.privateTripCount} <br /> 
${getTranslation(customTranslation.business, me)}: ${day.businessTripCount} <br /> 
${getTranslation(customTranslation.driveToWork, me)}: ${day.driveToWorkTripCount}`,
                });
            });
        });
    return <Timeline setTrip={setTrip}
                     onTrip={onTrip}
                     items={mappedTrips}
                     groups={mappedVehicles}
                     viewType={ViewTypes.Week}
                     from={from}/>;
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestVehicles, requestTripVehiclesReport }, dispatch);
}
function mapStateToProp(state) {
    return {
        me: state.users.me,
        vehicles: state.vehicles.entities,
        weeklyTrips: state.reports.vehicles,
        trips: state.trips.entities,
        filter: state.filter.api,
    };
}
export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withTranslation()(VehicleWeekTimeline));