import { AnyAction } from "redux";

import {
  receiveUnAuthoriseUser,
  receiveLogout,
} from "../../actions/actionAuthentication";

import {
  receiveInspection,
  receiveAddInspection,
  receiveDeleteInspection,
  receiveNextInspection
} from "../../actions/actionInspection";
import { receiveTripReport } from "../../actions/actionReport";
import {
  receiveVehicleService,
  receiveAddVehicleService,
  receiveDeleteVehicleService,
  receiveNextVehicleService,
} from "../../actions/actionVehicleService";
import {
  receiveVehicles,
  receiveAllVehicles,
  receiveVehiclesLastPosition,
  receiveVehicleById,
  receiveAddVehicle,
  receiveAddCalibration,
  receiveUpdateVehicle,
  receiveUpdateVehicles,
  selectVehicle,
  selectAllVehicles,
  deselectAllVehicle,
  loadVehicle,
  receiveDeleteVehicle,
  receiveDeleteCalibration,
  receiveVehicleToggleArchive,
  receiveVehiclesToggleArchive,
  receiveOpenTrack,
  receiveCalibrations,
} from "../../actions/actionVehicle";

import { receiveAvailableVehicles } from "../../actions/actionReservation";

import { TripResponseWithMoreInfo } from "../../types/trip/tripResponse.types";

import {
  VehicleLastPositionResponse,
  VehicleResponseInfo,
  VehicleCalibrationResponse,
  VehicleResponse,
} from "../../types/vehicle/vehicleResponse.types";
import {
  VehicleInspectOrServiceResponse,
  VehicleInspectionResponse,
} from "../../types/vehicle/vehicleResponse.types";

import {
  addEntity,
  deleteEntity,
  deleteEntities,
  deselectAllEntities,
  getEntityById,
  getPagedEntities,
  getAllPagedEntities,
  loadEntities,
  selectAllEntities,
  updateEntities,
  updateEntity,
  updateSelection,
} from "../../managers/reducerManager";


export type VehicleState = {
  readonly current: VehicleInspectOrServiceResponse;
  readonly entities: VehicleResponseInfo[];
  readonly allEntities: VehicleResponseInfo[];
  readonly entitiesWithLastPosition: VehicleLastPositionResponse[];
  readonly calibrations: { entities: VehicleCalibrationResponse[] };
  readonly loading: boolean;
  readonly pageLoading: boolean;
  readonly openTrip: TripResponseWithMoreInfo;
  readonly reservation: VehicleResponse;
};

const VEHICLE_INITIAL_STATE: VehicleState = {
  current: {} as VehicleInspectOrServiceResponse,
  entities: [],
  allEntities: [],
  entitiesWithLastPosition: [],
  calibrations: {
    entities: [],
  },
  openTrip: { positions: [] } as TripResponseWithMoreInfo,
  loading: true,
  pageLoading: true,
  reservation: {
    entities: [],
    total: 0,
  },
};

const isVehicleInspectionResponse = (
  response: VehicleInspectOrServiceResponse
): response is VehicleInspectionResponse => {
  return "inspections" in response;
};

export const vehiclesDetail = (
  state = VEHICLE_INITIAL_STATE,
  action: AnyAction
) => {
  if (receiveVehicles.match(action)) {
    return getPagedEntities(state, action.data);
  }

  if (receiveAvailableVehicles.match(action)) {
    return {
      ...state,
      reservation: {
        entities: action.data.entities.map((d: VehicleResponseInfo) => {
          d.isSelected = false;
          d.visibility = true;
          d.highlighted = false;
          return d;
        }),
        total: action.data.total,
      },
    };
  }

  if (receiveAllVehicles.match(action)) {
    return getAllPagedEntities(state, action.data);
  }

  if (receiveVehiclesLastPosition.match(action)) {
    return {
      ...state,
      entitiesWithLastPosition: action.data,
      loading: false,
    };
  }

  if (receiveVehicleById.match(action)) {
    return getEntityById(state, action.data);
  }

  if (receiveAddVehicle.match(action)) {
    return addEntity(state, action.data);
  }

  if (
    receiveAddCalibration.match(action) ||
    receiveUpdateVehicle.match(action)
  ) {
    return updateEntity(state, action.data);
  }

  if (receiveUpdateVehicles.match(action)) {
    return updateEntities(state, action.data);
  }

  if (selectVehicle.match(action)) {
    return updateSelection(state, action.data);
  }

  if (selectAllVehicles.match(action)) {
    return selectAllEntities(state);
  }

  if (deselectAllVehicle.match(action)) {
    return deselectAllEntities(state);
  }

  if (loadVehicle.match(action)) {
    return loadEntities(state);
  }

  if (
    receiveDeleteVehicle.match(action) ||
    receiveDeleteCalibration.match(action) ||
    receiveVehicleToggleArchive.match(action)
  ) {
    return deleteEntity(state, action.data);
  }

  if (receiveVehiclesToggleArchive.match(action)) {
    return deleteEntities(state, action.data);
  }

  if (receiveOpenTrack.match(action)) {
    return { ...state, openTrip: action.data };
  }

  if (receiveCalibrations.match(action)) {
    return {
      ...state,
      calibrations: {
        ...state.calibrations,
        entities: action.data,
      },
    };
  }

  if (receiveTripReport.match(action)) {
    return state;
  }

  if (receiveInspection.match(action)) {
    return {
      ...state,
      current: { ...state.current, inspections: action.data },
    };
  }

  if (receiveAddInspection.match(action)) {
    if (isVehicleInspectionResponse(state.current)) {
      return {
        ...state,
        current: {
          ...state.current,
          inspections: [...state.current.inspections, action.data],
        },
      };
    }
  }

  if (receiveDeleteInspection.match(action)) {
    if (isVehicleInspectionResponse(state.current)) {
      return {
        ...state,
        current: {
          ...state.current,
          inspections: state.current.inspections.filter(
            (o) => o.id !== action.data.id
          ),
        },
      };
    }
  }

  if (receiveVehicleService.match(action)) {
    return { ...state, current: { ...state.current, services: action.data } };
  }

  if (receiveAddVehicleService.match(action)) {
    if (!isVehicleInspectionResponse(state.current)) {
      return {
        ...state,
        current: {
          ...state.current,
          services: [...state.current.services, action.data],
        },
      };
    }
  }

  if (receiveDeleteVehicleService.match(action)) {
    if (!isVehicleInspectionResponse(state.current)) {
      return {
        ...state,
        current: {
          ...state.current,
          services: state.current.services.filter(
            (o) => o.id !== action.data.id
          ),
        },
      };
    }
  }

  if (receiveNextVehicleService.match(action)) {
    return {
      ...state,
      current: { ...state.current, nextService: action.data },
    };
  }

  if (receiveNextInspection.match(action)) {
    return {
      ...state,
      current: { ...state.current, nextInspection: action.data },
    };
  }

  if (receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
    return VEHICLE_INITIAL_STATE;
  }

  return state;
};
