import { AnyAction } from "redux";

import { getEntities, loadEntities } from "../managers/reducerManager";

import { LiveLocationResponse } from "../types/liveLocation/liveLocationResponse.types";

import { receiveLiveLocation, loadLiveLocation } from "../actions/actionLiveLocation";

import { receiveLogout, receiveUnAuthoriseUser } from "../actions/actionAuthentication";


export type LiveLocationState = {
    readonly current: LiveLocationResponse,
    readonly entities: LiveLocationResponse[],
    readonly loading: boolean,
  };

const LIVELOCATION_INITIAL_STATE: LiveLocationState = {
    current: {} as LiveLocationResponse,
    entities: [],
    loading: true,
};

export const liveLocation = (state = LIVELOCATION_INITIAL_STATE, action: AnyAction): LiveLocationState => {

    if(receiveLiveLocation.match(action)) {
        return { ...state, ...getEntities(state, action.data), loading: false };
    }

    if(loadLiveLocation.match(action)) {
        return loadEntities(state);
    }

    if(receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
        return LIVELOCATION_INITIAL_STATE;
    }

    return state;
};
