import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import { EmailRequest } from "../types/email/emailRequest.types";
import { RequestSendEmail, ReceiveSendEmail, ToggleEmailSuccessMsgVisibility } from "../types/actions/actionEmail.types";

export enum EMAIL_ACTION_TYPES {
  REQUEST_SEND_EMAIL = "REQUEST_SEND_EMAIL",
  RECEIVE_SEND_EMAIL = "RECEIVE_SEND_EMAIL",
  SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED",
  TOGGLE_EMAIL_SUCCESS_MSG_VISIBILITY = "TOGGLE_EMAIL_SUCCESS_MSG_VISIBILITY",
}

// Type "any" needs to change to a more specific type later
export const requestSendEmail = withMatcher((data: EmailRequest): RequestSendEmail => {
  return createAction(EMAIL_ACTION_TYPES.REQUEST_SEND_EMAIL, data);
});

export const receiveSendEmail = withMatcher((data: any): ReceiveSendEmail => {
  return createAction(EMAIL_ACTION_TYPES.RECEIVE_SEND_EMAIL, data);
});

export const toggleEmailSuccessMsgVisibility = withMatcher((data: boolean): ToggleEmailSuccessMsgVisibility => {
  return createAction(EMAIL_ACTION_TYPES.TOGGLE_EMAIL_SUCCESS_MSG_VISIBILITY, data);
});
