import React from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { menu } from "../../../managers/pathManager";
import LoadingIndicator from "../LoadingIndicator";
import { Fade, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";


const LoadingPage = props => {
    const { authorised, me, children } = props;
    const isLoggedIn = authorised && Object.entries(me).length;
    const { t } = useTranslation();

    return (
        <div>
            {!authorised && <Redirect to={menu.login.path}/>}
            {isLoggedIn && children}
            {!isLoggedIn && (
                <Grid container justify="center" direction="column" alignItems="center">
                    <Grid item style={{ marginTop: "15%" }}>
                        <LoadingIndicator/>
                    </Grid>
                    <Grid item>
                        <h4>{t("common.loading")}</h4>
                    </Grid>
                    <Grid item>
                        <Fade in timeout={30000}>
                            <Link to={menu.login.path}>Sry somthing went wrong, go back to login</Link>
                        </Fade>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProp(state) {
    return {
        me: state.users.me,
        error: state.error,
        authorised: state.authorised,
        authentication: state.authentication,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(LoadingPage);
