import React, {useState} from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CustomModal, {modalActions} from "../common/modals/CustomModal";
import InputField from "../common/inputs/InputField";
import ContactPersonTopicSelect from "./ContactPersonTopicSelect";


const ContactPersonModal = props => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [topic, setTopic] = useState(undefined);

    const {onClose} = props;

    const isValid = () => {
        return firstName !== "" && lastName !== "" && email !== "" && topic !== undefined
    }

    const content = <Grid container spacing={2}>
        <Grid item xs={6}>
            <InputField autoFocus
                        label="Vorname"
                        variant="outlined"
                        onChange={e => setFirstName(e.target.value)}
                        value={firstName}/>
        </Grid>

        <Grid item xs={6}>
            <InputField
                label="Nachname"
                variant="outlined"
                onChange={e => setLastName(e.target.value)}
                value={lastName}/>
        </Grid>

        <Grid item xs={12}>
            <InputField
                label="E-Mail"
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                value={email}/>
        </Grid>

        <Grid item xs={12}>
            <ContactPersonTopicSelect value={topic} onChange={setTopic}/>
        </Grid>


    </Grid>;

    return (
        <CustomModal action={isValid() ? modalActions.add : modalActions.cancel} onSave={onClose} onClose={onClose}
                     title="Kontaktperson hinzufügen"
                     contents={[content]}/>
    );
};

// ContactPersonModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

export default ContactPersonModal;