const periods = {
    none: {id: 1, lable: "Kein Zeitraum ausgewählt"},
    day: {id: 20, lable: "Tag"},
    week: {id: 30, lable: "Woche"},
    month: {id: 40, lable: "Monat"},
    year: {id: 50, lable: "Jahr"},
    range: {id: 60, lable: "Zeitraum"},
};

/*
   {
        id: periods.week.id,
        name: periods.week.lable,
    }
 */
const periodArray = [
    {
        id: periods.none.id,
        name: periods.none.lable,
    },
    {
        id: periods.day.id,
        name: periods.day.lable,
    },
    {
        id: periods.month.id,
        name: periods.month.lable,
    },
    {
        id: periods.year.id,
        name: periods.year.lable,
    },
    {
        id: periods.range.id,
        name: periods.range.lable,
    },
];

const periodDayAndMonth = [
    {
        id: periods.day.id,
        name: periods.day.lable,
    },
    {
        id: periods.month.id,
        name: periods.month.lable,
    }
];

const periodArraySmall = [
    {
        id: periods.none.id,
        name: periods.none.lable,
    },
    {
        id: periods.day.id,
        name: periods.day.lable,
    },
    {
        id: periods.week.id,
        name: periods.week.lable,
    },
    {
        id: periods.range.id,
        name: periods.range.lable,
    },
];

export {periods, periodArray, periodArraySmall, periodDayAndMonth};
