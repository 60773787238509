import React from "react"
import CustomModal from "../../common/modals/CustomModal";
import {connect} from "react-redux"
import moment from "moment";
import { getTranslation } from "../../../translations/translations";
import { useTranslation } from "react-i18next";

const MandatorVehicleRegistrationModal = (props) => {
    const {onClose, vehicleRegistration, loading} = props;
    const { t } = useTranslation();
    const dividerStyle = {width: "50%", height: 1, backgroundColor: "black", marginBottom: 15, marginTop: 15}
    const activeDivider = vehicleRegistration.length >= 2;
    const noData = vehicleRegistration.length === 0;

    const content = noData ? [(
        <h6 key="noData">Sorry, there's no registration data for this vehicle.</h6>
    )] : [(
        vehicleRegistration.map(v => {
            const startDataTime = moment(v.startedAt).format('DD MMMM YYYY, h:mm:ss a');
            const endDataTime = moment(v.finishedAt).format('DD MMMM YYYY, h:mm:ss a');
            return (
                <div key={v.userId}>
                    <div>
                        <h6><strong>{t("mandatorBoxHistoryModal.vehicleName")}:</strong> {v.vehicleName}</h6>
                        <h6><strong>{t("mandatorBoxHistoryModal.licensePlate")}:</strong> {v.licensePlate}</h6>
                        <h6><strong>{t("mandatorBoxHistoryModal.startedAt")}:</strong> {startDataTime}</h6>
                        <h6><strong>{t("mandatorBoxHistoryModal.finishAt")}:</strong> {endDataTime == "Invalid date" ? getTranslation("mandatorBoxHistoryModal.continuing") : endDataTime}</h6>  
                    </div>
                    {activeDivider && <div style={dividerStyle}/>}
                </div>
            )
        })
    )];


    return (
        <CustomModal contents={content} onClose={onClose} title={"Vehicle Registration"} loading={loading}/>
    )
}

function mapStatetoProp(state){
    return {
        vehicleRegistration: state.driveboxes.vehicleRegistration,
        loading: state.driveboxes.loading
    }
}

export default connect(mapStatetoProp)(MandatorVehicleRegistrationModal);