import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PrettyTripTableInformation from "./PrettyTripTableInformation";
import { connect } from "react-redux";
import EmptyResult from "../common/views/EmptyResult";
import { calculateFuelOrBatteryTripData } from "../../utils/CalculateFuelLevel";
import { findEngineTypeById } from "../../models/EngineType";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "15px 10px",
  },
}));


const PrettyTripTable = ({ trips, isUserAuthorized }) => {
  const classes = useStyles();
  const mappedTrip = trips.sort((t1, t2) =>
    t1.startedAt < t2.startedAt ? -1 : 1
  );
  const lastMappedTrip =
    mappedTrip.length > 0 ? mappedTrip[mappedTrip.length - 1] : {};

  const userSelectedEngineType = (lastMappedTrip.vehicle ? lastMappedTrip.vehicle.engineType : null) === 0 ? "kWh" : "L";

  const getPreviousTrip = (index) => {
    if (index === 0) return undefined;
    return mappedTrip[index - 1];
  };

  function isTankProcess(currentTrip, previousTrip) {
    const engineType = findEngineTypeById(currentTrip?.vehicle?.engineType)?.name;
    if (!previousTrip) {
      return false; // No previous trip, cannot compare
    }
    const startFuel = engineType === "Fuel" ? currentTrip.startingBatteryFuelLevelInLiter : currentTrip.startingBatteryChargeLevelInPercent;
    const finishFuelPrev = engineType === "Fuel" ? previousTrip.finishingBatteryFuelLevelInLiter : previousTrip.finishingBatteryChargeLevelInPercent;
    return startFuel > finishFuelPrev;
  }

  function isTakingDuringTrip(currentTrip){
    const engineType = findEngineTypeById(currentTrip?.vehicle?.engineType)?.name;
    const startFuel = engineType === "Fuel" ? currentTrip.startingBatteryFuelLevelInLiter : currentTrip.startingBatteryChargeLevelInPercent;
    const finishFuel = engineType === "Fuel" ? currentTrip.finishingBatteryFuelLevelInLiter : currentTrip.finishingBatteryChargeLevelInPercent;

    return startFuel < finishFuel
  }

const tripInformation = mappedTrip.map((t, i) => {
  const { averageFuelConsumption, overConsumption, underConsumption, isVehicleDetailsFilled } = calculateFuelOrBatteryTripData(t);
  const previousTrip = getPreviousTrip(i);
  const isTankProcessIdentified = isTankProcess(t, previousTrip);
  const isTankProcessDuringTripIdentified = isTakingDuringTrip(t);
  return (
      <PrettyTripTableInformation
          key={i}
          trip={t}
          visible={t.visibility}
          highlight={t.highlighted}
          previousTrip={previousTrip}
          isFirst={i === 0}
          isOverconsumtion={overConsumption}
          isUnderConsumption={underConsumption}
          averageFuelConsumptionSingleTrip={averageFuelConsumption}
          isTankProcessIdentified={isTankProcessIdentified}
          userSelectedEngineType={userSelectedEngineType}
          isUserAuthorized={isUserAuthorized}
          isVehicleDetailsFilled={isVehicleDetailsFilled}
          isTankProcessDuringTripIdentified={isTankProcessDuringTripIdentified}
      />
  );
});

  tripInformation.push(
    <PrettyTripTableInformation
      key="last"
      trip={lastMappedTrip}
      visible={lastMappedTrip.visibility}
      highlight={lastMappedTrip.highlighted}
      userSelectedEngineType={userSelectedEngineType}
      isLast
      isUserAuthorized={isUserAuthorized}
    />
  );

  return (
    <div className={classes.container}>
      {mappedTrip.length === 0 ? <EmptyResult /> : tripInformation}
    </div>
  );
};

function mapStateToProp(state) {
  return {
    trips: state.trips.entities,
  };
}

export default connect(mapStateToProp)(PrettyTripTable);
