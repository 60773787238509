import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { Alert } from "antd";

import { clearFilter } from "../../../actions/actionFilter";

const styles = theme => ({
    toolbar: {
        width: "100%",
        margin: "0px 0px 7px 0px",
    },
});

class FilterToolbar extends Component {
    componentWillUnmount() {
        this.props.clearFilter();
    }

    handleClose = () => {
        this.props.clearFilter();
        if (this.props.onClose) this.props.onClose();
    };

    render() {
        const { classes, filter } = this.props;
        if (filter && filter.active && filter.text)
            return <Alert className={classes.toolbar}
                          description={filter.text}
                          type="info"
                          closable
                          afterClose={this.handleClose}/>;
        
        return <div/>;
    }
}

FilterToolbar.defaultProps = {
    open: true,
};

// FilterToolbar.propTypes = {
//     classes: PropTypes.object.isRequired,
//     theme: PropTypes.object.isRequired,
//     onClose: PropTypes.func,
// };

function mapStateToProp(state) {
    return { filter: state.filter };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearFilter }, dispatch);
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(FilterToolbar));
