import React, {Component} from "react";
import PropTypes from "prop-types";
import GenericTable from "../../common/table/GenericTable";
import TableTypes from "../../../models/TableTypes";

class DriveboxTable extends Component {
    handleChangePage = (event, page) => {
        this.setState({page});
    };

    render() {
        const {
            driveboxes,
            numSelected,
            page,
            rowsPerPage,
            handleChangePage,
            handleChangeRowsPerPage,
            totalRows,
            archive,
            onSelect,
            onSelectAll,
            onArchiveToggle,
            onEdit,
            onHistory,
            onLastSignal
        } = this.props;

        const columnInformations = [
            {title: "IMEI", key: "imei"},
            {title: "Created At", key: "createdAt", type: TableTypes.date},
            {title: "Model", key: "model"},
            {title: "SimCard", key: "simCardNumber"},
            {title: "Telefonnummer", key: "phoneNumber"},
            {title: "Vehicle", key: "vehicle.name"},
            {title: "Mandant", key: "mandator.name"},
        ];

        const columnInformationsArchived = [
            {title: "IMEI", key: "imei"},
            {title: "Created At", key: "createdAt", type: TableTypes.date},
            {title: "Model", key: "model"},
            {title: "SimCard", key: "simCardNumber"},
            {title: "Telefonnummer", key: "phoneNumber"},
            {title: "Kommentar", key: "archiveComment"},
        ];

        const actions = [{title: "Edit", event: onEdit}, {title: "Archive", event: onArchiveToggle}, {title: "History", event: onHistory}, {title: "Last Signal", event: onLastSignal}];

        return (
            <GenericTable
                data={driveboxes}
                actions={actions}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                pageNavigation
                rowCount={totalRows}
                numSelected={numSelected}
                columnInformations={archive ? columnInformationsArchived : columnInformations}
                onSelectAllClick={onSelectAll}
                onSelect={onSelect}
                onEdit={onEdit}
                showCheckboxes
                showActions
            />
        );
    }
}

// DriveboxTable.propTypes = {
//     driveboxes: PropTypes.array.isRequired,
//     onSelect: PropTypes.func.isRequired,
//     onSelectAll: PropTypes.func.isRequired,
//     numSelected: PropTypes.number.isRequired,
//     onEdit: PropTypes.func.isRequired,
//     onArchiveToggle: PropTypes.func.isRequired,
//     archive: PropTypes.bool.isRequired,
// };

export default DriveboxTable;
