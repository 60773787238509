import { Form } from "antd";

import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core";

import { flexContainer } from "../../reservation/newReservationAddModal/ReservationAddModal.utils";

const useStyles = makeStyles({
  formInputContainer: {
    ...flexContainer("center", "row"),
    minHeight: "60px",
    width: "100%"
  },
  formInput: {
    width: "90%",
    margin: "0 10px 0 0",
    "& .ant-form-item-children": {
      paddingTop: "5px",
    },
    position: "relative",
  },
});

const FormInput = ({
  inputIcon,
  children,
  name,
  label,
  rules,
  extraRules,
}) => {
    const classes = useStyles();

  return (
    <Box className={classes.formInputContainer}>
      <Form.Item className={classes.formInput} name={name} label={label} rules={rules} {...extraRules}>
        {children}
        {inputIcon && inputIcon}
      </Form.Item>
    </Box>
  );
};

export default FormInput;