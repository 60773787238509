import React from "react";
import {fromToByPeriod} from "../../../../managers/timeManager";
import {periods} from "../../../../models/Period";
import {addMonths, subMonths} from "date-fns";
import {useHotkeys} from "react-hotkeys-hook";
import CustomDatePicker from "./CustomDatePicker";

const MonthPicker = props => {
    const { value, minDate, disableFuture = true } = props;

    const minDateValue = (typeof minDate !== "undefined") ? minDate : new Date(1990, 0, 1);

    useHotkeys("ctrl+alt+left", () => {
        setDates(subMonths(value, 1));
    }, [value]);
    useHotkeys("ctrl+alt+right", () => {
        setDates(addMonths(value, 1));
    }, [value]);


    function setDates(startDate) {
        const monthRange = fromToByPeriod(periods.month.id, startDate);
        props.onDateChange(monthRange.from, monthRange.to);
    }

    return (
        <CustomDatePicker views={["year", "month"]}
                            value={value}
                            disableFuture={disableFuture}
                            onChange={setDates}
                            minDate={minDateValue}
                            inputFormat="MMMM yyyy"
                            {...props}/>
    );
};


export default MonthPicker;
