import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericTable from "../common/table/GenericTable";
import { usePagination } from "../../hooks/usePagination";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { setFilter, updateFilterAPICall } from "../../actions/actionFilter";
import { Redirect } from "react-router-dom";
import { menu } from "../../managers/pathManager";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    paddingTop: "80px",
    paddingBottom: "15px",
    paddingLeft: "10px",
  },
}));

function WithoutDriverTripsByGroupTable() {
  const [openTrip, redirectToTrip] = useState(false);
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const classes = useStyles();
  const dispatch = useDispatch();

  const report = useSelector((state) => state.reports.trip);
  const tripsCount = Object.entries(
    report.tripsCountWithoutDriverByGroup || []
  );

  const mappedTripCount = tripsCount.map((value) => {
    const name = value[0];
    const count = value[1];
    return { name, count };
  });

  const total = mappedTripCount.length;

  const pageData = mappedTripCount.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const columnInformations = [
    {
      title: "Gruppe",
      key: "name",
    },
    {
      title: "Anzahl der Fahrten ohne Fahrer",
      key: "count",
    },
  ];

  return (
    <Paper>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Anzahl der Fahrten ohne Fahrer
      </Typography>
      <GenericTable
        data={pageData}
        columnInformations={columnInformations}
        rowCount={total}
        pageNavigation
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={changePage}
        handleChangeRowsPerPage={changeRowPerPage}
        onRowClick={(row) => {
          dispatch(
            updateFilterAPICall({
              filterGroupName: row.name,
              filterOnlyOpenTrips: false,
              filterOnlyWithoutDrivers: true,
            })
          );
          redirectToTrip(true);
        }}
      />
      {openTrip && <Redirect to={menu.groupTrip.path} />}
    </Paper>
  );
}

export default WithoutDriverTripsByGroupTable;
