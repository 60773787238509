const hoursOfDayArray = [
    {id: 1, name: "Bericht um 1 Uhr senden"},
    {id: 2, name: "Bericht um 2 Uhr senden"},
    {id: 3, name: "Bericht um 3 Uhr senden"},
    {id: 4, name: "Bericht um 4 Uhr senden"},
    {id: 5, name: "Bericht um 5 Uhr senden"},
    {id: 6, name: "Bericht um 6 Uhr senden"},
    {id: 7, name: "Bericht um 7 Uhr senden"},
    {id: 8, name: "Bericht um 8 Uhr senden"},
    {id: 9, name: "Bericht um 9 Uhr senden"},
    {id: 10, name: "Bericht um 10 Uhr senden"},
    {id: 11, name: "Bericht um 11 Uhr senden"},
    {id: 12, name: "Bericht um 12 Uhr senden"},
    {id: 13, name: "Bericht um 13 Uhr senden"},
    {id: 14, name: "Bericht um 14 Uhr senden"},
    {id: 15, name: "Bericht um 15 Uhr senden"},
    {id: 16, name: "Bericht um 16 Uhr senden"},
    {id: 17, name: "Bericht um 17 Uhr senden"},
    {id: 18, name: "Bericht um 18 Uhr senden"},
    {id: 19, name: "Bericht um 19 Uhr senden"},
    {id: 20, name: "Bericht um 20 Uhr senden"},
    {id: 21, name: "Bericht um 21 Uhr senden"},
    {id: 22, name: "Bericht um 22 Uhr senden"},
    {id: 23, name: "Bericht um 23 Uhr senden"},
    {id: 24, name: "Bericht um Mitternacht senden"},

];

export {hoursOfDayArray};
