import { Box, Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DriverIdentification from "./DriverIdentification";
import { clearVehicleDriver } from "../../actions/actionDriverIdentification";
import { makeStyles } from "@material-ui/core";
import successImg from "../../assets/success.png";
import { RootState } from "../../store";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    textAlign: "center",
  },

  gap: {
    height: "10vh",
  },

  successContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  successTitleMessage: {
    marginTop: "25px",
    fontWeight: 500,
    color: "#F0F0F0",

    [theme.breakpoints.up("xs")]: {
      fontSize: "clamp(2.3rem, 1.3vw + 1rem, 2.5rem)",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(1rem, 0.5vw + 1rem, 2rem)",
    },
  },

  successMessage: {
    marginTop: "25px",
    fontWeight: 500,
    color: "#F0F0F0",

    [theme.breakpoints.up("xs")]: {
      fontSize: "clamp(1rem, 1.3vw + 1rem, 2.5rem)",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(1rem, 0.5vw + 1rem, 2rem)",
    },
  },

  link: {
    color: "#00BCD4",
    marginTop: "5px",
    cursor: "pointer",
  },

  successImg: {
    width: "clamp(6.5rem, 1.5vw + 6.5rem, 11rem)",
  },
}));

const DriverIdentificationSuccessPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const vehicleId = useSelector(
    (state: RootState) => state.driverIdentification.vehicleId
  );
  const pathname = `driverIdentification/${vehicleId}`;
  const { t } = useTranslation();

  const vehicleName = useSelector(
    (state: RootState) => state.driverIdentification.vehicleName
  );

  const navigateBack = async () => {
    dispatch(clearVehicleDriver());
    history.push(`/${pathname}`);
  };

  return (
    <DriverIdentification headerVisibility={false}>
      <Grid item xs={10} className={classes.gap}></Grid>
      <Grid item xs={10} className={classes.contentContainer}>
        <Box className={classes.successContainer}>
          <img
            src={successImg}
            alt="success sign"
            className={classes.successImg}
          />
          <Typography className={classes.successTitleMessage}>
              {t("driverIdentification.successMessage")}
          </Typography>

        <Trans>
          <Typography className={classes.successMessage}>
            {t("driverIdentification.successAssignedVehicleMessage",
                { vehicleName: vehicleName }
              )} 
            </Typography>
        </Trans>

        </Box>
        <Typography
          onClick={navigateBack}
          variant="subtitle2"
          component={"span"}
          className={classes.link}
        >
          {t("driverIdentification.goBack")}
        </Typography>
      </Grid>
    </DriverIdentification>
  );
};

export default DriverIdentificationSuccessPage;
