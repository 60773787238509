import {Select} from "antd";
import CustomSelect from "../Select";
import { reservationStausArray } from "../../../../models/ReservationStatus";
import { getTranslation } from "../../../../translations/translations";

const {Option} = Select;

const ReservationStatusSelect = (props) => {
    const { onChange, value, allowClear } = props

    return (
      <CustomSelect
      handleChange={onChange}
      placeholder="Status"
      value={value}
      allowClear={allowClear}
      >
         {reservationStausArray.map(rs => (
                <Option key={rs.id} value={rs.id}>
                    {getTranslation(rs.name)}
                </Option>
            ))}
      </CustomSelect>
    )
}

export default ReservationStatusSelect;