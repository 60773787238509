import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import GenericTable from "../../../common/table/GenericTable";
import {useDispatch, useSelector} from "react-redux";
import {requestDeleteVehicleService, requestVehicleService} from "../../../../actions/actionVehicleService";
import {requestDeleteInspection, requestInspection} from "../../../../actions/actionInspection";
import TableTypes from "../../../../models/TableTypes";

const ServiceModalTable = props => {
    const {showInspection} = props;
    const dispatch = useDispatch();
    const vehicle = useSelector(state => state.vehicles.current);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (vehicle) {
            if (showInspection) {
                console.log("REQUEST INSPECTION");
                dispatch(requestInspection(vehicle.id));
            } else {
                dispatch(requestVehicleService(vehicle.id));
            }
        }
    }, [])

    useEffect(() => {
        if (vehicle)
            if (showInspection && vehicle.inspections)
                setData(vehicle.inspections)
            else if (!showInspection && vehicle.services)
                setData(vehicle.services.map(s => {
                    s.serviceAtInKm = s.serviceAt / 1000 + " km";
                    return s;
                }))
    }, [vehicle])

    const handleDelete = props => {
        if (showInspection)
            dispatch(requestDeleteInspection(props.id));
        else
            dispatch(requestDeleteVehicleService(props.id));
    }

    const serviceColumnInformations = [
        {title: "Datum", key: "serviceDate", type: TableTypes.date},
        {title: "Kilometerstand", key: "serviceAtInKm"},
        {title: "Beschreibung", key: "description"}
    ];

    const inspectionColumnInformations = [
        {title: "Datum", key: "inspectionDate", type: TableTypes.date},
        {title: "Beschreibung", key: "description"},

    ];

    const actions = [
        {title: "Löschen", event: handleDelete}
    ];

    return (
        <>
            {data.length===0 && "Keine vergangenen Daten vorhanden"}
            {data.length!==0 && <GenericTable
                data={data}
                columnInformations={showInspection ? inspectionColumnInformations : serviceColumnInformations}
                actions={actions}
                showActions
                disableContainer
                hover={false}
            />}
            </>
   
    );
};

ServiceModalTable.defaultProps = {
    page: 0,
};

// ServiceModalTable.propTypes = {
//     showInspection: PropTypes.bool
// };


export default ServiceModalTable;
