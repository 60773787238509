const timeLogEventType = {
    startOfWork: 0,
    endOfWork: 1,
    StartOfRestBreak: 2,
    EndOfRestBreak: 3,
    StartOfDrivingBreak: 4,
    EndOfDrivingBreak: 5,
    StartOfTrip: 6,
    EndOfTrip: 7,
};


const timeLogEventArray = [
    {id: timeLogEventType.startOfWork, name: "Arbeitsbeginn",},
    {id: timeLogEventType.endOfWork, name: "Arbeitsende"},
    {id: timeLogEventType.StartOfRestBreak, name: "Beginn der Ruhepause",},
    {id: timeLogEventType.EndOfRestBreak, name: "Ende der Ruhepause"},
    {id: timeLogEventType.StartOfDrivingBreak, name: "Beginn der Lenkpause",},
    {id: timeLogEventType.EndOfDrivingBreak, name: "Ende der Lenkpause"},
];

const timeLogEventArraySmall = [
    {id: timeLogEventType.startOfWork, name: "Arbeitstag",},
    {id: timeLogEventType.StartOfRestBreak, name: "Ruhepause"},
    {id: timeLogEventType.StartOfDrivingBreak, name: "Lenkpause"},
];

const getNameOfEvent = eventNumber => {
    switch (eventNumber) {
        case timeLogEventType.startOfWork:
            return "Arbeitsbeginn";
        case timeLogEventType.endOfWork:
            return "Arbeitsende";
        case timeLogEventType.StartOfRestBreak:
            return "Beginn der Ruhepause";
        case timeLogEventType.EndOfRestBreak:
            return "Ende der Ruhepause";
        case timeLogEventType.StartOfDrivingBreak:
            return "Beginn der Lenkpause";
        case timeLogEventType.EndOfDrivingBreak:
            return "Ende der Lenkpause";
        case timeLogEventType.StartOfTrip:
            return "Lenkzeit";
        case timeLogEventType.EndOfTrip:
            return "Ende der Lenkzeit"
    }
}

const getAllEventsWithDateTime = (timeLogEvents, startEventType = timeLogEventType.StartOfRestBreak, finishEventType = timeLogEventType.EndOfRestBreak) => {
    let typStr = "";

    let lastType = 0;
    timeLogEvents.forEach(t => {
        const event = t.Event;
        const date = new Date(t.OccurredAt);
        const occuredAt = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());

        if (event === startEventType && lastType !== startEventType) {
            typStr += occuredAt.getHours() + ":" + occuredAt.getMinutes() + "-";
            lastType = startEventType;
        }
        if (event === finishEventType && lastType === startEventType) {
            typStr += occuredAt.getHours() + ":" + occuredAt.getMinutes() + " ";
            lastType = finishEventType;
        }

    })
    return typStr;
}

export {timeLogEventType, timeLogEventArray, timeLogEventArraySmall, getNameOfEvent, getAllEventsWithDateTime}