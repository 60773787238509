import React, {Component, useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import FormModal from "../../common/modals/FormModal";

import UserSelect from "../../common/selects/specificSelects/UserSelect";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import FormBuilder from "../../../managers/formBuilder";

import {requestUpdateGroup} from "../../../actions/actionGroups";
import {Input} from "antd";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../../common/modals/modal.utils";
import { useTranslation } from "react-i18next";

const getDefaultInputValues = (group) => ({
    name: group ? group.name : "",
    vehicleIds: group ? group.vehicles.map(v => v.id) : [],
    userIds: group ? group.users.map(u => u.id) : [],
})

const GroupModal = props => {
    const {group, onClose, requestUpdateGroup} = props;
    const { handleInputChange, fields } = useInputChangeHandler(getDefaultInputValues(group));

    const { name, vehicleIds, userIds } = fields;
    const { t } = useTranslation();

    function handleUpdate() {
        requestUpdateGroup({name: name, id: group.id, vehicleIds, userIds});
        onClose();
    }

    const inputs = [
        new FormBuilder.FormInput(t("group.editModal.nameOfGroup"), <Input value={name} onChange={(e) => handleInputChange("name", e.target.value)} />)
            .withValue(name)
            .withKey("name")
            .build(),
        new FormBuilder.FormInput(t("group.editModal.vehicles"), <VehicleSelect multiple value={vehicleIds} onChange={(value) => handleInputChange("vehicleIds", value)} />)
            .withValue(vehicleIds)
            .withKey("vehicleIds")
            .build(),
        new FormBuilder.FormInput(t("group.editModal.users"), <UserSelect multiple value={userIds} onChange={(value) => handleInputChange("userIds", value)}/>)
            .withValue(userIds)
            .withKey("userIds")
            .build(),
    ];

    return <FormModal action="update" onSave={handleUpdate} onClose={onClose} formType={MODAL_TYPES.withoutValidation}
                      title={t("group.editModal.title", { name: name })} inputs={inputs}/>;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestUpdateGroup}, dispatch);
}

function mapStateToProp(state) {
    return {
        group: state.group,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(GroupModal);
