import { takeLatest, put, call, takeEvery } from "typed-redux-saga/macro";

import {
  receiveCostcenterById,
  receiveAddCostcenter,
  receiveAllCostcenters,
  receiveCostcenters,
  receiveUpdateCostcenter,
  receiveUpdateCostcenters,
  receiveDeleteCostcenter,
  receiveCostcenterToggleArchive,
  receiveCostcentersToggleArchive,
  receiveRoles,
} from "../actions/actionCostcenter";

import { RequestCostcenters,
    RequestCostcenterById,
    RequestAddCostcenter,
    RequestUpdateCostcenter,
    RequestUpdateCostcenters,
    RequestDeleteCostcenter,
    RequestCostcenterToggleArchive,
    RequestCostcentersToggleArchive,
} from "../types/actions/actionCostcenter.types";
import { COSTCENTER_ACTION_TYPES } from "../actions/actionCostcenter";

import { handleError } from "../managers/sagaManager";

import {
  getCostcenters,
  getCostcenterById,
  addCostcenter,
  updateCostcenter,
  updateCostcenters,
  deleteCostcenter,
  updatCostcenterArchive,
  updatCostcentersArchive,
  getRoles,
} from "./api";

function* getCostcentersData(action: RequestCostcenters) {
  try {
    const response = yield* call(getCostcenters, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveCostcenters(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getAllCostcentersData() {
  try {
    const response = yield* call(getCostcenters);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAllCostcenters(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getCostcenterDataById(action: RequestCostcenterById) {
  try {
    const response = yield* call(getCostcenterById, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveCostcenterById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addCostcenterData(action: RequestAddCostcenter) {
  try {
    const response = yield* call(addCostcenter, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddCostcenter(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateCostcenterData(action: RequestUpdateCostcenter) {
  try {
    const response = yield* call(updateCostcenter, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateCostcenter(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateCostcentersData(action: RequestUpdateCostcenters) {
  try {
    const response = yield* call(updateCostcenters, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateCostcenters(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteCostcenterData(action: RequestDeleteCostcenter) {
  try {
    const response = yield* call(deleteCostcenter, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteCostcenter(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateCostcenterArchiveToggleData(action: RequestCostcenterToggleArchive) {
  try {
    const response = yield* call(updatCostcenterArchive, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveCostcenterToggleArchive(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateCostcentersArchiveToggleData(action: RequestCostcentersToggleArchive) {
  try {
    const response = yield* call(updatCostcentersArchive, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveCostcentersToggleArchive(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getRolesData() {
  try {
    const response = yield* call(getRoles);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveRoles(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaCostcenter() {
  yield* takeLatest(COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTERS, getCostcentersData);
  yield* takeLatest(COSTCENTER_ACTION_TYPES.REQUEST_ALL_COSTCENTERS, getAllCostcentersData);
  yield* takeLatest(COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTER_BY_ID, getCostcenterDataById);
  yield* takeEvery(COSTCENTER_ACTION_TYPES.REQUEST_ADD_COSTCENTER, addCostcenterData);
  yield* takeEvery(COSTCENTER_ACTION_TYPES.REQUEST_UPDATE_COSTCENTER, updateCostcenterData);
  yield* takeEvery(COSTCENTER_ACTION_TYPES.REQUEST_UPDATE_COSTCENTERS, updateCostcentersData);
  yield* takeEvery(COSTCENTER_ACTION_TYPES.REQUEST_DELETE_COSTCENTER, deleteCostcenterData);
  yield* takeEvery(
    COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTER_TOGGLE_ARCHIVE,
    updateCostcenterArchiveToggleData
  );
  yield* takeEvery(
    COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTERS_TOGGLE_ARCHIVE,
    updateCostcentersArchiveToggleData
  );

  yield* takeLatest(COSTCENTER_ACTION_TYPES.REQUEST_ROLES, getRolesData);
}
