import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportFormat } from "../../../models/ExportFormat";
import { grouping, tripGeofenceGroupingArray } from "../../../models/Grouping";
import { fromToByPeriod } from "../../../managers/timeManager";
import { periods } from "../../../models/Period";
import { subMonths } from "date-fns";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import {
  requestDayViewExportPdf,
  requestDayViewGraphicExportPDF,
  requestTripExportXlsx,
  requestTripGeofenceIntersectionsExportPdf,
} from "../../../actions/actionExport";
import Grid from "@material-ui/core/Grid";
import PeriodDatePicker from "../../common/selects/datePickers/PeriodDatePicker";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import Button from "@material-ui/core/Button";
import EditModal from "../../common/modals/CustomModal";
import { makeStyles } from "@material-ui/core";
import GeofenceSelect from "../../common/selects/specificSelects/GeofenceSelect";
import GroupingSelect from "../../common/selects/specificSelects/GroupingSelect";
import DriverSelect from "../../common/selects/specificSelects/DriverSelect";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  exportButton: {
    marginTop: theme.spacing(1),
  },
}));

const DayViewExportModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter.api);
  const me = useSelector((state) => state.users.me);
  const { t } = useTranslation();

  const [chosenGrouping, setGrouping] = useState(grouping.vehicle);

  const { onClose, onExport, graphicReport, isTripGeofenceIntersection, minDate } =
    props;

  const defaultRange = fromToByPeriod(
    periods.month.id,
    subMonths(new Date(), 1)
  );

  useEffect(() => {
    dispatch(updateFilterAPICall({ grouping: chosenGrouping }));
  }, [chosenGrouping]);

  function handleExportTripGeofenceIntersection(vehicleIds) {
    // TODO this code should be in a separate modal
    let geofenceIds =
      chosenGrouping === grouping.geofence ? filter.geofenceIds : [];
    
    let driverIds = 
    chosenGrouping === grouping.driver ? filter.driverIds : [];

    dispatch(
      requestTripGeofenceIntersectionsExportPdf({
        ...filter,
        vehicleIds,
        geofenceIds,
        driverIds,
        from: filter.from ? filter.from : defaultRange.from.toISOString(),
        to: filter.to ? filter.to : defaultRange.to.toISOString(),
      })
    );
  }

  function handleExportGraphicDayViewReport(vehicleIds) {
    dispatch(
      requestDayViewGraphicExportPDF({
        ...filter,
        vehicleIds,
        driverIds: undefined,
        grouping: undefined,
        from: filter.from ? filter.from : defaultRange.from.toISOString(),
        to: filter.to ? filter.to : defaultRange.to.toISOString(),
      })
    );
  }

  function handleExportDayViewReport(vehicleIds) {
    dispatch(
      requestDayViewExportPdf({
        ...filter,
        vehicleIds,
        driverIds: undefined,
        grouping: undefined,
        from: filter.from ? filter.from : defaultRange.from.toISOString(),
        to: filter.to ? filter.to : defaultRange.to.toISOString(),
      })
    );
  }

  function handleExport() {
    let vehicleIds =
      chosenGrouping === grouping.vehicle ? filter.vehicleIds : [];

    if (isTripGeofenceIntersection)
      handleExportTripGeofenceIntersection(vehicleIds);
    else if (graphicReport) handleExportGraphicDayViewReport(vehicleIds);
    else handleExportDayViewReport(vehicleIds);

    onExport();
    onClose();
  }

  function handleDateChange(period, from, to) {
    dispatch(
      updateFilterAPICall({
        period,
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      })
    );
  }

  function handleVehiclesChange(vehicleIds) {
    dispatch(updateFilterAPICall({ vehicleIds }));
  }

  function handleGeofenceChange(geofenceIds) {
    dispatch(updateFilterAPICall({ geofenceIds }));
  }

  function handleUsersChange(driverIds) {
    dispatch(updateFilterAPICall({ driverIds }));
  }

  let showGrouping = null,
    showVehiclesSelect = null,
    showGeofencesSelect = null,
    showDriversSelect = null;
  let isButtonDisabled = !filter.vehicleIds || filter.vehicleIds.length === 0;

  // if grouping is chosen by geofence show selection for geofence and enable/disable button
  if (isTripGeofenceIntersection && chosenGrouping === grouping.geofence) {

    isButtonDisabled = !filter.geofenceIds || filter.geofenceIds.length === 0;
    showGeofencesSelect = (
      <>
        <Grid item xs={3}>
          Geofences{" "}
        </Grid>
        <Grid item xs={9}>
          <GeofenceSelect
            multiple
            onChange={handleGeofenceChange}
            value={filter.geofenceIds}
          />
        </Grid>
      </>
    );
  } else if (isTripGeofenceIntersection && chosenGrouping === grouping.driver) {
    isButtonDisabled = !filter.driverIds || filter.driverIds.length === 0;

    showDriversSelect = (
      <>
        <Grid item xs={3}>
          Drivers{" "}
        </Grid>
        <Grid item xs={9}>
          <DriverSelect
            multiple
            onChange={handleUsersChange}
            value={filter.driverIds}
          />
        </Grid>
      </>
    );
  }
  // otherwise show select for vehicles
  else if (
    (isTripGeofenceIntersection && chosenGrouping === grouping.vehicle) ||
    !isTripGeofenceIntersection
  ) {

    showVehiclesSelect = (
      <>
        <Grid item xs={3}>
          Fahrzeuge{" "}
        </Grid>
        <Grid item xs={9}>
          <VehicleSelect
            multiple
            onChange={handleVehiclesChange}
            value={filter.vehicleIds}
          />
        </Grid>
      </>
    );
  }

  showGrouping = (
    <>
      <Grid item xs={3}>
        Gruppierung{" "}
      </Grid>
      <Grid item xs={9}>
        <GroupingSelect
          onChange={setGrouping}
          value={chosenGrouping}
          groupArray={tripGeofenceGroupingArray}
        />
      </Grid>
    </>
  );

  const content = (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        Zeitraum
      </Grid>

      <Grid item xs={9}>
        <PeriodDatePicker
          minDate={minDate}
          period={filter.period || periods.month.id}
          onDateChange={handleDateChange}
          startDate={
            filter.from
              ? new Date(filter.from).toISOString()
              : defaultRange.from
          }
          endDate={
            filter.to ? new Date(filter.to).toISOString() : defaultRange.to
          }
        />
      </Grid>

      {isTripGeofenceIntersection && showGrouping}

      {showVehiclesSelect}

      {showGeofencesSelect}

      {showDriversSelect}

      <Grid item xs={12}>
        <Button
          onClick={handleExport}
          disabled={isButtonDisabled}
          className={classes.exportButton}
          variant="contained"
          color="primary"
          fullWidth
        >
          {t("trip.exportmodal.export")}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <EditModal
      onClose={onClose}
      title={t("trip.exportmodal.title")}
      contents={[content]}
    />
  );
};

export default DayViewExportModal;
