import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";

import InputField from "../../../common/inputs/InputField";
import EditModal from "../../../common/modals/CustomModal";
import LoadingContainer from "../../../common/container/LoadingContainer";

import {requestAddMandatorDriveboxes, requestMandators} from "../../../../actions/actionMandators";

class MandatorDriveboxMultiEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatorName: "Ilogs",
        };
    }

    componentDidMount() {
        this.props.requestMandators();
    }

    handleOnSave = (event, value) => {
        const {driveboxes} = this.props;
        this.props.requestAddMandatorDriveboxes({mandatorId: this.state.mandatorId, driveboxes: driveboxes});
        this.props.onClose();
    };

    handleMandatorChange = event => {
        const {mandators} = this.props;
        const id = mandators.find(m => m.name == event.target.value).id;
        this.setState({
            mandatorId: id,
            mandatorName: event.target.value,
        });
    };

    render() {
        const {onClose, driveboxes, mandators} = this.props;
        const {mandatorName} = this.state;
        const active = mandators && mandators.length > 0 && driveboxes && Object.keys(driveboxes).length !== 0;

        const generalDialogContent = (
            <LoadingContainer active={active}>
                {data => (
                    <div>
                        <InputField label="Mandator" select shrink value={mandatorName}
                                    onChange={this.handleMandatorChange}>
                            {mandators.map(option => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </InputField>
                    </div>
                )}
            </LoadingContainer>
        );
        const contents = [generalDialogContent];

        return (
            <EditModal action="update" onSave={this.handleOnSave} onClose={onClose}
                       title={"Edit: " + driveboxes.length + " driveboxes"} contents={contents}/>
        );
    }
}

// MandatorDriveboxMultiEditModal.propTypes = {
//     driveboxes: PropTypes.array.isRequired,
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestMandators, requestAddMandatorDriveboxes}, dispatch);
}

function mapStateToProp(state) {
    return {
        mandators: state.mandators.entities,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(MandatorDriveboxMultiEditModal);
