import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Link from "@material-ui/core/Link";

import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(createStyles(theme => ({
    image: {
        width: "19px",
        height: "18px",
        marginRight: "6px",
    },
    infoText: {
        color: "white",
        fontSize: "12px"
    }
})));

const IconTextItem = ({ icon: Icon, text, phoneNumber }) => {
const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" m={1}>
      <Box component={Icon} className={classes.image} />
      {phoneNumber ? (
        <Link className={classes.infoText} href={`tel:${text}`} underline="none">{text}</Link>
      ) : (
        <Typography className={classes.infoText} >{text}</Typography>
      )}
      
    </Box>
  );
};

export default IconTextItem;