import { takeLatest, put, call } from "typed-redux-saga/macro";

import { handleError } from "../managers/sagaManager";

import { sendOrderMoreBoxesEmailRequest } from "./api";

import { receiveSendEmail, toggleEmailSuccessMsgVisibility, EMAIL_ACTION_TYPES } from "../actions/actionEmail";

import { RequestSendEmail } from "../types/actions/actionEmail.types";

function* sendMoreBoxesEmail(action: RequestSendEmail) {
  try {
    const response = yield* call(sendOrderMoreBoxesEmailRequest, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveSendEmail(response.data));
      yield* put(toggleEmailSuccessMsgVisibility(true));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}


export default function* sagaEmail() {
  yield* takeLatest(
    EMAIL_ACTION_TYPES.REQUEST_SEND_EMAIL,
    sendMoreBoxesEmail
  );
}