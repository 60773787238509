import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import CustomSelect from "../../common/selects/Select";

const { Option } = Select;
const tripTypeArray = [
  { id: "0", name: "Einladung per Mail" },
  { id: "1", name: "Passwort setzen" },
];

const AccessDataPasswordSelect = (props) => {
  const { onChange, value } = props;

  return (
    <CustomSelect value={value} handleChange={onChange} allowClear={false}>
      {tripTypeArray.map((tt) => (
        <Option key={tt.id} value={tt.id}>
          {tt.name}
        </Option>
      ))}
    </CustomSelect>
  );
};

// AccessDataPasswordSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string,
// };

export default AccessDataPasswordSelect;
