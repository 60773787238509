import {call, put, takeLatest} from "typed-redux-saga/macro";
import { receiveOrder, ORDER_ACTION_TYPES } from "../actions/actionOrder";

import { RequestOrder } from "../types/actions/actionOrder.types";

import {handleError} from "../managers/sagaManager";

import {getOrder} from "./api";

function* getOrderData(action: RequestOrder) {
    try {
        const response = yield* call(getOrder, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveOrder(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

export default function* sagaOrder() {
    yield* takeLatest(ORDER_ACTION_TYPES.REQUEST_ORDER, getOrderData);

}
