import { useState, useEffect, useCallback } from "react";
import AddressSelect from "../../../common/selects/specificSelects/AddressSelect";
import CustomDateTimePicker from "../../../common/selects/datePickers/CustomDateTimePicker";
import FormInput from "../../../common/inputs/FormInput";

import {
  setStopoversNumber,
  setStopoverValues,
} from "../reservationAddModal.action";

import {
  reservationAddModalStyles,
  getRequiredRule,
  validateMaxStopovers,
} from "../ReservationAddModal.utils";
import { validateInputs } from "../../../common/inputs/inputs.utils";

import DateRangeSharpIcon from "@material-ui/icons/DateRangeSharp";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveIcon from "@material-ui/icons/Remove";

import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(reservationAddModalStyles);

const ReservationAddModalStepOne = ({
  pickupDate,
  returnDate,
  pickupAddress,
  returnAddress,
  stopoverValues,
  numOfStopovers,
  reservationAddDispatch,
  handleInputChange,
  isValidated,
  customRule,
  reservation,
  form,
}) => {
  const [hasExceededMaxStopovers, setHasExceededMaxStopovers] = useState(false);
  const [allowInputClear, setAllowInputClear] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleInputClear = useCallback(() => {
    setAllowInputClear(true);
  }, [reservationAddDispatch]);

  const datepickerInputIcon = (
    <DateRangeSharpIcon className={classes.datePickerIcon} />
  );

  const handleStopoverChange = (index, value) => {
    const newStopoverValues = stopoverValues.map((stopoverValue, idx) => {
      return index === idx ? value : stopoverValue;
    });

    reservationAddDispatch(setStopoverValues(newStopoverValues));
    form.setFieldsValue({ [`stopover${index + 1}`]: value });
  };

  useEffect(() => {
    if (numOfStopovers === 1 && allowInputClear) {
      reservationAddDispatch(setStopoverValues([{}]));
    }

    setAllowInputClear(false);
  }, [allowInputClear]);

  const addAddressField = () => {
    if (numOfStopovers < 4) {
      const newStopoverValues = [...stopoverValues, {}];

      reservationAddDispatch(setStopoversNumber(1));
      reservationAddDispatch(setStopoverValues(newStopoverValues));
      setHasExceededMaxStopovers(false);
    } else {
      setHasExceededMaxStopovers(true);
    }
  };

  const deleteInputHandler = (index) => {
    const newStopoverValues = stopoverValues.filter((_, idx) => {
      return index !== idx;
    });

    reservationAddDispatch(setStopoversNumber(-1));
    reservationAddDispatch(setStopoverValues(newStopoverValues));
  };

  const stopoverInputIcon = (idx) => {
    return (
      <>
        {numOfStopovers - 1 === idx && (
          <AddCircleOutlineIcon
            className={classes.stopoverAddIcon}
            onClick={() => addAddressField()}
          />
        )}
        <RemoveIcon
          className={classes.stopoverRemoveIcon}
          onClick={() =>
            numOfStopovers === 1 ? handleInputClear() : deleteInputHandler(idx)
          }
        />
      </>
    );
  };

  return (
    <>
      <FormInput
        inputIcon={datepickerInputIcon}
        name="pickupDate"
        label={
          t("reservation.addReservationModal.pageOne.pickupDate")
        }
        rules={[
          getRequiredRule(
            t("reservation.addReservationModal.pageOne.pickupDateValidation")
          ),
        ]}
      >
        <CustomDateTimePicker
          value={pickupDate}
          onChange={(value) => handleInputChange("pickupDate", value)}
          disablePast={!reservation}
          disableFuture={false}
        />
      </FormInput>

      <FormInput
        inputIcon={datepickerInputIcon}
        name="returnDate"
        label={
          t("reservation.addReservationModal.pageOne.returnDate")
        }
        rules={[
          getRequiredRule(
            t("reservation.addReservationModal.pageOne.returnDateValidation")
          ),
          customRule,
        ]}
        extraRules={!isValidated && validateInputs(t("reservation.addReservationModal.inputValidation.datePickersValidation") )}
      >
        <CustomDateTimePicker
          value={returnDate}
          onChange={(value) => handleInputChange("returnDate", value)}
          disablePast={!reservation}
          disableFuture={false}
        />
      </FormInput>
      <FormInput
        name="pickupAddress"
        label={
          t("reservation.addReservationModal.pageOne.pickupAddress")
        }
        rules={[
          getRequiredRule(
            t("reservation.addReservationModal.pageOne.pickupAddressValidation")
          ),
        ]}
      >
        <AddressSelect
          onChange={(value) => handleInputChange("pickupAddress", value)}
          value={pickupAddress}
        />
      </FormInput>
      {stopoverValues.map((stopoverValue, idx) => {
        return (
          <FormInput
            key={`stopover-input-${idx}`}
            inputIcon={stopoverInputIcon(idx)}
            name={`stopover${idx + 1}`}
            label={
              t("reservation.addReservationModal.pageOne.stopover",
                { index: idx + 1 }
              )}
            extraRules={
              idx === 3 && hasExceededMaxStopovers && validateMaxStopovers(4)
            }
          >
            <AddressSelect
              onChange={(value) => handleStopoverChange(idx, value)}
              value={stopoverValue}
            />
          </FormInput>
        );
      })}
      <FormInput
        name="returnAddress"
        label={
          t("reservation.addReservationModal.pageOne.returnAddress")
        }
        rules={[
          getRequiredRule(
          t("reservation.addReservationModal.pageOne.returnAddressValidation")
          ),
        ]}
      >
        <AddressSelect
          onChange={(value) => handleInputChange("returnAddress", value)}
          value={returnAddress}
        />
      </FormInput>
    </>
  );
};

export default ReservationAddModalStepOne;
