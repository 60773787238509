import {call, put, takeEvery} from "redux-saga/effects";
import {receiveScheduledReports, REQUEST_SCHEDULED_REPORTS} from "../actions/actionScheduledReport";
import {receiveScheduledReportById, REQUEST_SCHEDULED_REPORT_BY_ID} from "../actions/actionScheduledReport";
import {receiveAddScheduledReport, REQUEST_ADD_SCHEDULED_REPORT} from "../actions/actionScheduledReport";
import {receiveUpdateScheduledReport, REQUEST_UPDATE_SCHEDULED_REPORT} from "../actions/actionScheduledReport";
import {receiveDeleteScheduledReport, REQUEST_DELETE_SCHEDULED_REPORT} from "../actions/actionScheduledReport";

import {handleError} from "../managers/sagaManager";
import {
    getScheduledReports,
    getScheduledReportById,
    addScheduledReport,
    updateScheduledReport,
    deleteScheduledReport
} from "./api";


function* getScheduledReportsData(action) {
    try {
        const response = yield call(getScheduledReports, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveScheduledReports(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* getScheduledReportDataById(action) {
    try {
        const response = yield call(getScheduledReportById, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveScheduledReportById(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* addScheduledReportsData(action) {
    try {
        const response = yield call(addScheduledReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveAddScheduledReport(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* updateScheduledReportsData(action) {
    try {
        const response = yield call(updateScheduledReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveUpdateScheduledReport(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* deleteScheduledReportsData(action) {
    try {
        const response = yield call(deleteScheduledReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveDeleteScheduledReport(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

export default function* sagaScheduledReport() {
    yield takeEvery(REQUEST_SCHEDULED_REPORTS, getScheduledReportsData);
    yield takeEvery(REQUEST_SCHEDULED_REPORT_BY_ID, getScheduledReportDataById);
    yield takeEvery(REQUEST_ADD_SCHEDULED_REPORT, addScheduledReportsData);
    yield takeEvery(REQUEST_UPDATE_SCHEDULED_REPORT, updateScheduledReportsData);
    yield takeEvery(REQUEST_DELETE_SCHEDULED_REPORT, deleteScheduledReportsData); 
}
