import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useSelector } from "react-redux";

import StopoverContents from "./StopoverContents";

import { STEP_TWO_DATA, STEP_THREE_DATA, STEP_FOUR_DATA } from "./ReservationAddModal.utils";
import { makeStyles } from "@material-ui/core";
import { stepsContentValues } from "./ReservationAddModal.utils";

const useStyles = makeStyles({
  stepOneContent: { margin: "25px 20px 35px 10px" },
  stepsContent: { margin: "5px 40px 10px 0" },
});

const ReservationStepsContent = ({
  step,
  reservationAddState
}) => {
  const { costCenter, stopoverValues, vehicleId, driverId } = reservationAddState;
  const classes = useStyles();
  const availableVehicles = useSelector(state => state.vehicles.reservation.entities);
  const availableDrivers = useSelector(state => state.users.reservation.entities);

  const selectedVehicle = availableVehicles.find((vehicle) => vehicle.id === vehicleId);
  const selectedDriver = availableDrivers.find((driver) => driver.id === driverId);

  const dynamicStepData =
    step === 1
      ? STEP_TWO_DATA
      : step === 2
      ? STEP_THREE_DATA
      : step === 3
      ? STEP_FOUR_DATA
      : null;

  return (
    <Grid
      container
      spacing={step === 1 ? 3 : step === 2 ? 1 : 2}
      justify="center"
      className={step === 1 ? classes.stepOneContent : classes.stepsContent}
    >
      {dynamicStepData &&
        dynamicStepData
          .filter((data) => !(data.package === "costCenter" && !costCenter))
          .map(({ title, value, style }, index) => {
            if (title === "stopover") {
              return <StopoverContents key={`stopover-contents-${index}`} stopoverValues={stopoverValues} />;
            }
            return (
              <Grid item xs={5} key={`reservation-step-data-${index}`}>
                <Typography variant="subtitle2" component="p" style={style}>
                  {value
                    ? stepsContentValues(value, reservationAddState, selectedVehicle, selectedDriver)
                    : title}
                </Typography>
              </Grid>
            );
          })}
    </Grid>
  );
};

export default ReservationStepsContent;
