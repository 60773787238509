import {
  takeLatest,
  put,
  call,
  takeEvery,
  throttle,
} from "typed-redux-saga/macro";

import {
  receiveAddMandator,
  receiveAddMandatorDriveboxes,
  receiveAddMandatorSuperUser,
  receiveAddMandatorUsers,
  receiveDeleteMandator,
  receiveDeleteMandatorDrivebox,
  receiveDeleteMandatorSuperUser,
  receiveMandatorById,
  receiveMandatorDriveboxes,
  receiveMandatorDriveboxesByImei,
  receiveMandatorDriveboxEvents,
  receiveMandatorDriveboxPositions,
  receiveMandatorDriveboxToggleArchive,
  receiveMandators,
  receiveMandatorSuperUsers,
  receiveMandatorSurrogate,
  receiveMandatorToggleArchive,
  receiveMandatorTrips,
  receiveMandatorUsers,
  receiveMandatorUserSurrogate,
  receiveMandatorUserWelcomeMail,
  receiveUpdateMandator,
  receiveMandatorDriveBoxHistory,
  receiveUpdateMandatorDrivebox,
  receiveUpdateMandatorSuperUser,
  receiveUpdateMandatorSuperUserPassword,
  receiveUpdateMandatorNotification,
  receiveVehicleRegistration,
  receiveDriveBoxLastSignal,
  receiveLastLogIns,
  MANDATOR_ACTION_TYPES,
} from "../actions/actionMandators";

import { handleError } from "../managers/sagaManager";

import { RequestMandators,
    RequestMandatorById,
    RequestAddMandator,
    RequestUpdateMandator,
    RequestDeleteMandator,
    RequestMandatorDriveboxes,
    RequestMandatorDriveboxesByImei,
    RequestAddMandatorDriveboxes,
    RequestUpdateMandatorDrivebox,
    RequestDeleteMandatorDrivebox,
    RequestMandatorUsers,
    RequestAddMandatorUsers,
    RequestMandatorSuperUsers,
    RequestUpdateMandatorSuperUser,
    RequestAddMandatorSuperUser,
    RequestDeleteMandatorSuperUser,
    RequestUpdateMandatorSuperUserPassword,
    RequestMandatorDriveboxToggleArchive,
    RequestMandatorToggleArchive,
    RequestMandatorSurrogate,
    RequestMandatorUserWelcomeMail,
    RequestMandatorDriveboxPositions,
    RequestMandatorDriveboxEvents,
    RequestMandatorTrips,
    RequestMandatorUserSurrogate,
    RequestUpdateMandatorNotification,
    RequestMandatorDriveBoxHistory,
    RequestVehicleRegistration,
    RequestDriveBoxLastSignal,
    RequestLastLogIns,
} from "../types/actions/actionMandator.types";

import {
  addMandator,
  addMandatorDrivebox,
  addMandatorSuperUser,
  addMandatorUsers,
  deleteMandator,
  deleteMandatorDrivebox,
  deleteMandatorSuperUser,
  getCurrentlyLoggedinUser,
  getMandatorById,
  getMandatorDriveboxes,
  getMandatorDriveboxesByImei,
  getMandatorDriveboxEvents,
  getMandatorDriveboxPositions,
  getMandators,
  getMandatorSuperUsers,
  getMandatorTrips,
  getMandatorUsers,
  sendWelcomeMail,
  surrogateMandator,
  surrogateMandatorUser,
  updateMandator,
  updateMandatorArchive,
  updateMandatorDrivebox,
  updateMandatorDriveboxArchive,
  updateMandatorSuperUser,
  updateMandatorUserChangePassword,
  updateUserNotificationOfMandator,
  getDriveBoxHistory,
  getVehicleRegistration,
  getDriveBoxLastSignal,
  getLastLogIns,
} from "./api";
import { receiveCurrentlyLoggedinUser } from "../actions/actionUser";

function* getMandatorData(action: RequestMandators) {
  try {
    const response = yield* call(getMandators, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandators(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorDataById(action: RequestMandatorById) {
  try {
    const response = yield* call(getMandatorById, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addMandatorData(action: RequestAddMandator) {
  try {
    const response = yield* call(addMandator, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddMandator(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateMandatorData(action: RequestUpdateMandator) {
  try {
    const response = yield* call(updateMandator, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateMandator(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteMandatorData(action: RequestDeleteMandator) {
  try {
    const response = yield* call(deleteMandator, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteMandator(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorDriveboxesData(action: RequestMandatorDriveboxes) {
  try {
    const response = yield* call(getMandatorDriveboxes, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorDriveboxes(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorDriveboxDataByImei(action: RequestMandatorDriveboxesByImei) {
  try {
    const response = yield* call(getMandatorDriveboxesByImei, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorDriveboxesByImei(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addMandatorDriveboxesData(action: RequestAddMandatorDriveboxes) {
  try {
    const response = yield* call(addMandatorDrivebox, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddMandatorDriveboxes(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateMandatorDriveboxData(action: RequestUpdateMandatorDrivebox) {
  try {
    const response = yield* call(updateMandatorDrivebox, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateMandatorDrivebox(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteMandatorDriveboxData(action: RequestDeleteMandatorDrivebox) {
  try {
    const response = yield* call(deleteMandatorDrivebox, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteMandatorDrivebox());
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorUserData(action: RequestMandatorUsers) {
  try {
    const response = yield* call(getMandatorUsers, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorUsers(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addMandatorUserData(action: RequestAddMandatorUsers) {
  try {
    const response = yield* call(addMandatorUsers, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddMandatorUsers(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorSuperUserData(action: RequestMandatorSuperUsers) {
  try {
    const response = yield* call(getMandatorSuperUsers, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorSuperUsers(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateMandatorSuperUserData(action: RequestUpdateMandatorSuperUser) {
  try {
    const response = yield* call(updateMandatorSuperUser, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateMandatorSuperUser(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addMandatorSuperUserData(action: RequestAddMandatorSuperUser) {
  try {
    const response = yield* call(addMandatorSuperUser, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddMandatorSuperUser(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteMandatorSuperUserData(action: RequestDeleteMandatorSuperUser) {
  try {
    const response = yield* call(deleteMandatorSuperUser, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteMandatorSuperUser(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateMandatorSuperUserPasswordData(action: RequestUpdateMandatorSuperUserPassword) {
  try {
    const response = yield* call(updateMandatorUserChangePassword, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateMandatorSuperUserPassword(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateMandatorDriveboxToggleArchiveData(action: RequestMandatorDriveboxToggleArchive) {
  try {
    const response = yield* call(updateMandatorDriveboxArchive, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorDriveboxToggleArchive(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateMandatorToggleArchiveData(action: RequestMandatorToggleArchive) {
  try {
    const response = yield* call(updateMandatorArchive, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorToggleArchive(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* surrogateMandatorData(action: RequestMandatorSurrogate) {
  try {
    const response = yield* call(surrogateMandator, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorSurrogate(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* sendMandatorUserWelcomeMailData(action: RequestMandatorUserWelcomeMail) {
  try {
    const response = yield* call(sendWelcomeMail, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorUserWelcomeMail());
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorDriveboxPositionsData(action: RequestMandatorDriveboxPositions) {
  try {
    const response = yield* call(getMandatorDriveboxPositions, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorDriveboxPositions(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorDriveboxEventsData(action: RequestMandatorDriveboxEvents) {
  try {
    const response = yield* call(getMandatorDriveboxEvents, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorDriveboxEvents(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMandatorTripsData(action: RequestMandatorTrips) {
  try {
    const response = yield* call(getMandatorTrips, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* surrogateMandatorUserData(action: RequestMandatorUserSurrogate) {
  try {
    const response = yield* call(surrogateMandatorUser, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorUserSurrogate(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateMandatorNotificationData(action: RequestUpdateMandatorNotification) {
  try {
    const response = yield* call(updateUserNotificationOfMandator, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateMandatorNotification(response.data));
      try {
        const response = yield* call(getCurrentlyLoggedinUser);
        if (response.status >= 200 && response.status < 300) {
          yield* put(receiveCurrentlyLoggedinUser(response.data));
        } else {
          throw response;
        }
      } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
      }
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getDriveBoxHistoryData(action: RequestMandatorDriveBoxHistory) {
  try {
    const response = yield* call(getDriveBoxHistory, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMandatorDriveBoxHistory(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getVehicleRegistrationData(action: RequestVehicleRegistration) {
  try {
    const response = yield* call(getVehicleRegistration, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield put(receiveVehicleRegistration(response.data));
    } else {
      throw response;
    }
    
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getDriveBoxLastSignalData(action: RequestDriveBoxLastSignal){
  try {
    const response = yield* call(getDriveBoxLastSignal, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield put(receiveDriveBoxLastSignal(response.data));
    } else {
      throw response;
    } 
  }  catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getUserLogInsData(action: RequestLastLogIns){
  try {
    const response = yield* call(getLastLogIns, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield put(receiveLastLogIns(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaUser() {
  yield* throttle(500, MANDATOR_ACTION_TYPES.REQUEST_MANDATORS, getMandatorData);
  yield* throttle(500, MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_USERS, getMandatorUserData);
  yield* throttle(500, MANDATOR_ACTION_TYPES.REQEUST_ADD_MANDATOR_USERS, addMandatorUserData);

  yield* throttle(500, MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOXES, getMandatorDriveboxesData);
  yield* throttle(
    500,
    MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOXES_BYIMEI,
    getMandatorDriveboxDataByImei
  );
  yield* takeLatest(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_BY_ID, getMandatorDataById);

  //superUser
  yield* takeLatest(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_SUPERUSER, getMandatorSuperUserData);
  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQEUST_UPDATE_MANDATOR_SUPERUSER,
    updateMandatorSuperUserData
  );
  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_ADD_MANDATOR_SUPERUSER, addMandatorSuperUserData);
  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_DELETE_MANDATOR_SUPERUSER,
    deleteMandatorSuperUserData
  );
  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR_SUPERUSER_PASSWORD,
    updateMandatorSuperUserPasswordData
  );

  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_ADD_MANDATOR, addMandatorData);
  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_ADD_MANDATOR_DRIVEBOXES, addMandatorDriveboxesData);

  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR, updateMandatorData);
  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR_DRIVEBOX, updateMandatorDriveboxData);
  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR_NOTIFICATION,
    updateMandatorNotificationData
  );

  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_DELETE_MANDATOR, deleteMandatorData);
  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_DELETE_MANDATOR_DRIVEBOX, deleteMandatorDriveboxData);

  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOX_TOGGLE_ARCHIVE,
    updateMandatorDriveboxToggleArchiveData
  );
  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_TOGGLE_ARCHIVE,
    updateMandatorToggleArchiveData
  );

  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_SURROGATE, surrogateMandatorData);
  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_USER_SURROGATE, surrogateMandatorUserData);
  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_USER_WELCOME_MAIL,
    sendMandatorUserWelcomeMailData
  );

  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOX_POSITIONS,
    getMandatorDriveboxPositionsData
  );
  yield* takeEvery(
    MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOX_EVENTS,
    getMandatorDriveboxEventsData
  );
  yield* takeEvery(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_TRIPS, getMandatorTripsData);
  yield* takeLatest(MANDATOR_ACTION_TYPES.REQUEST_DRIVEBOX_HISTORY, getDriveBoxHistoryData);
  yield* takeLatest(MANDATOR_ACTION_TYPES.REQUEST_VEHICLE_REGISTRATION, getVehicleRegistrationData);
  yield* takeLatest(MANDATOR_ACTION_TYPES.REQUEST_DRIVEBOX_LAST_SIGNAL, getDriveBoxLastSignalData);
  yield* takeLatest(MANDATOR_ACTION_TYPES.REQUEST_USER_LAST_LOGIN, getUserLogInsData);
}
