export const MODAL_TYPES = {
    withoutValidation: "withoutValidation",
    addUser: "addUser",
    addVehicle: "addVehicle",
}

export const filterDriveboxesByModel = (driveboxes) => {
    let filteredDriveboxes = [];

    driveboxes.length > 0 && driveboxes.forEach(drivebox => {
        const existingDrivebox = findDriveboxByModel(filteredDriveboxes, drivebox);
        if(existingDrivebox) {
            filteredDriveboxes = filteredDriveboxes.map(fDrivebox => fDrivebox.model === existingDrivebox.model ? ({...fDrivebox, number: fDrivebox.number + 1}) : fDrivebox);
        } else {
            filteredDriveboxes = [...filteredDriveboxes, {model: drivebox.model, number: 1}];
        }
    });

    return filteredDriveboxes
}

const findDriveboxByModel = (array, entity) => {
    return array.find(element => element.model.toLowerCase() === entity.model.toLowerCase());
}