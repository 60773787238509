import { takeLatest, put, call } from "typed-redux-saga/macro";

import { receiveStatus, COMMON_ACTION_TYPES } from "../actions/actionCommon";
import { handleError } from "../managers/sagaManager";
import { getVersion } from "./api";

import { RequestStatus } from "../types/actions/actionCommon.types";

function* getVersionData(action: RequestStatus) {
    try {
        const response = yield* call(getVersion, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveStatus(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

export default function* sagaLiveLocation() {
    yield* takeLatest(COMMON_ACTION_TYPES.REQUEST_STATUS, getVersionData);
}
