import { useState } from "react";

export const useModalStatus = () => {
    const [modal, setModalOpen] = useState(false);

    const open = () => setModalOpen(true);
    const close = () => setModalOpen(false);

    return { modal, open, close };
};

export const useWizardSteps = () => {
    const [step, setStep] = useState(0);
    const nextStep = () => setStep(step + 1);
    const prevStep = () => {
        if (step > 0) setStep(step - 1);
    };

    return { step, nextStep, prevStep };
};

export const useContextMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = anchorEl => setAnchorEl(anchorEl);
    const close = () => setAnchorEl(null);

    return { anchorEl, open, close };
};

export const useSnackbar = () => {
    const [open, openSnackbar] = useState(false);

    const show = () => openSnackbar(true);
    const hide = () => openSnackbar(false);

    return { open, show, hide };
};