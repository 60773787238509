import React, {useState} from "react";
import CustomSelect from "../../common/selects/Select";
import {Select} from "antd";
import PropTypes from "prop-types";
import {dayOfTheWeekArray} from "../../../models/TimeRange";

const {Option} = Select;
const DayOfTheWeekSelect = props => {

    const {onChange, value} = props;

    return <CustomSelect value={dayOfTheWeekArray[value - 1]?.id}
                         allowClear={false}
                         handleChange={onChange}
                         placeholder="Montag">
        {dayOfTheWeekArray.map(p => {
            return <Option key={p.id} value={p.id}>
                {p.name}
            </Option>
        })}
    </CustomSelect>
};

// DayOfTheWeekSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string
// }

export default DayOfTheWeekSelect;