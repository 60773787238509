const tripType = {
    Business: 0,
    Private: 1,
    DriveToWork: 2,
};

const tripTypeArray = [
    {id: tripType.Business, name: "Business", translateKey: "model.triptype.business"},
    {id: tripType.Private, name: "Private", translateKey: "model.triptype.private"},
    {id: tripType.DriveToWork, name: "Drive to work", translateKey: "model.triptype.driveToWork"}
];

export {tripTypeArray, tripType};
