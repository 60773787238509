import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestAuthentication,
  ReceiveAuthentication,
  ReceiveAuthoriseUser,
  ReceiveUnAuthoriseUser,
  RequestLogout,
  ReceiveLogout
} from "../types/actions/actionAuth.types";

import { LoginRequest } from "../types/auth/authRequest.types";
import { UserResponseInfo } from "../types/user/userResponse.types";

export enum AUTH_ACTION_TYPES {
  REQUEST_AUTHENTICATION = "REQUEST_AUTHENTICATION",
  RECEIVE_AUTHENTICATION = "RECEIVE_AUTHENTICATION",
  RECEIVE_AUTHORISE_USER = "RECEIVE_AUTHORISE_USER",
  RECEIVE_UNAUTHORISE_USER = "RECEIVE_UNAUTHORISE_USER",

  REQUEST_LOGOUT = "REQUEST_LOGOUT",
  RECEIVE_LOGOUT = "RECEIVE_LOGOUT",
}

export const requestAuthentication = withMatcher(
  (data: LoginRequest): RequestAuthentication => {
    return createAction(AUTH_ACTION_TYPES.REQUEST_AUTHENTICATION, data);
});

export const receiveAuthentication = withMatcher(
  (data: UserResponseInfo): ReceiveAuthentication => {
    return createAction(AUTH_ACTION_TYPES.RECEIVE_AUTHENTICATION, data);
});

export const receiveAuthoriseUser = withMatcher((): ReceiveAuthoriseUser => {
  return createAction(AUTH_ACTION_TYPES.RECEIVE_AUTHORISE_USER);
});

export const receiveUnAuthoriseUser = withMatcher((): ReceiveUnAuthoriseUser => {
    return createAction(AUTH_ACTION_TYPES.RECEIVE_UNAUTHORISE_USER);
});

export const requestLogout = withMatcher((): RequestLogout => {
    return createAction(AUTH_ACTION_TYPES.REQUEST_LOGOUT);
});

export const receiveLogout = withMatcher(
    (data: UserResponseInfo): ReceiveLogout => {
      return createAction(AUTH_ACTION_TYPES.RECEIVE_LOGOUT, data);
});
