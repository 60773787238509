function countSelected(entities) {
    return entities.filter(e => e.isSelected && !e.lockedAt).length;
}

export function loadEntities(state) {
    return {
        ...state,
        loading: true,
    };
}

export function getEntities(state, data) {
    return {
        ...state,
        entities: data.map(d => {
            d.isSelected = false;
            d.visibility = true;
            d.highlighted = false;
            return d;
        }),
        pageLoading: false,
        loading: false,
    };
}

export function getPagedEntities(state, data) {
    return {
        ...state,
        entities: data.entities.map(d => {
            d.isSelected = false;
            d.visibility = true;
            d.highlighted = false;
            return d;
        }),
        total: data.total,
        pageLoading: false,
        loading: false,
    };
}

export function getAllPagedEntities(state, data) {
    return {
        ...state,
        allEntities: data.entities.map(d => {
            d.isSelected = false;
            d.visibility = true;
            d.highlighted = false;
            return d;
        })
    };
}

export function getEntityById(state, data) {
    return {
        ...state,
        current: data,
        entities: state.entities.some(e => e.id == data.id) ? state.entities : [...state.entities, data],
        loading: false,
    };
}

export function addEntity(state, data) {
    return {...state, entities: [...state.entities, data], loading: false, total: state.total +1};
}

export function addEntities(state, data) {
    return {...state, entities: [...state.entities, ...data], loading: false};
}

export function updateEntity(state, data) {
    return {
        ...state,
        entities: state.entities.map(o => (o.id === data.id ? data : o)),
        current: {...data, hasBeenChanged: true},
        loading: false,
    };
}

export function updateEntities(state, data) {
    let newEntities = state.entities.map(o => data.find(e => e.id == o.id) || o);
    return {
        ...state,
        entities: newEntities,
        selected: countSelected(newEntities),
    };
}

export function updateSelection(state, data) {
    return {
        ...state,
        entities: state.entities.map(o => {
            if (o.id == data.id) {
                o.isSelected = !o.isSelected;
            }
            return o;
        }),
        selected: countSelected(state.entities),
    };
}

export function selectAllEntities(state) {
    var isAllSelected = state.entities.filter(e => !e.lockedAt).every(o => o.isSelected);
    return {
        ...state,
        entities: state.entities.map(o => {
            o.isSelected = o.lockedAt ? o.isSelected : !isAllSelected;
            return o;
        }),
        selected: countSelected(state.entities),
    };
}

export function deselectAllEntities(state) {
    return {
        ...state,
        entities: state.entities.map(o => {
            o.isSelected = false;
            return o;
        }),
        selected: 0,
    };
}

export function deleteEntity(state, data) {
    return {
        ...state,
        entities: state.entities.filter(o => o.id !== data.id),
        selected: countSelected(state.entities),
        pageLoading: false,
        total: state.total -1
    };
}

export function deleteEntities(state, data) {
    return {
        ...state,
        entities: state.entities.filter(o => !data.some(e => e.id === o.id)).map(e => {
            e.isSelected = false;
            return e;
        }),
        selected: 0,
        pageLoading: false,
    }
}

export function getError(data) {
    let errorMsg = "";
    if (data.errors) {
        errorMsg = Object.keys(data.errors)
            .map(e => e + " " + data.errors[e])
            .join(". ");
    }
    return {
        message: errorMsg,
        status: data.status,
        entities: data.errors,
        updatedAt: Date.now(),
    };
}
