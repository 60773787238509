enum PACKAGES {
    Tracking = 1,
    Logbook = 2,
    TimeLog = 4,
    CostCenter = 8,
    Geofences = 16,
    Reservations = 32,
    StateOfCharge = 64,
    QualityCharging = 128,
    DriverIdentification = 256,

}

export type PackageName = Extract<keyof typeof PACKAGES, string>;

type PackageInfo = {
    id: PACKAGES,
    name: PackageName,
}
  
  const packageArray: PackageInfo[] = [
    { id: PACKAGES.Tracking, name: "Tracking" },
    { id: PACKAGES.Logbook, name: "Logbook" },
    { id: PACKAGES.TimeLog, name: "TimeLog" },
    { id: PACKAGES.CostCenter, name: "CostCenter" },
    { id: PACKAGES.Geofences, name: "Geofences" },
    { id: PACKAGES.Reservations, name: "Reservations" },
    { id: PACKAGES.StateOfCharge, name: "StateOfCharge" },
    {id: PACKAGES.QualityCharging, name: "QualityCharging"},
    {id: PACKAGES.DriverIdentification, name: "DriverIdentification"}
  ];
  
  const findPackageByID = (id: PACKAGES) => {
    return packageArray.find((p) => p.id == id);
  };
  
  export { PACKAGES, packageArray, findPackageByID };
  