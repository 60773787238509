import React, { useEffect } from "react";
import PropTypes from "prop-types";
import EditModal from "../../common/modals/CustomModal";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { requestVehicles } from "../../../actions/actionVehicle";

import {
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";

const VehicleModal = (props) => {
  const { userId, onClose } = props;
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.vehicles.entities);
  const filter = useSelector((state) => state.filter);
  const { api } = filter;

  useEffect(() => {
    if (userId)
      dispatch(
        requestVehicles({
          vehicleFrom: api.from,
          vehicleTo: api.to,
          driverIds: [userId],
        })
      );
  }, [userId]);

  const contents = [
    <Grid container>
      <Grid item xs={12}>
        <List>
          {vehicles.map((v) => {
            const name = v.name;
            const shortForm = v.name ? v.name.charAt(0) : "";
            const licensePlate = v.licensePlate;

            return (
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar aria-label={name}>{shortForm}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={licensePlate} />
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>,
  ];

  return (
    <EditModal
      largeModal
      onClose={onClose}
      title="Fahrzeuge"
      contents={contents}
    />
  );
};

// VehicleModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   userId: PropTypes.string.isRequired,
// };

export default VehicleModal;
