import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

import {menu} from "../../../managers/pathManager";

import {useModalStatus} from "../../../hooks/useModalStatus";
import {usePagination} from "../../../hooks/usePagination";

import {requestMandators, requestMandatorUsers, requestMandatorUserSurrogate, requestVehicleRegistration, loadDriveBoxHistory, requestLastLogIns} from "../../../actions/actionMandators";
import {requestMandatorUserExportXlsx} from "../../../actions/actionExport";

import Page from "../../common/container/Page";
import Toolbar from "../../common/toolbar/Toolbar";
import MandatorSelect from "../../common/selects/specificSelects/MandatorSelect";

import MandatorUserTable from "./MandatorUserTable";
import MandatorUserWelcomeMailModal from "./MandatorUserWelcomeMailModal";
//Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import MandatorUserInsertModal from "./MandatorUserInsertModal";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Switch from "../../common/inputs/Switch";

import MandatorVehicleRegistrationModal from "./MandatorVehicleRegistrationModal";
import LastLogInsModal from "./LastLogInsModal";

const MandatorUserPage = props => {
    const [user, setUser] = useState({});
    const [mandatorId, setMandatorId] = useState(undefined);

    const [searchTerm, setSearchTerm] = useState("");
    const [surrogate, startSurrugate] = useState(false);
    const [archived, setArchived] = useState(false);

    const {page, rowsPerPage, changePage, changeRowPerPage} = usePagination();
    const {modal: welcomeMailModal, open: openWelcomeMailModal, close: closeWelcomeMailModal} = useModalStatus();
    const {modal: csvImport, open: openCSVImport, close: closeCSVImport} = useModalStatus();
    const {modal: vehicleRegistration, open: openVehicleRegistration, close: closeVehicleRegistration} = useModalStatus();
    const {modal: lastLogin, open: openLastLogin, close: closeLastLogin} = useModalStatus();

    useEffect(() => {
        requestMandatorUsers();
    }, [page, rowsPerPage, searchTerm, mandatorId, archived]);

    function requestMandatorUsers() {
        props.requestMandatorUsers({
            page: page + 1,
            perPage: rowsPerPage,
            mandatorId,
            searchTerm,
            IsArchived: archived
        });
    }

    function handleArchivedChange(event) {
        setArchived(event.target.checked);
    }

    const actionButtons = [
        {
            icon: <ImportExportIcon/>,
            translateKey: "mandatorDriveboxes.page.csvImport",
            onClick: openCSVImport,
        },
        {
            icon: <GetAppIcon/>,
            translateKey: "mandator.page.export",
            onClick: () => props.requestMandatorUserExportXlsx({mandatorId, searchTerm}),
        },
    ];

    function onVehicleRegistration(user) {
        props.loadDriveBoxHistory();
        props.requestVehicleRegistration(user.id);
        openVehicleRegistration();
    }

    function onLastLogIns(user){
        props.loadDriveBoxHistory();
        props.requestLastLogIns(user.id);
        openLastLogin();
    }

    const toolbar = (
        <Toolbar actionButtons={actionButtons}
                 onSearch={searchTerm => setSearchTerm(searchTerm)}
                 controls={[<Switch value={archived} label="Archived user"
                                    handleChange={handleArchivedChange} toolbar/>
                     , <MandatorSelect value={mandatorId} onChange={setMandatorId}/>]}/>
    );

    if (surrogate) {
        return <Redirect to={menu.dashboard.path}/>;
    }

    return (
        <Page activePage={menu.mandatorUser.key} toolbar={toolbar}>
            <MandatorUserTable
                archived={archived}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={changePage}
                handleChangeRowsPerPage={changeRowPerPage}
                surrogateUser={u => {
                    props.requestMandatorUserSurrogate(u.id);
                    localStorage.clear();
                    startSurrugate(true);
                }}
                onWelcomeMail={u => {
                    setUser(u);
                    openWelcomeMailModal();
                }}
                vehicleRegistration={onVehicleRegistration}
                lastLogIns={onLastLogIns}
            />
            {welcomeMailModal && <MandatorUserWelcomeMailModal user={user} onClose={closeWelcomeMailModal}/>}
            {csvImport && <MandatorUserInsertModal onClose={closeCSVImport}/>}
            {vehicleRegistration && <MandatorVehicleRegistrationModal onClose={closeVehicleRegistration}/>}
            {lastLogin && <LastLogInsModal onClose={closeLastLogin} />}
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestMandators,
            requestMandatorUsers,
            requestMandatorUserSurrogate,
            requestMandatorUserExportXlsx,
            requestVehicleRegistration,
            loadDriveBoxHistory,
            requestLastLogIns
        },
        dispatch
    );
}

function mapStateToProp(state) {
    return {};
}

// MandatorUserPage.propTypes = {
//     classes: PropTypes.object,
//     theme: PropTypes.object,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(MandatorUserPage);
