import { withMatcher, createAction } from "../../utils/reducer/reducer.utils";

import { RequestMisMatchTrips, ReceiveMisMatchTrips } from "../../types/actions/faultyTrips/actionMisMatchTrips.types";

export enum MISMATCH_TRIPS_ACTION_TYPES {
  REQUEST_MISMATCH_TRIPS = "REQUEST_MISMATCH_TRIPS",
  RECEIVE_MISMATCH_TRIPS = "RECEIVE_MISMATCH_TRIPS",
}
 
// Type "any" needs to change to a more specific type later
export const requestMisMatchTrips = withMatcher(
  (data: any): RequestMisMatchTrips => {
    return createAction(MISMATCH_TRIPS_ACTION_TYPES.REQUEST_MISMATCH_TRIPS, data);
  }
);

export const receiveMisMatchTrips = withMatcher(
  (data: any): ReceiveMisMatchTrips => {
    return createAction(MISMATCH_TRIPS_ACTION_TYPES.RECEIVE_MISMATCH_TRIPS, data);
  }
);

