import React from 'react';
import CustomModal from '../../common/modals/CustomModal';
import { modalActions } from '../../common/modals/CustomModal';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestDeleteTrip } from '../../../actions/actionTrips';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles({
    textCenter: {
        textAlign: "center"
    }
})

const TripDeleteModal = (props) => {

    const { onClose, title, type, data } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    function deleteManualCreatedTrip() {
        props.requestDeleteTrip(data.id)
        onClose()
    }

    const content = [
        <div>
            <h5 className={classes.textCenter} >{t("trip.deleteTripModal.deleteTripConfirmMsg")}</h5>
           <Trans><p className={classes.textCenter}>{t("trip.deleteTripModal.deleteTripModalMsg", {mappedDriver: data.mappedDriver, mappedVehicle :data.mappedVehicle, mappedStartAddress: data.mappedStartAddress, mappedDestinationAddress :data.mappedDestinationAddress})}</p></Trans> 
        </div>
    ]

    return (
       <CustomModal onClose={onClose} contents={content} title={title} type={type} action={modalActions.delete} onSave={deleteManualCreatedTrip}/>
    )}


    function mapDispatchToProps(dispatch){
        return bindActionCreators({requestDeleteTrip}, dispatch)
     }

     export default connect(null, mapDispatchToProps)(TripDeleteModal);
