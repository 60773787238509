import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const CustomModalTabs = ({ tabBarState, setTabBar, tabs }) => {
  const handleTabBarChange = (_, value) => {
    setTabBar(value);
  };

  return (
    <Tabs
      value={tabBarState}
      onChange={handleTabBarChange}
      indicatorColor="primary"
      textColor="primary"
    >
      {tabs.map((tab, idx) => (
        <Tab key={`tab-${idx}`} label={tab} />
      ))}
    </Tabs>
  );
};

export default CustomModalTabs;
