import React from "react";
import { Select } from "antd";
import CustomSelect from "../common/selects/Select";
import { useTranslation } from "react-i18next";


const { Option } = Select;

const AssignedDriveBoxSelect = props => {
    const { onChange, value } = props;
    const { t } = useTranslation();

    const options = [
        { id: 0, name: t("drivebox.page.all") },
        { id: 1, name: t("drivebox.page.assigned") },
        { id: 2, name: t("drivebox.page.unassigned") },
    ];

    return (
        <CustomSelect value={value} handleChange={onChange} allowClear={false}>
            {options.map(tt => (
                <Option key={tt.id} value={tt.id}>
                    {tt.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

export default AssignedDriveBoxSelect;
