import React from "react";
import PropTypes from "prop-types";
import {Timeline} from "antd";
import {addressToStr} from "../../managers/locationManager";
import {secondsToString, serverTimeToSeconds} from "../../managers/timeManager";
import {format} from "date-fns";
import {tripTypeArray} from "../../models/TripType"
import classNames from "classnames";
import {allowedRoles, isAuthorized, isViewer} from "../../managers/authManager";
import {packages} from "../../models/Package";
import {roles} from "../../models/Role";
import {connect, useSelector, shallowEqual} from "react-redux";

// icons
import {
    DriveEta as CarIcon,
    Timer as TimerIcon,
    Person as UserIcon,
    Gesture as LengthIcon,
    BusinessCenter as BriefcaseIcon,
    Room as TripDestinationMarkerIcon,
    RadioButtonUnchecked as TripOriginIcon,
    FiberManualRecordOutlined as DotIcon,
    Place as DestinationIcon,
    AccountBalance as AccountBalance,
    MonetizationOn as MonetizationOn
} from "@material-ui/icons"
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import FuelInCircleIcon from "../../icons/FuelInCircleIcon";
import { calculateFuelOrBatteryTripData } from "../../utils/CalculateFuelLevel";


const useStyles = makeStyles(theme => ({
    generalInfo: {
        paddingBottom: "25px",
        "& .MuiGrid-root": {
            display: "flex",
            alignItems: "center",
            margin: "4px 0",
            "& svg": {
                marginRight: "5px",
                color: "#0000008a"
            }
        }
    },
    timelineIcon: {
        color: theme.palette.primary.main
    },
    iconSmaller: {
        fontSize: "1.3rem"
    }
}));

const OriginDestinationTimeline = ({showDetailInfo, trip}) => {
    const { averageFuelConsumption, userSelectedEngineType, overConsumption, underConsumption } = calculateFuelOrBatteryTripData(trip);
    const engineUnit = userSelectedEngineType === "Fuel" ? "L" : "kWh";

    const {startAddress, destinationAddress, startedAt, finishedAt, duration, type, mileageInMeters, description, costcenterKey} = trip;
    const classes = useStyles();

    const me = useSelector(state => state.users.me);
    const isUserAuthorized = isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor]);

    let tripTypeName = "Business";
    if (tripTypeArray.find(tt => tt.id === type) !== undefined) {
        tripTypeName = tripTypeArray.find(tt => tt.id === type).name;
    }

    let driverName = trip.driver ? trip.driver.name : "";
    if (driverName === "") {
        driverName = "Nicht angegeben"
    }

    let costcenterName = costcenterKey;
    if (costcenterKey == null) costcenterName = "keine zugewiesen";

    const generateInfo = (iconComponent, text, size) => (
        <Grid item sm={size || 6}>
            {iconComponent} {text}
        </Grid>
    );

    const costCenterInformation = isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]) ? generateInfo(<MonetizationOn />, "Kostenstelle: " + costcenterName, 12) : <></>;
    const fuelConsumptionInformation = isUserAuthorized && (
    <div style={{marginTop: 4, marginLeft: 2, display: "flex"}}>
        <FuelInCircleIcon color={overConsumption ? "red" : underConsumption ? "green" : undefined } />
        <p style={{marginLeft: 5, marginBottom: 0}}>Verbrauch: <span style={{color: overConsumption ? "red" : underConsumption ? "green" : undefined }}>{averageFuelConsumption}{engineUnit}/100km</span> </p>
    </div>
    )
    const mileageInfo = (Math.round(mileageInMeters / 10) / 100 < 1) ? "< 1 km" : 
                        Math.round(mileageInMeters / 10) / 100 + "km";
    const generalInfo = (
        <>
            <Grid container className={classes.generalInfo}>
                {generateInfo(<TimerIcon/>, secondsToString(serverTimeToSeconds(duration)))}
                {generateInfo(<LengthIcon/>, mileageInfo)}
                {generateInfo(<UserIcon/>, driverName)}
                {generateInfo(<CarIcon/>, trip.vehicle ? trip.vehicle.name : "")}
                {trip.mileageInformation && generateInfo(
                    <DotIcon/>, Math.round(trip.mileageInformation.vehicleMileageInMetersBefore / 10) / 100 + "km")}
                {trip.mileageInformation && generateInfo(
                    <DestinationIcon/>, Math.round(trip.mileageInformation.vehicleMileageInMetersAfter / 10) / 100 + "km")}

                {generateInfo(<BriefcaseIcon/>, tripTypeName, 12)}
                {costCenterInformation}
                {fuelConsumptionInformation}
            </Grid>
            {description && (
                <>
                    <h3>Description</h3>
                    <p> {description}</p>
                </>
            )}
        </>
    );
    return (
        <div>
            <Timeline style={{marginTop: "20px", marginLeft: "2px"}}>
                <Timeline.Item
                    dot={<TripOriginIcon className={classNames(classes.timelineIcon, classes.iconSmaller)}/>}>
                    <b> {startAddress && addressToStr(startAddress)}</b> <br/>
                    {startedAt ? format(new Date(startedAt), "dd.MM.yy H:mm") : ""} <br/>
                </Timeline.Item>

                <Timeline.Item dot={<TripDestinationMarkerIcon className={classes.timelineIcon}/>}>
                    <b>{destinationAddress && addressToStr(destinationAddress)}</b>
                    <br/>
                    {finishedAt ? format(new Date(finishedAt), "dd.MM.yy H:mm") : ""}
                </Timeline.Item>
            </Timeline>
            {showDetailInfo && generalInfo}
        </div>
    );
};

// OriginDestinationTimeline.propTypes = {
//     trip: PropTypes.object.isRequired,
//     showDetailInfo: PropTypes.bool,
// };

export default OriginDestinationTimeline;
