import { getPagedEntities } from "../../managers/reducerManager";

import { receiveUnrealisticTrips } from "../../actions/faultyTrips/actionUnrealisticTrips";

import { AnyAction } from "redux";

export type UnrealisticTripsState = {
  readonly entities: any[];
  readonly total: number;
  readonly loading: boolean;
};

const UNREALISITC_TRIPS_INITIAL_STATE: UnrealisticTripsState = {
  entities: [],
  total: 0,
  loading: false,
};

export const unrealisticTripsReducer = (
  state = UNREALISITC_TRIPS_INITIAL_STATE,
  action: AnyAction
): UnrealisticTripsState => {
  if (receiveUnrealisticTrips.match(action)) {
    return getPagedEntities(state, action.data);
  }

  return state;
};
