import React from "react";
import TesterToolbar from "../common/toolbar/TesterToolbar";
import Page from "../common/container/Page";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
}));

const TestExpiredPage = props => {
    const classes = useStyles();

    return <Page disableDrawer toolbar={<div className={classes.toolbar}/>}>
        
    </Page>

};

export default TestExpiredPage;