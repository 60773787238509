import { useState } from "react";

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(localStorage[key] ? JSON.parse(localStorage[key]) : defaultValue);

    const set = value => {
        localStorage.setItem(key, JSON.stringify(value));
        setValue(value);
    };

    return { value, set };
};
