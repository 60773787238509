import React from "react";
import {Select} from "antd";
import CustomSelect from "../Select";
import { archivedStatusArray } from "../../../../models/AchrivedReservation";
import { getTranslation } from "../../../../translations/translations";

const {Option} = Select;

const ArchivedReservationSelect = (props) => {
    const { onChange, value} = props

    return (
      <CustomSelect
      handleChange={onChange}
      placeholder="Archive Status"
      value={value}
      >
         {archivedStatusArray.map(ar => (
                <Option key={ar.id} value={ar.id}>
                    {getTranslation(ar.name)}
                </Option>
            ))}
      </CustomSelect>
    )
}

export default ArchivedReservationSelect;