export const wizardModalStyles = (theme) => {
    return {
        root: { minHeight: "400px" },
        content: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        mobileStepper: {
          backgroundColor: "transparent",

          "& .MuiMobileStepper-dots": {
            marginLeft: "75px",
          },
        },
        hide: { display: "none" },
        completeButton: {
          padding: '7px',
        }, 
        form: {
          width: "100%",
          marginBottom: "15px",
        },
        tripFormInput: { marginRight: "35px", marginBottom: "34px"},
      };
} 

export const wizardformItemLayout = {
  labelCol: {
    md: { span: 24 },
    lg: { span: 7 },
  },
  wrapperCol: {
    md: { span: 24 },
    lg: { span: 17 },
  },
};
