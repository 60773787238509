import {useState, useEffect} from "react";

const useResize = (myRef) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setWidth(myRef.current.offsetWidth);
        setHeight(myRef.current.offsetHeight);
    };

    useEffect(() => {
        if (myRef.current) {
            handleResize();
            window.addEventListener('resize', handleResize);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [myRef.current]);

    return {width, height}
};

export default useResize;