import { AnyAction } from "redux";

import {
  receiveAuthoriseUser,
  receiveLogout,
  receiveUnAuthoriseUser,
  receiveAuthentication,
} from "../actions/actionAuthentication";

import { resetSessionLocalStorage } from "../managers/localStorageManager";

//default we assume that the user is logged on
export const authorisedReducer = (state = true, action: AnyAction) => {
  if (receiveAuthoriseUser.match(action)) {
    return true;
  }

  if (receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
    resetSessionLocalStorage();
    return false;
  }

  return state;
};

export const authentication = (state = {}, action: AnyAction) => {
  if (receiveAuthentication.match(action)) {
    return action.data;
  }
  return state;
};
