import {createMuiTheme} from "@material-ui/core/styles"

export default createMuiTheme({
    palette: {
        primary: {main: "#203159"},
        secondary: {main: "rgba(0, 0, 0, 0.54)"},
        error: {main: "#ff0000"},
        errorBadge : { main: "#d52e3e", secondary: "#e54638" }
    }
});
