import { takeLatest, put, call } from "typed-redux-saga/macro";

import { handleError } from "../managers/sagaManager";

import {
  RequestReservationReport,
  RequestReservations,
  RequestAddReservation,
  RequestDeleteReservation,
  RequestUpdateReservations,
  RequestAvailableVehicles,
  RequestAvailableDrivers,
  RequestFilterReservations,
} from "../types/actions/actionReservation.types";

import {
  addReservation,
  deleteReservation,
  getAvailableVehicles,
  getAvailableDrivers,
  getReservations,
  updateReservation,
  getFilteredReservations,
  getReservationReportData,
} from "./api";
import {
  receiveAddReservation,
  receiveDeleteReservation,
  receiveReservations,
  receiveUpdateReservations,
  receiveAvailableDrivers,
  receiveFilterReservations,
  receiveReservationReport,
  receiveAvailableVehicles,
  toggleReservationSuccessMsgVisibility,
  RESERVATION_ACTION_TYPES,
} from "../actions/actionReservation";

function* getReservationReports(action: RequestReservationReport) {
  try {
    const response = yield* call(getReservationReportData, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveReservationReport(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getReservationData(action: RequestReservations) {
  try {
    const response = yield* call(getReservations, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveReservations(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addReservationData(action: RequestAddReservation) {
  try {
    const response = yield* call(addReservation, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddReservation(response.data));
      yield* put(toggleReservationSuccessMsgVisibility(true));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateReservationData(action: RequestUpdateReservations) {
  try {
    const response = yield* call(updateReservation, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateReservations(response.data));
      yield* put(toggleReservationSuccessMsgVisibility(true));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteReservationData(action: RequestDeleteReservation) {
  try {
    const response = yield* call(deleteReservation, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteReservation(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getAvailableVehiclesData(action: RequestAvailableVehicles) {
  try {
    const response = yield* call(getAvailableVehicles, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAvailableVehicles(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getAvailableDriversData(action: RequestAvailableDrivers) {
  try {
    const response = yield* call(getAvailableDrivers, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAvailableDrivers(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getFilterReservationData(action: RequestFilterReservations) {
  try {
    const response = yield* call(
      getFilteredReservations,
      action.data.filterData
    );
    if (response.status >= 200 && response.status < 300) {
      yield* put(
        receiveFilterReservations({
          ...response.data,
          isFilterActive: action.data.isFilterActive,
        })
      );
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaReservation() {
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_RESERVATIONS,
    getReservationData
  );
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_ADD_RESERVATION,
    addReservationData
  );
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_AVAILABLE_VEHICELS,
    getAvailableVehiclesData
  );
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_AVAILABLE_DRIVERS,
    getAvailableDriversData
  );
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_UPDATE_RESERVATION,
    updateReservationData
  );
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_DELETE_RESERVATION,
    deleteReservationData
  );
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_FILTER_RESERVATIONS,
    getFilterReservationData
  );
  yield* takeLatest(
    RESERVATION_ACTION_TYPES.REQUEST_RESERVATION_REPORTS,
    getReservationReports
  );
}
