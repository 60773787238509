import React from "react";
import { Select } from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import { packageArray } from "../../../../models/Package";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const PackageSelect = ({ onChange, value, disabled, multiple }) => {

    const { t } = useTranslation();

    if (multiple) return <MultiSelect placeholder={t("common.select.packages")} selectedArray={value}
                                      array={packageArray}
                                      handleChange={onChange}/>;
    return (
        <CustomSelect value={value} placeholder={t("common.select.package")} handleChange={onChange}
                      disabled={disabled}>
            {packageArray.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

PackageSelect.defaultProps = {
    disabled: false,
    multiple: false,
};

export default PackageSelect;
