import React, {useEffect, useRef} from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    "& label.MuiFormLabel-root": {
        backgroundColor: "#ffffff",
        padding: '0 5px'
      },
      "& .MuiInputBase-input": { 
        color: 'black !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px !important'
    },
  },
};

const InputField = (props) => {
    const {label, rows, children, select, type, shrink, startAdornment, value, disabled, onChange, variant, onBlur, error, name, autoFocus, classes, isRequired, helperText} = props;
    const textFieldRef = useRef(null);

    useEffect(() => {
        if (autoFocus && value) textFieldRef.current.childNodes[textFieldRef.current.childNodes.length - 1].childNodes[0].focus();
    }, []);

    return (
        <TextField
            required={isRequired}
            ref={textFieldRef}
            label={label}
            name={name}
            margin="dense"
            helperText={error ? helperText : ""}
            fullWidth
            variant={variant}
            value={value ? value : ""}
            onChange={onChange}
            InputLabelProps={shrink ? {shrink: true} : {}}
            InputProps={startAdornment ? {
                startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>
            } : {}}
            type={type}
            rows={rows ? rows : 1}
            multiline={rows}
            disabled={disabled}
            select={select}
            onBlur={onBlur}
            error={error}
            className={classes.root}
        >
            {children}
        </TextField>
    );
};

InputField.defaultProps = {
    variant: "standard",
    type: "text",
    disabled: false,
};

// InputField.propTypes = {
//     variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
//     label: PropTypes.string.isRequired,
//     rows: PropTypes.number,
//     disabled: PropTypes.bool,
//     children: PropTypes.object,
//     select: PropTypes.bool,
//     type: PropTypes.string,
//     shrink: PropTypes.bool,
//     startAdornment: PropTypes.object,
//     value: PropTypes.string,
//     onChange: PropTypes.func,
//     onBlur: PropTypes.func,
//     error: PropTypes.bool,
//     name: PropTypes.string,
//     autoFocus: PropTypes.bool,
// };

export default withStyles(styles)(InputField);