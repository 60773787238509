import React from "react";
import PropTypes from "prop-types";
import CustomSelect from "../../common/selects/Select";
import {possiblePersonGroupArray} from "../../../models/PossiblePersonGroup";
import {Select} from "antd";

const {Option} = Select;

const SendToPersonGroupSelect = props => {
    const {onChange, value} = props;

    return <CustomSelect value={value}
                         allowClear={false}
                         handleChange={onChange}
                         placeholder="Personen">
        {possiblePersonGroupArray.map(p => {
            return <Option key={p.id} value={p.id}>
                {p.name}
            </Option>
        })}
    </CustomSelect>
}


// SendToPersonGroupSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string,
// };

export default SendToPersonGroupSelect;