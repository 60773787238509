import React from "react";
import PropTypes from "prop-types";
import {MobileDatePicker, MobileDateTimePicker} from "@material-ui/pickers";
import CustomDatePicker from "./CustomDatePicker";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    inputStyle: {
        '& .MuiInputBase-input': {
          color: 'black !important',
          fontWeight: '400 !important'
        },
      },
    dateTimeLabel: {
        '& .MuiTypography-overline': {
            fontSize: '15px'
        },
        '& .MuiDateTimePickerToolbar-timeContainer': {
            marginLeft: '20px',
        },
        '& .MuiTypography-h3': {
            fontSize: '32px'
        }
    }
})
const CustomDateTimePicker = ({views, onChange, value, minDate, disableFuture, disablePast, format, renderDay, labelFunc, variant}) => {
    const viewProp = views && views.length > 0 ? {views: views} : {};
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <MobileDateTimePicker
            {...viewProp}
            value={value}
            minDate={minDate}
            toolbarTitle="Datum auswählen"
            renderInput={props => <TextField style={{width: "100%"}} className={classes.inputStyle}  variant={variant} {...props} />}
            onChange={onChange}
            minDateMessage={t("common.datePicker.minDateMessage")}
            maxDateMessage={t("common.datePicker.maxDateMessage")}
            invalidDateMessage={t("common.datePicker.invalidDateMessage")}
            renderDay={renderDay}
            labelFunc={labelFunc}
            format={format}
            autoOk
            style={{width: "100%"}}
            disablePast={disablePast}
            disableFuture={disableFuture}
            className={classes.dateTimeLabel}
            />
            
    );
};
CustomDateTimePicker.defaultProps = {
    variant: "outlined"
};

export default CustomDateTimePicker;