import React, {useState, useEffect} from "react";

import PropTypes from "prop-types";

import EditModal from "../../common/modals/CustomModal";
import CustomSelect from "../../common/selects/Select";
import {Select} from "antd";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
//model
import VehicleDesignation from "../../../models/VehicleDesignation";

const {Option} = Select;
const vehicleDesignationArray = [
    {
        value: VehicleDesignation.licensePlate,
        label: "Kennzeichen",
    },
    {
        value: VehicleDesignation.name,
        label: "Name",
    },
];

const LiveLocationSettingModal = props => {
    const [settings, setSettings] = useState(props.settings);
    const {onClose} = props;

    function handleSwitchChange(event) {
        setSettings({...settings, [event.target.name]: event.target.checked});
    }

    function handleChange(value) {
        setSettings({...settings, tooltipText: value});
    }

    // TODO translations
    const generalDialogContent = (
        <div>
            <FormControlLabel
                control={<Switch onChange={handleSwitchChange}
                                 name="showStandingVehicles"
                                 checked={settings.showStandingVehicles}
                                 color="primary"/>}
                label="Stehende Fahrzeuge anzeigen?"
            />
            <FormControlLabel
                control={<Switch onChange={handleSwitchChange}
                                 name="showSpeed"
                                 checked={settings.showSpeed}
                                 color="primary"/>}
                label="Geschwindigkeit im Label anzeigen?"
            />
            <CustomSelect value={settings.tooltipText}
                          placeholder="Fahrzeugbezeichnung"
                          handleChange={handleChange}
                          allowClear={false}>
                {vehicleDesignationArray.map(o => (
                    <Option key={o.value} value={o.value}>
                        {o.label}
                    </Option>
                ))}
            </CustomSelect>
        </div>
    );
    const contents = [generalDialogContent];
    return <EditModal action="confirm" onSave={() => props.onSave(settings)} onClose={onClose} title="Setting"
                      contents={contents}/>;
};

LiveLocationSettingModal.defaultProps = {
    settings: {},
};

// LiveLocationSettingModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     onSave: PropTypes.func.isRequired,
//     settings: PropTypes.object,
// };

export default LiveLocationSettingModal;
