import React, {Component, useEffect} from "react";
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {Alert} from "antd";
import {connect} from "react-redux";

import {clearError} from "../../../actions/actionCommon";

const styles = theme => ({
    toolbar: {
        width: "100%",
        margin: "0px 0px 7px 0px",
    },
});

const ErrorToolbar = props => {
    const {classes, error, closable, hideTitle, className} = props;

    useEffect(() => {
        return () => {
            props.clearError();
        }
    }, []);

    if (error && error.updatedAt)
        return <div className={className}>
            <Alert className={classes.toolbar}
                   message={error.message}
                   type="error"
                   closable={closable}/>
        </div>;
    return <div/>;
};

function mapStateToProp(state) {
    return {error: state.error};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({clearError}, dispatch);
}

ErrorToolbar.defaultProps = {
    closable: false,
};

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withStyles(styles, {withTheme: true})(ErrorToolbar));
