import { getPagedEntities } from "../../managers/reducerManager";

import { receiveLogsData } from "../../actions/faultyTrips/actionLogs";

import { AnyAction } from "redux";

export type LogsState = {
  readonly entities: any[];
  readonly total: number;
};

const LOGS_INITIAL_STATE: LogsState = {
  entities: [],
  total: 0,
};

export const logsReducer = (
  state = LOGS_INITIAL_STATE,
  action: AnyAction
): LogsState => {

  if (receiveLogsData.match(action)) {
    return getPagedEntities(state, action.data);
  }

  return state;
};
