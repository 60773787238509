import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestAddTrip,
  ReceiveAddTrip,
  ReceiveTripById,
  RequestTripById,
  RequestTrips,
  ReceiveTrips,
  ToggleTripSuccessMsgVisibility,
  RequestLockTrip,
  ReceiveLockTrips,
  RequestUnlockTrips,
  ReceiveUnlockTrips,
  RequestSetReadyToExport,
  ReceiveSetReadyToExport,
  RequestSetReadyToExportByMonth,
  ReceiveSetReadyToExportByMonth,
  RequestUpdateTrip,
  ReceiveUpdateTrip,
  RequestUpdateTrips,
  RequestDeleteTrip,
  ReceiveDeleteTrip,
  RequestTrackOfTrip,
  ReceiveTrackOfTrip,
  RequestMileageOfTrip,
  ReceiveMileageOfTrip,
  ShowTrack,
  HighlightTrack,
  RequestSplitTrip,
  ReceiveSplitTrip,
  RequestMergeTrips,
  ReceiveMergeTrips,
  RequestMergeTripsPreview,
  ReceiveMergeTripsPreview,
  SelectTrip,
  SelectAllTrips,
  DeselectAllTrips,
  LoadTrip,
} from "../types/actions/actionTrip.types";

import {
  TripGetRequest,
  TripAddRequest,
  TripUpdateRequest,
  TripLockRequest,
  TripExportRequest,
  TripExportByMonthRequest,
  TripsUpdate,
  TripTrackRequest,
  TripShowTrack,
  TripHighlightedTrack,
  TripSplitRequest,
} from "../types/trip/tripRequests.types";
import {
  MileageInformation,
  TripResponse,
  TripResponseInfo,
  TripResponseWithMoreInfo,
} from "../types/trip/tripResponse.types";

export enum TRIP_ACTION_TYPES {
  REQUEST_TRIPS = "REQUEST_TRIPS",
  RECEIVE_TRIPS = "RECEIVE_TRIPS",

  REQUEST_TRIP_BY_ID = "REQUEST_TRIP_BY_ID",
  RECEIVE_TRIP_BY_ID = "RECEIVE_TRIP_BY_ID",

  REQUEST_ADD_TRIP = "REQUEST_ADD_TRIP",
  RECEIVE_ADD_TRIP = "RECEIVE_ADD_TRIP",
  TOGGLE_TRIP_SUCCESS_MESSAGE_VISIBILITY = "TOGGLE_TRIP_SUCCESS_MESSAGE_VISIBILITY",

  REQUEST_LOCK_TRIPS = "REQUEST_LOCK_TRIPS",
  RECEIVE_LOCK_TRIPS = "RECEIVE_LOCK_TRIPS",

  REQUEST_UNLOCK_TRIPS = "REQUEST_UNLOCK_TRIPS",
  RECEIVE_UNLOCK_TRIPS = "RECEIVE_UNLOCK_TRIPS",

  REQUEST_SET_READY_TO_EXPORT = "REQUEST_SET_READY_TO_EXPORT",
  RECEIVE_SET_READY_TO_EXPORT = "RECEIVE_SET_READY_TO_EXPORT",

  REQUEST_SET_READY_TO_EXPORT_BY_MONTH = "REQUEST_SET_READY_TO_EXPORT_BY_MONTH",
  RECEIVE_SET_READY_TO_EXPORT_BY_MONTH = "RECEIVE_SET_READY_TO_EXPORT_BY_MONTH",

  REQUEST_UPDATE_TRIP = "REQUEST_UPDATE_TRIP",
  RECEIVE_UPDATE_TRIP = "RECEIVE_UPDATE_TRIP",

  REQUEST_UPDATE_TRIPS = "REQUEST_UPDATE_TRIPS",
  RECEIVE_UPDATE_TRIPS = "RECEIVE_UPDATE_TRIPS",

  REQUEST_DELETE_TRIP = "REQUEST_DELETE_TRIP",
  RECEIVE_DELETE_TRIP = "RECEIVE_DELETE_TRIP",

  REQUEST_TRACK_OF_TRIP = "REQUEST_TRACK_OF_TRIP",
  RECEIVE_TRACK_OF_TRIP = "RECEIVE_TRACK_OF_TRIP",

  REQUEST_MILEAGE_OF_TRIP = "REQUEST_MILEAGE_OF_TRIP",
  RECEIVE_MILEAGE_OF_TRIP = "RECEIVE_MILEAGE_OF_TRIP",

  SHOW_TRACK = "SHOW_TRACK",
  HIGHLIGHT_TRACK = "HIGHLIGHT_TRACK",

  REQUEST_SPLIT_TRIP = "REQUEST_SPLIT_TRIP",
  RECEIVE_SPLIT_TRIP = "RECEIVE_SPLIT_TRIP",

  REQUEST_MERGE_TRIPS = "REQUEST_MERGE_TRIPS",
  RECEIVE_MERGE_TRIPS = "RECEIVE_MERGE_TRIPS",

  REQUEST_MERGE_TRIPS_PREVIEW = "REQUEST_MERGE_TRIPS_PREVIEW",
  RECEIVE_MERGE_TRIPS_PREVIEW = "RECEIVE_MERGE_TRIPS_PREVIEW",

  SELECT_TRIP = "SELECT_TRIP",
  SELECT_ALL_TRIPS = "SELECT_ALL_TRIPS",
  DESELECT_ALL_TRIPS = "DESELECT_ALL_TRIPS",
  LOAD_TRIP = "LOAD_TRIP",
}

export const requestTrips = withMatcher(
  (data: TripGetRequest): RequestTrips => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_TRIPS, data);
  }
);

export const receiveTrips = withMatcher((data: TripResponse): ReceiveTrips => {
  return createAction(TRIP_ACTION_TYPES.RECEIVE_TRIPS, data);
});

export const requestTripById = withMatcher((data: string): RequestTripById => {
  return createAction(TRIP_ACTION_TYPES.REQUEST_TRIP_BY_ID, data);
});
export const receiveTripById = withMatcher(
  (data: TripResponseInfo): ReceiveTripById => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_TRIP_BY_ID, data);
  }
);

export const requestAddTrip = withMatcher(
  (data: TripAddRequest): RequestAddTrip => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_ADD_TRIP, data);
  }
);
export const receiveAddTrip = withMatcher(
  (data: TripResponseInfo): ReceiveAddTrip => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_ADD_TRIP, data);
  }
);

export const toggleTripSuccessMsgVisibility = withMatcher(
  (isVisible: boolean): ToggleTripSuccessMsgVisibility => {
    return createAction(
      TRIP_ACTION_TYPES.TOGGLE_TRIP_SUCCESS_MESSAGE_VISIBILITY,
      isVisible
    );
  }
);

export const requestLockTrips = withMatcher(
  (data: TripLockRequest): RequestLockTrip => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_LOCK_TRIPS, data);
  }
);
export const receiveLockTrips = withMatcher(
  (data: TripResponseInfo[]): ReceiveLockTrips => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_LOCK_TRIPS, data);
  }
);

export const requestUnlockTrips = withMatcher(
  (data: TripLockRequest): RequestUnlockTrips => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_UNLOCK_TRIPS, data);
  }
);
export const receiveUnlockTrips = withMatcher(
  (data: TripResponseInfo[]): ReceiveUnlockTrips => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_UNLOCK_TRIPS, data);
  }
);

export const requestSetReadyToExport = withMatcher(
  (data: TripExportRequest): RequestSetReadyToExport => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_SET_READY_TO_EXPORT, data);
  }
);
export const receiveSetReadyToExport = withMatcher(
  (data: TripResponseInfo[]): ReceiveSetReadyToExport => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_SET_READY_TO_EXPORT, data);
  }
);
export const requestSetReadyToExportByMonth = withMatcher(
  (data: TripExportByMonthRequest): RequestSetReadyToExportByMonth => {
    return createAction(
      TRIP_ACTION_TYPES.REQUEST_SET_READY_TO_EXPORT_BY_MONTH,
      data
    );
  }
);
export const receiveSetReadyToExportByMonth = withMatcher(
  (data: TripResponseInfo[]): ReceiveSetReadyToExportByMonth => {
    return createAction(
      TRIP_ACTION_TYPES.RECEIVE_SET_READY_TO_EXPORT_BY_MONTH,
      data
    );
  }
);

export const requestUpdateTrip = withMatcher(
  (data: TripUpdateRequest): RequestUpdateTrip => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_UPDATE_TRIP, data);
  }
);
export const receiveUpdateTrip = withMatcher(
  (data: TripResponseInfo): ReceiveUpdateTrip => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_UPDATE_TRIP, data);
  }
);

export const requestUpdateTrips = withMatcher(
  (
    updateData: TripsUpdate,
    getTripsData: TripGetRequest
  ): RequestUpdateTrips => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_UPDATE_TRIPS, {
      updateData,
      getTripsData,
    });
  }
);

export const receiveUpdateTrips = withMatcher((data: any) => {
  return createAction(TRIP_ACTION_TYPES.RECEIVE_UNLOCK_TRIPS, data);
})

// export const receiveUpdateTrips = (data) => ({
//   type: RECEIVE_UPDATE_TRIPS,
//   data,
// });

export const requestDeleteTrip = withMatcher(
  (data: string): RequestDeleteTrip => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_DELETE_TRIP, data);
  }
);
export const receiveDeleteTrip = withMatcher(
  (data: TripResponseInfo): ReceiveDeleteTrip => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_DELETE_TRIP, data);
  }
);

export const requestTrackOfTrip = withMatcher(
  (data: TripTrackRequest): RequestTrackOfTrip => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_TRACK_OF_TRIP, data);
  }
);
export const receiveTrackOfTrip = withMatcher(
  (data: TripResponseWithMoreInfo): ReceiveTrackOfTrip => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_TRACK_OF_TRIP, data);
  }
);

export const requestMileageOfTrip = withMatcher(
  (data: string): RequestMileageOfTrip => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_MILEAGE_OF_TRIP, data);
  }
);

export const receiveMileageOfTrip = withMatcher(
  (data: MileageInformation): ReceiveMileageOfTrip => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_MILEAGE_OF_TRIP, data);
  }
);

export const showTrack = withMatcher((data: TripShowTrack): ShowTrack => {
  return createAction(TRIP_ACTION_TYPES.SHOW_TRACK, data);
});

export const highlightTrack = withMatcher(
  (data: TripHighlightedTrack): HighlightTrack => {
    return createAction(TRIP_ACTION_TYPES.HIGHLIGHT_TRACK, data);
  }
);

export const requestSplitTrip = withMatcher(
  (splitData: TripSplitRequest, filter: TripGetRequest): RequestSplitTrip => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_SPLIT_TRIP, {
      splitData,
      filter,
    });
  }
);
export const receiveSplitTrip = withMatcher(
  (data: TripResponse): ReceiveSplitTrip => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_SPLIT_TRIP, data);
  }
);

export const requestMergeTrips = withMatcher(
  (tripIds: string[] , filter: TripGetRequest): RequestMergeTrips => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_MERGE_TRIPS, {
      tripIds,
      filter,
    });
  }
);
export const receiveMergeTrips = withMatcher(
  (data: TripResponse): ReceiveMergeTrips => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_MERGE_TRIPS, data);
  }
);

export const requestMergeTripsPreview = withMatcher(
  (data: string[]): RequestMergeTripsPreview => {
    return createAction(TRIP_ACTION_TYPES.REQUEST_MERGE_TRIPS_PREVIEW, data);
  }
);
export const receiveMergeTripsPreview = withMatcher(
  (data: TripResponseWithMoreInfo): ReceiveMergeTripsPreview => {
    return createAction(TRIP_ACTION_TYPES.RECEIVE_MERGE_TRIPS_PREVIEW, data);
  }
);

export const selectTrip = withMatcher((data: TripResponseInfo): SelectTrip => {
  return createAction(TRIP_ACTION_TYPES.SELECT_TRIP, data);
});

export const selectAllTrips = withMatcher((): SelectAllTrips => {
  return createAction(TRIP_ACTION_TYPES.SELECT_ALL_TRIPS);
});

export const deselectAllTrips = withMatcher((): DeselectAllTrips => {
  return createAction(TRIP_ACTION_TYPES.DESELECT_ALL_TRIPS);
});

export const loadTrip = withMatcher((): LoadTrip => {
  return createAction(TRIP_ACTION_TYPES.LOAD_TRIP);
});
