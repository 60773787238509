import { getTranslation } from "../../translations/translations";
import TableTypes from "../../models/TableTypes";

import GenericTable from "../common/table/GenericTable";
import { PAGE_NAME } from "../common/container/Page";
import { StatuseResponse } from "../../types/qualityCharging/qualityCharginResponses.types";

type StatusTableProps = {
  statuses: StatuseResponse[],
}

const StatusTable: React.FC<StatusTableProps> = ({ statuses }) => {

  const columnInformations = [
    {
      title: getTranslation("qualityCharging.tableItems.statuses.currentState"),
      key: "currentState",
    },
    {
      title: getTranslation("qualityCharging.tableItems.statuses.oldState"),
      key: "oldState",
    },
    {
      title: getTranslation("qualityCharging.tableItems.statuses.timestamp"),
      key: "timestamp",
      type: TableTypes.dateTime
    },
    {
      title: getTranslation("qualityCharging.tableItems.statuses.evseId"),
      key: "evseId",
    },
  ];

  return (
    <GenericTable
      data={statuses}
      pageName={PAGE_NAME.qualityCharging}
      columnInformations={columnInformations} 
    />
  );
};

export default StatusTable;
