import React, {useState} from "react";
import PropTypes from "prop-types";
import EditModal from "../../common/modals/CustomModal";
import OrderBySelect from "../../common/selects/specificSelects/OrderBySelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const TripSettingModal = props => {
    const [settings, setSettings] = useState(props.settings);

    const {onClose, onSave} = props;

    const generalDialogContent = (
        <div>
            <FormControlLabel
                control={<Switch onChange={e => setSettings({...settings, showArchived: e.target.checked})}
                                 checked={settings.showArchived}
                                 color="primary"/>}
                label="Archivierte Fahrten anzeigen?"
            />
            <OrderBySelect onChange={e => setSettings({...settings, orderBy: e})} value={settings.orderBy}/>
        </div>
    );
    const contents = [generalDialogContent];
    return <EditModal action="confirm"
                      onSave={() => onSave(settings)}
                      onClose={onClose}
                      title="Einstellungen"
                      contents={contents}/>;
};

TripSettingModal.defaultProps = {
    settings: {},
};

// TripSettingModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     onSave: PropTypes.func.isRequired,
//     settings: PropTypes.object,
// };

export default TripSettingModal;
