import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import { OrderRequest } from "../types/order/orderRequest.types";

import { OrderResponse } from "../types/order/orderResponse.types";

import { RequestOrder, ReceiveOrder } from "../types/actions/actionOrder.types";

export enum ORDER_ACTION_TYPES {
  REQUEST_ORDER = "REQUEST_ORDER",
  RECEIVE_ORDER = "RECEIVE_ORDER",
}

export const requestOrder = withMatcher((data: OrderRequest): RequestOrder => {
  return createAction(ORDER_ACTION_TYPES.REQUEST_ORDER, data);
});

export const receiveOrder = withMatcher((data: OrderResponse): ReceiveOrder => {
  return createAction(ORDER_ACTION_TYPES.RECEIVE_ORDER, data);
});
