import { takeLatest, put, call } from "typed-redux-saga/macro";

import { handleError } from "../../managers/sagaManager";

import { getLogsDataAsync } from "../api";

import { LOGS_ACTION_TYPES, receiveLogsData } from "../../actions/faultyTrips/actionLogs";

import { RequestLogsData } from "../../types/actions/faultyTrips/actionLogs.types";

function* getLogsData(action: RequestLogsData) {
  try {
    const response = yield* call(getLogsDataAsync, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveLogsData(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}


export default function* sagaLogsData() {
  yield* takeLatest(
    LOGS_ACTION_TYPES.REQUEST_LOGS_DATA,
    getLogsData
  );
}
