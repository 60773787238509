import { takeLatest, put, call } from "typed-redux-saga/macro";

import {
  receiveLocationDetails,
  receiveGeoCode,
  receiveReverseGeoCode,
  receiveAutoCompleteAddress,
} from "../actions/actionGeoLocation";

import {
  RequestGeoCode,
  RequestReverseGeoCode,
  RequestAutocompleteAddress,
  RequestLocationDetails,
} from "../types/actions/actionGeoLocation.types";

import { GEOCODE_ACTION_TYPES } from "../actions/actionGeoLocation";

import { getServices } from "./api";
import {
  getReverseGeoCode,
  getGeoCode,
  getAutocomplete,
  getLocationDetails,
} from "./geoLocationAPI";

import { handleGeoLocationError } from "../managers/sagaManager";

function* getGeoCodeData(action: RequestGeoCode) {
  try {
    const serviceResponse = yield* call(getServices);
    const response = yield* call(getGeoCode, serviceResponse.data, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveGeoCode(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleGeoLocationError());
  }
}

function* getReverseGeoCodeData(action: RequestReverseGeoCode) {
  try {
    const serviceResponse = yield* call(getServices);
    const response = yield* call(
      getReverseGeoCode,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveReverseGeoCode(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleGeoLocationError());
  }
}

function* getAddressAutocompleteData(action: RequestAutocompleteAddress) {
  try {
    const serviceResponse = yield* call(getServices);
    const response = yield* call(
      getAutocomplete,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAutoCompleteAddress(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleGeoLocationError());
  }
}

function* getLocationDetailsData(action: RequestLocationDetails) {
  try {
    const serviceResponse = yield* call(getServices);
    const response = yield* call(
      getLocationDetails,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveLocationDetails(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleGeoLocationError());
  }
}

export default function* sagaGeoLocation() {
  yield* takeLatest(GEOCODE_ACTION_TYPES.REQUEST_GEOCODE, getGeoCodeData);
  yield* takeLatest(
    GEOCODE_ACTION_TYPES.REQUEST_REVERSE_GEOCODE,
    getReverseGeoCodeData
  );
  yield* takeLatest(
    GEOCODE_ACTION_TYPES.REQUEST_AUTOCOMPLETE_ADDRESS,
    getAddressAutocompleteData
  );
  yield* takeLatest(
    GEOCODE_ACTION_TYPES.REQUEST_LOCATION_DETAILS,
    getLocationDetailsData
  );
}
