import React from "react";
import {connect} from "react-redux";
import GenericTable from "../../common/table/GenericTable";
import TableTypes from "../../../models/TableTypes";

const RawDataEventsTable = ({events}) => {
    const columnInformations = [
        {title: "DeviceTime", key: "deviceTime", type: TableTypes.dateTime},
        {title: "DeviceType", key: "deviceType"},
        {title: "EventType", key: "eventType"},
        {title: "InfoText", key: "infoText"},
        {title: "Timestamp", key: "timestamp", type: TableTypes.dateTime},
    ];

    return <GenericTable data={events} columnInformations={columnInformations}/>;
};

function mapStateToProp(state) {
    return {
        events: state.rawdata.events.entities,
    };
}

export default connect(mapStateToProp)(RawDataEventsTable);
