import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import FontAwesomeIcon from "../views/FontAwesomeIcon";
import Tooltip from "@material-ui/core/Tooltip";
import {Typography} from "@material-ui/core";

const styles = theme => ({
    buttonIcon: {
        fontSize: "17",
    },
    text: {
        marginLeft: "5px",
    },
});

const FilterButton = ({classes, onClick}) => {
    return (
        <Button size="small" variant="outlined" onClick={onClick} className={classes.buttonIcon}>
            <FontAwesomeIcon fontSize={15}>far fa-filter</FontAwesomeIcon>
            <div className={classes.text}>
                Filter
            </div> 
        </Button>
    );
};

// FilterButton.propTypes = {
//     onClick: PropTypes.func.isRequired,
// };

export default withStyles(styles)(FilterButton);
