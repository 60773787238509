import { AnyAction } from "redux";

import { getPagedEntities, addEntities } from "../managers/reducerManager";

import { HistoryResponseInfo } from "../types/history/historyResponse.types";

import { receiveHistoryById, clearHistory } from "../actions/actionHistories";
import {
  receiveLogout,
  receiveUnAuthoriseUser,
} from "../actions/actionAuthentication";

export type HistoryState = {
  readonly current: HistoryResponseInfo;
  readonly entities: HistoryResponseInfo[];
};

const HISTORY_INITIAL_STATE: HistoryState = {
  current: {} as HistoryResponseInfo,
  entities: [],
};

export const historyDetail = (
  state = HISTORY_INITIAL_STATE,
  action: AnyAction
) => {
  if (receiveHistoryById.match(action)) {
    return state.entities.length == 0
      ? getPagedEntities(state, action.data)
      : addEntities(state, action.data.entities);
  }

  if (
    clearHistory.match(action) ||
    receiveLogout.match(action) ||
    receiveUnAuthoriseUser.match(action)
  ) {
    return HISTORY_INITIAL_STATE;
  }

  return state;
};
