import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { isViewer } from "../../../managers/authManager";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TableContextMenu = ({
  anchorEl,
  onOpen,
  onRename,
  onMove,
  onRemove,
  onClose,
  disableOpen,
}) => {
  const me = useSelector((state) => state.users.me);
  const viewer = isViewer(me);
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {onOpen && !disableOpen && (
        <MenuItem onClick={onOpen}>
          {t("group.contextmenu.open")}
        </MenuItem>
      )}

      {!viewer && (
        <MenuItem
          onClick={() => {
            onClose();
            onRename();
          }}
        >
          {t("group.contextmenu.rename")}
        </MenuItem>
      )}
      {!viewer && (
        <MenuItem
          onClick={() => {
            onClose();
            onMove();
          }}
        >
          {t("group.contextmenu.move")}
        </MenuItem>
      )}

      {!viewer && (
        <MenuItem onClick={onRemove}>
          {t("group.contextmenu.remove")}
        </MenuItem>
      )}
    </Menu>
  );
};

export default TableContextMenu;
