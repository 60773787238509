import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {fromToByPeriod} from "../../../../managers/timeManager";
import {periods} from "../../../../models/Period";
import {addDays, subDays} from "date-fns";
import {useHotkeys} from "react-hotkeys-hook";
import CustomDatePicker from "./CustomDatePicker";


const DayPicker = ({ value, minDate, onDateChange, disableFuture = true, style, variant}) => {

    const minDateValue = (typeof minDate !== "undefined") ? minDate : new Date(1990, 0, 1);

    useHotkeys("ctrl+alt+left", () => {
        handleChange(subDays(value, 1));
    }, [value]);
    useHotkeys("ctrl+alt+right", () => {
        handleChange(addDays(value, 1));
    }, [value]);


    function handleChange(day) {
        const dayRange = fromToByPeriod(periods.day.id, day);
        onDateChange(dayRange.from, dayRange.to);
    }

    return (
        <CustomDatePicker value={value}
                          style={style}
                          onChange={handleChange}
                          minDate={minDateValue}
                          disableFuture={disableFuture}
                          variant={variant}
                          format="dd MMMM yyyy"/>
    );
};

DayPicker.defaultProps = {
    disableFuture: true,
}

DayPicker.propTypes = {
    minDate: PropTypes.object,
    disableFuture: PropTypes.bool,
    value: PropTypes.object,
    disableFuture: PropTypes.bool,
    onDateChange: PropTypes.func,
    style: PropTypes.object,
    variant: PropTypes.string
};

export default DayPicker;
