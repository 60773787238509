import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import { HistoryGetRequest } from "../types/history/historyRequest.types";
import { HistoryResponse } from "../types/history/historyResponse.types";

import {
  RequestHistoryById,
  ReceiveHistoryById,
  ClearHistory,
} from "../types/actions/actionHistory.types";

export enum HISTORIES_ACTION_TYPES {
  REQUEST_HISTORY_BY_ID = "REQUEST_HISTORY_BY_ID",
  RECEIVE_HISTORY_BY_ID = "RECEIVE_HISTORY_BY_ID",
  CLEAR_HISTORY = "CLEAR_HISTORY",
}

export const requestHistoryById = withMatcher(
  (data: HistoryGetRequest): RequestHistoryById => {
    return createAction(HISTORIES_ACTION_TYPES.REQUEST_HISTORY_BY_ID, data);
  }
);

export const receiveHistoryById = withMatcher(
  (data: HistoryResponse): ReceiveHistoryById => {
    return createAction(HISTORIES_ACTION_TYPES.RECEIVE_HISTORY_BY_ID, data);
  }
);

export const clearHistory = withMatcher((): ClearHistory => {
  return createAction(HISTORIES_ACTION_TYPES.CLEAR_HISTORY);
});
