import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import LoadingIndicator from "../common/LoadingIndicator";
import {Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";

import {Doughnut} from "react-chartjs-2";
import EmptyResult from "../common/views/EmptyResult";
import {customTranslation, getTranslation} from "../../translations/customTranslation";
import { useTranslation } from "react-i18next";

const styles = theme => ({
    card: {
        minWidth: 275,
        margin: "3px 5px",
        height: "400px",
    },
    title: {
        fontSize: 20,
    },
    chartContainer: {
        width: "100%",
        height: "100%",
    },
});

const DashboardTripDistribution = ({classes, title, trip, loading, me}) => {
    const {privateMileageInMeters, businessMileageInMeters, driveToWorkMileageInMeters} = trip;
    const {privateTripCount, businessTripCount, driveToWorkTripCount} = trip;

    const noDataAvailable = privateTripCount === 0 && businessTripCount === 0 && driveToWorkTripCount === 0;
    const { t } = useTranslation();
    const data = {
        labels: [
            getTranslation(customTranslation.business, me),
            getTranslation(customTranslation.private),
            getTranslation(customTranslation.driveToWork, me)
        ],
        datasets: [
            {
                data: [businessTripCount, privateTripCount, driveToWorkTripCount],
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
        ],
    };

    return (
        <Card className={classes.card}>
            {!loading && !noDataAvailable && (
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {title}
                    </Typography>

                    <div className="chartContainer">
                        <Doughnut data={data} options={{maintainAspectRatio: false}}/>
                    </div>

                    <div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("dashboard.charts.tripDistribution.type")}</TableCell>
                                    <TableCell>{t("dashboard.charts.tripDistribution.count")}</TableCell>
                                    <TableCell>{t("dashboard.charts.tripDistribution.mileage")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{getTranslation(customTranslation.business, me)}</TableCell>
                                    <TableCell>{businessTripCount}</TableCell>
                                    <TableCell>{Math.round(businessMileageInMeters / 1000)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("dashboard.charts.tripDistribution.private")}</TableCell>
                                    <TableCell>{privateTripCount}</TableCell>
                                    <TableCell>{Math.round(privateMileageInMeters / 1000)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{getTranslation(customTranslation.driveToWork, me)}</TableCell>
                                    <TableCell>{driveToWorkTripCount}</TableCell>
                                    <TableCell>{Math.round(driveToWorkMileageInMeters / 1000)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            )}
            {!loading && noDataAvailable && <EmptyResult/>}
            {loading && <LoadingIndicator/>}
        </Card>
    );
};

function mapStateToProp(state) {
    return {
        me: state.users.me,
        trip: state.reports.trip
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(DashboardTripDistribution));
