import { Polygon } from 'react-leaflet';
import React from 'react';

function MyPolygon(props) {
    let { polygon, idGeoFence, pathOptions, hidden } = props;
    return !hidden ? <Polygon
        positions={polygon.getLatLngs()}
        pathOptions={pathOptions}
        key={'poly_' + idGeoFence}
        idGeoFence={idGeoFence}
    ></Polygon> : <></>
}

export default MyPolygon;
