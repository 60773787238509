import React from "react";

const FilterIcon = ({ size, color }) => {
  return (
<svg height={size} viewBox="0 0 1792 1792" width={size} xmlns="http://www.w3.org/2000/svg">
  <path d="M1595 295q17 41-14 70l-493 493v742q0 42-39 59-13 5-25 5-27 0-45-19l-256-256q-19-19-19-45v-486l-493-493q-31-29-14-70 17-39 59-39h1280q42 0 59 39z" fill={color} stroke="rgba(0, 0, 0, 0.54)" strokeWidth="100" />
</svg>
  );
};

export default FilterIcon;