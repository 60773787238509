import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    splitText: {
        marginTop: "30px",
    },
    leftColumn: {
        boxShadow: "28px 0 20px -22px #0606068a",
        zIndex: 1000,
        paddingRight: "20px",
    },
    innerContainer: {
        height: "100%",
    },
    bottomContainer: {
        marginTop: "auto",
    },
    modal: {
        "& .MuiDialogContent-root": {
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
        },
    },
    bottomNav: {
        "& > .MuiListItem-root": {
            paddingLeft: 0,
        },
        "& .MuiListItemIcon-root": {
            minWidth: "1.8rem",
        },
    },
}));

export default useStyles;