import React from "react";
import PropTypes from "prop-types";
 import {Select} from "antd";
import CustomSelect from "../Select";
 import {countryArray} from "../../../../models/Countries";
 
const {Option} = Select;

const CountrySelect = props => {
    const {onChange, value, disabled} = props;

    return (
        <CustomSelect value={value}
                      placeholder="Herstellland"
                      handleChange={onChange}
                      allowClear={false}
                      disabled={disabled}>
            {countryArray.map(tt => (
                <Option key={tt.id} value={tt.id}>
                    {tt.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

CountrySelect.defaultProps = {
    disabled: false,
 };

// CountrySelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     disabled: PropTypes.bool,
//  };

export default CountrySelect;
