import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { format } from "date-fns";
//Common
import EditModal from "../../common/modals/CustomModal";
//Actions
import {
  requestMergeTrips,
  requestMergeTripsPreview,
} from "../../../actions/actionTrips";
import { setError } from "../../../actions/actionCommon";
import { useTranslation } from "react-i18next";

const TripMergeModal = (props) => {
  const { onClose, trip, trips, error, filter, orderAsc, rowsPerPage, page } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const { setError, requestMergeTripsPreview } = props;
    const selectedTripsIds = trips.filter((t) => t.isSelected).map((t) => t.id);
    if (selectedTripsIds.length >= 2)
      requestMergeTripsPreview(selectedTripsIds);
    else setError({ message: "You must select 2 trips to merge" });
  }, []);

  function handleMergeTrips() {
    const selectedTripsIds = trips.filter((t) => t.isSelected).map((t) => t.id);
    props.requestMergeTrips(selectedTripsIds, {
      ...filter.api,
      orderAsc,
      page: page + 1,
      perPage: rowsPerPage,
    });
    onClose();
  }

  const selectedTripsIds = trips.filter((t) => t.isSelected).map((t) => t.id);
  const mergeContent = trip && trip.startAddress && !error.title && (
    <div>
      {t("trip.mergeModal.modalMessage", {trips: selectedTripsIds.length})}
      <br /> <br />
      <h3>{t("trip.mergeModal.new")}</h3>
      {trip.startedAt && format(new Date(trip.startedAt), "dd.MM.yyyy - H:m")} -
      {trip.startAddress
        ? trip.startAddress.street +
          " " +
          trip.startAddress.postcode +
          " " +
          trip.startAddress.city
        : ""}
      <br />{" "}
      {trip.finishedAt && format(new Date(trip.finishedAt), "dd.MM.yyyy - H:m")}{" "}
      -
      {trip.destinationAddress
        ? trip.destinationAddress.street +
          " " +
          trip.destinationAddress.postcode +
          " " +
          trip.destinationAddress.city
        : ""}
    </div>
  );
  return (
    <EditModal
      action={error && error.updatedAt ? "cancel" : "confirm"}
      onSave={handleMergeTrips}
      onClose={onClose}
      title={t("trip.mergeModal.title")}
      contents={[mergeContent]}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setError, requestMergeTrips, requestMergeTripsPreview },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    trips: state.trips.entities,
    trip: state.trips.current,
    error: state.error,
    filter: state.filter,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(TripMergeModal);
