import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core";
import {
  AddressPrintLevel,
  addressToStr,
} from "../../managers/locationManager";
import {
  secondsToString,
  serverTimeToSeconds,
} from "../../managers/timeManager";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FlareIcon from "@material-ui/icons/Flare";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import { bindActionCreators } from "redux";
import { showTrack, highlightTrack } from "../../actions/actionTrips";

import { connect, useSelector } from "react-redux";
import { tripType } from "../../models/TripType";
import AverageIcon from "../../icons/AverageIcon";
import FuelIcon from "../../icons/FuelIcon";

const useStyles = makeStyles((theme) => ({
  hidden: {
    color: "#8080805e",
    "& .MuiSvgIcon-root": {
      color: "#8080805e",
    },
    "& b": {
      color: "#8080805e !important",
    },
  },
  container: {
    position: "relative",
    borderRadius: 5,
    paddingBottom: (props) => (props.isLast ? 0 : "5rem"),
    marginTop: "-1rem",
    display: "flex",
    width: "100%"
  },
  timesContainer: {
    paddingLeft: "3rem",
    paddingRight: "1.5rem",
    display: "flex",
    flexDirection: "column",
  },
  fuelMonitorContainer: {
    width: "50%"
  },
  namesContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "50%",
    fontSize: "1.2rem",
    "& b": {
      display: "block",
      fontSize: ".7rem",
      color: "#bbbbbb",
    },
  },
  time: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontSize: "1.2rem",
    "&:before": {
      content: "close-quote",
      display: "block",
      height: 10,
      width: 10,
      borderRadius: 20,
      backgroundColor: "#3f51b5",
      marginRight: "1rem",
      zIndex: 2,
    },
  },
  idleTime: {
    color: "black",
    display: "flex",
    alignItems: "center",
  },
  idleTimeText: {
    color: "#bbbbbb",
  },
  halfCircleIcon: {
    position: "absolute",
    top: "7px",
    left: "-1rem",
    height: "78%",
    width: "3rem",
  },
  hourglassIcon: {
    marginLeft: "-22px",
    background: "white",
    position: "relative",
    color: "#3f51b5",
  },
  timeWithIdleContainer: {
    position: "relative",
  },
  tail: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    marginTop: (props) => (props.hasBoth ? "4rem" : "0.5rem"),
    display: "flex",
    alignItems: "center",
  },
  eyePlaceHolder: {
    width: "45px",
  },
  eye: {
    padding: 0,
    marginRight: 5,
    "& svg": {
      fontSize: "1.6rem",
    },
  },
  tailLine: {
    height: "100%",
    width: 2,
    margin: "0 10px 0 1px",
    position: "relative",
    zIndex: 2,
    "& svg": {
      content: "close-quote",
      display: "block",
      position: "absolute",
      top: "33%",
      left: "-13px",
      fontSize: "2rem",
    },
  },
  tailLineColor: {
    backgroundColor: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  tailLineDisabledColor: {
    backgroundColor: "#8080805e",
    "& svg": {
      color: "#8080805e",
    },
  },
  tailText: {
    color: "#bbbbbb",
  },
  hovered: {
    backgroundColor: "#f6f6f6",
  },
  btnContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "3.7rem",
    bottom: 0,
    right: "1rem",
  },
  fuelLevelValue: {
    fontSize: 16,
  },
  fuelLevelSpaceHolder: {
    marginTop:22 ,
    marginBottom: 22
  },
  averageIconPosition: {
    position: "absolute",
    top: 0,
    bottom: 0,
    marginTop: (props) => (props.hasBoth ? "4rem" : "0.5rem"),
    display: "flex",
    alignItems: "center",
  },
  dottedLine: {
    position: "absolute",
    top: 31,
    left: 1,
    borderTop: '3px dotted #808080',
    width: '100%',
},
}));

function roundDate(date) {
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

const PrettyTripTableInformation = ({
  trip,
  previousTrip,
  visible,
  highlight,
  isFirst,
  isLast,
  showTrack,
  highlightTrack,
  isOverconsumtion,
  isUnderConsumption,
  averageFuelConsumptionSingleTrip,
  isTankProcessIdentified,
  userSelectedEngineType,
  isUserAuthorized,
  isVehicleDetailsFilled,
  isTankProcessDuringTripIdentified
}) => {

  const classes = useStyles({ hasBoth: !isFirst && !isLast, isLast: isLast });
  const me = useSelector((state) => state.users.me);
  const isFuelEngineType = userSelectedEngineType === "L";
  function percentageOfFuel(fuelInTank, tankSize){
    return (fuelInTank/tankSize) * 100 
  }

  function kWHOfBattery (tankSize, currentPercentageOfFuel) {
    return tankSize * (currentPercentageOfFuel / 100)
  }

  const canViewTrip =
    trip.type !== tripType.Private ||
    (trip.type === tripType.Private && trip.driver && me.id === trip.driver.id);

  let address = !canViewTrip ? (
    "Privat"
  ) : isLast ? (
    <>
      {addressToStr(trip.destinationAddress, AddressPrintLevel.street)}
      {<b>{addressToStr(trip.destinationAddress, AddressPrintLevel.city)}</b>}      
    </>
  ) : (
    <>
      {addressToStr(trip.startAddress, AddressPrintLevel.street)}
      <b>{addressToStr(trip.startAddress, AddressPrintLevel.city)}</b>
    </>
  );

  const formatTime = (time) => (
    <div className={classes.time}>
      {time && format(new Date(time), "HH:mm")}
    </div>
  );

  const timeWithIdle = isFirst ? (
    <>{formatTime(trip.startedAt)}</>
  ) : !isLast ? (
    <>
      {formatTime(previousTrip.finishedAt)}
      <div className={classes.idleTime}>
        <svg
          className={classes.halfCircleIcon}
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 100 100"
        >
          <g>
            <path
              d="M50,0 a1,1 0 0,0 2,100"
              fill="transparent"
              stroke="#3f51b5"
              strokeWidth={3}
            />
          </g>
        </svg>
        <HourglassEmptyIcon className={classes.hourglassIcon} />
        <div className={classes.idleTimeText}>
          {secondsToString(
            (roundDate(new Date(trip.startedAt)) -
              roundDate(new Date(previousTrip.finishedAt))) /
              1000
          )}
        </div>
      </div>
      {formatTime(trip.startedAt)}
    </>
  ) : (
    <div />
  );

  function showEngineType(isVehicleDetailsFilled, userSelectedEngineType, isTankProcessDuringTripIdentified){
    if(isTankProcessDuringTripIdentified){
      return null
    } else if (isVehicleDetailsFilled) {
      return userSelectedEngineType + "/100km"
    } else {
      return null
    }
  }

  const timeWithIdleTwo = isFirst ? (
  <p className={classes.fuelLevelValue}>
    {isFuelEngineType ? percentageOfFuel(trip.startingBatteryFuelLevelInLiter, trip.vehicle.tankSize).toFixed() : trip.startingBatteryChargeLevelInPercent}%
    ({isFuelEngineType ? trip.startingBatteryFuelLevelInLiter : kWHOfBattery(trip.vehicle.tankSize, trip.startingBatteryChargeLevelInPercent).toFixed()} {userSelectedEngineType})</p>)
  : !isLast ?
  (
    <>
      <p className={classes.fuelLevelValue}>
        {isFuelEngineType ? percentageOfFuel(previousTrip.finishingBatteryFuelLevelInLiter, trip.vehicle.tankSize).toFixed(): previousTrip.finishingBatteryChargeLevelInPercent}%
        ({isFuelEngineType ? previousTrip.finishingBatteryFuelLevelInLiter : kWHOfBattery(trip.vehicle.tankSize, previousTrip.finishingBatteryChargeLevelInPercent).toFixed()} {userSelectedEngineType})</p> 
      <div className={classes.fuelLevelSpaceHolder}>
      </div>
      <p className={classes.fuelLevelValue}>
        {isFuelEngineType ? percentageOfFuel(trip.startingBatteryFuelLevelInLiter, trip.vehicle.tankSize).toFixed() : trip.startingBatteryChargeLevelInPercent}%
        ({ isFuelEngineType ? trip.startingBatteryFuelLevelInLiter : kWHOfBattery(trip.vehicle.tankSize, trip.startingBatteryChargeLevelInPercent).toFixed()} {userSelectedEngineType})
        {isTankProcessIdentified &&<span style={{marginLeft: 5}} ><FuelIcon size="19" /></span> }</p> 
      <div className={classes.dottedLine}></div>
    </>
  )
  :
  (<div />);

  const timeAndEye = isLast ? (
    <>{formatTime(trip.finishedAt)}</>
  ) : (
    <>
      <div className={classes.tail}>
        <div className={classes.eyePlaceHolder} />
        <div
          className={classNames(
            classes.tailLine,
            {
              [classes.tailLineColor]: visible,
              [classes.tailLineDisabledColor]: !visible,
            },
            {
              [classes.hidden]: !visible,
            }
          )}
        >
          <KeyboardArrowDownIcon />
        </div>
        <div className={classes.tailText}>
          {secondsToString(serverTimeToSeconds(trip.duration))} <br />
          {Math.round(trip.mileageInMeters / 1000) + " km"}
        </div>
      </div>
      <div className={classes.timeWithIdleContainer}>{timeWithIdle}</div>
    </>
  );

  const fuelMonitorInformation = isLast ? (
    <p className={classes.fuelLevelValue}>
      {isFuelEngineType ? percentageOfFuel(trip.finishingBatteryFuelLevelInLiter, trip.vehicle.tankSize).toFixed() : trip.finishingBatteryChargeLevelInPercent}%
      ({isFuelEngineType ? trip.finishingBatteryFuelLevelInLiter : kWHOfBattery(trip.vehicle.tankSize, trip.finishingBatteryChargeLevelInPercent).toFixed()} {userSelectedEngineType})</p>
  ) : (
      <>
       <div className={classes.averageIconPosition}>
        <AverageIcon color={isOverconsumtion ? "red" : isUnderConsumption ? "green" : undefined}/>
        <p style={{marginTop:22, marginLeft: 10, fontSize: 16, color: isOverconsumtion ? "red" : isUnderConsumption ? "green" : undefined}}>
          {isTankProcessDuringTripIdentified ? 'Tank Process identified' : averageFuelConsumptionSingleTrip} {showEngineType(isVehicleDetailsFilled, userSelectedEngineType, isTankProcessDuringTripIdentified)}
        </p> 
      </div>
      <div className={classes.timeWithIdleContainer}>{timeWithIdleTwo}</div>
      </>
  );

  return (
    <div className={classes.container}>
      <div className={classes.timesContainer}>{timeAndEye}</div>
      <div className={classes.namesContainer}>{address}</div>
      {isUserAuthorized && <div className={classes.fuelMonitorContainer}>{fuelMonitorInformation}</div>} 
      {!isLast && (
        <div className={classes.btnContainer}>
          {visible && canViewTrip && (
            <IconButton
              className={classes.eye}
              onClick={() =>
                highlightTrack({
                  id: trip.id,
                  highlighted: !highlight,
                })
              }
            >
              {highlight ? <FlareIcon /> : <Brightness1OutlinedIcon />}
            </IconButton>
          )}
          {canViewTrip && (
            <IconButton
              className={classes.eye}
              onClick={() => {
                highlightTrack({ id: trip.id, highlighted: false });
                showTrack({ id: trip.id, visibility: !visible });
              }}
            >
              {!visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

PrettyTripTableInformation.defaultProps = {
  isFirst: false,
  isLast: false,
  secondToLast: false,
  previousTrip: {},
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ showTrack, highlightTrack }, dispatch);
}

function mapStateToProp(state) {
  return {};
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(PrettyTripTableInformation);
