import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { requestAddMandatorDriveboxes } from "../../../../actions/actionMandators";
import GenericCSVInsertModal from "../../../common/modals/GenericCSVInsertModal";

const MandatorDriveboxInsertModal = ({ response, onClose, requestAddMandatorDriveboxes }) => {

    const columnInformations = [
        { title: "IMEI", key: "imei" },
        { title: "Model", key: "model" },
        { title: "SimCard", key: "simCardNumber" },
        { title: "PhoneNumber", key: "phoneNumber" },
        { title: "Mandator", key: "mandatorName" },
    ];

    return <GenericCSVInsertModal
        identifier="imei"
        columnInformations={columnInformations}
        response={response}
        onAdd={requestAddMandatorDriveboxes}
        onClose={onClose}
    />;
};

// MandatorDriveboxInsertModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestAddMandatorDriveboxes }, dispatch);
}

function mapStateToProp(state) {
    return {
        response: state.driveboxes.current,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MandatorDriveboxInsertModal);
