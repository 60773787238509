import {useState} from "react";

export const useTransferList = () => {
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const not = (a, b) => {
        return a.filter(value => b.indexOf(value) === -1);
    };

    const intersection = (a, b) => {
        return a.filter(value => b.indexOf(value) !== -1);
    };

    const union = (a, b) => {
        return [...a, ...not(b, a)];
    };

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const onToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = items => intersection(checked, items).length;

    const onToggleAll = items => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const onCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const onCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    return {
        checked,
        left,
        setLeft,
        right,
        setRight, 
        leftChecked,
        rightChecked, 
        numberOfChecked,
        onCheckedRight,
        onCheckedLeft,
        onToggle,
        onToggleAll
    };
};
