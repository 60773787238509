import React, { useEffect, useState } from "react";
import { menu } from "../../../managers/pathManager";
import Page from '../../common/container/Page';
import { Grid, Card, CardActions, CardContent, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip, makeStyles, Snackbar, Paper, Tabs, Tab } from '@material-ui/core';
import ListItemMenu from '../sidebar/geofencelist/listitem/ListItemMenu';
import { useModalStatus, useSnackbar } from "../../../hooks/useModalStatus";
import ArchiveModal from "../../common/modals/ArchiveModal";
import GeofencesTripIntersectionModal from "./modals/GeofencesTripIntersectionModal";
import CarGeofencesModal from "./modals/CarGeofencesModal";
import GeofencesAnalysisModal from "./modals/GeofencesAnalysisModal";
import { ADD_TYPE, EDIT_TYPE }  from "./modals/GeofencesAnalysisModal";
import FilterToolbar from "../../common/toolbar/FilterToolbar";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { settingKeys, userKey, vehicleKey, geofenceAnalysisKey } from "../../../managers/localStorageManager";
import { Divider } from "rc-menu";
import { usePagination } from "../../../hooks/usePagination";
import AnalysisTable from "./AnalysisTable";
import { deselectAllTrips, loadTrip, requestLockTrips, requestTrips, selectAllTrips, selectTrip } from "../../../actions/actionTrips";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import { requestAllUsers } from "../../../actions/actionUser";
import { requestAllVehicles, requestOpenTrack, requestVehicleById } from "../../../actions/actionVehicle";
import { requestTripReport } from "../../../actions/actionReport";
import { roles } from "../../../models/Role";
import { allowedRoles, isAuthorized } from "../../../managers/authManager"; 
import { orderBy } from "../../../models/OrderBy";
import GeofenceAnalysisToolbar from "./toolbars/GeofenceAnalysisToolbar";
import { Link } from "react-router-dom";
import { packages } from "../../../models/Package";
import StreetviewIcon from '@material-ui/icons/Streetview';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import CircularProgress from "@material-ui/core/CircularProgress";
import GeofenceAnalysisHeader from "./header/GeofenceAnalysisHeader";
import GeofenceAnalysisHeaderCard from "./header/GeofenceAnalysisHeaderCard";
import GeofenceAnalysisVehiclesEnteringCard from "./header/GeofenceAnalysisVehiclesEnteringCard";
import GeofenceAnalysisUniqueVehiclesEnteredCard from "./header/GeofenceAnalysisUniqueVehiclesEnteredCard";
import GeofenceAnalysisTotalEntryEventsCard from "./header/GeofenceAnalysisTotalEntryEventsCard";
import GeofenceAnalysisTotalTimeInsideCard from "./header/GeofenceAnalysisTotalTimeInsideCard";
import GeofenceAnalysisVehiclesEnteringDurationCard from "./header/GeofenceAnalysisVehiclesEnteringDurationCard";
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";
import { fromToByPeriod } from "../../../managers/timeManager";
import { periods } from "../../../models/Period";
import { createGeofenceAnalysis, getGeofenceAnalyses } from "../../../saga/api";
import { getTranslation, getTranslationWithParam } from '../../../translations/translations.js';
import {
    requestGeofencesEventTrips,
    requestGeofencesNameId,
    requestGeofencesOfCurrentVehicle,
    requestGeofenceAnalyses,
    requestGeofenceAnalysisEvents,
    requestGeofenceAnalysisVehiclesEntering,
    requestGeofenceAnalysisUniqueVehiclesEntered,
    requestGeofenceAnalysisTotalEntryEvents,
    requestGeofenceAnalysisTotalTimeInside,
    requestGeofenceAnalysisVehiclesEnteringDuration,
    requestGeofenceAnalysisToggleArchive,
    requestEditGeofenceAnalysis
} from "../../../actions/actionGeofences";
import GeoFenceAnalysisTooltip from "../generic/GeofenceAnalysisTooltip.js"; 
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(theme => ({
    geofenceAnalysisCardContainer: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    geofenceAnalysisCard: {
        marginRight: "4px",

    },
    geofenceAnalysisList: {
        overflowY: "auto",
        maxHeight: "calc( " + window.innerHeight + "px - 270px)",
        height: "calc( " + window.innerHeight + "px - 270px)",
    },
    selectList: {
        borderTop: "1px solid #ccc",
    },
    contentContainer: {
        marginLeft: "1px"
    }
}));

const GeofencesAnalysis = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const filter = useSelector(state => state.filter);
    const users = useSelector(state => state.users.allEntities);
    const vehicles = useSelector(state => state.vehicles.allEntities);
    const geofences = useSelector(state => state.geofences.geofencesNameId);
    const geofenceAnalyses = useSelector(state => state.geofences.geofenceAnalyses);
    const geofenceAnalysisToggleArchive = useSelector(state => state.geofences.geofenceAnalysisToggleArchive);
    const geofenceAnalysisEvents = useSelector(state => state.geofences.geofenceAnalysisEvents.allEntities);
    const me = useSelector(state => state.users.me);

    const { api, updatedAt } = filter;

    const classes = useStyles();
    const [geofenceMode, setGeofenceMode] = useState(false);
    const [archive, setArchive] = useState(false);

    const callbackIsArchivedToggle = () => {
        setArchive(!archive);
        onclick(!archive);
    }

    const [trip, setTrip] = useState("");

    // Widget cards constants
    const geofenceAnalysisVehiclesEntering = useSelector(state => state.geofences.geofenceAnalysisVehiclesEntering);
    const geofenceAnalysisUniqueVehiclesEntered = useSelector(state => state.geofences.geofenceAnalysisUniqueVehiclesEntered);
    const geofenceAnalysisTotalEntryEvents = useSelector(state => state.geofences.geofenceAnalysisTotalEntryEvents);
    const geofenceAnalysisTotalTimeInside = useSelector(state => state.geofences.geofenceAnalysisTotalTimeInside);
    const geofenceAnalysisVehiclesEnteringDuration = useSelector(state => state.geofences.geofenceAnalysisVehiclesEnteringDuration);

    const { modal: createGeofenceAnalysisModal, open: openGeofenceAnalysisModal, close: closeGeofenceAnalysisModal } = useModalStatus();
    const { modal: editGeofenceAnalysisModal, open: openEditGeofenceAnalysisModal, close: closeEditGeofenceAnalysisModal } = useModalStatus();
    const { modal: geofenceTripsTrackModal, open: openGeofenceTripsTrackModal, close: closeGeofenceTripsTrackModal } = useModalStatus();
    const { modal: vehicleGeofencesModal, open: openVehicleGeofencesModal, close: closeVehicleGeofencesModal } = useModalStatus();
    const { modal: archiveModal, open: openArchiveModal, close: closeArchiveModal } = useModalStatus();
    const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
    const { open: exportSnackbar, show: showExportSnackbar, hide: hideExportSnackbar } = useSnackbar();
    // Defines the tab to be active/highlighted
    const [value, setValue] = React.useState(2);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { value: settings, set: setSettings } = useLocalStorage(settingKeys.tripPage, {
        orderBy: orderBy.desc,
        showArchived: false,
    });


    const { value: savedUser, set: setSavedUser } = useLocalStorage(userKey);
    const { value: savedGeofenceAnalysis, set: setSavedGeofenceAnalysis } = useLocalStorage(geofenceAnalysisKey);
    const { value: savedVehicle, set: setSavedVehicle } = useLocalStorage(vehicleKey);

    const [user, setUser] = useState(savedUser ? savedUser : "");
    const [geofenceAnalysis, setGeofenceAnalysis] = useState(
        (filter.api.geofencesAnalysis) ? filter.api.geofencesAnalysis : savedGeofenceAnalysis ? savedGeofenceAnalysis : "");
    const [selectedVehicles, setSelectedVehicles] = useState(
        (filter.api.selectedVehicles) ? filter.api.selectedVehicles : []);
    const [selectedGeofences, setSelectedGeofences] = useState(
        (filter.api.selectedGeofences) ? filter.api.selectedGeofences : []);
    const [vehicle, setVehicle] = useState(
        (filter.api.tripbookVehicle) ? filter.api.tripbookVehicle : savedVehicle ? savedVehicle : "");
    const [vehicleIdDriver, setVehicleIdDriver] = useState(
        (filter.api.tripbookVehicle) ? filter.api.tripbookVehicle : savedVehicle ? savedVehicle : "");

    const handleGeofenceMode = mode => {
        setGeofenceMode(mode);
    }

    const toggles = [
        {
            icon: <FontAwesomeIcon fontSize={19}
                color={archive ? "secondary" : undefined}>
                far fa-clipboard</FontAwesomeIcon>,
            translateKey: "geofenceAnalysis.page.unarchived",
            active: !archive,
            onClick: () => setArchive(false),
        },
        {
            icon: <FontAwesomeIcon fontSize={19}
                color={!archive ? "secondary" : undefined}>
                far fa-archive</FontAwesomeIcon>,
            translateKey: "geofenceAnalysis.page.archived",
            active: archive,
            onClick: () => setArchive(true),
        },
    ];
    const toolbar = <GeofenceAnalysisToolbar
        onCreateGeofenceAnalysis={openGeofenceAnalysisModal}
        vehicle={vehicle}
        setVehicle={setVehicle}
        settings={settings}
        setSettings={setSettings}
        toggles={toggles}
        geofenceMode={geofenceMode}
        setGeofenceMode={handleGeofenceMode}
        //controls={setControls} to add a toggle
    />;

    useEffect(() => {
        // reload all GeofenceAnalyses
        dispatch(requestGeofenceAnalyses(user));

    }, [geofenceAnalysisToggleArchive]);

    useEffect(() => {
        if (vehicle) setSavedVehicle(vehicle);
    }, [vehicle]);

    useEffect(() => {
        // Update the Widget Card
        renderGeofenceAnalysisVehiclesEnteringCard();
        
    }, [geofenceAnalysisVehiclesEntering]);

    useEffect(() => {
        // Update the Widget Card
        renderGeofenceAnalysisUniqueVehiclesEnteredCard();

    }, [geofenceAnalysisUniqueVehiclesEntered]);

    useEffect(() => {
        // Update the Widget Card
        renderGeofenceAnalysisTotalEntryEventsCard();

    }, [geofenceAnalysisTotalEntryEvents]);

    useEffect(() => {
        // Update the Widget Card
        renderGeofenceAnalysisTotalTimeInsideCard();

    }, [geofenceAnalysisTotalTimeInside]);

    useEffect(() => {
        // Update the Widget Card
        renderGeofenceAnalysisVehiclesEnteringDurationCard();

    }, [geofenceAnalysisVehiclesEnteringDuration]);

    useEffect(() => {
        if (geofenceAnalysis) {
            setSavedGeofenceAnalysis(geofenceAnalysis);
            var ga = getGeofenceAnalysisObject();
            if (ga) {
                //selectedGeofences = ga.geofenceAnalysisGeofences.Geofences;
                if (ga.geofenceAnalysisVehicles.length > 0) {
                    setVehicle(ga.geofenceAnalysisVehicles[0].vehicleId);

                    var vehicleIds = [];
                    for (var i = 0; i < ga.geofenceAnalysisVehicles.length; i++) {
                        vehicleIds.push('' + ga.geofenceAnalysisVehicles[i].vehicleId);
                    }
                    setSelectedVehicles(vehicleIds);

                    var geofenceIds = [];
                    for (var j = 0; j < ga.geofenceAnalysisGeofences.length; j++) {
                        geofenceIds.push('' + ga.geofenceAnalysisGeofences[j].geofenceId);
                    }
                    setSelectedGeofences(geofenceIds);
                }
            }
            dispatch(requestGeofenceAnalysisVehiclesEntering({ geofenceAnalysisId: [geofenceAnalysis] }));
            dispatch(requestGeofenceAnalysisUniqueVehiclesEntered({ geofenceAnalysisId: [geofenceAnalysis] }));
            dispatch(requestGeofenceAnalysisTotalEntryEvents({ geofenceAnalysisId: [geofenceAnalysis] }));
            dispatch(requestGeofenceAnalysisTotalTimeInside({ geofenceAnalysisId: [geofenceAnalysis] }));
            dispatch(requestGeofenceAnalysisVehiclesEnteringDuration({ geofenceAnalysisId: [geofenceAnalysis] }))
        }

    }, [geofenceAnalysis]);

    useEffect(() => {
        if (user) setSavedUser(user);
    }, [user]);

    //if current vehicle/user is not loaded, select first vehicle of list
    useEffect(() => {
        if (!vehicle && vehicles.length > 0) {
            setVehicle(vehicles[0].id);
        }
    }, [vehicles]);

    //if current analysis/user is not loaded, select fist geofence-analysis of list
    useEffect(() => {
        if (!geofenceAnalysis && geofenceAnalyses && geofenceAnalyses.length > 0) {
            setGeofenceAnalysis(geofenceAnalyses[0].id);
            resetSelectedGeofenceAnalysis();
        }
    }, [geofenceAnalyses]);

    useEffect(() => {
        if (!user && users.length > 0) {
            setUser(users[0].id);
        }
    }, [users]);

    useEffect(() => {
        requestTrip();
        dispatch(requestGeofenceAnalysisEvents({
            page: page + 1,
            perPage: rowsPerPage,
            geofenceAnalysisId: [geofenceAnalysis]
        }));
    }, [updatedAt, page, rowsPerPage, geofenceAnalysis]);


    useEffect(() => {
        return () => {
            dispatch(deselectAllTrips());
        };
    }, []);

    //TODELETE: preload vehicle or userlist
    //preload all geofenceanalyses
    useEffect(() => {
        dispatch(requestGeofenceAnalyses(user));
        dispatch(requestAllVehicles({ page: 1, perPage: 99999 }));
        dispatch(requestGeofencesNameId())
        if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
            dispatch(requestAllUsers({ page: 1, perPage: 99999 }));
        }
    }, []);

    useEffect(() => {
        if (!vehicle && (!api.vehicleIds || api.vehicleIds.length === 0) && vehicles && vehicles.length > 0) {
            setVehicle(vehicles[0].id);
        }
    }, [updatedAt]);

    useEffect(() => {
        if (!geofenceAnalysis && geofenceAnalyses && geofenceAnalyses.length > 0) {
            resetSelectedGeofenceAnalysis();
        }
    }, [updatedAt]);

    useEffect(() => {
        resetSelectedGeofenceAnalysis();

    }, [archive]);

    useEffect(() => {
        if (vehicle && !geofenceMode) dispatch(updateFilterAPICall({ vehicleIds: [vehicle], driverIds: [] }));
        if (vehicle && !geofenceMode) dispatch(requestVehicleById(vehicle));
        if (vehicle && !geofenceMode) dispatch(requestAllVehicles({
            page: 1,
            perPage: 99999
        }));
    }, [vehicle, geofenceMode]);

    useEffect(() => {
        // Update all analyses 
        //if (geofenceAnalysis && !geofenceMode) dispatch(requestGeofenceAnalyses(user));

    }, [geofenceAnalysis, geofenceMode]);

    useEffect(() => {
        if (user && geofenceMode) dispatch(updateFilterAPICall({ vehicleIds: [], driverIds: [user] }));
        if (user && geofenceMode) dispatch(requestGeofencesNameId());
    }, [user, geofenceMode]);

    function resetSelectedGeofenceAnalysis() {
        for (var i = 0; i < geofenceAnalyses.length; i++) {
            var gaDate = geofenceAnalyses[i].archivedAt;
            if (i === 40) {
                var test =0;
            }
            if ((gaDate !== null) === archive) {
                setGeofenceAnalysis(geofenceAnalyses[i].id);
                break;
            }
        }
    }

    function handleLockTrip(trip) {
        dispatch(requestLockTrips({ tripIds: [trip.id] }));
    }

    function requestTrip() {
        if (typeof geofenceAnalysis !== "undefined" && geofenceAnalysis !== "") {
            var ga = getGeofenceAnalysisObject();

            if (ga && ga.from && ga.to && ga.geofenceAnalysisVehicles && ga.geofenceAnalysisGeofences) {

                const requestParams = fromToByPeriod(periods.month.id, new Date());
                requestParams.from = new Date(ga.from);
                requestParams.to = new Date(ga.to);
                requestParams.from.setMinutes(requestParams.from.getMinutes() - requestParams.from.getTimezoneOffset());
                requestParams.to.setMinutes(requestParams.to.getMinutes() - requestParams.to.getTimezoneOffset());

                dispatch(requestOpenTrack(vehicle));
                api.from = requestParams.from.toISOString();
                api.to = requestParams.to.toISOString();
                api.vehicleIds = getAllVehicleIdsFromGeofenceAnalysis(ga);
                api.geofenceIds = getAllGeofenceIdsFromGeofenceAnalysis(ga);
                dispatch(requestGeofencesEventTrips({
                    ...api,
                    page: page + 1,
                    perPage: rowsPerPage,
                    orderAsc: settings.orderBy === orderBy.asc
                }));
                
            }
        }
    }

    function getAllGeofenceIdsFromGeofenceAnalysis(geofenceAnalysisObject) {
        var geofenceIds = [];
        if (geofenceAnalysisObject) {
            for (var i = 0; i < geofenceAnalysisObject.geofenceAnalysisGeofences.length; i++) {
                geofenceIds.push(geofenceAnalysisObject.geofenceAnalysisGeofences[i].geofenceId);
               
            }
        }
        return geofenceIds;
    }

    function getAllVehicleIdsFromGeofenceAnalysis(geofenceAnalysisObject) {
        var vehicleIds = [];
        if (geofenceAnalysisObject) {
            for (var i = 0; i < geofenceAnalysisObject.geofenceAnalysisVehicles.length; i++) {
                vehicleIds.push(geofenceAnalysisObject.geofenceAnalysisVehicles[i].vehicleId);

            }
        }
        return vehicleIds;
    }

    function getGeofenceAnalysisObject() {
        var ga = null;
        if (geofenceAnalyses && geofenceAnalysis) {
            for (var i = 0; i < geofenceAnalyses.length; i++) {
                if (geofenceAnalysis === geofenceAnalyses[i].id) {
                    ga = geofenceAnalyses[i];
                }
            }
        }
        return ga;
    }

    function renderGeofenceAnalysisHeaderCard() {
        var ga = getGeofenceAnalysisObject();

        if (ga) {
            // Gather all vehicle names
            var vehicleNames = [];
            var vehicleNamesStr = "";
            for (var j=0; j < ga.geofenceAnalysisVehicles.length; j++) {

                vehicleNamesStr += ga.geofenceAnalysisVehicles[j].vehicle.name;
                if ((ga.geofenceAnalysisVehicles.length - j) === 1) {
                    vehicleNamesStr += ';';
                } else {
                    vehicleNamesStr += ', ';
                }
            }
            vehicleNames.push(<div class="itemValue">{vehicleNamesStr}</div>);

            // Gather all geofence names
            var geofenceNames = [];
            var geofenceNamesStr = "";
            for (var k=0; k < ga.geofenceAnalysisGeofences.length; k++) {
                geofenceNamesStr += ga.geofenceAnalysisGeofences[k].geofence.title;
                // in case they are archived we add a label to the name
                geofenceNamesStr += (ga.geofenceAnalysisGeofences[k].geofence.archivedAt) !== null ? getTranslation("geofenceAnalysis.archivedSuffix") : "";
                
                if ((ga.geofenceAnalysisGeofences.length - k) === 1) {
                    geofenceNamesStr += ';';
                } else {
                    geofenceNamesStr += ', ';
                }
            }
            geofenceNames.push(<div class="itemValue">{geofenceNamesStr}</div>);

            var fromDate = getDateWithLocalTimezoneOffset(ga.from);
            var toDate = getDateWithLocalTimezoneOffset(ga.to);
            var dateOptions = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" };  

            return <GeofenceAnalysisHeaderCard
                title={ga.title}
                from={fromDate.toLocaleDateString(fromDate, dateOptions)}
                to={toDate.toLocaleDateString(toDate, dateOptions)} 
                vehicles={vehicleNames}
                geofences={geofenceNamesStr} />

        }
    }


    function renderGeofenceAnalysisTotalTimeInsideCard() {

        if (geofenceAnalysisTotalTimeInside) {

            return <GeofenceAnalysisTotalTimeInsideCard
                geofenceAnalysisTotalTimeInside={geofenceAnalysisTotalTimeInside} />

        }
    }

    function renderGeofenceAnalysisTotalEntryEventsCard() {

        if (geofenceAnalysisTotalEntryEvents) {

            return <GeofenceAnalysisTotalEntryEventsCard
                geofenceAnalysisTotalEntryEvents={geofenceAnalysisTotalEntryEvents} />

        }
    }

    function renderGeofenceAnalysisVehiclesEnteringCard() {
        
        if (geofenceAnalysisVehiclesEntering) {

            return <GeofenceAnalysisVehiclesEnteringCard
                geofenceAnalysisVehiclesEntering={geofenceAnalysisVehiclesEntering} />

        }
    }

    function renderGeofenceAnalysisUniqueVehiclesEnteredCard() {

        if (geofenceAnalysisUniqueVehiclesEntered) {

            return <GeofenceAnalysisUniqueVehiclesEnteredCard
                geofenceAnalysisUniqueVehiclesEntered={geofenceAnalysisUniqueVehiclesEntered} />

        }
    }
    function renderGeofenceAnalysisVehiclesEnteringDurationCard() {

        if (geofenceAnalysisVehiclesEnteringDuration && geofenceAnalysisVehiclesEnteringDuration.length > 0) {

            return <GeofenceAnalysisVehiclesEnteringDurationCard
                geofenceAnalysisVehiclesEnteringDuration={geofenceAnalysisVehiclesEnteringDuration} />

        }
    }  

    function getDateWithLocalTimezoneOffset(utcTime) {
        var localtime = new Date(utcTime);
        localtime.setMinutes(localtime.getMinutes() - localtime.getTimezoneOffset());

        return localtime;
    }

    function renderTablePage() {
        return <AnalysisTable
            analysisEvents={geofenceAnalysisEvents}
            archived={archive}
            isInGeofenceAnalysis
            onlyShowBusinessTrips={true}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={changePage}
            handleChangeRowsPerPage={changeRowPerPage}
            onSelect={selectTrip}
            onSelectAll={selectAllTrips}
            onLock={handleLockTrip}
            onTrack={trip => {
                setTrip(trip);
                openGeofenceTripsTrackModal();
            }}
            showCheckboxes={false}
        />
    }
    const geofencesLink = <Tab aria-label="foldertree" component={Link} to={"/geofences"} label="geofences" />;
    const geofencesReport = <Tab aria-label="foldertree" component={Link} to={"/geofences/report"} label="geofencesReport" />;

    return (
        <Page activePage={menu.geofences.key} >
           
            <Paper square style={{ marginTop: '50px' }}>
            {isAuthorized(me, allowedRoles(roles.Admin), packages.Geofences) ? 
                <Tabs
                    value={value}
                    onChange={handleChange}   
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon tabs example"
                >

                    {geofencesLink}
                    {geofencesReport}
                    <GeoFenceAnalysisTooltip />

                </Tabs>: null
            }
            </Paper>
            {toolbar}
            <FilterToolbar />
            <Grid container>
                <Grid item md={2} xs={12} className={classes.geofenceAnalysisCardContainer}>
                    <Card className={classes.geofenceAnalysisCard}>
                        <List dense className={classes.geofenceAnalysisList}>
                            {geofenceAnalyses && Array.isArray(geofenceAnalyses) && geofenceAnalyses.map((ga, i) => {
                                var archived = geofenceAnalyses.length > 0 && geofenceAnalyses[i] && geofenceAnalyses[i].archivedAt
                                    ? true
                                    : false;
                                if (archived === archive) {
                                    return (
                                        <ListItem
                                            className={classes.selectList}
                                            button
                                            key={"GeofenceAnalysis#li#" + i}
                                            selected={ga.id === geofenceAnalysis}
                                            onClick={() => {
                                                if (geofenceAnalysis !== ga.id) {
                                                    setGeofenceAnalysis(ga.id);
                                                }
                                            }}>

                                            <ListItemText primary={ga.title || ""} />

                                            {!ga.isCalculated &&
                                                <Tooltip title={t("status.loading") }>
                                                    <CircularProgress size="1rem" />
                                                </Tooltip>
                                            }
                                            {ga.archivedAt === null && 
                                                <ListItemMenu
                                                    isDisabled={false}
                                                    thisGeoFenceAnalysis={ga.id}
                                                    onEditTitle={openEditGeofenceAnalysisModal}
                                                    onArchive={openArchiveModal}
                                                    setDialog={archiveModal}
                                                ></ListItemMenu>
                                            }
                                        </ListItem>
                                    )
                                }
                            })}
                            {geofenceMode && geofences && geofences.map((v, i) =>
                                <ListItem
                                    className={classes.selectList}
                                    button
                                    key={"TripPage#li#" + i}
                                    selected={v.id === user}
                                    onClick={() => {
                                        if (user !== v.id) {
                                            dispatch(loadTrip());
                                            setUser(v.id)
                                        }
                                    }}>
                                    <ListItemText
                                        primary={v.name || ""}
                                        secondary={v.name || ""}
                                    />
                                    <Divider variant="inset" component="li" />
                                </ListItem>
                            )}
                        </List>
                    </Card>
                </Grid>

                <Grid item md={10} xs={12}>
                    <Grid container className={classes.geofenceAnalysisHeader} spacing={0}>
                        <Grid item className={classes.card}>
                                    {renderGeofenceAnalysisHeaderCard()}
                        </Grid>
                        <Grid item className={classes.card}>
                            {renderGeofenceAnalysisVehiclesEnteringDurationCard()}
                        </Grid>
                        <Grid item className={classes.card}>
                            {renderGeofenceAnalysisVehiclesEnteringCard()}
                        </Grid>
                        <Grid item className={classes.card}>
                            {renderGeofenceAnalysisUniqueVehiclesEnteredCard()}
                        </Grid>
                        <Grid item className={classes.card}>
                            {renderGeofenceAnalysisTotalEntryEventsCard()}
                        </Grid>
                        <Grid item className={classes.card}>
                            {renderGeofenceAnalysisTotalTimeInsideCard()}
                        </Grid>
                    </Grid>
                    <br />
                    <div className={classes.contentContainer}>
                        {renderTablePage()}
                    </div>
                </Grid>
            </Grid>
            
            {geofenceTripsTrackModal && <GeofencesTripIntersectionModal id={trip.tripId} vehicleId={trip.vehicleId} onClose={closeGeofenceTripsTrackModal} />}
            {vehicleGeofencesModal && <CarGeofencesModal  vehicleId={vehicleIdDriver} onClose={closeVehicleGeofencesModal} />}
            {createGeofenceAnalysisModal && (
                <GeofencesAnalysisModal
                    modalType={ADD_TYPE}
                    onClose={closeGeofenceAnalysisModal}
                    />
            )}
            {editGeofenceAnalysisModal && (
                <GeofencesAnalysisModal
                    geofenceAnalysis={getGeofenceAnalysisObject()}
                    modalType={EDIT_TYPE}
                    onClose={closeEditGeofenceAnalysisModal}
                />
            )}
            {archiveModal && (
                <ArchiveModal
                    title=""
                    noComment={true}
                    description={getTranslationWithParam('dialogs.confirmGeofenceAnalysisArchive',
                        { title: geofenceAnalyses.filter(ga => { return ga.id === geofenceAnalysis })[0].title }
                    )}
                    elementId={geofenceAnalysis}
                    onClose={closeArchiveModal}
                    onArchive={geofenceAnalysis => {
                        try {
                            dispatch(
                                requestGeofenceAnalysisToggleArchive({
                                    geofenceAnalysisId: geofenceAnalysis
                                })
                            )
                        } catch (e) {
                            let geoFenceAnalysisTitle = geofenceAnalyses.find(ga => ga.id === geofenceAnalysis).title;
                            alert(getTranslationWithParam('errors.couldNotToggleArchiveGeofenceAnalysis', { title: geoFenceAnalysisTitle }));
                            console.log(e);
                        };
                    }
                    }
                />
            )}
            <Snackbar translateKey="trip.page.snackbarExport"
                onClose={hideExportSnackbar}
                open={exportSnackbar} />

        </Page>
    )
}

export default GeofencesAnalysis;