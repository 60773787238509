import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {renderToStaticMarkup} from "react-dom/server";
import {divIcon} from "leaflet";
import {Marker, Polyline, Popup, Tooltip} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import FinishMarkerIcon from "../../../icons/finishMarker.svg";
import OriginIcon from "../../../icons/tripOrigin.svg";
import L from 'leaflet';
import ArrowDropRightIcon from "../../../icons/directionArrow.png";

import {withStyles} from "@material-ui/core/styles";
import {format} from "date-fns";

const styles = theme => ({});

function getArrowIcon(heading) {
    return divIcon({
        html: renderToStaticMarkup(
            <img style={{zIndex: "0", width: "25px", height: "25px", transform: "rotate(" + (heading - 90) + "deg)"}}
                 src={ArrowDropRightIcon}/>
        ),
    });
}

function getFinishIcon() {
    return divIcon({
        html: renderToStaticMarkup(<img style={{zIndex: "500", marginBottom: "30px", width: "30px", height: "30px"}}
                                        src={FinishMarkerIcon}/>),
    });
}

function getOriginIcon() {
    return divIcon({
        html: renderToStaticMarkup(
            <img style={{zIndex: "500", marginTop: "7px", marginLeft: "7px", width: "15px", height: "15px"}}
                 src={OriginIcon}/>
        ),
    });
}

const MapTrack = props => {
    const {showMarker, showEndPoint, showStartPoint, weight, color, positions, zoom, trip, highlighted, startAddress, destinationAddress} = props;
    const [computedPositions, setComputedPositions] = useState(
        [
            [trip.startLatitude, trip.startLongitude],
            [trip.destinationLatitude, trip.destinationLongitude]
        ]);
    const positionPopup = useRef(null);
    const hasComputedTrack = Object.entries(computedPositions).length !== 0;

    useEffect(() => {
        var icons = document.getElementsByClassName("leaflet-div-icon leaflet-marker-icon");
        if (icons.length > 0) {
            for (let i = 0; i < icons.length; i++) {
                icons[i].style.setProperty('margin-left', '-15px', 'important');
                icons[i].style.setProperty('margin-top', '-15px', 'important');
            }
        }
    }, []);


    useEffect(() => {
        if (positions && positions.length > 0) {
            setComputedPositions(positions.map(position => [position.latitude, position.longitude]));

            // optimize trips over 10 km
            if (trip.mileageInMeters > 10000)
                for (let i = 0; i < positions.length; i++) {
                    let currentPosition = positions[i];
                    if (currentPosition.distanceToPreviousInMeters == 0) {
                        currentPosition.distanceFromBeginning = virtualPosition(i);
                        if (isNaN(currentPosition.distanceFromBeginning))
                            currentPosition.distanceFromBeginning = 0;
                    }
                }
        }
    }, [positions]);

    useEffect(() => {
        // change the margins 
        var icons = document.getElementsByClassName("leaflet-div-icon leaflet-marker-icon" );
        if(icons.length > 0){
            for(let i=0;i<icons.length; i++){ 
                icons[i].style.cssText += ';margin-left: -15px !important;';
                icons[i].style.cssText += ';margin-top: -15px !important;'; 
            }
        }
    });

    useEffect(() => {
        props.closePopups();
    }, [zoom]);

    if (!(trip.startLatitude || trip.startLongitude || trip.destinationLatitude || trip.destinationLongitude)) {
        return <div/>;
    }

    function getGapInM() {
        const startGap = 50;
        const startZoom = 18;

        return startGap * Math.pow(2, startZoom - zoom);
    }

    function virtualPosition(startIndex) {
        let firstPosDistanceFromBeginning = 0;
        let lastPosDistanceFromBeginning = trip.mileageInMeters;

        let prevSteps = 0;
        let nextSteps = 0;

        for (let i = startIndex; i > 0; i--) {
            prevSteps++;
            if (positions[i].distanceToPreviousInMeters > 0) {
                firstPosDistanceFromBeginning = positions[i].distanceFromBeginning;
                break;
            }
        }

        for (let i = startIndex; i < positions.length; i++) {
            nextSteps++;
            if (positions[i].distanceToPreviousInMeters > 0) {
                lastPosDistanceFromBeginning = positions[i].distanceFromBeginning;
                break;
            }
        }

        let steps = prevSteps + nextSteps;
        return (firstPosDistanceFromBeginning * (steps - prevSteps) + lastPosDistanceFromBeginning * (steps - nextSteps)) / steps;
    }

    function createCustomClusterIcon(cluster) {
        const count = cluster.getChildCount();
        const className = count > 1 ? 'custom-cluster' : 'custom-single-marker';
        return L.divIcon({
            html: `<div style="${getClusterStyle(count)}" class="${className}">${count}</div>`,
            className: 'custom-cluster-icon',
            iconSize: L.point(40, 40),
        });
    }

    function getClusterStyle(count) {
        return `background-color: ${count > 1 ? '#4852B6' : '#4caf50'}; 
                color: #ffffff; 
                border-radius: 50%; 
                text-align: center;
                line-height: 40px;`;
    }

    function renderMarkerInTrack() {
        if (!positions || positions.length === 0) {
            return null;
        }
    
        const endPosition = positions[positions.length - 1];
        let gapInM = getGapInM();
    
        let lastDistanceFromBeginningMarker = 0;
        let basicClustering = [];
    
        positions.forEach(p => {
            if (
                p.distanceFromBeginning > gapInM &&
                p.distanceFromBeginning < endPosition.distanceFromBeginning - gapInM &&
                p.distanceFromBeginning > lastDistanceFromBeginningMarker + gapInM
            ) {
                lastDistanceFromBeginningMarker = p.distanceFromBeginning;
                basicClustering.push(p);
            }
        });
    
        //TODO translation
        const markers = basicClustering.map((position, i) => {
            const icon = getArrowIcon(position.directionInDegrees);
            return (
                <Marker icon={icon} key={i} position={[position.latitude, position.longitude]}>
                    <Popup ref={positionPopup}>
                        Datum: {format(new Date(position.createdAt), "dd.MM.yy H:mm")} <br/>
                        Dauer: {milisecondToString(position.timeFromBeginning)} <br/>
                        {position.distanceFromBeginning && "Entfernung: " + Math.round(position.distanceFromBeginning / 10) / 100 + "km"}
                        <br/>
                        Geschwindigkeit: {Math.round(position.speedInKmh * 100) / 100} km/h
                    </Popup>
                </Marker>
            );
        });
    
        return <MarkerClusterGroup showCoverageOnHover={false} iconCreateFunction={createCustomClusterIcon}>{markers}</MarkerClusterGroup>;
    }

    function milisecondToString(ms) {
        let seconds = Math.floor(ms / 1000);
        let hours = convertAndFormatTointeger((seconds / 3600));
        seconds = seconds % 3600;
        let minutes = convertAndFormatTointeger((seconds / 60));
        seconds = convertAndFormatTointeger(seconds % 60);
        return hours + ":" + minutes + ":" + seconds;
    }

    function convertAndFormatTointeger(number) {
        return formatTimeUnderTen(parseInt(number));
    }

    function formatTimeUnderTen(time) {
        return time < 10 ? "0" + time : `${time}`;
    }

    // TODO translate
    function renderOriginMarker() {
        const startPosition = computedPositions[0];
        return [
            <Marker icon={getOriginIcon()} position={startPosition} key="startMarker">
                <Tooltip>{trip.startAddress ? trip.startAddress.displayName : startAddress}</Tooltip>
            </Marker>,
        ];
    }

    // TODO translate
    function renderStopMarker() {
        const endPosition = computedPositions[computedPositions.length - 1];
        return (
            <Marker icon={getFinishIcon()} position={endPosition} key="destinationMarker">
                <Tooltip>{trip.destinationAddress ? trip.destinationAddress.displayName : destinationAddress}</Tooltip>
            </Marker>
        );
    }
   
    //Polyline has same PropTypes as path https://leafletjs.com/reference-1.7.1.html#polyline
    let polyColor = highlighted ? {color: "red", weight: weight * 1.5 } : {color: color, weight: weight};
    return (
        <div>
            {hasComputedTrack &&
            <Polyline positions={computedPositions} pathOptions={polyColor}
                      opacity={0.8}/>}
            {hasComputedTrack && showMarker && renderMarkerInTrack()}
            {hasComputedTrack && showStartPoint && renderOriginMarker()}
            {hasComputedTrack && showEndPoint && renderStopMarker()}
        </div>
    );
};

MapTrack.defaultProps = {
    color: "#3f51b5",
    showMarker: true,
    showDriverInformation: false,
    showStartPoint: true,
    showEndPoint: true,
    weight: 5,
    trip: {mileageInMeters: 0},
    highlighted: false,
    startAddress: "Start",
    destinationAddress: "Destination"
};

// MapTrack.propTypes = {
//     positions: PropTypes.array,
//     zoom: PropTypes.number,
//     weight: PropTypes.number,
//     color: PropTypes.string,
//     trip: PropTypes.object,
//     showMarker: PropTypes.bool,
//     showStartPoint: PropTypes.bool,
//     showEndPoint: PropTypes.bool,
//     showDriverInformation: PropTypes.bool,
//     marker: PropTypes.array,
//     closePopups: PropTypes.func.isRequired,
//     highlighted: PropTypes.bool,
//     startAddress: PropTypes.string,
//     destinationAddress: PropTypes.string
// };

export default withStyles(styles, {withTheme: true})(MapTrack);
