import { takeLatest, put, call } from "typed-redux-saga/macro";

import { handleError } from "../../managers/sagaManager";

import { getMisMatchTripsRequest } from "../api";

import { receiveMisMatchTrips, MISMATCH_TRIPS_ACTION_TYPES } from "../../actions/faultyTrips/actionMisMatchTrips";

import { RequestMisMatchTrips } from "../../types/actions/faultyTrips/actionMisMatchTrips.types";

function* getMisMatchTrips(action: RequestMisMatchTrips) {
  try {
    const response = yield* call(getMisMatchTripsRequest, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMisMatchTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}


export default function* sagaMisMatchTrips() {
  yield* takeLatest(
    MISMATCH_TRIPS_ACTION_TYPES.REQUEST_MISMATCH_TRIPS,
    getMisMatchTrips
  );
}
