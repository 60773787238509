import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {fade} from "@material-ui/core/styles/colorManipulator";
import { useTranslation } from "react-i18next";

const styles = theme => ({
    searchInputStyle: {
        position: "relative",
        borderRadius: 20,
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: 0,
        marginLeft: 0,
        display: "flex",
        width: "100%",
        border: '2px solid #e8e8e6',
        height: "35px",
        marginTop: 2
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        display: "flex",
        width: "100%"
    },
    searchIcon: {
        width: theme.spacing(4),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "rgba(0, 0, 0, 0.54)",
    },
    inputRoot: {
        color: "inherit",
        width: "100%",
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        transition: theme.transitions.create("width"),
        width: "100%",
    },
});

let timeout;
const ToolbarSearch = props => {
    const [searchTerm, setSearchTerm] = useState(undefined);
    const {classes, placeholder, onSearch, applyStyle} = props;
    const keyStrokeTimeout = 200;
    const { t } = useTranslation();

    useEffect(() => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (typeof searchTerm != "undefined") onSearch(searchTerm);
        }, keyStrokeTimeout);
    }, [searchTerm]);

    return (
        <div className={applyStyle ? classes.searchInputStyle : classes.search}>
        <div className={classes.searchIcon}>
            <SearchIcon/>
        </div>
        <InputBase
            value={searchTerm}
            type="search"
            placeholder={t("common.toolbar.search")}
            onChange={e => setSearchTerm(e.target.value)}
            autoComplete="new-password" //PREVENT autocomplete
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            fullWidth
        />
    </div>
    );
};


export default (withStyles(styles, {withTheme: true})(ToolbarSearch));
