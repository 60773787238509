import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles";
import LoadingIndicator from "../../../common/LoadingIndicator";
import { Card, CardActions, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { customTranslation, getTranslation } from "../../../../translations/customTranslation";
import { tripType } from "../../../../models/TripType";
import useResize from "../../../../hooks/useResize";
import { Doughnut } from "react-chartjs-2";
import FontAwesomeIcon from "../../../common/views/FontAwesomeIcon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer !important"
    },
    card: {
        minWidth: 275,
        margin: "0 2px",
        padding: "15px 15px 15px 15px",
        minHeight: "72px"
    },
    headerIcon: {
        margin: "15px 15px 15px 15px"
    },
    title: {
        fontSize: props => props.condensed ? 13 : 20,
    },
    itemTitle: {
        fontSize: props => props.condensed ? 11 : 16,
    },
    itemValue: {
        fontSize: props => props.condensed ? 10 : 14,
    },
    icon: {
        fontSize: 30,
        marginRight: 5
    },
    chartContainer: {
        width: "100%",
        height: "100%",
    },
    condensedCardContent: {
        padding: "8px 16px !important"
    },
    text: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }
}));

const GeofenceAnalysisVehiclesEnteringCard = ({ condensed, geofenceAnalysisVehiclesEnteringDuration }) => {
    const classes = useStyles({ condensed: condensed });
    const ref = useRef(null);
    const { width } = useResize(ref);
    const { t } = useTranslation();
    const gavedPrivate = geofenceAnalysisVehiclesEnteringDuration.filter((obj) => obj.tripType === tripType.Private);
    const gavedBusiness = geofenceAnalysisVehiclesEnteringDuration.filter((obj) => obj.tripType === tripType.Business);
    const gavedDriveToWork = geofenceAnalysisVehiclesEnteringDuration.filter((obj) => obj.tripType === tripType.DriveToWork);

    const privateTotalEnteredCount = gavedPrivate.reduce((total, entry) => total + entry.enteredCount, 0);
    const businessTotalEnteredCount = gavedBusiness.reduce((total, entry) => total + entry.enteredCount, 0);
    const driveToWorkTotalEnteredCount = gavedDriveToWork.reduce((total, entry) => total + entry.enteredCount, 0);

    const privateTotalTimeInside = gavedPrivate.length > 0 ? gavedPrivate[0].totalTimeInside : '-';
    const businessTotalTimeInside = gavedBusiness.length > 0 ? gavedBusiness[0].totalTimeInside : '-';
    const driveToWorkTotalTimeInside = gavedDriveToWork.length > 0 ? gavedDriveToWork[0].totalTimeInside : '-';

    const data = {
        labels: [
            getTranslation(customTranslation.business),
            getTranslation(customTranslation.private),
            getTranslation(customTranslation.driveToWork)
        ],
        datasets: [
            {
                data: [businessTotalEnteredCount, privateTotalEnteredCount, driveToWorkTotalEnteredCount],
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
        ],
    };


    //<Translate id="geofenceAnalysis.vehiclesEnteringDurationCard.noneEntering" />);

    const tripTypeFilter = (item, tripTypeToFilter) => {
        return item.tripType === tripTypeToFilter;
    };



    return <div className={classes.card}>
        <Card className={classes.card} sx={{ minWidth: 275 }}>
            <CardContent class={classes.title}>
                <FontAwesomeIcon fontSize={30}>far fa-file-chart-pie</FontAwesomeIcon>
                    {t("geofenceAnalysis.vehiclesEnteringDurationCard.title")}
            </CardContent>
            <CardContent class={classes.title}>

                <div className="chartContainer">
                    <Doughnut data={data} options={{ maintainAspectRatio: false }} />
                </div>

                <div>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("geofenceAnalysis.vehiclesEnteringDurationCard.vehicle")}</TableCell>
                                <TableCell>{t("geofenceAnalysis.vehiclesEnteringDurationCard.enteringEvents")}</TableCell>
                                <TableCell>{t("geofenceAnalysis.vehiclesEnteringDurationCard.duration")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t("geofenceAnalysis.vehiclesEnteringDurationCard.business")}</TableCell>
                                <TableCell>
                                    {businessTotalEnteredCount}
                                </TableCell>
                                <TableCell>
                                    {businessTotalTimeInside}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("geofenceAnalysis.vehiclesEnteringDurationCard.private")}</TableCell>
                                <TableCell>
                                    {privateTotalEnteredCount}
                                </TableCell>
                                <TableCell>
                                    {privateTotalTimeInside}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("geofenceAnalysis.vehiclesEnteringDurationCard.driveToWork")}</TableCell>
                                <TableCell>
                                    {driveToWorkTotalEnteredCount}

                                </TableCell>
                                <TableCell>
                                    {driveToWorkTotalTimeInside}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>


            </CardContent>
            <br/>
            <CardActions>
                <div class={classes.itemTitle}>
                    <div class={classes.itemValue}>
                        {//vehicleNames
                        }
                    </div>
                </div>
            </CardActions>

        </Card>
        </div>
};

GeofenceAnalysisVehiclesEnteringCard.defaultProps = {
    condensed: false
};

GeofenceAnalysisVehiclesEnteringCard.propTypes = {
    vehiclesEntering: PropTypes.any,
};

export default (GeofenceAnalysisVehiclesEnteringCard);
