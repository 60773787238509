import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

import Page from "../../common/container/Page";
import ConfirmArchiveModal from "../../common/modals/TextConfirmModal";
import Toolbar from "../../common/toolbar/Toolbar";

import {menu} from "../../../managers/pathManager";

import {useModalStatus} from "../../../hooks/useModalStatus";
import {usePagination} from "../../../hooks/usePagination";

import MandatorTable from "./MandatorTable";
import MandatorAddModal from "./MandatorAddModal";
import MandatorModal from "./MandatorModal";
//icons
import ArchiveIcon from "@material-ui/icons/Archive";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from "@material-ui/icons/GetApp";

import {
    requestDeleteMandator,
    requestMandators,
    requestMandatorSurrogate,
    requestMandatorToggleArchive
} from "../../../actions/actionMandators";

import {requestMandatorExportXlsx} from "../../../actions/actionExport";
import MandatorTesterSelect from "./MandatorTesterSelect";

const MandatorPage = props => {
    const [mandator, setMandator] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [archive, setArchive] = useState(false);
    const [surrogate, startSurrugate] = useState(false);
    const [tester, setTester] = useState(0);

    const {page, rowsPerPage, changePage, changeRowPerPage} = usePagination();
    const {modal: confirmArchiveModal, open: openConfirmArchiveModal, close: closeConfirmArchiveModal} = useModalStatus();
    const {modal: mandatorModal, open: openMandatorModal, close: closeMandatorModal} = useModalStatus();
    const {modal: addMandatorModal, open: openAddMandatorModal, close: closeAddMandatorModal} = useModalStatus();

    useEffect(() => {
        requestMandators();
    }, [page, rowsPerPage, archive, searchTerm, tester]);

    function requestMandators() {
        props.requestMandators({
            page: page + 1,
            perPage: rowsPerPage,
            searchTerm: searchTerm,
            isArchived: archive,
            isTestMandator: tester == 0 ? undefined : tester == 1 ? false : true
        });
    }

    function handleSearchChange(searchTerm) {
        setSearchTerm(searchTerm);
        changePage(null, 0);
    }

    function handleSurrogateMandator(mandator) {
        //window.location.reload(false);
        props.requestMandatorSurrogate(mandator.id);
        localStorage.clear();
        startSurrugate(true);
    }

    function handleToggleArchiveMandator(mandator) {
        setMandator(mandator);
        openConfirmArchiveModal();
    }

    function handleRequestMandatorToggleArchive() {
        props.requestMandatorToggleArchive(mandator.id);
        closeConfirmArchiveModal();
    }

    if (surrogate) {
        return <Redirect to={menu.dashboard.path}/>;
    }

    const toggles = [
        {
            key: "1",
            icon: <RssFeedIcon/>,
            translateKey: "mandator.page.mandator",
            active: !archive,
            onClick: () => setArchive(false),
        },
        {
            key: "2",
            icon: <ArchiveIcon/>,
            translateKey: "mandator.page.archive",
            active: archive,
            onClick: () => setArchive(true),
        },
    ];
    const actionButtons = [
        {
            key: "3",
            icon: <AddIcon/>,
            translateKey: "mandator.page.add",
            onClick: openAddMandatorModal
        },
        {
            key: "4",
            icon: <GetAppIcon/>,
            translateKey: "mandator.page.export",
            onClick: () =>
                props.requestMandatorExportXlsx({
                    searchTerm: searchTerm,
                    isArchived: archive,
                }),
        },
    ];

    const toolbar = <Toolbar toggles={toggles} actionButtons={actionButtons} onSearch={handleSearchChange}
                             controls={[<MandatorTesterSelect onChange={setTester} value={tester}/>]}/>;

    return (
        <Page activePage={menu.mandator.key} toolbar={toolbar}>
            <MandatorTable
                onInlineEdit={m => {
                    setMandator(m);
                    openMandatorModal();
                }}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={changePage}
                handleChangeRowsPerPage={changeRowPerPage}
                surrogateMandator={handleSurrogateMandator}
                onArchiveToggle={handleToggleArchiveMandator}
            />
            {mandatorModal && <MandatorModal activeMandator={mandator} onClose={closeMandatorModal}/>}
            {addMandatorModal && <MandatorAddModal onClose={closeAddMandatorModal}/>}
            {confirmArchiveModal && (
                <ConfirmArchiveModal
                    text={'Type the name of the mandator "' + mandator.name + '" to ' + (mandator.archivedAt ? "unarchive" : "archive") + " it"}
                    confirmControlText={mandator.name}
                    onClose={closeConfirmArchiveModal}
                    onConfirm={handleRequestMandatorToggleArchive}
                />
            )}
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestMandators,
            requestDeleteMandator,
            requestMandatorSurrogate,
            requestMandatorToggleArchive,
            requestMandatorExportXlsx,
        },
        dispatch
    );
}

function mapStateToProp(state) {
    return {
        error: state.mandators.error,
    };
}

// MandatorPage.propTypes = {};

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(MandatorPage);
