import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector, shallowEqual } from "react-redux";
import GenericTable from "../../common/table/GenericTable";
import TableTypes from "../../../models/TableTypes";
import Comparator from "../../../models/Comparator";
import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../../managers/authManager";
import { packages } from "../../../models/Package";
import { roles } from "../../../models/Role";
import {
  AddressPrintLevel,
  addressToStr,
} from "../../../managers/locationManager";
import {
  secondsToString,
  serverTimeToSeconds,
} from "../../../managers/timeManager";
import { tripType } from "../../../models/TripType";
import {
  customTranslation,
  getTranslation,
} from "../../../translations/customTranslation";
import { useTranslation } from "react-i18next";

const DashboardOpenTripsTable = (props) => {
  const {
    trips,
    total,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    me,
    loading,
  } = props;

  const viewer = isViewer(me);
  const { t } = useTranslation();

  const tripsWithMappedAddress = trips.map((trip) => {
    trip.mappedStartAddress = addressToStr(
      trip.startAddress,
      AddressPrintLevel.street
    );
    trip.mappedDestinationAddress = addressToStr(
      trip.destinationAddress,
      AddressPrintLevel.street
    );
    trip.mappedDescription = trip.description
      ? trip.description.length > 20
        ? trip.description.substring(0, 20) + "..."
        : trip.description
      : "";
    trip.mappedMileage = trip.mileageInMeters / 1000 + " km";
    if (trip.mileageInMeters / 1000 < 1) trip.mappedMileage = "< 1 km";
    trip.mappedTripType =
      trip.type == tripType.Business ? (
        getTranslation(customTranslation.business, me)
      ) : trip.type == tripType.Private ? (
        t("trip.table.private")
      ) : trip.type == tripType.DriveToWork ? (
        getTranslation(customTranslation.driveToWork, me)
      ) : (
        ""
      );
    trip.locked = Boolean(trip.lockedAt);
    trip.mappedDuration = secondsToString(serverTimeToSeconds(trip.duration));
    trip.mappedIdleTime = secondsToString(serverTimeToSeconds(trip.idleTime));
    trip.mappedDate = trip.startedAt;
    trip.hasDriver = Boolean(trip.driver);
    trip.mappedDriver = trip.driver ? (
      trip.driver.name
    ) : (
      t("trip.table.noDriver")
    );
    trip.mappedVehicle = trip.vehicle.name;
    return trip;
  });

  const columnInformations = [
    { title: t("trip.table.vehicle"), key: "mappedVehicle" },
    {
      title: t("trip.table.driver"),
      key: "mappedDriver",
      color: {
        value: "red",
        comparator: new Comparator(["hasDriver"], (hasDriver) => !hasDriver),
      },
    },
    {
      title: t("trip.table.date"),
      key: "mappedDate",
      type: TableTypes.date,
    },
    {
      title: t("trip.table.startAt"),
      key: "startedAt",
      type: TableTypes.time,
    },
    {
      title: t("trip.table.finishAt"),
      key: "finishedAt",
      type: TableTypes.time,
    },
    {
      title: t("trip.table.duration") ,
      key: "mappedDuration",
      noBr: true,
    },
    {
      title: t("trip.table.startAddress"),
      key: "mappedStartAddress",
    },
    {
      title: t("trip.table.destinationAddress"),
      key: "mappedDestinationAddress",
    },
    {
      title: t("trip.table.mileage") ,
      key: "mappedMileage",
      noBr: true,
    },
    { title: t("trip.table.tripType") , key: "mappedTripType" },
    {
      title: t("trip.table.description") ,
      key: "mappedDescription",
    },
  ];

  if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]))
    columnInformations.push({
      title: t("trip.table.costCenter") ,
      key: "costcenterKey",
      noBr: true,
    });

  return (
    <GenericTable
      data={tripsWithMappedAddress}
      columnInformations={columnInformations}
      rowCount={total}
      pageNavigation
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

DashboardOpenTripsTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onLock: PropTypes.func.isRequired,
  onTrack: PropTypes.func.isRequired,
  onHistory: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function mapStateToProp(state) {
  return {
    trips: state.trips.entities,
    costcenters: state.costcenters.entities,
    loading: state.trips.loading,
    total: state.trips.total,
    me: state.users.me,
  };
}

export default connect(mapStateToProp)(DashboardOpenTripsTable);
