import { call, put, takeLatest, takeEvery } from "typed-redux-saga/macro";
import {
  receiveGroups,
  receiveDeleteGroup,
  receiveUpdateGroup,
  receiveAddGroup,
  receiveGroupById,
  receiveTreesCurrentUser,
  receiveTree,
  GROUP_ACTION_TYPES,
} from "../actions/actionGroups";

import { handleError } from "../managers/sagaManager";
import {
  getGroups,
  getGroupById,
  addGroup,
  updateGroup,
  deleteGroup,
  getTree,
  getTreesCurrentUser,
  moveGroup,
} from "./api";

import {
  RequestGroups,
  RequestMoveGroup,
  RequestGroupById,
  RequestAddGroup,
  RequestUpdateGroup,
  RequestDeleteGroup,
} from "../types/actions/actionGroup.types";

function* getGroupsData(action: RequestGroups) {
  try {
    const response = yield* call(getGroups, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveGroups(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMoveGroupData(action: RequestMoveGroup) {
  try {
    const response = yield* call(moveGroup, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveGroups(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getGroupDataById(action: RequestGroupById) {
  try {
    const response = yield* call(getGroupById, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveGroupById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addGroupData(action: RequestAddGroup) {
  try {
    const response = yield* call(addGroup, action.data);
    const treeResponst = yield* call(getTree);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveTree(treeResponst.data));
      yield* put(receiveAddGroup(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateGroupData(action: RequestUpdateGroup) {
  try {
    const response = yield* call(updateGroup, action.data);
    const treeResponst = yield* call(getTree);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveTree(treeResponst.data));
      yield* put(receiveUpdateGroup(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteGroupData(action: RequestDeleteGroup) {
  try {
    const response = yield* call(deleteGroup, action.data);
    const treeResponst = yield* call(getTree);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveTree(treeResponst.data));
      yield* put(receiveDeleteGroup(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getTreeData() {
  try {
    const response = yield* call(getTree);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveTree(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getTreesForCurrentUserData() {
  try {
    const response = yield* call(getTreesCurrentUser);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveTreesCurrentUser(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaGroup() {
  yield* takeLatest(GROUP_ACTION_TYPES.REQUEST_GROUPS, getGroupsData);
  yield* takeLatest(GROUP_ACTION_TYPES.REQUEST_GROUP_BY_ID, getGroupDataById);
  yield* takeEvery(GROUP_ACTION_TYPES.REQUEST_ADD_GROUP, addGroupData);
  yield* takeEvery(GROUP_ACTION_TYPES.REQUEST_UPDATE_GROUP, updateGroupData);
  yield* takeEvery(GROUP_ACTION_TYPES.REQUEST_DELETE_GROUP, deleteGroupData);
  yield* takeEvery(GROUP_ACTION_TYPES.REQUEST_TREE, getTreeData);
  yield* takeEvery(
    GROUP_ACTION_TYPES.REQUEST_TREES_CURRENT_USER,
    getTreesForCurrentUserData
  );
  yield* takeEvery(GROUP_ACTION_TYPES.REQUEST_MOVE_GROUP, getMoveGroupData);
}
