import { call, put, takeLatest } from "typed-redux-saga/macro";

import { RequestHistoryById } from "../types/actions/actionHistory.types";

import {
  receiveHistoryById,
  HISTORIES_ACTION_TYPES,
} from "../actions/actionHistories";

import { getHistory } from "./api";
import { handleError } from "../managers/sagaManager";

function* getHistoryDataById(action: RequestHistoryById) {
  try {
    const response = yield* call(getHistory, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveHistoryById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaHistory() {
  yield* takeLatest(
    HISTORIES_ACTION_TYPES.REQUEST_HISTORY_BY_ID,
    getHistoryDataById
  );
}
