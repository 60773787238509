import React from "react";
import CustomSelect from "../common/selects/Select";
import {Select} from "antd";
import PropTypes from "prop-types";
import {topicArray} from "../../models/ContactPersonTopics";

const {Option} = Select;
const ContactPersonTopicSelect = props => {

    const {onChange, value} = props;

    return <CustomSelect value={value}
                         allowClear={false}
                         handleChange={onChange}
                         placeholder="Fachgebiet">
        {topicArray.map(p => {
            return <Option key={p.id} value={p.id}>
                {p.name}
            </Option>
        })}
    </CustomSelect>
};

// ContactPersonTopicSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string
// }

export default ContactPersonTopicSelect;