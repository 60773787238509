const packages = {
  Tracking: 1,
  Logbook: 2,
  TimeLog: 4,
  CostCenter: 8,
  Geofences: 16,
  Reservations: 32,
  FuelMonitor: 64,
  QualityCharging: 128,
  DriverIdentification: 256,
};

const packageArray = [
  { id: packages.Tracking, name: "Tracking" },
  { id: packages.Logbook, name: "Logbook" },
  { id: packages.TimeLog, name: "TimeLog" },
  { id: packages.CostCenter, name: "CostCenter" },
  { id: packages.Geofences, name: "Geofences" },
  { id: packages.Reservations, name: "Reservations" },
  { id: packages.FuelMonitor, name: "FuelMonitor" },
  {id: packages.QualityCharging, name: "QualityCharging"},
  {id: packages.DriverIdentification, name: "DriverIdentification"}
];

const findPackageByID = (id) => {
  return packageArray.find((p) => p.id == id);
};

export { packages, packageArray, findPackageByID };
