import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableTypes from "../../models/TableTypes";

import { usePagination } from "../../hooks/usePagination";

import { requestLogsData } from "../../actions/faultyTrips/actionLogs";

import GenericTable from "../common/table/GenericTable";
import { PAGE_NAME } from "../common/container/Page";
import { RootState } from "../../store";

const UnrealisticTripsTable = () => {
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const logsData = useSelector((state: RootState) => state.logs.entities);
  const total = useSelector((state: RootState) => state.logs.total);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestLogsData({ page: page + 1, perPage: rowsPerPage }));
  }, [page, rowsPerPage]);

  const columnInformations = [
    {
        title: "EntityName",
        key: "entityName",
      },
      {
        title: "Type",
        key: "type",
      },
      {
        title: "Information",
        key: "information",
        type: TableTypes.date,
      } 
  ];

  return (
    <GenericTable
      data={logsData}
      pageName={PAGE_NAME.faultyTrips}
      pageNavigation
      rowCount={total}
      page={page}
      rowsPerPage={rowsPerPage}
      columnInformations={columnInformations}
      handleChangePage={changePage}
      handleChangeRowsPerPage={changeRowPerPage}
    />
  );
};

export default UnrealisticTripsTable;
