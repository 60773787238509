import { Grid, makeStyles, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { exchangeLocallyGeofenceVehicles } from '../../../../../actions/actionGeofences';
import ArrowIcon from '@material-ui/icons/Forward';
import { geofenceVehicles } from '../../../../../models/GroupingGeofenceVehicles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    rotateLeft: {
        transform: "scaleX(-1)",
        fontSize: "50px"
    },
    fontSize: {
        fontSize: "50px"
    }
}));

const ExchangeButtons = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    return <Grid item>
        <Tooltip title={t("geofenceVehicles.assign")} placement="top">
            <IconButton
                variant="text"
                color="primary"
                onClick={() => dispatch(exchangeLocallyGeofenceVehicles({
                    fromModule: geofenceVehicles.unAssignedGeofenceVehicles,
                    toModule: geofenceVehicles.assignedGeofenceVehicles
                }))}>
                {/* t("geofenceVehicles.assign") */}
                <ArrowIcon className={classes.fontSize} />
            </IconButton >
        </Tooltip>
        <br />
        <br />
        <Tooltip title={t("geofenceVehicles.unAssign")} placement="top">
            <IconButton
                variant="text"
                color="secondary"
                onClick={() => dispatch(exchangeLocallyGeofenceVehicles({
                    fromModule: geofenceVehicles.assignedGeofenceVehicles,
                    toModule: geofenceVehicles.unAssignedGeofenceVehicles
                }))}>
                {/* t("geofenceVehicles.unAssign") */}
                <ArrowIcon className={classes.rotateLeft} />
            </IconButton >
        </Tooltip>
    </Grid>

}

export default ExchangeButtons;