import React from "react";
import {Select} from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import {vehicleProfileArray} from "../../../../models/VehicleProfile";
import { useTranslation } from "react-i18next";

const {Option} = Select;

const VehicleProfileSelect = props => {
    const {onChange, value, disabled, multiple, allowClear} = props;
    const { t } = useTranslation();

    if (multiple) return <MultiSelect placeholder={t("common.select.vehicleprofiles")}
                                      selectedArray={value}
                                      array={vehicleProfileArray}
                                      handleChange={onChange}/>;
    return (
        <CustomSelect value={value}
                      placeholder={t("common.select.vehicleprofile")}
                      handleChange={onChange}
                      disabled={disabled}
                      allowClear={allowClear}>
            {vehicleProfileArray.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

VehicleProfileSelect.defaultProps = {
    disabled: false,
    multiple: false,
    allowClear: true,
};

export default VehicleProfileSelect;
