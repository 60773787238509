import { takeLatest, put, call } from "typed-redux-saga/macro";

import { handleError } from "../../managers/sagaManager";

import { getEvoChargeStationsRequest, getEvoChargeStationsByIdRequest, startEvoConnectionRequest, stopEvoConnectionRequest } from "../api";

import { receiveChargeStations, receiveChargeStationById, CHARGE_STATION_ACTION_TYPES } from "../../actions/qualityCharging/actionChargeStations";

import { RequestChargeStationById, RequestChargeStations } from "../../types/actions/qualityCharging/actionChargeStations.types";

function* getEvoChargeStations(action: RequestChargeStations) {
  try {
    const response = yield* call(getEvoChargeStationsRequest, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveChargeStations(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getEvoChargeStationsById(action: RequestChargeStationById) {
  try {
    const response = yield* call(getEvoChargeStationsByIdRequest, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveChargeStationById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* startEvoConnection() {
  try {
    yield* call(startEvoConnectionRequest);
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* stopEvoConnection() {
  try {
    yield* call(stopEvoConnectionRequest);
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}


export default function* sagaChargeStations() {
  yield* takeLatest(
    CHARGE_STATION_ACTION_TYPES.REQUEST_CHARGE_STATIONS,
    getEvoChargeStations
  );

  yield* takeLatest(
    CHARGE_STATION_ACTION_TYPES.REQUEST_CHARGE_STATION_BY_ID,
    getEvoChargeStationsById
  );

  yield* takeLatest(
    CHARGE_STATION_ACTION_TYPES.REQUEST_START_CONNECTION_TO_EVO,
    startEvoConnection
  );

  yield* takeLatest(
    CHARGE_STATION_ACTION_TYPES.REQUEST_STOP_CONNECTION_TO_EVO,
    stopEvoConnection
  )
}
