import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import { LiveLocationRequest } from "../types/liveLocation/liveLocationRequest.types";
import { LiveLocationResponse } from "../types/liveLocation/liveLocationResponse.types";

import {
  RequestLiveLocation,
  ReceiveLiveLocation,
  LoadLiveLocation
} from "../types/actions/actionLiveLocation.types";

export enum LIVELOCATION_ACTION_TYPES {
  REQUEST_LIVELOCATION = "REQUEST_LIVELOCATION",
  RECEIVE_LIVELOCATION = "RECEIVE_LIVELOCATION",
  LOAD_LIVELOCATION = "LOAD_LIVELOCATION",
}

export const requestLiveLocation = withMatcher(
  (data: LiveLocationRequest): RequestLiveLocation => {
    return createAction(LIVELOCATION_ACTION_TYPES.REQUEST_LIVELOCATION, data);
  }
);

export const receiveLiveLocation = withMatcher(
  (data: LiveLocationResponse[]): ReceiveLiveLocation => {
    return createAction(LIVELOCATION_ACTION_TYPES.RECEIVE_LIVELOCATION, data);
  }
);

export const loadLiveLocation = withMatcher((): LoadLiveLocation => {
  return createAction(LIVELOCATION_ACTION_TYPES.LOAD_LIVELOCATION);
});
