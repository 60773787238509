import { TablesFormatedDateAndTime } from "../../../managers/timeManager";
import i18n from "../../../i18n";

export const flexContainer = (alignItems = 'start', flexDirection = 'column') => ({
  display: 'flex',
  flexDirection,
  alignItems,
});

const icons = {
  fontSize: "26px",
  position: "absolute",
  right: "-35px",
};

const stopoverIconStyle = (hoverColor) => ({
  color: "#203159",
  cursor: "pointer",
  transition: "all 0.1s ease",
  "&:hover": {
    fontSize: "27px",
    color: hoverColor,
  },
  ...icons,
  top: "10%",
});

export const reservationAddModalStyles = (theme) => ({
  container: {
    display: "flex",
  },
  defaultSpacing: {
    marginRight: 5,
    fontSize: 14,
    textAlign: "center",
  },
  datePickerIcon: { ...icons, top: "2px" },
  stopoverAddIcon: { ...stopoverIconStyle("#6ab7ff"), right: "-18%" },
  stopoverRemoveIcon: { ...stopoverIconStyle("#ff0000"), right: "-9.5%" },
  root: {
    minHeight: "600px",
    ...flexContainer("center"),
  },
  content: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "510px",
    },
  },
  stepOneContent: { margin: "25px 20px 35px 10px" },
  stepsContent: { margin: "5px 40px 10px 0" },
  form: {
    width: "100%",
    marginBottom: "15px",
  },
});

export const textFieldStyles = {
  marginBottom: "25px",
};

export const RESERVATION_ADD_MODAL_NUM_OF_PAGES = 4;

export const propertiesToCompare = [
  "stopovers",
  "pickupDate",
  "returnDate",
  "driverId",
  "comment",
  "pickupAddress",
  "returnAddress",
  "purpose",
  "costcenterId",
  "vehicleId",
];

export const RESERVATION_INPUT_TYPES = {
  stopover: "stopover",
  pickupDate: "pickupDate",
  returnDate: "returnDate",
  driverId: "driverId",
  comment: "comment",
  pickupAddress: "pickupAddress",
  returnAddress: "returnAddress",
  purpose: "purpose",
  costcenterId: "costcenterId",
  vehicleId: "vehicleId",
};

export const extractUserSelect = (inputs) => {
  let userSelect = {};
  inputs.forEach((input) => {
    if (input.props.name === RESERVATION_INPUT_TYPES.driverId) {
      userSelect = input;
    }

    return userSelect;
  });
};

export const createNewValuesObject = (values) => {
  const stopovers = [];
  Object.keys(values).forEach((key) => {
    if (key.includes(RESERVATION_INPUT_TYPES.stopover)) {
      values[key]?.locationId && stopovers.push(values[key]);
    } else if (
      key === RESERVATION_INPUT_TYPES.pickupDate ||
      key === RESERVATION_INPUT_TYPES.returnDate
    ) {
      values[key] = new Date(values[key]).toISOString();
    }
  });
  const { pickupDate, returnDate, pickupAddress, returnAddress } = values;
  return { pickupDate, returnDate, pickupAddress, returnAddress, stopovers };
};

export const checkAllTheProperties = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (
      (value1 || value2) &&
      typeof value1 === "object" &&
      typeof value2 === "object"
    ) {
      if (!checkAllTheProperties(value1, value2)) {
        return false;
      }
    } else if (
      (value1 || value2) &&
      (value1 !== "stopoverKey" || value2 !== "stopoverKey") &&
      value1 !== value2
    ) {
      return false;
    }
  }

  return true;
};

const areStopoversEqual = (stopovers1, stopovers2) => {
  if (stopovers1.length !== stopovers2.length) return false;

  for (let i = 0; i < stopovers1.length; i++) {
    if (stopovers1[i].locationId !== stopovers2[i].locationId) {
      return false;
    }
  }
  return true;
};

export const areObjectsEqual = (obj1, obj2, propertiesToCompare) => {
  for (const prop of propertiesToCompare) {
    const value1 = obj1[prop];
    const value2 = obj2[prop];

    if (prop === "stopovers" && (value1.length || value2.length)) {
      if (!areStopoversEqual(value1, value2)) {
        return false;
      }
    } else if (
      (value1 || value2) &&
      typeof value1 === "object" &&
      typeof value2 === "object"
    ) {
      if (!checkAllTheProperties(value1, value2)) {
        return false;
      }
    } else if (prop === "pickupDate" || prop === "returnDate") {
      if (
        TablesFormatedDateAndTime(value1) !== TablesFormatedDateAndTime(value2)
      ) {
        return false;
      }
    } else if ((value1 || value2) && value1 !== value2) {
      return false;
    }
  }
  return true;
};

export const getDynamicStepTitle = (step) => {
  switch (step) {
    case 0:
      return i18n.t("reservation.addReservationModal.pageOne.description");
    case 1:
      return i18n.t("reservation.addReservationModal.pageTwo.description");
    case 2:
      return i18n.t("reservation.addReservationModal.pageThree.description");
    case 3:
      return i18n.t("reservation.addReservationModal.pageFour.description");
    default:
      return "";
  }
};

export const getRequiredRule = (message) => ({ required: true, message });

export const validateMaxStopovers = (maxNumber) => {
  return {
    validateStatus: "warning",
    hasFeedback: true,
    help: i18n.t("reservation.addReservationModal.inputValidation.stopoversValidation", { number: maxNumber }),
  };
};

export const STEP_TWO_DATA = [
  {
    title: i18n.t("reservation.addReservationModal.pageThree.pickupDate"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.pickupDate"),
    value: 'pickupDate',
    style: { fontWeight: 'bold' },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.returnDate"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.returnDate"),
    value: 'returnDate',
    style: { fontWeight: 'bold' },
  },
];

export const STEP_THREE_DATA = [
  ...STEP_TWO_DATA,
  {
    title: i18n.t("reservation.addReservationModal.pageThree.vehicle"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.vehicle"),
    value: 'selectedVehicle',
    style: { fontWeight: 'bold' },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.pickupAddress"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.pickupAddress"),
    value: 'pickupAddress',
    style: { fontWeight: 'bold' },
  },
  {
    title: 'stopover',
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.returnAddress"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageThree.returnAddress"),
    value: 'returnAddress',
    style: { fontWeight: 'bold' },
  },
];

export const STEP_FOUR_DATA = [
  ...STEP_TWO_DATA,
  {
    title: i18n.t("reservation.addReservationModal.pageFour.vehicle"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageFour.vehicle"),
    value: 'selectedVehicle',
    style: { fontWeight: 'bold' },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageFour.driver"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageFour.driver"),
    value: 'driver',
    style: { fontWeight: 'bold' },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageFour.costCenter"),
    style: { textAlign: "end" },
  },
  {
    title: i18n.t("reservation.addReservationModal.pageFour.comment"),
    value: 'comment',
    style: { fontWeight: 'bold' },
  },
];

export const stepsContentValues = (value, reservationAddState, selectedVehicle, selectedDriver) => {
  const { pickupDate, returnDate, pickupAddress, returnAddress, purpose, comment } = reservationAddState;
  
  switch(value) {
    case 'pickupDate':
      return TablesFormatedDateAndTime(pickupDate);
    case 'returnDate':
      return TablesFormatedDateAndTime(returnDate);
    case 'selectedVehicle':
      return selectedVehicle?.name;
    case 'driver':
      return `${selectedDriver?.firstName} ${selectedDriver?.lastName}`;
    case 'pickupAddress':
      return pickupAddress.displayName;
    case 'returnAddress':
      return returnAddress.displayName;
    case 'costCenter': 
      return reservationAddState.costCenter?.name;
    case 'purposeOfUse':
      return purpose;
    case 'comment':
      return comment;
    default:
      console.error("The value doesn't exist!")
  }
}
