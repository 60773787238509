import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import StorageIcon from '@material-ui/icons/Storage';
import { customColors } from '../../../generic/StylingOptions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { ListItemIcon, Tooltip } from '@material-ui/core';
import axios from 'axios';
import { useTranslation, withTranslation } from 'react-i18next';
import i18n from '../../../../../i18n';

const baseUrl = window.location.origin + "/api";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const defaultCategories = [
    {
        id: '1',
        title: i18n.t("category.worker")
    },
    {
        id: '99',
        title: i18n.t("category.others")
    },
];

function MetadataDialog(props) {
  let { thisGeoFence, geoFenceMetadata, setGeoFenceMetadata, setDialog, currentUser } = props;

  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  function onDelete(elem) {
    setDialog({
      isOpen: true,
      type: 'confirm',
      danger: true,
      title: props.t('dialogs.metaRemove', { title: elem.dataValue }),
      btnConfirm: props.t('dialogs.buttons.delete'),
      confirm: () => {
        axios({
          method: 'delete',
          url: `${baseUrl}/GeoFenceMetadata/${elem.id}`,
          headers: {
              Authorization: currentUser
          }
        }).then(res => {
            let newGeoFenceMetadata = geoFenceMetadata;
            
            newGeoFenceMetadata = newGeoFenceMetadata.filter(obj => {
                return obj.id !== elem.id;
            });
    
            setGeoFenceMetadata([...newGeoFenceMetadata]);
        }).catch(err => {
            alert(props.t('errors.couldNotDeleteMetadata'));
        })
      },
      cancel: () => {

      }
    });
  }

  function onAdd() {
    setDialog({
      isOpen: true,
      type: 'prompt',
      title: props.t('dialogs.metaAdd'),
      btnConfirm: props.t('dialogs.buttons.add'),
      confirm: (newValue) => {
        let newObj = {
          idGeoFence: thisGeoFence.geoFence.ID,
          idCategory: +selectedCategory,
          dataValue: newValue,
        }
    
        let newStr = JSON.stringify(newObj);
    
        axios({
            method: 'post',
            url: `${baseUrl}/GeoFenceMetadata/`,
            data: newStr,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: currentUser
            }
        }).then(res => {
            newObj.id = res.data.id;
    
            let newGeoFenceMetadata = geoFenceMetadata;
            newGeoFenceMetadata.push(newObj);
            setGeoFenceMetadata([...newGeoFenceMetadata]);
        }).catch(err => {
            alert(props.t('errors.couldNotAddMetadata'));
        })
      },
      cancel: () => {

      }
    });
  }

  return (
    <div>
      <MenuItem disabled={thisGeoFence.geoFence.SystemGeoFence} style={{color: customColors.dark }} onClick={handleClickOpen}>
        <ListItemIcon>
            <StorageIcon  style={{color: customColors.dark }}/>
        </ListItemIcon>
        {t("fenceList.metadata")}
      </MenuItem>
      
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {thisGeoFence.geoFence.Title}
        </DialogTitle>
        <DialogContent dividers>
          <div className="metadataContent">
            <FormControl style={{width: '100%'}}>
                <InputLabel id="demo-simple-select-label">{t("metadata.category")}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategory}
                    onChange={handleChange}
                    style={{width: '100%'}}
                >
                    {defaultCategories.map(elem => {
                        return (
                            <MenuItem key={elem.id} value={elem.id}>{elem.title}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <div className="metadataHeader">
                <Tooltip enterDelay={500} title={t("metadata.add")}>
                    <IconButton
                        style={{ color: customColors.bright,
                                backgroundColor: customColors.ilogsBlueBright,
                                borderRadius: '5px',
                                width: '100%' }}
                        onClick={onAdd}
                    >
                        <AddIcon></AddIcon>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="metadataList">
                {geoFenceMetadata.filter(e => e.idCategory === +selectedCategory && e.idGeoFence === thisGeoFence.geoFence.ID).map((elem, idx) => {
                    return (
                        <div key={elem.idCategory + '_' + idx} className="metadataItem">
                            <div className="metadataValue">{elem.dataValue}</div>
                            <div className="metadataActions">
                                <Tooltip enterDelay={500} title={t("metadata.delete")}>
                                    <IconButton
                                        style={{color: customColors.danger, padding: '8px'}}
                                        onClick={() => onDelete(elem)}
                                    >
                                        <DeleteIcon style={{fontSize: 20}}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    );
                })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withTranslation()(MetadataDialog);
