import { takeLatest, put, call } from "typed-redux-saga/macro";

import { handleError } from "../../managers/sagaManager";

import { getUnrealisticTripsRequest } from "../api";

import { receiveUnrealisticTrips, UNREALISTIC_TRIPS_ACTION_TYPES } from "../../actions/faultyTrips/actionUnrealisticTrips";

import { RequestUnrealisticTrips } from "../../types/actions/faultyTrips/actionUnrealisticTrips.types";

function* getUnrealisticTrips(action: RequestUnrealisticTrips) {
  try {
    const response = yield* call(getUnrealisticTripsRequest, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUnrealisticTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}


export default function* sagaUnrealisticTrips() {
  yield* takeLatest(
    UNREALISTIC_TRIPS_ACTION_TYPES.REQUEST_UNREALISTIC_TRIPS,
    getUnrealisticTrips
  );
}
