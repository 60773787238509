import { PAGE_NAME } from "../components/common/container/Page";

const roles = {
    Admin: "Admin",
    AdminViewer: "Admin Viewer",
    GroupLeader: "Group Leader",
    GroupLeaderViewer: "Group Leader Viewer",
    Driver: "Driver",
    DriverViewer: "DriverViewer",
    DriverLeader: "DriverLeader",
    SuperAdmin: "Super Admin",
};

const getRoleId = (allRoles, roleName) => {
    if (allRoles && allRoles.length > 0 && roleName) {
        return allRoles.find(r => r.name === roleName);
    }
    return undefined;
};

export {roles, getRoleId};

export const isHigherThanDriverLeader = (user) => {
    return (user.role.name !== roles.Driver && user.role.name !== roles.DriverViewer && user.role.name !== roles.DriverLeader);
}

export const hasDriverLeaderPermission = (currentUser, pageName) => {
    return (currentUser.role.name === roles.DriverLeader) && (pageName && (pageName === PAGE_NAME.trip || pageName === PAGE_NAME.reservation)) || (currentUser.role.name !== roles.DriverLeader);
}
