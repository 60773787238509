import {
  receiveVehicleNameById,
  receiveDriverNameByPin,
  receiveDriversByName,
  getDriverNameByPinFailed,
  getDriversByNameFailed,
  getVehicleNameByIdFailed,
  driverIdentificationClearError,
  clearDriverName,
  assignVehicleToDriverByDriverIdFailed,
  assignVehicleToDriverByPinFailed,
  receiveAssignVehicleToDriverByDriverId,
  receiveAssignVehicleToDriverByPin,
  clearVehicleDriver, 
  setVehicleId,
  loadDriverPin
} from "../actions/actionDriverIdentification";

import { AnyAction } from "redux";
import { UserResponseInfo } from "../types/user/userResponse.types";

export type DriverIdentificationState = {
  vehicleName: string;
  driverName: string;
  drivers: UserResponseInfo[];
  vehicleDriver: any,
  loading: boolean;
  error: Error | null;
  vehicleId: string;
};

const DRIVER_IDENTIFICATION_INITIAL_STATE: DriverIdentificationState = {
  vehicleName: "",
  driverName: "",
  drivers: [],
  vehicleDriver: null,
  loading: false,
  error: null,
  vehicleId: "",
};

export const driverIdentificationReducer = (
  state = DRIVER_IDENTIFICATION_INITIAL_STATE,
  action: AnyAction
): DriverIdentificationState => {

  if(loadDriverPin.match(action)) {
    return {
      ...state,
      loading: true
    }
  }

  if (receiveVehicleNameById.match(action)) {
    return {
      ...state,
      vehicleName: action.data,
      loading: false,
    };
  }

  if (receiveDriverNameByPin.match(action)) {
    return {
      ...state,
      driverName: action.data,
      loading: false,
    };
  }

  if (receiveDriversByName.match(action)) {
    return {
      ...state,
      drivers: action.data,
      loading: false,
    };
  }

  if (
    assignVehicleToDriverByPinFailed.match(action) ||
    assignVehicleToDriverByDriverIdFailed.match(action) ||
    getDriverNameByPinFailed.match(action) ||
    getDriversByNameFailed.match(action) ||
    getVehicleNameByIdFailed.match(action)
  ) {
    return {
      ...state,
      loading: false,
      error: action.data,
    }
  }

  if(driverIdentificationClearError.match(action)) {
    return {
      ...state,
      error: null,
    }
  }

  if(clearDriverName.match(action)) {
    return {
      ...state,
      driverName: "",
    }
  }

  if(receiveAssignVehicleToDriverByDriverId.match(action) || receiveAssignVehicleToDriverByPin.match(action)) {
    return {
      ...state,
      vehicleDriver: action.data
    }
  }

  if(clearVehicleDriver.match(action)) {
    return {
      ...state,
      vehicleDriver: null,
    }
  }

  if(setVehicleId.match(action)) {
    return {
      ...state,
      vehicleId: action.data
    }
  }

  return state;
};
