import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import CustomSelect from "../../common/selects/Select";
 
const { Option } = Select;
const options = [
    { id: 0, name: "Alle Mandator" },
    { id: 1, name: "Keine Tester" },
    { id: 2, name: "Tester" },
];

const MandatorTesterSelect = props => {
    const { onChange, value } = props;
    return (
        <CustomSelect value={value} handleChange={onChange} allowClear={false}>
            {options.map(tt => (
                <Option key={tt.id} value={tt.id}>
                    {tt.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

// MandatorTesterSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.oneOfType([
//         PropTypes.string.isRequired,
//         PropTypes.number.isRequired,
//     ]) 
// };

export default MandatorTesterSelect;
