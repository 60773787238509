import React, {useState} from "react";
import CustomSelect from "../../common/selects/Select";
import {Select} from "antd";
import PropTypes from "prop-types";
import {hoursOfDayArray} from "../../../models/HoursOfDay";

const {Option} = Select;
const HourlySelect = props => {

    const {onChange, value} = props;

    return <CustomSelect value={value}
                         allowClear={false}
                         handleChange={onChange}
                         placeholder="Bitte Uhrzeit angeben">
        {hoursOfDayArray.map(p => {
            return <Option key={p.id} value={p.id}>
                {p.name}
            </Option>
        })}
    </CustomSelect>
};

// HourlySelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string
// }

export default HourlySelect;