import {mapQueryParams} from "../managers/apiManager";
const axios = require("axios");

function getInstance(response) {
    return axios.create({
        baseURL: response.geoLocationServiceUrl + "/api/",
        timeout: 10000,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: response.authorizationToken,
        },
    });
}

export const getReverseGeoCode = async (services, params) => {
    return getInstance(services).get("ReverseGeoCode" + mapQueryParams(params));
};

export const getGeoCode = async (services, params) => {
    return getInstance(services).get("GeoCode" + mapQueryParams(params));
};

export const getAutocomplete = async (services, params) => {
    return getInstance(services).get("Autocomplete" + mapQueryParams(params));
};

export const getLocationDetails = async (services, params) => {
    return getInstance(services).get("LocationDetails" + mapQueryParams(params));
};