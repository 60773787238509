import { withMatcher, createAction } from "../../utils/reducer/reducer.utils";

import { RequestLogsData, ReceiveLogsData } from "../../types/actions/faultyTrips/actionLogs.types";

export enum LOGS_ACTION_TYPES {
  REQUEST_LOGS_DATA = "REQUEST_LOGS_DATA",
  RECEIVE_LOGS_DATA = "RECEIVE_LOGS_DATA",
}
 
// Type "any" needs to change to a more specific type later 
export const requestLogsData = withMatcher(
  (data: any): RequestLogsData => {
    return createAction(LOGS_ACTION_TYPES.REQUEST_LOGS_DATA, data);
  }
);

export const receiveLogsData = withMatcher(
  (data: any): ReceiveLogsData => {
    return createAction(LOGS_ACTION_TYPES.RECEIVE_LOGS_DATA, data);
  }
);