import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

const CustomIconButton = ({ onClick, lable, children, disabled }) => {
    return (
        <IconButton disabled={disabled} onClick={onClick} aria-label={lable}
                    style={{ margin: 0, padding: 0, color: "rgba(0, 0, 0, 0.54)" }}>
            {children}
        </IconButton>
    );
};
 
// CustomIconButton.propTypes = {
//     onClick: PropTypes.func,
//     disabled: PropTypes.bool,
// };

export default CustomIconButton;
