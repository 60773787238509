import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestTripReport,
  ReceiveTripReport,
  RequestTripDaysReport,
  ReceiveTripDaysReport,
  RequestTripVehiclesReport,
  ReceiveTripVehiclesReport,
  RequestTripUsersReport,
  ReceiveTripUserReport,
  RequestTripMileageReport,
  ReceiveTripMileageReport,
  RequestTripIdleTimeReport,
  ReceiveTripIdleTimeReport,
  RequestVehicleReport,
  ReceiveVehicleReport,
  RequestDriveboxReport,
  ReceiveDriveboxReport,
} from "../types/actions/actionTripReport.types";
import {
  TripReportResponse,
  DailyTripReportResponse,
  VehicleTripReportResponse,
  UserTripReportResponse,
  MileageTripReportResponse,
  VehicleIdleTimeResponse,
  VehicleReportResponse,
  DriveBoxReportResponse,
} from "../types/tripReport/tripReportResponse.types";

import { VehicleQueryParamsRequest } from "../types/vehicle/vehicleRequests.types";

import {
  TripReportRequest,
  TripMileageReportRequest,
  VehicleIdleTimeRequest,
} from "../types/tripReport/tripReportRequest.types";

export enum REPORT_ACTION_TYPES {
  REQUEST_TRIP_REPORT = "REQUEST_TRIP_REPORT",
  RECEIVE_TRIP_REPORT = "RECEIVE_TRIP_REPORT",

  REQUEST_TRIP_DAYS_REPORT = "REQUEST_TRIP_DAYS_REPORT",
  RECEIVE_TRIP_DAYS_REPORT = "RECEIVE_TRIP_DAYS_REPORT",

  REQUEST_TRIP_VEHICLES_REPORT = "REQUEST_TRIP_VEHICLES_REPORT",
  RECEIVE_TRIP_VEHICLES_REPORT = "RECEIVE_TRIP_VEHICLES_REPORT",

  REQUEST_TRIP_USERS_REPORT = "REQUEST_TRIP_USERS_REPORT",
  RECEIVE_TRIP_USERS_REPORT = "RECEIVE_TRIP_USERS_REPORT",

  REQUEST_TRIP_MILEAGE_REPORT = "REQUEST_TRIP_MILEAGE_REPORT",
  RECEIVE_TRIP_MILEAGE_REPORT = "RECEIVE_TRIP_MILEAGE_REPORT",

  REQUEST_TRIP_IDLETIME_REPORT = "REQUEST_TRIP_IDLETIME_REPORT",
  RECEIVE_TRIP_IDLETIME_REPORT = "RECEIVE_TRIP_IDLETIME_REPORT",

  REQUEST_VEHICLE_REPORT = "REQUEST_VEHICLE_REPORT",
  RECEIVE_VEHICLE_REPORT = "RECEIVE_VEHICLE_REPORT",

  REQUEST_DRIVEBOX_REPORT = "REQUEST_DRIVEBOX_REPORT",
  RECEIVE_DRIVEBOX_REPORT = "RECEIVE_DRIVEBOX_REPORT",
}

export const requestTripReport = withMatcher(
  (data: TripReportRequest): RequestTripReport => {
    return createAction(REPORT_ACTION_TYPES.REQUEST_TRIP_REPORT, data);
  }
);

export const receiveTripReport = withMatcher(
  (data: TripReportResponse): ReceiveTripReport => {
    return createAction(REPORT_ACTION_TYPES.RECEIVE_TRIP_REPORT, data);
  }
);

export const requestTripDaysReport = withMatcher(
  (data: TripReportRequest): RequestTripDaysReport => {
    return createAction(
      REPORT_ACTION_TYPES.REQUEST_TRIP_DAYS_REPORT,
      data
    );
  }
);

export const receiveTripDaysReport = withMatcher(
  (data: DailyTripReportResponse[]): ReceiveTripDaysReport => {
    return createAction(
      REPORT_ACTION_TYPES.RECEIVE_TRIP_DAYS_REPORT,
      data
    );
  }
);

export const requestTripVehiclesReport = withMatcher(
  (data: TripReportRequest): RequestTripVehiclesReport => {
    return createAction(
      REPORT_ACTION_TYPES.REQUEST_TRIP_VEHICLES_REPORT,
      data
    );
  }
);

export const receiveTripVehiclesReport = withMatcher(
  (data: VehicleTripReportResponse[]): ReceiveTripVehiclesReport => {
    return createAction(
      REPORT_ACTION_TYPES.RECEIVE_TRIP_VEHICLES_REPORT,
      data
    );
  }
);

export const requestTripUsersReport = withMatcher(
  (data: TripReportRequest): RequestTripUsersReport => {
    return createAction(
      REPORT_ACTION_TYPES.REQUEST_TRIP_USERS_REPORT,
      data
    );
  }
);

export const receiveTripUserReport = withMatcher(
  (data: UserTripReportResponse[]): ReceiveTripUserReport => {
    return createAction(
      REPORT_ACTION_TYPES.RECEIVE_TRIP_USERS_REPORT,
      data
    );
  }
);

export const requestTripMileageReport = withMatcher(
  (data: TripMileageReportRequest): RequestTripMileageReport => {
    return createAction(
      REPORT_ACTION_TYPES.REQUEST_TRIP_MILEAGE_REPORT,
      data
    );
  }
);

export const receiveTripMileageReport = withMatcher(
  (data: MileageTripReportResponse[]): ReceiveTripMileageReport => {
    return createAction(
      REPORT_ACTION_TYPES.RECEIVE_TRIP_MILEAGE_REPORT,
      data
    );
  }
);

export const requestTripIdleTimeReport = withMatcher(
  (data: VehicleIdleTimeRequest): RequestTripIdleTimeReport => {
    return createAction(
      REPORT_ACTION_TYPES.REQUEST_TRIP_IDLETIME_REPORT,
      data
    );
  }
);

export const receiveTripIdleTimeReport = withMatcher(
  (data: VehicleIdleTimeResponse): ReceiveTripIdleTimeReport => {
    return createAction(
      REPORT_ACTION_TYPES.RECEIVE_TRIP_IDLETIME_REPORT,
      data
    );
  }
);

export const requestVehicleReport = withMatcher(
  (data: VehicleQueryParamsRequest): RequestVehicleReport => {
    return createAction(REPORT_ACTION_TYPES.REQUEST_VEHICLE_REPORT, data);
  }
);

export const receiveVehicleReport = withMatcher(
  (data: VehicleReportResponse): ReceiveVehicleReport => {
    return createAction(REPORT_ACTION_TYPES.RECEIVE_VEHICLE_REPORT, data);
  }
);

export const requestDriveboxReport = withMatcher((): RequestDriveboxReport => {
  return createAction(REPORT_ACTION_TYPES.REQUEST_DRIVEBOX_REPORT);
});

export const receiveDriveboxReport = withMatcher(
  (data: DriveBoxReportResponse): ReceiveDriveboxReport => {
    return createAction(REPORT_ACTION_TYPES.RECEIVE_DRIVEBOX_REPORT, data);
  }
);
