import { AnyAction } from "redux";

import {
  receiveTripReport,
  receiveTripVehiclesReport,
  receiveTripUserReport,
  receiveTripDaysReport,
  receiveTripMileageReport,
  receiveTripIdleTimeReport,
  receiveVehicleReport,
  receiveDriveboxReport,
} from "../actions/actionReport";

import {
  receiveLogout,
  receiveUnAuthoriseUser,
} from "../actions/actionAuthentication";

import {
  TripReportResponse,
  VehicleTripReportResponse,
  VehicleReportResponse,
  UserTripReportResponse,
  DailyTripReportResponse,
  MileageTripReportResponse,
  DriveBoxReportResponse,
} from "../types/tripReport/tripReportResponse.types";

export type ReportState = {
  readonly trip: TripReportResponse;
  readonly vehicle: VehicleReportResponse;
  readonly drivebox: DriveBoxReportResponse;
  readonly mileage: MileageTripReportResponse[];
  readonly vehicles: VehicleTripReportResponse[];
  readonly users: UserTripReportResponse[];
  readonly days: DailyTripReportResponse[];
  readonly idleTime?: number;
};

const REPORTS_INITIAL_STATE: ReportState = {
  trip: {} as TripReportResponse,
  vehicle: {} as VehicleReportResponse,
  drivebox: {} as DriveBoxReportResponse,
  mileage: [],
  vehicles: [],
  users: [],
  days: [],
  idleTime: undefined,
};

export const reports = (state = REPORTS_INITIAL_STATE, action: AnyAction) => {
  if (receiveTripReport.match(action)) {
    return { ...state, trip: action.data };
  }

  if (receiveTripVehiclesReport.match(action)) {
    return { ...state, vehicles: action.data };
  }

  if (receiveTripUserReport.match(action)) {
    return { ...state, users: action.data };
  }

  if (receiveTripDaysReport.match(action)) {
    return { ...state, days: action.data };
  }

  if (receiveTripMileageReport.match(action)) {
    return { ...state, mileage: action.data };
  }

  if (receiveTripIdleTimeReport.match(action)) {
    return { ...state, idleTime: action.data.idleTimeInMinutes };
  }

  if (receiveVehicleReport.match(action)) {
    return { ...state, vehicle: action.data };
  }

  if (receiveDriveboxReport.match(action)) {
    return { ...state, drivebox: action.data };
  }

  if (receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
    return REPORTS_INITIAL_STATE;
  }

  return state;
};
