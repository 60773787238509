import React from "react";
import {makeStyles} from "@material-ui/core";
import BgImage from '../../images/loginBg.jpg';

const useLoginStyles = makeStyles(theme => ({
    page: {
        backgroundImage: `url(${BgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        paddingBottom: "60%",
        [theme.breakpoints.up('sm')]: {
            maxHeight: "100%",
            width: "100%",
            height: "100%",
            top: "0",
            position: "fixed",
        },
    },
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            width: 500,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    paperContainer: {
        marginTop: "25%",
        [theme.breakpoints.up('sm')]: {
            marginTop: "30%",
        },

    },
    paper: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        paddingRight: "40px",
        paddingLeft: "40px",
        paddingBottom: "20px",
        borderRadius: "25px",
        backgroundColor: "rgba(144,185,239,0.581)",
    },
    logo: {
        display: "block",
        marginLeft: "-40px",
        marginRight: "auto",

        marginBottom: "30px",
        marginTop: "10px"

    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
        borderRadius: "25px",
        //width: "100px",
        //height: "40px"
    },
    error: {
        marginTop: theme.spacing(1),
    },
    rememberMe: {
        marginTop: "20px",
        color: "white"
    },
    forgotPasswordContainer: {
        marginTop: "15px",
        marginBottom: "20px",
        //marginRight: "235px",
        //overflow: "hidden",
        //whiteSpace: "nowrap"
    },
    labelText: {
        color: "white",
        fontWeight: "bold",
        fontFamily: "Arial"
    },
    inputLogin: {
        padding: "8px 20px",
        borderRadius: "25px",
        backgroundColor: "rgba(144,185,239,0.581)",
        outline: "none",
        borderColor: "transparent",
        border: "none",
        color: "white"
    },
    version: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        zIndex: theme.zIndex.tooltip,
        borderRadius: '20px',
    }
}));

export default useLoginStyles;