import React from "react";
import PropTypes from "prop-types";
import Toolbar from "../common/toolbar/Toolbar";
import { allowedRoles, isAuthorized } from "../../managers/authManager";
import { roles } from "../../models/Role";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import { useDispatch, useSelector } from "react-redux";
import { deselectAllTrips } from "../../actions/actionTrips";
import { tripType } from "../../models/TripType";

/**
 *
 * @param {object} param0
 * @param {string} param0.name
 * @param {()=>void | undefined} param0.onLock
 * @param {()=>void | undefined} param0.onBulkEdit
 */
function GroupTripToolbar({ name, onLock, onBulkEdit }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.users.me);
  const trips = useSelector((state) => state.trips.entities);
  const selected = useSelector((state) => state.trips.selected);

  let isAPrivateTripSelected = trips
    .filter((t) => t.isSelected)
    .some(
      (t) => t.type === tripType.Private && (!t.driver || me.id !== t.driver.id)
    );

  const bulkActionButtons = [];
  if (isAuthorized(me, allowedRoles(roles.Driver)) && !isAPrivateTripSelected) {
    bulkActionButtons.push({
      icon: <FontAwesomeIcon>far fa-pen</FontAwesomeIcon>,
      translateKey: "trip.page.bulkedit",
      onClick: onBulkEdit,
    });
  }

  if (
    (isAuthorized(me, allowedRoles(roles.Driver)) && !isAPrivateTripSelected) ||
    me.isHWNOE
  ) {
    bulkActionButtons.push({
      icon: <FontAwesomeIcon>far fa-lock</FontAwesomeIcon>,
      translateKey: "trip.page.lock",
      onClick: () => {
        onLock();
        dispatch(deselectAllTrips());
      },
    });
  }

  return (
    <Toolbar
      bulkActionButtons={bulkActionButtons}
      title={"Filtere Fahrten nach der Gruppe " + name}
      numberSelected={selected}
    />
  );
}

// GroupTripToolbar.propTypes = {
//   name: PropTypes.string.isRequired,
//   onLock: PropTypes.func,
//   onBulkEdit: PropTypes.func,
// };

export default GroupTripToolbar;
