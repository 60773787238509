import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//Material ui
import {
  makeStyles,
  Table,
  TableCell,
  TableRow,
  Typography,
  TableBody,
} from "@material-ui/core";
//common
import IconButton from "../common/buttons/IconButton";
//Icons
import FolderIcon from "@material-ui/icons/FolderOutlined";
import PersonIcon from "@material-ui/icons/Person";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import LoadingContainer from "../common/container/LoadingContainer";

const types = {
  node: "node",
  vehicle: "vehicle",
  user: "user",
};

const useStyles = makeStyles({
  contextMenuColumn: {
    width: "50px",
  },
  iconColumn: {
    width: "50px",
    marginLeft: "70px",
    cursor: "pointer",
  },
  tableCell: {
    cursor: "pointer",
  },
});

const GroupTable = (props) => {
  const classes = useStyles();

  const { group, onClick, onContextMenuOpen } = props;
  const { children, vehicles, users } = group;
  let data = [];

  const getIconByType = (type) => {
    switch (type) {
      case types.node:
        return <FontAwesomeIcon>far fa-folder</FontAwesomeIcon>;
      case types.vehicle:
        return <FontAwesomeIcon>far fa-car</FontAwesomeIcon>;
      case types.user:
        return <FontAwesomeIcon>far fa-user</FontAwesomeIcon>;
    }
    return "No icon found";
  };

  if (!group.children) {
    return <LoadingContainer></LoadingContainer>; //TODO return loading
  }

  if (group.children) {
    data = data.concat(children.map((node) => ({ ...node, type: types.node })));
    if (vehicles) {
      data = data.concat(
        vehicles.map((vehicle) => ({ ...vehicle, type: types.vehicle }))
      );
    }
    if (users) {
      data = data.concat(users.map((user) => ({ ...user, type: types.user })));
    }

    if (data.length > 0)
      return (
        <Table style={{ minHeight: "38px" }}>
          <TableBody>
            {data.map((d) => {
              return (
                <TableRow onDoubleClick={(e) => onClick(d)} key={d.id} hover>
                  <TableCell
                    onClick={(e) => onClick(d)}
                    className={classes.iconColumn}
                  >
                    <IconButton disabled>{getIconByType(d.type)}</IconButton>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={(e) => onClick(d)}
                  >
                    <Typography variant="body2" color="textPrimary">
                      {d.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.contextMenuColumn}
                    align="right"
                  >
                    <IconButton
                      onClick={(e) => onContextMenuOpen(e, d)}
                      label="Open modal"
                    >
                      <FontAwesomeIcon>far fa-ellipsis-v</FontAwesomeIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
  }
  return <div />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProp(state) {
  return {
    group: state.group,
  };
}

// GroupTable.propTypes = {
//   onClick: PropTypes.func.isRequired,
//   onContextMenuOpen: PropTypes.func.isRequired,
// };

export default connect(mapStateToProp, mapDispatchToProps)(GroupTable);
