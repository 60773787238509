import { takeLatest, put, call } from "typed-redux-saga/macro";

import { handleError } from "../../managers/sagaManager";

import { getEvoConnectorByIdRequest } from "../api";

import { receiveConnectorById, CONNECTOR_ACTION_TYPES } from "../../actions/qualityCharging/actionConnectors";

import { RequestConnectorById } from "../../types/actions/qualityCharging/actionConnectors.types";

function* getEvoConnectorById(action: RequestConnectorById) {
  try {
    const response = yield* call(getEvoConnectorByIdRequest, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveConnectorById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}


export default function* sagaConnectors() {
  yield* takeLatest(
    CONNECTOR_ACTION_TYPES.REQUEST_CONNECTOR_BY_ID,
    getEvoConnectorById
  );
}
