import React, {useState} from "react";
import CustomSelect from "../../common/selects/Select";
import {Select} from "antd";
import PropTypes from "prop-types";
import {finishModes, finishModesArray} from "../../../models/FinishModes";

const {Option} = Select;
const FinishSelect = props => {

    const {onChange, value} = props;
    const [date, setDate] = useState(new Date());

    return <CustomSelect value={value || finishModes.never}
                         allowClear={false}
                         handleChange={onChange}
                         placeholder="Never">
        {finishModesArray.map(p => {
            return <Option key={p.id} value={p.id}>
                {p.name}
            </Option>
        })}
    </CustomSelect>
};

// FinishSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string
// }

export default FinishSelect;