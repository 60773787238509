import { withMatcher, createAction } from "../../utils/reducer/reducer.utils";

import { RequestConnectorById, ReceiveConnectorById } from "../../types/actions/qualityCharging/actionConnectors.types";
import { ConnectorByIdRequest } from "../../types/qualityCharging/qualityChargingRequests.types";

export enum CONNECTOR_ACTION_TYPES {
  REQUEST_CONNECTOR_BY_ID = "REQUEST_CONNECTOR_BY_ID",
  RECEIVE_CONNECTOR_BY_ID = "RECEIVE_CONNECTOR_BY_ID"
}

// Type "any" needs to change to a more specific type later
export const requestConnectorById = withMatcher((data: ConnectorByIdRequest): RequestConnectorById => {
  return createAction(CONNECTOR_ACTION_TYPES.REQUEST_CONNECTOR_BY_ID, data);
});

export const receiveConnectorById = withMatcher((data: any): ReceiveConnectorById => {
  return createAction(CONNECTOR_ACTION_TYPES.RECEIVE_CONNECTOR_BY_ID, data);
});
