import {Icon} from "@material-ui/core";

const FontAwesomeIcon = ({children, color, minWidth, fontSize, marginTop}) => {
    const style = {minWidth: minWidth, fontSize: fontSize, marginTop: marginTop};
    return <Icon className={children} color={color} style={style}/>;
};

FontAwesomeIcon.defaultProps = {
    color: "inherit"
}

export default FontAwesomeIcon;
