const finishModes = {
    never: 1,
    date: 2,
    occurrences: 3, 
};

const finishModesArray = [
    {id: finishModes.never, name: "Niemals"},
    {id: finishModes.date, name: "Ende an einem Datum"},
    {id: finishModes.occurrences, name: "Ende nach x Wiederholungen"},
];

export {finishModes, finishModesArray};
