import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestStatus,
  ReceiveStatus,
  SetLanguage,
  ReceiveError,
  SetError,
  ClearError,
} from "../types/actions/actionCommon.types";

import {
  CommonStatusResponse,
  CustomError,
  MessageError,
} from "../types/common/commonResponse.types";

export enum COMMON_ACTION_TYPES {
  REQUEST_STATUS = "REQUEST_STATUS",
  RECEIVE_STATUS = "RECEIVE_STATUS",
  SET_LANGUAGE = "SET_LANGUAGE",
  RECEIVE_ERROR = "RECEIVE_ERROR",
  SET_ERROR = "SET_ERROR",
  CLEAR_ERROR = "CLEAR_ERROR",
}

export const requestStatus = withMatcher((): RequestStatus => {
  return createAction(COMMON_ACTION_TYPES.REQUEST_STATUS);
});

export const receiveStatus = withMatcher(
  (data: CommonStatusResponse): ReceiveStatus => {
    return createAction(COMMON_ACTION_TYPES.RECEIVE_STATUS, data);
  }
);

//later we need to pick a more specific data type(narrower) because language can have a enum type instead of a string.
export const setLanguage = withMatcher((data: string): SetLanguage => {
  return createAction(COMMON_ACTION_TYPES.SET_LANGUAGE, data);
});

export const receiveError = withMatcher((data: CustomError): ReceiveError => {
  return createAction(COMMON_ACTION_TYPES.RECEIVE_ERROR, data);
});

export const setError = withMatcher((data: MessageError): SetError => {
  return createAction(COMMON_ACTION_TYPES.SET_ERROR, data);
});

export const clearError = withMatcher((): ClearError => {
  return createAction(COMMON_ACTION_TYPES.CLEAR_ERROR);
});
