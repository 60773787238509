import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import EditModal from "../../common/modals/CustomModal";

const LiveLocationIntroductionModal = props => {
    const { onClose } = props;
    const generalDialogContent = (
        <div>
            <Typography>
                Welcome to the live location view. This view is showing the current location of your vehicles on a map
                but vehicles driving in private
                mode.
            </Typography>

            <Typography>
                Some hints to the start:
                <ul>
                    <li>Pulsing Icons: Moving Cars</li>
                    <li>Normal Icons: Standing Cars</li>
                    <li>The map refreshes automaticly every minute</li>
                </ul>
            </Typography>
        </div>
    );
    const contents = [generalDialogContent];
    return <EditModal action="ok" onClose={onClose} title="Introduction - Live Location View" contents={contents}/>;
};

// LiveLocationIntroductionModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

export default LiveLocationIntroductionModal;
