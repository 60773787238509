import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardContent } from "@material-ui/core";
import { menu } from "../../managers/pathManager";
import { secondsToString } from "../../managers/timeManager";
import GenericTable from "../common/table/GenericTable";
import {
  customTranslation,
  getTranslation,
} from "../../translations/customTranslation";
import { useTranslation } from "react-i18next";

const TripUserStatisticTable = ({ users, userMode, vehicles, me }) => {

    const { t } = useTranslation();

  const userColumnInformations = [
    { title: t("dashboard.table.driver"), key: "user.name" },
    { title: t("dashboard.table.trips"), key: "trips" },
    { title: t("dashboard.table.duration"), key: "duration" },
    {
      title: getTranslation(customTranslation.business, me),
      key: "businessMileageInMeters",
    },
    {
      title: t("dashboard.table.private"),
      key: "privateMileageInMeters",
    },
    {
      title: getTranslation(customTranslation.driveToWork, me),
      key: "driveToWorkMileageInMeters",
    },
  ];

  const vehicleColumnInformations = [
    { title: t("dashboard.table.vehicle"), key: "vehicle.name" },
    { title: t("dashboard.table.trips"), key: "trips" },
    { title: t("dashboard.table.duration"), key: "duration" },
    {
      title: getTranslation(customTranslation.business, me),
      key: "businessMileageInMeters",
    },
    {
      title: t("dashboard.table.private"),
      key: "privateMileageInMeters",
    },
    {
      title: getTranslation(customTranslation.driveToWork, me),
      key: "driveToWorkMileageInMeters",
    },
  ];

  const mapData = (u) => {
    u.trips =
      Math.round(u.mileageInMeters / 1000) +
      "km (" +
      (u.businessTripCount + u.privateTripCount + u.driveToWorkTripCount) +
      " Fahrten)";
    u.duration = secondsToString(u.timeInSeconds);
    u.businessMileageInMeters =
      Math.round(u.businessMileageInMeters / 1000) +
      " km (" +
      u.businessTripCount +
      " Fahrten)";
    u.privateMileageInMeters =
      Math.round(u.privateMileageInMeters / 1000) +
      " km (" +
      u.privateTripCount +
      " Fahrten)";
    u.driveToWorkMileageInMeters =
      Math.round(u.driveToWorkMileageInMeters / 1000) +
      " km (" +
      u.driveToWorkTripCount +
      " Fahrten)";
    return u;
  };

  return (
    <Card>
      <GenericTable
        columnInformations={
          userMode ? vehicleColumnInformations : userColumnInformations
        }
        data={
          userMode
            ? vehicles.map((v) => mapData(v))
            : users.map((u) => mapData(u))
        }
      />
    </Card>
  );
};

function mapStateToProp(state) {
  return {
    me: state.users.me,
    users: state.reports.users,
    vehicles: state.reports.vehicles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(TripUserStatisticTable);
