import React from "react";
import { connect, useSelector } from "react-redux";
import GenericTable from "../common/table/GenericTable";
import TableTypes from "../../models/TableTypes";
import Comparator from "../../models/Comparator";
import {
  AddressPrintLevel,
  addressToStr,
} from "../../managers/locationManager";
import {
  secondsToString,
  serverTimeToSeconds,
} from "../../managers/timeManager";
import EditIcon from "@material-ui/icons/EditTwoTone";
import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../managers/authManager";
import { packages } from "../../models/Package";
import { roles } from "../../models/Role";

import { tripType } from "../../models/TripType";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import {
  customTranslation,
  getTranslation,
} from "../../translations/customTranslation";
import { Typography, makeStyles } from "@material-ui/core";
import {calculateFuelOrBatteryTripData } from "../../utils/CalculateFuelLevel"
import { findEngineTypeById } from "../../models/EngineType";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  noTripsContainer: {
    display: "flex",
    minHeight: "50vh",
    justifyContent: "center",
    alignItems: "center",
  }
});

const TripTable = (props) => {
  const {
    trips,
    total,
    page,
    rowsPerPage,
    onSelect,
    onSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    onLock,
    onUnlock,
    onTrack,
    onDelete,
    onHistory,
    onToBusiness,
    me,
    loading,
    isInGeofenceReport,
    onlyShowBusinessTrips,
    showCheckboxes,
    showVehicle,
    settings,
    pageName
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const isFuelMonitorAuthorized = isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor]);
  const engineTypeById = trips.length > 0 && findEngineTypeById(trips[trips.length - 1].vehicle.engineType);
  const engineType = engineTypeById ? engineTypeById.name : "";

  const viewer = isViewer(me);
  const openTrip = useSelector((state) => state.vehicles.openTrip);
  const noTrips = trips.length === 0;

    const customTripComparator = new Comparator(
    ["agent"],
    (agent) => agent == "1"
  );
  const changedComparator = new Comparator(
    ["hasBeenChanged"],
    (hasBeenChanged) => hasBeenChanged
  );
  const calibratedAtComparator = new Comparator(
    ["calibratedAt"],
    (calibratedAt) => Boolean(calibratedAt)
  );

  const notLockedComparator = new Comparator(
    ["locked", "type", "driver.id"],
    (locked, type, driverID) =>
      !locked &&
      (type != tripType.Private ||
        (type == tripType.Private && me.id == driverID) ||
        me.isHWNOE)
  );
  const isLockedComparator = new Comparator(
    ["locked", "type", "driver.id"],
    (locked, type, driverID) => locked
  );
  const isYourPrivateTripComparator = new Comparator(
    ["type", "driver.id"],
    (type, driverID) =>
      type != tripType.Private ||
      (type == tripType.Private && me.id == driverID)
  );
  const isToBusinessComparator = new Comparator(
    ["type", "driver.id"],
    (type, driverID) =>
      !(
        type != tripType.Private ||
        (type == tripType.Private && me.id == driverID)
      )
  );

  const checkTripForPrivateRide = (trip) =>
    trip.type != tripType.Private ||
    (trip.type == tripType.Private && me.id == trip.driver.id)
      ? onTrack(trip)
            : undefined;

    function tripTypeFilter(trip) {
        if (onlyShowBusinessTrips) {
            if (trip.type !== tripType.Business) {
                return false;
            }
        }
        return true;
    }

  const tripsWithMappedAddress = trips.filter(tripTypeFilter).map((trip) => {
    const { averageFuelConsumption, overConsumption, underConsumption } = calculateFuelOrBatteryTripData(trip);
    trip.mappedStartAddress = addressToStr(
      trip.startAddress,
      AddressPrintLevel.street
    );
    trip.mappedDestinationAddress = addressToStr(
      trip.destinationAddress,
      AddressPrintLevel.street
    );
    trip.mappedDescription = trip.description
      ? trip.description.length > 20
        ? trip.description.substring(0, 20) + "..."
        : trip.description
          : "";
      var mileageInKm = trip.mileageInMeters / 1000;

      trip.mappedMileage = mileageInKm.toLocaleString(navigator.languages, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " km";
      if (mileageInKm < 1) trip.mappedMileage = "< " + (1).toLocaleString(navigator.languages, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " km";;
    trip.mappedTripType =
      trip.type == tripType.Business ? (
        getTranslation(customTranslation.business, me)
      ) : trip.type == tripType.Private ? (
        t("trip.table.private")
      ) : trip.type == tripType.DriveToWork ? (
        getTranslation(customTranslation.driveToWork, me)
      ) : (
        ""
      );
    trip.locked = Boolean(trip.lockedAt);
    trip.mappedDuration = secondsToString(serverTimeToSeconds(trip.duration));
    trip.mappedIdleTime = secondsToString(serverTimeToSeconds(trip.idleTime));
    trip.mappedDate = trip.startedAt;
    trip.hasDriver = Boolean(trip.driver);
    trip.mappedStartingBatteryChargeLevelInPercent = trip.startingBatteryChargeLevelInPercent ? trip.startingBatteryChargeLevelInPercent + "%" : "Keine Daten";
    trip.mappedFinishingBatteryChargeLevelInPercent = trip.finishingBatteryChargeLevelInPercent ? trip.finishingBatteryChargeLevelInPercent + "%" : "Keine Daten";

    trip.mappedDriver = trip.driver ? (
      trip.driver.name
    ) : (
      t("trip.table.noDriver")
    );
    trip.mappedVehicle = trip.vehicle?.name || (
      t("trip.table.noVehicle")
    );
    return {
      ...trip,
      averageFuelConsumption,
      overConsumption,
      underConsumption
    };
  });

  if (openTrip.positions.length > 0 && page === 0) {
    tripsWithMappedAddress.unshift({
      mappedDriver: openTrip.driver ? (
        openTrip.driver.name
      ) : (
        t("trip.table.noDriver")
      ),
      mappedIdleTime: "-",
      mappedDuration: "-",
      locked: true,
      lockedAt: new Date(),
      mappedTripType: "-",
      mappedStartAddress: addressToStr(
        openTrip.startAddress,
        AddressPrintLevel.street
      ),
      mappedDestinationAddress: "Fahrt wurde noch nicht beendet",
      startedAt: openTrip.startedAt,
      mappedDate: openTrip.startedAt,
      finishedAt: null,
    });
  }

  const columnInformations = [
    {
      title: t("trip.table.driver"),
      key: "mappedDriver",
      color: {
        value: "red",
        comparator: new Comparator(["hasDriver"], (hasDriver) => !hasDriver),
      },
    },
  ];

  if (showVehicle) {
    columnInformations.push({
      title: t("trip.table.vehicle"),
      key: "mappedVehicle",
    });
  }

  columnInformations.push({
    title: t("trip.table.date"),
    key: "mappedDate",
    type: TableTypes.date,
  });
  columnInformations.push({
    title: t("trip.table.startAt"),
    key: "startedAt",
    type: TableTypes.time,
  });
  columnInformations.push({
    title: t("trip.table.finishAt"),
    key: "finishedAt",
    type: TableTypes.time,
  });
  columnInformations.push({
    title: t("trip.table.duration"),
    key: "mappedDuration",
    noBr: true,
  });
  columnInformations.push({
    title: t("trip.table.startAddress"),
    key: "mappedStartAddress",
  });
  columnInformations.push({
    title: t("trip.table.destinationAddress"),
    key: "mappedDestinationAddress",
  });
  columnInformations.push({
    title: t("trip.table.mileage"),
    key: "mappedMileage",
    noBr: true,
  });
  columnInformations.push({
    title: t("trip.table.tripType"),
    key: "mappedTripType",
  });
  
  // This code adds properties for fuel vehicles
  if (isFuelMonitorAuthorized) {
    if (engineType === "Fuel") {
      columnInformations.push(
        {
          title: "Fuel Start",
          key: "startingBatteryFuelLevelInLiter",
          noBr: true,
          type: TableTypes.fuelAtStart
        },
        {
          title: "Fuel Finish",
          key: "finishingBatteryFuelLevelInLiter",
          noBr: true,
          type: TableTypes.fuelAtFinish
        },
        {
          title: "Average Fuel",
          key: "averageFuelConsumption",
          noBr: true,
          type: TableTypes.averageFuel
        }
      );
    } else if (engineType === "E-Car") {
      columnInformations.push(
        {
          title: "Batterie (Start)",
          key: "mappedStartingBatteryChargeLevelInPercent",
          noBr: true,
          type: TableTypes.fuelAtStart
        },
        {
          title: "Batterie (Ziel)",
          key: "mappedFinishingBatteryChargeLevelInPercent",
          noBr: true,
          type: TableTypes.fuelAtFinish
        },
        {
          title: "Average Fuel",
          key: "averageFuelConsumption",
          noBr: true,
          type: TableTypes.averageFuel
        }
      );
    };
  }
  
  

  columnInformations.push({
    title: t("trip.table.description"),
    key: "mappedDescription",
  });

  if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]))
    columnInformations.push({
      title: t("trip.table.costCenter"),
      key: "costcenterKey",
      noBr: true,
    });

  const actions = [
    {
      title: t("trip.table.lock"),
      event: onLock,
      comparator: notLockedComparator,
    },
  ];

  if (onTrack) {
    actions.push({
      title: t("trip.table.map"),
      event: onTrack,
      comparator: isYourPrivateTripComparator,
    });
  }

  if (onHistory) {
    actions.push({
      title: t("trip.table.history"),
      event: onHistory,
      comparator: isYourPrivateTripComparator,
    });
  }

  if (onDelete) {
    actions.push({
      title: t("trip.table.delete"),
      event: onDelete,
      comparator: customTripComparator,
    });
  }

  if (
    onToBusiness &&
    me.isHWNOE &&
    isAuthorized(me, allowedRoles(roles.GroupLeader))
  )
    actions.push({
      title: t("trip.table.toBusiness"),
      event: onToBusiness,
      comparator: isToBusinessComparator,
    });

  if (me.isHWNOE && isAuthorized(me, allowedRoles(roles.Admin)))
    actions.push({
      title: t("trip.table.unlock"),
      event: onUnlock,
      comparator: isLockedComparator,
    });

  const viewerActions = [
    {
      title: t("trip.table.history"),
      event: onHistory,
      comparator: isYourPrivateTripComparator,
    },
  ];

  const typeIcons = [
    {
      icon: <FontAwesomeIcon color={"secondary"}>far fa-pen</FontAwesomeIcon>,
      comparator: calibratedAtComparator,
      title: t("trip.table.calibratedTrip"),
    },
    {
      icon: <FontAwesomeIcon color={"secondary"}>far fa-tools</FontAwesomeIcon>,
      comparator: customTripComparator,
      title: t("trip.table.manuallyTrip"),
    },
    {
      icon: <EditIcon />,
      comparator: changedComparator,
      title: t("trip.table.editTrip"),
    },
  ];

  return (
    noTrips ? <div className={classes.noTripsContainer}>
    <Typography variant="h5">
      {t("trip.noTrips")}
    </Typography>
    </div>:
    <GenericTable
      data={tripsWithMappedAddress}
      onEdit={checkTripForPrivateRide}
      onRowClick={checkTripForPrivateRide}
      columnInformations={columnInformations}
      actions={viewer ? viewerActions : actions}
      rowCount={total}
      pageNavigation
      page={page}
      rowsPerPage={rowsPerPage}
      pageName={pageName}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      onSelectAllClick={onSelectAll}
      onSelect={onSelect}
      numSelected={trips.filter((trip) => trip.isSelected).length}
      showActions={isInGeofenceReport ? false : true}
      showCheckboxes={
        showCheckboxes === undefined
          ? isInGeofenceReport
            ? false
            : !viewer
          : showCheckboxes
      }
      typeIcons={isInGeofenceReport ? false : typeIcons}
      loading={loading}
      settings={settings}
      engineType={engineType}
    />
  );
};
TripTable.defaultProps = {
  page: 0,
  showVehicle: false,
};

function mapStateToProp(state) {
  return {
    trips: state.trips.entities,
    costcenters: state.costcenters.entities,
    loading: state.trips.loading,
    total: state.trips.total,
    me: state.users.me,
  };
}

export default connect(mapStateToProp)(TripTable);
