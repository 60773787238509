class FormBuilder {
    constructor(build) {
        this.name = build.name;
        this.component = build.component;
        this.value = build.value;
        this.key = build.key || build.name; //TODO do not work in translation -> every attribute must have a key
        this.valuePropName = build.valuePropName || "value";
        this.triggerName = build.triggerName || "onChange";
        this.validation = {
            description: build.validationMessage || "",
            required: build.required || false,
            type: build.type,
        };
        this.subGroup = build.subGroup;
        this.disabled = build.disabled;
        this.hide = build.hide || false;
        this.role = build.role;
        this.customRule = build.customRule;
    }

    static get FormInput() {
        class Builder {
            constructor(name, component) {
                this.name = name;
                this.component = component;

                // default values
                this.required = false;
                this.validationMessage = "";
                this.disabled = false;
            }

            withRole(role) {
                this.role = role;
                return this;
            }

            withValue(value) {
                this.value = value;
                return this;
            }

            withKey(key) {
                this.key = key;
                return this;
            }

            withType(type) {
                this.type = type;
                return this;
            }

            withValidationMessage(description) {
                this.validationMessage = description;
                return this;
            }

            withValuePropName(propName) {
                this.valuePropName = propName;
                return this;
            }

            withTrigger(triggerName) {
                this.triggerName = triggerName;
                return this;
            }

            withSubGroup(subGroupName) {
                this.subGroup = subGroupName;
                return this;
            }

            isRequired(shouldBeRequired = true) {
                this.required = shouldBeRequired;
                return this;
            }

            viewOnly() {
                this.disabled = true;
                this.key = "noKeyNeeded"
                return this;
            }

            withCustomRule(customRule) {
                this.customRule = customRule;
                return this;
            }

            hide(hideValue = true) {
                this.hide = hideValue;
                return this; 
            }

            build() {
                if (!this.required) this.validationMessage = "";
                return new FormBuilder(this);
            }
        }

        return Builder;
    }
}

export default FormBuilder;
