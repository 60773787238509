import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import { FilterUpdateAPICall } from "../types/filter/filter.types";

import {
  SetFilter,
  SetText,
  SetTextArray,
  ClearFilter,
  UpdateFilterAPICall,
  UpdateInitial,
} from "../types/actions/actionFilter.types";

export enum FILTER_ACTION_TYPES {
  SET_FILTER = "SET_FILTER",
  SET_TEXT = "SET_TEXT",
  SET_TEXT_ARRAY = "SET_TEXT_ARRAY",
  CLEAR_FILTER = "CLEAR_FILTER",
  UPDATE_FILTER_API_CALL = "UPDATE_FILTER_API_CALL",
  UPDATE_INITIAL = "UPDATE_INITIAL",
}

// later we need to pick a more specific type instead of 'any' type
export const setFilter = withMatcher(
  (initial: any, api?: any, text?: any): SetFilter => {
    return createAction(FILTER_ACTION_TYPES.SET_FILTER, { initial, api, text });
  }
);

export const setText = withMatcher((data: string): SetText => {
  return createAction(FILTER_ACTION_TYPES.SET_TEXT, data);
});

export const setTextArray = withMatcher((data: string[]): SetTextArray => {
  return createAction(FILTER_ACTION_TYPES.SET_TEXT_ARRAY, data);
});

export const clearFilter = withMatcher((): ClearFilter => {
  return createAction(FILTER_ACTION_TYPES.CLEAR_FILTER);
});

export const updateFilterAPICall = withMatcher(
  (
    filterOptions: FilterUpdateAPICall,
    boolean?: boolean
  ): UpdateFilterAPICall => {
    return createAction(
      FILTER_ACTION_TYPES.UPDATE_FILTER_API_CALL,
      filterOptions
    );
  }
);

// later this should change to a more specific type
export const updateInitial = withMatcher((data: any): UpdateInitial => {
  return createAction(FILTER_ACTION_TYPES.UPDATE_INITIAL, data);
});
