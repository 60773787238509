import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import FormatListBulletedRoundedIcon from "@material-ui/icons/FormatListBulletedRounded";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  stepFourDescription: { margin: "5px 0 25px" },
  description: {
    maxWidth: "375px",
    margin: "17px 0",
    textAlign: "center",
  },
  headerIcon: { fontSize: "110px" },
});

const ReservationWizardStepHeader = ({step, title}) => {
  const classes = useStyles();

  const reservationCustomHeaderIcon = () => {
    switch (step) {
      case 0:
        return <CalendarTodayIcon className={classes.headerIcon} />;
      case 1:
        return <DriveEtaIcon className={classes.headerIcon} />;
      case 2:
        return <NoteAddOutlinedIcon className={classes.headerIcon} />;
      case 3:
        return <FormatListBulletedRoundedIcon className={classes.headerIcon} />;
    }
  };

  return (
    <>
      {reservationCustomHeaderIcon()}
      <Typography
        variant="subtitle2"
        className={classNames(classes.description, {
          [classes.stepFourDescription]: step === 3,
        })}
      >
        {title}
      </Typography>
    </>
  );
};

export default ReservationWizardStepHeader;
