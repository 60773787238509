import React from "react";
import PropTypes from "prop-types";

import {Col, InputNumber, Row, Slider} from "antd";

const CustomSlider = ({handleChange, value, min, max, step}) => {
    return (
        <Row>
            <Col span={12}>
                <Slider min={min} max={max} onChange={handleChange}
                        value={typeof value === "number" ? (value < min ? min : value) : min}/>
            </Col>
            <Col span={4}>
                <InputNumber min={min} max={max} style={{marginLeft: 16}} value={value} onChange={handleChange}/>
            </Col>
        </Row>
    );
};

CustomSlider.defaultProps = {step: 10};

// CustomSlider.propTypes = {
//     handleChange: PropTypes.func.isRequired,
//     value: PropTypes.number.isRequired,
//     min: PropTypes.number.isRequired,
//     max: PropTypes.number.isRequired,
//     step: PropTypes.number,
// };

export default CustomSlider;
