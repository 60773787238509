export const REQUEST_MANDATOR_EXPORT_XLSX = "REQUEST_MANDATOR_EXPORT_XLSX";
export const REQUEST_MANDATOR_USER_EXPORT_XLSX =
  "REQUEST_MANDATOR_USER_EXPORT_XLSX";
export const REQUEST_MANDATOR_DRIVEBOX_EXPORT_XLSX =
  "REQUEST_MANDATOR_DRIVEBOX_EXPORT_XLSX";

export const REQUEST_TRIPS_EXPORT_PDF = "REQUEST_TRIPS_EXPORT_PDF";
export const REQUEST_TRIPS_EXPORT_XLSX = "REQUEST_TRIPS_EXPORT_XLSX";

export const REQUEST_TIMELOG_EXPORT_PDF = "REQUEST_TIMELOG_EXPORT_PDF";

export const REQUEST_DAY_VIEW_EXPORT_PDF = "REQUEST_DAY_VIEW_EXPORT_PDF";
export const REQUEST_DAY_VIEW_GRAPHIC_EXPORT_PDF =
  "REQUEST_DAY_VIEW_GRAPHIC_EXPORT_PDF";

export const REQUEST_USERS_EXPORT_XLSX = "REQUEST_USERS_EXPORT_XLSX";
export const REQUEST_VEHICLES_EXPORT_XLSX = "REQUEST_VEHICLES_EXPORT_XLSX";
export const REQUEST_DRIVEBOXES_EXPORT_XLSX = "REQUEST_DRIVEBOXES_EXPORT_XLSX";

export const REQUEST_VEHICE_MILEAGE_EXPORT_PDF =
  "REQUEST_VEHICE_MILEAGE_EXPORT_PDF";

export const REQUEST_TRIP_GEOFENCE_INTERSECTIONS_EXPORT_PDF =
  "REQUEST_TRIP_GEOFENCE_INTERSECTIONS_EXPORT_PDF";

export const REQUEST_MONTHLY_TRIP_REPORT = "REQUEST_TRIP_RECORDS"

export const REQUEST_RESERVATION_REPORT = "REQUEST_RESERVATION_REPORT"

export const REQUEST_HWNOE_VEHICLE_DATA_REPORT = "REQUEST_HWNOE_VEHICLE_DATA_REPORT";

export const REQUEST_COSTCENTER_REPORT = "REQUEST_COSTCENTER_REPORT";

export const REQUEST_FUELMONITOR_REPORT = "REQUEST_FUELMONITOR_REPORT"

export const requestMandatorExportXlsx = (data) => ({
  type: REQUEST_MANDATOR_EXPORT_XLSX,
  data,
});

export const requestMandatorUserExportXlsx = (data) => ({
  type: REQUEST_MANDATOR_USER_EXPORT_XLSX,
  data,
});

export const requestMandatorDriveboxExportXlsx = (data) => ({
  type: REQUEST_MANDATOR_DRIVEBOX_EXPORT_XLSX,
  data,
});

export const requestTripExportPdf = (data) => ({
  type: REQUEST_TRIPS_EXPORT_PDF,
  data,
});

export const requestTripExportXlsx = (data) => ({
  type: REQUEST_TRIPS_EXPORT_XLSX,
  data,
});

export const requestTimeLogExportPdf = (data) => ({
  type: REQUEST_TIMELOG_EXPORT_PDF,
  data,
});

export const requestDayViewExportPdf = (data) => ({
  type: REQUEST_DAY_VIEW_EXPORT_PDF,
  data,
});

export const requestDayViewGraphicExportPDF = (data) => ({
  type: REQUEST_DAY_VIEW_GRAPHIC_EXPORT_PDF,
  data,
});

export const requestUserExportXlsx = (data) => ({
  type: REQUEST_USERS_EXPORT_XLSX,
  data,
});

export const requestVehiclesExportXlsx = (data) => ({
  type: REQUEST_VEHICLES_EXPORT_XLSX,
  data,
});

export const requestDriveboxesExportXlsx = (data) => ({
  type: REQUEST_DRIVEBOXES_EXPORT_XLSX,
  data,
});

export const requestVehicleMileageExportPDF = (data) => ({
  type: REQUEST_VEHICE_MILEAGE_EXPORT_PDF,
  data,
});

export const requestTripGeofenceIntersectionsExportPdf = (data) => ({
  type: REQUEST_TRIP_GEOFENCE_INTERSECTIONS_EXPORT_PDF,
  data,
});

export const requestTripMonthlyPreport = (data) => ({
  type: REQUEST_MONTHLY_TRIP_REPORT,
  data
})

export const requestReservationReport = (data) => ({
  type: REQUEST_RESERVATION_REPORT,
  data
})

export const requestHwnoeVehicleDataReport = (data) => ({
  type: REQUEST_HWNOE_VEHICLE_DATA_REPORT,
  data
})

export const requestCostcenterReport = (data) => ({
  type: REQUEST_COSTCENTER_REPORT,
  data
});

export const requestFuelMonitorReport = (data) => ({
  type: REQUEST_FUELMONITOR_REPORT,
  data
})
