import React, {useEffect, useState} from "react";

import {bindActionCreators} from "redux";
import {connect, useSelector} from "react-redux";
import EditModal from "../../common/modals/CustomModal";
import Map from "../../common/map/Map";
import Slider from "../../common/inputs/Slider";
import {
    requestSplitTrip,
    requestTrackOfTrip,
    requestTripById,
    requestMileageOfTrip,
    loadTrip
} from "../../../actions/actionTrips";
import {Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SplitIcon from "@material-ui/icons/CallSplit";
import CachedIcon from "@material-ui/icons/Cached"

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import OriginDestinationTimeline from "../OriginDestinationTimeline";
import {clearError} from "../../../actions/actionCommon";
import {allowedRoles, isAuthorized} from "../../../managers/authManager";
import {roles} from "../../../models/Role";
import {clearGeoCode, requestReverseGeoCode} from "../../../actions/actionGeoLocation";
import useTrackModalStyle from "./useTrackModalStyle";
import { useTranslation } from "react-i18next";


const TrackModal = props => {
    const classes = useTrackModalStyle();

    const me = useSelector(state => state.users.me);
    const { current: trip, loading } = useSelector((state) => state.trips)
    const error = useSelector(state => state.error);
    const geoLocationAddress = useSelector(state => state.address.current);
    const filter = useSelector(state => state.filter);
    const { t } = useTranslation();

    const [showTrack, setShowTrack] = useState(me.canShowTracks);

    const [splitAfter, setSplitPosition] = useState(0);
    const [trackToSplit, setTrackToSplit] = useState({
        color: "#d92133",
        showMarker: false,
        showStartPoint: false,
        showEndPoint: false,
        positions: [],
    });

    const {id, onClose, onEdit, onSplit, requestReverseGeoCode, requestSplitTrip,costcenterId} = props;

    const [showSplitContent, setShowSplitContent] = useState(false);
    const track = trip.positions || [];
    const sumDistance = track.reduce((accum, t) => accum + t.distanceToPreviousInMeters, 0);
    const hasDistanceToPreviouseInMeters = sumDistance !== 0;

    const mileage = trip.mileageInMeters;
    const firstPos = getFirstSplitPosition();
    const lastPos = getLastSplitPosition();
    const prevPosition = findPositionByMeter(splitAfter);
    const hasBeenCalibrated = Boolean(trip.calibratedAt);
    const hasBeedEdited = Boolean(trip.hasBeenChanged);

    // View Content stuff
    const startEndTrack = [
        {latitude: trip.startLatitude, longitude: trip.startLongitude},
        {latitude: trip.destinationLatitude, longitude: trip.destinationLongitude},
    ];

    const mappedTrip = trip;
    mappedTrip.positions = showTrack ? track : startEndTrack;

    useEffect(() => {
        if (error.status === 404) {
            setShowTrack(false);
            props.clearError();
        }
    }, [error]);

    useEffect(() => {
        props.loadTrip();
        props.requestTripById(id);
        props.requestMileageOfTrip(id);
        if (me.canShowTracks) props.requestTrackOfTrip(id);
        return () => props.clearGeoCode;
    }, []);

    useEffect(() => {
        if (hasDistanceToPreviouseInMeters && canSplit())
            handleSliderChange(sumDistance / 2)
    }, [sumDistance]);


    function hasTrack() {
        if (showTrack) return true;
        return (startEndTrack &&
            startEndTrack.length > 0 &&
            startEndTrack[0].latitude &&
            startEndTrack[0].longitude);
    }

    const viewContent = () => (
        <Grid container className={classes.innerContainer}>
            <Grid item xs={12}>
                <OriginDestinationTimeline trip={trip} showDetailInfo/>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                <List component="nav" className={classes.bottomNav}>
                    {hasBeedEdited &&
                    <ListItem dense onClick={onEdit}>
                        <ListItemIcon>
                            <EditIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t("trip.trackmodal.edited")}/>
                    </ListItem>
                    }
                    {hasBeenCalibrated && <ListItem dense onClick={onEdit}>
                        <ListItemIcon>
                            <CachedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t("trip.trackmodal.calibrated")}/>
                    </ListItem>
                    }
                    {onEdit && !trip.lockedAt && isAuthorized(me, allowedRoles(roles.Driver)) && (
                        <ListItem button dense onClick={onEdit}>
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t("trip.trackmodal.edit")}/>
                        </ListItem>
                    )}
                    {!trip.lockedAt && isAuthorized(me, allowedRoles(roles.Driver)) && trip.agent !== 1 && me.canMergeSplitTrips && hasDistanceToPreviouseInMeters && (
                        <ListItem button dense onClick={() => {
                            setShowSplitContent(true);
                        }}>
                            <ListItemIcon>
                                <SplitIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t("trip.trackmodal.split")}/>
                        </ListItem>
                    )}
                </List>
            </Grid>
        </Grid>
    );


    function handleSliderChange(value) {
        if (mileage - 10 < value || !prevPosition) return;
        requestReverseGeoCode({latitude: prevPosition.latitude, longitude: prevPosition.longitude});
        setSplitPosition(value);
        setTrackToSplit({
            ...trackToSplit,
            ...trip, positions: track.filter(position => position.distanceFromBeginning < value)
        });
    }

    function meterToKm(meter) {
        if (!meter) return "";
        if (meter >= 1000) {
            const km = meter / 1000;
            return km.toFixed(1) + " km";
        }
        return meter + " m";
    }

    function handleSplitTrip() {
        loadTrip();
        requestSplitTrip({byMileageInMeters: splitAfter, tripId: trip.id}, filter.api);
        onClose();
    }

    function getFirstSplitPosition() {
        let splitPosition = 0;
        for (let pos of track) {
            let distanceFromBeginning = pos.distanceFromBeginning;
            if (splitPosition === 0 && distanceFromBeginning > 0) {
                splitPosition = distanceFromBeginning;
                break;
            }
        }
        return splitPosition;
    }

    function getLastSplitPosition() {
        let splitPosition = 0;
        for (let i = track.length - 1; i > 0; i--) {
            let pos = track[i];
            let distanceFromBeginning = pos.distanceFromBeginning;
            if (splitPosition === 0 && distanceFromBeginning < track[track.length - 1].distanceFromBeginning) {
                splitPosition = distanceFromBeginning;
                break;
            }
        }
        return splitPosition;
    }

    function canSplit() {
        const firstPos = getFirstSplitPosition();
        const lastPos = getLastSplitPosition();
        return firstPos < lastPos;
    }

    function findPositionByMeter(meter) {
        for (let i = 1; i < track.length; i++) {
            let distanceFromBeginning = track[i].distanceFromBeginning;
            if (distanceFromBeginning > meter) {
                return track[i];
            }
        }
        return null;
    }

    const tripInformation = (
        <div>
            <h3 style={{marginTop: "20px", marginLeft: "2px"}}>Aktuelle Fahrt:</h3>
            <OriginDestinationTimeline trip={trip}/>
        </div>
    );

    const newTrip1 = (
        <div style={{marginTop: "30px"}}>
            <h3>Neue Fahrt 1:</h3>
            {prevPosition && (
                <OriginDestinationTimeline
                    trip={{...trip, destinationAddress: geoLocationAddress, finishedAt: prevPosition.createdAt}}/>
            )}
        </div>
    );

    const newTrip2 = (
        <div>
            <h3>Neue Fahrt 2:</h3>
            {prevPosition && <OriginDestinationTimeline
                trip={{...trip, startAddress: geoLocationAddress, startedAt: prevPosition.createdAt}}/>}
        </div>
    );

    const splitContent = () => (
        <Grid container className={classes.innerContainer}>
            <Grid item xs={12}>
                <Typography className={classes.splitText} variant="h6">Teilen nach {meterToKm(splitAfter)} </Typography>
                <Slider
                    min={firstPos}
                    max={lastPos}
                    step={Math.ceil(mileage / track.length / 10) * 10}
                    handleChange={handleSliderChange}
                    value={splitAfter}
                />
                {geoLocationAddress.displayName && canSplit() && newTrip1}
                {geoLocationAddress.displayName && canSplit() && newTrip2}
            </Grid>

            <Grid item xs={12} className={classes.bottomContainer}>
                <List component="nav" className={classes.bottomNav}>
                    {!trip.locked && isAuthorized(me, allowedRoles(roles.Driver)) && trip.agent !== 1 && me.canMergeSplitTrips && hasDistanceToPreviouseInMeters && (
                        <ListItem button dense onClick={handleSplitTrip} disabled={loading}>
                            <ListItemIcon><SplitIcon/></ListItemIcon>
                            <ListItemText primary="Jetzt teilen"/>
                        </ListItem>
                    )}

                    <ListItem button dense onClick={() => {
                        setShowSplitContent(false);
                    }}>
                        <ListItemIcon><ArrowBackIcon/></ListItemIcon>
                        <ListItemText primary="Zurück"/>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );


    const leftColumn = showSplitContent ? splitContent(t) : viewContent(t);
    const map = showSplitContent ? (
        <Map automaticHeight={false} trips={[mappedTrip, trackToSplit]} />
    ) : (
        <Map trips={[mappedTrip]} loading={loading} automaticHeight={false} showTrackStartStopMarker />
    );
    
    const contents = [
        <Grid container>
            <Grid item xs={3} className={classes.leftColumn}>
                {leftColumn}
            </Grid>
            <Grid item xs={9} style={{ height: "100%" }}>
                {map}
            </Grid>
        </Grid>,
    ];


    return <EditModal largeModal
                      onClose={onClose}
                      title={t("trip.trackmodal.title")}
                      contents={contents}
                      className={classes.modal}/>;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestTrackOfTrip,
            requestTripById,
            clearError,
            requestSplitTrip,
            requestReverseGeoCode,
            clearGeoCode,
            requestMileageOfTrip,
            loadTrip
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {};
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(TrackModal);
