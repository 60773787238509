import {
  getPagedEntities,
  addEntity,
  updateEntity,
  deleteEntity,
  loadEntities,
} from "../../managers/reducerManager";

import { ReservationResponse, ReservationReportResponse, ReservationResponseInfo } from "../../types/reservation/reservationResponse.types";

import {
  receiveReservationReport,
  receiveAddReservation,
  receiveDeleteReservation,
  receiveFilterReservations,
  receiveReservations,
  receiveUpdateReservations,
  loadReservations,
  setIsFilterActive,
  toggleReservationSuccessMsgVisibility, 
} from "../../actions/actionReservation";
import { AnyAction } from "redux";

export type ReservationState = {
    readonly current: ReservationResponse ;
    readonly entities: ReservationResponseInfo[];
    readonly allEntities: ReservationResponse[];
    readonly isSuccessMsgVisible: boolean,
    readonly activeFilter: boolean,
    readonly loading: boolean,
    readonly total: number,
    readonly pageLoading: boolean,
    readonly reservationReport: ReservationReportResponse,
  };

const RESERVATION_INITIAL_STATE: ReservationState = {
  current: {} as ReservationResponse,
  entities: [],
  allEntities: [],
  isSuccessMsgVisible: false,
  activeFilter: false,
  loading: false,
  total: 0, 
  pageLoading: false,
  reservationReport: {} as ReservationReportResponse,
};

export const reservationDetail = (state = RESERVATION_INITIAL_STATE, action: AnyAction): ReservationState => {

    if(loadReservations.match(action)) {
        return loadEntities(state);
    }

    if(receiveReservations.match(action)) {
        return getPagedEntities(state, action.data);
    }

    if(receiveAddReservation.match(action)) {
        return addEntity(state, action.data);
    }

    if(receiveFilterReservations.match(action)) {
        return {
            ...getPagedEntities(state, action.data),
            activeFilter: action.data.isFilterActive,
          };
    }

    if(setIsFilterActive.match(action)) {
        return {
            ...state,
            activeFilter: action.data,
          };
    }

    if(receiveReservationReport.match(action)) {
        return {
            ...state,
            reservationReport: action.data,
          };
    }

    if(toggleReservationSuccessMsgVisibility.match(action)) {
        return {
            ...state,
            isSuccessMsgVisible: action.data,
          };
    }

    if(receiveUpdateReservations.match(action)) {
        return updateEntity(state, action.data);
    }

    if(receiveDeleteReservation.match(action)) {
        return deleteEntity(state, action.data);
    }

    return state;
};
