import { useState, useEffect } from "react";
import {Menu, MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";

const AddContextMenu = props => {
    const [shouldMenuItemDisabled, setShouldMenuItemDisabled] = useState(true);
    const {anchorEl, onClose, onAddGroup, onAddCar, onAddUser, group, isInMainNode} = props;
    const { t } = useTranslation();

    useEffect(() => {
        if(isInMainNode) {
            setShouldMenuItemDisabled(true);
        } else {
            setShouldMenuItemDisabled(!Boolean(group.id));
        }
    }, [isInMainNode]);

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "left"}}
            transformOrigin={{vertical: "top", horizontal: "left"}}
            open={Boolean(anchorEl)}
            onClose={onClose}>
            <MenuItem onClick={onAddGroup}>{t("group.contextmenu.addGroup")}</MenuItem>
            <MenuItem onClick={onAddUser} disabled={shouldMenuItemDisabled}>{t("group.contextmenu.addUser")}</MenuItem>
            <MenuItem onClick={onAddCar} disabled={shouldMenuItemDisabled}>{t("group.contextmenu.addVehicle")}</MenuItem>
        </Menu>
    );
};


function mapStateToProp(state) {
    return {
        group: state.group,
    };
}

export default connect(
    mapStateToProp,
)(AddContextMenu);
