function objAreEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function compareByReference(prevObj, nextObj) {
    return prevObj === nextObj;
}

export {
    objAreEqual,
    compareByReference
}