import { AnyAction } from "redux";

import {
  receiveMandatorDriveboxPositions,
  receiveMandatorDriveboxEvents,
  receiveMandatorTrips,
} from "../actions/actionMandators";

import { getPagedEntities } from "../managers/reducerManager";

import { DriveBoxPositionInfo } from "../types/vehicle/vehicleResponse.types";

import { DriveboxEventsInfo } from "../types/mandator/mandatorResponse.types";

import { TripResponseInfo } from "../types/trip/tripResponse.types";

export type RawDataState = {
  readonly current: DriveBoxPositionInfo;
  readonly entities: DriveBoxPositionInfo[];
};

export type EventState = {
  readonly current: DriveboxEventsInfo;
  readonly entities: DriveboxEventsInfo[];
};

export type MandatorTripState = {
  readonly current: TripResponseInfo;
  readonly entities: TripResponseInfo[];
};

const POSITION_INITIAL_STATE: RawDataState = {
  current: {} as DriveBoxPositionInfo,
  entities: [],
};

const EVENTS_INITIAL_STATE: EventState = {
  current: {} as DriveboxEventsInfo,
  entities: [],
};

const MANDATOR_TRIP_STATE: MandatorTripState = {
  current: {} as TripResponseInfo,
  entities: [],
};

export const positionsDetail = (
  state = POSITION_INITIAL_STATE,
  action: AnyAction
) => {
  if (receiveMandatorDriveboxPositions.match(action)) {
    return getPagedEntities(state, action.data);
  }

  return state;
};

export const eventsDetail = (
  state = EVENTS_INITIAL_STATE,
  action: AnyAction
) => {
  if (receiveMandatorDriveboxEvents.match(action)) {
    return getPagedEntities(state, action.data);
  }

  return state;
};

export const tripsDetail = (state = MANDATOR_TRIP_STATE, action: AnyAction) => {
  if (receiveMandatorTrips.match(action)) {
    return getPagedEntities(state, action.data);
  }

  return state;
};
