import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { usePagination } from "../../../hooks/usePagination";
import { setFilter } from "../../../actions/actionFilter";
import { Typography } from "antd";
import { requestTrips } from "../../../actions/actionTrips";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import { useModalStatus } from "../../../hooks/useModalStatus";
import DashboardTripsWithoutDriverTable from "./DashboardTripsWithoutDriverTable";
import TripEditModal from "../../trips/tripsModals/TripEditModal";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    margin: "10px",
  },
}));

const DashboardTripsWithoutDriver = (props) => {
  const classes = useStyles();
  const { filter, date, total } = props;
  const { updatedAt } = filter;
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const { t } = useTranslation();

  const {
    modal: editModal,
    open: openEditModal,
    close: closeEditModal,
  } = useModalStatus();
  const [trip, setTrip] = useState("");

  useEffect(() => {
    props.requestTrips({
      ...filter.api,
      from: date.from,
      to: date.to,
      page: page + 1,
      perPage: rowsPerPage,
      isArchived: false,
      isLocked: false,
      withoutDriver: true,
    });
  }, [page, rowsPerPage, date]);

  const tripsCountText =
    total === 0 ? (
      t("dashboard.tripsWithoutDriver.titleNone")
    ) : (
        t("dashboard.tripsWithoutDriver.title",
          { total: total }
        )
    );
  return (
    <>
      <Typography className={classes.title}>{tripsCountText}</Typography>
      <DashboardTripsWithoutDriverTable
        page={page}
        rowsPerPage={rowsPerPage}
        archive={false}
        handleChangePage={changePage}
        handleChangeRowsPerPage={changeRowPerPage}
        onEdit={(trip) => {
          setTrip(trip);
          openEditModal();
        }}
      />
      {editModal && <TripEditModal tripId={trip.id} onClose={closeEditModal} />}
    </>
  );
};

function mapStateToProp(state) {
  return {
    filter: state.filter,
    total: state.trips.total,
    trips: state.trips,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setFilter,
      requestTrips,
      updateFilterAPICall,
    },
    dispatch
  );
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(DashboardTripsWithoutDriver);
