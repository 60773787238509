const timeRepetition = {
    day: 1,
    week: 2,
    month: 3,
    year: 4,
};

const timeRepetitionArray = [
    {id: timeRepetition.day, name: "Jeden Tag"},
    {id: timeRepetition.week, name: "Jede Woche"},
    {id: timeRepetition.month, name: "Jeden Monat"},
];

const mapTimeRepetitionToIntervalServerEnum = repetition => {
    switch (repetition) {
        case timeRepetition.day:
            return "Daily";
        case timeRepetition.week:
            return "Weakly";
        case timeRepetition.month:
            return "Monthly";
        case timeRepetition.year:
            return "Yearly";
        default:
            return undefined;
    }
}

const mapIntervalServerEnumToTimeRepetition = serverValue => {
    switch (serverValue) {
        case 0:
            return timeRepetition.year;
        case 1:
            return timeRepetition.month;
        case 2:
            return timeRepetition.week;
        case 3:
            return timeRepetition.day;
        default:
            return undefined;
    }
}

export {timeRepetition, timeRepetitionArray, mapTimeRepetitionToIntervalServerEnum,mapIntervalServerEnumToTimeRepetition};
