import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import axios from "axios";
import CarChip from "./CarChip";
import DistributorThroughputChunks from "./distributorChunks/DistributorThroughputChunks";

function ChargingPage() {
  const [internalId, setInternalId] = useState(0);

  const [data, setData] = useState({
    numberOfCars: 10,
    percentageNormal: 10,
    percentageMedium: 40,
    percentageStrong: 50,
    distributorMaxThroughput: 10,
    stationMaxThroughput: 10,
    nightShiftEnd: "5:00",
    startOfArrivalTime: "20:00",
    endOfArrivalTime: "22:00",
    numberOfKWPer100Km: 10,
    //{time:, throughput:}
    distributorThroughputChunks: [
      {
        id: -1,
      },
    ],
  });

  const [result, setResult] = useState();

  const handleNumberChange = (input) => {
    let old = { ...data };
    old[input.target.id] = input.target.value;

    setData(old);
  };

  const handleClick = () => {
    const baseUrl = window.location.origin + "/api/";

    axios
      .post(
        baseUrl +
          `ChargingSimulator?NumberOfCars=` +
          data.numberOfCars +
          `&PercentageNormal=` +
          data.percentageNormal +
          `&PercentageMedium=` +
          data.percentageMedium +
          `&PercentageStrong=` +
          data.percentageStrong +
          `&DistributorMaxThroughput=` +
          data.distributorMaxThroughput +
          `&StationMaxThroughput=` +
          data.stationMaxThroughput +
          `&NightShiftEnd=` +
          data.nightShiftEnd +
          `&StartOfArrivalTime=` +
          data.startOfArrivalTime +
          `&EndOfArrivalTime=` +
          data.endOfArrivalTime +
          `&NumberOfKWPer100Km=` +
          data.numberOfKWPer100Km,
        data.distributorThroughputChunks
      )
      .then((res) => {
        const data = res.data;
        setResult(data);
        //this.setState({ persons });
      });
  };

  var timePoints = new Map();
  var carNames = new Set();
  let lastChunkEndTime = null;
  var cc = result?.cars?.forEach((el) => {
    carNames.add({
      name: el.name,
      needed: el.neededChargeInKM,
      actual: el.energyDistribution.currentTotalKMCharged,
    });

    var dd = el?.energyDistribution?.energyChunks?.forEach((chunk) => {
      let timepointValue = timePoints.get(chunk.chunkStartTime);

      if (timepointValue == undefined) {
        timePoints.set(chunk.chunkStartTime, [{ name: el.name, chunk: chunk }]);
      } else {
        timepointValue.push({ name: el.name, chunk: chunk });
      }
      lastChunkEndTime = chunk.chunkEndTime;
    });
  });

  if (!timePoints.get(lastChunkEndTime)) {
    timePoints.set(lastChunkEndTime, []);
  }

  // if(result && !timePoints.get(result?.nightShiftEnd.hours + ":" +result?.nightShiftEnd.minutes )) {
  //   timePoints.set(result?.nightShiftEnd.hours + ":" +result?.nightShiftEnd.minutes, [])
  // }

  const getCarsChargingStatus = (carChunks) => {
    let result = [];
    carNames.forEach((carName) => {
      let carChunk = undefined;
      if (
        carChunks.some((e) => {
          if (e.name === carName.name) {
            carChunk = e;
          }
          return e.name === carName.name;
        })
      ) {
        /* vendors contains the element we're looking for */
        result.push(<CarChip chunk={carChunk.chunk} color={"green"} />);
      } else {
        result.push(<CarChip color={"darkred"} />);
      }
    });

    return result;
  };

  var finalResult = [];
  timePoints.forEach((value, timepointKey) => {
    finalResult.push(
      <Grid key={timepointKey} item style={{ marginRight: "2px" }}>
        <Grid container direction={"column"}>
          <Grid item style={{ marginBottom: "10px", textAlign: "center" }}>
            {timepointKey}
            <br />
            {value[0]?.chunk.throughput ?? "-"}
          </Grid>
          {getCarsChargingStatus(value)}
        </Grid>
      </Grid>
    );
  });

  const addStaticallyCars = () => {
    let result = [
      <Grid style={{ marginBottom: "10px", textAlign: "right" }} item>
        Time
        <br />
        Throughput KW/h
      </Grid>,
    ];

    carNames.forEach((carName) => {
      result.push(
        <Grid item key={carName.name} style={{ marginBottom: "10px" }}>
          {" "}
          {carName.name},<br /> {carName.actual.toFixed(2)} /{" "}
          {carName.needed.toFixed(2)} KM
        </Grid>
      );
    });

    return result;
  };

  const handleNewChunk = () => {
    let old = { ...data };
    let distributorChunks = old.distributorThroughputChunks.slice();

    distributorChunks.push({
      id: internalId,
    });

    old.distributorThroughputChunks = distributorChunks;

    setData(old);
    let newInternalId = internalId + 1;

    setInternalId(newInternalId);
  };

  const handleDistributorChange = (input, id) => {
    let old = { ...data };

    let distributorChunks = old.distributorThroughputChunks.slice();
    let editedChunk = distributorChunks.filter((el) => el.id == id)[0];

    editedChunk[input.target.id] = input.target.value;

    old.distributorThroughputChunks = distributorChunks;

    setData(old);
  };

  const handleDistributorChunkDelete = (id) => {
    let old = { ...data };

    let distributorChunks = old.distributorThroughputChunks
      .slice()
      .filter((el) => el.id != id);
    old.distributorThroughputChunks = distributorChunks;

    setData(old);
  };

  return (
    <Grid container spacing={2} style={{ padding: "8px", width: "100%" }}>
      <Grid item xs={12}>
        <Grid container justify="left">
          <form noValidate autoComplete="off">
            <TextField
              id="numberOfCars"
              label="Number of cars"
              type={"number"}
              onChange={handleNumberChange}
              value={data.numberOfCars}
              style={{ margin: "3px" }}
            />
            <TextField
              id="percentageNormal"
              label="Percentage normal"
              type="number"
              onChange={handleNumberChange}
              value={data.percentageNormal}
              style={{ margin: "3px" }}
            />
            <TextField
              id="percentageMedium"
              label="Percentage Medium"
              type="number"
              onChange={handleNumberChange}
              value={data.percentageMedium}
              style={{ margin: "3px" }}
            />
            <TextField
              id="percentageStrong"
              label="Percentage Strong"
              type="number"
              onChange={handleNumberChange}
              value={data.percentageStrong}
              style={{ margin: "3px" }}
            />
            <TextField
              id="distributorMaxThroughput"
              label="Distributor Max Throughput"
              type="number"
              onChange={handleNumberChange}
              value={data.distributorMaxThroughput}
              style={{ margin: "3px" }}
            />
            <TextField
              id="stationMaxThroughput"
              label="Station Max Throughput"
              type="number"
              onChange={handleNumberChange}
              value={data.stationMaxThroughput}
              style={{ margin: "3px" }}
            />
            <TextField
              id="numberOfKWPer100Km"
              label="KW/h per 100 km"
              type="number"
              onChange={handleNumberChange}
              value={data.numberOfKWPer100Km}
              style={{ margin: "3px" }}
            />
            <br />
            <br />
            <TextField
              id="startOfArrivalTime"
              label="Start of arrival time"
              type="text"
              onChange={handleNumberChange}
              value={data.startOfArrivalTime}
              style={{ margin: "3px" }}
            />
            <TextField
              id="endOfArrivalTime"
              label="End of arrival time"
              type="text"
              onChange={handleNumberChange}
              value={data.endOfArrivalTime}
              style={{ margin: "3px" }}
            />
            <TextField
              id="nightShiftEnd"
              label="Night shift end"
              type="text"
              onChange={handleNumberChange}
              value={data.nightShiftEnd}
              style={{ margin: "3px" }}
            />

            <DistributorThroughputChunks
              onChange={handleDistributorChange}
              onNewChunk={handleNewChunk}
              onDelete={handleDistributorChunkDelete}
              chunks={data.distributorThroughputChunks}
            />

            <Button
              onClick={handleClick}
              style={{ display: "block" }}
              variant="contained"
              color="primary"
            >
              Calculate
            </Button>
          </form>
        </Grid>

        <Grid container direction="row" xs={12} style={{ marginTop: "20px" }}>
          <Grid
            item
            style={{
              marginRight: "20px",
              width: "150px",
              position: "absolute",
            }}
          >
            {addStaticallyCars()}
          </Grid>
          <Grid
            item
            style={{
              marginRight: "20px",
              overflow: "auto",
              marginLeft: "160px",
            }}
          >
            <Grid
              container
              item
              direction={"row"}
              style={{
                marginRight: "20px",
                minWidth: "max-content",
                overflow: "auto",
              }}
            >
              {finalResult}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChargingPage;
