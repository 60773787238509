import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//Common
import FormModal from "../../common/modals/FormModal";
import {
  requestUpdateVehicles,
  selectAllVehicles,
} from "../../../actions/actionVehicle";

import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import { getIdsFromGroupValues } from "../../../managers/groupManager";
import FormBuilder from "../../../managers/formBuilder";
import { Input } from "antd";
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { MODAL_TYPES } from "../../common/modals/modal.utils";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { useTranslation } from "react-i18next";

const DEFAULT_INPUT_VALUES = {
  groupValue: undefined,
  description: "",
  costcenterId: "",
};

const VehicleBulkEditModal = (props) => {
  const { onClose, requestUpdateVehicles, vehicles, selected, tree, me } = props;
  const { fields, handleInputChange } = useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { groupValue, description, costcenterId } = fields;
  const { t } = useTranslation();

  const handleOnSave = () => {
    let objToUpdate = {
      vehicleIds: vehicles.filter((v) => v.isSelected).map((v) => v.id),
    };

    groupValue &&
      (objToUpdate.groupIds = getIdsFromGroupValues(tree, groupValue));

    description && (objToUpdate.description = description);

    costcenterId && (objToUpdate.costcenterId = costcenterId);

    requestUpdateVehicles(objToUpdate);
    onClose();
  };

  const inputs = [
    new FormBuilder.FormInput(
      "Groups",
      (
        <TreeSelect
          value={groupValue}
          onChange={(value) => handleInputChange("groupValue", value)}
        />
      )
    )
      .withKey("groupValue")
      .build(),
    new FormBuilder.FormInput(
      "Description",
      (
        <Input.TextArea
          value={description}
          onChange={(e) => handleInputChange("description", e.target.value)}
          autosize={{ minRows: 3, maxRows: 5 }}
        />
      )
    )
      .withKey("description")
      .build(),
  ];

  if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter])) {
    inputs.push(
      new FormBuilder.FormInput(
        t("trip.bulkeditModal.costCenter") ,
        (
          <CostCenterSelect
            value={costcenterId}
            onChange={(value) => handleInputChange("costcenterId", value)}
            allowClear={false}
          />
        )
      )
        .withKey("costcenterId")
        .build()
    );
  }
  return (
    <FormModal
      onSave={handleOnSave}
      onClose={onClose}
      formType={MODAL_TYPES.withoutValidation}
      title={"Update " + selected + " vehicles"}
      inputs={inputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { requestUpdateVehicles, selectAllVehicles },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    vehicles: state.vehicles.entities,
    selected: state.vehicles.selected,
    tree: state.tree,
    me: state.users.me,
  };
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(VehicleBulkEditModal);
