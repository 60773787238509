import React from "react";
import PropTypes from "prop-types";
import GenericTable from "../common/table/GenericTable";
import { useSelector } from "react-redux";
import TableTypes from "../../models/TableTypes";
import { roles } from "../../models/Role";
import { getTranslation } from "../../translations/translations";
import { packages } from "../../models/Package";

import { getTranslationForStatus } from "./Reservation.utils";

const MyReservationTable = ({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onEdit,
  reservations,
  totalReservations,
  onDelete,
  pageName,
}) => {

  // this should  move to selectors later 
  const reservationsWithFullName = reservations.map((reservation) => { 
    const { driver } = reservation;

    const reservationState = getTranslationForStatus(reservation);
  
    if (driver && driver.firstName && driver.lastName) {
      reservation.fullName = `${driver.firstName} ${driver.lastName}`;
    }

   reservationState && (reservation.state = reservationState);

    return reservation;
  });

  const me = useSelector((state) => state.users.me);

  const driverColumn = {
    title: getTranslation("reservation.tableItems.driver"),
    key: "fullName"
  }

  const costCenter =  {
    title: getTranslation("reservation.tableItems.costCenter"),
    key: "costcenter.foreignId"
  }

  const columnInformations = [
    {
      title: getTranslation("reservation.tableItems.vehicle"),
      key: "vehicle.name",
    },
    {
      title: getTranslation("reservation.tableItems.pickupDate"),
      key: "pickupDate",
      type: TableTypes.dateTime,
    },
    {
      title: getTranslation("reservation.tableItems.returnDate"),
      key: "returnDate",
      type: TableTypes.dateTime,
    },
    {
      title: getTranslation("reservation.tableItems.purpose"),
      key: "purpose",
    },
    {
      title: getTranslation("reservation.tableItems.comment"),
      key: "comment",
    },
    {
      title: getTranslation("reservation.tableItems.status"),
      key: "state",
    },
  ];

  if(me.packages.includes(packages.CostCenter)){
    columnInformations.splice(4, 0, costCenter)
  }

  const actions = [
    {
      title: getTranslation("reservation.edit"),
      event: onEdit,
    },
    {
      title: getTranslation("reservation.cancel"),
      event: onDelete,
    },
  ];

  if(me.role.name !== roles.Driver){
    columnInformations.unshift(driverColumn)
  }

  return (
    <GenericTable
      data={reservationsWithFullName}
      columnInformations={columnInformations}
      actions={actions}
      rowCount={totalReservations}
      pageNavigation
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      pageName={pageName}
      showActions
    />
  );
};
MyReservationTable.defaultProps = {
  page: 0,
};

// MyReservationTable.propTypes = {
//   page: PropTypes.number,
//   rowsPerPage: PropTypes.number.isRequired,
//   handleChangePage: PropTypes.func.isRequired,
//   handleChangeRowsPerPage: PropTypes.func.isRequired,
//   onEdit: PropTypes.func.isRequired,
//   reservations: PropTypes.array,
//   totalReservations: PropTypes.number,
//   onDelete: PropTypes.func.isRequired
// };

export default MyReservationTable;
