import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
    requestAssignedGeofenceVehicles,
    requestUnAssignedGeofenceVehicles,
} from '../../../../actions/actionGeofences';
import { geofenceVehicles } from '../../../../models/GroupingGeofenceVehicles';
import VehiclesItem from './GeofenceVehiclesItems/VehiclesItem';
import ExchangeButtons from './GeofenceVehiclesItems/ExchangeButtons';


const GeofenceVehicles = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let geofenceId = props.geofenceId == true ? '' : props.geofenceId;
        dispatch(requestUnAssignedGeofenceVehicles({ geofenceId }))
        dispatch(requestAssignedGeofenceVehicles({ geofenceId }))
    }, []);

    return (
        <Grid container spacing={2} style={{ paddingTop: "10px" }} justify="space-evenly" alignItems="center">
            {/* module is the name of the property of state object in reducer */}
            <VehiclesItem translationId="geofenceVehicles.unAssignedVehicles" module={geofenceVehicles.unAssignedGeofenceVehicles}/>
            <ExchangeButtons />
            <VehiclesItem translationId="geofenceVehicles.assignedVehicles" module={geofenceVehicles.assignedGeofenceVehicles}/>
        </Grid>
    );
}

export default GeofenceVehicles;