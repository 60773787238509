import i18n from "../../../../i18n";

export const DATE_PICKER_TYPE = {
    rawDataFilterDatePicker: "rawDataFilterDatePicker",
}

export const CustomDatePickerProps = {
    minDateMessage: i18n.t("common.datePicker.minDateMessage"),
    maxDateMessage: i18n.t("common.datePicker.maxDateMessage"),
    invalidDateMessage: i18n.t("common.datePicker.invalidDateMessage"),
}

export function getTodayDateRange() {
    const currentDate = new Date();
    const from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0).toISOString();
    const to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59).toISOString();
    
    return { from, to };
}