import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const toolbarStyle = {color: "#bfbfbf", marginTop: "4px"};
const defaultStyle = {marginTop: "10px", marginLeft: "4px"};

const CustomSwitch = ({label, handleChange, value, name, toolbar}) => {
    return (
        <FormControlLabel
            style={toolbar ? toolbarStyle : defaultStyle}
            control={<Switch size="small" onChange={handleChange} checked={value} name={name} color="primary"/>}
            label={label}
        />
    );
};

// CustomSwitch.propTypes = {
//     label: PropTypes.string.isRequired,
//     handleChange: PropTypes.func.isRequired,
//     value: PropTypes.bool,
//     name: PropTypes.string,
//     toolbar: PropTypes.bool,
// };

export default CustomSwitch;
