import { AnyAction } from "redux";

import {
  receiveGeoCode,
  receiveReverseGeoCode,
  receiveLocationDetails,
  receiveAutoCompleteAddress,
  clearGeoCode,
} from "../actions/actionGeoLocation";

import { getEntities, getEntityById } from "../managers/reducerManager";

import {
  receiveLogout,
  receiveUnAuthoriseUser,
} from "../actions/actionAuthentication";

// the any type should change to a more specific type in the future
export type GeoLocationState = {
  readonly current: any;
  readonly entities: any[];
};

const GEOLOCATION_INITIAL_STATE: GeoLocationState = {
  current: {},
  entities: [],
};

export const addressDetail = (
  state = GEOLOCATION_INITIAL_STATE,
  action: AnyAction
) => {
  if (
    receiveGeoCode.match(action) ||
    receiveReverseGeoCode.match(action) ||
    receiveLocationDetails.match(action)
  ) {
    return getEntityById(state, action.data);
  }

  if (receiveAutoCompleteAddress.match(action)) {
    return getEntities(state, action.data);
  }

  if (clearGeoCode.match(action)) {
    return GEOLOCATION_INITIAL_STATE;
  }

  if (receiveUnAuthoriseUser.match(action) || receiveLogout.match(action)) {
    return GEOLOCATION_INITIAL_STATE;
  }

  return state;
};
