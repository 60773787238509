const AddressPrintLevel = {
    street: "street",
    city: "city",
    streetCountry: "streetCountry",
    country: "country",
};

function isAddressValid(address) {
    if (!address) return false;
    const { city, country, postcode, street } = address;
    return city && country && postcode && street;
}

function addressToStr(address, printLevel = AddressPrintLevel.streetCountry) {
    if (!isAddressValid(address)) {
        if (address && address.displayName) {
            return address.displayName.replace(/<[^>]*>?/gm, "");
        }
        return "";
    }

    const { city, country, postcode, street, displayName } = address;

    switch (printLevel) {
        case AddressPrintLevel.city:
            return postcode + ", " + city;
        case AddressPrintLevel.street:
            return street;
        case AddressPrintLevel.streetCountry:
            return street + " " + postcode + " " + city + " " + country;
        case AddressPrintLevel.country:
            return country;
    }
}

export { AddressPrintLevel, isAddressValid, addressToStr };
