import { RESERVATION_ADD_MODAL_TYPES } from "./reservationAddModal.type";

// generic action creator
export const createAction = (type, payload) => ({type, payload});

//Action Creators
export const setInputValue = (newValue) => createAction(RESERVATION_ADD_MODAL_TYPES.SET_INPUT_VALUE, newValue);

export const setStopoversNumber = (numOfStopovers) => createAction(RESERVATION_ADD_MODAL_TYPES.SET_STOPOVERS_NUMBER, numOfStopovers);

export const setNewReservation = (newReservation) => createAction(RESERVATION_ADD_MODAL_TYPES.SET_NEW_RESERVATION, newReservation);

export const setIsReservationChanged = (boolean) => createAction(RESERVATION_ADD_MODAL_TYPES.SET_IS_RESERVATION_CHANGED, boolean);

export const setIsNextButtonTouched = (boolean) => createAction(RESERVATION_ADD_MODAL_TYPES.SET_IS_NEXT_BUTTON_TOUCHED, boolean);

export const setStopoverValues = (newStopoverValues) => createAction(RESERVATION_ADD_MODAL_TYPES.SET_STOPOVER_VALUES, newStopoverValues);