const reservationStatus = {
    All: null,
    New: "New",
    Approved: "Approved",
    Declined: "Declined"
}

const reservationStausArray = [
    {id: reservationStatus.All, name: "reservationStatusSelect.all"},
    {id: reservationStatus.New, name: "reservationStatusSelect.new"},
    {id: reservationStatus.Approved, name: "reservationStatusSelect.approved"},
    {id: reservationStatus.Declined, name: "reservationStatusSelect.declined"},
]

export {reservationStatus, reservationStausArray};