import { roles } from "../models/Role";

function isAuthorized(user, allowedRoles, allowedPackages) {
  if (!user || Object.keys(user).length === 0) {
    return false;
  }

  let roleAuthorized = true;
  let packageAuthorized = true;
  if (allowedRoles && allowedRoles.length > 0)
    roleAuthorized = allowedRoles.some((ar) => ar === user.role.name);
  if (allowedPackages && allowedPackages.length > 0)
    packageAuthorized = allowedPackages.some((ap) =>
      user.packages.some((p) => ap === p)
    );

  return roleAuthorized && packageAuthorized;
}

function isPackageAuthorized(user, allowedPackages) {
  return allowedPackages.some((ap) => user.packages.some((p) => ap === p));
}

function isViewer(user) {
  return !isAuthorized(user, allowedRoles(roles.Driver));
}

function allowedRoles(role) {
  switch (role) {
    case roles.SuperAdmin:
      return [roles.SuperAdmin];
    case roles.Admin:
      return [roles.Admin];
    case roles.AdminViewer:
      return [roles.Admin, roles.AdminViewer];
    case roles.GroupLeader:
      return [roles.Admin, roles.GroupLeader];
    case roles.GroupLeaderViewer:
      return [
        roles.Admin,
        roles.AdminViewer,
        roles.GroupLeader,
        roles.GroupLeaderViewer,
      ];
    case roles.Driver:
      return [roles.Admin, roles.GroupLeader, roles.DriverLeader, roles.Driver];
    case roles.DriverLeader:
      return [roles.Admin, roles.GroupLeader, roles.GroupLeaderViewer, roles.DriverLeader];
    case roles.DriverViewer:
      return [
        roles.Admin,
        roles.AdminViewer,
        roles.GroupLeader,
        roles.GroupLeaderViewer,
        roles.DriverLeader,
        roles.Driver,
      ];
  }
}

export { isAuthorized, isViewer, allowedRoles, isPackageAuthorized };
