import React from 'react'
import CustomModal from '../common/modals/CustomModal';
import { useTranslation } from 'react-i18next';

const VehiclesWithOverconsumptionModal = ({onClose, content}) => {

    const { t } = useTranslation()

    return (
        <CustomModal
        contents={content}
        title={t("dashboard.vehiclesWithOverconsumeModal.title")}
        onClose={onClose}

        />
    )
}

export default VehiclesWithOverconsumptionModal;