import { useTheme } from "@material-ui/core/styles";

import SwipeableViews from "react-swipeable-views";
import { DialogContent } from "@material-ui/core";

const CustomModalContents = ({ tabBarState, setTabBar, contents }) => {
  const theme = useTheme();

  const handleTabBarChangeIndex = (index) => {
    setTabBar(index);
  };

  return (
    <SwipeableViews
      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
      index={tabBarState}
      onChangeIndex={handleTabBarChangeIndex}
    >
      {contents.map((content, index) => (
        <div key={`custom-modal-contents-${index}`} dir={theme.direction}>
          <DialogContent>{content}</DialogContent>
        </div>
      ))}
    </SwipeableViews>
  );
};

export default CustomModalContents;
