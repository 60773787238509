import React from 'react'
import { BiRename } from 'react-icons/bi';
import DeleteIcon from '@material-ui/icons/Delete';
import ListAltIcon from '@material-ui/icons/ListAlt';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import SearchIcon from '@material-ui/icons/Search';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import { Fade } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import PolygonIcon from '../../../../icons/Polygon.svg';
import CircleIcon from '../../../../icons/Circle.svg';
import RectangleIcon from '../../../../icons/Rectangle.svg';
import EditIcon from '../../../../icons/Edit.svg';
import DirectionsIcon from '@material-ui/icons/Directions';
import Search from '@material-ui/icons/Search';
import { ColorLens, LibraryAdd } from '@material-ui/icons';
import { customColors } from '../../generic/StylingOptions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StorageIcon from '@material-ui/icons/Storage';
import { useTranslation } from 'react-i18next';

function HelpTooltip(props) {

    const {setPopperKey, navValue} = props;
    const iconFontSize = 30;
    const imageSize = 40;
    const { t } = useTranslation();

    return (<div className="helpWrapper" {...props}>
        <div className="helpContent">
            <Fade in={navValue === 0} timeout={0} unmountOnExit>
                <div className="helpPage">
                    <div className="helpRow">
                        <ListAltIcon style={{fontSize: iconFontSize, margin: "5px"}}></ListAltIcon>
                        <div className="helpTooltip">{t("help.history")}</div>
                    </div>
                    <div className="helpRow">
                        <VisibilityIcon style={{fontSize: iconFontSize, margin: "5px"}}></VisibilityIcon>
                        <div className="helpTooltip">{t("help.hide")}</div>
                    </div>
                    <div className="helpRow">
                        <WbIncandescentOutlinedIcon style={{fontSize: iconFontSize, margin: "5px"}}></WbIncandescentOutlinedIcon>
                        <div className="helpTooltip">{t("help.highlight")}</div>
                    </div>
                    <div className="helpRow">
                        <BiRename style={{fontSize: iconFontSize, margin: "5px"}}></BiRename>
                        <div className="helpTooltip">{t("help.rename")}</div>
                    </div>
                    <div className="helpRow">
                        <StorageIcon style={{fontSize: iconFontSize, margin: "5px"}}></StorageIcon>
                        <div className="helpTooltip">{t("help.metadata")}</div>
                    </div>
                    <div className="helpRow">
                        <DeleteIcon style={{fontSize: iconFontSize, margin: "5px"}}></DeleteIcon>
                        <div className="helpTooltip">{t("help.deleteFence")}</div>
                    </div>
                </div>
            </Fade>

            <Fade in={navValue === 1} timeout={0} unmountOnExit>
                <div className="helpPage">
                    <div className="helpRow">
                        <LockIcon style={{fontSize: iconFontSize, margin: "5px"}} />
                        <div className="helpTooltip">{t("help.lock")}</div>
                    </div>
                    <div className="helpRow">
                        <LockOpenIcon style={{fontSize: iconFontSize, margin: "5px"}}></LockOpenIcon>
                        <div className="helpTooltip">{t("help.unlock")}</div>
                    </div>
                    <div className="helpRow">
                        <SwapVertIcon style={{fontSize: iconFontSize, margin: "5px"}}></SwapVertIcon>
                        <div className="helpTooltip">{t("help.toggle")}</div>
                    </div>
                    <div className="helpRow">
                        <DeleteIcon style={{fontSize: iconFontSize, margin: "5px"}}></DeleteIcon>
                        <div className="helpTooltip">{t("help.deleteSelect")}</div>
                    </div>
                    <div className="helpRow">
                        <LibraryAdd style={{fontSize: iconFontSize, margin: "5px"}}></LibraryAdd>
                        <div className="helpTooltip">{t("help.addPreset")}</div>
                    </div>
                    
                    <div className="helpRow">
                        <Search style={{fontSize: iconFontSize, margin: "5px"}}></Search>
                        <div className="helpTooltip">{t("help.search")}</div>
                    </div>
                    <div className="helpRow">
                        <ColorLens style={{fontSize: iconFontSize, margin: "5px"}}></ColorLens>
                        <div className="helpTooltip">{t("help.colorPicker")}</div>
                    </div>
                </div>
            </Fade>

            <Fade in={navValue === 2} timeout={0} unmountOnExit>
                <div className="helpPage">
                <div className="helpRow">
                        <SearchIcon style={{fontSize: iconFontSize, margin: "5px"}}></SearchIcon>
                        <div className="helpTooltip">{t("help.searchPoly")}</div>
                    </div>
                    <div className="helpRow">
                        <img src={PolygonIcon} height={imageSize} width={imageSize} alt="Polygon Icon"></img>
                        <div className="helpTooltip">{t("help.polygon")}</div>
                    </div>
                    <div className="helpRow">
                        <img src={RectangleIcon} height={imageSize} width={imageSize} alt="Rectangle Icon"></img>
                        <div className="helpTooltip">{t("help.rectangle")}</div>
                    </div>
                    <div className="helpRow">
                        <img src={CircleIcon} height={imageSize} width={imageSize} alt="Circle Icon"></img>
                        <div className="helpTooltip">{t("help.circle")}</div>
                    </div>
                    <div className="helpRow">
                        <img src={EditIcon} height={imageSize} width={imageSize} alt="Edit Icon"></img>
                        <div className="helpTooltip">{t("help.edit")}</div>
                    </div>
                    <div className="helpRow">
                        <DirectionsIcon style={{fontSize: iconFontSize, margin: "5px"}}></DirectionsIcon>
                        <div className="helpTooltip">{t("help.route")}</div>
                    </div>
                </div>
            </Fade>
        </div>
        

        <BottomNavigation
            style={{ height: '60px', width: '100%', paddingTop: '5px', backgroundColor: customColors.bright, filter: 'drop-shadow(0 -2px 2px rgba(0,0,0,0.25))' }}
            value={navValue} showLabels onChange={(e, newValue) => {
            setPopperKey(newValue)}}>
            <BottomNavigationAction label={t("help.creationPoint")} value={2} icon={<FormatShapesIcon />} />
            <BottomNavigationAction label={t("help.listPoint")} value={0} icon={<SettingsIcon />} />
            <BottomNavigationAction label={t("help.lockPoint")} value={1} icon={<LockIcon />} />
        </BottomNavigation>
    </div>)

}

export default React.memo(HelpTooltip, isEqual);

function isEqual(prevProps, nextProps) {
    return true;
}