import React from "react";
import { TablePagination } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const styles = theme => ({
    pageNavigationInformation: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "contents",
        },
    },
});

const GenericTablePagination = ({ classes, rowsPerPage, rowCount, page, onChangePage, onChangeRowsPerPage }) => {
    const { t } = useTranslation();

    function displayRows(from, to, count) {
        return from + "-" + (to === -1 ? count : to) + " " + t("common.table.of") + " " + count;
    }

    return (
        <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={rowCount ? rowCount : 0}
            labelDisplayedRows={({ from, to, count }) => displayRows(from, to, count)}
            rowsPerPage={rowsPerPage}
            component="div"
            labelRowsPerPage={
                <span className={classes.pageNavigationInformation}>
                    {t("common.table.rowPerPage")}
                </span>
            }
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
        />
    );
};

export default withStyles(styles, { withTheme: true })(GenericTablePagination);
