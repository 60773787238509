import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TreeSelect } from "antd";
import { requestTree, requestTreesCurrentUser } from "../../../../actions/actionGroups";
import { getGroupValuesFromIds } from "../../../../managers/groupManager";
import { allowedRoles, isAuthorized } from "../../../../managers/authManager";
import { roles } from "../../../../models/Role";
import { useTranslation } from "react-i18next";

const SHOW_ALL = TreeSelect.SHOW_ALL;

const CustomTreeSelect = props => {
    const { me, tree, value, onChange, disabled, preSelectedGroups } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if (isAuthorized(me, allowedRoles(roles.AdminViewer))) props.requestTree();
        else props.requestTreesCurrentUser();
    }, []);

    useEffect(() => {
        if (preSelectedGroups && tree.length > 0) {
            let values = getGroupValuesFromIds(tree, preSelectedGroups.map(group => group.id));
            onChange(values);
        }
    }, [preSelectedGroups, tree]);

    return (
        <TreeSelect
            disabled={disabled}
            treeData={tree}
            value={value}
            onChange={onChange}
            multiple
            treeCheckable
            treeCheckStrictly
            treeDefaultExpandAll
            showCheckedStrategy={SHOW_ALL}
            searchPlaceholder={t("common.select.group")}
            style={{
                width: "100%",
                zIndex: "1350",
            }}
        />
    );

};

CustomTreeSelect.defaultProps = {
    disabled: false,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestTree, requestTreesCurrentUser }, dispatch);
}

function mapStateToProp(state) {
    return {
        tree: state.tree,
        me: state.users.me,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(CustomTreeSelect);
