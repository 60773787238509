import React, {Component} from "react";
import PropTypes from "prop-types";
import CustomModal from "./CustomModal";

const AddModal = ({onClose, onAdd, children, title, largeModal}) => {
    return <CustomModal title={title} action="add" onClose={onClose} onSave={onAdd} contents={[children]}
                        largeModal={largeModal}/>;
};

// AddModal.propTypes = {
//     open: PropTypes.bool.isRequired,
//     title: PropTypes.string.isRequired,
//     onAdd: PropTypes.func.isRequired,
//     onClose: PropTypes.func.isRequired,
//     largeModal: PropTypes.bool,
// };

export default AddModal;
