import { AnyAction } from "redux";

import { receiveError, setError, clearError } from "../actions/actionCommon";
import {
  receiveLogout,
  receiveAuthoriseUser,
  receiveUnAuthoriseUser,
} from "../actions/actionAuthentication";

import { getError } from "../managers/reducerManager";


export type ErrorState = {
  readonly updatedAt?: number;
  readonly message: string;
  readonly entities: [];
  readonly status: number;
};

const ERROR_INITIAL_STATE: ErrorState = {
  updatedAt: undefined,
  message: "",
  entities: [],
  status: 200,
};

export const errorDetail = (
  state = ERROR_INITIAL_STATE,
  action: AnyAction
): ErrorState => {
  if (receiveError.match(action)) {
    return getError(action.data);
  }

  if (setError.match(action)) {
    return { ...state, ...action.data, updatedAt: Date.now() };
  }

  if (
    clearError.match(action) ||
    receiveLogout.match(action) ||
    receiveAuthoriseUser.match(action)
  ) {
    return ERROR_INITIAL_STATE;
  }

  if (receiveUnAuthoriseUser.match(action)) {
    return {
      message: "Die Session ist abgelaufen",
      status: 401,
      entities: [],
      updatedAt: Date.now(),
    };
  }

  return state;
};
