import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  ReservationGetRequest,
  ReservationAddRequest,
  ReservationUpdateRequest,
  ReservationAvailableVehicleOrDriverRequest,
} from "../types/reservation/reservationRequest.types";
import { ReservationResponse, ReservationReportResponse } from "../types/reservation/reservationResponse.types";
import { UserResponse } from "../types/user/userResponse.types";
import { VehicleResponse } from "../types/vehicle/vehicleResponse.types";

import {
  RequestReservations,
  ReceiveReservations,
  RequestFilterReservations,
  ReceiveFilterReservations,
  RequestAddReservation,
  ReceiveAddReservation,
  ToggleReservationSuccessMsgVisibility,
  RequestUpdateReservations,
  ReceiveUpdateReservations,
  RequestDeleteReservation,
  ReceiveDeleteReservation, 
  LoadReservations, 
  SetIsFilterActive, 
  RequestAvailableVehicles,
  RequestAvailableDrivers, 
  ReceiveAvailableDrivers,
  ReceiveAvailableVehicles,
  RequestReservationReport,
  ReceiveReservationReport
} from "../types/actions/actionReservation.types";

export enum RESERVATION_ACTION_TYPES {
  REQUEST_RESERVATIONS = "REQUEST_RESERVATIONS",
  RECEIVE_RESERVATIONS = "RECEIVE_RESERVATIONS",

  REQUEST_FILTER_RESERVATIONS = "REQUEST_FILTER_RESERVATIONS",
  RECEIVE_FILTER_RESERVATIONS = "RECEIVE_FILTER_RESERVATIONS",

  REQUEST_ADD_RESERVATION = "REQUEST_ADD_RESERVATION",
  RECEIVE_ADD_RESERVATION = "RECEIVE_ADD_RESERVATION",
  TOGGLE_RESERVATION_SUCCESS_MESSAGE_VISIBILITY = "TOGGLE_RESERVATION_SUCCESS_MESSAGE_VISIBILITY",

  REQUEST_UPDATE_RESERVATION = "REQUEST_UPDATE_RESERVATION",
  RECEIVE_UPDATE_RESERVATION = "RECEIVE_UPDATE_RESERVATION",

  REQUEST_DELETE_RESERVATION = "REQUEST_DELETE_RESERVATION",
  RECEIVE_DELETE_RESERVATION = "RECEIVE_DELETE_RESERVATION",

  REQUEST_CLEAR_RESERVATION_FILTER = "REQUEST_CLEAR_RESERVATION_FILTER",
  RECEIVE_CLEAR_RESERVATION_FILTER = "RECEIVE_CLEAR_RESERVATION_FILTER",

  REQUEST_RESERVATION_REPORTS = "REQUEST_RESERVATION_REPORTS",
  RECEIVE_RESERVATION_REPORTS = "RECEIVE_RESERVATION_REPORTS",

  LOAD_RESERVATIONS = "LOAD_RESERVATIONS",

  SET_IS_FILTER_ACTIVE = "SET_IS_FILTER_ACTIVE",

  REQUEST_AVAILABLE_DRIVERS = "REQUEST_AVAILABLE_DRIVERS",
  RECEIVE_AVAILABLE_DRIVERS = "RECEIVE_AVAILABLE_DRIVERS",

  REQUEST_AVAILABLE_VEHICELS = "REQUEST_AVAILABLE_VEHICELS",
  RECEIVE_AVAILABLE_VEHICLES = "RECEIVE_AVAILABLE_VEHICLES",
}

export const requestReservations = withMatcher(
  (data: ReservationGetRequest): RequestReservations => {
    return createAction(RESERVATION_ACTION_TYPES.REQUEST_RESERVATIONS, data);
  }
);

export const receiveReservations = withMatcher(
  (data: ReservationResponse): ReceiveReservations => {
    return createAction(RESERVATION_ACTION_TYPES.RECEIVE_RESERVATIONS, data);
  }
);

export const requestFilterReservations = withMatcher(
  (
    filterData: ReservationGetRequest,
    isFilterActive: boolean
  ): RequestFilterReservations => {
    return createAction(RESERVATION_ACTION_TYPES.REQUEST_FILTER_RESERVATIONS, {
      filterData,
      isFilterActive,
    });
  }
);

export const receiveFilterReservations = withMatcher(
  (
    data: ReservationResponse & { isFilterActive: boolean }
  ): ReceiveFilterReservations => {
    return createAction(
      RESERVATION_ACTION_TYPES.RECEIVE_FILTER_RESERVATIONS,
      data
    );
  }
);

export const requestAddReservation = withMatcher(
  (data: ReservationAddRequest): RequestAddReservation => {
    return createAction(RESERVATION_ACTION_TYPES.REQUEST_ADD_RESERVATION, data);
  }
);

export const receiveAddReservation = withMatcher(
  (data: ReservationResponse): ReceiveAddReservation => {
    return createAction(RESERVATION_ACTION_TYPES.RECEIVE_ADD_RESERVATION, data);
  }
);

export const toggleReservationSuccessMsgVisibility = withMatcher(
  (isVisible: boolean): ToggleReservationSuccessMsgVisibility => {
    return createAction(
      RESERVATION_ACTION_TYPES.TOGGLE_RESERVATION_SUCCESS_MESSAGE_VISIBILITY,
      isVisible
    );
  }
);

export const requestUpdateReservations = withMatcher(
  (data: ReservationUpdateRequest): RequestUpdateReservations => {
    return createAction(
      RESERVATION_ACTION_TYPES.REQUEST_UPDATE_RESERVATION,
      data
    );
  }
);

export const receiveUpdateReservations = withMatcher(
  (data: ReservationResponse): ReceiveUpdateReservations => {
    return createAction(
      RESERVATION_ACTION_TYPES.RECEIVE_UPDATE_RESERVATION,
      data
    );
  }
);

export const requestDeleteReservation = withMatcher(
  (reservationId: string): RequestDeleteReservation => {
    return createAction(
      RESERVATION_ACTION_TYPES.REQUEST_DELETE_RESERVATION,
      reservationId
    );
  }
);

export const receiveDeleteReservation = withMatcher(
  (data: ReservationResponse): ReceiveDeleteReservation => {
    return createAction(
      RESERVATION_ACTION_TYPES.RECEIVE_DELETE_RESERVATION,
      data
    );
  }
);

export const loadReservations = withMatcher(
  (): LoadReservations => {
    return createAction(RESERVATION_ACTION_TYPES.LOAD_RESERVATIONS);
  }
);

export const setIsFilterActive = withMatcher(
  (isFilterActive: boolean): SetIsFilterActive => {
    return createAction(
      RESERVATION_ACTION_TYPES.SET_IS_FILTER_ACTIVE,
      isFilterActive
    );
  }
);

export const requestAvailableDrivers = withMatcher(
  (data: ReservationAvailableVehicleOrDriverRequest): RequestAvailableDrivers => {
    return createAction(
      RESERVATION_ACTION_TYPES.REQUEST_AVAILABLE_DRIVERS,
      data
    );
  }
);

export const receiveAvailableDrivers = withMatcher(
  (data: UserResponse): ReceiveAvailableDrivers => {
    return createAction(
      RESERVATION_ACTION_TYPES.RECEIVE_AVAILABLE_DRIVERS,
      data
    );
  }
);

export const requestAvailableVehicles = withMatcher(
  (data: ReservationAvailableVehicleOrDriverRequest): RequestAvailableVehicles => {
    return createAction(
      RESERVATION_ACTION_TYPES.REQUEST_AVAILABLE_VEHICELS,
      data
    );
  }
);

export const receiveAvailableVehicles = withMatcher(
  (data: VehicleResponse): ReceiveAvailableVehicles => {
    return createAction(
      RESERVATION_ACTION_TYPES.RECEIVE_AVAILABLE_VEHICLES,
      data
    );
  }
);

export const requestReservationReport = withMatcher(
  (): RequestReservationReport => {
    return createAction(RESERVATION_ACTION_TYPES.REQUEST_RESERVATION_REPORTS);
  }
);

export const receiveReservationReport = withMatcher(
  (data: ReservationReportResponse): ReceiveReservationReport => {
    return createAction(
      RESERVATION_ACTION_TYPES.RECEIVE_RESERVATION_REPORTS,
      data
    );
  }
);

