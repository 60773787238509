import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import WizardModal from "../../common/modals/wizard-modal/WizardModal";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import {requestAddCostcenter, requestRoles} from "../../../actions/actionCostcenter";
import {Input, Switch} from "antd";
import FormBuilder from "../../../managers/formBuilder";
import {getIdsFromGroupValues} from "../../../managers/groupManager";
import AddModal from "../../common/modals/AddModal";
import InputField from "../../common/inputs/InputField";
import FormModal from "../../common/modals/FormModal";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { useTranslation } from "react-i18next";


const DEFAULT_INPUT_VALUES = {
    foreignId: null,
    description: "",
}

const CostCenterAddModal = props => {
    const { handleInputChange, fields } = useInputChangeHandler(DEFAULT_INPUT_VALUES);

    const { foreignId, description } = fields;

    const { onClose, requestAddCostcenter, costcenter} = props;
    const { t } = useTranslation();

    function handleOnSave() {
        requestAddCostcenter({ ...costcenter, ...fields });
        onClose();
    }

    const inputs = [
            new FormBuilder.FormInput(t("costCenter.addmodal.page.id"), <Input value={foreignId} onChange={(e) => handleInputChange("foreignId", e.target.value)} />)
                .withValue(foreignId)
                .withKey("foreignId")
                .withValidationMessage(t("costCenter.addmodal.page.idValidation"))
                .isRequired()
                .build(),
            new FormBuilder.FormInput(t("costCenter.addmodal.page.description"), <Input value={description} onChange={(e) => handleInputChange("description", e.target.value)} />)
                .withValue(description)
                .withKey("description")
                .build(),
            ];

    return (
        <FormModal
            action={"update"}
            onSave={handleOnSave}
            onClose={onClose}
            title={t("costCenter.addmodal.page.title")}
            inputs={inputs}
        />
    );
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestAddCostcenter, requestRoles}, dispatch);
}

function mapStateToProp(state) {
    return {
        costcenter: state.costcenters.current,
        error: state.error,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(CostCenterAddModal);
