import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflowX: "hidden",
        maxWidth: "calc(100% - " + theme.spacing(7) + "px)",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3) + "px " + theme.spacing(1) + "px",
            maxWidth: "calc(100% - 1px - " + theme.spacing(7) + "px)",
        },
        "& > *": {
            margin: "0 5px",
            width: "auto"
        }
    },
}));
const ContentContainer = ({children}) => {
    const classes = useStyles();
    return <main className={classes.content}> {children} </main>;
};

export default ContentContainer;
