import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import GetAppIcon from "@material-ui/icons/GetApp";

import Page from "../common/container/Page";
import Toolbar from "../common/toolbar/Toolbar";
import Snackbar from "../common/views/Snackbar";
import {menu} from "../../managers/pathManager";

import AssignedDriveBoxSelect from "./AssignedDriveBoxSelect";
import DriveboxModal from "./DriveboxModal";
import DriveboxTable from "./DriveboxTable";

import {requestDriveboxes} from "../../actions/actionDriveboxes";
import {requestDriveboxesExportXlsx} from "../../actions/actionExport";

import {updateFilterAPICall} from "../../actions/actionFilter";

import {useModalStatus, useSnackbar} from "../../hooks/useModalStatus";
import {usePagination} from "../../hooks/usePagination";
import PluggedEventsModal from "./PluggedEventsModal";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";

const DriveboxListPage = props => {
    const [drivebox, setDrivebox] = useState();
    const [assignedFilter, setAssignedFilter] = useState(0);
    const {page, rowsPerPage, changePage, changeRowPerPage} = usePagination();
    const {open: exportSnackbar, show: showExportSnackbar, hide: hideExportSnackbar} = useSnackbar();
    const {modal: driveboxModal, open: openDriveboxModal, close: closeDriveboxModal} = useModalStatus();
    const {modal: pluggedEventsModal, open: openPluggedEventsModal, close: closePluggedEventsModal} = useModalStatus();

    const {loading, driveboxes, filter} = props;

    useEffect(() => {
        requestDrivebox();
    }, [filter, page, rowsPerPage]);

    function handleAssignedDriveboxChange(assignedFilter) {
        switch (assignedFilter) {
            case 1:
                props.updateFilterAPICall({isAssigned: true}, false);
                break;
            case 2:
                props.updateFilterAPICall({isAssigned: false}, false);
                break;
            default:
                props.updateFilterAPICall({isAssigned: ""}, false);
        }
        setAssignedFilter(assignedFilter);
    }

    function requestDrivebox() {
        props.requestDriveboxes({...filter, page: page + 1, perPage: rowsPerPage});
    }

    function requestDriveboxExport() {
        showExportSnackbar();
        props.requestDriveboxesExportXlsx({...filter});
    }

    function handleSearch(searchTerm) {
        props.updateFilterAPICall({ searchTerm }, false);
        changePage(null, 0);
    }

    const actionButtons = [{
        icon: <FontAwesomeIcon minWidth={26} fontSize={17}>far fa-download</FontAwesomeIcon>,
        translateKey: "drivebox.page.export",
        onClick: requestDriveboxExport,
    }];

    const toolbar = (
        <Toolbar actionButtons={actionButtons} onSearch={handleSearch}
                 controls={[<AssignedDriveBoxSelect value={assignedFilter} onChange={handleAssignedDriveboxChange}/>]}/>
    );

    const noEntryFound = driveboxes.length == 0 && !filter;
    return (
        <Page activePage={menu.driveboxes.key} toolbar={toolbar} loading={loading} noEntryFound={noEntryFound}>
            <DriveboxTable
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={changePage}
                handleChangeRowsPerPage={changeRowPerPage}
                onPlugged={d => {
                    setDrivebox(d);
                    openPluggedEventsModal();
                }}
                onEdit={d => {
                    setDrivebox(d);
                    openDriveboxModal();
                }}
            />
            {driveboxModal && <DriveboxModal drivebox={drivebox} onClose={closeDriveboxModal}/>}
            {pluggedEventsModal && <PluggedEventsModal id={drivebox.id} onClose={closePluggedEventsModal}/>}
            <Snackbar translateKey="drivebox.page.snackbarExport"
                      onClose={hideExportSnackbar}
                      open={exportSnackbar}/>
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestDriveboxes,
            requestDriveboxesExportXlsx,
            updateFilterAPICall,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        drivebox: state.driveboxes.current,
        driveboxes: state.driveboxes.entities,
        loading: state.driveboxes.pageLoading,
        filter: state.filter.api,
    };
}

// DriveboxListPage.propTypes = {
//     classes: PropTypes.object,
//     theme: PropTypes.object,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(DriveboxListPage);
