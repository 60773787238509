import { all, put, call, takeEvery, throttle } from "typed-redux-saga/macro";

import { RequestAuthentication } from "../types/actions/actionAuth.types";

import {
    receiveAuthentication,
    receiveAuthoriseUser,
    receiveLogout,
    receiveUnAuthoriseUser,
    AUTH_ACTION_TYPES,
} from "../actions/actionAuthentication";
import { getAuthentication, getLogout } from "./api";
import { handleError } from "../managers/sagaManager";

function* getAuthenticationData(action: RequestAuthentication) {
    try {
        const response = yield* call(getAuthentication, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* all([put(receiveAuthentication(response.data)), put(receiveAuthoriseUser())]);
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getLogoutData() {
    try {
        const user = yield* call(getLogout);
        yield* put(receiveLogout(user));
    } catch (e) {
        yield* put(receiveUnAuthoriseUser());
    }
}

export default function* sagaAutentication() {
    yield* throttle(1000, AUTH_ACTION_TYPES.REQUEST_AUTHENTICATION, getAuthenticationData);
    yield* takeEvery(AUTH_ACTION_TYPES.REQUEST_LOGOUT, getLogoutData);
}
