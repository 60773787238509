const engineType = {
    ElectricVehicle: 0,
    FuelVehicle: 1
};

const engineTypeArray  = [
    {id:  engineType.ElectricVehicle, name: "E-Car"},
    {id:  engineType.FuelVehicle, name: "Fuel"}
]

const findEngineTypeById = (id) => {
    return engineTypeArray.find((e) => e.id == id);
}

export { engineType, engineTypeArray, findEngineTypeById}