import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import AppBar from "../menu/AppBar";
import ContentContainer from "./ContentContainer";
import ErrorToolbar from "../toolbar/ErrorToolbar";
import LoadingIndicator from "../LoadingIndicator";
import { Grid } from "@material-ui/core";
import AddFirstEntry from "../masterData/AddFirstEntry";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import { useDispatch, useSelector } from "react-redux";
import TesterToolbar from "../toolbar/TesterToolbar";
import SuccessToolbar from "../toolbar/SuccessToolbar";

import { toggleEmailSuccessMsgVisibility } from "../../../actions/actionEmail";
import { RootState } from "../../../store";
import { useTranslation } from "react-i18next";

const styles = () => ({
  root: {
    display: "flex",
  },
});

export const PAGE_NAME = {
  trip : 'trip',
  reservation: 'reservation',
  user: 'user',
  vehicle: 'vehicle',
  qualityCharging: "qualityCharging",
  faultyTrips: "faultyTrips"
}

type PageProps = Partial<{
  classes: any,
  activePage: any,
  toolbar: any,
  children: any,
  loading: any,
  noEntryFound: any,
  onAdd: any,
  disableDrawer: any,
  disableErrorToolbar: any,
  disableTesterToolbar: any,
  isSuccessMsgVisible: any,
  successMsg: any,
  toggleMsgVisibility: any,
}>;

const Page: React.FC<PageProps> = ({
  classes,
  activePage,
  toolbar,
  children,
  loading,
  noEntryFound,
  onAdd,
  disableDrawer,
  disableErrorToolbar,
  disableTesterToolbar,
  isSuccessMsgVisible,
  successMsg,
  toggleMsgVisibility,
}) => {
  const isEmailSuccessMsgVisible = useSelector((state: RootState) => state.email.isSuccessfulMsgVisible);
  const emailStatusMsg = useSelector((state: RootState) => state.email.statusMsg);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFilterAPICall({ searchTerm: undefined }));
  }, [activePage]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar disableDrawer={disableDrawer} activePage={activePage} />
      <ContentContainer>
        {!loading && !noEntryFound && toolbar}
        {!disableErrorToolbar && <ErrorToolbar />}
        {isEmailSuccessMsgVisible && <SuccessToolbar successMsg={emailStatusMsg} toggleMsgVisibility={toggleEmailSuccessMsgVisibility} />}
        {isSuccessMsgVisible && <SuccessToolbar successMsg={successMsg} toggleMsgVisibility={toggleMsgVisibility} />}
        {!disableTesterToolbar && <TesterToolbar />}
        {!loading && noEntryFound && <AddFirstEntry onAdd={onAdd} />}
        {!loading && children}
        {loading && (
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item style={{ marginTop: "15%" }}>
              <LoadingIndicator />
            </Grid>
            <Grid item>
              <h4>
                {t("common.loading")}
              </h4>
            </Grid>
          </Grid>
        )}
      </ContentContainer>
    </div>
  );
};

export default withStyles(styles)(Page);
