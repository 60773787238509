import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//Common
import FormModal from "../../common/modals/FormModal";
import { requestUpdateTrips } from "../../../actions/actionTrips";

import UserSelect from "../../common/selects/specificSelects/UserSelect";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import TripTypeSelect from "../../common/selects/specificSelects/TripTypeSelect";
import FormBuilder from "../../../managers/formBuilder";
import { Input } from "antd";
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../../common/modals/modal.utils";
import { useTranslation } from "react-i18next";

const DEFAULT_INPUT_VALUES = {
  driverId: "",
  tripTypeId: "",
  description: "",
  vehicleId: "",
  costcenterId: "",
}

const TripBulkEditModal = ({
  onClose,
  me,
  requestUpdateTrips,
  trips,
  costcenters,
  selected,
  filter,
  page,
  rowsPerPage,
  orderAsc,
}) => {
  const { api } = filter;
  const { fields, handleInputChange } = useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { tripTypeId, driverId, description, vehicleId, costcenterId } = fields;
  const { t } = useTranslation();

  const handleOnSave = () => {
    requestUpdateTrips(
      {
        type: tripTypeId,
        driverId: driverId,
        description: description,
        tripIds: trips.filter((trip) => trip.isSelected).map((trip) => trip.id),
        vehicleId: vehicleId,
        costcenterId: costcenterId,
      },
      { ...api, page: page + 1, orderAsc, perPage: rowsPerPage }
    );
    onClose();
  };

  const inputs = [];

  if (isAuthorized(me, allowedRoles(roles.GroupLeader))) {
    inputs.push(
      new FormBuilder.FormInput(
        t("trip.bulkeditModal.driver") ,
        <UserSelect driverId={driverId} onChange={(value) => handleInputChange("driverId", value)} />
      )
        .withKey("driverId")
        .build()
    );
  }

  inputs.push(
    new FormBuilder.FormInput(
      t("trip.bulkeditModal.tripType"),
      <TripTypeSelect value={tripTypeId} onChange={(value) => handleInputChange("tripTypeId", value)} />
    )
      .withKey("tripTypeId")
      .build()
  );

  inputs.push(
    new FormBuilder.FormInput(
      t("trip.bulkeditModal.description"),
      <Input.TextArea value={description} onChange={(e) => handleInputChange("description", e.target.value)} autosize={{ minRows: 3, maxRows: 5 }} />
    )
      .withKey("description")
      .build()
  );

  if (me.isHWNOE) {
    inputs.push(
      new FormBuilder.FormInput(
        t("trip.bulkeditModal.vehicle"),
        <VehicleSelect value={vehicleId} onChange={(value) => handleInputChange("vehicleId", value)} />
      )
        .withKey("vehicleId")
        .build()
    );
  }

  if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter])) {
    inputs.push(
      new FormBuilder.FormInput(
        t("trip.bulkeditModal.costCenter"),
        <CostCenterSelect value={costcenters ? costcenters.id : ""} onChange={(value) => handleInputChange("costcenterId", value)} allowClear={false} />
      )
        .withValue(costcenters ? costcenters.id : "")
        .withKey("costcenterId")
        .build()
    ); 
  }

  return (
    <FormModal
      action="update"
      onSave={handleOnSave}
      onClose={onClose}
      formType={MODAL_TYPES.withoutValidation}
      title={
        t(
          selected === 1
            ? "trip.bulkeditModal.titleSingular"
            : "trip.bulkeditModal.title",
          { count: selected }
        )
      }      
      inputs={inputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ requestUpdateTrips }, dispatch);
}

function mapStateToProp(state) {
  return {
    trips: state.trips.entities,
    costcenters: state.costcenters.entities,
    selected: state.trips.selected,
    me: state.users.me,
    filter: state.filter,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(TripBulkEditModal);
