import React, {useEffect, useState} from "react";
import EditModal from "../../common/modals/CustomModal";
import {Grid, ListItemIcon} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {loadVehicle, requestTracksOfVehicle, requestVehicleById, requestVehicles} from "../../../actions/actionVehicle";


import {List, ListItemText, ListItem} from "@material-ui/core";
import VehicleDayMap from "../../dayview/VehicleDayMap";
import {periods} from "../../../models/Period";
import {requestTripIdleTimeReport, requestTripReport} from "../../../actions/actionReport";
import useTrackModalStyle from "../../trips/tripsModals/useTrackModalStyle";

import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";
import DayPicker from "../../common/selects/datePickers/DayPicker";
import {updateFilterAPICall} from "../../../actions/actionFilter";
import {fromToByPeriod} from "../../../managers/timeManager";
import {format} from "date-fns";
import Typography from "@material-ui/core/Typography";

import {allowedRoles, isAuthorized, isViewer} from "../../../managers/authManager";
import {packages} from "../../../models/Package";
import {roles} from "../../../models/Role";
import { requestCostcenterById } from "../../../actions/actionCostcenter";
import { useTranslation } from "react-i18next";

const VehicleModal = props => {
    const {userId, onClose, id, onCalibrate, onService, onEdit, trips} = props;
    const classes = useTrackModalStyle();
    const dispatch = useDispatch();
    const vehicle = useSelector(state => state.vehicles.current);
    const filter = useSelector(state => state.filter);
    const me = useSelector(state => state.users.me);

    const currentDay = fromToByPeriod(periods.day.id, new Date());
    const { api, updatedAt } = filter;
    const { name, licensePlate, model, drivebox, owner, currentDriver, type, description, archivedAt, mileageInMeters, isPrivateTripAllowed, assignTripsToOwner, totalAmountOfGroups, groups, createdAt, costcenters, costcenterId, costcenterKey } = vehicle;
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState({
        period: periods.day.id,
        from: currentDay.from,
        to: currentDay.to,
    });
    const {from, to} = dateRange;

    useEffect(() => {
        dispatch(loadVehicle());
        dispatch(requestVehicleById(id));
        //props.requestCostcenters();
        //dispatch(requestCostcenterById(id));
    }, []);


    useEffect(() => {
        if (api.from && api.to && vehicle && api.period === periods.day.id) {
            if (me.canShowTracks) dispatch(requestTracksOfVehicle({...filter.api, vehicleId: id}));
            dispatch(requestTripReport(api));
            dispatch(requestTripIdleTimeReport({...api, id: id}));
        }
    }, [vehicle.id, updatedAt]);


    useEffect(() => {
        if (userId)
            dispatch(requestVehicles({vehicleFrom: api.from, vehicleTo: api.to, driverIds: [userId]}));
    }, [userId]);

    useEffect(() => {
        dispatch(updateFilterAPICall({
            from: from.toISOString(),
            to: to.toISOString(),
            period: periods.day.id,
        }));
    }, [from, to]);

    const vehicleInfo = (iconComponent, text, size) => (
        <Grid item sm={size || 12}>
            {iconComponent}{text}
        </Grid>
    );

    const handleDateChange = (from, to) => {
        setDateRange({period: periods.day.id, from, to});
    }

    let costcenterName = costcenterKey;
    if (costcenterKey == null) costcenterName = "keine zugewiesen";

    const costCenterContent = isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter]) ? vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far fa-donate</FontAwesomeIcon>,
        "Standard-Kostenstelle: " + costcenterName) : <></>;

    const assessmentKmServices = (!me.isHWNOE) ? <ListItem button dense onClick={onService}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon minWidth={35}>far fa-car-mechanic</FontAwesomeIcon>
                                    </ListItemIcon>
                                    <ListItemText primary="Begutachtung / Km Services"/>
                                </ListItem> : <></>;

    const mapContainer = (!me.isHWNOE) ?
                            <Grid item xs={9} style={{height: "100%"}}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <DayPicker variant="standard" style={{width: "100%"}} value={from}
                                                    onDateChange={handleDateChange}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align={"center"} variant={"h6"}>
                                                {trips.length} Fahrten
                                                am {format(new Date(from), "dd.MM.yyyy")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid> 
                                <Grid item xs={12}>
                                    <VehicleDayMap/>
                                </Grid>
                            </Grid> : 
                            <Grid item xs={9} style={{height: "100%"}}>
                                <Grid style={{textAlign: "center", marginTop: "60px"}}>Die Map wurde für diesen Benutzer deaktiviert!</Grid>
                            </Grid> ;


    const viewContent = () => (
        <Grid container className={classes.innerContainer}>
            <Grid style={{marginTop: "20px"}} item xs={12}>
                <Grid container>
                    {vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far
                            fa-credit-card-blank</FontAwesomeIcon>,
                        "Kennzeichen: " + licensePlate)}
                    {vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far fa-info</FontAwesomeIcon>,
                        "Model: " + model
                    )}

                    {drivebox && vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far
                            fa-sim-card</FontAwesomeIcon>,
                        "Imei: " + drivebox.name)}
                    {!drivebox && vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far
                            fa-sim-card</FontAwesomeIcon>,
                        "Keine Drivebox zugewiesen")}

                    {assignTripsToOwner && vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far
                        fa-user</FontAwesomeIcon>, "Fahrten werden " + owner?.name + "  zugewiesen")}

                    {!assignTripsToOwner && vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far
                        fa-user</FontAwesomeIcon>, "Fahrten werden nicht automatisiert zugewiesen")}

                    {vehicleInfo(<FontAwesomeIcon minWidth={30} fontSize={17}>far
                        fa-road</FontAwesomeIcon>, "Kilometerstand: " + Math.round(mileageInMeters / 1000) + " km")}

                    {costCenterContent}
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomContainer}>
                <List component="nav" className={classes.bottomNav}>
                    <ListItem button dense onClick={onCalibrate}>
                        <ListItemIcon>
                            <FontAwesomeIcon minWidth={35}>far fa-road</FontAwesomeIcon>
                        </ListItemIcon>
                        <ListItemText primary="Kalibrieren"/>
                    </ListItem>
                    {assessmentKmServices}
                    <ListItem button dense onClick={onEdit}>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );

    const contents = [
        <Grid container>
            <Grid item xs={3} className={classes.leftColumn}>
                {viewContent(t)}
            </Grid>
            {mapContainer}
        </Grid>,
    ];

    return <EditModal largeModal
                        onClose={onClose}
                        title={name}
                        contents={contents}
                        className={classes.modal}/>

};

export default VehicleModal;
