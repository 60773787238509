import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { customColors } from "../../generic/StylingOptions";
import SimpleSelectionMenu from "../../generic/SimpleSelectionMenu";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ReplayIcon from '@material-ui/icons/Replay';
import {useCookies} from 'react-cookie';
import React from 'react';
import { useTranslation, withTranslation } from "react-i18next";

function PaginationButtonBar(props) {
    let { getGeoFenceData, currentPage, setCurrentPage, pageCount, size, setSize,
        dockSize, searchMode, setDialog } = props;

    const { t } = useTranslation();

    const [cookies, setCookie, removeCookie] = useCookies();

    function goToFirstPage() {
        setCurrentPage(1);
        setCookie("page", 1, {expires: new Date(new Date().getFullYear() + 1, 11, 30)});
        getGeoFenceData(size, 1);
    }

    function goToPreviousPage() {
        setCurrentPage(currentPage - 1);
        setCookie("page", currentPage - 1, {expires: new Date(new Date().getFullYear() + 1, 11, 30)});
        getGeoFenceData(size, currentPage - 1);
    }

    function goToNextPage() {
        if (getGeoFenceData(size, +currentPage + 1)) {
            setCurrentPage(+currentPage + 1);
            setCookie("page", +currentPage + 1, {expires: new Date(new Date().getFullYear() + 1, 11, 30)});
        } else {
            getGeoFenceData(size, +currentPage);
        }
    }

    function goToLastPage() {
        if (getGeoFenceData(size, pageCount)) {
            setCurrentPage(pageCount);
            setCookie("page", pageCount, {expires: new Date(new Date().getFullYear() + 1, 11, 30)});
        } else {
            getGeoFenceData(size, pageCount - 1);
            setCurrentPage(pageCount - 1);
        }
    }

    function reloadPage() {
        getGeoFenceData(size, currentPage);
    }

    function goToSelectedPage() {
        setDialog({
            isOpen: true,
            type: 'prompt',
            title: props.t('pagination.selectPageTitle'),
            label: props.t('pagination.selectPageLabel'),
            confirm: (pageNr) => {
                if (+pageNr !== parseInt(pageNr, 10)) {
                    return;
                }
                if (+pageNr <= 0) {
                    return;
                }
                if (+pageNr > pageCount) {
                    return;
                }

                if (getGeoFenceData(size, +pageNr)) {
                    setCurrentPage(+pageNr);
                    setCookie("page", +pageNr, {expires: new Date(new Date().getFullYear() + 1, 11, 30)});
                } else {
                    getGeoFenceData(size, 1);
                }
            },
            cancel: () => {

            }
        });
    }

    function changeSize(n) {
        setSize(n);
        setCookie("pageSize", n);
        setCurrentPage(1);
        getGeoFenceData(n, 1);
    }

    return (
        <div className="paginationBarWrapperDiv" style={{ width: `${dockSize}px`, height: (!searchMode ? '64px' : '0'), transition: 'height 0.3s' }}>
            <Toolbar style={{ backgroundColor: customColors.ilogsBlue }}>
                <Box display='flex' flexGrow={1} style={{ marginLeft: '10px' }}>
                    <SimpleSelectionMenu
                        isDisabled={false}
                        title={props.t('pagination.sizeTitle')}
                        tooltip={t("pagination.sizeTooltip")}
                        items={[
                            {title: '10', value: 10},
                            {title: '20', value: 20},
                            {title: '50', value: 50},
                        ]}
                        selectedItem={size}
                        setSelectedItem={changeSize}
                    ></SimpleSelectionMenu>
                </Box>

                <Tooltip enterDelay={500} title={t("pagination.firstPage")}>
                    <IconButton
                        style={{ color: (currentPage <= 1 ? customColors.disabledDark : customColors.bright), margin: '2px', marginRight: '-3px', padding: '5px' }}
                        onClick={goToFirstPage}
                        disabled={currentPage <= 1}
                    >
                        <SkipNextIcon style={{transform: 'rotate(-180deg)'}} />
                    </IconButton>
                </Tooltip>
                <Tooltip enterDelay={500} title={t("pagination.prevPage")}>
                    <IconButton
                        style={{ color: (currentPage <= 1 ? customColors.disabledDark : customColors.bright), margin: '2px', marginRight: '-3px', padding: '5px' }}
                        onClick={goToPreviousPage}
                        disabled={currentPage <= 1}
                    >
                        <PlayArrowIcon style={{transform: 'rotate(-180deg)'}} />
                    </IconButton>
                </Tooltip>

                <Tooltip enterDelay={500} title={t("pagination.selectPageTitle")}>
                    <div onClick={goToSelectedPage} className="paginationBarPageNumber">
                        {`${currentPage}/${pageCount}`}
                    </div>
                </Tooltip>

                <Tooltip enterDelay={500} title={t("pagination.nextPage")}>
                    <IconButton
                        style={{ color: (currentPage >= pageCount ? customColors.disabledDark : customColors.bright), margin: '2px -3px', padding: '5px' }}
                        onClick={goToNextPage}
                        disabled={currentPage >= pageCount}
                    >
                        <PlayArrowIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip enterDelay={500} title={t("pagination.lastPage")}>
                    <IconButton
                        style={{ color: (currentPage >= pageCount ? customColors.disabledDark : customColors.bright), margin: '2px', marginRight: '5px', padding: '5px' }}
                        onClick={goToLastPage}
                        disabled={currentPage >= pageCount}
                    >
                        <SkipNextIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip enterDelay={500} title={t("pagination.reloadPage")}>
                    <IconButton
                        style={{ color: customColors.bright, margin: '2px', marginRight: '-8px', padding: '5px' }}
                        onClick={reloadPage}
                    >
                        <ReplayIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </div>
    );
}

export default withTranslation()(PaginationButtonBar);