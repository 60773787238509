import { Card, Checkbox, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkLocallyGeofenceVehicle } from '../../../../../../actions/actionGeofences';
import PropTypes from 'prop-types';
import { CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    card: {
        maxHeight: '300px', 
        minHeight: '300px', 
        overflow: 'auto', 
        marginTop: '12px'
    }
}));

const GeofenceVehicleList = ({ module }) => {
    const geofenceVehicles = useSelector(state => state.geofences[module]);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectAll, setSelectAll] = useState("unchecked");
    const classes = useStyles();

    useEffect(() => {
        let countCurrentVehicles = geofenceVehicles.length;
        let countCheckedVehicles = geofenceVehicles.filter(el => el.isLocallyChecked && !el.isHidden).length;

        if (countCurrentVehicles == countCheckedVehicles && countCurrentVehicles !== 0) {
            setSelectAll("checked")
        } else if (countCheckedVehicles == 0) {
            setSelectAll("unchecked")
        } else {
            setSelectAll("indeterminate")
        }
    }, [geofenceVehicles])

    const handleSelectAllGeofenceVehicles = () => {
        dispatch(checkLocallyGeofenceVehicle({ toCheck: selectAll, module }));
    }

    const selectAllCheckbox = <FormGroup row={false}>
        <FormControlLabel control={
            <Checkbox
                checked={selectAll == "checked" ? true : false}
                onChange={() => handleSelectAllGeofenceVehicles()}
                name="checkedB"
                color="primary"
                indeterminate={selectAll == "indeterminate" ? true : false}
            />}
            label={t("geofenceVehicles.allGeofenceVehicles")}
        />
    </FormGroup>;

    const listCheckboxes = <FormGroup row={false}>
        {geofenceVehicles?.filter(el => !el.isHidden).map(el =>
            <FormControlLabel key={el.vehicleId} control={
                <Checkbox
                    checked={el.isLocallyChecked ? true : false}
                    onChange={() => dispatch(checkLocallyGeofenceVehicle({ toCheck: el.vehicleId, module }))}
                    name={el.vehicleId}
                    color="primary"
                />}
                label={el.vehicleName}
            />)}
    </FormGroup>

    return (
        <>
            {selectAllCheckbox}
            <Card variant="elevation" className={classes.card}>
                {/* <CardContent> */}
                    {listCheckboxes}
                {/* </CardContent> */}
                
            </Card>
        </>
    )
}

GeofenceVehicleList.propTypes = {
    module: PropTypes.string.isRequired
}

export default GeofenceVehicleList;