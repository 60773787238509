import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles";
import LoadingIndicator from "../../../common/LoadingIndicator";
import { Card, CardActions, CardContent, CardHeader, IconButton, Grid, Typography, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import useResize from "../../../../hooks/useResize";
import FontAwesomeIcon from "../../../common/views/FontAwesomeIcon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer !important"
    },
    card: {
        minWidth: 275,
        margin: "0 2px",
        padding: "15px 15px 15px 15px",
        minHeight: "72px"
    },
    headerIcon: {
        margin: "15px 15px 15px 15px"
    },
    title: {
        fontSize: props => props.condensed ? 13 : 20,
    },
    itemTitle: {
        fontSize: props => props.condensed ? 11 : 16,
    },
    itemValue: {
        fontSize: props => props.condensed ? 10 : 14,
    },
    icon: {
        fontSize: 30,
        marginRight: 5
    },
    condensedCardContent: {
        padding: "8px 16px !important"
    },
    text: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    helpIcon: {
        float: "right",
        marginLeft: "auto",
        marginBottom: "auto",
        marginTop: "-8px",
        marginRight: "-15px"
    }
}));

const GeofenceAnalysisVehiclesEnteringCard = ({ condensed, geofenceAnalysisVehiclesEntering }) => {
    const classes = useStyles({ condensed: condensed });
    const ref = useRef(null);
    const { width } = useResize(ref);
    const { t } = useTranslation();

    // Gather all vehicle names
    var displayStr = [];
    var vehicleNamesStr = "";
    for (var i = 0; i < geofenceAnalysisVehiclesEntering.length; i++) {

        vehicleNamesStr += geofenceAnalysisVehiclesEntering[i].vehicleName;
        if ((geofenceAnalysisVehiclesEntering.length - i) === 1) {
            vehicleNamesStr += ';';
        } else {
            vehicleNamesStr += ', ';
        }
    }
    if (vehicleNamesStr.length < 1) {
        displayStr = (t("geofenceAnalysis.vehiclesEnteringCard.noneEntering"));
    } else {
        displayStr = (<div class="itemValue">{vehicleNamesStr}</div>);
    }

    return <div className={classes.card}>
        <Card className={classes.card} sx={{ minWidth: 275 }}>
            <CardContent class={classes.title}>
                <FontAwesomeIcon fontSize={30}>far fa-portal-enter </FontAwesomeIcon>
                {t("geofenceAnalysis.vehiclesEnteringCard.title")}
            </CardContent>
            <br/>
            <CardActions>
                <div class={classes.itemTitle}>
                    <div class={classes.itemValue}>
                        {vehicleNamesStr}
                    </div>
                </div>
            </CardActions>

        </Card>
        </div>
};

GeofenceAnalysisVehiclesEnteringCard.defaultProps = {
    condensed: false
};

GeofenceAnalysisVehiclesEnteringCard.propTypes = {
    vehiclesEntering: PropTypes.any,
};

export default (GeofenceAnalysisVehiclesEnteringCard);
