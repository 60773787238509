import React from "react";
import EmptyResult from "../views/EmptyResult";

const AddFirstEntry = ({onAdd}) => {
    return (
        <div style={{marginTop: "200px"}}>
            <EmptyResult onAdd={onAdd}/>
        </div>
    );
};


export default AddFirstEntry;
