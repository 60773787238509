function convertTreeToTreeSelect(groups, death = "0") {
    for (let i in groups) {
        let group = groups[i];
        group.title = group.name;
        group.value = death + "-" + i;
        group.key = death + "-" + i;
        if (group.children) {
            group.children = convertTreeToTreeSelect(group.children, death + "-" + i);
        }
    }
    return groups;
}

function getGroupValuesFromTree(tree, value = []) {
    for (let group of tree) {
        value.push(group.value);
        if (group.children) {
            getGroupValuesFromIds(group.children, value);
        }
    }
    return value;
}


function getGroupValuesFromIds(tree, ids, value = []) {
    for (let group of tree) {
        for (let id of ids) {
            if (id == group.id) {
                value.push({name: group.name, value: group.value});
            }
        }
        if (group.children) {
            getGroupValuesFromIds(group.children, ids, value);
        }
    }
    return value;
}

function getIdsFromGroupValues(tree, values, ids = []) {
    if (!values) return ids;
    for (let group of tree) {
        for (let value of values) {
            if (value.value == group.value) {
                ids.push(group.id);
            }
        }
        if (group.children) {
            getIdsFromGroupValues(group.children, values, ids);
        }
    }
    return ids;
}

export {getIdsFromGroupValues, getGroupValuesFromIds, getGroupValuesFromTree, convertTreeToTreeSelect};
