import React from "react";
import {connect} from "react-redux";
import GenericTable from "../../common/table/GenericTable";
import TableTypes from "../../../models/TableTypes";

const RowDataPositionsTable = ({positions}) => {
    const columnInformations = [
        {title: "Timestamp", key: "timestamp", type: TableTypes.dateTime},
        {title: "DeviceTime", key: "deviceTime", type: TableTypes.dateTime},
        {title: "Longitude", key: "longitude"},
        {title: "Latitude", key: "latitude"},
        {title: "Altitude", key: "altitude"},
        {title: "Speed", key: "speed"},
        {title: "DistanceToPrevPointMeters", key: "distanceToPrevPointMeters"},
        {title: "GpsStatus", key: "gpsStatus"},
        {title: "Satellites", key: "satellites"},
        {title: "Heading", key: "heading"},
        {title: "ExternalPowerVoltage", key: "externalPowerVoltage"},
        {title: "BatteryVoltage", key: "batteryVoltage"},
        {title: "BatteryCurrent", key: "batteryCurrent"},
        {title: "TotalDistance", key: "totalDistance"},
        {title: "DeviceType", key: "deviceType"},
    ];

    return <GenericTable data={positions} columnInformations={columnInformations}/>;
};

function mapStateToProp(state) {
    return {
        positions: state.rawdata.positions.entities,
    };
}

export default connect(mapStateToProp)(RowDataPositionsTable);
