import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
//manager
import {menu} from "../../../managers/pathManager";
//action
import {
    requestMandatorDriveboxes,
    requestMandatorDriveboxToggleArchive,
    requestMandators,
    selectAllMandatorDriveboxes,
    selectMandatorDrivebox,
    requestMandatorDriveBoxHistory,
    loadDriveBoxHistory,
    requestDriveBoxLastSignal
} from "../../../actions/actionMandators";
import {requestMandatorDriveboxExportXlsx} from "../../../actions/actionExport";
//common
import Page from "../../common/container/Page";
import Toolbar from "../../common/toolbar/Toolbar";
import DriveboxTable from "./DriveboxTable";
import MandatorDriveboxMultiEditModal from "./mandatorDriveboxModals/MandatorDriveboxMultiEditModal";
import MandatorDriveboxModal from "./mandatorDriveboxModals/MandatorDriveboxModal";
import ArchiveModal from "../../common/modals/ArchiveModal";
import MandatorDriveboxAssignmentModal from "./mandatorDriveboxModals/MandatorDriveboxAssignmentModal";

import MandatorSelect from "../../common/selects/specificSelects/MandatorSelect";
import Switch from "../../common/inputs/Switch";
//icons
import ArchiveIcon from "@material-ui/icons/Archive";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import GetAppIcon from "@material-ui/icons/GetApp";
import {usePagination} from "../../../hooks/usePagination";
import {useModalStatus} from "../../../hooks/useModalStatus";
import MandatorDriveboxInsertModal from "./mandatorDriveboxModals/MandatorDriveboxInsertModal";
import MandatorBoxHistoryModal from "./mandatorDriveboxModals/MandatorBoxHistoryModal";
import LastSignalModal from "./mandatorDriveboxModals/LastSignalModal";

const MandatorDriveboxPage = props => {
    const [drivebox, setDrivebox] = useState({});
    const [archive, setArchive] = useState(false);
    const [mandatorId, setMandatorId] = useState();
    const [withoutMandator, setWithoutMandator] = useState();
    const [searchTerm, setSearchTerm] = useState("");

    const {page, rowsPerPage, changePage, changeRowPerPage} = usePagination();
    const {modal: editModal, open: openEditModal, close: closeEditModal} = useModalStatus();
    const {modal: bulkEditModal, open: openBulkEditModal, close: closeBulkEditModal} = useModalStatus();
    const {modal: archiveModal, open: openArchiveModal, close: closeArchiveModal} = useModalStatus();
    const {modal: assignmentModal, open: openAssignmentModal, close: closeAssignmentModal} = useModalStatus();
    const {modal: csvImport, open: openCSVImport, close: closeCSVImport} = useModalStatus();
    const {modal: boxHistoryModal, open: openBoxHistoryModal, close: closeBoxHistoryModal} = useModalStatus();
    const {modal: lastSignalModal, open: openLastSignalModal, close: closeLastSignalModal} = useModalStatus();


    const {driveboxes, total, selectMandatorDrivebox, selectAllMandatorDriveboxes} = props;
    const { driveBoxHistory } = props;

    useEffect(() => {
        props.requestMandators();
    }, []);

    useEffect(() => {
        sendRequestMandatorDrivebox();
    }, [archive, mandatorId, page, rowsPerPage, withoutMandator, searchTerm]);

    function sendRequestMandatorDrivebox() {
        props.requestMandatorDriveboxes({
            mandatorId: mandatorId,
            searchTerm: searchTerm,
            withoutMandator: withoutMandator,
            page: page + 1,
            perPage: rowsPerPage,
            isArchived: archive,
        });
    }

    function handleArchiveTogglePressed(newArchiveValue) {
        setArchive(newArchiveValue);
    }

    function handleMandatorChange(mandatorId) {
        setMandatorId(mandatorId);
    }

    function handleWithoutMandatorChange(event) {
        setMandatorId(undefined);
        setWithoutMandator(event.target.checked);
    }

    function handleSearchChange(searchTerm) {
        setSearchTerm(searchTerm);
    }

    function numSelectedDrivebox() {
        if (driveboxes) {
            return driveboxes.filter(function (s) {
                return s.isSelected;
            }).length;
        }
    }

    function getSelectedDriveboxes() {
        return driveboxes.filter(d => d.isSelected);
    }


    function handleEditModalOpen(drivebox) {
        openEditModal();
        setDrivebox(drivebox);
    }

    function handleToggleArchiveDrivebox(drivebox) {
        if (drivebox.archivedAt) {
            props.requestMandatorDriveboxToggleArchive({id: drivebox.id, comment: ""});
        } else {
            setDrivebox(drivebox);
            openArchiveModal();
        }
    }

    function handleArchiveDrivebox(comment) {
        props.requestMandatorDriveboxToggleArchive({id: drivebox.id, comment: comment});
        closeArchiveModal();
    }

    function handleDriveBoxHistory(drivebox) {
        props.loadDriveBoxHistory();
        props.requestMandatorDriveBoxHistory(drivebox.id);
        openBoxHistoryModal();
    }

    function handleLastSignal(driveBox){
        props.loadDriveBoxHistory();
        props.requestDriveBoxLastSignal(driveBox.imei)
        openLastSignalModal();
    }

    const toggles = [
        {
            icon: <RssFeedIcon/>,
            translateKey: "mandatorDriveboxes.page.mandator",
            active: !archive,
            onClick: () => setArchive(false),
        },
        {
            icon: <ArchiveIcon/>,
            translateKey: "mandatorDriveboxes.page.archive",
            active: archive,
            onClick: () => setArchive(true),
        },
    ];
    const actionButtons = [
        {
            icon: <ImportExportIcon/>,
            translateKey: "mandatorDriveboxes.page.csvImport",
            onClick: openCSVImport,
        },
        {
            icon: <FlipToBackIcon/>,
            translateKey: "mandatorDriveboxes.page.driveboxAssignment",
            onClick: openAssignmentModal,
        },
        {
            icon: <GetAppIcon/>,
            translateKey: "mandatorDriveboxes.page.export",
            onClick: () =>
                props.requestMandatorDriveboxExportXlsx({
                    mandatorId: mandatorId,
                    searchTerm: searchTerm,
                    withoutMandator: withoutMandator,
                    isArchived: archive,
                }),
        },
    ];

    const bulkActionButtons = [
        {
            icon: <FlipToBackIcon/>,
            translateKey: "mandatorDriveboxes.page.bulkEdit",
            onClick: openBulkEditModal,
        }
    ];

    const toolbar = (
        <Toolbar toggles={toggles}
                 actionButtons={actionButtons}
                 numberSelected={getSelectedDriveboxes().length}
                 bulkActionButtons={bulkActionButtons}
                 onSearch={handleSearchChange}
                 controls={[<Switch value={withoutMandator}
                                    label="Without mandator"
                                    handleChange={handleWithoutMandatorChange}
                                    toolbar/>,
                     <MandatorSelect value={mandatorId} onChange={handleMandatorChange}
                                     disabled={withoutMandator}/>]}>
        </Toolbar>
    );

    return (
        <Page activePage={menu.mandatorDrivebox.key} toolbar={toolbar}>
            <DriveboxTable
                handleChangePage={changePage}
                handleChangeRowsPerPage={changeRowPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRows={total}
                driveboxes={driveboxes}
                onSelect={selectMandatorDrivebox}
                onSelectAll={selectAllMandatorDriveboxes}
                onArchiveToggle={handleToggleArchiveDrivebox}
                numSelected={numSelectedDrivebox()}
                onEdit={handleEditModalOpen}
                archive={archive}
                onHistory={handleDriveBoxHistory}
                onLastSignal={handleLastSignal}
            />

            {editModal && (
                <MandatorDriveboxModal onClose={closeEditModal}
                                       drivebox={drivebox}
                                       archive={archive}/>
            )}
            {bulkEditModal && (
                <MandatorDriveboxMultiEditModal onClose={closeBulkEditModal}
                                                driveboxes={getSelectedDriveboxes()}/>
            )}
            {archiveModal && (
                <ArchiveModal title="Drivebox"
                              onArchive={handleArchiveDrivebox}
                              onClose={closeArchiveModal}/>
            )}
            {assignmentModal && <MandatorDriveboxAssignmentModal onClose={closeAssignmentModal}/>}
            {csvImport && <MandatorDriveboxInsertModal onClose={closeCSVImport}/>}
            {boxHistoryModal && <MandatorBoxHistoryModal data={driveBoxHistory} onClose={closeBoxHistoryModal}/>}
            {lastSignalModal && <LastSignalModal onClose={closeLastSignalModal} /> }
        </Page>
    );

};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestMandatorDriveboxes,
            requestMandators,
            requestMandatorDriveboxToggleArchive,
            requestMandatorDriveboxExportXlsx,
            selectMandatorDrivebox,
            selectAllMandatorDriveboxes,
            requestMandatorDriveBoxHistory,
            loadDriveBoxHistory,
            requestDriveBoxLastSignal
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        driveboxes: state.driveboxes.entities,
        total: state.driveboxes.total,
        driveBoxHistory: state.driveboxes.driveBoxHistory
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MandatorDriveboxPage);
