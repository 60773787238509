import React from "react";
import {CardContent, CardMedia, makeStyles, TableCell} from "@material-ui/core";
import BoxFront from "../../images/boxFront.png";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    media: {
        height: "100px",
        backgroundSize: "contain",
    },
    headline: {
        textAlign: "center"
    },
    subText: {
        textAlign: "center"
    },
    card: {
        marginTop: "6px"
    }
}));


const OrderInformation = props => {
    const classes = useStyles();

    const {picture, title, subTitle} = props;

    return <div className={classes.card}>
        {picture && <CardMedia className={classes.media}
                               image={picture}
                               title={title}/>}
        <CardContent>
            <Typography className={classes.headline} gutterBottom variant="h5" component="h2">
                {title}
            </Typography>
            <Typography className={classes.subText} variant="body2" color="textSecondary"
                        component="p">
                {subTitle}
            </Typography>
        </CardContent>
    </div>
};


// OrderInformation.propTypes = {
//     picture: PropTypes.object,
//     title: PropTypes.string.isRequired,
//     subTitle: PropTypes.string.isRequired,
// };

export default OrderInformation;