import {
    RECEIVE_SCHEDULED_REPORTS,
    RECEIVE_SCHEDULED_REPORT_BY_ID,
    RECEIVE_ADD_SCHEDULED_REPORT,
    RECEIVE_UPDATE_SCHEDULED_REPORT,
    RECEIVE_DELETE_SCHEDULED_REPORT
} from "../actions/actionScheduledReport";

import {
    addEntity,
    deleteEntity,
    getEntityById,
    getPagedEntities,
    updateEntity,
} from "../managers/reducerManager";

const initialState = {
    entities: [],
};
 
export const scheduledReports = (state = initialState, {type, data}) => {
    switch (type) {
        case RECEIVE_SCHEDULED_REPORTS:
            return getPagedEntities(state, data);
        case RECEIVE_SCHEDULED_REPORT_BY_ID:
            return getEntityById(state, data);
        case RECEIVE_ADD_SCHEDULED_REPORT:
            return addEntity(state, data);
        case RECEIVE_UPDATE_SCHEDULED_REPORT:
            return updateEntity(state, data);
        case RECEIVE_DELETE_SCHEDULED_REPORT:
            return deleteEntity(state, data);
        default:
            return state;
    }
};