import i18n from "../../i18n";

export const getTranslationForStatus = (reservation) => {
    const { state } = reservation; 

    switch(state) {
        case "New":
            return i18n.t("reservation.tableItems.stateValue", { stateInEnglish: "New" , stateInGerman: "Neu" })
        case "Approved":
            return i18n.t("reservation.tableItems.stateValue", { stateInEnglish: "Approved" , stateInGerman: "Genehmigt" })
        case "Declined":
            return i18n.t("reservation.tableItems.stateValue", { stateInEnglish: "Declined" , stateInGerman: "Abgelehnt" })
        default:
            return null;
    }
}