function isSelfIntersecting(arr) {
    if (arr === null)
        return false;

    let len = arr.length;

    if (len < 4)
        return false;

    for (let i=0; i < len-1; i++) {
        for (let j=i+2; j < len; j++) {
            if ((i === 0) && (j === (len - 1))) {
                continue;
            }

            let cut = intersects(arr[i].lat, arr[i].lng,
                                 arr[i+1].lat, arr[i+1].lng,
                                 arr[j].lat, arr[j].lng,
                                 arr[(j+1) % len].lat, arr[(j+1) % len].lng);

            if (cut)
                return true;
        }
    }
    return false;
}

function intersects(a,b,c,d,p,q,r,s) {
    let det, gamma, lambda;
    det = (c - a) * (s - q) - (r - p) * (d - b);
    if (det === 0) {
      return false;
    } else {
      lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
      gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
      return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
    }
  }

export {
    isSelfIntersecting,
}