import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, ListItemIcon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { customColors } from '../../../generic/StylingOptions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import { BiRename } from 'react-icons/bi';
import MetadataDialog from './MetadataDialog';
import { useTranslation } from 'react-i18next';

function ListItemMenu(props) {
    let { isDisabled, thisGeoFence, thisGeofenceAnalysis, onEditTitle, onHighlight, onArchive, onDelete,
        geoFenceMetadata, setGeoFenceMetadata, setDialog, currentUser } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Tooltip enterDelay={500} title={t("fenceList.options")}>
                <IconButton
                    id={ "menuButton_more" }
                    aria-controls={`menuButton_more`}
                    style={{color: (isDisabled ? customColors.disabled : customColors.dark), padding: '2px'}}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    <MoreVertIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                id={`menu_more`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {geoFenceMetadata && thisGeoFence &&
                    <MenuItem
                        style={{ color: customColors.dark }}
                        onClick={() => { onHighlight(); handleClose(); }}
                    >
                        <ListItemIcon>
                            <WbIncandescentOutlinedIcon style={{ color: customColors.dark }} />
                        </ListItemIcon>
                        {t("fenceList.highlight")}
                    </MenuItem>
                }

                {props.onEditTitle !== null && (
                    <MenuItem
                        style={{ color: customColors.dark }}
                        onClick={() => { onEditTitle(); handleClose(); }}
                    >
                        <ListItemIcon>
                            <BiRename style={{ color: customColors.dark, fontSize: 23 }} />
                        </ListItemIcon>
                        {t("fenceList.rename")}
                    </MenuItem>
                    )
                }

                {geoFenceMetadata && thisGeoFence &&
                <MetadataDialog
                    thisGeoFence={thisGeoFence}
                    geoFenceMetadata={geoFenceMetadata}
                    setGeoFenceMetadata={setGeoFenceMetadata}
                    setDialog={setDialog}
                    currentUser={currentUser}
                ></MetadataDialog>
                }

                {props.onArchive !== null && (
                    <MenuItem
                        style={{ color: customColors.danger }}
                        onClick={() => { onArchive(); handleClose(); }}
                    >
                        <ListItemIcon>
                            <DeleteIcon style={{ color: customColors.danger }}
                            />
                        </ListItemIcon>
                        {t("fenceList.archive")}
                    </MenuItem>
                )}
                {/* Deletion functionality is commented/disabled for now, only archiving is possible
                <MenuItem
                    style={{ color: customColors.danger }}
                    onClick={() => { onDelete(); handleClose(); }}
                >
                    <ListItemIcon>
                        <DeleteIcon style={{ color: customColors.danger }}
                    />
                    </ListItemIcon>
                    {t("fenceList.delete")}
                </MenuItem>
                */}
            </Menu>
        </div>
    );
}

export default React.memo(ListItemMenu);
