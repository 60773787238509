import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import AddIcon from "@material-ui/icons/Add";

import {menu} from "../../../managers/pathManager";

import {
    requestDeleteMandatorSuperUser,
    requestMandatorSuperUsers,
    requestUpdateMandatorSuperUserPassword
} from "../../../actions/actionMandators";

import Page from "../../common/container/Page";
import Toolbar from "../../common/toolbar/Toolbar";

import MandatorSuperUserTable from "./MandatorSuperUserTable";

import MandatorSuperUserAddModal from "./MandatorSuperUserAddModal";
import MandatorSuperUserModal from "./MandatorSuperUserModal";
import ChangePasswordModal from "../../common/modals/ChangePasswordModal";

class MandatorSuperUserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMandatorSuperUserModal: false,
            openMandatorSuperUserAddModal: false,
            openChangePasswordModal: false,
            searchTerm: ""
        };
    }

    componentDidMount() {
        this.requestMandatorSuperUser();
    }

    requestMandatorSuperUser() {
        this.props.requestMandatorSuperUsers({searchTerm: this.state.searchTerm});
    }

    handleEditModalOpen = activeUser => {
        const {users} = this.props;
        this.setState({
            activeUserId: activeUser.id,
            currentSuperUser: users.find(u => u.id == activeUser.id),
            openMandatorSuperUserModal: true
        });
    };

    handleChangePasswordModalOpen = activeUser => {
        this.setState({activeUserId: activeUser.id, openChangePasswordModal: true});
    };

    handleDeleteSuperUser = activeUser => {
        this.props.requestDeleteMandatorSuperUser({id: activeUser.id});
    };

    renderTable() {
        const {users} = this.props;
        return (
            <MandatorSuperUserTable
                users={users}
                onInlineEdit={this.handleEditModalOpen}
                onChangePassword={this.handleChangePasswordModalOpen}
                onDelete={this.handleDeleteSuperUser}
            />
        );
    }

    handleModalClose = () => {
        this.setState({
            openMandatorSuperUserModal: false,
            openMandatorSuperUserAddModal: false,
            openChangePasswordModal: false
        });
    };

    handlePasswordChange = (oldPassword, newPassword) => {
        this.props.requestUpdateMandatorSuperUserPassword({newPassword: newPassword, id: this.state.activeUserId});
    };
    handleSearchTermChange = searchTerm => {
        this.setState({searchTerm}, () => this.requestMandatorSuperUser());
    };

    renderMandatorSuperUserAddModal() {
        const {openMandatorSuperUserAddModal} = this.state;
        if (openMandatorSuperUserAddModal) return <MandatorSuperUserAddModal onClose={this.handleModalClose}/>;
    }

    renderMandatorSuperUserModal() {
        const {openMandatorSuperUserModal, currentSuperUser} = this.state;
        if (openMandatorSuperUserModal) return <MandatorSuperUserModal superUser={currentSuperUser}
                                                                       onClose={this.handleModalClose}/>;
    }

    renderChangePasswordModal() {
        const {openChangePasswordModal} = this.state;
        if (openChangePasswordModal) return <ChangePasswordModal onClose={this.handleModalClose}
                                                                 onConfirm={this.handlePasswordChange}/>;
    }

    render() {
        const {classes, error} = this.props;
        const actionButtons = [
            {
                icon: <AddIcon size="small"/>,
                translateKey: "superUser.page.add",
                onClick: () => this.setState({openMandatorSuperUserAddModal: true})
            },
        ];

        const toolbar = <Toolbar actionButtons={actionButtons} onSearch={this.handleSearchTermChange}/>;

        return (
            <Page activePage={menu.mandatorSuperUser.key} toolbar={toolbar}>
                {this.renderTable()}
                {this.renderMandatorSuperUserModal()}
                {this.renderMandatorSuperUserAddModal()}
                {this.renderChangePasswordModal()}
            </Page>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestMandatorSuperUsers,
            requestUpdateMandatorSuperUserPassword,
            requestDeleteMandatorSuperUser,
        },
        dispatch
    );
}

function mapStateToProp(state) {
    return {
        users: state.users.entities,
        error: state.mandators.error,
    };
}

// MandatorSuperUserPage.propTypes = {
//     classes: PropTypes.object,
//     theme: PropTypes.object,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(MandatorSuperUserPage);
