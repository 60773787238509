import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect, useDispatch, useSelector} from "react-redux";
import {requestPluggedEvents} from "../../actions/actionDriveboxes";

import CustomModal from "../common/modals/CustomModal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {format} from "date-fns";
import { useTranslation } from "react-i18next";

const PluggedEventsModal = props => {
    const dispatch = useDispatch();
    const events = useSelector(state => state.driveboxes.events);

    const {onClose, id} = props;
    const { t } = useTranslation();

    useEffect(() => {
        if (id) dispatch(requestPluggedEvents(id));
    }, [id]);

    const content = <div>
        <List dense>
            {events.map(e => <ListItem>
                <ListItemText
                    primary={e.name}
                    secondary={format(new Date(e.happenedAt), "dd.MM.yy H:mm")}
                />
            </ListItem>)}

        </List>

        {events.map(e => {

        })}
    </div>;


    return <CustomModal title={t("drivebox.eventsmodal.title")}
                        action="none"
                        contents={[content]}
                        onClose={onClose}/>

};

export default PluggedEventsModal;
