import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form } from "antd";

import DriveboxTable from "./MandatorAddModalDriveboxesTable";
import WizardModal from "../../common/modals/wizard-modal/WizardModal";
import AddressSelect from "../../common/selects/specificSelects/AddressSelect";
import PackageSelect from "../../common/selects/specificSelects/PackageSelect";

import { addressToStr } from "../../../managers/locationManager";

import {
  requestAddMandator,
  requestMandatorDriveboxesByImei,
} from "../../../actions/actionMandators";

import { clearError, setError } from "../../../actions/actionCommon";
import FormBuilder from "../../../managers/formBuilder";
import { Input, InputNumber, Switch } from "antd";
import Label from "../../common/views/Label";
import { WIZARD_TYPES } from "../../common/modals/wizard-modal/WizardModal";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { useWizardSteps } from "../../../hooks/useModalStatus";
import { useValidateForm } from "../../../hooks/useValidateForm";

const { TextArea } = Input;

const DEFAULT_INPUT_VALUES = {
  name: null,
  packages: undefined,
  description: null,
  isTester: false,
  companyName: null,
  address: null,
  firstName: null,
  lastName: null,
  email: null,
  canShowTracks: true,
  canMergeSplitTrips: true,
  liveLocationIntervalInSeconds: 30,
  imei: null,
};

const MandatorAddModal = (props) => {
  const { onClose, driveboxes, error } = props;
  const [form] = Form.useForm();
  const [newMandator, setNewMandator] = useState(null);
  const [driveboxData, setDriveboxData] = useState([]);
  const { handleInputChange, fields, setFields } =
    useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { step, nextStep, prevStep } = useWizardSteps();
  const { submittable } = useValidateForm(form);
  const {
    name,
    packages,
    description,
    isTester,
    companyName,
    address,
    firstName,
    lastName,
    email,
    canShowTracks,
    canMergeSplitTrips,
    liveLocationIntervalInSeconds,
    imei,
    sendWelcomeMail,
  } = fields;

  useEffect(() => {
    if (error.status === 404) {
      props.setError({ message: "Could not find Drivebox in system" });
    }
  }, [error?.status]);

  useEffect(() => {
    let lastDrivebox = driveboxes[driveboxes?.length - 1];

    //isInDriveboxDataTable
    if (driveboxData?.some((data) => data.imei == lastDrivebox?.imei)) {
      props.setError({ message: "Already in table" });
      return;
    }

    if (lastDrivebox?.mandator) {
      props.setError({
        message:
          "Drivebox " +
          lastDrivebox?.imei +
          " is already in mandator " +
          lastDrivebox?.mandator.name,
      });
      return;
    }

    if (imei && driveboxes.length) {
      setDriveboxData((prevDriveboxData) => [
        ...prevDriveboxData,
        {
          vehicleName: "Vehicle " + driveboxes.length,
          imei: lastDrivebox?.imei,
        },
      ]);
    }
  }, [driveboxes]);

  useEffect(() => {
    if (step === 5) {
      addANewMandator();
      onClose();
    }
  }, [step]);

  const handleDriveboxVehicleNameChange = (imei, newName) => {
    let newDriveboxData = driveboxData.map((data) => {
      if (data.imei === imei) {
        data.vehicleName = newName;
      }
      return data;
    });
    setDriveboxData(newDriveboxData);
  };

  const handleDriveboxVehicleRemove = (imei) => {
    let newDriveboxData = driveboxData.filter((data) => data.imei != imei);
    setDriveboxData(newDriveboxData);
  };

  const addANewMandator = () => {
    props.requestAddMandator({
      ...newMandator,
      driveboxes: driveboxData,
    });
  };

  const handleAddDrivebox = (event) => {
    let imei = event.target.value;

    if (imei.length >= 15) {
      props.requestMandatorDriveboxesByImei(imei);
      setFields({ ...fields, imei: "" });
    } else {
      setFields({ ...fields, imei: imei });
    }
  };

  const handleNext = () => {
    setNewMandator({ ...newMandator, ...fields });
    nextStep();
    props.clearError();
  };

  const handleBack = () => {
    prevStep();
  };

  const canBack = () => {
    return true;
  };

  const page1 = {
    title: "Schritt 1: Name und Details",
    inputs: [
      new FormBuilder.FormInput(
        "Mandatorname",
        (
          <Input
            value={name}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        )
      )
        .withValue(name)
        .withKey("name")
        .withValidationMessage("Mandatorname must be set")
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        "Package",
        (
          <PackageSelect
            value={packages}
            onChange={(value) => handleInputChange("packages", value)}
            multiple
          />
        )
      )
        .withValue(packages)
        .withKey("packages")
        .withValidationMessage("At least 1 package must be set")
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        "Description",
        (
          <TextArea
            value={description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            autosize={{ minRows: 3, maxRows: 5 }}
          />
        )
      )
        .withValue(description)
        .withKey("description")
        .build(),
      new FormBuilder.FormInput(
        "Tester",
        (
          <Switch
            checked={Boolean(isTester)}
            onChange={(value) => handleInputChange("isTester", value)}
          />
        )
      )
        .withKey("isTester")
        .withValue(Boolean(isTester))
        .withValuePropName("checked")
        .build(),
    ],
  };

  const page2 = {
    title: "Schritt 2: Kundendaten",
    inputs: [
      new FormBuilder.FormInput(
        "Companyname",
        (
          <Input
            value={companyName}
            onChange={(e) => handleInputChange("companyName", e.target.value)}
          />
        )
      )
        .withValue(companyName)
        .withKey("companyName")
        .withValidationMessage("Companyname must be set")
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        "Address",
        (
          <AddressSelect
            value={address}
            onChange={(value) => handleInputChange("address", value)}
          />
        )
      )
        .withValue(address)
        .withKey("address")
        .withValidationMessage("Address must be set")
        .isRequired()
        .build(),
    ],
  };

  const page3 = {
    title: "Schritt 3: Administrator",
    inputs: [
      new FormBuilder.FormInput(
        "Vorname",
        (
          <Input
            value={firstName}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />
        )
      )
        .withValue(firstName)
        .withKey("firstName")
        .withValidationMessage("Firstname must be set")
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        "Nachname",
        (
          <Input
            value={lastName}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />
        )
      )
        .withValue(lastName)
        .withKey("lastName")
        .withValidationMessage("Lastname must be set")
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        "Email",
        (
          <Input
            value={email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        )
      )
        .withValue(email)
        .withKey("email")
        .withType("email")
        .withValidationMessage("Email must be set")
        .isRequired()
        .build(),
    ],
  };
  const page4 = {
    title: "Schritt 4: Settings",
    inputs: [
      new FormBuilder.FormInput(
        "Show tracks",
        (
          <Switch
            checked={canShowTracks}
            onChange={(value) => handleInputChange("canShowTracks", value)}
          />
        )
      )
        .withKey("canShowTracks")
        .withValue(canShowTracks)
        .withValuePropName("checked")
        .build(),
      new FormBuilder.FormInput(
        "Merge and split",
        (
          <Switch
            checked={canMergeSplitTrips}
            onChange={(value) => handleInputChange("canMergeSplitTrips", value)}
          />
        )
      )
        .withKey("canMergeSplitTrips")
        .withValue(canMergeSplitTrips)
        .withValuePropName("checked")
        .build(),
      new FormBuilder.FormInput(
        "LiveLocationinterval",
        (
          <InputNumber
            value={liveLocationIntervalInSeconds}
            onChange={(value) =>
              handleInputChange("liveLocationIntervalInSeconds", value)
            }
          />
        )
      )
        .withKey("liveLocationIntervalInSeconds")
        .withValue(liveLocationIntervalInSeconds)
        .build(),
    ],
  };

  const page5 = {
    title: "Schritt 5: DriveBoxen zuordnen (Optional)",
    inputs: [
      new FormBuilder.FormInput(
        "Imei",
        <Input value={imei} onChange={handleAddDrivebox} />
      )
        .withValue(imei)
        .withKey("imei")
        .build(),
      new FormBuilder.FormInput(
        "DriveBox Barcode",
        (
          <DriveboxTable
            data={driveboxData}
            onNameChange={handleDriveboxVehicleNameChange}
            onRemove={handleDriveboxVehicleRemove}
          />
        )
      ).build(),
    ],
  };

  const page6 = {
    title: "Schritt 5: Zusammenfassung",
    inputs: [
      new FormBuilder.FormInput("Name", <Label value={name} />)
        .withValue(name)
        .viewOnly()
        .build(),
      new FormBuilder.FormInput("Package", <Label value={packages} />)
        .withValue(packages)
        .viewOnly()
        .build(),
      new FormBuilder.FormInput("Company name", <Label value={companyName} />)
        .withValue(companyName)
        .viewOnly()
        .build(),
      new FormBuilder.FormInput("Address", <Label value={address} />)
        .withValue(addressToStr(address))
        .viewOnly()
        .build(),
      new FormBuilder.FormInput(
        "Administrator",
        <Label value={firstName + " " + lastName + " , " + email} />
      )
        .withValue(firstName + " " + lastName + " , " + email)
        .viewOnly()
        .build(),
      new FormBuilder.FormInput(
        "Driveboxen",
        <Label value={driveboxData.length} />
      )
        .withValue(driveboxData.length)
        .viewOnly()
        .build(),
      new FormBuilder.FormInput(
        "Welcome Mail",
        (
          <Switch
            checked={sendWelcomeMail}
            onChange={(value) => handleInputChange("sendWelcomeMail", value)}
          />
        )
      )
        .withValue(sendWelcomeMail)
        .withKey("sendWelcomeMail")
        .withValuePropName("checked")
        .viewOnly()
        .build(),
    ],
  };

  const pages = [page1, page2, page3, page4, page5, page6];
  return (
    <WizardModal
      onClose={onClose}
      active
      canBack={canBack}
      onNext={handleNext}
      onBack={handleBack}
      title="New Mandator Wizard"
      submittable={submittable}
      form={form}
      pages={pages}
      step={step}
      wizardType={WIZARD_TYPES.mandator.name}
      nextButtonText={step === 4 ? "Abschließen" : "Next"}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestAddMandator,
      requestMandatorDriveboxesByImei,
      setError,
      clearError,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    mandator: state.mandators.current,
    driveboxes: state.mandators.current.driveboxes,
    error: state.error,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(MandatorAddModal);
