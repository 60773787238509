import React, {Component} from "react";
import PropTypes from "prop-types";
import GenericTable from "../../common/table/GenericTable";

class MandatorSuperUserTable extends Component {
    render() {
        const {users, onInlineEdit, onDelete, onChangePassword} = this.props;

        const columnInformations = [
            {title: "Email", key: "email"},
            {title: "Firstname", key: "firstName"},
            {title: "Lastname", key: "lastName"},
        ];

        const actions = [
            {title: "Edit", event: onInlineEdit},
            {title: "Change Password", event: onChangePassword},
            {title: "Delete", event: onDelete},
        ];
        return (
            <GenericTable
                data={users}
                onEdit={onInlineEdit}
                columnInformations={columnInformations}
                actions={actions}
                rowCount={users.length}
                showActions
            />
        );
    }
}

// MandatorSuperUserTable.propTypes = {
//     users: PropTypes.array.isRequired,
//     onInlineEdit: PropTypes.func.isRequired,
//     onDelete: PropTypes.func.isRequired,
//     onChangePassword: PropTypes.func.isRequired,
// };

export default MandatorSuperUserTable;
