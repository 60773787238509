import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {requestAddCostcenter} from "../../../actions/actionCostcenter";
import GenericCSVInsertModal from "../../common/modals/GenericCSVInsertModal";

const CostCenterImportModal = props => {
    const {onClose, response, requestAddCostcenter} = props;

    const columnInformations = [
        { title: "Bezeichnung", key: "foreignId" },
        { title: "Beschreibung (optional)", key: "description" },
    ];

    return <GenericCSVInsertModal
        identifier="foreignId"
        columnInformations={columnInformations}
        response={response}
        onAdd={requestAddCostcenter}
        onClose={onClose}
        forCostcenter
    />;
};

// CostCenterImportModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestAddCostcenter }, dispatch);
}

function mapStateToProp(state) {
    return {
        response: state.costcenters.current,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(CostCenterImportModal);
