export const RESERVATION_ADD_MODAL_TYPES = {
  SET_INPUT_VALUE: "SET_INPUT_VALUE",
  SET_ALLOW_INPUT_CLEAR: "SET_ALLOW_INPUT_CLEAR",
  SET_STOPOVERS_NUMBER: "SET_STOPOVERS_NUMBER",
  SET_LAST_STOPOVER_INDEX: "SET_LAST_STOPOVER_INDEX",
  SET_IS_RESERVATION_CHANGED: "SET_IS_RESERVATION_CHANGED",
  SET_IS_VEHICLE_CHANGED: "SET_IS_VEHICLE_CHANGED",
  SET_NEW_RESERVATION: "SET_NEW_RESERVATION",
  SET_IS_NEXT_BUTTON_TOUCHED: "SET_IS_NEXT_BUTTON_TOUCHED",
  SET_STOPOVER_VALUES: "SET_STOPOVER_VALUES",
};
