import { AnyAction } from "redux";

import { receiveCostcenterById, receiveCostcenters, receiveAllCostcenters,
    receiveRoles,
    receiveAddCostcenter,
    receiveUpdateCostcenter,
    receiveUpdateCostcenters,
    selectCostcenter,
    selectAllCostcenter,
    deselectAllCostcenter,
    loadCostcenter,
    receiveDeleteCostcenter,
    receiveCostcenterToggleArchive,
    receiveCostcentersToggleArchive,
} from "../../actions/actionCostcenter";

import {
  addEntity,
  deleteEntity,
  deleteEntities,
  deselectAllEntities,
  getEntities,
  getEntityById,
  getPagedEntities,
  loadEntities,
  selectAllEntities,
  updateEntities,
  updateEntity,
  updateSelection,
  getAllPagedEntities,
} from "../../managers/reducerManager";

import { UserResponseInfo } from "../../types/user/userResponse.types";
import { CostcenterResponseInfo } from "../../types/costcenter/costcenterResponse.types";
import { RolesResponse } from "../../types/global.types";


export type CostcenterState = {
    readonly current: CostcenterResponseInfo,
    readonly me : UserResponseInfo,
    readonly entities: CostcenterResponseInfo[],
    readonly allEntities: CostcenterResponseInfo[],
    readonly roles: RolesResponse[],
    readonly loading: boolean,
  };

const COSTCENTER_INITIAL_STATE: CostcenterState = {
  current: {} as CostcenterResponseInfo,
  me: {} as UserResponseInfo,
  entities: [],
  allEntities: [],
  roles: [],
  loading: true,
};

export const costcentersDetail = (state = COSTCENTER_INITIAL_STATE, action: AnyAction) => {
    if(receiveCostcenterById.match(action)) {
        return getEntityById(state, action.data);
    }

    if(receiveCostcenters.match(action)) {
        return getPagedEntities(state, action.data);
    }

    if(receiveAllCostcenters.match(action)) {
        return getAllPagedEntities(state, action.data);
    }

    if(receiveRoles.match(action)) {
        return { ...state, roles: action.data };
    }

    if(receiveAddCostcenter.match(action)) {
        return getEntityById(state, action.data);
    }

    if(receiveUpdateCostcenter.match(action)) {
        return updateEntity(state, action.data);
    }

    if(receiveUpdateCostcenters.match(action)) {
        return updateEntities(state, action.data);
    }

    if(selectCostcenter.match(action)) {
        return updateSelection(state, action.data); 
    }

    if(selectAllCostcenter.match(action)) {
        return selectAllEntities(state);
    }

    if(deselectAllCostcenter.match(action)) {
        return deselectAllEntities(state);
    }

    if(loadCostcenter.match(action)) {
        return loadEntities(state);
    }

    if(receiveDeleteCostcenter.match(action) || receiveCostcenterToggleArchive.match(action)) {
        return deleteEntity(state, action.data);
    }

    if(receiveCostcentersToggleArchive.match(action)) {
        return deleteEntities(state, action.data);
    }
  
    return state;
};
