import { AnyAction } from "redux";

import {
  receiveLogout,
  receiveUnAuthoriseUser,
} from "../actions/actionAuthentication";

import {
  setFilter,
  setText,
  setTextArray,
  clearFilter,
  updateFilterAPICall,
  updateInitial,
} from "../actions/actionFilter";

// these any types later should change to a more specific types
export type ReportState = {
  readonly api: any;
  readonly initial: any;
  readonly text: string;
  readonly active: boolean;
  readonly updatedAt?: number;
};

const FILTER_INITIAL_STATE: ReportState = {
  api: {},
  initial: {},
  text: "",
  active: false,
  updatedAt: undefined,
};

export const filter = (state = FILTER_INITIAL_STATE, action: AnyAction): ReportState => {
  if (setFilter.match(action)) {
    return {
      ...state,
      api: { ...state.api, ...action.data.api },
      initial: { ...state.initial, ...action.data.initial },
      text: action.data.text || state.text,
      active: true,
      updatedAt: Date.now(),
    };
  }

  if (setText.match(action)) {
    return { ...state, text: action.data };
  }

  if (setTextArray.match(action)) {
    return { ...state, text: action.data.join(" | ") };
  }

  if (clearFilter.match(action)) {
    return FILTER_INITIAL_STATE;
  }

  if (updateFilterAPICall.match(action)) {
    return {
      ...state,
      api: { ...state.api, ...action.data },
      active: true,
      updatedAt: Date.now(),
    };
  }

  if (updateInitial.match(action)) {
    return {
      ...state,
      initial: { ...state.initial, ...action.data },
      active: true,
      updatedAt: Date.now(),
    };
  }

  if (receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
    return FILTER_INITIAL_STATE;
  }

  return state;
};
