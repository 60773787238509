import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Select} from "antd";


const MultiSelect = ({placeholder, array, selectedArray, handleChange, handleSearch, disabled, customMaxTagCount, customMinWidth}) => {
    const newCustomMaxTagCount = customMaxTagCount ? customMaxTagCount : 1000;
    
    return (
        <Select
            mode="multiple"
            placeholder={placeholder}
            value={selectedArray}
            onSearch={handleSearch}
            onChange={handleChange}
            style={{width: "100%", minWidth: customMinWidth }}
            disabled={disabled}
            autoClearSearchValue
            filterOption={false}
            maxTagCount={newCustomMaxTagCount}
        >
            {array.length && array.map(item => {
                    return <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                }
            )}
        </Select>
    );
};

// MultiSelect.propTypes = {
//     placeholder: PropTypes.object.isRequired,
//     array: PropTypes.array.isRequired,
//     selectedArray: PropTypes.array,
//     handleChange: PropTypes.func.isRequired,
//     handleSearch: PropTypes.func,
//     disabled: PropTypes.bool,
// };

export default MultiSelect;
