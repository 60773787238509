import React from "react"

const FuelIcon = ({color="#757575", size="31"}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0078 0L20.6016 1.64286L23.2734 4.14286C23.3549 4.22563 23.466 4.33644 23.5547 4.42857L22.9922 5.07143C22.0917 5.98621 22.7378 7.17013 23.0625 7.5L24.75 9.21429V22.8571C24.75 25.1429 23.8677 25.1429 23.625 25.1429C23.3823 25.1429 22.5 25.1429 22.5 22.8571V13.7143C22.5 10.7865 20.25 9.14286 18 9.14286V4.57143C18 3.31131 17.0444 2.28571 15.75 2.28571H2.25C0.901703 2.28571 0 3.25652 0 4.57143V32H18V11.4286C18 11.4286 20.25 11.4286 20.25 13.7143V22.8571C20.25 27.4286 23.0393 27.4286 23.625 27.4286C24.2452 27.4286 27 27.4286 27 22.8571V6.85714C27 4.57143 26.3565 4.09322 25.5938 3.35714L22.0078 0ZM2.25 6.85714H15.75V13.7143H2.25V6.85714Z" fill={color}/>
        </svg>
    )
}

export default FuelIcon;