import {useState} from "react";

export const usePagination = (initRowsPerPage) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage || 10);

    const changePage = (e, p) => setPage(p);
    const changeRowPerPage = (e) => {
        setPage(0)
        setRowsPerPage(e.target.value);
    };
    return {page, rowsPerPage, changePage, changeRowPerPage};
};

export const maxRowsPerPage = 999;
export const minRowsPerPage = 5;

