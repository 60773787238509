import { AnyAction } from "redux";

import {
  deleteEntity,
  getEntityById,
  getPagedEntities,
  loadEntities,
  selectAllEntities,
  updateEntity,
  updateSelection,
} from "../../managers/reducerManager";

import { MandatorDriveboxHistoryResponse } from "../../types/mandator/mandatorResponse.types";

import {
  receiveDriveboxes,
  receiveDriveboxById,
  receiveUpdateDrivebox,
  selectDrivebox,
  selectAllDriveboxes,
  loadDrivebox,
  receivePluggedEvents,
} from "../../actions/actionDriveboxes";
import {
  receiveMandatorDriveBoxHistory,
  receiveMandatorDriveboxes,
  receiveAddMandatorDriveboxes,
  receiveUpdateMandatorDrivebox,
  selectMandatorDrivebox,
  selectAllMandatorDriveboxes,
  receiveMandatorDriveboxToggleArchive,
  receiveVehicleRegistration,
  receiveDriveBoxLastSignal,
  receiveLastLogIns,
  loadDriveBoxHistory,
} from "../../actions/actionMandators";
import {
  DriveBoxResponseInfo,
  DriveBoxPluggedEventsResponse,
} from "../../types/drivebox/driveboxResponse.types";

import {
  MandatorVehicleRegistrationResponse,
  MandatorDriveboxLastSignalResponse,
  MandatorLastLoginResponse,
} from "../../types/mandator/mandatorResponse.types";

import {
  receiveLogout,
  receiveUnAuthoriseUser,
} from "../../actions/actionAuthentication";

export type DriveboxState = {
  readonly current: DriveBoxResponseInfo;
  readonly entities: DriveBoxResponseInfo[];
  readonly events: DriveBoxPluggedEventsResponse[];
  readonly loading: boolean;
  readonly pageLoading: boolean;
  readonly driveBoxHistory: MandatorDriveboxHistoryResponse[];
  readonly vehicleRegistration: MandatorVehicleRegistrationResponse[];
  readonly lastSignal: MandatorDriveboxLastSignalResponse;
  readonly lastLogIns: MandatorLastLoginResponse;
};

const DRIVEBOX_INITIAL_STATE: DriveboxState = {
  current: {} as DriveBoxResponseInfo,
  entities: [],
  events: [],
  loading: true,
  pageLoading: true,
  driveBoxHistory: [],
  vehicleRegistration: [],
  lastSignal: {} as MandatorDriveboxLastSignalResponse,
  lastLogIns: {} as MandatorLastLoginResponse,
};

export const driveboxesDetail = (
  state = DRIVEBOX_INITIAL_STATE,
  action: AnyAction
) => {
  if (
    receiveDriveboxes.match(action) ||
    receiveMandatorDriveboxes.match(action)
  ) {
    return getPagedEntities(state, action.data);
  }

  if (
    receiveDriveboxById.match(action) ||
    receiveAddMandatorDriveboxes.match(action)
  ) {
    return getEntityById(state, action.data);
  }

  if (
    receiveUpdateDrivebox.match(action) ||
    receiveUpdateMandatorDrivebox.match(action)
  ) {
    return updateEntity(state, action.data);
  }

  if (selectMandatorDrivebox.match(action) || selectDrivebox.match(action)) {
    return updateSelection(state, action.data);
  }

  if (
    selectAllDriveboxes.match(action) ||
    selectAllMandatorDriveboxes.match(action)
  ) {
    return selectAllEntities(state);
  }

  if (loadDrivebox.match(action)) {
    return loadEntities(state);
  }

  if (receiveMandatorDriveboxToggleArchive.match(action)) {
    return deleteEntity(state, action.data);
  }

  if (receivePluggedEvents.match(action)) {
    return { ...state, events: action.data };
  }

  if (loadDriveBoxHistory.match(action)) {
    return { ...state, loading: true };
  }

  if (receiveMandatorDriveBoxHistory.match(action)) {
    return { ...state, driveBoxHistory: action.data, loading: false };
  }

  if (receiveVehicleRegistration.match(action)) {
    return { ...state, vehicleRegistration: action.data, loading: false };
  }

  if (receiveDriveBoxLastSignal.match(action)) {
    return { ...state, lastSignal: action.data, loading: false };
  }

  if (receiveLastLogIns.match(action)) {
    return { ...state, lastLogIns: action.data, loading: false };
  }

  if (receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
    return DRIVEBOX_INITIAL_STATE;
  }

  return state;
};
