import React from "react";
import LoadingIndicator from "../LoadingIndicator";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";


function LoadingContainer(props) {
    const { active } = props;
    const { t } = useTranslation();

    if (active) return props.children();
    else
        return (
            <Grid container justify="center" direction="column" alignItems="center">
                <Grid>
                    <LoadingIndicator/>
                </Grid>
                <Grid>
                    <h4>{t("common.loading")}</h4>
                </Grid>
            </Grid>
        );
}

export default LoadingContainer;
