import React, { useState } from "react";
import { Button, Grid, Popover, Typography } from "@material-ui/core";

function CarChip(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const chunk = props.chunk;
  const color = props.color;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let popover = "";
  if (chunk) {
    popover = (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 1 }}>
          Charging period: {chunk?.chunkStartTime} - {chunk?.chunkEndTime}
        </Typography>
        <Typography sx={{ p: 1 }}>
          Chunk charge in KW/h: {chunk?.chunkChargeInKW} KW/h
        </Typography>
        <Typography sx={{ p: 1 }}>
          Chunk charge in KM: {chunk?.chunkChargeInKM} KM
        </Typography>
        <Typography sx={{ p: 1 }}>
          Total charge in KM: {chunk?.currentTotal} KM
        </Typography>
        <Typography sx={{ p: 1 }}>
          Chunk throughput: {chunk?.throughput} KW/h
        </Typography>
      </Popover>
    );
  }

  return (
    <>
      <Grid item style={{ marginBottom: "10px" }}>
        {
          <Button
            variant="contained"
            style={{
              backgroundColor: color,
              width: "10px",
              marginRight: "0px",
              height: "42px",
            }}
            onClick={(event) => handleClick(event)}
          >
            {chunk?.currentTotalKW ?? "-"}
          </Button>
        }
      </Grid>
      {popover}
    </>
  );
}

export default CarChip;
