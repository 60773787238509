import { AnyAction } from "redux";

import {
  receiveUserById,
  receiveResetPassword,
  receiveCurrentlyLoggedinUser,
  receiveUpdateUserMandator,
  receiveResetMomentPassword,
  receiveUsers,
  receiveAllUsers,
  receiveRoles,
  receiveAddUser,
  receiveUpdateUser,
  receiveUpdateUsers,
  selectUser,
  selectAllUser,
  deselectAllUser,
  loadUser,
  receiveDeleteUser,
  receiveUserToggleArchive,
  receiveUsersToggleArchive,
} from "../../actions/actionUser";

import {
  receiveAuthentication,
  receiveLogout,
  receiveUnAuthoriseUser,
} from "../../actions/actionAuthentication";

import { receiveAvailableDrivers } from "../../actions/actionReservation";

import {
  addEntity,
  deleteEntity,
  deleteEntities,
  deselectAllEntities,
  getEntities,
  getEntityById,
  getPagedEntities,
  loadEntities,
  selectAllEntities,
  updateEntities,
  updateEntity,
  updateSelection,
  getAllPagedEntities,
} from "../../managers/reducerManager";
import { resetSessionLocalStorage } from "../../managers/localStorageManager";

import {
  receiveMandatorSuperUsers,
  receiveAddMandatorUsers,
  receiveMandatorSurrogate,
  receiveMandatorUserSurrogate,
  receiveMandatorUsers,
  receiveAddMandatorSuperUser,
  receiveUpdateMandatorSuperUser,
  receiveDeleteMandatorSuperUser,
} from "../../actions/actionMandators";

import {
  UserResponse,
  UserResponseInfo,
} from "../../types/user/userResponse.types";
import { RolesResponse } from "../../types/global.types";

export type UserState = {
  readonly current: UserResponseInfo;
  readonly me: UserResponseInfo;
  readonly entities: UserResponseInfo[];
  readonly allEntities: UserResponseInfo[];
  readonly roles: RolesResponse[];
  readonly loading: boolean;
  readonly reservation: UserResponse;
};

const USER_INITIAL_STATE: UserState = {
  current: {} as UserResponseInfo,
  me: {} as UserResponseInfo,
  entities: [],
  allEntities: [],
  roles: [],
  loading: true,
  reservation: {
    entities: [],
    total: 0,
  },
};

export const usersDetail = (state = USER_INITIAL_STATE, action: AnyAction) => {
  if (receiveMandatorSuperUsers.match(action)) {
    return getEntities(state, action.data);
  }

  if (receiveUserById.match(action) || receiveAddMandatorUsers.match(action)) {
    return getEntityById(state, action.data);
  }

  if (receiveResetPassword.match(action)) {
    return { ...state, me: { loggedIn: true } };
  }

  if (
    receiveAuthentication.match(action) ||
    receiveCurrentlyLoggedinUser.match(action) ||
    receiveUpdateUserMandator.match(action) ||
    receiveResetMomentPassword.match(action) ||
    receiveMandatorSurrogate.match(action) ||
    receiveMandatorUserSurrogate.match(action)
  ) {
    resetSessionLocalStorage();
    return { ...state, me: action.data };
  }

  if (receiveAvailableDrivers.match(action)) {
    return {
      ...state,
      reservation: {
        entities: action.data.entities,
        total: action.data.total,
      },
    };
  }

  if(receiveUsers.match(action) || receiveMandatorUsers.match(action)) {
      return getPagedEntities(state, action.data);
  }

  if (receiveAllUsers.match(action)) {
    return getAllPagedEntities(state, action.data);
  }

  if (receiveRoles.match(action)) {
    return { ...state, roles: action.data };
  }

  if(receiveAddUser.match(action) || receiveAddMandatorSuperUser.match(action)) {
      return addEntity(state, action.data);
  }

  if(receiveUpdateUser.match(action) || receiveUpdateMandatorSuperUser.match(action)) {
      return updateEntity(state, action.data);
  }

  if (receiveUpdateUsers.match(action)) {
    return updateEntities(state, action.data);
  }

  if (selectUser.match(action)) {
    return updateSelection(state, action.data);
  }

  if (selectAllUser.match(action)) {
    return selectAllEntities(state);
  }

  if (deselectAllUser.match(action)) {
    return deselectAllEntities(state);
  }

  if (loadUser.match(action)) {
    return loadEntities(state);
  }

  if(receiveDeleteUser.match(action) || receiveDeleteMandatorSuperUser.match(action) || receiveUserToggleArchive.match(action)) {
      return deleteEntity(state, action.data);
  }

  if (receiveUsersToggleArchive.match(action)) {
    return deleteEntities(state, action.data);
  }

  if (receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
    return USER_INITIAL_STATE;
  }

  return state;
};
