import React, {Component} from "react";
import PropTypes from "prop-types";
import {Select} from "antd";

const {Option} = Select;

class MailsInputField extends Component {
    render() {
        const {onMailChange, options} = this.props;
        return (
            <Select mode="tags" style={{width: "100%"}} placeholder="Emails" onChange={onMailChange}>
                {options.map(mail => (
                    <Option key={mail}>{mail}</Option>
                ))}
            </Select>
        );
    }
}

// MailsInputField.propTypes = {
//     onMailChange: PropTypes.func.isRequired,
//     options: PropTypes.array,
// };

export default MailsInputField;
