import { AnyAction } from "redux";

import {
  getPagedEntities,
  getEntityById,
  addEntity,
  updateEntity,
  deleteEntity,
} from "../../managers/reducerManager";

import { receiveMandators,
    receiveMandatorDriveboxesByImei,
    receiveMandatorById,
    receiveAddMandator,
    receiveUpdateMandator,
    receiveMandatorToggleArchive,
    receiveDeleteMandator,
} from "../../actions/actionMandators";

import { receiveLogout, receiveUnAuthoriseUser } from "../../actions/actionAuthentication";

import { MandatorResponseInfo } from "../../types/mandator/mandatorResponse.types";

type CurrentMandator = MandatorResponseInfo & {driveboxes: MandatorResponseInfo[]};

export type MandatorState = {
  readonly current: CurrentMandator;
  readonly entities: MandatorResponseInfo[];
};

const MANDATOR_INITIAL_STATE: MandatorState = {
  current: { driveboxes: [] as MandatorResponseInfo[]} as CurrentMandator,
  entities: [],
};

export const mandatorsDetail = (state = MANDATOR_INITIAL_STATE, action: AnyAction) => {

    if(receiveMandators.match(action)) {
        return getPagedEntities(state, action.data);
    }

    if(receiveMandatorDriveboxesByImei.match(action)) {
        return {
            ...state,
            current: {
              ...state.current,
              driveboxes: [...state.current.driveboxes, action.data],
            },
          };
    }

    if(receiveMandatorById.match(action)) {
        return getEntityById(state, action.data);
    }

    if(receiveAddMandator.match(action)) {
        return addEntity(state, action.data);
    }

    if(receiveUpdateMandator.match(action)) {
        return updateEntity(state, action.data);
    }   

    if(receiveMandatorToggleArchive.match(action) || receiveDeleteMandator.match(action)) {
        return deleteEntity(state, action.data);
    }

    if(receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) { 
        return MANDATOR_INITIAL_STATE;
    }
  
    return state;
};
