import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from "@material-ui/core/Tooltip";

import {
    deselectAllCostcenter,
    requestDeleteCostcenter,
    requestCostcenters,
    requestCostcenterToggleArchive,
    requestCostcentersToggleArchive,
    selectAllCostcenter,
    selectCostcenter
} from "../../actions/actionCostcenter"; 

import {setTextArray, updateFilterAPICall} from "../../actions/actionFilter";

import {menu} from "../../managers/pathManager";
import FilterToolbar from "../common/toolbar/FilterToolbar";

import CostCenterModal from "./costcenterModals/CostCenterModal";
import CostCenterBulkEditModal from "./costcenterModals/CostCenterBulkEditModal";
import CostCenterAddModal from "./costcenterModals/CostCenterAddModal";
import ArchiveModal from "../common/modals/ArchiveModal";
import Page from "../common/container/Page";
import ToolbarAdmin from "../common/toolbar/ToolbarAdmin";

import CostCenterTable from "./CostCenterTable";

import {useModalStatus} from "../../hooks/useModalStatus";
import {usePagination} from "../../hooks/usePagination";
import {makeStyles} from "@material-ui/core";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import CostCenterImportModal from "./costcenterModals/CostCenterImportModal";

const useStyles = makeStyles(theme => ({
    
    root: {
        flexGrow: 1,
        margin: "-110px 5px -12.5px 5px", 
        borderRadius: "5px",
    },
    contentContainer: {
        margin: "75px 5px"
    }
}));

const CostCenterListPage = props => {
    const dispatch = useDispatch();
    
    const [archive, setArchive] = useState(false);
    const [costcenter, setCostcenter] = useState(undefined);
    const {page, rowsPerPage, changePage, changeRowPerPage} = usePagination();
    const { modal: editModal, open: openEditModal, close: closeEditModal } = useModalStatus();
    const { modal: bulkEditModal, open: openBulkEditModal, close: closeBulkEditModal } = useModalStatus();
    const {modal: addModal, open: openAddModal, close: closeAddModal} = useModalStatus();
    const {modal: importModal, open: openImportModal, close: closeImportModal} = useModalStatus();
    const {modal: archiveModal, open: openArchiveModal, close: closeArchiveModal} = useModalStatus();
    const {modal: bulkArchiveModal, open: openBulkArchiveModal, close: closeBulkArchiveModal} = useModalStatus();
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const classes = useStyles();

    const { filter, selectCostcenter, selectAllCostcenter, costcenters, selected, me } = props;
    const {api, initial, updatedAt} = filter;

    useEffect(() => {
        requestCostcenters();
    }, [updatedAt, archive, page, rowsPerPage]);

    function handleSearch(searchTerm) {
        props.updateFilterAPICall({ searchTerm }, false);
        changePage(null, 0);
    }

    function requestCostcenters() {
        props.requestCostcenters({...filter.api, isArchived: archive, page: page + 1, perPage: rowsPerPage});
    }

    const actionButtons = [];
    const bulkActionButtons = [];

    actionButtons.push({
        icon: <FontAwesomeIcon >far fa-plus</FontAwesomeIcon>,
        translateKey: "costCenter.page.add",
        onClick: openAddModal,
    });

    actionButtons.push({
        icon: <FontAwesomeIcon minWidth={26} fontSize={17}>far fa-file-import</FontAwesomeIcon>,
        translateKey: "costCenter.page.import",
        onClick: openImportModal,
    });
        
    /*bulkActionButtons.push({
        icon: <FontAwesomeIcon>far fa-archive</FontAwesomeIcon>,
        translateKey: archive ? "user.page.bulkunarchive" : "user.page.bulkarchive",
        onClick: () => archive ?
            dispatch(requestCostcentersToggleArchive({
                ids: costcenters.filter(v => v.isSelected).map(v => v.id)
            })) : openBulkArchiveModal()
    });*/

    const toggles = [
        {
            icon: <FontAwesomeIcon fontSize={19} 
                                   color={archive ? "secondary" : undefined}>
                far fa-donate</FontAwesomeIcon>,
            translateKey: "costCenter.page.costCenters",
            active: !archive,
            onClick: () => setArchive(false),
        },
        {
            icon: <FontAwesomeIcon fontSize={19}
                                   color={!archive ? "secondary" : undefined}>
                far fa-archive</FontAwesomeIcon>,
            translateKey: "user.page.archive",
            active: archive,
            onClick: () => setArchive(true),
        },
    ];

    const toolbar = (
        <ToolbarAdmin
            numberSelected={selected}
            toggles={toggles}
            actionButtons={actionButtons}
            bulkActionButtons={bulkActionButtons}
            onSearch={handleSearch}
            onDeselect={props.deselectAllCostcenter}
        />
    );

    const costCenterInformation =  <Tab aria-label="donate" component={Link} to={"/costcenters"} label="Kostenstellenverwaltung" />; 
                                                                                                     
    return (
        <Page activePage={menu.costcenters.key} toolbar={toolbar} disableTesterToolbar>
            
            <Paper square className={classes.root}>
                <Tabs
                    className={classes.tab}
                    value={value}
                    onChange={handleChange}   
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon tabs example">

                    <Tab aria-label="foldertree" component={Link} to={"/groups"} label="Gruppenverwaltung" /> 
                    {costCenterInformation}    
                </Tabs>
            </Paper>
            <FilterToolbar /> 
            <div className={classes.contentContainer}>
                <CostCenterTable
                    page={page}
                    rowsPerPage={rowsPerPage}
                    archive={archive}
                    onSelect={selectCostcenter}
                    onSelectAll={selectAllCostcenter}
                    onPageChange={changePage}
                    onRowsPerPageChange={changeRowPerPage}
                    onEdit={costcenter => {
                        setCostcenter(costcenter);
                        openEditModal();
                    }}
                    onArchive={costcenter => {
                        setCostcenter(costcenter);
                        openArchiveModal();
                    }}
                    onUnArchive={costcenter => {
                        setCostcenter(costcenter);
                        props.requestCostcenterToggleArchive({id: costcenter.id});
                    }}
                />
            </div>
            {editModal && <CostCenterModal costcenter={costcenter} onClose={closeEditModal} />}
            {bulkEditModal && <CostCenterBulkEditModal onClose={closeBulkEditModal} />}
            {addModal && <CostCenterAddModal onClose={closeAddModal}/>}
            {importModal && <CostCenterImportModal onClose={closeImportModal}/>}
        
            {archiveModal && (
                <ArchiveModal
                    title="Archivieren"
                    onClose={closeArchiveModal}
                    onArchive={comment => {
                        props.requestCostcenterToggleArchive({id: costcenter.id, comment});
                    }}
                />
            )}
            {bulkArchiveModal && <ArchiveModal
                title="Costcenter"
                onClose={closeBulkArchiveModal}
                onArchive={comment => {
                    dispatch(requestCostcentersToggleArchive({
                        ids: costcenters.filter(v => v.isSelected).map(v => v.id),
                        comment
                    }))
                }}
            />}
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestCostcenters,
            requestDeleteCostcenter,
            requestCostcenterToggleArchive,
            requestCostcentersToggleArchive,
            selectCostcenter,
            selectAllCostcenter,
            deselectAllCostcenter,
            setTextArray,
            updateFilterAPICall,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        selected: state.costcenters.selected,
        costcenters: state.costcenters.entities,
        me: state.costcenters.me,
        filter: state.filter,
    };
}
// CostCenterListPage.propTypes = {};

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(CostCenterListPage);
