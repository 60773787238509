import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import DriveboxTable from "../../mandators/MandatorAddModalDriveboxesTable";

import LoadingContainer from "../../../common/container/LoadingContainer";
import EditModal from "../../../common/modals/CustomModal";
import InputField from "../../../common/inputs/InputField";
import MandatorSelect from "../../../common/selects/specificSelects/MandatorSelect";

import {
  requestAddMandatorDriveboxes,
  requestMandatorDriveboxesByImei,
} from "../../../../actions/actionMandators";
import { clearError, setError } from "../../../../actions/actionCommon";

const styles = {};

const MandatorDriveboxModal = (props) => {
  const { onClose, mandators, driveboxes, error, setError, clearError } = props;
  const [driveboxData, setDriveboxData] = useState([]);
  const [mandator, setMandator] = useState(null);
  const [imei, setImei] = useState(null);

  useEffect(() => {
    if (error.status === 404)
      setError({ message: "Could not find Drivebox in system" });
    else clearError();
  }, [error?.status]);

  useEffect(() => {
    if (driveboxes?.length) {
      let lastDrivebox = driveboxes[driveboxes.length - 1];
      if (driveboxData.some((data) => data.imei == lastDrivebox?.imei)) {
        setError({ message: "Already in table" });
      }

      if (lastDrivebox?.mandator) {
        setError({
          message:
            "Drivebox " +
            lastDrivebox?.imei +
            " is already in mandator " +
            lastDrivebox?.mandator?.name,
        });
      }

      setDriveboxData([
        ...driveboxData,
        {
          vehicleName: "Vehicle " + driveboxes.length,
          imei: lastDrivebox?.imei,
        },
      ]);
    }
  }, [driveboxes]);

  const handleAddDrivebox = (event) => {
    let imei = event.target.value;
    if (imei.length >= 15) {
      props.requestMandatorDriveboxesByImei(imei);
      setImei("");
    } else {
      setImei(imei);
    }
  };

  const handleDriveboxVehicleNameChange = (imei, newName) => {
    let newDriveboxData = driveboxData.map((data) => {
      if (data.imei === imei) {
        data.vehicleName = newName;
      }
      return data;
    });

    setDriveboxData(newDriveboxData);
  };

  const handleDriveboxVehicleRemove = (imei) => {
    let newDriveboxData = driveboxData.filter((data) => data.imei != imei);
    setDriveboxData(newDriveboxData);
  };

  const handleMandatorChange = (value) => {
    setMandator(value);
  };

  const handleSave = () => {
    if (!mandator) {
      setError({ message: "Mandator is not allowed to be empty" });
      return;
    }

    if (driveboxData.length == 0) {
      setError({ message: "You must have at least 1 drivebox" });
      return;
    }
    props.requestAddMandatorDriveboxes({
      mandatorId: mandator,
      driveboxes: driveboxData,
    });
    onClose();
  };

  const generalDialogContent = (
    <LoadingContainer active={mandators}>
      {(data) => (
        <div>
          <MandatorSelect onChange={handleMandatorChange} value={mandator} />
          <InputField
            label="DriveBox Barcode"
            value={imei}
            onChange={handleAddDrivebox}
            shrink
          />
          <DriveboxTable
            data={driveboxData}
            onNameChange={handleDriveboxVehicleNameChange}
            onRemove={handleDriveboxVehicleRemove}
          />
        </div>
      )}
    </LoadingContainer>
  );

  const contents = [generalDialogContent];
  return (
    <EditModal
      action="confirm"
      onClose={onClose}
      onSave={handleSave}
      title="Drivebox zuordnung"
      contents={contents}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestMandatorDriveboxesByImei,
      requestAddMandatorDriveboxes,
      setError,
      clearError,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    mandators: state.mandators.entities,
    error: state.error,
    driveboxes: state.mandators.current.driveboxes,
  };
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MandatorDriveboxModal));
