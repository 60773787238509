import React from "react";
import PropTypes from "prop-types";
import DayPicker from "./DayPicker";
import WeekPicker from "./WeekPicker";
import MonthPicker from "./MonthPicker";
import YearPicker from "./YearPicker";
import PeriodSelect from "../specificSelects/PeriodSelect";
import { periods } from "../../../../models/Period";
import { fromToByPeriod } from "../../../../managers/timeManager";
import DateRangePicker from "./DateRangePicker";
import { Grid, withStyles } from "@material-ui/core";
import { DATE_PICKER_TYPE } from "./date-picker.utils";

const styles = theme => ({
    container: {
        marginTop: "0px",
        "white-space": "nowrap",
        "display": "flex",
        "alignItems": "center",
        "justify": "center",
    },
});

const PeriodDatePicker = props => {
    const { classes, minDate, startDate, endDate, period, onlySmallRange, disableFuture, middleRange, allowDateTime, datePickerType } = props;
    
    function renderPicker() {
        switch (period) {
            case periods.day.id:
                return <DayPicker onDateChange={handleRangeChange} value={startDate} minDate={minDate} disableFuture={disableFuture} />;
            case periods.week.id:
                return <WeekPicker onDateChange={handleRangeChange} value={startDate} minDate={minDate} disableFuture={disableFuture} />;
            case periods.month.id:
                return <MonthPicker onDateChange={handleRangeChange} value={startDate} minDate={minDate} disableFuture={disableFuture} />;
            case periods.year.id:
                return <YearPicker onDateChange={handleRangeChange} value={startDate} minDate={minDate} disableFuture={disableFuture} />;
            case periods.none.id:
                return <div style={{ width: 0 }}/>;
            default:
                return <DateRangePicker onDateChange={handleRangeChange} minDate={minDate} startDate={startDate} endDate={endDate} disableFuture={disableFuture} allowDateTime={allowDateTime} />;
        }
    }

    function handleRangeChange(startDay, endDay) {
        props.onDateChange(period, startDay, endDay);
    }

    function handlePeriodChange(period) {
        const defaultDate = fromToByPeriod(period);
        datePickerType && datePickerType === DATE_PICKER_TYPE.rawDataFilterDatePicker && (defaultDate.to = null );
        props.onDateChange(period, defaultDate.from, defaultDate.to);
    }

    return (
        <Grid container spacing={1}>
            <Grid item>
                <PeriodSelect onChange={handlePeriodChange}
                              value={period}
                              small={onlySmallRange}
                              middle={middleRange}
                              />
            </Grid>
            <Grid item>
                {renderPicker()}
            </Grid>
        </Grid> 
    );
};

PeriodDatePicker.defaultProps = {
    onlySmallRange: false,
    middleRange: false
  };

// PeriodDatePicker.propTypes = {
//     minDate: PropTypes.object,
//     startDate: PropTypes.object,
//     endDate: PropTypes.object,
//     period: PropTypes.number.isRequired,
//     onDateChange: PropTypes.func.isRequired,
//     onlySmallRange: PropTypes.bool,
//     disableFuture: PropTypes.bool,
// };

export default withStyles(styles)(PeriodDatePicker);
