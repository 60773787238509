import React, { useEffect, useState } from "react";
import EditModal from "../../common/modals/CustomModal";
import Map from "../../common/map/Map";
import { useSelector } from "react-redux";
import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import useTrackModalStyle from "./useTrackModalStyle";
import OriginDestinationTimeline from "../OriginDestinationTimeline";
import { useTranslation } from "react-i18next";

const OpenTrackModal = (props) => {
  const classes = useTrackModalStyle();
  const { onClose } = props;
  const trip = useSelector((state) => state.vehicles.openTrip);
  const error = useSelector((state) => state.error);
  const me = useSelector((state) => state.users.me);
  const { t } = useTranslation();

  const [showTrack, setShowTrack] = useState(me.canShowTracks);

  const track = trip.positions || [];

  const startEndTrack = [
    { latitude: trip.startLatitude, longitude: trip.startLongitude },
    { latitude: trip.destinationLatitude, longitude: trip.destinationLongitude },
  ];

  const mappedTrip = { ...trip };
  mappedTrip.positions = showTrack ? track : startEndTrack;
  mappedTrip.driver = trip.currentDriver;

  useEffect(() => {
    if (error.status === 404) {
      setShowTrack(false);
      props.clearError();
    }
  }, [error]);

  const viewContent = (
    <Grid container className={classes.innerContainer}>
      <Grid item xs={12}>
        <OriginDestinationTimeline trip={mappedTrip} showDetailInfo />
      </Grid>
    </Grid>
  );

  const leftColumn = viewContent;
  const map = <Map trips={[mappedTrip]} automaticHeight={false} showTrackStartStopMarker />;

  const contents = [(
    <Grid container>
      <Grid item xs={3} className={classes.leftColumn}>
        {leftColumn}
      </Grid>
      <Grid item xs={9} style={{ height: "100%" }}>
        {map}
      </Grid>
    </Grid>
  )];

  return (
    <EditModal
      largeModal
      onClose={onClose}
      title={t("openTrip.title", "Offene Fahrt")}
      contents={contents}
      className={classes.modal}
    />
  );
};

export default OpenTrackModal;
