import React from "react";
import {Alert} from "@material-ui/lab";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {format} from "date-fns";
import {getDateLocale} from "../../../../translations/customTranslation";
import PropTypes from "prop-types";

const ServiceInspectionAlert = props => {
    const {title, value, hasError, showInspection} = props;

    return <>
        {!hasError && <Alert severity={"info"}>
            <AlertTitle>{title}</AlertTitle>
            {showInspection && value && format(new Date(value), "MMMM yyyy", {locale: getDateLocale()})}
            {!showInspection && value}

        </Alert>}
        {hasError && <Alert severity={"error"}>
            <AlertTitle>Berechnung nicht möglich</AlertTitle>
            {showInspection && "Bitte tragen Sie zuerst Ihre Erstzulassung, die Art des Fahrzeuges und das Herstellungsland ein, damit eine Berechnung möglich ist."}
            {!showInspection && "Bitte tragen Sie ein nach wie vielen Kilometer das Fahrzeug zum Service muss"}
        </Alert>}

    </>
}

// ServiceInspectionAlert.propTypes = {
//     title: PropTypes.string,
//     showInspection: PropTypes.bool,
//     value: PropTypes.string,
//     hasError: PropTypes.bool,
// };

export default ServiceInspectionAlert;