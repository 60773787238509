import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import FormModal from "../common/modals/FormModal";
import FormBuilder from "../../managers/formBuilder";
import {requestDriveboxById, requestUpdateDrivebox} from "../../actions/actionDriveboxes";
import VehicleSelect from "../common/selects/specificSelects/VehicleSelect";
import Label from "../common/views/Label";
import { useInputChangeHandler } from "../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../common/modals/modal.utils";
import { useTranslation } from "react-i18next";

const getDefaultInputValues = (drivebox) => ({
    vehicleId: drivebox?.vehicle?.id,
});

const DriveboxModal = props => {
    const {onClose, drivebox} = props;
    const { handleInputChange, fields } = useInputChangeHandler(getDefaultInputValues(drivebox));
 
    const { vehicleId } = fields;
    const { t } = useTranslation();

    function saveDriveBox() {
        props.requestUpdateDrivebox({vehicleId: fields.vehicleId, id: drivebox.id});
        onClose();
    }

    const inputs = [
        new FormBuilder.FormInput(t("drivebox.editmodal.imei"), <Label value={drivebox?.imei} />)
            .withValue(drivebox?.imei)
            .withKey("imeiKey")
            .build(),
        new FormBuilder.FormInput(t("drivebox.editmodal.model"), <Label value={drivebox?.model} />)
            .withValue(drivebox?.model)
            .withKey("modelKey")
            .build(),
        new FormBuilder.FormInput(t("drivebox.editmodal.vehicle"), <VehicleSelect value={vehicleId} onChange={(value) => handleInputChange("vehicleId", value)} showGroups
                                                                                               allowClear/>)
            .withValue(vehicleId)
            .withKey("vehicleId")
            .build(),
    ];

    return <FormModal onSave={saveDriveBox} onClose={onClose} title={"Drivebox: " + drivebox.imei} inputs={inputs} formType={MODAL_TYPES.withoutValidation} />;

};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestDriveboxById, requestUpdateDrivebox}, dispatch);
}

function mapStateToProp(state) {
    return state
}


export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(DriveboxModal);
