import React, {useState} from "react";
import CustomSelect from "../../common/selects/Select";
import {Select} from "antd";
import PropTypes from "prop-types";
import {pastTimeRangeArray} from "../../../models/TimeRange";

const {Option} = Select;
const PastTimeRangeSelect = props => {

    const {onChange, value} = props;

    return <CustomSelect value={value}
                         allowClear={false}
                         handleChange={onChange}
                         placeholder="Letzten Monat">
        {pastTimeRangeArray.map(p => {
            return <Option key={p.id} value={p.id}>
                {p.name}
            </Option>
        })}
    </CustomSelect>
};

// PastTimeRangeSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string
// }

export default PastTimeRangeSelect;