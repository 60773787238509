import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { customColors } from './StylingOptions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

/**
 * generic simple selection component
 * 
 * format for items:
 * [
 *      { title: [string], value: [any] },
 *      { title: [string], value: [any] },
 *      ...
 * ]
 */
export default function SimpleSelectionMenu(props) {
    let { isDisabled, title, tooltip, items, color, selectedItem, setSelectedItem } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="simpleSelectionMenuWrapper">
            <Tooltip enterDelay={500} title={tooltip}>
                <Button
                    id={ "menuButton_" + tooltip }
                    aria-controls={`menuButton_${tooltip}`}
                    style={{ color: (isDisabled ? customColors.disabled : (color || customColors.bright)), padding: '2px', marginLeft: '-10px' }}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    {title + ': ' + items.filter(e => {
                        return +e.value === +selectedItem;
                    })[0].title}
                    <ArrowDropDownIcon></ArrowDropDownIcon>
                </Button>
            </Tooltip>
            <Menu
                id={`menu_${tooltip}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map((e, idx) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                setSelectedItem(e.value);
                                handleClose();
                            }}
                            key={e.title + idx}
                        >
                            {e.title}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
