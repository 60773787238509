import React, { useEffect } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { requestUsers } from "../../../../actions/actionUser";

const { Option } = Select;

const TagEmailSelect = (props) => {
  const { onChange, value, disabled } = props;
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.entities);

  useEffect(() => {
    dispatch(requestUsers({ page: 1, perPage: 99999, isArchived: false }));
  }, []);

  function handleChange(value) {
    onChange(value);
  }

  const options = [];
  users.forEach((u) => {
    options.push(<Option key={u.email}>{u.email}</Option>);
  });

  return (
    <Select
      disabled={disabled}
      value={value}
      mode="tags"
      style={{ width: "100%" }}
      placeholder="mail@drivebox.at"
      onChange={handleChange}
    >
      {options}
    </Select>
  );
};

// TagEmailSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.array.isRequired,
//   disabled: PropTypes.bool,
// };

export default TagEmailSelect;
