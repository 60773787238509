import { packages } from "../models/Package";
import { isHwnoeVehicleReportAuthorised } from "../components/reporting/reportAuthorisation";

const reports = {
  logBook: 0,
  day: 1,
  dayGraphic: 2,
  tripGeofenceIntersections: 3,
  vehicleMileageSummary: 4,
  month:5,
  reservation: 6,
  hwnoeVehicle: 7,
  costcenter: 8,
  fuelMonitor: 9
};

const reportInformation = {
  day: {
    id: reports.day,
    disabled: false,
    serverName: "Day",
    translationKey: "report.reportList.day",
  },
  dayGraphic: {
    id: reports.dayGraphic,
    disabled: false,
    serverName: "DayGraphic",
    translationKey: "report.reportList.dayGraphic",
  },
  logBook: {
    id: reports.logBook,
    disabled: false,
    serverName: "Month",
    translationKey: "report.reportList.month",
  },
  tripGeofenceIntersections: {
    id: reports.tripGeofenceIntersections,
    disabled: false,
    disableTimedReport: true,
    packages: [packages.Geofences],
    translationKey: "report.reportList.tripGeoIntersection",
  },
  vehicleMileageSummary: {
    id: reports.vehicleMileageSummary,
    disabled: false,
    disableTimedReport: true,
    serverName: "VehicleMileageSummary",
    translationKey: "report.reportList.vehicleMileageSummary",
  },
  monthReport:{
    id: reports.month,
    disabled: false,
    serverName: "Month",
    translationKey: "report.reportList.tripsRecord",
  },
  reservationReport:{
   id: reports.reservation,
   disabled: false,
   serverName: "Month",
   disableTimedReport: true,
   packages: [packages.Reservations],
   translationKey: "report.reportList.reservation",
  },
  hwnoeVehicleReport:{
    id: reports.hwnoeVehicle,
    disabled: false,
    serverName: "Month",
    disableTimedReport: false,
    translationKey: "report.reportList.hwnoeVehicle",
    hasPermission: isHwnoeVehicleReportAuthorised,
   },
   costcenterReport:{
    id: reports.costcenter,
    disabled: false,
    disableTimedReport: true,
    packages: [packages.CostCenter],
    translationKey: "report.reportList.costcenter",
   },
   fuelMonitorReport: {
    id: reports.fuelMonitor,
    disabled: false,
    disableTimedReport: true,
    packages: [packages.FuelMonitor],
    translationKey: "report.reportList.fuelMonitor",
   }
};

const reportsArray = [
  { id: reports.day, translationKey: reportInformation.day.translationKey },
  {
    id: reports.dayGraphic,
    translationKey: reportInformation.dayGraphic.translationKey,
  },
  {
    id: reports.logBook,
    translationKey: reportInformation.logBook.translationKey,
  },
  {
    id: reports.tripGeofenceIntersections,
    translationKey: reportInformation.tripGeofenceIntersections.translationKey,
  },
  {
    id: reports.vehicleMileageSummary,
    translationKey: reportInformation.vehicleMileageSummary.translationKey,
  },
  {
    id: reports.month,
    translationKey: reportInformation.monthReport.translationKey,
  },
  {
    id: reports.reservation,
    translationKey: reportInformation.reservationReport.translationKey
  },
  {
    id: reports.hwnoeVehicle,
    translationKey: reportInformation.hwnoeVehicleReport.translationKey
  },
  {
    id: reports.costcenter,
    translationKey: reportInformation.costcenterReport.translationKey
  },
  {
    id: reports.fuelMonitor,
    translationKey: reportInformation.fuelMonitorReport.translationKey
  }
];

const getReportInformationById = (id) => {
  switch (id) {
    case reports.day:
      return reportInformation.day;
    case reports.dayGraphic:
      return reportInformation.dayGraphic;
    case reports.logBook:
      return reportInformation.logBook;
    case reports.tripGeofenceIntersections:
      return reportInformation.tripGeofenceIntersections;
    case reports.vehicleMileageSummary:
      return reportInformation.vehicleMileageSummary;
    case reports.month:
      return reportInformation.monthReport;
    case reports.reservation:
      return reportInformation.reservationReport
    case reports.hwnoeVehicle:
      return reportInformation.hwnoeVehicleReport
    case reports.costcenter:
      return reportInformation.costcenterReport
    case reports.fuelMonitor:
        return reportInformation.fuelMonitorReport
  }
};

const getReportInformationByServerName = (serverName) => {
  switch (serverName) {
    case reportInformation.day.serverName:
      return reportInformation.day;
    case reportInformation.dayGraphic.serverName:
      return reportInformation.dayGraphic;
    case reportInformation.logBook.serverName:
      return reportInformation.logBook;
  }
};

export {
  reports,
  reportsArray,
  reportInformation,
  getReportInformationById,
  getReportInformationByServerName,
};