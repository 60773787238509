import { findEngineTypeById } from "../models/EngineType"
import i18n from "../i18n";

export const calculateFuelOrBatteryTripData = (trip) => {

    const { startingBatteryFuelLevelInLiter, finishingBatteryFuelLevelInLiter, mileageInMeters, vehicle , startingBatteryChargeLevelInPercent, finishingBatteryChargeLevelInPercent } = trip;

    const engineTypeById = findEngineTypeById(vehicle?.engineType);
    const userSelectedEngineType = engineTypeById ? engineTypeById.name : "";
    const isFuelEngine = userSelectedEngineType === "Fuel";

    const isVehicleDetailsFilled = vehicle?.tankSize !== null || vehicle?.consumptionThresholdHigh !== null || vehicle?.consumptionThresholdLow !== null;

    const metersInKilometers = mileageInMeters / 1000;
    const fuelAtStart = isFuelEngine ? startingBatteryFuelLevelInLiter : (vehicle?.tankSize * startingBatteryChargeLevelInPercent / 100).toFixed();
    const fuelAtFinish = isFuelEngine ? finishingBatteryFuelLevelInLiter : (vehicle?.tankSize * finishingBatteryChargeLevelInPercent / 100).toFixed();
    const fuelConsumForSingleTripIn = fuelAtStart - fuelAtFinish;

    const averageFuelConsumption = isVehicleDetailsFilled ? ((fuelConsumForSingleTripIn / metersInKilometers) * 100).toFixed(1) : <i style={{color: "red"}}>{i18n.t("fuelMonitor.vehicleDetailsMissing")}.</i>;
    const overConsumption = averageFuelConsumption > vehicle?.consumptionThresholdHigh;
    const underConsumption = averageFuelConsumption < vehicle?.consumptionThresholdLow;
    
    return { averageFuelConsumption, overConsumption, underConsumption, userSelectedEngineType, overConsumption, underConsumption, isVehicleDetailsFilled };
};