import React from "react"

const AverageIcon = ({color="black"}) => {
    return (
    <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5426 14.9091C17.5426 16.8267 17.1964 18.4839 16.5039 19.8807C15.8114 21.2775 14.8615 22.3546 13.6541 23.1122C12.4467 23.8698 11.0677 24.2486 9.51705 24.2486C7.96638 24.2486 6.58736 23.8698 5.37997 23.1122C4.17259 22.3546 3.22266 21.2775 2.53018 19.8807C1.83771 18.4839 1.49148 16.8267 1.49148 14.9091C1.49148 12.9915 1.83771 11.3343 2.53018 9.9375C3.22266 8.54072 4.17259 7.46354 5.37997 6.70597C6.58736 5.94839 7.96638 5.5696 9.51705 5.5696C11.0677 5.5696 12.4467 5.94839 13.6541 6.70597C14.8615 7.46354 15.8114 8.54072 16.5039 9.9375C17.1964 11.3343 17.5426 12.9915 17.5426 14.9091ZM15.4119 14.9091C15.4119 13.3348 15.1486 12.006 14.6218 10.9229C14.101 9.83984 13.3937 9.02012 12.5 8.46378C11.6122 7.90743 10.6179 7.62926 9.51705 7.62926C8.41619 7.62926 7.41892 7.90743 6.52521 8.46378C5.63743 9.02012 4.93016 9.83984 4.40341 10.9229C3.88258 12.006 3.62216 13.3348 3.62216 14.9091C3.62216 16.4834 3.88258 17.8121 4.40341 18.8952C4.93016 19.9783 5.63743 20.7981 6.52521 21.3544C7.41892 21.9107 8.41619 22.1889 9.51705 22.1889C10.6179 22.1889 11.6122 21.9107 12.5 21.3544C13.3937 20.7981 14.101 19.9783 14.6218 18.8952C15.1486 17.8121 15.4119 16.4834 15.4119 14.9091Z" fill={color}/>
        <rect x="18.2747" y="4" width="2" height="26.951" transform="rotate(42.6933 18.2747 4)" fill={color}/>
    </svg>
    )
}

export default AverageIcon;