import React from "react";
import { Card, List, ListItem, ListItemText } from "@material-ui/core";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import {
  getReportInformationById,
  reports,
  reportsArray,
} from "../../managers/reportManager";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import {
  isPackageAuthorized,
} from "../../managers/authManager";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
});

const ReportList = (props) => {
  const classes = useStyles(props);
  const me = useSelector((state) => state.users.me);
  const { t } = useTranslation();

  const {
    onLogBookExport,
    onDayViewExport,
    onDayViewGraphicExport,
    onTripGeofenceIntersectionsExport,
    onSchedule,
    onVehicleMileageExport,
    onMonthlyReportExport,
    onReservationReportExport, 
    onVehicleReportExport,
    onCostcenterReportExport,
    onFuelMonitorReportExport
  } = props;

  const openExportModal = (id) => {
    switch (id) {
      case reports.day:
        onDayViewExport();
        break;
      case reports.dayGraphic:
        onDayViewGraphicExport();
        break;
      case reports.logBook:
        onLogBookExport();
        break;
      case reports.tripGeofenceIntersections:
        onTripGeofenceIntersectionsExport();
        break;
      case reports.vehicleMileageSummary:
        onVehicleMileageExport();
        break;
      case reports.month:
        onMonthlyReportExport();
        break;
      case reports.reservation:
        onReservationReportExport();
        break;
      case reports.hwnoeVehicle:
        onVehicleReportExport();
        break;
      case reports.costcenter:
        onCostcenterReportExport();
        break;
      case reports.fuelMonitor:
        onFuelMonitorReportExport();
        break;
    }
  };
  return (
    <Card className={classes.container}>
      <List>
        {reportsArray.map((r, i) => {
          const { id } = r;
          const report = getReportInformationById(id);

          if (report.packages && !isPackageAuthorized(me, report.packages)) {
            return null;
          }

          if(report.hasPermission && !report.hasPermission(me)) {
            return;
          }

          return (
            <ListItem key={"liRL" + i} disabled={report.disabled}>
              <ListItemText
                primary={t(report.translationKey)}
              />
              {!report.disableTimedReport && (
                <Tooltip title="Automatischer Versand">
                  <IconButton onClick={() => onSchedule(id)}>
                    <FontAwesomeIcon>far fa-clock</FontAwesomeIcon>
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Download">
                <IconButton
                  onClick={() => {
                    openExportModal(id);
                  }}
                >
                  <FontAwesomeIcon>far fa-print</FontAwesomeIcon>
                </IconButton>
              </Tooltip>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};

// ReportList.propTypes = {
//   onSchedule: PropTypes.func.isRequired,
//   onLogBookExport: PropTypes.func.isRequired,
//   onDayViewExport: PropTypes.func.isRequired,
//   onDayViewGraphicExport: PropTypes.func.isRequired,
//   onVehicleMileageExport: PropTypes.func.isRequired,
//   onMonthlyReportExport: PropTypes.func.isRequired
// };
export default ReportList;
