import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import "leaflet.icon.glyph"
import React from "react";
import { compareByReference } from "../../utils/comparisonUtils";

const createRoutingMachineLayer = (props) => {
    let { map, setSelectedRoute, setRouteController } = props;

    const instance = L.Routing.control({
      waypoints: [
        map.getBounds().getCenter(),
        {
          lat: map.getBounds().pad(-0.6).getNorth(),
          lng: map.getBounds().pad(-0.6).getWest()
        },
      ],
      
      routeWhileDragging: true,
      waypointMode: 'snap',
      fitSelectedRoutes: false,
      
      createMarker: function(i, wp, nWps) {
        return L.marker(wp.latLng, {
            draggable: true,
            icon: L.icon.glyph({
              prefix: '',
              glyph: i+1,
              className: "marker-icon"
            })
        })
      }

    })
    .on('routeselected', function(e) {
        console.log(e.route);
        setSelectedRoute(e.route);
    });
    
    setRouteController(instance);
  
    return instance;
  };
  
  const RoutingMachine = createControlComponent(createRoutingMachineLayer);
  
  export default React.memo(RoutingMachine, isEqual);

  function isEqual(prevProps, nextProps) {
    if (compareByReference(prevProps.map, nextProps.map)) {
      return true;
    }
    return false;
  }