import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

import Toolbar from "../../common/toolbar/Toolbar";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import DateRangePicker from "../../common/selects/datePickers/PeriodDatePicker";

import UserSelect from "../../common/selects/specificSelects/UserSelect";

//actions
import { deselectAllTrips } from "../../../actions/actionTrips";
import { updateFilterAPICall } from "../../../actions/actionFilter";

//manager
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { fromToByPeriod } from "../../../managers/timeManager";

//models
import { periods } from "../../../models/Period";
import { roles } from "../../../models/Role";
import { tripType } from "../../../models/TripType";

//Icons
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";
import TripsFilterSelect from "../../common/selects/specificSelects/TripsFilterSelect";
import { useTranslation } from "react-i18next";

const TripPageToolbar = (props) => {
  const currentDay = fromToByPeriod(periods.month.id, new Date());
  const [dateRange, setDateRange] = useState({
    period: periods.month.id,
    from:
      props.filter &&
      props.filter.from &&
      props.filter.period === periods.month.id
        ? new Date(props.filter.from)
        : currentDay.from,
    to:
      props.filter &&
      props.filter.to &&
      props.filter.period === periods.month.id
        ? new Date(props.filter.to)
        : currentDay.to,
  });

  const currentVehicle = useSelector((state) => state.vehicles.current);
  const currentUser = useSelector((state) => state.users.current);

  const { from, to, period } = dateRange;
  const {
    selected,
    deselectAllTrips,
    vehicle,
    setVehicle,
    views,
    view,
    setView,
    me,
    onAdd,
    onExport,
    onBulkEdit,
    settings,
    onSetting,
    onLock,
    onMerge,
    trips,
    usermode,
    user,
    setUser,
    setUserMode,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    props.updateFilterAPICall({
      from: from
        ? new Date(from).toISOString()
        : new Date("1760-01-01T00:00:00Z").toISOString(),
      to: to
        ? new Date(to).toISOString()
        : new Date("9999-01-01T00:00:00Z").toISOString(),
      period: period,
    });
  }, [period, from, to]);

  const actionButtons = [];
  const bulkActionButtons = [];

  function handleDateChange(period, from, to) {
    setDateRange({ period, from, to });
  }

  if (isAuthorized(me, allowedRoles(roles.Driver))) {
    actionButtons.push({
      icon: <FontAwesomeIcon>far fa-plus</FontAwesomeIcon>,
      translateKey: "trip.page.add",
      onClick: onAdd,
    });
  }

  if (isAuthorized(me, allowedRoles(roles.DriverViewer))) {
    actionButtons.push({
      icon: (
        <FontAwesomeIcon minWidth={26} fontSize={17}>
          far fa-download
        </FontAwesomeIcon>
      ),
      translateKey: "trip.page.export",
      onClick: onExport,
    });
  }
  if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
    actionButtons.push({
      icon: (
        <FontAwesomeIcon fontSize={16}>
          {usermode ? "far fa-ca" : "far fa-user"}
        </FontAwesomeIcon>
      ),
      translateKey: usermode ? "trip.page.vehicleLogBook" : "trip.page.driver",
      onClick: () => setUserMode(!usermode),
    });
  }

  let isAPrivateTripSelected = trips
    .filter((t) => t.isSelected)
    .some(
      (t) => t.type === tripType.Private && (!t.driver || me.id !== t.driver.id)
    );
    
  if (isAuthorized(me, allowedRoles(roles.Driver)) && !isAPrivateTripSelected) {
    bulkActionButtons.push({
      icon: <FontAwesomeIcon>far fa-pen</FontAwesomeIcon>,
      translateKey: "trip.page.bulkedit",
      onClick: onBulkEdit,
    });
  }

  if (
    (isAuthorized(me, allowedRoles(roles.Driver)) && !isAPrivateTripSelected) ||
    me.isHWNOE
  ) {
    bulkActionButtons.push({
      icon: <FontAwesomeIcon>far fa-lock</FontAwesomeIcon>,
      translateKey: "trip.page.lock",
      onClick: () => {
        onLock();
        deselectAllTrips();
      },
    });
  }

  if (
    me.canMergeSplitTrips &&
    isAuthorized(me, allowedRoles(roles.Driver)) &&
    !isAPrivateTripSelected
  ) {
    bulkActionButtons.push({
      icon: <FontAwesomeIcon>far fa-code-merge</FontAwesomeIcon>,
      translateKey: "trip.page.merge",
      onClick: onMerge,
      min: 2,
    });
  }

  const toggles = [
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={view !== views.table ? "secondary" : undefined}
        >
          far fa-book
        </FontAwesomeIcon>
      ),
      translateKey: "trip.page.driveBook",
      onClick: () => setView(views.table),
      active: view === views.table,
    },
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={view !== views.statistic ? "secondary" : undefined}
        >
          far fa-chart-pie
        </FontAwesomeIcon>
      ),
      translateKey: "trip.page.statisticView",
      onClick: () => setView(views.statistic),
      active: view === views.statistic,
    },
  ];

  const controls = [];

  if (!usermode)
    controls.push(
      <VehicleSelect
        showArchived={settings.showArchived}
        classname="toolbarchild"
        value={vehicle}
        onChange={setVehicle}
        allowClear={false}
      />
    );
  if (!usermode)
    controls.push(
      <TripsFilterSelect
        showArchived={settings.showArchived}
        classname="toolbarchild"
        value={props.tripsFilter}
        onChange={props.setTripsFilter}
        allowClear={false}
        me={me}
      />
    );

  if (usermode)
    controls.push(
      <UserSelect
        showArchived={settings.showArchived}
        classname="toolbarchild"
        value={user}
        onChange={setUser}
        allowClear={false}
      />
    );

  controls.push(
    <DateRangePicker
      period={period}
      startDate={from}
      classname="toolbarchild"
      endDate={to}
      onDateChange={handleDateChange}
      allowDateTime={true}
    />
  );

  return (
    <Toolbar
      title={
        usermode ? (
          t("trip.page.userTitle",
            { user: currentUser.firstName + " " + currentUser.lastName }
          )
        ) : (
          t("trip.page.vehicleTitle",
            { vehicle: currentVehicle.name }
          ))
      }
      toggles={toggles}
      actionButtons={actionButtons}
      bulkActionButtons={bulkActionButtons}
      numberSelected={selected}
      onSetting={onSetting}
      controls={controls}
      onDeselect={deselectAllTrips}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deselectAllTrips,
      updateFilterAPICall,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    selected: state.trips.selected,
    me: state.users.me,
    filter: state.filter.api,
    trips: state.trips.entities,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(TripPageToolbar);
