import React, { useEffect, useLayoutEffect, useState } from "react";
import Page from "../common/container/Page";
import { Card, Grid, makeStyles } from "@material-ui/core";
import PrettyTripTable from "./PrettyTripTable";
import VehicleDayMap from "./VehicleDayMap";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { vehicleKey } from "../../managers/localStorageManager";
import FilterToolbar from "../common/toolbar/FilterToolbar";
import { menu } from "../../managers/pathManager";
import DayViewToolbar from "./DayViewToolbar";
import { periods } from "../../models/Period";
import {
  requestAllVehicles,
  requestTracksOfVehicle,
} from "../../actions/actionVehicle";
import {
  requestTripIdleTimeReport,
  requestTripReport,
} from "../../actions/actionReport";
import DayViewSummaryReports from "./DayViewSummaryReports";
import { updateFilterAPICall } from "../../actions/actionFilter";
import { requestTrips } from "../../actions/actionTrips";
import { packages } from "../../models/Package";
import { allowedRoles, isAuthorized } from "../../managers/authManager";
import { roles } from "../../models/Role";

const useStyles = makeStyles({
  container: {
    minHeight: "calc(100vh - 420px)",
    height: (props) => props.containerHeight,
    position: "relative",
  },
  prettyTripContainer: {
    marginTop: 5,
  },
  prettyTripTableCard: {
    marginRight: 10,
    height: "100%",
  },
  map: {
    height: "100%",
  },
});

const DayViewPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.vehicles.allEntities);
  const filter = useSelector((state) => state.filter);
  const me = useSelector((state) => state.users.me);
  const isUserAuthorized = isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor]);
  const isFuelMonitorActive = isUserAuthorized &&  me.packages.includes(packages.FuelMonitor);
  const { value: savedVehicle, set: setSavedVehicle } =
    useLocalStorage(vehicleKey);

  const [_, setContainerHeight] = useState("calc(100vh - 210px)");

  const { api } = filter;
  const containerId = "containerId";

  const updateContainerHeight = () => {
    if (document.querySelector(`#${containerId}`)) {
      setContainerHeight(
        window.innerHeight -
          document.querySelector(`#${containerId}`).getBoundingClientRect().y -
          40
      );
    }
  };

  useLayoutEffect(() => {
    updateContainerHeight();
    setTimeout(updateContainerHeight, 200);
    window.addEventListener("resize", updateContainerHeight);
    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  });

  //preload vehicles
  useEffect(() => {
    dispatch(
      requestAllVehicles({ page: 1, perPage: 99999, isArchived: false })
    );
  }, []);

  const [vehicle, setVehicle] = useState(
    filter.api.tripbookVehicle
      ? filter.api.tripbookVehicle
      : savedVehicle
      ? savedVehicle
      : ""
  );

  //Save vehicle/user in localStorage
  useEffect(() => {
    if (vehicle) {
      setSavedVehicle(vehicle);
    }
  }, [vehicle]);

  //Update api filter
  useEffect(() => {
    dispatch(updateFilterAPICall({ vehicleIds: [vehicle] }));
  }, [vehicle]);

  //if current vehicle/user is not loaded, select fist vehicle of list
  useEffect(() => {
    if (!vehicle && vehicles.length > 0) {
      setVehicle(vehicles[0].id);
    }
  }, [vehicles]);

  useEffect(() => {
    if (api.from && api.to && vehicle && api.period === periods.day.id) {
      if (me.canShowTracks)
        dispatch(requestTracksOfVehicle({ ...api, vehicleId: vehicle }));
      else {
        dispatch(
          requestTrips({
            ...api,
            page: 1,
            perPage: 900,
            vehicleId: vehicle,
          })
        );
      }
      dispatch(requestTripReport(api));
      dispatch(requestTripIdleTimeReport({ ...api, id: vehicle }));
    }
  }, [vehicle, api, me]);

  return (
    <Page
      activePage={menu.dayView.key}
      toolbar={<DayViewToolbar setVehicle={setVehicle} vehicle={vehicle} />}
    >
      <FilterToolbar />
      <DayViewSummaryReports />

      <Grid
        container
        spacing={0}
        className={classes.prettyTripContainer}
        id={containerId}
      >
        <Grid className={classes.container} item xs={12} sm={5} md={5} lg={isFuelMonitorActive ? 4 : 3}>
          <Card className={classes.prettyTripTableCard}>
            <PrettyTripTable isUserAuthorized={isUserAuthorized} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={7} md={7} lg={isFuelMonitorActive ? 8 : 9}>
          <Card className={classes.map}>
            <VehicleDayMap />
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

// DayViewPage.propTypes = {};

export default DayViewPage;
