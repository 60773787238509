import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { menu } from "../../../managers/pathManager";

import {
    requestMandatorDriveboxEvents,
    requestMandatorDriveboxPositions,
    requestMandatorTrips,
} from "../../../actions/actionMandators";
import { setText } from "../../../actions/actionFilter";

import { useModalStatus } from "../../../hooks/useModalStatus";
import { clearError, setError } from "../../../actions/actionCommon";

import Page from "../../common/container/Page";
import Toolbar from "../../common/toolbar/Toolbar";
//Toolbar
import FilterToolbar from "../../common/toolbar/FilterToolbar";
//icons
import GPSFixedIcon from "@material-ui/icons/GpsFixed";
import EventIcon from "@material-ui/icons/Event";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
//tables
import RawDataPositionsTable from "./RawDataPositionsTable";
import RawDataEventsTable from "./RawDataEventsTable";
import RawDataTripsTable from "./RawDataTripsTable";
//modal
import RawDataFilterModal from "./RawDataFilterModal";

import { format } from "date-fns";

const RowDataPage = props => {
    const [step, setStep] = useState(0);
    const { modal: filterModal, open: openFilterModal, close: closeFilterModal } = useModalStatus();
    const { filter } = props;
    const { api, initial, updatedAt } = filter;

    useEffect(() => {
        requestData();
    }, [filter, step]);

    useEffect(() => {
        const { from, to, imei } = initial;
        const fromStr = from ? format(new Date(from), "dd MMMM yyyy") : "";
        const toStr = to ? format(new Date(to), "dd MMMM yyyy") : "";

        props.setText("IMEI: " + imei + " Von: " + fromStr + " Zu: " + toStr);
    }, [updatedAt]);

    function requestData() {
        if (hasFilterValues()) {
            props.clearError();
            switch (step) {
                case 0:
                    requestMandatorDriveboxPositions();
                    break;
                case 1:
                    requestMandatorDriveboxEvents();
                    break;
                case 2:
                    requestMandatorTrips();
                    break;
            }
        } else {
            setFilterMissingError();
        }
    }

    function setFilterMissingError() {
        props.setError({ message: "Bitte Filter setzen" });
    }

    function requestMandatorDriveboxPositions() {
        props.requestMandatorDriveboxPositions({
            imei: api.imei,
            from: api.from.toISOString(),
            to: api.to.toISOString(),
        });
    }

    function requestMandatorTrips() {
        props.requestMandatorTrips(
            {
                imei: api.imei,
                from: api.from.toISOString(),
                to: api.to.toISOString(),
            });
    }

    function requestMandatorDriveboxEvents() {
        props.requestMandatorDriveboxEvents({
            imei: api.imei,
            from: api.from.toISOString(),
            to: api.to.toISOString(),
        });
    }

    function renderTable() {
        switch (step) {
            case 0:
                return <RawDataPositionsTable/>;
            case 1:
                return <RawDataEventsTable/>;
            case 2:
                return <RawDataTripsTable/>;
        }
        return <RawDataPositionsTable/>;
    }

    function hasFilterValues() {
        return filter && api.imei && api.from && api.to;
    }

    const toggles = [
        {
            icon: <GPSFixedIcon/>,
            translateKey: "rawData.page.position",
            active: step === 0,
            onClick: () => setStep(0),
        },
        {
            icon: <EventIcon/>,
            translateKey: "rawData.page.events",
            active: step === 1,
            onClick: () => setStep(1),
        },
        {
            icon: <DriveEtaIcon/>,
            translateKey: "rawData.page.trips",
            active: step === 2,
            onClick: () => setStep(2),
        },
    ];

    const toolbar = <Toolbar toggles={toggles} onFilter={openFilterModal}/>;

    return (
        <Page activePage={menu.rowData.key} toolbar={toolbar}>
            <FilterToolbar/>
            {hasFilterValues() && renderTable()}
            {filterModal && <RawDataFilterModal onClose={closeFilterModal}/>}
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestMandatorDriveboxPositions,
            requestMandatorTrips,
            requestMandatorDriveboxEvents,
            setError,
            clearError,
            setText,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return { error: state.mandators.error, filter: state.filter };
}

// RowDataPage.propTypes = {
//     classes: PropTypes.object,
//     theme: PropTypes.object,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(RowDataPage);
