import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import { allowedRoles, isAuthorized } from "../../../../managers/authManager";
import { roles } from "../../../../models/Role";
import { packages } from "../../../../models/Package";

const { Option } = Select;

export const filterOptions = {
  All: 0,
  WithoutDriver: 1,
  UnLockedTrips: 2,
  UnderConsumption: 3,
  NormalConsumption: 4,
  OverConsumption: 5,
};

export const getRequestedFilterOption = (filterOption) => {
  switch(filterOption) {
    case filterOptions.WithoutDriver:
      return 1;
    case filterOptions.UnLockedTrips:
      return 2;
    case filterOptions.UnderConsumption:
      return 3;
    case filterOptions.NormalConsumption: 
      return 4;
    case filterOptions.OverConsumption:
      return 5;
    default: 
      return 0;
  }
}

export const filterOptionArray = [
  { id: filterOptions.All, name: "Alle Fahrten anzeigen" },
  { id: filterOptions.WithoutDriver, name: "Fahrten ohne Fahrer" },
  { id: filterOptions.UnLockedTrips, name: "Nicht Gesperrte Fahrten" },
];


export const fuelMonitorFielterOptions = [
  ...filterOptionArray,
  { id: filterOptions.UnderConsumption, name: "Unterkonsum"},
  { id: filterOptions.NormalConsumption, name: "Normaler Konsum"},
  { id: filterOptions.OverConsumption, name: "Überkonsum" },
];




const TripsFilterSelect = (props) => {
  const { onChange, value, disabled, multiple, allowClear, me } = props;

  const isUserAuthorized = isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor]);

  const customFilterOptions = isUserAuthorized ? fuelMonitorFielterOptions : filterOptionArray

  if (multiple)
    return (
      <MultiSelect
        placeholder="Alle Fahrten anzeigen"
        selectedArray={value}
        array={customFilterOptions}
        handleChange={onChange}
      />
    );
  return (
    <CustomSelect
      value={value}
      placeholder="Alle Fahrten anzeigen"
      handleChange={onChange}
      disabled={disabled}
      allowClear={allowClear}
    >
      {customFilterOptions.map((vp) => (
        <Option key={vp.id} value={vp.id}>
          {vp.name}
        </Option>
      ))}
    </CustomSelect>
  );
};

TripsFilterSelect.defaultProps = {
  disabled: false,
  multiple: false,
  allowClear: true,
  value: filterOptions.All,
};

// TripsFilterSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.number.isRequired,
//   disabled: PropTypes.bool,
//   multiple: PropTypes.bool,
//   allowClear: PropTypes.bool,
// };

export default TripsFilterSelect;
