import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EditModal from "../../common/modals/CustomModal";
import GenericTable from "../../common/table/GenericTable";
import { usePagination } from "../../../hooks/usePagination";

import { requestVehiclesLastPosition } from "../../../actions/actionVehicle";
import { Grid } from "@material-ui/core";
import { Alert, Input, Space } from "antd";
import TableTypes from "../../../models/TableTypes";
import Comparator from "../../../models/Comparator";
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  infoCard: {
    margin: "3px",
    cursor: "pointer",
  },
  container: {
    paddingBottom: "7px",
  },
}));

const quickFilter = {
  All: 1,
  SendingVehicle: 2,
  NotSendingVehicle: 3,
};

const ActiveVehicleModal = (props) => {
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const [filter, setFilter] = useState(quickFilter.All);
  const classes = useStyles();
  const { t } = useTranslation();

  const { onClose, vehicles } = props;

  useEffect(() => {
    props.requestVehiclesLastPosition();
  }, [page, rowsPerPage]);

  const noSignalComperator = new Comparator(
    ["lastPosition.timestamp"],
    (timestamp) => {
      let hours = moment().diff(moment(timestamp), "hours");
      return hours >= 3;
    }
  );

  const [search, setSearch] = React.useState('');

  const columnInformations = [
    {
      title: t("vehicle.table.name"),
      key: "name",
      color: { value: "red", comparator: noSignalComperator },
    },
    {
      title: t("vehicle.table.licensePlate"),
      key: "licensePlate",
      color: { value: "red", comparator: noSignalComperator },
    },
    {
      title: t("vehicle.table.drivebox"),
      key: "drivebox.name",
      color: { value: "red", comparator: noSignalComperator },
    },
    {
      title: t("vehicle.table.lastPosition"),
      key: "lastPosition.timestamp",
      type: TableTypes.date,
      color: { value: "red", comparator: noSignalComperator },
    },
    {
      title: "",
      key: "lastPosition.timestamp",
      type: TableTypes.time,
      color: { value: "red", comparator: noSignalComperator },
    },
  ];

  var filteredValues = vehicles.filter((v) => {

    if (!v.lastPosition) return false;

    let hours = moment().diff(moment(v.lastPosition.timestamp), "hours");
    if (filter === quickFilter.NotSendingVehicle) {
      return hours >= 3;
    }
    if (filter === quickFilter.SendingVehicle) {
      return hours < 3;
    }
    return true;
  }).filter((v) => {
    return v.name.toLowerCase().includes(search.toLowerCase()) || v.licensePlate.toLowerCase().includes(search.toLowerCase()) ||
      v.drivebox.name.toLowerCase().includes(search.toLowerCase())
  });

  const total = filteredValues.length;



  const handleSearch = (value) => {
    console.log("Searching for... ", value);
    setSearch(value);
  };

  const handleSearchChange = (event) => {
    console.log("Searching for... ", event);
    setSearch(event.target.value);
  };

  const pageData = filteredValues.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );


  const { Search } = Input;

  const content = (
    <div className={classes.container}>
      <Grid container spacing={3}>
        {filter === quickFilter.NotSendingVehicle && (
          <Grid xs={12}>
            <div
              className={classes.infoCard}
              onClick={() => {
                changePage(undefined, 0);
                setFilter(quickFilter.All);
              }}
            >
              <Alert
                message={
                  t("vehicle.activeVehicleModal.notSendingVehicles")
                }
                type="error"
              />
            </div>
          </Grid>
        )}

        {filter === quickFilter.SendingVehicle && (
          <Grid xs={12}>
            <div
              className={classes.infoCard}
              onClick={() => {
                changePage(undefined, 0);
                setFilter(quickFilter.All);
              }}
            >
              <Alert
                message={
                  t("vehicle.activeVehicleModal.sendingVehicles")
                }
                type="success"
              />
            </div>
          </Grid>
        )}

        {filter === quickFilter.All && (
          <Grid xs={6}>
            <div
              className={classes.infoCard}
              onClick={() => {
                changePage(undefined, 0);
                setFilter(quickFilter.NotSendingVehicle);
              }}
            >
              <Alert
                message={
                  t("vehicle.activeVehicleModal.notSendingVehicles")
                }
                type="error"
              />
            </div>
          </Grid>
        )}

        {filter === quickFilter.All && (
          <Grid xs={6}>
            <div
              className={classes.infoCard}
              onClick={() => {
                changePage(undefined, 0);
                setFilter(quickFilter.SendingVehicle);
              }}
            >
              <Alert
                message={
                  t("vehicle.activeVehicleModal.sendingVehicles")
                }
                type="success"
              />
            </div>
          </Grid>
        )}


        <Grid xs={12}>
          <div
            className={classes.infoCard} ><Search
              placeholder={t("vehicle.activeVehicleModal.searchPlaceholder")}
              size="large"
              onSearch={handleSearch}
              onChange={handleSearchChange}
              style={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </div>

          <GenericTable
            data={pageData}
            columnInformations={columnInformations}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={changePage}
            handleChangeRowsPerPage={changeRowPerPage}

          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <EditModal
      title={t("vehicle.activeVehicleModal.title")}
      largeModal
      onClose={onClose}
      contents={[content]}
    />
  );
};



ActiveVehicleModal.defaultProps = {
  page: 0,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestVehiclesLastPosition,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    vehicles: state.vehicles.entitiesWithLastPosition,
    total: state.vehicles.total,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(ActiveVehicleModal);
