import React from "react";
import CustomModal from "../../../common/modals/CustomModal";
import moment from "moment";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../../translations/translations";
import { useTranslation } from "react-i18next";

const MandatorBoxHistoryModal = ({onClose, data}) => {

    const loading = useSelector((state) => state.driveboxes.loading)
    const { t } = useTranslation();

    const activeDivider = data.length >= 2;

    const dividerStyle = {width: "50%", height: 1, backgroundColor: "black", marginBottom: 15, marginTop: 15}

    const content = [
      data.map((e, i) => {
        const startDataTime = moment(e.startedAt).format('DD MMMM YYYY, h:mm:ss a');
        const endDataTime = moment(e.finishedAt).format('DD MMMM YYYY, h:mm:ss a');

        return (
        <div key={i}>
            <div>
                <h6><strong>Imei:</strong> {e.driveboxImei}</h6>
                <h6><strong>{t("mandatorBoxHistoryModal.mandatorName")}:</strong> {e.driveBoxMandator}</h6>
                <h6><strong>{t("mandatorBoxHistoryModal.vehicleName")}:</strong> {e.vehicleName}</h6>
                <h6><strong>{t("mandatorBoxHistoryModal.licensePlate")}:</strong> {e.licensePlate}</h6>
                <h6><strong>{t("mandatorBoxHistoryModal.startedAt")}:</strong> {startDataTime}</h6>
                <h6><strong>{t("mandatorBoxHistoryModal.finishAt")}:</strong> {endDataTime == "Invalid date" ? getTranslation("mandatorBoxHistoryModal.continuing") : endDataTime}</h6>
            </div>
            {activeDivider && <div style={dividerStyle}/>}
        </div>
        )
      })
  
    ]

    return (
       <CustomModal onClose={onClose} contents={content} title={getTranslation("mandatorBoxHistoryModal.title")} loading={loading}/>
    )
}

export default MandatorBoxHistoryModal;