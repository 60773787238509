import { RolesResponse } from "../types/global.types";
import { UserResponseInfo } from "../types/user/userResponse.types";

enum ROLES {
  Admin = "Admin",
  AdminViewer = "Admin Viewer",
  GroupLeader = "Group Leader",
  GroupLeaderViewer = "Group Leader Viewer",
  Driver = "Driver",
  DriverViewer = "DriverViewer",
  DriverLeader = "DriverLeader",
  SuperAdmin = "Super Admin",
}

const getRoleId = (allRoles: RolesResponse[], roleName: ROLES) => {
  if (allRoles && allRoles.length > 0 && roleName) {
    return allRoles.find((r) => r.name === roleName);
  }
  return undefined;
};

export { ROLES, getRoleId };

export const isHigherThanDriverLeader = (user: UserResponseInfo): boolean => {
  return (
    user.role.name !== ROLES.Driver &&
    user.role.name !== ROLES.DriverViewer &&
    user.role.name !== ROLES.DriverLeader
  );
};
