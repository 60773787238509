import React from "react";
import PropTypes from "prop-types";

const Label = ({value}) => {
    return <span>{value}</span>;
};

// Label.propTypes = {
//     value: PropTypes.node
// };

export default Label;
