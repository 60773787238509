import { RESERVATION_ADD_MODAL_TYPES } from "./reservationAddModal.type";

export const getInitialState = (reservation) => ({
  pickupDate: reservation ? new Date(reservation.pickupDate) : null,
  returnDate: reservation ? new Date(reservation.returnDate) : null,
  pickupAddress: reservation ? reservation.pickupAddress : null,
  returnAddress: reservation ? reservation.returnAddress : null,
  numOfStopovers: reservation?.stopovers.length
    ? reservation.stopovers.length
    : 1,
  stopoverValues: reservation?.stopovers.length ? reservation.stopovers : [{}],
  vehicleId: reservation ? reservation.vehicle.id : null,
  driverId: reservation ? reservation.driver.id : null,
  purpose: reservation ? reservation.purpose : "",
  comment: reservation ? reservation.comment : "",
  newReservation: null,
  isReservationChanged: true,
  costcenterId: reservation ? reservation?.costcenter?.id : null,
  isNextButtonTouched: false,
});

export const reservationAddModalReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESERVATION_ADD_MODAL_TYPES.SET_IS_RESERVATION_CHANGED:
      return {
        ...state,
        isReservationChanged: payload,
      };
    case RESERVATION_ADD_MODAL_TYPES.SET_NEW_RESERVATION:
      return {
        ...state,
        newReservation: payload,
      };
    case RESERVATION_ADD_MODAL_TYPES.SET_IS_NEXT_BUTTON_TOUCHED:
      return {
        ...state,
        isNextButtonTouched: payload,
      };
    case RESERVATION_ADD_MODAL_TYPES.SET_INPUT_VALUE:
      return {
        ...state,
        [payload.name]: payload.value,
      };
    case RESERVATION_ADD_MODAL_TYPES.SET_STOPOVERS_NUMBER:
      return {
        ...state,
        numOfStopovers: state.numOfStopovers + payload,
      };
    case RESERVATION_ADD_MODAL_TYPES.SET_STOPOVER_VALUES:
        return {
          ...state,
          stopoverValues: payload,
        }
    default:
      throw new Error(
        `unhandled type of ${type} in reservationAddModalReducer`
      );
  }
};
