import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestGeofencesNameId } from "../../../../actions/actionGeofences";
import React from "react";
import MultiSelect from "../MultiSelect";
import { useTranslation } from "react-i18next";

const GeofenceSelect = (props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();
    const {value,  onChange} = props;
    const geofences = useSelector(state => state.geofences.geofencesNameId);
    const dispatch = useDispatch();

    useEffect(() => {
        setSearchTerm("");
    }, [value]);

    useEffect(() => {
        getGeofencesWhenSearchTermChanges();
    }, [searchTerm]);

    function getGeofencesWhenSearchTermChanges() {
        dispatch(requestGeofencesNameId({searchTerm}))
    }


    return (
        <MultiSelect placeholder={t("common.select.geofences")}
            selectedArray={value}
            array={geofences}
            handleChange={onChange}
            handleSearch={setSearchTerm} 
            customMinWidth={props.customMinWidth}
            customMaxTagCount={props.customMaxTagCount} />
    )
}

export default GeofenceSelect;