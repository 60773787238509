const log = {
    SUCCESS: 0,
    FATAL: 1,
    ERROR: 2,
    WARN: 3,
    INFO: 4,
    DEBUG: 5,
    TRACE: 6,
};

const logArray = [
    {id: log.SUCCESS, name: "SUCCESS"},
    {id: log.FATAL, name: "Fatal"},
    {id: log.ERROR, name: "Error"},
    {id: log.WARN, name: "Warn"},
    {id: log.INFO, name: "Info"},
    {id: log.DEBUG, name: "Debug"},
    {id: log.TRACE, name: "Trace"},
];

export {log, logArray};