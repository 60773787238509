import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//Common
import AddressSelect from "../../common/selects/specificSelects/AddressSelect";
import FormModal from "../../common/modals/FormModal";
import PackageSelect from "../../common/selects/specificSelects/PackageSelect";

import {
  requestUpdateMandator,
} from "../../../actions/actionMandators";
import FormBuilder from "../../../managers/formBuilder";
import { Input, InputNumber, Switch, Select } from "antd";
import Label from "../../common/views/Label";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";

const getDefaultInputValues = (mandator) => ({
  name: mandator?.name ,
  packages: mandator?.packages,
  companyName: mandator?.companyName,
  hideTripsUpToInMeter: mandator?.hideTripsUpToInMeter,
  address: mandator?.address,
  preferredGeolocationServiceProvider: mandator?.preferredGeolocationServiceProvider,
  description: mandator?.description,
  canShowTracks: mandator?.canShowTracks,
  canMergeSplitTrips: mandator?.canMergeSplitTrips,
  liveLocationIntervalInSeconds:  mandator?.liveLocationIntervalInSeconds,
  isTester: Boolean(mandator?.testerSince),
  testerCreatedBy: mandator?.testerCreatedBy,
  testerExpiresInDays: mandator?.testerExpiresInDays,
});

const MandatorModal = (props) => {
  const { onClose, activeMandator } = props;
  const { handleInputChange, fields } = useInputChangeHandler(getDefaultInputValues(activeMandator));
 
  const { name, packages, companyName, hideTripsUpToInMeter, 
    address, description, canShowTracks, canMergeSplitTrips, 
    liveLocationIntervalInSeconds, isTester,
    testerCreatedBy, testerExpiresInDays, preferredGeolocationServiceProvider } = fields

  function saveMandator() {
    props.requestUpdateMandator({ ...fields, id: activeMandator.id });
    onClose();
  }

  const inputs = [
    new FormBuilder.FormInput("Name", <Input value={name} onChange={(e) => handleInputChange("name", e.target.value)} />)
      .withValue(name)
      .withKey("name")
      .withValidationMessage("Mandatorname must be set", true)
      .isRequired()
      .build(),
    new FormBuilder.FormInput("Package", <PackageSelect value={packages} onChange={(value) => handleInputChange("packages", value)} multiple />)
      .withValue(packages)
      .withKey("packages")
      .withValidationMessage("At least 1 package must be set")
      .isRequired()
      .build(),
    new FormBuilder.FormInput("Companyname", <Input value={companyName} onChange={(e) => handleInputChange("companyName", e.target.value)} />)
      .withValue(companyName)
      .withKey("companyName")
      .withValidationMessage("Company name must be set", true)
      .isRequired()
      .build(),
    new FormBuilder.FormInput("Hide rides up to meter", <Input value={hideTripsUpToInMeter} onChange={(e) => handleInputChange("hideTripsUpToInMeter", e.target.value)} />)
      .withValue(hideTripsUpToInMeter)
      .withKey("hideTripsUpToInMeter")
      .build(),
    new FormBuilder.FormInput("Address", <AddressSelect value={address} onChange={(value) => handleInputChange("address", value)} />)
      .withValue(address)
      .withKey("address")
      .withValidationMessage("Address must be set", true)
      .isRequired()
      .build(),
      new FormBuilder.FormInput("Preferred Geocoding", <Select options={
        [
          {label: "Default", value: null},
          {label: "Here", value: 2},
          {label: "Google", value: 3}
        ]} value={preferredGeolocationServiceProvider} onChange={(value) => handleInputChange("preferredGeolocationServiceProvider", value)} />)
      .withValue(preferredGeolocationServiceProvider)
      .withKey("preferredGeolocationServiceProvider")
      // .withValidationMessage("Preferred Geocoding must be set", true)
      // .isRequired()
      .build(),
    new FormBuilder.FormInput("Description", <Input value={description} onChange={(e) => handleInputChange("description", e.target.value)} />)
      .withValue(description)
      .withKey("description")
      .build(),
    new FormBuilder.FormInput("Show tracks", <Switch checked={canShowTracks} onChange={(value) => handleInputChange("canShowTracks", value)} />)
      .withKey("canShowTracks")
      .withValue(canShowTracks)
      .withValuePropName("checked")
      .build(),
    new FormBuilder.FormInput("Merge and split", <Switch checked={canMergeSplitTrips} onChange={(value) => handleInputChange("canMergeSplitTrips", value)} />)
      .withKey("canMergeSplitTrips")
      .withValue(canMergeSplitTrips)
      .withValuePropName("checked")
      .build(),
    new FormBuilder.FormInput("LiveLocationinterval", <InputNumber value={liveLocationIntervalInSeconds} onChange={(value) => handleInputChange("liveLocationIntervalInSeconds", value)}/>)
      .withKey("liveLocationIntervalInSeconds")
      .withValue(liveLocationIntervalInSeconds)
      .build(),
      //Boolean(isTester) ? Boolean(isTester) : Boolean(activeMandator.testerSince)
    new FormBuilder.FormInput("Tester", <Switch checked={Boolean(isTester)} onChange={(value) => handleInputChange("isTester", value)} />)
      .withKey("isTester")
      .withValue(Boolean(isTester))
      .withValuePropName("checked")
      .build(),
    new FormBuilder.FormInput("Tester since", <Label value={activeMandator.testerSince ? new Date(activeMandator?.testerSince).toDateString() : ""} />)
      .withKey("isTesterLab")
      .withValue(
        activeMandator.testerSince ? new Date(activeMandator?.testerSince).toDateString() : ""
      )
      .build(),
    new FormBuilder.FormInput("Tester created by", <Label value={testerCreatedBy} />)
      .withKey("testerCreatedBy")
      .withValue(testerCreatedBy)
      .build(),
    new FormBuilder.FormInput("Tester expires in days", <InputNumber value={testerExpiresInDays} onChange={(value) => handleInputChange("testerExpiresInDays", value)} />)
      .withKey("testerExpiresInDays")
      .withValue(testerExpiresInDays)
      .build(),
  ];

  return (
    <FormModal
      onSave={saveMandator}
      onClose={onClose}
      title="Mandator"
      inputs={inputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestUpdateMandator,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
      error: state.mandators.error,
  };
}

// MandatorModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
// };

export default connect(mapStateToProp, mapDispatchToProps)(MandatorModal);
