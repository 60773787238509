import React from 'react';
import GeoFenceListItem from './listitem/GeoFenceListItem';
import { Checkbox } from '@material-ui/core';
import { customColors } from '../../generic/StylingOptions';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';


const selectionState = {
    NONE: 0,
    SOME: 1,
    ALL: 2,
}

function GeoFenceList(props) {
    let { geoFences, setGeoFences, setMapState, map, currentUser,
        selection, setSelection, polygonColor, geoFenceMetadata, setGeoFenceMetadata,
        setDialog, visibilityObj, setVisibilityObj, geoFenceLocks, setGeoFenceLocks } = props;
    const [selectAllState, setSelectAllState] = useState(selectionState.NONE);

    useEffect(() => {
        setSelectAllState(getSelectionState());
    }, [geoFences]);

    function handleChange(id) {
        let newSelection = selection;
        if (newSelection.includes(id)) {
            let foundIndex = newSelection.indexOf(id);
            if (foundIndex > -1) {
                newSelection.splice(foundIndex, 1);
            }
        } else {
            newSelection.push(id);
        }
        setSelection([...newSelection]);
        setSelectAllState(getSelectionState());
    }

    function getSelectionState() {
        let geoFenceCount = [...geoFences.keys()].length;

        setSelection([...selection].filter(id => geoFences.has(id)));

        if (selection.length === 0)
            return selectionState.NONE;
        else if (selection.length === geoFenceCount)
            return selectionState.ALL;
        else
            return selectionState.SOME;
    }

    function onSelectAllChanged() {
        switch (selectAllState) {
            case selectionState.NONE:   selectAllGeoFences();   break;
            default:                    deselectAllGeoFences(); break;
        }
    }

    function selectAllGeoFences() {
        let newSelection = selection;
        [...geoFences.keys()].forEach(id => {
            if (!selection.includes(id))
                newSelection.push(id);
        })
        setSelection([...newSelection]);
        setSelectAllState(selectionState.ALL);
    }

    function deselectAllGeoFences() {
        setSelection([]);
        setSelectAllState(selectionState.NONE);
    }

    return (
        <div className="scrollableListWrapperDiv">
            <div>
                <div className="checkBoxDiv">
                    <Checkbox
                        id="cb_all"
                        style={{ color: customColors.ilogsBlue }}
                        indeterminate={selectAllState === selectionState.SOME}
                        checked={selectAllState !== selectionState.NONE}
                        onChange={() => onSelectAllChanged()}
                    ></Checkbox>
                </div>
                <div className="headline-wrapper">
                    <h1>Geofences</h1>
                </div>
            </div>
            
            {geoFences.size <= 0 ? <div className="noGeofencesDiv">
                {props.t('errors.noGeofences')}
            </div> : <></>}

            {[...geoFences.keys()].map(id => {
                return (
                    <div key={"listItemWrapper_" + id}>
                        <div className="checkBoxDiv">
                            <Checkbox
                                id={`cb_${id}`}
                                key={`cb_${id}`}
                                onChange={() => handleChange(id)}
                                style={{ color: customColors.ilogsBlue }}
                                checked={selection.includes(id)}
                            ></Checkbox>
                        </div>
                        
                        <GeoFenceListItem
                            key={'listItem_' + id}
                            id={id}
                            geoFences={geoFences}
                            setGeoFences={setGeoFences}
                            setMapState={setMapState}
                            map={map}
                            editHistory={geoFences.get(id).geoFence.Histories}
                            currentUser={currentUser}
                            polygonColor={polygonColor}
                            setVisibilityObj={setVisibilityObj}
                            visibilityObj={visibilityObj}
                            geoFenceMetadata={geoFenceMetadata}
                            setGeoFenceMetadata={setGeoFenceMetadata}
                            setDialog={setDialog}
                            removeFromSelection={() => {
                                let newSelection = selection;
                                let foundIndex = newSelection.indexOf(id);
                                if (foundIndex > -1) {
                                    newSelection.splice(foundIndex, 1);
                                }
                                setSelection([...newSelection]);
                            }}
                            geoFenceLocks={geoFenceLocks}
                            setGeoFenceLocks={setGeoFenceLocks}
                        ></GeoFenceListItem>
                    </div>
                );
            })}
        </div>
    );
}

export default withTranslation()(GeoFenceList);