import { call, put, takeLatest } from "typed-redux-saga/macro";

import {
  receiveLiveLocation,
  LIVELOCATION_ACTION_TYPES,
} from "../actions/actionLiveLocation";

import { RequestLiveLocation } from "../types/actions/actionLiveLocation.types";

import { handleError } from "../managers/sagaManager";

import { getLastLocationVehicles } from "./api";

function* getVehiclesLastLocation(action: RequestLiveLocation) {
  try {
    const response = yield* call(getLastLocationVehicles, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveLiveLocation(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaLiveLocation() {
  yield* takeLatest(
    LIVELOCATION_ACTION_TYPES.REQUEST_LIVELOCATION,
    getVehiclesLastLocation
  );
}
