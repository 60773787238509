import { useState } from 'react';

export const useInputChangeHandler = (defaultInputValues) => {
  const [fields, setFields] = useState(defaultInputValues);

  const handleInputChange = (name, value) => {
    setFields({ ...fields, [name]: value });
  };

  return { handleInputChange, fields, setFields };
};
