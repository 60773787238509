import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestVehicleNameById,
  ReceiveVehicleNameById,
  RequestDriverNameByPin,
  ReceiveDriverNameByPin,
  RequestDriversByName,
  ReceiveDriversByName,
  GetVehicleNameByIdFailed,
  GetDriverNameByPinFailed,
  GetDriversByNameFailed,
  DriverIdentificationClearError,
  ClearDriverName,
  RequestAssignVehicleToDriverByPin,
  ReceiveAssignVehicleToDriverByPin,
  RequestAssignVehicleToDriverByDriverId,
  ReceiveAssignVehicleToDriverByDriverId,
  AssignVehicleToDriverByPinFailed,
  AssignVehicleToDriverByDriverIdFailed,
  ClearVehicleDriver,
  SetVehicleId,
  RequestQrCodeByVehicleId,
  RequestSelectedVehicleQrCode,
  LoadDriverPin
} from "../types/actions/actionDriverIdentification";
import { UserResponseInfo } from "../types/user/userResponse.types";

export enum DRIVER_IDENTIFICATION_TYPES {
  REQUEST_VEHICLE_NAME_BY_ID = "REQUEST_VEHICLE_NAME_BY_ID",
  RECEIVE_VEHICLE_NAME_BY_ID = "RECEIVE_VEHICLE_NAME_BY_ID",
  GET_VEHICLE_NAME_BY_ID_FAILED = "GET_VEHICLE_NAME_BY_ID_FAILED",

  REQUEST_DRIVER_NAME_BY_PIN = "REQUEST_DRIVER_NAME_BY_PIN",
  RECEIVE_DRIVER_NAME_BY_PIN = "RECEIVE_DRIVER_NAME_BY_PIN",
  GET_DRIVER_NAME_BY_PIN_FAILED = "GET_DRIVER_NAME_BY_PIN_FAILED",

  REQUEST_DRIVERS_BY_NAME = "REQUEST_DRIVERS_BY_NAME",
  RECEIVE_DRIVERS_BY_NAME = "RECEIVE_DRIVERS_BY_NAME",
  GET_DRIVERS_BY_NAME_FAILED = "GET_DRIVERS_BY_NAME_FAILED",

  REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_PIN = "REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_PIN",
  RECEIVE_ASSIGN_VEHICLE_TO_DRIVER_BY_PIN = "RECEIVE_ASSIGN_VEHICLE_TO_DRIVER_BY_PIN",
  ASSIGN_VEHICLE_TO_DRIVER_BY_PIN_FAILED = "ASSIGN_VEHICLE_TO_DRIVER_BY_PIN_FAILED",

  REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID = "REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID",
  RECEIVE_ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID = "RECEIVE_ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID",
  ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID_FAILED = "ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID_FAILED",

  DRIVER_IDENTIFICATION_CLEAR_ERROR = "DRIVER_IDENTIFICATION_CLEAR_ERROR",

  CLEAR_DRIVER_NAME = "CLEAR_DRIVER_NAME",

  CLEAR_VEHICLE_DRIVER = "CLEAR_VEHICLE_DRIVER",

  SET_DRIVER_IDENTIFICATION_VEHICLE_ID = "SET_DRIVER_IDENTIFICATION_VEHICLE_ID",

  REQUEST_VEHICLE_QR_CODE = "REQUEST_VEHICLE_QR_CODE",

  REQUEST_SELECTED_VEHICLES_PDF_QR_CODE = "REQUEST_SELECTED_VEHICLES_PDF_QR_CODE",

  LOAD_DRIVER_PIN = "LOAD_DRIVER_PIN"
}

// Type "any" needs to change to a more specific type later
export const requestVehicleNameById = withMatcher(
  (data: string): RequestVehicleNameById => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.REQUEST_VEHICLE_NAME_BY_ID,
      data
    );
  }
);

export const receiveVehicleNameById = withMatcher(
  (data: string): ReceiveVehicleNameById => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.RECEIVE_VEHICLE_NAME_BY_ID,
      data
    );
  }
);

export const getVehicleNameByIdFailed = withMatcher(
  (data: any): GetVehicleNameByIdFailed => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.GET_VEHICLE_NAME_BY_ID_FAILED,
      data
    );
  }
);

export const requestDriverNameByPin = withMatcher(
  (data: { vehicleId: string; pin: string }): RequestDriverNameByPin => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.REQUEST_DRIVER_NAME_BY_PIN,
      data
    );
  }
);

export const receiveDriverNameByPin = withMatcher(
  (data: string): ReceiveDriverNameByPin => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.RECEIVE_DRIVER_NAME_BY_PIN,
      data
    );
  }
);

export const getDriverNameByPinFailed = withMatcher(
  (data: {message: any}): GetDriverNameByPinFailed => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.GET_DRIVER_NAME_BY_PIN_FAILED,
      data
    );
  }
);

export const requestDriversByName = withMatcher(
  (data: { searchTerm: string; vehicleId: string }): RequestDriversByName => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.REQUEST_DRIVERS_BY_NAME,
      data
    );
  }
);

export const receiveDriversByName = withMatcher(
  (data: UserResponseInfo[]): ReceiveDriversByName => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.RECEIVE_DRIVERS_BY_NAME,
      data
    );
  }
);

export const getDriversByNameFailed = withMatcher(
  (data: any): GetDriversByNameFailed => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.GET_DRIVERS_BY_NAME_FAILED,
      data
    );
  }
);

export const driverIdentificationClearError = withMatcher(
  (): DriverIdentificationClearError => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.DRIVER_IDENTIFICATION_CLEAR_ERROR
    );
  }
);

export const clearDriverName = withMatcher((): ClearDriverName => {
  return createAction(DRIVER_IDENTIFICATION_TYPES.CLEAR_DRIVER_NAME);
});

export const requestAssignVehicleToDriverByPin = withMatcher(
  (data: {
    vehicleId: string;
    pin: string;
    tripType: string;
  }): RequestAssignVehicleToDriverByPin => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_PIN,
      data
    );
  }
);

export const receiveAssignVehicleToDriverByPin = withMatcher(
  (data: any): ReceiveAssignVehicleToDriverByPin => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.RECEIVE_ASSIGN_VEHICLE_TO_DRIVER_BY_PIN,
      data
    );
  }
);

export const assignVehicleToDriverByPinFailed = withMatcher(
  (data: any): AssignVehicleToDriverByPinFailed => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.ASSIGN_VEHICLE_TO_DRIVER_BY_PIN_FAILED,
      data
    );
  }
);

export const requestAssignVehicleToDriverByDriverId = withMatcher(
  (data: {
    vehicleId: string;
    driverId: string;
    tripType: string
  }): RequestAssignVehicleToDriverByDriverId => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID,
      data
    );
  }
);

export const receiveAssignVehicleToDriverByDriverId = withMatcher(
  (data: any): ReceiveAssignVehicleToDriverByDriverId => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.RECEIVE_ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID,
      data
    );
  }
);

export const assignVehicleToDriverByDriverIdFailed = withMatcher(
  (data: any): AssignVehicleToDriverByDriverIdFailed => {
    return createAction(
      DRIVER_IDENTIFICATION_TYPES.ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID_FAILED,
      data
    );
  }
);

export const clearVehicleDriver = withMatcher((): ClearVehicleDriver => {
  return createAction(DRIVER_IDENTIFICATION_TYPES.CLEAR_VEHICLE_DRIVER);
});

export const setVehicleId = withMatcher((data: string): SetVehicleId => {
  return createAction(DRIVER_IDENTIFICATION_TYPES.SET_DRIVER_IDENTIFICATION_VEHICLE_ID, data);
});

export const requestVehicleQrCode = withMatcher((data: string): RequestQrCodeByVehicleId => {
  return createAction(DRIVER_IDENTIFICATION_TYPES.REQUEST_VEHICLE_QR_CODE, data)
});

export const requestSelectedVehiclePdfQrCode = withMatcher((data: string[]): RequestSelectedVehicleQrCode => {
  return createAction(DRIVER_IDENTIFICATION_TYPES.REQUEST_SELECTED_VEHICLES_PDF_QR_CODE, data)
});

export const loadDriverPin = withMatcher((): LoadDriverPin => {  
  return createAction(DRIVER_IDENTIFICATION_TYPES.LOAD_DRIVER_PIN)
});