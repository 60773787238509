import { divIcon } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { requestLiveLocation } from "../../../../actions/actionLiveLocation";
import { requestVehicleById } from "../../../../actions/actionVehicle";
import CarGeofencesModal from "./CarGeofencesModal";
import Direction from "../../../../icons/direction.svg";
import LoadingIndicator from "../../../common/LoadingIndicator";

const LiveLocationGeofencesModal = (props) => {
    const { vehicleId, onClose } = props;

    const dispatch = useDispatch();
    const liveVehiclesLocation = useSelector(state => state.lastVehiclesLocation.entities);
    const clickedVehicle = useSelector(state => state.vehicles.current);
    const me = useSelector(state => state.users.me);

    const [isPopupShown, setIsPopupShown] = useState(false);
    // create refs for markers
    const markerRefs = useRef({});

    const vehicles = [vehicleId];

    useEffect(() => {
        dispatch(requestVehicleById(vehicleId));
    }, [])

    useEffect(() => {
        let liveLocationTimer = setTimeout(() => {
            dispatch(requestLiveLocation({ vehicles }));
        }, me.liveLocationIntervalInSeconds * 1000 || 30000);

        return () => {
            clearTimeout(liveLocationTimer);
        }
    }, [liveVehiclesLocation]);

    // set the popup after the map is refreshed (refreshed becuase we send a new request and we get new data)
    useEffect(() => {
        if (isPopupShown) {
            const markerToOpen = markerRefs.current[vehicleId];
            var popUpOpened = markerToOpen?.openPopup();
        }
    });

    // if needed later for all cars we can use this configuration, otherwise it is not needed since there is only one car
    function onPopupOpen(id) {
        setIsPopupShown(false);
        // send request only if we are clicking another vehicle and not the one that we already have info
        if (clickedVehicle.id !== id) {
            dispatch(requestVehicleById(id));
        }
        setIsPopupShown(true);
    }

    const getVehicleIcon = (moving, heading) => {
        return divIcon({
            html: renderToStaticMarkup(
                <div className={moving ? "container-circle pulsating-circle" : "container-circle"}>
                    <img style={{ transform: "rotate(" + heading + "deg)" }} className="navigationIcon" src={Direction} />
                </div>,
            ),
        });
    };

    function renderPopupContent(pos) {
        if (clickedVehicle) {
            let driver = clickedVehicle.driver != null ? clickedVehicle.driver.name : "Kein Fahrer zugeteilt";
            return (
                <div>
                    Fahrzeugname: {clickedVehicle.name} <br />
                    Kennzeichen: {clickedVehicle.licensePlate} <br />
                    Fahrer: {driver} <br />
                    Datum: {new Date(pos.deviceTime).toLocaleString()} <br />
                </div>
            );
        } else {
            return <LoadingIndicator />;
        }
    }

    const isCarDriving = (speed) => {
        return speed > 0;
    };

    function getTooltip(pos) {
        const speed = isCarDriving(pos.speed) ? " (" + pos.speed + " km/h)" : "";
        return (<Tooltip direction="bottom" permanent>
            {pos.licensePlate} {speed}
        </Tooltip>);
    }

    const vehicleData = liveVehiclesLocation?.map(pos => {
        var posObj = { lat: pos.latitude, lng: pos.longitude };
        const vehicleIcon = getVehicleIcon(isCarDriving(pos.speed), pos.heading);
        return (
            <Marker key={pos.id} position={posObj} icon={vehicleIcon}
                ref={(m) => {  // <--- add marker refs to ref each marker so that we can open it again after sending request for fetching data
                    markerRefs.current[pos.id] = m;
                }}>
                {getTooltip(pos)}
                <Popup offset={[0, -23]} autoPan={false} keepInView={true} onClose={() => {
                    setIsPopupShown(false);
                    clickedVehicle.id = null;
                }}
                    onOpen={() => onPopupOpen(pos.id)}>
                    {renderPopupContent(pos)}
                </Popup>
            </Marker>
        );
    })

    return (
        <CarGeofencesModal
            vehicleId={vehicleId}
            isLiveLocation
            onClose={() => {
                onClose();
                dispatch(requestLiveLocation({}));
            }
            }>
            {vehicleData}
        </CarGeofencesModal>
    );
}


export default LiveLocationGeofencesModal;