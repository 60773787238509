import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Input, Switch, Form, Select, Slider } from "antd";
import WizardModal from "../../common/modals/wizard-modal/WizardModal";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import VehicleProfileSelect from "../../common/selects/specificSelects/VehicleProfileSelect";
import DriveboxSelect from "../../common/selects/specificSelects/DriveboxSelect";
import UserSelect from "../../common/selects/specificSelects/UserSelect";

import { requestAddVehicle } from "../../../actions/actionVehicle";
import { getIdsFromGroupValues } from "../../../managers/groupManager";
import FormBuilder from "../../../managers/formBuilder";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { WIZARD_TYPES } from "../../common/modals/wizard-modal/WizardModal";
import { useWizardSteps } from "../../../hooks/useModalStatus";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { useValidateForm } from "../../../hooks/useValidateForm";
import {makeStyles} from "@material-ui/core/styles";
import { packages } from "../../../models/Package";
import { useTranslation } from "react-i18next";


const DEFAULT_INPUT_VALUES = {
  name: null,
  licensePlate: null,
  model: null,
  description: null,
  driveType: null,
  consumptionThreshold: [4, 9],
  tankSize: null,
  type: null,
  ownerId: null,
  groupValue: undefined,
  assignTripsToOwner: false,
  isPrivateTripAllowed: false,
  driveboxId: null,
  costcenterId: null,
  mileageInKm: 0,
};

const useStyles = makeStyles({
  inputWrapper: {
    display: "flex",
    alignItems: "center"
  },
  inputText: {
    fontSize: 14,
    marginLeft: 13,
    marginBottom: 0
  },
  thresholdLowBox: {
    fontSize: 14,
    marginBottom: 0
  },
  thresholdBox: {
    border: "1px solid #D9D9D9 ",
    borderRadius: 3,
    padding: 3,
    marginRight: 5,
  },
  square: {
    width: 22,
    height: 22,
    border: "1px solid #D9D9D9 ",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    marginLeft: 2
  }
});

const VehicleAddModal = (props) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { tree, onClose, me } = props;
  const { submittable } = useValidateForm(form);
  const { handleInputChange, fields, setFields } =
    useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const [vehicle, setVehicle] = useState({});
  const { step, nextStep, prevStep } = useWizardSteps();
  const isFuelMonitorActive = me.packages.includes(packages.FuelMonitor);
  const { t } = useTranslation();

  const {
    name,
    licensePlate,
    model,
    engineType,
    tankSize,
    description,
    consumptionThreshold,
    type,
    ownerId,
    groupValue,
    assignTripsToOwner,
    isPrivateTripAllowed,
    driveboxId,
    mileageInKm,
    costcenterId,
  } = fields;

  const [ consumptionThresholdLow, consumptionThresholdHigh ] = consumptionThreshold;

  fields.consumptionThresholdLow = consumptionThresholdLow;
  fields.consumptionThresholdHigh = consumptionThresholdHigh;

  const vehicleUnit = engineType === 0 ? "kWh" : "Litres"
  const addANewVehicle = () => {
    const groupIds = getIdsFromGroupValues(tree, fields.groupValue);
    props.requestAddVehicle({
      ...fields,
      groupIds: groupIds,
      mileageInMeters: fields.mileageInKm * 1000,
    });
  };

  useEffect(() => {
    if(step === 4) onClose();
  }, [step]);

  useEffect(() => {
    type === 2 ? setFields({ ...fields , assignTripsToOwner: false}) : setFields({ ...fields, isPrivateTripAllowed: false });
  }, [type]);

  const handleNext = (values) => {   
    if (step == 3) {
      addANewVehicle();
    }

    nextStep();
  };

  const handleBack = () => {
    prevStep();
  };

  const canBack = () => {
    return true;
  };

  const isVehicleAddModalValidated = () => {
    if(step === 1) {
      if((type === 0 || type === 1) && ownerId) return true;
      else if(type === 2) return true;
    }
    return false;
  }

  const page1 = {
    title: t("vehicle.addmodal.page1.title"),
    inputs: [
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page1.name"),
        (
          <Input
            value={name}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        )
      )
        .withValue(name)
        .withKey("name")
        .withValidationMessage(
          t("vehicle.addmodal.page1.nameValidation")
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page1.licensePlate"),
        (
          <Input
            value={licensePlate}
            onChange={(e) => handleInputChange("licensePlate", e.target.value)}
          />
        )
      )
        .withValue(licensePlate)
        .withKey("licensePlate")
        .withValidationMessage(
          t("vehicle.addmodal.page1.licensePlateValidation")
        )
        .isRequired()
        .build(),
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page1.model"),
        (
          <Input
            value={model}
            onChange={(e) => handleInputChange("model", e.target.value)}
          />
        )
      )
        .withValue(model)
        .withKey("model")
        .withValidationMessage(
          t("vehicle.addmodal.page1.modelValidation")
        )
        .isRequired()
        .build(),


      new FormBuilder.FormInput(
        t("vehicle.addmodal.page1.description"),
        (
          <Input.TextArea
            value={description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            autosize={{ minRows: 3, maxRows: 5 }}
          />
        )
      )
        .withValue(description)
        .withKey("description")
        .build(),
    ],
  };

  if(isFuelMonitorActive){
    page1.inputs.splice(3, 0,
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page1.driveType"), ( <Select value={engineType} onChange={(e) => handleInputChange("engineType", e)} options={[{value: 'None', label: 'Not specified' }, {value: 0, label: 'Electric Car'}, {value: 1, label: 'Fuel'}]}/>))
        .withValue(engineType)
        .withKey("engineType")
        // .withValidationMessage(<Translate id="vehicle.addmodal.page1.driveTypeValidation" />)
        // .isRequired()
        .build(),
      )

      if(engineType === 0 || engineType === 1){
        page1.inputs.splice(4, 0,
          new FormBuilder.FormInput(
            t("vehicle.addmodal.page1.tankSize"), ( <div className={classes.inputWrapper} ><Input value={tankSize} onChange={(e) => handleInputChange("tankSize", e.target.value)}/><p className={classes.inputText} >{vehicleUnit}</p></div>))
            .withValue(tankSize)
            .withKey("tankSize")
            .withValidationMessage(t("vehicle.addmodal.page1.tankSizeValidation"))
            .isRequired()
            .build(),
      
          new FormBuilder.FormInput(t("vehicle.addmodal.page1.threshold"), ( <div className={classes.inputWrapper}>
            <p className={classes.thresholdLowBox}>
              <span className={classes.square}>{consumptionThresholdLow}
              </span>
            </p><Slider range max={40} value={consumptionThreshold} onChange={(e) => handleInputChange("consumptionThreshold", e)} style={{ flex: 1 }}/>
            <p className={classes.inputText}><span className={classes.thresholdBox}>{consumptionThresholdHigh}</span>{vehicleUnit}/100km</p></div>))
            .withValue(consumptionThreshold)
            .withKey("consumptionThreshold")
            .withValidationMessage(t("vehicle.addmodal.page1.thresholdValidation"))
            .isRequired()
            .build(),  
        )
      }
  }

  //Magic strings incoming
  const page2 = {
    title: t("vehicle.addmodal.page2.title"),
    inputs: [
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page2.vehicleProfile"),
        (
          <VehicleProfileSelect
            value={type}
            onChange={(value) => handleInputChange("type", value)}
          />
        )
      )
        .withValue(type)
        .withKey("type")
        .withValidationMessage(
          t("vehicle.addmodal.page2.vehicleProfileValidation")
        )
        .isRequired()
        .build(),
    ],
  };

  if (type == 0 || type == 1) {
    page2.inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page2.owner"),
        (
          <UserSelect
            driverId={ownerId}
            onChange={(value) => handleInputChange("ownerId", value)}
          />
        )
      )
        .withValue(ownerId)
        .withKey("ownerId")
        .withValidationMessage(
          t("vehicle.addmodal.page2.ownerValidation")
        )
        .isRequired()
        .build()
    );
    page2.inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page2.group"),
        (
          <TreeSelect
            value={groupValue}
            onChange={(value) => handleInputChange("groupValue", value)}
          />
        )
      )
        .withValue(groupValue)
        .withKey("groupValue")
        .build()
    );
    page2.inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page2.assignToOwner"),
        (
          <Switch
            checked={assignTripsToOwner}
            onChange={(value) => handleInputChange("assignTripsToOwner", value)}
          />
        )
      )
        .withValue(assignTripsToOwner)
        .withKey("assignTripsToOwner")
        .withValuePropName("checked")
        .build()
    );
  }
  if (type == 2) {
    page2.inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page2.group"),
        (
          <TreeSelect
            value={groupValue}
            onChange={(value) => handleInputChange("groupValue", value)}
          />
        )
      )
        .withValue(groupValue)
        .withKey("groupValue")
        .build()
    );
    page2.inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page2.allowPrivateTrips"),
        (
          <Switch
            checked={isPrivateTripAllowed}
            onChange={(value) =>
              handleInputChange("isPrivateTripAllowed", value)
            }
          />
        )
      )
        .withValue(isPrivateTripAllowed)
        .withKey("isPrivateTripAllowed")
        .withValuePropName("checked")
        .build()
    );
  }

  const page3 = {
    title: t("vehicle.addmodal.page3.title"),
    inputs: [
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page3.driveBox"),
        (
          <DriveboxSelect
            value={driveboxId}
            onChange={(value) => handleInputChange("driveboxId", value)}
            showGroups
          />
        )
      )
        .withValue(driveboxId)
        .withKey("driveboxId")
        .build(),
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page3.costcenter"),
        (
          <CostCenterSelect
            value={costcenterId}
            onChange={(value) => handleInputChange("costcenterId", value)}
            showGroups
            showArchived={false}
          />
        )
      )
        .withValue(costcenterId)
        .withKey("costcenterId")
        .build(),
    ],
  };

  const page4 = {
    title: t("vehicle.addmodal.page4.title"),
    inputs: [
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page4.mileage"),
        (
          <Input
            value={mileageInKm}
            onChange={(e) => handleInputChange("mileageInKm", e.target.value)}
            suffix="km"
          />
        )
      )
        .withValue(mileageInKm)
        .withKey("mileageInKm")
        .withValidationMessage(
          t("vehicle.addmodal.page4.mileageValidation")
        )
        .isRequired()
        .build(),
    ],
  };

  const pages = [page1, page2, page3, page4];


  return (
    <WizardModal
      onClose={onClose}
      canBack={canBack}
      isVehicleAddModalValidated={isVehicleAddModalValidated}
      onNext={handleNext}
      onBack={handleBack}
      title={t("vehicle.addmodal.title")}
      pages={pages}
      form={form}
      submittable={submittable}
      step={step}
      wizardType={WIZARD_TYPES.vehicle.name}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ requestAddVehicle }, dispatch);
}

function mapStateToProp(state) {
  return {
    roles: state.roles,
    vehicle: state.vehicles.current,
    error: state.vehicles.error,
    tree: state.tree,
    me: state.users.me
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(VehicleAddModal);