import {
    RECEIVE_ADD_TIME_RECORDINGS,
    RECEIVE_DELETE_TIME_RECORDINGS,
    RECEIVE_TIME_RECORDINGS,
    RECEIVE_TIME_RECORDINGS_BY_ID,
    RECEIVE_UPDATE_TIME_RECORDINGS
} from "../actions/actionTimeRecording";
import {deleteEntity, getEntityById, getPagedEntities} from "../managers/reducerManager";

const initialState = {
    current: {},
    entities: [],
};

export const timeRecordingDetail = (state = initialState, {type, data}) => {
    switch (type) {
        case RECEIVE_TIME_RECORDINGS:
            return getPagedEntities(state, data);
        case RECEIVE_TIME_RECORDINGS_BY_ID:
        case RECEIVE_UPDATE_TIME_RECORDINGS:
        case RECEIVE_ADD_TIME_RECORDINGS:
            return getEntityById(state, data);
        case RECEIVE_DELETE_TIME_RECORDINGS:
            return deleteEntity(state, data);
        default:
            return state;
    }
};
