import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { DayPilotScheduler } from "daypilot-pro-react";
import { Card, CardContent } from "@material-ui/core";
import ViewTypes from "./ViewTypes";

import "./testtheme.css";
import "./simpletheme.css";
import moment from "moment";

const styles = theme => ({
    timelineHeader: {
        backgroundColor: "#FFF !important",
    },
});
const CustomTimeline = ({ from, groups, items, viewType, setTrip, onTrip, beginsHour, endsHour, countCustomDays }) => {
    const scheduler = useRef(null);

    const dayConfig = {
        days: 1,
        scale: "CellDuration",
        cellDuration: 15,
        timeHeaders: [
            {
                groupBy: "Day",
                format: "dddd, d MMMM yyyy",
            },
            {
                groupBy: "Hour",
            },
        ],
        startDate: moment(from).local().add(2, "hour").toDate(),
    };

    const weekConfig = {
        scale: "Day",
        days: 7,
        timeHeaders: [
            {
                groupBy: "Month",
            },
            {
                groupBy: "Day",
                format: "d",
            },
        ],
        startDate: moment(from).local().add(6, "hour").toDate(),
    };

    const customConfig = countCustomDays === 2 ?
    {
        days: 1,
        scale: "CellDuration",
        cellDuration: 15,
        timeHeaders: [
            {
                groupBy: "Day",
                format: "dddd, d MMMM yyyy",
            },
            {
                groupBy: "Hour",
            },
        ],
        startDate: moment(from).local().add(2, "hour").toDate(),
    }
    :
    {
        scale: "Day",
        days: countCustomDays,
        timeHeaders: [
            {
                groupBy: "Month",
            },
            {
                groupBy: "Day",
                format: "d",
            },
        ],
        startDate: moment(from).local().add(6, "hour").toDate(),
    } 

    const defaultConfig = {
        durationBarVisible: false,
        eventHeight: 40,
        showNonBusiness: viewType !== ViewTypes.Day,
        businessBeginsHour: beginsHour,
        businessEndsHour: endsHour,
        cellWidthSpec: "Auto",
        resources: groups,
        events: items,
        locale: "de-de",
        businessWeekends: true,
        timeRangeSelectedHandling: "Disabled",
        eventMoveHandling: "Disabled",
        eventResizeHandling: "Disabled",
        eventDeleteHandling: "Disabled",
        eventHoverHandling: "Bubble",
        eventClickHandling: "Enabled",
        theme: "simple",
    };

    const config = viewType == ViewTypes.Day ? dayConfig : viewType == ViewTypes.Week ? weekConfig : customConfig;

    return (
        <Card>
            <CardContent>
                <DayPilotScheduler
                    {...config}
                    {...defaultConfig}
                    ref={scheduler}
                    onEventClicked={args => {
                        if (viewType == ViewTypes.Day && setTrip && onTrip) {
                            setTrip(args.e.data.trip);
                            onTrip();
                        } else if (viewType == ViewTypes.Custom && onTrip){
                            onTrip(args.e.data.reservation);
                        }
                    }}
                />
            </CardContent>
        </Card>
    );
};

CustomTimeline.defaultProps = {
    beginsHour: 0,
    endsHour: 24,
};
CustomTimeline.propTypes = {
    from: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.string.isRequired
    ]),
    groups: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    viewType: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]),
    setTrip: PropTypes.func,
    onTrip: PropTypes.func,
    beginsHour: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    endsHour: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])
};

export default withStyles(styles)(CustomTimeline);