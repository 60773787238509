import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { modalType } from "../CustomModal";
import { customColors } from "../../../geofences/generic/StylingOptions";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    flex: {
        flex: 1,
      },

    appBar: {
        position: "relative",
        paddingRight: " 0 !important",
        "& button.MuiIconButton-root": {
          marginRight: "-12px",
        },
      },
});

const CustomModalAppBar = ({ title, type, onClose }) => {
    const classes = useStyles();

    return (
      <AppBar
        color="primary"
        className={classes.appBar}
        style={
          type === modalType.warnType
            ? { backgroundColor: customColors.deleteDarkRed }
            : {}
        }
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {title}
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }

export default CustomModalAppBar;