import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { customColors } from '../../generic/StylingOptions';
import { getWeekDayName } from '../../utils/dateTimeUtils';
import { withTranslation } from 'react-i18next';

function WeekdayMenu(props) {
    let { Icon, tooltip, action, lockMethod, isDisabled, leftMost } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Tooltip enterDelay={500} title={tooltip}>
                <IconButton
                    id={ "menuButton_" + tooltip }
                    aria-controls={`menuButton_${tooltip}`}
                    style={{ color: (isDisabled ? customColors.disabled : customColors.dark), margin: '2px', padding: '15px', marginRight: '-10px', marginLeft: (leftMost ? '-10px' : '2px')}}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    <Icon/>
                </IconButton>
            </Tooltip>
            <Menu
                id={`menu_${tooltip}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem 
                    key={ "menuItem_" + tooltip + "_allDays" }
                    id={ "menuItem_" + tooltip + "_allDays" }
                    onClick={() => {
                        action(-1, lockMethod);
                        handleClose();
                    }}><b>{props.t('day.ALL')}</b>
                </MenuItem>
                {[1,2,3,4,5,6,0].map((weekday) => {
                    return (
                        <MenuItem
                            key={ "menuItem_" + tooltip + "_" + weekday }
                            id={ "menuItem_" + tooltip + "_" + weekday }
                            onClick={() => {
                                action(weekday, lockMethod);
                                handleClose();
                            }}>{props.t(`day.${getWeekDayName(weekday)}`)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

export default React.memo(withTranslation()(WeekdayMenu));
