import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import LoadingIndicator from "../common/LoadingIndicator";
import moment from "moment";

import {Card, CardContent, Typography} from "@material-ui/core";
import {Line} from "react-chartjs-2";
import {customTranslation, getTranslation} from "../../translations/customTranslation";

const styles = theme => ({
    card: {
        minWidth: 275,
        margin: "3px 5px",

        height: "400px",
    },
    title: {
        fontSize: 20,
    },

    chartContainer: {
        width: "100%",
        height: "100%",
        minHeight: "300px",
    },
});

const DashboardKmHistory = ({classes, title, loading, mileages, endMonth, me}) => {
    let months = [];
    let listOfMonths = [];
    if (mileages.length > 0) {
        let endMonthNumber = new Date(endMonth).getMonth() + 1;
        let currentMonth = mileages[0].month;

        listOfMonths.push(currentMonth);
        months.push(moment(currentMonth, "MM").format("MMMM"));

        while (endMonthNumber !== currentMonth && listOfMonths.length < 12) {
            if (currentMonth === 12) currentMonth = 0;
            currentMonth++;
            listOfMonths.push(currentMonth);
            months.push(moment(currentMonth, "MM").format("MMMM"));
        }
    }

    const totalEntries = mileages && mileages.length > 0 ? listOfMonths.map(month => {
        let mileageInfo = mileages.find(milage => milage.month === month);
        if (mileageInfo) return mileageInfo.sum / 1000;
        return 0;
    }) : [];

    const businessEntries = mileages && mileages.length > 0 ? listOfMonths.map(month => {
        let mileageInfo = mileages.find(milage => milage.month === month);
        if (mileageInfo) return mileageInfo.businessSum / 1000;
        return 0;
    }) : [];

    const privateEntries = mileages && mileages.length > 0 ? listOfMonths.map(month => {
        let mileageInfo = mileages.find(milage => milage.month === month);
        if (mileageInfo) return mileageInfo.privateSum / 1000;
        return 0;
    }) : [];

    const driveToWorkEntries = mileages && mileages.length > 0 ? listOfMonths.map(month => {
        let mileageInfo = mileages.find(milage => milage.month === month);
        if (mileageInfo) return mileageInfo.driveToWorkSum / 1000;
        return 0;
    }) : []; 
    
    const defaultDatasetProps = {
        fill: false,
        lineTension: 0.3,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderCapStyle: "round",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#2c387e",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#6573c3",
        pointHoverBorderColor: "#3f51b5",
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
    };

    //Translation do not work as a lable in dataset -> manuel translation :/
    const data = {
        labels: months,
        datasets: [
            {
                ...defaultDatasetProps,
                label: getTranslation(customTranslation.total),
                borderColor: "#3f51b5",
                data: totalEntries,
            },
            {
                ...defaultDatasetProps,
                label: getTranslation(customTranslation.business, me),
                borderColor: "#FF6384",
                data: businessEntries,
            },
            {
                ...defaultDatasetProps,
                label: getTranslation(customTranslation.private),
                borderColor: "#36A2EB",
                data: privateEntries,
            },
            {
                ...defaultDatasetProps,
                label: getTranslation(customTranslation.driveToWork, me),
                borderColor: "#FFCE56",
                data: driveToWorkEntries,
            },
        ],
    };


    return (
        <Card className={classes.card}>
            {!loading && (
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {title}
                    </Typography>

                    <div className={classes.chartContainer}>
                        <Line data={data} options={{maintainAspectRatio: false}} legend={{labels: {boxWidth: 2}}}/>
                    </div>
                </CardContent>
            )}
            {loading && <LoadingIndicator/>}
        </Card>
    );
};


function mapStateToProp(state) {
    return {
        me: state.users.me,
        mileages: state.reports.mileage,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withStyles(styles, {withTheme: true})(DashboardKmHistory));
