import {receiveUnAuthoriseUser} from "../actions/actionAuthentication";
import {clearGeoCode} from "../actions/actionGeoLocation";
import {receiveError} from "../actions/actionCommon";
import {customTranslation, getTranslation} from "../translations/customTranslation";

function handleError(error: any) {
        switch (error.response.status) {
            case 401:
                return receiveUnAuthoriseUser();
            case 500:
                return receiveError({
                    status: 500,
                    errors: {Error: getTranslation(customTranslation.error.internalServerError)}
                });
            default:
                return receiveError(error.response.data);
        }
}

function handleReportError(error: any) {
    if (error.response && error.response.data.title) return receiveError(error.response.data);
    return receiveError({
        status: 500,
        errors: {Error: getTranslation(customTranslation.error.internalServerError)}
    });
}

function handleGeoLocationError() {
    return clearGeoCode();
}

export {handleError, handleReportError, handleGeoLocationError};
