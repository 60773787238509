import React from "react";
import CustomModal from "../common/modals/CustomModal";
import { modalActions } from "../common/modals/CustomModal";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { requestDeleteReservation } from "../../actions/actionReservation";
import { useDispatch } from "react-redux";
import { modalType } from "../common/modals/CustomModal";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles({
    wrapper : {
        padding: 12
    },
    container:{
        display: "flex"
    },
    title:{
        fontSize: 19,
        textAlign: "center",
    },
    defaultSpacing: {
        marginRight: 5,
        fontSize: 14,
        textAlign: "center"
    }
})

const DeleteReservationModal = ({onClose, data, allowDelete}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { driver, vehicle, pickupDate, returnDate } = data;
    const { t } = useTranslation();

    function handleDeleteReservation() {
        dispatch(requestDeleteReservation(data.id));
        onClose();
    }

    const content = (
        <div className={classes.wrapper}>
            <p className={classes.title}>
                {t("reservation.deleteReservationModal.modalTitle")}
            </p>
            <div className={classes.container}>
                <Trans>
                    <p className={classes.defaultSpacing}>
                    {t("reservation.deleteReservationModal.message",
                        {firstName: driver.firstName, lastName: driver.lastName, vehicle: vehicle.name, pickupDate: moment(pickupDate).format("DD.MM.YYYY h:mm A"), returnDate: moment(returnDate).format("DD.MM.YYYY h:mm A")}
                        )}
                    </p>
                </Trans>
            </div>
        </div>
    )

    const cantDeleteContent = (
        <div className={classes.container}>
            <p className={classes.defaultSpacing}>{t("reservation.deleteReservationModal.cantDeleteMessage")}</p>
        </div>
    )

    return (
        allowDelete ? 
        (
            <CustomModal
            onClose={onClose}
            title={t("reservation.deleteReservationModal.title")}
            contents={[content]}
            onSave={handleDeleteReservation}
            action={modalActions.delete}
            type={modalType.warnType}
            />
        ) :
            <CustomModal
            onClose={onClose}
            title={t("reservation.deleteReservationModal.title")}
            contents={[cantDeleteContent]}
            action={modalActions.ok}
            />
    )
}

export default DeleteReservationModal;