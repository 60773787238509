import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
//Common
import EditModal from "../../common/modals/CustomModal";
import MailsInputField from "../../common/inputs/MailsInputField";

import {requestMandatorUsers, requestMandatorUserWelcomeMail} from "../../../actions/actionMandators";

const styles = {
    flex: {
        flex: 1,
    },
};

class MandatorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mails: [],
        };
    }

    componentDidMount() {
        const {user} = this.props;
        this.props.requestMandatorUsers({
            page: 1,
            perPage: 50,
            mandatorId: user.mandator.id,
            searchTerm: "",
        });
    }

    sendMail() {
        this.props.requestMandatorUserWelcomeMail({sendCopiesTo: this.state.mails, id: this.props.user.id});
    }

    handleOnSave = () => {
        this.sendMail();
        this.props.onClose();
    };

    handleMailChange = value => {
        this.setState({mails: value});
    };

    render() {
        const {users, user} = this.props;
        const generalDialogContent = (
            <div>
                <Typography>
                    Send welcome mail to {user.firstName} {user.lastName}.
                </Typography>
                <Typography>Send copy to:</Typography>
                <MailsInputField options={users.map(u => u.email)} onMailChange={this.handleMailChange}/>
            </div>
        );

        const contents = [generalDialogContent];
        return (
            <div>
                <EditModal action="confirm" onSave={this.handleOnSave} onClose={this.props.onClose} title="Welcome mail"
                           contents={contents}/>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestMandatorUserWelcomeMail, requestMandatorUsers}, dispatch);
}

function mapStateToProp(state) {
    return {
        users: state.users.entities,
    };
}

// MandatorModal.propTypes = {
//     classes: PropTypes.object.isRequired,
//     theme: PropTypes.object.isRequired,
//     user: PropTypes.object.isRequired,
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(MandatorModal));
