const possiblePersonGroup = {
    individual: 1,
    driver: 2,
    groups: 3,
    groupLeader: 4,
    admins: 5

};

const sendToAll = {
    admin: 0,
    groupLeader: 1,
    driver: 2
}


const possiblePersonGroupArray = [
    {id: possiblePersonGroup.individual, name: "Personen auswählen"},
    {id: possiblePersonGroup.admins, name: "An alle Administratoren senden"},
];

//If personGroup is a driver, groupLeader or admin map it to the server enum, else send undefined
const mapPersonGroupToSendToAllServerEnum = (personGroup) => {
    switch (personGroup) {
        case possiblePersonGroup.driver:
            return sendToAll.driver;
        case possiblePersonGroup.groupLeader:
            return sendToAll.groupLeader;
        case possiblePersonGroup.admins:
            return sendToAll.admin;
        default:
            return undefined;
    }
}

const mapSendToAllServerEnumToPersonGroup = value => {
    switch (value) {
        case sendToAll.driver:
            return possiblePersonGroup.driver;
        case sendToAll.groupLeader:
            return possiblePersonGroup.groupLeader;
        case sendToAll.admin:
            return possiblePersonGroup.admins;
        default:
            return undefined;
    }
}


export {
    possiblePersonGroup,
    possiblePersonGroupArray,
    mapPersonGroupToSendToAllServerEnum,
    mapSendToAllServerEnumToPersonGroup
};
