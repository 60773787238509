import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ChangePasswordModal from "../common/modals/ChangePasswordModal";
import { requestResetMomentPassword } from "../../actions/actionUser";
import { Button, Card, CardContent, CardHeader, CardMedia, Typography } from "@material-ui/core";
import DriveboxLogo from "../../images/DriveboxLogo.svg";
import { useModalStatus } from "../../hooks/useModalStatus";
import { menu } from "../../managers/pathManager";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    card: {
        maxWidth: 900,
        margin: "auto",
        marginTop: 20,
    },
    button: {
        marginTop: "10px",
    },
    paragraph: {
        marginTop: "4px",
    },
}));

const MomentPasswordResetPage = props => {
    const [resetPassword, setResetPasswort] = useState(false);
    const { modal: passwodModal, open: openPassword } = useModalStatus();

    const classes = useStyles();
    const { me } = props;

    function handleResetPassword(oldPassword, newPassword) {
        props.requestResetMomentPassword({
            newPassword: newPassword,
        });
        setResetPasswort(true);
    }

    if (resetPassword || !me.hasAMomentPassword) {
        return <Redirect to={menu.dashboard.path}/>;
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Card className={classes.card}>
                <CardHeader title="Wilkommen im neuen Driveboxsystem"/>
                <CardMedia
                    image={DriveboxLogo}
                    title="Drivebox"
                />
                <CardContent>
                    <Typography className={classes.paragraph} variant="body1" component="p">
                        Wilkommen im schnellsten Flottentrackingsystem. Wir bei Drivebox haben bewiesen, dass wir
                        wissen, wie wir global wachsen können. Wir sind nicht nur stolz auf unsere Funktionen, sondern
                        auch auf unsere benutzerfreundliche
                        Administration und einfache Konfiguration.
                    </Typography>


                    <Typography className={classes.paragraph} variant="body1" component="p">
                        Um loszulegen setzen sie ein neues Passwort
                    </Typography>

                    <Button className={classes.button} variant="contained" color="primary" onClick={openPassword}>
                        Password setzen
                    </Button>
                </CardContent>
            </Card>
            {passwodModal && <ChangePasswordModal onConfirm={handleResetPassword}/>}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestResetMomentPassword }, dispatch);
}

function mapStateToProp(state) {
    return { me: state.users.me, error: state.error };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MomentPasswordResetPage);