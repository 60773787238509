import { useState, useEffect } from 'react';
import { Form } from "antd";

export const useValidateForm = (form) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  return { submittable, setSubmittable };
};
