import React from "react";
import {connect, useSelector, shallowEqual} from "react-redux";
import GenericTable from "../common/table/GenericTable";
import TableTypes from "../../models/TableTypes";
import Comparator from "../../models/Comparator";
import {allowedRoles, isAuthorized, isViewer} from "../../managers/authManager";
import {packages} from "../../models/Package";
import {roles} from "../../models/Role";
import { useTranslation } from "react-i18next";


const UserTable = props => {
    const users = useSelector(state => state.users.entities);

    const selected = useSelector(state => state.users.selected);
    const total = useSelector(state => state.users.total);
    const me = useSelector(state => state.users.me);
    const { t } = useTranslation();

    const {
        archive,
        page,
        rowsPerPage,
        onEdit,
        onSelect,
        onSelectAll,
        onPageChange,
        onRowsPerPageChange,
        onHistory,
        onArchive,
        onUnArchive,
        onPasswordChangeByAdmin,
    } = props;

    const viewer = isViewer(me);

    const columnInformations = [
        {title: t("user.table.foreignKey"), key: "foreignId"},
        {title: t("user.table.firstname"), key: "firstName"},
        {title: t("user.table.lastname"), key: "lastName"},
        {title: t("user.table.email"), key: "email"},
        {title: t("user.table.description"), key: "description"},
        {title: t("user.table.groups"), key: "groups", type: TableTypes.group},
        {title: t("user.table.role"), key: "role.name"},
    ];

    const archivedColumnInformations = [
        {title: t("user.table.foreignKey"), key: "foreignId"},
        {title: t("user.table.firstname"), key: "firstName"},
        {title: t("user.table.lastname"), key: "lastName"},
        {title: t("user.table.email"), key: "email"},
        {title: t("user.table.description"), key: "description"},
        {title: t("user.table.groups"), key: "groups", type: TableTypes.group},
        {title: t("user.table.role"), key: "role.name"},
        {title: t("user.table.archiveComment"), key: "archiveComment"},
    ];

    const actions = [
        {title: t("user.table.edit"), event: onEdit},
        {title: t("user.table.history"), event: onHistory},
        {
            title: t("user.table.archive"),
            event: onArchive,
            comparator: new Comparator(["archive"], (archive) => !archive),
        },
        {
            title: t("user.table.deArchive"),
            event: onUnArchive,
            comparator: new Comparator(["archive"], (archive) => archive),
        },
        {
            title: t("user.table.changePassword"),
            event: onPasswordChangeByAdmin,
        },
    ];

    const viewerActions = [
        {title: t("user.table.history"), event: onHistory},
    ];

    const mappedUsers = users.map(u => {
        const {archivedAt} = u;
        u.archive = Boolean(archivedAt);
        return u;
    });

    if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter])) 
        columnInformations.push({title: t("user.table.costCenter"), key: "costcenterKey"});

    if (isAuthorized(me, allowedRoles(roles.Admin), [packages.DriverIdentification])) {
        columnInformations.push({title: t("user.table.pin"), key: "driverIdentificationPin", type: TableTypes.driverPin});
    }
    
    return (
        <GenericTable
            data={mappedUsers}
            columnInformations={archive ? archivedColumnInformations : columnInformations}
            actions={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? viewerActions : actions}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            onEdit={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? () => console.log("") : onEdit}
            onRowClick={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? () => console.log("") : onEdit}
            handleChangePage={onPageChange}
            handleChangeRowsPerPage={onRowsPerPageChange}
            onSelectAllClick={onSelectAll}
            onSelect={onSelect}
            numSelected={selected}
            showActions
            showCheckboxes={!viewer}
        />
    );
};

UserTable.defaultProps = {
    page: 0,
};

export default UserTable;
