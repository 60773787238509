function formatTimeStamp(timestamp) {
    let date = new Date(timestamp * 1000);
    return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' +
        (date.getMonth()+1 < 10 ? '0' : '') + (date.getMonth()+1) + '.' +
        date.getFullYear() + ', ' +
        (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' +
        (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();}

function getWeekDayName(weekday) {
    switch (weekday) {
      case 0: return 'SUN';
      case 1: return 'MON';
      case 2: return 'TUE';
      case 3: return 'WED';
      case 4: return 'THU';
      case 5: return 'FRI';
      case 6: return 'SAT';
      default: return 'Unknown day';
    }
  }

export {
    formatTimeStamp,
    getWeekDayName,
}