import React from "react";
import {connect} from "react-redux";
import GenericTable from "../common/table/GenericTable";
import Comparator from "../../models/Comparator";
import {isViewer} from "../../managers/authManager";
import { useTranslation } from "react-i18next";

const DriveboxTable = props => {
    const {driveboxes, total, page, rowsPerPage, onEdit, handleChangePage, handleChangeRowsPerPage, onPlugged, me} = props;
    const { t } = useTranslation();

    const viewer = isViewer(me);

    const columnInformations = [
        {title: t("drivebox.table.imei"), key: "imei"},
        {
            title: t("drivebox.table.vehicle"),
            key: "vehiclename",
            color: {value: "red", comparator: new Comparator(["hasVehicle"], (hasVehicle) => !hasVehicle)},
        },
        {title: t("drivebox.table.model"), key: "model"},
    ];
    const actions = [{title: t("drivebox.table.edit"), event: onEdit}, {
        title: t("drivebox.table.pluggedEvents"), event: onPlugged
    }];

    const mappedDriveBoxes = driveboxes.map(d => {
        d.vehiclename = d.vehicle ? d.vehicle.name : t("drivebox.table.noVehicleAssigned");
        d.hasVehicle = Boolean(d.vehicle);
        return d;
    });

    return (
        <GenericTable
            data={mappedDriveBoxes}
            columnInformations={columnInformations}
            actions={actions}
            showActions={!viewer}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            onEdit={viewer ? () => console.log("") : onEdit}
            onRowClick={viewer ? () => console.log("") : onEdit}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            numSelected={driveboxes.filter(d => d.isSelected).length}
        />
    );
};

DriveboxTable.defaultProps = {
    page: 0,
};

function mapStateToProp(state) {
    return {
        driveboxes: state.driveboxes.entities,
        total: state.driveboxes.total,
        me: state.users.me,
    };
}

export default connect(mapStateToProp)(DriveboxTable);
