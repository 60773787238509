import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import EditModal from "../../common/modals/CustomModal";
import EmptyResult from "../../common/views/EmptyResult";
import {Button} from "@material-ui/core";

import {Timeline} from "antd";
import {clearHistory, requestHistoryById} from "../../../actions/actionHistories";
import {format} from "date-fns";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    timeline: {
        marginTop: "30px",
    },
});

class HistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 50,
        };
    }

    componentDidMount() {
        this.requestHistoryById();
    }

    componentWillUnmount() {
        this.props.clearHistory();
    }

    requestHistoryById() {
        const {page, rowsPerPage} = this.state;
        this.props.requestHistoryById({id: this.props.id, page: page + 1, perPage: rowsPerPage});
    }

    requestMoreData = () => {
        this.setState({page: this.state.page + 1}, this.requestHistoryById);
    };

    render() {
        const {classes, onClose, history, total} = this.props;

        const timelineView = (
            <div>
                <Timeline className={classes.timeline}>
                    {history.map(entry => (
                        <Timeline.Item>
                            <p>
                                <b>{entry.propertyName} Änderung</b>
                                <br/>
                                {!entry.oldValue && entry.newValue && '"' + entry.newValue + '" wurde hinzugefügt'}
                                {!entry.newValue && entry.oldValue && '"' + entry.oldValue + '" wurde entfernt'}
                                {entry.oldValue && entry.newValue && '"' + entry.oldValue + '" änderte sich zu "' + entry.newValue + '"'}
                                <br/>
                                {format(new Date(entry.changedAt), "dd.MM.yy H:mm")} von {entry.user.name}
                            </p>
                        </Timeline.Item>
                    ))}
                </Timeline>
                {total > history.length && <Button onClick={this.requestMoreData}>Load more</Button>}
            </div>
        );

        const noEntriesFound = <EmptyResult onClose={onClose}/>;

        return <EditModal onClose={onClose} title="History"
                          contents={history.length == 0 ? [noEntriesFound] : [timelineView]}/>;
    }
}

// HistoryModal.propTypes = {
//     id: PropTypes.string.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({requestHistoryById, clearHistory}, dispatch);
}

function mapStateToProp(state) {
    return {history: state.history.entities, total: state.history.total};
}

export default withStyles(styles, {withTheme: true})(
    connect(
        mapStateToProp,
        mapDispatchToProps
    )(HistoryModal)
);
