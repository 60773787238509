import {createStore, applyMiddleware} from "redux";

import rootReducer from "./reducers/rootReducer";

import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga/rootSaga";

export type RootState = ReturnType<typeof rootReducer>;


const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
