import { takeLatest, put, call, takeEvery } from "typed-redux-saga/macro";

import {
  RequestDriveboxes,
  RequestDriveboxById,
  RequestUpdateDrivebox,
  RequestPluggedEvents,
} from "../types/actions/actionDrivebox.types";

import {
  receiveDriveboxes,
  receiveDriveboxById,
  receiveUpdateDrivebox,
  receivePluggedEvents,
  DRIVEBOX_ACTION_TYPES,
} from "../actions/actionDriveboxes";

import { handleError } from "../managers/sagaManager";

import {
  getDriveboxes,
  getDriveboxById,
  updateDrivebox,
  getPluggedEvents,
} from "./api";

function* getDriveboxesData(action: RequestDriveboxes) {
  try {
    const response = yield* call(getDriveboxes, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDriveboxes(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getDriveboxDataById(action: RequestDriveboxById) {
  try {
    const response = yield* call(getDriveboxById, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDriveboxById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateDriveboxData(action: RequestUpdateDrivebox) {
  try {
    const response = yield* call(updateDrivebox, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateDrivebox(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getPluggedEventsData(action: RequestPluggedEvents) {
  try {
    const response = yield* call(getPluggedEvents, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receivePluggedEvents(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaDrivebox() {
  yield* takeLatest(
    DRIVEBOX_ACTION_TYPES.REQUEST_DRIVEBOXES,
    getDriveboxesData
  );
  yield* takeLatest(
    DRIVEBOX_ACTION_TYPES.REQUEST_DRIVEBOX_BY_ID,
    getDriveboxDataById
  );
  yield* takeEvery(
    DRIVEBOX_ACTION_TYPES.REQUEST_UPDATE_DRIVEBOX,
    updateDriveboxData
  );
  yield* takeLatest(
    DRIVEBOX_ACTION_TYPES.REQUEST_PLUGGED_EVENTS,
    getPluggedEventsData
  );
}
