import { put, call, takeEvery } from "typed-redux-saga/macro";

import {
  RequestVehicleService,
  RequestNextVehicleService,
  RequestAddVehicleService,
  RequestDeleteVehicleService,
} from "../types/actions/actionVehicleService.types";

import {
  addVehicleService,
  deleteVehicleService,
  getNextVehicleService,
  getVehicleService,
} from "./api";

import { handleError } from "../managers/sagaManager";

import {
  receiveAddVehicleService,
  receiveDeleteVehicleService,
  receiveNextVehicleService,
  receiveVehicleService,
  VEHICLE_SERVICE_ACTION_TYPES,
} from "../actions/actionVehicleService";

function* getVehicleServiceData(action: RequestVehicleService) {
  try {
    const response = yield* call(getVehicleService, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveVehicleService(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleError(error));
  }
}

function* getNextVehicleServiceData(action: RequestNextVehicleService) {
  try {
    const response = yield* call(getNextVehicleService, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveNextVehicleService(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleError(error));
  }
}

function* addVehicleServiceData(action: RequestAddVehicleService) {
  try {
    const response = yield* call(addVehicleService, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddVehicleService(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleError(error));
  }
}

function* deleteVehicleServiceData(action: RequestDeleteVehicleService) {
  try {
    const response = yield* call(deleteVehicleService, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteVehicleService(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield* put(handleError(error));
  }
}

export default function* sagaVehicleService() {
  yield* takeEvery(
    VEHICLE_SERVICE_ACTION_TYPES.REQUEST_VEHICLE_SERVICE,
    getVehicleServiceData
  );
  yield* takeEvery(
    VEHICLE_SERVICE_ACTION_TYPES.REQUEST_NEXT_VEHICLE_SERVICE,
    getNextVehicleServiceData
  );
  yield* takeEvery(
    VEHICLE_SERVICE_ACTION_TYPES.REQUEST_ADD_VEHICLE_SERVICE,
    addVehicleServiceData
  );
  yield* takeEvery(
    VEHICLE_SERVICE_ACTION_TYPES.REQUEST_DELETE_VEHICLE_SERVICE,
    deleteVehicleServiceData
  );
}
