import React, {useState} from "react";
import FormModal from "../../../common/modals/FormModal";
import FormBuilder from "../../../../managers/formBuilder";
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {requestAddInspection} from "../../../../actions/actionInspection";
import {Input} from "antd";
import DayPicker from "../../../common/selects/datePickers/DayPicker";
import { useInputChangeHandler } from "../../../../hooks/useInputChangeHandler";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    listItem: {
        marginTop: "-21px",
        marginLeft: "-15px"
    },
});

const DEFAULT_INPUT_VALUES = {
    date: null,
    description: null,
}


const InspectionAddModal = props => {
    const {onClose} = props;
    const { handleInputChange, fields } = useInputChangeHandler(DEFAULT_INPUT_VALUES);

    const { date, description } = fields;
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const vehicle = useSelector(state => state.vehicles.current);

    let inputs = [new FormBuilder.FormInput("Datum", <DayPicker
        disableFuture={false}
        style={{width: "100%"}} 
        value={date}
        onDateChange={(value) => handleInputChange("date", value)}/>)
        .withValue(date)
        .withKey("date")
        .withValidationMessage("Bitte setzte ein Datum")
        .isRequired()
        .build(),
        new FormBuilder.FormInput(t("user.editmodal.description"), <Input.TextArea value={description} onChange={(e) => handleInputChange("description", e.target.value)}
            autoSize={{minRows: 3, maxRows: 5}}/>)
            .withKey("description")
            .build()
    ]

    const handleSave = () => {
        dispatch(requestAddInspection({
            vehicleId: vehicle.id,
            inspectionDate: new Date(date).toISOString(),
            description: description
        }));
        onClose();
    }

    return (
        <FormModal
            action="add"
            onClose={onClose}
            onSave={handleSave}
            title="Neue Begutachtung hinzufügen"
            inputs={inputs}/>
    );
};


export default InspectionAddModal;
