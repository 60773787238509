const topics = {
    commercial: 0,
    technical: 1,
};

const topicArray = [
    {id: topics.commercial, name: "Kaufmännische Angelegenheiten"},
    {id: topics.technical, name: "Technische Angelegenheiten"},
 ];

export {topics, topicArray};
