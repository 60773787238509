import React, { useEffect, useState } from "react"
import DriverIdentification from "./DriverIdentification";
import TripTypeSelect from "../common/selects/specificSelects/TripTypeSelect";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button, Box } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { customColors } from "../geofences/generic/StylingOptions";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { clearDriverName, requestAssignVehicleToDriverByDriverId, requestAssignVehicleToDriverByPin } from "../../actions/actionDriverIdentification";
import LoadingIndicator from "../common/LoadingIndicator";

type Params = {
    vehicleId: string,
  }

type LocationState = {
pin?: any ;
selectedDriver?: any,
forgotPinPage?: boolean,
}

interface SelectedDriverItems {
    vehicleName: string; 
    selectedDriver: {
        firstName: string
    }
}

interface SavedDriverItems {
    vehicleName: string; 
    driverName: string;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonPosition: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        bottom: 100,
        width: "90%"
    },
    startButton: {
        backgroundColor: customColors.bright,
        color: customColors.ilogsBlue,
        padding: 15,
        margin: 8,
        fontSize: 15,
        "&:focus": {
            outline: "none",
            backgroundColor: customColors.bright,
        },
    },
    cancelButton: {
        backgroundColor: "red",
        color: "white",
        padding: 15,
        margin: 8,
        fontSize: 15,
        "&:focus": {
            outline: "none",
            backgroundColor: "red",
        }
    },
    pageContainer: {
        padding: "10vh 50px 50px 50px",
        width: "100%",
        backgroundColor: customColors.ilogsBlueBright,
        color: "white",
        display: "flex",
    
        [theme.breakpoints.up("lg")]: {
          padding: "20vh 50px 50px 50px",
        },
      },
}));


const DriverIdentificationTripTypePage = () => {

    const {vehicleDriver , driverName, vehicleName, loading} = useSelector((state: RootState) => ({
        vehicleDriver: state.driverIdentification.vehicleDriver,
        driverName: state.driverIdentification.driverName,
        vehicleName: state.driverIdentification.vehicleName,
        loading: state.driverIdentification.loading
      }));


    const formatName = (fullName: string) => {
    const [ firstName, lastName ] = fullName?.split(" ");
    const lastNameInitial = lastName ? lastName.slice(0, 1).toUpperCase() : "";
    return `${firstName} ${lastNameInitial}`
    }


    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const { vehicleId } = useParams<Params>();
    const { pin, selectedDriver, forgotPinPage }: LocationState = location.state || {};
    const pathname = `driverIdentification/${vehicleId}`;
    const history = useHistory();

    useEffect(() => {
    vehicleDriver && history.push(`/${pathname}/success`);
    }, [vehicleDriver]);

    const { t } = useTranslation();
    const [ tripType, setTripType ] = useState<string | null>(null)
  
    const [ tripTypeValidationMsg, setTripTypeValidationMsg] = useState<boolean | null>();

    const handleInputChange = ( value: string | null  ) => {
        setTripType(value);
    }

    useEffect(() => {
        if(tripType !== null)setTripTypeValidationMsg(false)
    }, [tripType]);


    useEffect(() => {
        if (forgotPinPage && selectedDriver && vehicleName) {
            const forgotPinScreenOptionToSave = {
                selectedDriver,
                vehicleName,
            };
            localStorage.setItem("optionToSave", JSON.stringify(forgotPinScreenOptionToSave));
        }
    }, [forgotPinPage, selectedDriver, vehicleName]);



    const [getDriverItemsFromForgotPinAssignementScreen, setDriverItems] = useState<SavedDriverItems | null>(null);

    useEffect(() => {
        if (driverName && vehicleName) {
            const pinAssignementScreenOptionToSave = {
                driverName,
                vehicleName,
            };
            localStorage.setItem("pinAssignementOptionToSave", JSON.stringify(pinAssignementScreenOptionToSave));
    
            const getPinAssignementLocalStoreItems = localStorage.getItem("pinAssignementOptionToSave");
            const parsedDriverItems: SavedDriverItems | null = getPinAssignementLocalStoreItems
                ? JSON.parse(getPinAssignementLocalStoreItems) as SavedDriverItems
                : null;
            setDriverItems(parsedDriverItems);
        }
    }, [driverName, vehicleName]);
    

    useEffect(() => {
        const getPinAssignementLocalStoreItems = localStorage.getItem("pinAssignementOptionToSave");
        const parsedDriverItems: SavedDriverItems | null = getPinAssignementLocalStoreItems
            ? JSON.parse(getPinAssignementLocalStoreItems) as SavedDriverItems
            : null;
        setDriverItems(parsedDriverItems);
    }, []);
        

    const getForgotPinLocalStoreItems = localStorage.getItem("optionToSave");
    const getSelectedDriverItemsFromForgotPinScreen: SelectedDriverItems | null = getForgotPinLocalStoreItems
        ? JSON.parse(getForgotPinLocalStoreItems) as SelectedDriverItems
        : null;


    const onHandleConfirm = () => {
        if (tripType === null) {
            setTripTypeValidationMsg(true);
            return;
        }

        const action = forgotPinPage
            ? requestAssignVehicleToDriverByDriverId({
                  vehicleId,
                  driverId: selectedDriver!.id,
                  tripType
              })
            : requestAssignVehicleToDriverByPin({ vehicleId, pin, tripType });
    
        dispatch(action);
    };

    return (
        <DriverIdentification headerVisibility={false}>
            {loading ? <Box className={classes.pageContainer}>
                <LoadingIndicator color={"white"} />
            </Box>
                :
                <><><div>
                    <p style={{ fontSize: 25, textAlign: "center" }}>
                        {t("driverIdentification.name", {
                            driverName: forgotPinPage
                                ? getSelectedDriverItemsFromForgotPinScreen?.selectedDriver?.firstName || ""
                                : formatName(getDriverItemsFromForgotPinAssignementScreen?.driverName || ""),
                        })}
                    </p>
                    <Trans><p style={{fontSize: 17, textAlign: "center", marginBottom: 25}} >{t("driverIdentification.vehicleAssignmentTitle", { vehicleName:  forgotPinPage ? getSelectedDriverItemsFromForgotPinScreen?.vehicleName : getDriverItemsFromForgotPinAssignementScreen?.vehicleName })}</p></Trans>
                    <p style={{textAlign: "center", marginBottom: 2, fontSize: 25}}>{t("driverIdentification.tripTypeQuestion")}</p>
                    </div><TripTypeSelect showSearch={false} allowClear={false} value={tripType} onChange={(value: string) => handleInputChange(value)} />
                        {tripTypeValidationMsg && <p>{t("driverIdentification.tripTypeValidationMessage")}</p>}
                    </><div className={classes.buttonPosition}>
                        <Button className={classes.startButton} onClick={onHandleConfirm}>{t("driverIdentification.start")}</Button>
                        <Button className={classes.cancelButton}
                        onClick={() => {
                            history.goBack();
                            dispatch(clearDriverName());
                            if (forgotPinPage) {
                                localStorage.removeItem("optionToSave");
                            } else {
                                localStorage.removeItem("pinAssignementOptionToSave");
                            }
                        }}
                        >{t("driverIdentification.cancel")}</Button>
                    </div></>
            }

        </DriverIdentification>
    )
}

export default DriverIdentificationTripTypePage;