import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Toolbar from "../../common/toolbar/Toolbar";
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";
import DayPicker from "../../common/selects/datePickers/DayPicker";
import {fromToByPeriod} from "../../../managers/timeManager";
import {periods} from "../../../models/Period";

const DayTimeRecordingToolbar = props => {
    const {pages, page, setPage, dateValue, onDateChange, onExport} = props;

    useEffect(() => {
         const period = fromToByPeriod(periods.day.id, new Date());
         onDateChange(period.from, period.to);
    }, [])
    
    const toggles = [
        {
            icon: <FontAwesomeIcon fontSize={19}
                                   color={page !== pages.day ? "secondary" : undefined}>
                far fa-calendar-day</FontAwesomeIcon>,
            translateKey: "time.page.day",
            active: page === pages.day,
            onClick: () => {
                setPage(pages.day)
            },
        },
        {
            icon: <FontAwesomeIcon fontSize={19} color={page !== pages.month ? "secondary" : undefined}>
                far fa-calendar-alt</FontAwesomeIcon>,
            translateKey: "time.page.month",
            active: page === pages.month,
            onClick: () => {
                setPage(pages.month)
            },
        },]

    const actionButtons = [
        {
            icon: <FontAwesomeIcon minWidth={26} fontSize={17}>far fa-download</FontAwesomeIcon>,
            translateKey: "trip.page.export",
            onClick: onExport,
        }
    ]; 
    
    const controls = [
        <DayPicker disableFuture={false} onDateChange={onDateChange} value={dateValue}/>
    ];

    return <Toolbar title={"Tagesübersicht"} toggles={toggles} controls={controls} actionButtons={actionButtons}/>
}

// DayTimeRecordingToolbar.propTypes = {
//     pages: PropTypes.array.isRequired,
//     page: PropTypes.number.isRequired,
//     setPage: PropTypes.func.isRequired,
//     dateValue: PropTypes.object.isRequired,
//     onDateChange: PropTypes.func.isRequired,
//     onExport: PropTypes.func.isRequired,
// };

export default DayTimeRecordingToolbar;