import { useState } from "react";

export const useCustomValidation = (message) => {
  const [isValidated, setIsValidated] = useState(true);

  const customRule = () => ({
      validator(_, value) {
        if (isValidated) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(message)
      );
      },
    });
  
    return { customRule, isValidated, setIsValidated }
}


