import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core/";
import Page from "../common/container/Page";
import { menu } from "../../managers/pathManager";
import ReportAgenda from "./ReportAgenda";
import ReportList from "./ReportList";
import { makeStyles } from "@material-ui/core/styles";
import LogBookExportModal from "./modals/LogBookExportModal";
import { useModalStatus, useSnackbar } from "../../hooks/useModalStatus";
import DayViewExportModal from "./modals/DayViewExportModal";
import { usePagination } from "../../hooks/usePagination";
import Snackbar from "../common/views/Snackbar";
import ReportScheduleModal from "./modals/ReportScheduleModal";
import ConfirmDialog from "../common/modals/ConfirmModal";
import { useDispatch } from "react-redux";
import {
  requestDeleteScheduledReport,
  requestScheduledReports,
} from "../../actions/actionScheduledReport";
import { requestTree } from "../../actions/actionGroups";
import VehicleMileageExportModal from "./modals/VehicleMileageExportModal";
import TripsRecordModal from "./modals/TripsRecordModal";
import PeriodicExportModal from "./modals/PeriodicExportModal";

export const REPORT_TYPE = {
  reservation: 'reservation',
  hwnoeVehicle: 'hwnoeVehicle',
  costcenter: "costcenter",
  fuelMonitor: "fuelMonitor"
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

const ReportPage = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const [scheduleEntry, setScheduleEntry] = useState();
  const {
    modal: logBookexportModal,
    open: openLogBookExportModal,
    close: closeLogBookExportModal,
  } = useModalStatus();
  const {
    modal: dayViewExportModal,
    open: openDayViewExportModal,
    close: closeDayViewExportModal,
  } = useModalStatus();
  const {
    modal: dayViewGraphicExportModal,
    open: openDayViewGraphicExportModal,
    close: closeDayViewGraphicExportModal,
  } = useModalStatus();
  const {
    modal: dayViewTripGeoIntersectionsExportModal,
    open: openDayViewTripGeoIntersectionsExportModal,
    close: closeDayViewTripGeoIntersectionsExportModal,
  } = useModalStatus();
  const {
    modal: vehicleMileageExportModal,
    open: openVehicleMileageExportModal,
    close: closeVehicleMileageExportModal,
  } = useModalStatus();

  const {
    modal: reportScheduleModal,
    open: openReportScheduleModal,
    close: closeReportScheduleModal,
  } = useModalStatus();
  const {
    modal: confirmDeleteModal,
    open: openConfirmDeleteModal,
    close: closeConfirmDeleteModal,
  } = useModalStatus();

  const {
    modal: monthlyReportModal,
    open: openMonthlyReportModal,
    close: closeMonthlyReportModal
  } = useModalStatus();

  const {
    modal: reservationReportModal,
    open: openReservationReportModal,
    close: closeReservationReportModal
  } = useModalStatus();

  const {
    modal: vehicleMonthlyReport,
    open: openVehicleMonthlyReport,
    close: closeVehicleMonthlyReport,
  } = useModalStatus();

  const {
    modal: costcenterReport,
    open: openCostcenterReport,
    close: closeCostcenterReport,
  } = useModalStatus();

  const {
    modal: fuelMonitorReport,
    open: openFuelMonitorReport,
    close: closeFuelMonitorReport,
  } = useModalStatus();

  const {
    open: exportSnackbar,
    show: showExportSnackbar,
    hide: hideExportSnackbar,
  } = useSnackbar();

  const [currentReportId, setCurrentReportId] = useState(null);

  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();

  useEffect(() => {
    dispatch(requestScheduledReports({ page: page + 1, perPage: rowsPerPage }));
    dispatch(requestTree());
  }, [page, rowsPerPage]);

  const handleDelete = () => {
    dispatch(requestDeleteScheduledReport({ id: scheduleEntry.id }));
    closeConfirmDeleteModal();
  };

  return (
    <Page
      toolbar={<div className={classes.toolbar} />}
      activePage={menu.reporting.key}
    >
      <div className={classes.container}>
        <Grid spacing={2} container className={classes.container}>
          <Grid item md={3} sm={4} xs={12}>
            <ReportList
              onSchedule={(r) => {
                setScheduleEntry(null);
                setCurrentReportId(r);
                openReportScheduleModal();
              }}
              onDayViewExport={() => {
                setScheduleEntry(null);
                openDayViewExportModal();
              }}
              onDayViewGraphicExport={() => {
                setScheduleEntry(null);
                openDayViewGraphicExportModal();
              }}
              onLogBookExport={() => {
                setScheduleEntry(null);
                openLogBookExportModal();
              }}
              onTripGeofenceIntersectionsExport={() => {
                setScheduleEntry(null);
                openDayViewTripGeoIntersectionsExportModal();
              }}
              onVehicleMileageExport={() => {
                setScheduleEntry(null);
                openVehicleMileageExportModal();
              }}
              onMonthlyReportExport={() => {
                setScheduleEntry(null);
                openMonthlyReportModal();
              }}
              onReservationReportExport={() => {
                setScheduleEntry(null);
                openReservationReportModal();
              }}
              onVehicleReportExport={() => {
                setScheduleEntry(null);
                openVehicleMonthlyReport();
              }}
              onCostcenterReportExport={() => {
                setScheduleEntry(null);
                openCostcenterReport();
              }}
              onFuelMonitorReportExport={() => {
                setScheduleEntry(null);
                openFuelMonitorReport();
              }}
            />
          </Grid>
          <Grid item md={9} sm={8} xs={12}>
            <ReportAgenda
              page={page}
              onEdit={(e) => {
                setScheduleEntry(e);
                openReportScheduleModal();
              }}
              onDelete={(e) => {
                setScheduleEntry(e);
                openConfirmDeleteModal();
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={changeRowPerPage}
              onPageChange={changePage}
            />
          </Grid>
        </Grid>
      </div>
      {logBookexportModal && (
        <LogBookExportModal
          onExport={showExportSnackbar}
          onClose={closeLogBookExportModal}
        />
      )}

      {dayViewExportModal && (
        <DayViewExportModal
          onExport={showExportSnackbar}
          onClose={closeDayViewExportModal}
        />
      )}
      {dayViewGraphicExportModal && (
        <DayViewExportModal
          graphicReport
          onExport={showExportSnackbar}
          onClose={closeDayViewGraphicExportModal}
        />
      )}
      {dayViewTripGeoIntersectionsExportModal && (
        <DayViewExportModal
          isTripGeofenceIntersection
          onExport={showExportSnackbar}
          onClose={closeDayViewTripGeoIntersectionsExportModal}
        />
      )}
      {vehicleMileageExportModal && (
        <VehicleMileageExportModal
          onClose={closeVehicleMileageExportModal}
          onExport={showExportSnackbar}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDialog
          title="Wollen sie den automatischen Bericht löschen?"
          text="Wenn Sie in Zukunft keinen automatisierten Monatsbericht erhalten wollen, löschen Sie ihn hier "
          onClose={closeConfirmDeleteModal}
          onConfirm={handleDelete}
        />
      )}
      {
        monthlyReportModal && ( 
          <TripsRecordModal
          onExport={showExportSnackbar}
          onClose={closeMonthlyReportModal}
        />
         )
      }
      {
        reservationReportModal && ( 
          <PeriodicExportModal
          reportType={REPORT_TYPE.reservation}
          onExport={showExportSnackbar}
          onClose={closeReservationReportModal}
        />
          )
      }
      {
        vehicleMonthlyReport && ( 
          <PeriodicExportModal
          reportType={REPORT_TYPE.hwnoeVehicle}
          onExport={showExportSnackbar}
          onClose={closeVehicleMonthlyReport}
        />
          )
      }
       {
        costcenterReport && ( 
          <PeriodicExportModal
          reportType={REPORT_TYPE.costcenter}
          onExport={showExportSnackbar}
          onClose={closeCostcenterReport}
        />
          )
      }
       {
        fuelMonitorReport && ( 
          <PeriodicExportModal
          reportType={REPORT_TYPE.fuelMonitor}
          onExport={showExportSnackbar}
          onClose={closeFuelMonitorReport}
        />
          )
      }
      {reportScheduleModal && (
        <ReportScheduleModal
          id={scheduleEntry ? scheduleEntry.id : undefined}
          reportId={currentReportId}
          onClose={closeReportScheduleModal}
        />
      )}
      <Snackbar
        translateKey="trip.page.snackbarExport"
        onClose={hideExportSnackbar}
        open={exportSnackbar}
      />
    </Page>
  );
};

export default ReportPage;
