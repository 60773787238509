import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Form, Input } from "antd";

import WizardModal from "../../common/modals/wizard-modal/WizardModal";

import { requestAddMandatorSuperUser } from "../../../actions/actionMandators";
import FormBuilder from "../../../managers/formBuilder";
import { WIZARD_TYPES } from "../../common/modals/wizard-modal/WizardModal";
import { useWizardSteps } from "../../../hooks/useModalStatus";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { useValidateForm } from "../../../hooks/useValidateForm";

const DEFAULT_INPUT_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const UserAddModal = (props) => {
  const { onClose } = props;
  const [form] = Form.useForm();
  const [newSuperUser, setNewSuperUser] = useState(null);
  const { handleInputChange, fields } =
    useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { step, nextStep, prevStep } = useWizardSteps();
  const { submittable } = useValidateForm(form);
  const { firstName, lastName, email, password } = fields;

  useEffect(() => {
    if (step === 1) {
      props.requestAddMandatorSuperUser({ ...fields });
      onClose();
    }
  }, [step]);

  const handleNext = () => {
    nextStep();
  };

  const handleBack = () => {
    prevStep();
  };

  const canBack = () => {
    return false;
  };

  const pages = [
    {
      title: "Basic Data",
      inputs: [
        new FormBuilder.FormInput("Firstname", <Input value={firstName} onChange={(e) => handleInputChange("firstName", e.target.value)} />)
          .withKey("firstName")
          .withValidationMessage("Firstname must be set")
          .isRequired()
          .build(),
        new FormBuilder.FormInput("Lastname", <Input value={lastName} onChange={(e) => handleInputChange("lastName", e.target.value)} />)
          .withKey("lastName")
          .withValidationMessage("Lastname must be set")
          .isRequired()
          .build(),
        new FormBuilder.FormInput("E-mail", <Input value={email} onChange={(e) => handleInputChange("email", e.target.value)} />)
          .withKey("email")
          .withType("email")
          .withValidationMessage("Email must be set")
          .isRequired()
          .build(),
        new FormBuilder.FormInput("Password", <Input.Password value={password} onChange={(e) => handleInputChange("password", e.target.value)} />)
          .withKey("password")
          .withValidationMessage("Password must be set")
          .isRequired()
          .build(),
      ],
    },
  ];
  return (
    <WizardModal
      onClose={onClose}
      active={newSuperUser}
      canBack={canBack}
      onNext={handleNext}
      onBack={handleBack}
      form={form}
      submittable={submittable}
      title="Add a new Superadmin"
      pages={pages}
      step={step}
      wizardType={WIZARD_TYPES.mandatorSuperUser.name}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ requestAddMandatorSuperUser }, dispatch);
}

function mapStateToProp(state) {
  return {
    error: state.users.error,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(UserAddModal);