import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CustomModalActions from "./customModalSubComponents/CustomModalActions";
import CustomModalAppBar from "./customModalSubComponents/CustomModalAppBar";
import CustomModalContents from "./customModalSubComponents/CustomModalContents";
import CustomModalTabs from "./customModalSubComponents/CustomModalTabs";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import ErrorToolbar from "../toolbar/ErrorToolbar";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import { clearError } from "../../../actions/actionCommon";
import LoadingIndicator from "../LoadingIndicator";

export const modalActions = {
  none: "none",
  cancel: "cancel",
  filter: "filter",
  confirm: "confirm",
  update: "update",
  ok: "ok",
  add: "add",
  delete: "delete",
};

export const modalType = {
  informationType: "informationType",
  confirmationType: "confirmationType",
  warnType: "warnType",
};

const CustomModal = (props) => {
  const [tabBarState, setTabBar] = useState(0);

  const {
    fullScreen,
    open,
    onClose,
    largeModal,
    loading,
    contents,
    action,
    onSave,
    title,
    tabs,
    className,
    showErrorToolbar,
    applyButtonStyle,
    clearFilter,
    type,
    isFilterActive,
  } = props;

  useEffect(() => {
    props.clearError();
    return () => props.clearError();
  }, []);

  if (open)
    return (
      <Dialog
        className={className}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={largeModal ? "lg" : "sm"}
        disableBackdropClick
        open
        onClose={onClose}
        TransitionComponent={Slide}
      >
        <CustomModalAppBar title={title} type={type} onClose={onClose} />
        {tabs && tabs.length > 1 && <CustomModalTabs tabBarState={tabBarState} setTabBar={setTabBar} tabs={tabs} />}
        {showErrorToolbar && <ErrorToolbar />}
        {loading && <LoadingIndicator />}

        {!loading && contents && contents.length > 0 && (
          <CustomModalContents
            tabBarState={tabBarState}
            setTabBar={setTabBar}
            contents={contents}
          />
        )}
        {!loading && (
          <CustomModalActions
            action={action}
            onClose={onClose}
            onSave={onSave}
            applyButtonStyle={applyButtonStyle}
            clearFilter={clearFilter}
            isFilterActive={isFilterActive}
          />
        )}
      </Dialog>
    );
  return <div />;
};

CustomModal.defaultProps = {
  disableAppBar: false,
  open: true,
  largeModal: false,
  action: "none",
  showErrorToolbar: true,
};

// CustomModal.propTypes = {
//     className: PropTypes.string,
//     fullScreen: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//     onSave: PropTypes.func,
//     title: PropTypes.string,
//     tabs: PropTypes.array,
//     contents: PropTypes.array,
//     open: PropTypes.bool,
//     action: PropTypes.oneOf(Object.values(modalActions)),
//     largeModal: PropTypes.bool,
//     loading: PropTypes.bool,
//     showErrorToolbar: PropTypes.bool,
//     type: PropTypes.string
// };

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ clearError }, dispatch);
}

function mapStateToProp(state) {
  return {};
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withMobileDialog()(CustomModal));
