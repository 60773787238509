import { AnyAction } from "redux";

import { requestSendEmail, receiveSendEmail, toggleEmailSuccessMsgVisibility } from "../actions/actionEmail";

export type EmailState = {
  readonly sending: boolean;
  readonly statusMsg: string;
  readonly isSuccessfulMsgVisible: boolean;
};

const EMAIL_INITAIL_STATE: EmailState = {
  sending: false,
  statusMsg: "",
  isSuccessfulMsgVisible: false,
};

export const emailReducer = (
  state = EMAIL_INITAIL_STATE,
  action: AnyAction
): EmailState => {
  if (requestSendEmail.match(action)) {
    return {
        ...state,
        sending: true,
    }
  }

  if(receiveSendEmail.match(action)) {
    return { 
      ...state,
      sending: false,
      statusMsg: action.data.message,
    }
  }

  if(toggleEmailSuccessMsgVisibility.match(action)) {
    return {
      ...state,
      sending: false,
      isSuccessfulMsgVisible: action.data,
    }
  }

  return state;
};
