import { useState, useEffect } from "react";
import FormModal from "./FormModal";
import { Input, InputNumber, Typography } from "antd";
import FormBuilder from "../../../managers/formBuilder";
import { makeStyles } from "@material-ui/core";

import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  mailIcon: {
    fontSize: "110px",
    color: theme.palette.info.dark,
  },
  formDescription: {
    fontSize: "13px",
    fontWeight: "bold",
  },
}));

const OrderBoxModal = ({ onClose, currentUser }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const DEFAULT_INPUT_VALUES = {
    numberOfBoxes: 1,
    emailBody: `Liebes DRIVEBOX Team,\n\nich würde gerne weitere Boxen bestellen und bitte um Kontaktaufnahme!\n\n <b>Meine Kontaktdaten sind:</b> \n\n<clientfirstname> <clientlastname>\n<clientmail>\n\nIch habe noch folgende Anmerkungen:\n\nHier steht ein Platzhaltertext, wo ich als Kunde meine Anmerkungen hinterlegen kann.`,
  };

  const [inputValues, setInputValues] = useState(DEFAULT_INPUT_VALUES);

  const { emailBody, numberOfBoxes } = inputValues;

  const handleInputNumberChange = (value, name) => {
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleTextAreaChange = (e) => {
    const { value, name } = e.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  function handleSendEmail() {}

  const inputs = [
    new FormBuilder.FormInput(
      t("common.modal.OrderBoxModal.numberOfBoxes"),
      (
        <InputNumber
          value={numberOfBoxes}
          onChange={(value) => handleInputNumberChange(value, "numberOfBoxes")}
          min={1}
          name="numberOfBoxes"
        />
      )
    )
      .withValue(numberOfBoxes)
      .withKey("numberOfBoxes")
      .withValidationMessage(
         t("common.modal.OrderBoxModal.numberOfBoxesValidation")
      )
      .isRequired()
      .build(),

    new FormBuilder.FormInput(
      t("common.modal.OrderBoxModal.emailBody") ,
      (
        <Input.TextArea
          value={emailBody}
          autoSize={{ minRows: 8, maxRows: 16 }}
          onChange={handleTextAreaChange}
          maxLength={400}
          name="emailBody"
        />
      )
    )
      .withValue(emailBody)
      .withKey("emailBody")
      .withValidationMessage(
        t("common.modal.OrderBoxModal.emailBodyValidation")
      )
      .isRequired()
      .build(),
  ];

  return (
    <FormModal
      onClose={onClose}
      inputs={inputs}
      onSave={handleSendEmail}
      title={t("common.modal.OrderBoxModal.title")}
      formIcon={<DraftsOutlinedIcon className={classes.mailIcon} />}
      formDescription={
        <Typography.Text className={classes.formDescription} editable={false}>
          {t("common.modal.OrderBoxModal.modalDescription")}
        </Typography.Text>
      }
    />
  );
};

export default OrderBoxModal;
