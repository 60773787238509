import { MapContainer, TileLayer, FeatureGroup, ScaleControl, LayersControl } from 'react-leaflet';
import MyEditComponent from './leafletOperations/MyEditComponent';
import MyPolygon from './leafletOperations/MyPolygon';
import RoutingMachine from './leafletOperations/RoutingMachine';
import polylabel from 'polylabel';
import L from 'leaflet';
import React, { useState } from 'react';
import GeoSearchField from './leafletOperations/GeoSearchField';
import PolygonMarker from './leafletOperations/PolygonMarker';
 import { compareByReference, objAreEqual } from '../utils/comparisonUtils';
import { highlightedPolyOptions } from '../generic/StylingOptions';
import  { useLayoutEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';

function LeafletMap(props) {
    let { map, setMap, mapState, geoFences, currentUser,
        selectedRoute, setSelectedRoute, routeMode, setRouteController,
        polygonColor, addGeoFenceInState } = props;

    const [zoomLevel, setZoomLevel] = useState(11);

    function latLngsToPolyLabelFormat(latLngs) {
        let coordArray = [];
        latLngs.forEach(parent => {
            parent.forEach(coord => {
                coordArray.push([coord.lat, coord.lng]);
            });
        });
        return [coordArray];
    }

    function getOffsets(latLngs) {
        let labelPos = polylabel(latLngsToPolyLabelFormat(latLngs), 0.001);
        //let center = polygon.getCenter();

        return { lat: labelPos[0], lng: labelPos[1] };
    }

    function textIcon(title) {
        return L.divIcon({
            className: "",
            html: `<div class="tooltipMarker ${"zoom" + (zoomLevel > 6 ? zoomLevel : 6)}">${title}</div>`,
        });
    }

    if (map) {
        map.on("zoomend", (e) => {
            setZoomLevel(e.target.getZoom());
        });
    }
    const useStyles = makeStyles(theme => ({
        container: {
            minHeight: "calc(100vh - 420px)",
            height: props => props.containerHeight,
            position: "relative"
        },
        containerModal: {
            height: "calc(100vh - 280px)",
            position: "relative"
        },
        hide: {
            display: "none",
        },
        loading: {
            position: "absolute",
            zIndex: "5000",
            top: "40%",
            left: "50%",
            textAlign: "center",
        }, 
         
    }));

    const automaticHeight = true;
    const [containerHeight, setContainerHeight] = useState("calc(100vh - 210px)");
    const classes = useStyles({containerHeight: containerHeight});
    const mapContainerId = "mapContainerId";

    const updateMapContainerHeight = () => {
        if (automaticHeight && document.querySelector(`#${mapContainerId}`)) {
            const mapHeight = window.innerHeight - document.querySelector(`#${mapContainerId}`).getBoundingClientRect().y - 40;
            setContainerHeight(mapHeight)
        } else {
            setContainerHeight("calc(100vh - 210px)");
        }
    };

    useLayoutEffect(() => {
        const timeOutKey = setTimeout(updateMapContainerHeight, 200);
        if (automaticHeight) {
            updateMapContainerHeight();
            window.addEventListener("resize", updateMapContainerHeight)
        }
        return () => {
            if (automaticHeight) {
                clearTimeout(timeOutKey);
                window.removeEventListener("resize", updateMapContainerHeight)
            }
        };
    });

    return (
        <div className={classes.container} id={mapContainerId}>
        <MapContainer
            style={{  height: "100%" }}
            center={mapState.position}
            zoom={mapState.zoom}
            whenCreated={(myMap) => {
                // myMap.invalidateSize()
                setMap(myMap);
                myMap.zoomControl.remove();
                L.control.zoom({position: 'bottomleft', zoomInTitle: props.t("map.zoomIn"), zoomOutTitle: props.t("map.zoomOut")}).addTo(myMap);
            }}
            scrollWheelZoom={true}
            id="leaflet-map"
            worldCopyJump
            preferCanvas
            key={[...geoFences.keys()].join("-")}
        > 
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // use openstreetmap.org for international tiles
            /> 
            <GeoSearchField />
            
            {/* {<Marker position={liveMarker} of icon={L.divIcon({
                className: "",
                html: `<div class="liveMarker">VI 28DHE</div>`,
                iconAnchor: [50, 50]
            })}></Marker>} */}

            {routeMode ? <RoutingMachine
                map={map}
                selectedRoute={selectedRoute}
                setSelectedRoute={setSelectedRoute}
                setRouteController={setRouteController}
            /> : <></>}

            <ScaleControl position="bottomleft" metric imperial={false} />
            {/* <Fullscreen
                        {...options}
                        /> */}
            {/*render geofence labels*/}
            {[...geoFences.keys()].map(id => {
                return (
                    <PolygonMarker
                        key={'marker_' + id}
                        position={getOffsets(geoFences.get(id).getLatLngs())}
                        icon={textIcon(geoFences.get(id).geoFence.Title)}
                        hidden={geoFences.get(id).geoFence.Hidden}
                    ></PolygonMarker>
                );
            })}

            {/*display non-editable geofences (circles or roads)*/}
            {[...geoFences.keys()].filter(id => {
                return (geoFences.get(id).geoFence.SystemGeoFence || geoFences.get(id).geoFence.IsNotEditable)
            }).map(id => {
                return (
                    <MyPolygon
                        polygon={geoFences.get(id)}
                        idGeoFence={id}
                        key={'nonEditPoly_' + id}
                        hidden={geoFences.get(id).geoFence.Hidden}
                        pathOptions={geoFences.get(id).pathOptions || (geoFences.get(id).geoFence.Highlighted ? highlightedPolyOptions : polygonColor)}
                        {...props}
                    ></MyPolygon>
                );
            })}

            <FeatureGroup>
                <MyEditComponent
                    currentUser={currentUser}
                    geoFences={geoFences}
                    map={map}
                    addGeoFenceInState={addGeoFenceInState}
                    {...props}
                ></MyEditComponent>

                {/*display editable geofences (not circles or roads) inside edit-featuregroup*/}
                {[...geoFences.keys()].filter(id => {
                    return (geoFences.get(id) && !geoFences.get(id).geoFence.SystemGeoFence && !geoFences.get(id).geoFence.IsNotEditable)
                }).map(id => {
                    return (
                        <MyPolygon
                            polygon={geoFences.get(id)}
                            idGeoFence={id}
                            key={'editPoly_' + id}
                            hidden={geoFences.get(id).geoFence.Hidden}
                            pathOptions={geoFences.get(id).pathOptions || (geoFences.get(id).geoFence.Highlighted ? highlightedPolyOptions : polygonColor)}
                            {...props}
                        ></MyPolygon>
                    );
                })}
            </FeatureGroup>
        </MapContainer>
        </div>
    );
}

export default withTranslation()(React.memo(LeafletMap, isEqual));

function isEqual(prevProps, nextProps) {
    if (compareByReference(prevProps.mapState, nextProps.mapState) &&
        compareByReference(prevProps.geoFences, nextProps.geoFences) &&
        objAreEqual(prevProps.currentUser, nextProps.currentUser) &&
        objAreEqual(prevProps.swapLatLngOnExport, nextProps.swapLatLngOnExport) &&
        objAreEqual(prevProps.selectedRoute, nextProps.selectedRoute) &&
        objAreEqual(prevProps.routeMode, nextProps.routeMode) &&
        objAreEqual(prevProps.polygonColor, nextProps.polygonColor)) {
        return true;
    }
    return false;
}