import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  GroupAddRequest,
  GroupUpdateRequest,
  GroupMoveRequest,
} from "../types/group/groupRequest.types";

import {
  GroupTreeResponse,
  GroupCurrentUserResponse,
  GroupResponse,
  Group,
} from "../types/group/groupResponse.types";

import {
  RequestTree,
  ReceiveTree,
  RequestTreesCurrentUser,
  ReceiveTreesCurrentUser,
  RequestGroups,
  ReceiveGroups,
  RequestGroupById,
  ReceiveGroupById,
  RequestAddGroup,
  ReceiveAddGroup,
  RequestUpdateGroup,
  ReceiveUpdateGroup,
  RequestDeleteGroup,
  ReceiveDeleteGroup,
  RequestMoveGroup,
  ReceiveMoveGroup,
  LoadGroup,
} from "../types/actions/actionGroup.types";

export enum GROUP_ACTION_TYPES {
  REQUEST_TREE = "REQUEST_TREE",
  RECEIVE_TREE = "RECEIVE_TREE",

  REQUEST_TREES_CURRENT_USER = "REQUEST_TREES_CURRENT_USER",
  RECEIVE_TREES_CURRENT_USER = "RECEIVE_TREES_CURRENT_USER",

  REQUEST_GROUPS = "REQUEST_GROUPS",
  RECEIVE_GROUPS = "RECEIVE_GROUPS",

  REQUEST_GROUP_BY_ID = "REQUEST_GROUP_BY_ID",
  RECEIVE_GROUP_BY_ID = "RECEIVE_GROUP_BY_ID",

  REQUEST_ADD_GROUP = "REQUEST_ADD_GROUP",
  RECEIVE_ADD_GROUP = "RECEIVE_ADD_GROUP",

  REQUEST_UPDATE_GROUP = "REQUEST_UPDATE_GROUP",
  RECEIVE_UPDATE_GROUP = "RECEIVE_UPDATE_GROUP",

  REQUEST_DELETE_GROUP = "REQUEST_DELETE_GROUP",
  RECEIVE_DELETE_GROUP = "RECEIVE_DELETE_GROUP",

  REQUEST_MOVE_GROUP = "REQUEST_MOVE_GROUP",
  RECEIVE_MOVE_GROUP = "RECEIVE_MOVE_GROUP",

  LOAD_GROUP = "LOAD_GROUP",
}

export const requestTree = withMatcher((): RequestTree => {
  return createAction(GROUP_ACTION_TYPES.REQUEST_TREE);
});

export const receiveTree = withMatcher(
  (data: GroupTreeResponse[]): ReceiveTree => {
    return createAction(GROUP_ACTION_TYPES.RECEIVE_TREE, data);
  }
);

export const requestTreesCurrentUser = withMatcher(
  (): RequestTreesCurrentUser => {
    return createAction(GROUP_ACTION_TYPES.REQUEST_TREES_CURRENT_USER);
  }
);

export const receiveTreesCurrentUser = withMatcher(
  (data: GroupCurrentUserResponse[]): ReceiveTreesCurrentUser => {
    return createAction(GROUP_ACTION_TYPES.RECEIVE_TREES_CURRENT_USER, data);
  }
);

export const requestGroups = withMatcher(
  (groupParentId?: string): RequestGroups => {
    return createAction(GROUP_ACTION_TYPES.REQUEST_GROUPS, groupParentId);
  }
);

export const receiveGroups = withMatcher(
  (data: GroupResponse): ReceiveGroups => {
    return createAction(GROUP_ACTION_TYPES.RECEIVE_GROUPS, data);
  }
);

export const requestGroupById = withMatcher(
  (groupId: string): RequestGroupById => {
    return createAction(GROUP_ACTION_TYPES.REQUEST_GROUP_BY_ID, groupId);
  }
);

export const receiveGroupById = withMatcher((data: Group): ReceiveGroupById => {
  return createAction(GROUP_ACTION_TYPES.RECEIVE_GROUP_BY_ID, data);
});

export const requestAddGroup = withMatcher(
  (data: GroupAddRequest): RequestAddGroup => {
    return createAction(GROUP_ACTION_TYPES.REQUEST_ADD_GROUP, data);
  }
);

export const receiveAddGroup = withMatcher((data: Group): ReceiveAddGroup => {
  return createAction(GROUP_ACTION_TYPES.RECEIVE_ADD_GROUP, data);
});

export const requestUpdateGroup = withMatcher(
  (data: GroupUpdateRequest): RequestUpdateGroup => {
    return createAction(GROUP_ACTION_TYPES.REQUEST_UPDATE_GROUP, data);
  }
);

export const receiveUpdateGroup = withMatcher(
  (data: Group): ReceiveUpdateGroup => {
    return createAction(GROUP_ACTION_TYPES.RECEIVE_UPDATE_GROUP, data);
  }
);

export const requestDeleteGroup = withMatcher(
  (groupId: string): RequestDeleteGroup => {
    return createAction(GROUP_ACTION_TYPES.REQUEST_DELETE_GROUP, groupId);
  }
);

export const receiveDeleteGroup = withMatcher(
  (data: Group): ReceiveDeleteGroup => {
    return createAction(GROUP_ACTION_TYPES.RECEIVE_DELETE_GROUP, data);
  }
);

export const requestMoveGroup = withMatcher(
  (data: GroupMoveRequest): RequestMoveGroup => {
    return createAction(GROUP_ACTION_TYPES.REQUEST_MOVE_GROUP, data);
  }
);

export const receiveMoveGroup = withMatcher((data: Group): ReceiveMoveGroup => {
  return createAction(GROUP_ACTION_TYPES.RECEIVE_MOVE_GROUP, data);
});

export const loadGroup = withMatcher((): LoadGroup => {
  return createAction(GROUP_ACTION_TYPES.LOAD_GROUP);
});
