
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterAPICall } from '../../../../actions/actionFilter';
import { deselectAllTrips } from '../../../../actions/actionTrips';
import { fromToByPeriod } from '../../../../managers/timeManager';
import { periods } from '../../../../models/Period';
import DateRangePicker from '../../../common/selects/datePickers/PeriodDatePicker';
import GeofenceSelect from '../../../common/selects/specificSelects/GeofenceSelect';
import UserSelect from '../../../common/selects/specificSelects/UserSelect';
import VehicleSelect from '../../../common/selects/specificSelects/VehicleSelect';
import CustomToolbar from '../../../common/toolbar/Toolbar';
import FontAwesomeIcon from '../../../common/views/FontAwesomeIcon';
import { useTranslation } from 'react-i18next';

const GeofenceToolbar = props => {
    const filter = useSelector(state => state.filter);
    const multiSelectFilter = useSelector((state) => state.filter.api);

    const selected = useSelector(state => state.trips.selected);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const currentDay = fromToByPeriod(periods.month.id, new Date());
    const [dateRange, setDateRange] = useState({
        period: periods.month.id,
        from: (filter && filter.from && filter.period === periods.month.id) ? new Date(filter.from) : currentDay.from,
        to: (filter && filter.to && filter.period === periods.month.id) ? new Date(filter.to) : currentDay.to,
    });
    const { onExport, vehicle, setVehicle, settings, setSettings,geofenceMode, setGeofenceMode, user, setUser
    } = props;

    const {from, to, period} = dateRange;
    const actionButtons = [];

    useEffect(() => {
        dispatch(updateFilterAPICall({
            from: from ? new Date(from).toISOString() : new Date("1760-01-01T00:00:00Z").toISOString(),
            to: to ? new Date(to).toISOString() : new Date("9999-01-01T00:00:00Z").toISOString(),
            period: period,
           // page: 1
        }));
    }, [period, from, to]);
    
    function handleDateChange(period, from, to) {
        setDateRange({period, from, to});
    }
    
    function handleGeofenceChange(geofenceIds) {
        dispatch(updateFilterAPICall({ geofenceIds }));
    }

    function handleVehiclesChange(vehicleIds) {
        dispatch(updateFilterAPICall({ vehicleIds }));
    }

    actionButtons.push({
        icon: <FontAwesomeIcon minWidth={26} fontSize={17}>far fa-download</FontAwesomeIcon>,
        translateKey: "trip.page.export",
        onClick: onExport,
    });
    // actionButtons.push({
        //     icon: <FontAwesomeIcon fontSize={16}>{geofenceMode ? "far fa-ca" : "far fa-user"}</FontAwesomeIcon>,
        //     translateKey: geofenceMode ? "geofenceReport.byVehicleReport" : "geofenceReport.byGeofenceReport",
        //     onClick: () => setGeofenceMode(!geofenceMode),
        // });
        
    const controls = [];

    controls.push(
        <GeofenceSelect
            multiple
            onChange={handleGeofenceChange}
            classname="toolbarchild"
            value={multiSelectFilter.geofenceIds}
            customMinWidth="150px"
            customMaxTagCount={1}
            />);
            
        controls.push(<VehicleSelect
            multiple
            classname="toolbarchild"
            onChange={handleVehiclesChange}
            value={multiSelectFilter.vehicleIds}
            customMinWidth="150px"
            customMaxTagCount={1}
      />);

    controls.push(<DateRangePicker
        period={period}
        startDate={from}
        classname="toolbarchild"
        endDate={to}
        onDateChange={handleDateChange}
    />);

    return <CustomToolbar
        marginTop={'5px'}
        title={t("report.reportList.tripGeoIntersection")}
        //toggles={toggles}
        actionButtons={actionButtons}
        //bulkActionButtons={bulkActionButtons}
        numberSelected={selected}
        //onSetting={onSetting}
        controls={controls}
        onDeselect={el => dispatch(deselectAllTrips(el))} />
}

export default GeofenceToolbar;