import { takeLatest, put, call, takeEvery } from "typed-redux-saga/macro";

import {
  requestTripById,
  receiveAddTrip,
  receiveDeleteTrip,
  receiveLockTrips,
  receiveUnlockTrips,
  receiveSetReadyToExport,
  receiveSetReadyToExportByMonth,
  receiveMergeTrips,
  receiveMergeTripsPreview,
  receiveMileageOfTrip,
  receiveSplitTrip,
  receiveTrackOfTrip,
  receiveTripById,
  receiveTrips,
  receiveUpdateTrip,
  toggleTripSuccessMsgVisibility,
  TRIP_ACTION_TYPES,
} from "../actions/actionTrips";

import { handleError } from "../managers/sagaManager";

import {
  addTrip,
  deleteTrip,
  getMileageOfTrip,
  getTrackOfTrip,
  getTripById,
  getTrips,
  lockTrips,
  unlockTrips,
  mergeTrips,
  mergeTripsPreview,
  setReadyToExportTrips,
  setReadyToExportTripsByMonth,
  splitTrip,
  updateTrip,
  updateTrips,
} from "./api";

import {
  RequestTrips,
  RequestTripById,
  RequestAddTrip,
  RequestLockTrip,
  RequestUnlockTrips,
  RequestSetReadyToExport,
  RequestSetReadyToExportByMonth,
  RequestUpdateTrip,
  RequestUpdateTrips,
  RequestDeleteTrip,
  RequestTrackOfTrip,
  RequestMileageOfTrip,
  RequestSplitTrip,
  RequestMergeTrips,
  RequestMergeTripsPreview,
} from "../types/actions/actionTrip.types";

function* getTripsData(action: RequestTrips) {
  try {
    const response = yield* call(getTrips, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getTripByIdData(action: RequestTripById) {
  try {
    const response = yield* call(getTripById, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveTripById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addTripData(action: RequestAddTrip) {
  try {
    const response = yield* call(addTrip, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddTrip(response.data));
      yield* put(toggleTripSuccessMsgVisibility(true));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* lockTripData(action: RequestLockTrip) {
  try {
    const response = yield* call(lockTrips, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveLockTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* unlockTripData(action: RequestUnlockTrips) {
  try {
    const response = yield* call(unlockTrips, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUnlockTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* setReadyToExportTripData(action: RequestSetReadyToExport) {
  try {
    const response = yield* call(setReadyToExportTrips, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveSetReadyToExport(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* setReadyToExportByMonthTripData(
  action: RequestSetReadyToExportByMonth
) {
  try {
    const response = yield* call(setReadyToExportTripsByMonth, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveSetReadyToExportByMonth(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateTripData(action: RequestUpdateTrip) {
  try {
    const response = yield* call(updateTrip, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveUpdateTrip(response.data));
      yield* put(requestTripById(action.data.tripId));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* updateTripsData(action: RequestUpdateTrips) {
  try {
    const tripResponse = yield* call(updateTrips, action.data.updateData);
    const response = yield* call(getTrips, action.data.getTripsData);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      tripResponse.status >= 200 &&
      tripResponse.status < 300
    ) {
      yield* put(receiveTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteTripData(action: RequestDeleteTrip) {
  try {
    const response = yield* call(deleteTrip, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteTrip(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getTrackOfTripData(action: RequestTrackOfTrip) {
  try {
    const tripResponse = yield* call(getTripById, action.data);
    const response = yield* call(getTrackOfTrip, action.data);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      tripResponse.status >= 200 &&
      tripResponse.status < 300
    ) {
      yield* put(
        receiveTrackOfTrip({ ...tripResponse.data, positions: response.data })
      );
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getMileageOfTripData(action: RequestMileageOfTrip) {
  try {
    const response = yield* call(getMileageOfTrip, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMileageOfTrip(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* splitTripData(action: RequestSplitTrip) {
  try {
    const response = yield* call(splitTrip, action);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveSplitTrip(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* mergeTripData(action: RequestMergeTrips) {
  try {
    const response = yield* call(mergeTrips, action);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMergeTrips(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* mergeTripPreviewData(action: RequestMergeTripsPreview) {
  try {
    const response = yield* call(mergeTripsPreview, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveMergeTripsPreview(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaTrips() {
  yield* takeLatest(TRIP_ACTION_TYPES.REQUEST_TRIPS, getTripsData);
  yield* takeLatest(TRIP_ACTION_TYPES.REQUEST_TRIP_BY_ID, getTripByIdData);
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_ADD_TRIP, addTripData);
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_LOCK_TRIPS, lockTripData);
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_UNLOCK_TRIPS, unlockTripData);
  yield* takeEvery(
    TRIP_ACTION_TYPES.REQUEST_SET_READY_TO_EXPORT,
    setReadyToExportTripData
  );
  yield* takeEvery(
    TRIP_ACTION_TYPES.REQUEST_SET_READY_TO_EXPORT_BY_MONTH,
    setReadyToExportByMonthTripData
  );
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_UPDATE_TRIP, updateTripData);
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_UPDATE_TRIPS, updateTripsData);
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_DELETE_TRIP, deleteTripData);
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_TRACK_OF_TRIP, getTrackOfTripData);
  yield* takeLatest(
    TRIP_ACTION_TYPES.REQUEST_MILEAGE_OF_TRIP,
    getMileageOfTripData
  );
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_SPLIT_TRIP, splitTripData);
  yield* takeEvery(TRIP_ACTION_TYPES.REQUEST_MERGE_TRIPS, mergeTripData);
  yield* takeEvery(
    TRIP_ACTION_TYPES.REQUEST_MERGE_TRIPS_PREVIEW,
    mergeTripPreviewData
  );
}
