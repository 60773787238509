import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";

import TripTable from "./DashboardTripTable";

import {
  requestTripMileageReport,
  requestTripReport,
  requestTripVehiclesReport,
} from "../../actions/actionReport";
import { setFilter, updateFilterAPICall } from "../../actions/actionFilter";

import Page from "../common/container/Page";
import Toolbar from "../common/toolbar/Toolbar";
import MonthPicker from "../common/selects/datePickers/MonthPicker";

import DashboardTripDistribution from "./DashboardTripDistribution";
import DashboardKmHistory from "./DashboardKmHistory";
import { menu } from "../../managers/pathManager";
import { fromToByPeriod, secondsToString } from "../../managers/timeManager";
import ReportCard from "../common/views/ReportCard";
import { periods } from "../../models/Period";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import FuelIcon from "../../icons/FuelIcon";
import { packages } from "../../models/Package";
import VehiclesWithOverconsumptionModal from "./VehiclesWithOverconsumptionModal";
import { useModalStatus } from "../../hooks/useModalStatus";
import {Redirect} from "react-router-dom";
import { allowedRoles, isAuthorized } from "../../managers/authManager";
import { roles } from "../../models/Role";
import { filterOptions } from "../common/selects/specificSelects/TripsFilterSelect";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: "0 3px 4px 3px",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    margin: "5px",
    width: "100%",
    borderRadius: "5px",
  },
  card: {
    marginTop: "2px",
    marginBottom: "2px",
    [theme.breakpoints.up("md")]: {
      width: (props) => props.numberOfReportCards,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const Dashboard = (props) => {
  const [start, setStart] = useState(new Date());
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { me, trip, vehicles } = props;
  const {
    mileageInMeters,
    privateTripCount,
    businessTripCount,
    driveToWorkTripCount,
    timeInSeconds,
    overconsumedTripsByVehicle,
    totalOverConsumedTrips
  } = trip;

  const numberOfVehiclesWithOVerconsumption = overconsumedTripsByVehicle?.length;
  const [selectedOversonsumedVehicle, setSelectedOversonsumedVehicle] = useState(null);

  const [redirectToTripsPage, setRedirectToTripsPage] = useState(null);

  const {
    modal: vehiclesWithOverconsumptionModal,
    open: openVehiclesWithOverconsumptionModal,
    close: closeVehiclesWithOverconsumptionModal,
  } = useModalStatus();

  const tripCount = privateTripCount + businessTripCount + driveToWorkTripCount;

  const requestParams = fromToByPeriod(periods.month.id, new Date(start));
  requestParams.from = requestParams.from.toISOString();
  requestParams.to = requestParams.to.toISOString();

  useEffect(() => {
    props.requestTripReport(requestParams);
  }, [start]);

  useEffect(() => {
    props.requestTripVehiclesReport(requestParams);
  }, [start]);

  useEffect(() => {
    props.requestTripMileageReport({ month: new Date(start).toISOString() });
  }, [start]);

  function handleDateChange(start, end) {
    setStart(start);
  }

  useEffect(() => {
    dispatch(
      updateFilterAPICall({
        from: requestParams.from
          ? new Date(requestParams.from).toISOString()
          : new Date("1760-01-01T00:00:00Z").toISOString(),
        to: requestParams.to
          ? new Date(requestParams.to).toISOString()
          : new Date("9999-01-01T00:00:00Z").toISOString(),
        period: periods.month.id,
      })
    );
  }, [start]);

  const toolbar = (
    <Toolbar
      title={
        <div>
          {t('dashboard.page.welcomeBack')} {me.firstName}
        </div>
      }
      controls={[<MonthPicker value={start} onDateChange={handleDateChange} />]}
    />
  );

  function showWehicleWithOverConsumptionModal(){
    if(numberOfVehiclesWithOVerconsumption === 1){
      setSelectedOversonsumedVehicle(overconsumedTripsByVehicle[0]?.vehicleId)
      setRedirectToTripsPage(true);
    } else if(numberOfVehiclesWithOVerconsumption > 1){
      openVehiclesWithOverconsumptionModal();
    } else {
      //nothing
      return;
    }
  }

  const numberOfTripsWithOverconsumtion = totalOverConsumedTrips

  const fuelMonitorReportCardContent =  {
    title: t("dashboard.cards.overconsumeVehicles"),
    helpText: t("dashboard.cards.overconsumeVehiclesHelp"),
    loading: false, // this need to be implemented when the request is ready
    value: numberOfTripsWithOverconsumtion,
    icon: <FuelIcon  color={numberOfTripsWithOverconsumtion < 1 ? undefined : "red"}/>,
    handleClick: showWehicleWithOverConsumptionModal
  }

  const content = [
   <div>
    <div style={{display: "flex", justifyContent: "space-between" }}>
      <h6>Fahrzeuge</h6>
      <h6>Fahrten</h6>
    </div>
    <div style={{width: "100%", height: 1, backgroundColor: "black", marginBottom: 30}} ></div>
    {overconsumedTripsByVehicle?.map((v) => {
      return (
        <div key={v.vehicleId} style={{cursor: "pointer"}}> 
          <div style={{display: "flex", justifyContent: "space-between", marginTop: 10, marginBottom: 10, alignItems: "center"}} onClick={() => {
            setSelectedOversonsumedVehicle(v.vehicleId)
            setRedirectToTripsPage(true)
          }}>
            <p style={{margin: 0, color: "red"}}>{v.vehicleName}</p>
            <p style={{margin: 0, color: "red"}}>{v.numOfOverconsumptions}</p>
          </div>
          <div style={{width: "100%", height: 1, backgroundColor: "red"}} ></div>
        </div>
      )
    })}
   </div>
  ]

  const reportsCardContent = [
    {
      title: t("dashboard.cards.trips"),
      helpText: t("dashboard.cards.tripsHelp"),
      loading: typeof tripCount == "undefined" || isNaN(tripCount),
      value: tripCount,
      icon: <FontAwesomeIcon fontSize={30} color="secondary"> far fa-book </FontAwesomeIcon>
    },
    {
      title: t("dashboard.cards.mileage") ,
      helpText: t("dashboard.cards.mileageHelp") ,
      loading:typeof mileageInMeters == "undefined",
      value: Math.round(mileageInMeters / 1000),
      icon: <FontAwesomeIcon fontSize={30} minWidth={34} color="secondary"> far fa-road </FontAwesomeIcon>
    },
    {
      title: t("dashboard.cards.duration"),
      helpText: t("dashboard.cards.durationHelp"),
      loading: typeof timeInSeconds == "undefined",
      value: secondsToString(timeInSeconds) || 0,
      icon:  <FontAwesomeIcon fontSize={30} color="secondary"> far fa-stopwatch </FontAwesomeIcon>
    },
    {
      title: t("dashboard.cards.vehicles"),
      helpText: t("dashboard.cards.vehiclesHelp"),
      loading: typeof vehicles == "undefined",
      value: vehicles ? vehicles.length : 0, 
      icon: <FontAwesomeIcon fontSize={30} color="secondary">far fa-car </FontAwesomeIcon>,
    }
  ]

  if(isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor])){
     reportsCardContent.push(fuelMonitorReportCardContent)
  } 

  const numberOfReportCards = `${100 / reportsCardContent.length}%`;

  const classes = useStyles({numberOfReportCards});
  return (
    <Page activePage={menu.dashboard.key} toolbar={toolbar}
>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.contentContainer}
      >

      {reportsCardContent.map((content, index) => {
        return (
          <Grid item key={index} className={classes.card}>
            <ReportCard
              condensed
              title={content.title}
              helpText={content.helpText}
              loading={content.loading}
              value={content.value}
              icon={content.icon}
              onClick={content.handleClick ? () => content.handleClick() : undefined}
            />
          </Grid>
        )
      })}

        <Grid item xs={12} md={4}>
          <DashboardTripDistribution
            title={t("dashboard.charts.tripDistribution.title")}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <DashboardKmHistory
            endMonth={start}
            title={t("dashboard.charts.kmHistory.title")}
          />
        </Grid>
        <Grid item xs={12}>
          <TripTable />
        </Grid>
      </Grid>
      {vehiclesWithOverconsumptionModal && <VehiclesWithOverconsumptionModal onClose={closeVehiclesWithOverconsumptionModal} content={content} />}
      {redirectToTripsPage && <Redirect to={{
            pathname: menu.trips.path,
            state: { selectedOversonsumedVehicle: selectedOversonsumedVehicle, filterOverconsumeVehicle: filterOptions.OverConsumption }
          }}/>}
    </Page>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestTripMileageReport,
      requestTripReport,
      requestTripVehiclesReport,
      setFilter,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    trip: state.reports.trip,
    vehicles: state.reports.vehicles,
    me: state.users.me,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(Dashboard);
