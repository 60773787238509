import { AnyAction } from "redux";

import { GroupTreeResponse } from "../types/group/groupResponse.types";
import { Group } from "../types/group/groupResponse.types";

import { receiveUpdateUser } from "../actions/actionUser";

import { receiveUpdateVehicle } from "../actions/actionVehicle";

import {
  receiveAddGroup,
  receiveDeleteGroup,
  receiveGroupById,
  receiveGroups,
  receiveTree,
  receiveTreesCurrentUser,
  receiveUpdateGroup,
} from "../actions/actionGroups";

import {
  receiveLogout,
  receiveUnAuthoriseUser,
} from "../actions/actionAuthentication";

import { convertTreeToTreeSelect } from "../managers/groupManager";
import { UserResponseInfo } from "../types/user/userResponse.types";
import { VehicleResponseInfo } from "../types/vehicle/vehicleResponse.types";

export type GroupState = {
  readonly id: string;
  readonly users: (UserResponseInfo & { toRemove?: boolean })[];
  readonly vehicles: (VehicleResponseInfo & { toRemove?: boolean })[];
  readonly children?: (GroupTreeResponse | Group)[];
  readonly loading: boolean;
};

const GROUP_INITIAL_STATE: GroupState = {
  id: "",
  users: [],
  vehicles: [],
  children: [],
  loading: false,
};

export const group = (state = GROUP_INITIAL_STATE, action: AnyAction) => {
  if (receiveGroupById.match(action)) {
    return {
      ...state,
      ...action.data,
      children: state.children?.map((e) =>
        e.id === action.data.id ? action.data : e
      ),
      loading: false,
    };
  }

  if (receiveUpdateGroup.match(action)) {
    if (state.id === action.data.id) {
      return {
        ...state,
        ...action.data,
        loading: false,
      }
    }
    return state;
  }

  if (receiveUpdateUser.match(action)) {
    const { data } = action;
    let users = state.users;
    if (users.some((v) => v.id === data.id)) {
      users = users.map((user) => {
        if (user.id === data.id && data.groups.some((g) => g.id === state.id))
          return { ...data, name: data.firstName + " " + data.lastName };
        else if (user.id === data.id) return { ...user, toRemove: true };
        return user;
      });
    } else {
      users.push(data);
    }
    users = users.filter((v) => v.toRemove);
    return { ...state, users };
  }

  if (receiveUpdateVehicle.match(action)) {
    const { data } = action;
    let vehicles = state.vehicles;
    if (vehicles.some((v) => v.id === data.id)) {
      vehicles = vehicles.map((vehicle) => {
        //update a vehicle if the data has been changed
        if (
          vehicle.id === data.id &&
          data.groups.some((g) => g.id === state.id)
        )
          return data;
        else if (vehicle.id === data.id) return { ...vehicle, toRemove: true }; //mark vehicle to be removed
        return vehicle;
      });
    } else {
      vehicles.push(data); //add vehicle
    }
    vehicles = vehicles.filter((v) => v.toRemove); //remove marked vehicles
    return { ...state, vehicles };
  }

  if (receiveDeleteGroup.match(action)) {
    return {
      ...state,
      children: state.children?.filter((e) => e.id !== action.data.id),
      loading: false,
    };
  }

  if (receiveAddGroup.match(action)) {
    return {
      ...state,
      children: [...state.children!, action.data],
      loading: false,
    };
  }

  if (receiveLogout.match(action) || receiveUnAuthoriseUser.match(action)) {
    return GROUP_INITIAL_STATE;
  }

  if (receiveGroups.match(action)) {
    return { ...state, ...action.data };
  }

  return state;
};

export const tree = (state = [], action: AnyAction) => {
  if (receiveTree.match(action) || receiveTreesCurrentUser.match(action)) {
    return convertTreeToTreeSelect(action.data);
  }

  return state;
};
