const archivedStatus = {
    AktiveReservation: false,
    ArchivedReservations: true,
    AllReservations: null
}

const archivedStatusArray = [
    {id: archivedStatus.AktiveReservation, name: "archiveReservationSelect.onlyAktive"},
    {id: archivedStatus.ArchivedReservations, name: "archiveReservationSelect.archived"},
    {id: archivedStatus.AllReservations, name: "archiveReservationSelect.all"}
]

export {archivedStatus, archivedStatusArray};