import React from "react";
import PropTypes from "prop-types";
import {makeStyles, TableCell, Typography} from "@material-ui/core";


const useStyles = makeStyles({
    clickCursor: {
        cursor: "pointer"
    },
});


const GenericTableCell = ({children, color, disabled, onClick}) => {
    const classes = useStyles();
    
    const event = onClick ? {onClick: onClick, className: classes.clickCursor} : {};

    const formatedValue = disabled ? (
        <Typography variant="body2" color="textSecondary">
            {children}
        </Typography>
    ) : (
        <div style={{color: color}}>{children}</div>
    );
    return <TableCell {...event}> {formatedValue}</TableCell>;
};


// GenericTableCell.propTypes = {
//     disabled: PropTypes.oneOfType([
//         PropTypes.bool,
//         PropTypes.string
//     ]) ,
//     color: PropTypes.string,
//     onClick: PropTypes.func,
// };

export default GenericTableCell;
