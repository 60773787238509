import React from "react";
import {Select} from "antd";
import CustomSelect from "../Select";
import {orderByArray} from "../../../../models/OrderBy";

const {Option} = Select;

const OrderBySelect = ({onChange, value, disabled}) => {
    return (
        <CustomSelect allowClear={false} value={value} placeholder="Sortierung" handleChange={onChange}
                      disabled={disabled}>
            {orderByArray.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

export default OrderBySelect;
