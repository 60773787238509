import { AnyAction } from "redux";

import {
  requestSetReadyToExportByMonth,
  receiveSetReadyToExportByMonth,
} from "../actions/actionTrips";

import { TripResponseInfo } from "../types/trip/tripResponse.types";

export type ExportTripState = {
  readonly entities: TripResponseInfo[];
  readonly loading: boolean;
};

const EXPORT_TRIP_INITIAL_STATE: ExportTripState = {
  entities: [],
  loading: false,
};
export const exportTripsDetail = (
  state = EXPORT_TRIP_INITIAL_STATE,
  action: AnyAction
): ExportTripState => {
  if (requestSetReadyToExportByMonth.match(action)) {
    return { ...state, loading: true };
  }

  if (receiveSetReadyToExportByMonth.match(action)) {
    return { entities: action.data, loading: false };
  }

  return state;
};
