import React from "react";
import CustomModal from "../common/modals/CustomModal";
import PropTypes from "prop-types";

const OrderAGBModal = props => {

    const {onClose} = props;

    const content = <div>
        <h1>Allgemeine Geschäftsbedingungen</h1>
        <h2>ilogs DRiVEBOX GmbH</h2>
        <h2>Stand: Juni 2020</h2>
        <br/>
        <br/>
        <u>Geltungsbereich</u><br/>
        Die nachstehend angeführten Geschäftsbedingungen haben für alle vertraglichen Beziehungen zu unserem Unternehmen
        Geltung und finden auf alle Bestellungen, Aufträge und Leistungen, die diesem erteilt werden oder von diesem
        erbracht werden Anwendung, soweit nicht ausdrücklich beiderseits unterfertigte schriftliche anders lautende
        Vereinbarungen getroffen wurden oder, für den Fall, dass der Auftraggeber unserem Unternehmer als Endverbraucher
        gegenübertritt, Bestimmungen des Konsumentenschutzgesetzes diesen Geschäftsbedingungen vorgehen.<br/>
        Änderungen dieser AGB können durch Angebot der ilogs DRiVEBOX GmbH und Annahme des Vertragspartners nach Maßgabe
        der folgenden Bestimmungen vereinbart werden. Das Angebot der ilogs DRiVEBOX GmbH erfolgt durch Mitteilung der
        inhaltlichen Änderungen in Textform (Änderungsmitteilung). Schweigt der Vertragspartner auf das Angebot der
        ilogs DRiVEBOX GmbH oder widerspricht er nicht innerhalb von vier Wochen nach Zugang der Änderungsmitteilung, so
        stellt dies eine Annahme des Angebots dar mit der die Änderungen wirksam werden. Widerspricht der
        Vertragspartner fristgerecht, so gelten die bisherigen AGB fort. Zur Wahrung der Frist genügt die rechtzeitige
        Absendung der Widerspruchserklärung in Textform.<br/>
        Für das Zustandekommen eines Vertragsverhältnisses ist die Bestellung des Vertragspartner (online, offline) und
        unsere Auftragsbestätigung oder alternativ unsere erste Rechnung notwendig.<br/>
        <br/>
        <u>Gegenstand von DRiVEBOX</u><br/>
        DRiVEBOX ist eine elektronisches Telematiklösung zur Dokumentation von getätigten Fahrten mit einem KFZ.
        Das Produkt DRiVEBOX der ilogs DRiVEBOX GmbH besteht aus einem OBDII Stecker, der dem Vertragspartner mietweise
        überlassen wird, sowie bei Bedarf einem Verlängerungskabel (Kaufpflicht)zum Anschluss des OBDII Steckers. Die
        Software stellt ilogs DRiVEBOX dem Vertragspartner mietweise zur Verfügung ("Lizenz").<br/>
        Der mietweise überlassene DRiVEBOX OBDII Stecker wird in den (sich meist im Fußraum befindlichen)
        Diagnoseanschluss des Fahrzeuges eingesteckt. Diese Technik ermöglicht Zugriff auf Daten direkt aus dem
        Fahrzeug, was das Führen eines lückenlosen Fahrtenbuchs begünstigt (solange der Stecker sich im
        Diagnoseanschluss befindet). In dem OBDII Stecker ist ein GPS-Modul zur Positionsbestimmung verbaut. Zur
        Datenübermittlung enthält der OBDII Stecker zudem eine SIM-Karte. Diese darf nicht aus dem OBDII Stecker
        entfernt werden und ist ausschließlich im Zusammenhang mit DRiVEBOX zu verwenden.<br/>
        Die alternativ angebotene Hardware DRiVEBOX GPS-Ortungseinheit BM (battery mounted) wird an der Fahrzeugbatterie
        befestigt und wird mietweise dem Vertragspartner überlassene. Diese Technik ermöglicht die Nutzung von DRiVEBOX
        ohne Verwendung von OBD-Daten. In der DRiVEBOX GPS-Ortungseinheit BM ist ein GPS-Modul zur Positionsbestimmung
        verbaut. Zur Datenübermittlung enthält die DRiVEBOX GPS-Ortungseinheit BM zudem eine SIM-Karte.<br/>
        Zur Nutzung von DRiVEBOX ist weiters die DRiVEBOX-App und ein Nutzer-Account erforderlich. Die DRiVEBOX -App
        lädt der Nutzer in den entsprechenden "App-Stores" (iOS, Android) selbstständig auf sein Endgerät herunter. Die
        Zugangsdaten für den Nutzer-Account werden per E-Mail zugesendet.<br/>
        Das im Funktionsumfang von DRiVEBOX gehörende Fahrtenbuch orientiert sich an den gesetzlichen Vorschriften der
        Finanzbehörden an digitale Fahrtenbücher. Die ilogs DRiVEBOX GmbH erklärt nach bestem Wissen, dass die
        derzeitige Programmversion die technischen Voraussetzungen für die Führung eines ordnungsgemäßen digitalen
        Fahrtenbuchs erfüllt und auch zukünftige Updates die Voraussetzungen ebenfalls erfüllen.<br/>
        Die ilogs DRiVEBOX GmbH weist darauf hin, dass es derzeit keine Möglichkeit gibt, elektronische Fahrtenbücher
        von der Finanzverwaltung zertifizieren zu lassen oder eine offizielle Zulassung elektronischer Fahrtenbücher
        durch die Finanzverwaltung zu erhalten. Die Prüfung, ob ein elektronisches Fahrtenbuch als ordnungsgemäß
        anzuerkennen ist, erfolgt immer nur im Einzelfall.<br/>
        Die zur Nutzung von DRiVEBOX erforderliche Hardware (z.B. GPS-Aufzeichnungsgerät) wird dem Vertragspartner
        während der Vertragslaufzeit überlassen. Sie verbleibt im Eigentum der ilogs DRiVEBOX GmbH und muss nach
        Vertragsende auf Kosten des Vertragspartners an die ilogs DRiVEBOX GmbH zurückgesandt werden. Die Hardware ist
        pfleglich zu behandeln. Der Vertragspartner haftet für jede von ihm oder von Dritten verschuldete Beschädigung,
        für die er einzustehen hat. Bei Verlust oder irreversibler Beschädigung der Hardware wird dem Vertragspartner
        ein Pauschalbetrag in Höhe von 100 Euro (exkl. MwSt.) für jedes Gerät in Rechnung gestellt.<br/>
        Für die Dauer der Laufzeit des Vertrages bzw. der Testphase erhält der Nutzer das nicht-ausschließliche,
        zeitlich unbeschränkte, nicht übertragbare und nicht unterlizenzierbare Recht zur Nutzung der Software. Die
        vertragsgemäße Nutzung umfasst die Installation sowie das Laden, Anzeigen und Anwenden der installierten
        Software.<br/>
        Der Vertragspartner erhält etwaige Softwareupdates einschließlich der Firmware auf der Hardware, die
        gegebenenfalls zum ordnungsgemäßen Betrieb der Software erforderlich sind. Der Vertragspartner verpflichtet
        sich, die Hardware ausschließlich mit der von der ilogs DRiVEBOX GmbH zur Verfügung gestellten Firmware zu
        betreiben.
        <br/>
        <br/>

        <u>Lieferung und Produktverfügbarkeit</u><br/>
        Der Versand der Hardware erfolgt per Post. Die ilogs DRiVEBOX GmbH trägt das Versandrisiko. Bei lagernder Ware
        erfolgt die Auslieferung zumeist innerhalb von einer Woche.<br/>
        Die Software wird vom Nutzer direkt aus den entsprechenden "App-Stores" (iOS, Android, etc.) für sein jeweiliges
        mobiles Endgerät heruntergeladen.<br/>
        Für den Zugriff auf die Plattform wird dem Nutzer eine E-Mail mit allen weiteren Informationen (Login) gesendet.
        <br/>
        <br/>
        <u>Preise und Zahlungskonditionen</u><br/>
        Mit Erwerb von DRiVEBOX erhält der Vertragspartner für einen beschränkten Zeitraum die Hardware sowie das
        nicht-ausschließliche, zeitlich unbeschränkte, nicht übertragbare und nicht unterlizenzierbare Recht zur Nutzung
        der Software, die Kosten für die Nutzung der SIM-Karte sind hierbei bereits enthalten. Die Gebühr für die Lizenz
        ist jeweils im Voraus zu entrichten. Die verrechnete Gebühr ist mit Erhalt der Rechnung fällig.<br/>
        Eventuell geltend gemachte Gewährleistungsansprüche oder Mängelrügen haben keine zahlungsauf-schiebende Wirkung.
        Sollte unserer Zahlungsaufforderung trotz Mahnung nicht termingerecht nachgekommen werden, behalten wir uns das
        Recht vor, die bereitgestellte Software vorrübergehend bis zur Zahlung zu deaktivieren.<br/>
        Alle Preise verstehen sich in Euro zuzüglich der gesetzlichen Mehrwertsteuer sowie zuzüglich Transport- und
        Verpackungskosten. Preisänderungen sind, wenn nicht anders bekannt gegeben, ab sofort gültig. Für zukünftige
        oder andere Aufträge und Nachbestelllungen gelten die zukünftigen Preise zum Zeitpunkt der Lieferung der
        Nachbestellung. Mengenstaffeln gelten nur für Einzelaufträge und wenn die bestellte Menge in einer Lieferung
        ausgeliefert wird.<br/>
        <br/>
        <u>Testphase</u><br/>
        Dem interessierten Neukunden wird DRiVEBOX für einen beschränkten Zeitraum unentgeltlich zur Verfügung gestellt,
        dieser Zeitraum endet mit der dem Vertragspartner individuell mitgeteilten Laufzeit automatisch ("Testphase").
        Nach Ablauf dieser Testphase hat der Nutzer die Möglichkeit seinen bereits angelegten Nutzer-Account durch
        Erwerb einer Lizenz weiterzuführen.<br/>
        Entscheidet sich der Nutzer nach Ablauf der individuell mitgeteilten Testphase gegen den Erwerb einer Lizenz, so
        wird sein Nutzer-Account mit Ablauf der Testphase gesperrt. Die Hardware ist auf eigene Kosten unverzüglich an
        die ilogs DRiVEBOX GmbH (Adresse siehe Homepage) zurückzusenden. Für Hardware, die nicht binnen zehn Tagen nach
        Ende der Testphase bei uns eingegangen ist, wird ein Pauschalbetrag in Höhe von 100 Euro (exkl. MwSt.) für jedes
        Gerät verrechnet.
        <br/>
        <br/>
        <u>Laufzeit und Kündigung</u><br/>
        Für Neukunden beträgt die Mindestvertragslaufzeit zwölf Monate. Für Bestandskunden gilt die individuell
        mitgeteilte Vertragslaufzeit, die in der Regel ebenfalls zwölf Monate beträgt. In jedem Fall verlängert sich die
        Vertragslaufzeit jeweils um weitere zwölf Monate, sofern der Vertrag nicht spätestens zwölf Wochen vor
        Vertragsende durch den Vertragspartner schriftlich mittels Einschreiben gekündigt wird.<br/>
        Nach Beendigung des Vertragsverhältnisses hat der Vertragspartner die Hardware auf eigene Kosten unverzüglich an
        die ilogs DRiVEBOX GmbH (Adresse siehe Homepage) zurückzusenden. Für Hardware, die nicht binnen zehn Tagen nach
        Vertragsende bei uns eingegangen ist, wird dem Vertragspartner ein Pauschalbetrag in Höhe von 100 Euro (exkl.
        MwSt.) für jedes Gerät verrechnet.<br/>
        Die außerordentliche Kündigung aus wichtigem Grund bleibt hiervon unberührt.<br/>
        <br/>
        <u>Gewährleistung und Garantie</u><br/>
        Wir haften für Schäden nur, sofern Vorsatz oder grobe Fahrlässigkeit nachgewiesen werden kann, im Rahmen der
        gesetzlichen Vorschriften. Die Haftung für leichte Fahrlässigkeit ist ebenso ausgeschlossen wie der Ersatz von
        Folgeschäden und Vermögensschäden, nicht erzielten Ersparnissen oder Einkünften, Zinsenverlusten und von Schäden
        aus Ansprüchen Dritter gegen den Vertragspartner, selbst dann nicht, wenn wir von der Möglichkeit eines solchen
        Schadens unterrichtet worden sind.<br/>
        Jede Haftung ist ausgeschlossen bei Schäden aus entgangenem Gewinn, Betriebsunterbrechungen, Verlust von
        geschäftlichen Informationen oder finanziellen Verlusten.<br/>
        Bei Nichteinhaltung allfälliger Bedingungen für Montage, Inbetriebnahme und Benützung (wie z.B. in der
        technischen Dokumentation) oder behördlicher Zulassungsbedingungen durch den Vertragspartner ist jeder
        Schadenersatz ausgeschossen.<br/>
        Aufgrund der komplexen Abhängigkeiten zu anderen Netzen oder durch externe Störungen oder auftretende Ausnahmen
        (z.B. ungenaues GPS Signal, Störungen in Fahrzeugen, GSM Ausfälle, Internet/Netzwerke) kann nicht garantiert
        werden, dass die Software oder Endgeräte ununterbrochen, lückenlos oder fehlerfrei laufen oder dass alle
        Programmfehler behoben werden können.<br/>
        Insbesondere Aktualität, Genauigkeit oder Lückenlosigkeit der Daten und Aufzeichnungen hängen auch von uns nicht
        vertretbaren Faktoren ab (Umweltfaktoren, Mobilfunkprovider etc.) und können nicht permanent garantiert werden.
        Für falsche oder fehlende Daten von Zubehör (Sensoren, Motordaten, Tachodaten usw.) die von Einbau und
        angesteckten Geräten abhängen, übernehmen wir keine Garantie.<br/>
        Im Speziellen übernehmen wir keinerlei Haftung für nicht ganz oder nur teilweise oder verspätet zugestellte
        Daten und Nachrichten, langsame Übertragungsgeschwindigkeiten, höhere Gewalt oder technische Einschränkungen,
        die sich durch die Beschaffenheit der verwendeten Endgeräte ergeben.
        Es liegt in der Verantwortung des Kunden, regelmäßig die Daten zu exportieren. Für etwaigen Datenverlust
        übernehmen wir keinerlei Haftung.<br/>
        Eine Anerkennung von Berichten („Fahrtenbuch“) durch Dritte (z.B. Finanzbehörden) kann vom Anbieter nicht
        garantiert werden.<br/>
        Bei Updates oder Wartungsarbeiten an zentralen Komponenten (Softwareupdates) kann es zu Einschränkung der
        Verfügbarkeit von DRiVEBOX kommen.<br/>
        Die ilogs DRiVEBOX GmbH übernimmt während der Vertragslaufzeit die Kosten für einen etwaigen Austausch oder eine
        Reparatur der Hardware, soweit die aufgetretenen Störungen bei ordnungsgemäßem Gebrauch entstanden sind.
        Reklamiert der Nutzer einen Fehler der Hardware, überprüft die ilogs DRiVEBOX GmbH deren Funktionsfähigkeit.
        Gibt es Anhaltspunkte dafür, dass die Hardware defekt ist, wird dem Nutzer auf unsere Kosten Austauschhardware
        zugesendet.<br/>
        Stellt sich jedoch durch unsere Überprüfung heraus, dass die eingesendete Hardware funktionsfähig ist, ist die
        ilogs DRiVEBOX GmbH berechtigt, die durch die Überprüfung/Reparatur anfallenden Kosten dem Vertragspartner nach
        Aufwand in Rechnung zu stellen. <br/>
        <br/>
        <u>Installation und Haftung</u><br/>
        Die Installation der Hardware erfolgt ausschließlich auf Verantwortung des Vertragspartners. Er muss
        eigenständig, vor Bestellung, mit dem Hersteller seines Kraftfahrzeuges klären, ob die Installation der Hardware
        die jeweilige Herstellergarantie und/oder -gewährleistung berührt.
        Ansprüche des Vertragspartners auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind
        Schadensersatzansprüche des Vertragspartners aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus
        der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige Schäden, die
        auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung der ilogs DRiVEBOX GmbH, ihrer gesetzlichen
        Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur
        Erreichung des Ziels des Vertrags notwendig ist.<br/>
        Der Vertragspartner wird darauf hinwirken, umgehend nach Erhalt der Hardware einen Kompatibilitätscheck mit
        seinem KFZ sowie einen Test der Funktionsfähigkeit von DRiVEBOX durchzuführen und die ilogs DRiVEBOX GmbH auf
        etwaige, für ihn erkennbare, Fehler und/oder Fehlfunktionen unverzüglich hinweisen.
        Die ilogs DRiVEBOX GmbH behält sich vor, den Zugriff auf die Software und die uneingeschränkte Nutzung der
        Hardware zu unterbrechen, solange und soweit dies aus zwingenden Gründen erforderlich ist. Beispiele für solche
        Gründe sind notwendige Wartungsarbeiten an Servern oder Programmen bzw. der notwendigen Infrastruktur, bei
        unbefugten Angriffen auf Daten oder Rechner, zur Beseitigung unvorhergesehener Sicherheitslücken oder sonstiger
        schwerwiegender Störungen. Diese kurzzeitigen Einschränkungen begründen keine Haftung oder
        Gewährleistungsansprüche des Vertragspartners.<br/><br/>
        <u>Datenschutzerklärung, Geheimhaltungsverpflichtung u. Urheberrecht</u><br/>
        Der sichere Umgang mit Kundendaten ist in unserer Datenschutzerklärung geregelt, die online auf unserer Homepage
        bereitgestellt wird, und auf die alle Aufträge und Verträge Anwendung findet. Alle für die Zusammenarbeit
        wesentlichen Aspekte des Datenschutzes sind in unserer Datenschutzvereinbarung geregelt, die öffentlich auf
        unserer Homepage zugänglich ist.<br/>
        Eine Zugriffsmöglichkeit des Vertragspartners auf die Datenbestände einzelner oder aller Geräte ist nach
        Beendigung des Vertrages oder bei Löschung von Daten aus Gründen des Datenschutzes nicht mehr möglich.
        Der Vertragspartner verpflichtet sich insbesondere alle ihm bekannt gegebenen Zugangsdaten (Webadressen)
        und/oder Passwörter und Informationen nicht an Dritte weiterzugeben und datenschutzrechtliche Bestimmungen zu
        beachten.<br/>
        Der Vertragspartner ist ferner nicht berechtigt, die gelieferten Hard- und Softwarekomponenten zu
        vervielfältigen, nachzuahmen, zu verändern oder mit Fremdprodukten zu verbinden.<br/>
        Ausführungsunterlagen wie z.B. Pläne, Skizzen und sonstige technische Unterlagen bleiben ebenso wie Muster,
        Kataloge, Prospekte, Abbildungen u.ä. stets in unserem geistigen Eigentum.<br/>
        Sämtliche sonstige Vereinbarungen, die Vertragsinhalt sein sollen, oder die zwischen unserem Unternehmen und dem
        Vertragspartner im Zusammenhang mit der Ausführung des Vertrags getroffen werden, sind schriftlich
        niederzulegen.<br/>
        Dies gilt auch für Vereinbarungen, durch die der Inhalt des Vertrags nachträglich geändert wird. Mündliche
        Abreden haben nur Geltung, wenn diese von unserem Unternehmen schriftlich bestätigt werden.
        Erfüllungsort und anzuwendendes Recht<br/>
        Es gilt österreichisches Recht unter Ausschluss der UN Kaufrechts (Uniform Sales Law), auch dann, wenn der
        Auftrag im Ausland durchgeführt wird. Erfüllungsort und Gerichtsstandort ist das sachlich zuständige Gericht am
        Sitz der ilogs DRiVEBOX GmbH. Ilogs DRiVEBOX GmbH ist berechtigt, diese AGB bzw. unsere Datenschutzerklärung,
        unsere technische Dokumentation und alle anderen vertraglichen Vereinbarungen jederzeit zu ändern. Alle
        Änderungen werden online veröffentlicht und treten sofort in Kraft.
        Rücktrittsrecht für Konsumenten (Privatkunden) laut ECG-Gesetz:<br/>
        Ein Verbraucher (im Sinne des Konsumentenschutzgesetzes) kann von einem im Fernabsatz geschlossenen Vertrag
        innerhalb von 14 Werktagen zurücktreten. Wir gewähren unseren Privatkunden (Verbrauchern, Konsumenten), die Ihre
        Bestellung online durchführen, eine Rücktrittsfrist von 14 Werktagen.<br/>
        Die Frist beginnt mit dem Tag ab Zustellung der Ware bzw. Lieferung/Freischalten der Software. Die
        Widerrufsfrist läuft auch ab, wenn die Produkte bis dahin nicht verbaut oder die Software bis dahin nicht
        getestet wurde.<br/>
        Etwaige Kosten für den Ausbau oder Folgekosten für SIM-Karten trägt der Kunde, auch dann, wenn er von seinem
        Rücktrittsrecht Gebrauch macht. Der Widerruf hat schriftlich oder durch Rücksendung der bestellten Ware zu
        erfolgen. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs oder der Ware.
    </div>

    return <CustomModal largeModal title="AGBs" contents={[content]} onClose={onClose}/>
};

// OrderAGBModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };


export default OrderAGBModal;