export const SEARCH_LOCALLY_GEOFENCES = "SEARCH_LOCALLY_GEOFENCES";

export const REQUEST_GEOFENCE_SUBSCRIPTION_DATE = "REQUEST_GEOFENCE_SUBSCRIPTION_DATE";
export const RECEIVE_GEOFENCE_SUBSCRIPTION_DATE = "RECEIVE_GEOFENCE_SUBSCRIPTION_DATE";

export const REQUEST_GEOFENCES_OF_CURRENT_TRIP = "REQUEST_GEOFENCES_OF_CURRENT_TRIP";
export const RECEIVE_GEOFENCES_OF_CURRENT_TRIP = "RECEIVE_GEOFENCES_OF_CURRENT_TRIP";

export const REQUEST_GEOFENCES_EVENTS_OF_CURRENT_TRIP = "REQUEST_GEOFENCES_EVENTS_OF_CURRENT_TRIP";
export const RECEIVE_GEOFENCES_EVENTS_OF_CURRENT_TRIP = "RECEIVE_GEOFENCES_EVENTS_OF_CURRENT_TRIP";

export const REQUEST_GEOFENCES_OF_CURRENT_VEHICLE = "REQUEST_GEOFENCES_OF_CURRENT_VEHICLE";
export const RECEIVE_GEOFENCES_OF_CURRENT_VEHICLE = "RECEIVE_GEOFENCES_OF_CURRENT_VEHICLE";

export const SEARCH_LOCALLY_GEOFENCE_VEHICLES = "SEARCH_LOCALLY_GEOFENCE_VEHICLES";  

export const REQUEST_ASSIGNED_GEOFENCE_VEHICLES = "REQUEST_ASSIGNED_GEOFENCE_VEHICLES";
export const RECEIVE_ASSIGNED_GEOFENCE_VEHICLES = "RECEIVE_ASSIGNED_GEOFENCE_VEHICLES";

export const REQUEST_UNASSIGNED_GEOFENCE_VEHICLES = "REQUEST_UNASSIGNED_GEOFENCE_VEHICLES";
export const RECEIVE_UNASSIGNED_GEOFENCE_VEHICLES = "RECEIVE_UNASSIGNED_GEOFENCE_VEHICLES";

export const EXCHANGE_LOCALLY_GEOFENCE_VEHICLES = "EXCHANGE_LOCALLY_GEOFENCE_VEHICLES";

export const CHECK_LOCALLY_CLICKED_GEOFENCE_VEHICLE = "CHECK_LOCALLY_CLICKED_GEOFENCE_VEHICLE"; 

export const REQUEST_UPDATE_GEOFENCE_VEHICLES = "REQUEST_UPDATE_GEOFENCE_VEHICLES";

export const REQUEST_GEOFENCES_NAME_ID = "REQUEST_GEOFENCES_NAME_ID";
export const RECEIVE_GEOFENCES_NAME_ID = "RECEIVE_GEOFENCES_NAME_ID";

export const REQUEST_GEOFENCES_EVENT_TRIPS = "REQUEST_GEOFENCES_EVENT_TRIPS";

export const REQUEST_CREATE_GEOFENCE_ANALYSIS = "REQUEST_CREATE_GEOFENCE_ANALYSIS";

export const REQUEST_EDIT_GEOFENCE_ANALYSIS = "REQUEST_EDIT_GEOFENCE_ANALYSIS";

export const REQUEST_GEOFENCE_ANALYSES = "REQUEST_GEOFENCE_ANALYSES";
export const RECEIVE_GEOFENCE_ANALYSES = "RECEIVE_GEOFENCE_ANALYSES";

export const REQUEST_GEOFENCE_ANALYSIS_EVENTS = "REQUEST_GEOFENCE_ANALYSIS_EVENTS";
export const RECEIVE_GEOFENCE_ANALYSIS_EVENTS = "RECEIVE_GEOFENCE_ANALYSIS_EVENTS";

export const REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING = "REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING";
export const RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING = "RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING";

export const REQUEST_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED = "REQUEST_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED";
export const RECEIVE_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED = "RECEIVE_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED";

export const REQUEST_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS = "REQUEST_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS";
export const RECEIVE_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS = "RECEIVE_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS";

export const REQUEST_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE = "REQUEST_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE";
export const RECEIVE_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE = "RECEIVE_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE";

export const REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION = "REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION";
export const RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION = "RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION";

export const REQUEST_GEOFENCE_TOGGLE_ARCHIVE = "REQUEST_GEOFENCE_TOGGLE_ARCHIVE";
export const RECEIVE_GEOFENCE_TOGGLE_ARCHIVE = "RECEIVE_GEOFENCE_TOGGLE_ARCHIVE";

export const REQUEST_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE = "REQUEST_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE";
export const RECEIVE_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE = "RECEIVE_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE";


/**
 * 
 * @param {Object} data Contains the search term for filtering geofences. 
 * @param {string} data.searchTerm Search term for filtering geofences. 
 * @param {string} data.module Module/state name of geofences to search.
 * @returns 
 */
export const searchLocallyGeofences = data => ({
    type: SEARCH_LOCALLY_GEOFENCES,
    data,
})

/**  
 * Send request to backend via sagaGeofences.js to read the GeofenceSubscriptionDate of the current mandator.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @returns 
 */
export const requestGeofenceSubscriptionDate = data => ({
    type: REQUEST_GEOFENCE_SUBSCRIPTION_DATE,
    data,
});

/** 
 * Sets state.geofenceSubscriptionDate with data received from {@link requestGeofenceSubscriptionDate}.
 * @param {DateTime} data  The date of the mandators subscription to the geofence module.
 * @returns 
 */
export const receiveGeofenceSubscriptionDate = data => ({
    type: RECEIVE_GEOFENCE_SUBSCRIPTION_DATE,
    data,
});

/**  
 * Send request to backend via sagaGeofences.js to read all geofence of current trip.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @param {string} data.tripId Id of the trip to filter geofences.
 * @returns 
 */
export const requestGeofencesOfCurrentTrip = data => ({
    type: REQUEST_GEOFENCES_OF_CURRENT_TRIP,
    data,
});

/** 
 * Sets state.geofencesOfCurrentTrip with data received from {@link requestGeofencesOfCurrentTrip}.
 * @param {List<Object>} data Contains a list of geofences for a particular trip, for more info check initial state in reducerGeofence.js (geofencesOfCurrentTrip).
 * @param {string} data.id Id of geofence.
 * @param {string} data.title Title of geofence.
 * @param {List<List<double>>} data.coordinates Coordinates of polygon geofence.
 * @returns 
 */
export const receiveGeofencesOfCurrentTrip = data => ({
    type: RECEIVE_GEOFENCES_OF_CURRENT_TRIP,
    data,
});

/**
 * Send request to backend via sagaGeofences.js to read all geofence events of current trip.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @param {string} data.tripId Id of the trip to filter geofence events.
 * @returns 
 */
export const requestGeofencesEventsOfCurrentTrip = data => ({
    type: REQUEST_GEOFENCES_EVENTS_OF_CURRENT_TRIP,
    data,
});

/**
 * Sets state.eventsOfCurrentTrip with data received from {@link requestGeofenceEventsOfCurrentTrip}
 * @param {Object} data Contains a list of geofence-events for a particular trip, for more info check initial state in reducerGeofence.js (eventsOfCurrentTrip).
 * @returns 
 */
export const receiveGeofencesEventsOfCurrentTrip = data => ({
    type: RECEIVE_GEOFENCES_EVENTS_OF_CURRENT_TRIP,
    data,
});

/**
 * Send request to backend via sagaGeofences.js to read geofences that are assigned to current vehicle.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @param {string} data.vehicleId The id of vehicle of which we are going to read geofences.
 * @returns 
 */
export const requestGeofencesOfCurrentVehicle = data => ({
    type: REQUEST_GEOFENCES_OF_CURRENT_VEHICLE,
    data,
});

/**
 * Sets the state.geofencesOfCurrentVehicle with data received from {@link requestGeofencesOfCurrentVehicle}
 * @param {Object} data Contains a list of geofences assigned to the vehicle, for more info check initial state in reducerGeofences.js (geofencesOfCurrentVehicle).
 * @returns 
 */
export const receiveGeofencesOfCurrentVehicle = data => ({
    type: RECEIVE_GEOFENCES_OF_CURRENT_VEHICLE,
    data,
});

/**
 * Filters the geofences based on the search term in the specified module (the state name).
 * @param {Object} data Object that contains the searchTerm and the state name.
 * @param {string} data.searchTerm Term to search.
 * @param {string} data.module Module/state name of geofences to search.
 * @returns 
 */
export const searchLocallyGeofenceVehicles = data => ({
    type: SEARCH_LOCALLY_GEOFENCE_VEHICLES,
    data,
})

/**
 * Send request to backend to read unassigned geofence vehicles of mandator via sagaGeofences.js.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @param {Object} data.geofenceId The id of geofence.
 * @returns 
 */
export const requestUnAssignedGeofenceVehicles = data => ({
    type: REQUEST_UNASSIGNED_GEOFENCE_VEHICLES,
    data,
});

/**
 * Sets the state.unAssignedGeofenceVehicles with the data received from {@link requestUnAssignedGeofenceVehicles}.
 * @param {Object} data Contains a list of unassigned geofence vehicles, for more info check initial state in reducerGeofences.js (unAssignedGeofenceVehicles).
 * @returns 
 */
export const receiveUnAssignedGeofenceVehicles = data => ({
    type: RECEIVE_UNASSIGNED_GEOFENCE_VEHICLES,
    data,
})

/**
 * Send request to backend to read assigned geofence vehicles of mandator for particular geofence via sagaGeofences.js.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @param {Object} data.geofenceId The id of geofence.
 * @returns 
 */
export const requestAssignedGeofenceVehicles = data => ({
    type: REQUEST_ASSIGNED_GEOFENCE_VEHICLES,
    data,
})

/**
 * Sets the state.assignedGeofenceVehicles with the data received from {@link requestAssignedGeofenceVehicles}.
 * @param {Object} data Contains a list of assigned geofence vehicles, for more info check initial state in reducerGeofences.js (assignedGeofenceVehicles).
 * @returns 
 */
export const receiveAssignedGeofenceVehicles = data => ({
    type: RECEIVE_ASSIGNED_GEOFENCE_VEHICLES,
    data,
})

/**
 * Moves geofence vehicles locally from assigned list (initially taken from {@link requestAssignedGeofenceVehicles}) 
 * to unassigned list (initially taken from {@link  requestUnAssignedGeofenceVehicles}) and vice-versa.
 * This is done by comparing a parameter that is added dinamically after we read data from backend which is called isLocallyChecked.
 * @param {Object} data Contains the states from where to taken and send geofence vehicles.
 * @param {string} data.fromModule The module or state (accessed as state[data.fromModule]) from which we want to move geofence vehicles.
 * @param {string} data.toModule The module or state (accessed as state[data.toModule]) to which we want to move geofence vehicles.
 * @returns It updates the states assignedGeofenceVehicles and unAssignedGeofenceVehicles.
 */
export const exchangeLocallyGeofenceVehicles = data => ({
    type: EXCHANGE_LOCALLY_GEOFENCE_VEHICLES,
    data,
})

/**
 * Checks locally elements in (un)assigned geofence vehicle lists. 
 * The model taken from {@link requestAssignedGeofenceVehicles} and {@link requestUnAssignedGeofenceVehicles} does not contain the field isLocallyChecked, but here it is added dynamically.
 * @param {Object} data Contains the ids/or check all strings, and in which module are those geofence vehicles checked.
 * @param {string} data.toCheck The id of vehicle to be checked, or if the value is 'checked', 'unchecked' or 'indeterminate', it will handle Check All operation.
 * @param {string} data.module The module or state (accessed as state[data.toModule]) in which we want to do check geofence vehicles.boolean
 * @returns It updates the states assignedGeofenceVehicles and unAssignedGeofenceVehicles, by adding (if does not exists) or updating isLocallyChecked
 */
export const checkLocallyGeofenceVehicle = data => ({
    type: CHECK_LOCALLY_CLICKED_GEOFENCE_VEHICLE,
    data,
})

/**
 * Updates the geofence vehicles in database by calling backend via sageGeofences.js.
 * It concats from state both assignedGeofenceVehicles and unAssignedGeofenceVehicles and sends it as parameter in backend. 
 * @param {string} data Contains the id of geofence where the vehicles will be (un)assigned.
 * @returns
 */
export const requestUpdateGeofenceVehicles = data => ({
    type: REQUEST_UPDATE_GEOFENCE_VEHICLES,
    data,
})

/**
 * Send a request to backend to read geofence name and ids via sagaGeofences.js.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @param {string} [data.searchTerm] Term to search in geofences.
 * @returns
 */
export const requestGeofencesNameId = data => ({
    type: REQUEST_GEOFENCES_NAME_ID,
    data,
})
/**
 * Sets the state.geofencesNameId with the data received from {@link requestGeofencesNameId}  
 * @param {List<Object>} data List of geofences (name and id).
 * @param {string} data.Name Name of the geofence.
 * @param {string} data.Id Id of the geofence.
 * @returns 
 */
export const receiveGeofencesNameId = data => ({
    type: RECEIVE_GEOFENCES_NAME_ID,
    data,
})

/**
 * Send a request to backend to read geofence trips that have geofence events via sagaGeofences.js.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @param {string} [data.searchTerm] Term to search in geofences.
 * @returns
 */
export const requestGeofencesEventTrips = data => ({
    type: REQUEST_GEOFENCES_EVENT_TRIPS,
    data,
})

/**
 * Send a request to backend to create a worker computing geofence events regarding a set of 
 * vehicles in a set time period. After successful computation this will be available via sagaGeofences.js.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @returns
 */
export const requestCreateGeofenceAnalysis = data => ({
    type: REQUEST_CREATE_GEOFENCE_ANALYSIS,
    data,
})

/**
 * Send a request to backend to edit a geofence analysis object, after creation this should be availbale via sagaGeofences.js.
 * @param {Object} data Object that contains additional properties for handling this request.
 * @returns
 */
export const requestEditGeofenceAnalysis = data => ({
    type: REQUEST_EDIT_GEOFENCE_ANALYSIS,
    data,
})

/**
 * 
 * @param {string} geofenceAnalysisId Object that contains additional properties for handling this request.
 * @returns
 */
export const requestGeofenceAnalysisTotalTimeInside = data => ({
    type: REQUEST_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE,
    data,
})

/**
 * Sets the state.geofenceAnalysisTotalTimeInside with data received from {@link requestGeofenceAnalysisTotalTimeInside}
 * @param {string} geofenceAnalysisTotalTimeInside A datetime object representing time all vehicles have been inside geofences inside of a geofence analysis.
 * @returns 
 */
export const receiveGeofenceAnalysisTotalTimeInside = data => ({
    type: RECEIVE_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE,
    data,
})

/**
 * 
 * @param {string} geofenceAnalysisId Object that contains additional properties for handling this request.
 * @returns
 */
export const requestGeofenceAnalysisTotalEntryEvents = data => ({
    type: REQUEST_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS,
    data,
})

/**
 * Sets the state.geofenceAnalysisTotalEntryEvents with data received from {@link requestGeofenceAnalysisTotalEntryEvents}
 * @param {Object} geofenceAnalysisTotalEntryEvents An integer showing the number of total entry events registered.
 * @returns 
 */
export const receiveGeofenceAnalysisTotalEntryEvents = data => ({
    type: RECEIVE_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS,
    data,
})

/**
 * 
 * @param {string} geofenceAnalysisId Object that contains additional properties for handling this request.
 * @returns
 */
export const requestGeofenceAnalysisVehiclesEntering = data => ({
    type: REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING,
    data,
})

/**
 * 
 * @param {string} geofenceAnalysisId Object that contains additional properties for handling this request.
 * @returns
 */
export const requestGeofenceAnalysisUniqueVehiclesEntered = data => ({
    type: REQUEST_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED,
    data,
});

/**
 * Sets the state.geofenceAnalysisUniqueVehiclesEntered with data received from {@link requestGeofenceAnalysisUniqueVehiclesEntered}
 * @param {Object} geofenceAnalysisUniqueVehiclesEntered An integer showing the number of unique vehicles which have entered in all geofences in a geofence analysis.
 * @returns 
 */
export const receiveGeofenceAnalysisUniqueVehiclesEntered = data => ({
    type: RECEIVE_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED,
    data,
})

/**
 * @param {int} page Integer containing the page numer as a pagination parameter
 * @param {int} perPage Integer containing the page numer as a pagination parameter
 * @param {string} geofenceAnalysisId Object that contains additional properties for handling this request.
 * @returns 
 */
export const requestGeofenceAnalysisEvents = data => ({
    type: REQUEST_GEOFENCE_ANALYSIS_EVENTS,
    data,
});

/**
 * Sets the state.geofenceAnalysisEvents with data received from {@link requestGeofenceAnalysisEvents}
 * @param {Object} geofenceAnalysisEvents Contains a list of geofence events which are connected to a particular geofence analysis.
 * @returns 
 */
export const receiveGeofenceAnalysisEvents = data => ({
    type: RECEIVE_GEOFENCE_ANALYSIS_EVENTS,
    data,
});

/**
 * Sets the state.geofenceAnalysesVehiclesEntering with data received from {@link requestGeofenceAnalysisVehiclesEntering}
 * @param {Object} geofenceAnalysisVehiclesEntering Contains a list of vehicles which are entering geofences contained by a geofence analysis.
 * @returns 
 */
export const receiveGeofenceAnalysisVehiclesEntering = data => ({
    type: RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING,
    data,
});

/**
 * 
 * @param {string} geofenceAnalysisId Object that contains additional properties for handling this request.
 * @returns
 */
export const requestGeofenceAnalysisVehiclesEnteringDuration = data => ({
    type: REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION,
    data,
});

/**
 * Sets the state.geofenceAnalysesVehiclesEnteringDuration with data received from {@link requestGeofenceAnalysisVehiclesEnteringDuration}
 * @param {Object} geofenceAnalysisVehiclesEnteringDuration Contains a list of views representing vehicles geofence analysis trip data by type (business, private, etc.)
 * @returns 
 */
export const receiveGeofenceAnalysisVehiclesEnteringDuration = data => ({
    type: RECEIVE_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION,
    data,
});

/**
 * 
 * @param {Object} data Object that contains additional properties for handling this request.
 * @returns
 */
export const requestGeofenceAnalyses = data => ({
    type: REQUEST_GEOFENCE_ANALYSES,
    data,
});


/**
 * Sets the state.geofenceAnalyses with data received from {@link requestGeofenceAnalyses}
 * @param {Object} data Contains a list of geofence-analysis objects assigned to the mandator, for more info check initial state in reducerGeofences.js (geofenceAnalyses).
 * @returns 
 */
export const receiveGeofenceAnalyses = data => ({
    type: RECEIVE_GEOFENCE_ANALYSES,
    data,
});

/**
 * Send a request to backend to toggle the archived date and comment of a geofence via sagaGeofences.js.
 * 
 * @param {Object} data Object that contains the geofenceId as well as an archiveComment.
 * @returns
 */
export const requestGeofenceToggleArchive = data => ({
    type: REQUEST_GEOFENCE_TOGGLE_ARCHIVE,
    data,
});

/**
 * Sets the state.geofence with data received from {@link requestGeofenceToggleArchive}
 * @param {Object} data Contains the updated geofence object which is either archived or reactivated.
 * @returns 
 */
export const receiveGeofenceToggleArchive = data => ({
    type: RECEIVE_GEOFENCE_TOGGLE_ARCHIVE,
    data,
});

/**
 * Send a request to backend to toggle the archived date of a geofenceAnalysis via sagaGeofences.js.
 * 
 * @param {Object} data Object that contains the geofenceAnalyisId.
 * @returns
 */
export const requestGeofenceAnalysisToggleArchive = data => ({
    type: REQUEST_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE,
    data,
});

/**
 * Sets the state.geofenceAnalysis with data received from {@link requestGeofenceAnalysisToggleArchive}
 * @param {Object} data Contains the updated geofenceAnalysis object which is either archived or reactivated.
 * @returns 
 */
export const receiveGeofenceAnalysisToggleArchive = data => ({
    type: RECEIVE_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE,
    data,
});