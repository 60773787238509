import React, {useState} from "react";
import Page from "../common/container/Page";
import {menu} from "../../managers/pathManager";
import {
    Card,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    makeStyles, Tooltip, IconButton
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import BoxFront from "../../images/boxFront.png";
import BoxBattery from "../../images/boxBattery.png";
import OrderInformation from "./OrderInformation";
import Button from "@material-ui/core/Button";
import {requestOrder} from "../../actions/actionOrder";
import {useDispatch, useSelector} from "react-redux";
import {setError} from "../../actions/actionCommon";
import FontAwesomeIcon from "../common/views/FontAwesomeIcon";
import ErrorToolbar from "../common/toolbar/ErrorToolbar";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useModalStatus} from "../../hooks/useModalStatus";
import OrderAGBModal from "./OrderAGBModal";
import Toolbar from "../common/toolbar/Toolbar";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {addDays, differenceInDays} from "date-fns";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        maxWidth: 900,
        margin: "auto"
    },
    container: {
        maxWidth: 900,
        margin: "auto",
        marginTop: 5,
    },
    headline: {
        textAlign: "center",
        marginBottom: "10px"
    },
    summaryCard: {
        marginTop: "5px",
        marginBottom: "5px",
        padding: "10px"
    },
    orderBtn: {
        marginLeft: "auto"
    },
    helpIcon: {
        marginTop: "-20px"
    },
    paymentSection: {
        marginLeft: "150px",
        marginTop: "20px",
        marginBottom: "20px"
    },
    agbSection: {
        marginLeft: "150px",
        marginTop: "20px",
        marginBottom: "20px"
    },
    hrStyle: {
        display: "block; height: 1px",
        border: "0",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        margin: "1em 0",
        padding: "0"

    }
}));


const OrderPage = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const me = useSelector(state => state.users.me);

    const {modal: agbModal, open: openAgbModal, close: closeAgbModal} = useModalStatus();

    const [driveboxCount, setDriveboxCount] = useState(0);
    const [driveboxBMCount, setDriveboxBMCount] = useState(0);
    const [obdCableCount, setObdCableCount] = useState(0);
    const [agbChecked, setAgbChecked] = useState(false);
    const [dsvChecked, setDsvChecked] = useState(false);

    const [price, setPrice] = useState(11);

    const [finished, setFinished] = useState(false);
    const {testerSince, testerExpiresInDays} = me;
    const testEndDate = addDays(new Date(testerSince), testerExpiresInDays);
    const dayLeft = differenceInDays(testEndDate, new Date());

    const getPackage = () => {
        switch (price) {
            case 11:
                return "Yearly";
            case 12:
                return "HalfYearly";
            case 14:
                return "Monthly";
        }
    };

    const handleOrder = () => {
        if (!agbChecked) {
            dispatch(setError({message: "Sie müssen die AGB akzeptieren"}));
            return;
        }

        if (!dsvChecked) {
            dispatch(setError({message: "Sie müssen die Datenschutzverordnung akzeptieren"}));
            return;
        }

        if (driveboxCount < 0) {
            dispatch(setError({message: "Sie können nicht weniger als 0 Boxen bestellen"}));
            return;
        }

        if (driveboxBMCount < 0) {
            dispatch(setError({message: "Sie können nicht weniger als 0 Boxen bestellen"}));
            return;
        }

        if (obdCableCount < 0) {
            dispatch(setError({message: "Sie können nicht weniger als 0 Kabel bestellen"}));
            return;
        }

        if (driveboxCount === 0 && driveboxBMCount === 0 && obdCableCount === 0) {
            dispatch(setError({message: "Sie müssen etwas bestellen, um die Bestellfunktion zu nutzen"}));
            return;
        }

        dispatch(requestOrder({
            paymentInterval: getPackage(),
            amountOfObd2: driveboxCount,
            amountOfBm: driveboxBMCount,
            amountOfCable: obdCableCount
        }));
        setFinished(true);
    }

    const toolbar = <div className={classes.toolbar}><Toolbar title="Bestellformular"/></div>


    return <Page disableDrawer={dayLeft <= 0} activePage={menu.order.key} toolbar={toolbar} disableErrorToolbar
                 disableTesterToolbar>
        {finished && <div className={classes.container}>
            <h1>Vielen Dank für Ihre Bestellung!</h1>
            <h4>Sie sollten in Kürze ein Bestätigungsmail zu Ihrer Bestellung erhalten.</h4>
            <h4>Falls Sie Fragen oder Anregungen haben zögern Sie bitte nicht und kontaktieren Sie unseren Support:</h4>
            <h4>support@drivebox.at</h4>


            <h1>Zusammenfassung Ihrer Bestellung</h1>
            <ul>
                <li>{driveboxCount} Boxe(n), die direkt in die OBD2 Schnittstelle angesteckt werden kann</li>
                <li>{obdCableCount} Verlängerunskabel</li>
                <li>{driveboxBMCount} Boxe(n), die an die Batterie angeseteckt werden können</li>
            </ul>

        </div>}

        {!finished && <div className={classes.container}>

            <Table size={"small"}>
                <TableBody>
                    <TableRow>
                        <TableCell align="right">
                            <TextField
                                InputLabelProps={{shrink: true}}
                                className={classes.inputField}
                                label="Stückanzahl"
                                value={driveboxCount}
                                onChange={e => setDriveboxCount(Math.round(e.target.value))}
                                helperText={"Bestelle zwischen 0 und 100 Boxen"}
                                error={driveboxCount < 0 || driveboxCount > 100}
                                type="number"/>
                        </TableCell>
                        <TableCell>
                            <OrderInformation picture={BoxFront} title="DRiVEBOX UltraSlim OBD2" subTitle="(Diagnosestecker)"/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">
                            <TextField
                                onChange={e => setObdCableCount(Math.round(e.target.value))}
                                value={obdCableCount}
                                InputLabelProps={{shrink: true}}
                                className={classes.inputField}
                                label="Stückanzahl"
                                helperText={"Bestelle zwischen 0 und 100 Kabel"}
                                error={obdCableCount < 0 || obdCableCount > 100}
                                type="number"/>
                        </TableCell>
                        <TableCell>
                            <OrderInformation title={<>
                                OBD2 Verlängerungskabel
                                <Tooltip
                                    className={classes.helpIcon}
                                    title="In Ausnahmefällen ist der Diagnosestecker (OBD-2) nur schwer zugänglich oder die Anbringung der DRiVEBOX stört beim Fahren. In diesem Fall benötigen Sie dieses Verlängerungskabel.">
                                    <IconButton aria-label="help" size="small">
                                        <FontAwesomeIcon fontSize={17}>far fa-question-circle</FontAwesomeIcon>
                                    </IconButton>
                                </Tooltip>

                            </>} subTitle="Einmalig 15,90€"/>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="right">
                            <TextField
                                onChange={e => setDriveboxBMCount(Math.round(e.target.value))}
                                value={driveboxBMCount}
                                InputLabelProps={{shrink: true}}
                                className={classes.inputField}
                                label="Stückanzahl"
                                helperText={"Bestelle zwischen 0 und 100 Boxen"}
                                error={driveboxBMCount < 0 || driveboxBMCount > 100}
                                type="number"/>
                        </TableCell>
                        <TableCell>
                            <OrderInformation picture={BoxBattery}
                                              title="DRiVEBOX BM"
                                              subTitle="(Primär für Elektroautos)"/>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>

            <Grid className={classes.paymentSection} container spacing={2}>
                <Grid item xs={12}>
                    <RadioGroup name="price" value={price} onChange={(e) => {
                        setPrice(Number(e.target.value));
                    }}>
                        <FormControlLabel value={11} control={<Radio/>}
                                          label="11€ / DRiVEBOX - Bei jährlicher Zahlung "/>
                        <FormControlLabel value={12} control={<Radio/>}
                                          label="12€ / DRiVEBOX - Bei halbjährlicher Zahlung "/>
                        <FormControlLabel value={14} control={<Radio/>}
                                          label="14€ / DRiVEBOX - Bei monatlicher Zahlung "/>
                    </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Versandkosten pauschal 10€

                    </Typography>
                </Grid>
            </Grid>

            <hr className={classes.hrStyle}/>

            <FormGroup className={classes.agbSection}>
                <FormControlLabel
                    control={<Checkbox checked={agbChecked} onChange={e => setAgbChecked(e.target.checked)}/>}
                    label={<>Ich akzeptiere die <a onClick={() => {
                        openAgbModal();
                        setAgbChecked(!agbChecked); //sadly a click event is fired by opening the modal --> i have to undo the state.. this is a quick fix not a solution!
                    }}>AGB</a></>}
                />
                <FormControlLabel
                    control={<Checkbox checked={dsvChecked} onChange={e => setDsvChecked(e.target.checked)}/>}
                    label={<>Ich akzeptiere die <a target="_blank"
                                                   href={"https://www.drivebox.at/datenschutz/"}> Datenschutzverordnung</a></>}
                />
            </FormGroup>

            <Card className={classes.summaryCard}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className={classes.headline} variant="h5" component="h2">
                            Einmalige Kosten: {(obdCableCount < 0 ? 0 : obdCableCount * 15.90 + 10).toFixed(2)} €
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.headline} variant="h5" component="h2">
                            Monatliche
                            Kosten: {(price * (driveboxCount < 0 ? 0 : driveboxCount) + price * (driveboxBMCount < 0 ? 0 : driveboxBMCount)).toFixed(2)} €
                        </Typography>
                    </Grid>
                </Grid>
                <ErrorToolbar/>
                <Button onClick={handleOrder} fullWidth
                        className={classes.orderBtn} color={"primary"} variant={"contained"}>
                    Jetzt bestellen
                </Button>
            </Card>

        </div>}
        {agbModal && <OrderAGBModal onClose={closeAgbModal}/>}
    </Page>
};


export default OrderPage;