import React from "react";
import { Snackbar } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CustomSnackbar = ({ translateKey, open, onClose }) => {
    const { t } = useTranslation();

    return <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                     autoHideDuration={3000}
                     open={open}
                     message={t(translateKey)}
                     onClose={onClose}/>;

};

export default CustomSnackbar;
