import FormInput from "../../../common/inputs/FormInput";
import VehicleSelect from "../../../common/selects/specificSelects/VehicleSelect";

import { getRequiredRule } from "../ReservationAddModal.utils";
import { useTranslation } from "react-i18next";

const ReservationAddModalStepTwo = ({
  vehicleId,
  handleInputChange,
}) => {

  const { t } = useTranslation();

  return (
    <FormInput
      name="vehicleId"
      label={
        t("reservation.addReservationModal.pageTwo.selectVehicle")
      }
      rules={[
        getRequiredRule(
          t("reservation.addReservationModal.pageTwo.selectVehicleValidation")
        ),
      ]}
    >
      <VehicleSelect
        onChange={(value) =>
          handleInputChange("vehicleId", value)
        }
        vehicleId={vehicleId}
        reservationModal={true}
      />
    </FormInput>
  );
};

export default ReservationAddModalStepTwo;
