import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Page from "../common/container/Page";
import Toolbar from "../common/toolbar/Toolbar";
import FilterToolbar from "../common/toolbar/FilterToolbar";

import LiveLocationMap from "./LiveLocationMap";
import FilterModal from "./liveLocationModals/LiveLocationFilterModal";
import IntroductionModal from "./liveLocationModals/LiveLocationIntroductionModal";
import SettingModal from "./liveLocationModals/LiveLocationSettingModal";

import { introductionLiveLocationKey, settingKeys, watched } from "../../managers/localStorageManager";
import { menu } from "../../managers/pathManager";
import { setText, setTextArray, updateFilterAPICall } from "../../actions/actionFilter";
import { loadLiveLocation } from "../../actions/actionLiveLocation";

import { useModalStatus } from "../../hooks/useModalStatus";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import "../../styling/liveLocation.css";
import { vehicleProfileArray } from "../../models/VehicleProfile";

const LiveLocationPage = props => {
    const { value: settings, set: setSettings } = useLocalStorage(settingKeys.liveLocation, {
        showStandingVehicles: true,
        tooltipText: "name",
        showSpeed: false,
    });
    const { value: watchedLiveLocation, set: setWatchedLiveLocation } = useLocalStorage(introductionLiveLocationKey);
    const { modal: filterModal, open: openFilterModal, close: closeFilterModal } = useModalStatus();
    const { modal: settingModal, open: openSettingModal, close: closeSettingModal } = useModalStatus();
    const { modal: introductionModal, close: closeIntroductionModal } = useModalStatus();
    const { filter, storedVehicles } = props;
    const { api, initial, updatedAt } = filter;

    useEffect(() => {
        const { groups, vehicleProfiles, vehicles } = initial;
        const filterParams = [];
        const filterVehicles = typeof vehicles === "undefined" ||
            vehicles.map(vId => storedVehicles.find(sv => sv.id === vId).name);
        const filterVehicleProfiles = typeof vehicleProfiles === "undefined" ||
            vehicleProfiles.map(vpId => vehicleProfileArray.find(vp => vp.id === vpId).name);

        if (typeof groups !== "undefined" && groups.length > 0)
            filterParams.push("Gruppen: " + groups.map(g => g.label).join(", "));

        if (typeof vehicleProfiles !== "undefined" && vehicleProfiles.length > 0)
            filterParams.push("VehicleProfile: " + filterVehicleProfiles.join(", "));

        if (typeof vehicles !== "undefined" && vehicles.length > 0)
            filterParams.push("Fahrzeuge: " + filterVehicles.join(", "));

        props.setTextArray(filterParams);
    }, [updatedAt]);

    useEffect(() => {
        props.loadLiveLocation();
    }, [updatedAt]);


    function handleSearch(searchTerm) {
        props.updateFilterAPICall({ searchTerm }, false);
    }

    const toolbar = <Toolbar onFilter={openFilterModal} onSetting={openSettingModal} onSearch={handleSearch}/>;

    return (
        <Page activePage={menu.livelocation.key} toolbar={toolbar}>
            <FilterToolbar/>
            <LiveLocationMap settings={settings}/>

            {introductionModal && watchedLiveLocation !== watched && (
                <IntroductionModal
                    onClose={() => {
                        closeIntroductionModal();
                        setWatchedLiveLocation(watched);
                    }}
                />
            )}
            {filterModal && <FilterModal onClose={closeFilterModal}/>}
            {settingModal && (
                <SettingModal
                    settings={settings}
                    onClose={closeSettingModal}
                    onSave={settings => {
                        setSettings(settings);
                        closeSettingModal();
                    }}
                />
            )}
        </Page>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setText, setTextArray, updateFilterAPICall, loadLiveLocation }, dispatch);
}

function mapStateToProp(state) {
    return {
        tree: state.tree, filter: state.filter,
        storedVehicles: state.vehicles.entities,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(LiveLocationPage);
