import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableTypes from "../../models/TableTypes";
import { usePagination } from "../../hooks/usePagination";
import { requestUnrealisticTrips } from "../../actions/faultyTrips/actionUnrealisticTrips";
import GenericTable from "../common/table/GenericTable";
import { PAGE_NAME } from "../common/container/Page";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";

const UnrealisticTripsTable = () => {
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const unrealisticTrips = useSelector((state: RootState) => state.unrealisticTrips.entities);
  const total = useSelector((state: RootState) => state.unrealisticTrips.total);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestUnrealisticTrips({ page: page + 1, perPage: rowsPerPage }));
  }, [page, rowsPerPage]);

  const { t } = useTranslation();

  const columnInformations = [
    {
      title: t("trip.table.driver") ,
      key: "mappedDriver",
    },
    {
      title: t("trip.table.vehicle") ,
      key: "mappedVehicle",
    },
    {
      title: t("trip.table.date") ,
      key: "mappedDate",
      type: TableTypes.date,
    },
    {
      title: t("trip.table.startAt") ,
      key: "startedAt",
      type: TableTypes.time,
    },
    {
      title: t("trip.table.finishAt") ,
      key: "finishedAt",
      type: TableTypes.time,
    },
    {
      title: t("trip.table.duration") ,
      key: "mappedDuration",
      noBr: true,
    },
    {
      title: t("trip.table.startAddress") ,
      key: "mappedStartAddress",
    },
    {
      title: t("trip.table.destinationAddress") ,
      key: "mappedDestinationAddress",
    },
    {
      title: t("trip.table.mileage") ,
      key: "mappedMileage",
      noBr: true,
    },
    {
      title: t("trip.table.tripType") ,
      key: "mappedTripType",
    } 
  ];

  return (
    <GenericTable
      data={unrealisticTrips}
      pageName={PAGE_NAME.faultyTrips}
      pageNavigation
      rowCount={total}
      page={page}
      rowsPerPage={rowsPerPage}
      columnInformations={columnInformations}
      handleChangePage={changePage}
      handleChangeRowsPerPage={changeRowPerPage}
    />
  );
};

export default UnrealisticTripsTable;
