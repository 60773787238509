import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Input, Result } from "antd";
import ErrorToolbar from "../toolbar/ErrorToolbar";
import { withTranslation } from "react-i18next";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = {
    container: { margin: "25px 5px 5px 5px" },
    strikethrough: {
        textDecoration: "line-through",
        color: "green",
    },
};

class ChangePasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
        };
    }

    handleOldPasswordChange = (event) => {
        this.setState({ oldPassword: event.target.value });
    };

    handleNewPasswordChange = (event) => {
        this.setState({ newPassword: event.target.value });
    };

    handleNewPasswordChangeRepeate = (event) => {
        this.setState({ newPasswordRepeat: event.target.value });
    };

    isValidPassword() {
        return this.isValidPassword6Chars() && this.isValidNewPasswordRepeat();
    }

    isValidPassword6Chars() {
        const { newPassword } = this.state;
        return newPassword.length >= 6;
    }

    isValidNewPasswordRepeat() {
        const { newPassword, newPasswordRepeat } = this.state;
        return newPasswordRepeat && newPassword === newPasswordRepeat;
    }

    handleConfirmPassword = () => {
        const { onConfirm, onClose } = this.props;
        const { newPassword, oldPassword } = this.state;
        onConfirm(oldPassword, newPassword);
        if (onClose) onClose();
    };

    renderCancelButton() {
        const { onClose, t } = this.props;
        if (onClose) {
            return (
                <Button onClick={onClose} fullWidth>
                    {t("common.modal.generic.cancel")}
                </Button>
            );
        }
    }

    render() {
        const { classes, fullScreen, open, onClose, oldPasswordNeeded, yourPasswordNeeded, t } = this.props;
        const { oldPassword, newPassword, newPasswordRepeat } = this.state;
        return (
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                disableBackdropClick
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <ErrorToolbar />
                <DialogContent className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography align="center" variant="h5" gutterBottom color="textSecondary">
                                {t("common.modal.passwordModal.changePassword")}
                            </Typography>
                            <Result
                                style={{ padding: "0" }}
                                icon={
                                    this.isValidPassword() ? (
                                        <LockOutlined style={{ color: "#CECECE" }} />
                                    ) : (
                                        <UnlockOutlined style={{ color: "#CECECE" }} />
                                    )
                                }
                                subTitle={t("common.modal.passwordModal.validation")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {oldPasswordNeeded && (
                                <Input.Password
                                    placeholder={t("common.modal.passwordModal.oldPassword")}
                                    autocomplete="new-password"
                                    type="password"
                                    value={oldPassword}
                                    onChange={this.handleOldPasswordChange}
                                    style={{ marginBottom: "4px" }}
                                />
                            )}

                            {yourPasswordNeeded && (
                                <Input.Password
                                    placeholder={t("common.modal.passwordModal.adminPassword")}
                                    autocomplete="new-password"
                                    type="password"
                                    value={oldPassword}
                                    onChange={this.handleOldPasswordChange}
                                    style={{ marginBottom: "4px" }}
                                />
                            )}
                            <Input.Password
                                placeholder={t("common.modal.passwordModal.newPassword")}
                                autocomplete="new-password"
                                type="password"
                                value={newPassword}
                                onChange={this.handleNewPasswordChange}
                                style={{ marginBottom: "4px" }}
                            />
                            <Input.Password
                                placeholder={t("common.modal.passwordModal.repeatPasswort")}
                                autocomplete="new-password"
                                type="password"
                                value={newPasswordRepeat}
                                onChange={this.handleNewPasswordChangeRepeate}
                                style={{ marginBottom: "4px" }}
                            />
                            <Button
                                disabled={!this.isValidPassword() || newPassword !== newPasswordRepeat}
                                color="primary"
                                type="password"
                                variant="contained"
                                fullWidth
                                onClick={this.handleConfirmPassword}
                            >
                                {t("common.modal.generic.confirm")}
                            </Button>
                            {this.renderCancelButton()}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

ChangePasswordModal.defaultProps = { confirmButton: "Confirm", cancelButton: "cancel", open: true };

export default withMobileDialog()(
    withStyles(styles, { withTheme: true })(
        withTranslation()(ChangePasswordModal)
    )
);
