import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestGeofencesNameId } from "../../../../actions/actionGeofences";
import React from "react";
import MultiSelect from "../MultiSelect";
import { requestAllUsers } from "../../../../actions/actionUser";
import { useTranslation } from "react-i18next";

const DriverSelect = (props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { value, onChange } = props;
  const driversEntities = useSelector((state) => state.users.allEntities);
  const drivers = driversEntities.map((user) => {
    return {
      id: user.id,
      name: user.firstName + " " + user.lastName,
    };
  }); 
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setSearchTerm("");
  }, [value]);

  useEffect(() => {
    getDriversWhenSearchTermChanges();
  }, [searchTerm]);

  function getDriversWhenSearchTermChanges() {
    dispatch(requestAllUsers({ searchTerm }));
  }

  return (
    <MultiSelect
      placeholder={t("common.select.drivers")}
      selectedArray={value}
      array={drivers}
      handleChange={onChange}
      handleSearch={setSearchTerm}
    />
  );
};

export default DriverSelect;
