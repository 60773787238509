import React from "react";
import {connect, useSelector} from "react-redux";
import GenericTable from "../common/table/GenericTable";
import TableTypes from "../../models/TableTypes";
import Comparator from "../../models/Comparator";
import {allowedRoles, isAuthorized, isViewer} from "../../managers/authManager";
import {roles} from "../../models/Role";
import {packages} from "../../models/Package";
import { findEngineTypeById } from "../../models/EngineType";
import { useTranslation } from "react-i18next";

const VehicleTable = props => {

    const {
        vehicles,
        total,
        selected,
        page,
        rowsPerPage,
        onView,
        onEdit,
        onSelect,
        onSelectAll,
        handleChangePage,
        handleChangeRowsPerPage,
        onHistory,
        onCalibrate,
        onArchive,
        onUnArchive,
        me,
        onGenerateQrCode
    } = props;

    const viewer = isViewer(me);
    const { t } = useTranslation();

    const columnInformations = [
        {title: t("vehicle.table.name"), key: "name"},
        {title: t("vehicle.table.licensePlate"), key: "licensePlate"},
        {title: t("vehicle.table.model"), key: "model"},
        {title: t("vehicle.table.description"), key: "description"},
        {title: t("vehicle.table.mileage"), key: "mappedMileage"},
        {title: t("vehicle.table.type"), key: "mappedType"},
        {title: t("vehicle.table.groups"), key: "groups", type: TableTypes.group},
        {title: t("vehicle.table.drivebox"), key: "drivebox.name"},
    ];

    if (isAuthorized(me, allowedRoles(roles.Driver), [packages.CostCenter])) 
        columnInformations.push( {title: t("vehicle.table.costCenter"), key: "costcenterKey"});

    if(isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor])){
        columnInformations.splice(7, 0, 
            {title: t("vehicle.table.engineType"), key: "engineType", type: TableTypes.engineType},
            {title: t("vehicle.table.propulsionStatus"), key: "fuelStatus"},
        )}; 

    const actions = [
        {title: t("vehicle.table.view"), event: onView},
        {title: t("vehicle.table.edit"), event: onEdit},
        {title: t("vehicle.table.history"), event: onHistory}
    ];

    if (me.canCalibrate)
        actions.push({title: t("vehicle.table.calibration"), event: onCalibrate});

    actions.push({
        title: t("vehicle.table.archive"),
        event: onArchive,
        comparator: new Comparator(["archive"], (archive) => !archive),
    });

    actions.push(
        {
            title: t("vehicle.table.deArchive"),
            event: onUnArchive,
            comparator: new Comparator(["archive"], (archive) => archive),
        }
    );

    if (isAuthorized(me, allowedRoles(roles.Admin), [packages.DriverIdentification])) {
        actions.push({title: t("driverIdentification.generateQrCode"), event: onGenerateQrCode }
        );
    }
    
    const viewerActions = [
        {title: "History", event: onHistory},
    ];

    const mappedVehicles = vehicles.map(v => {
        const {archivedAt, type, fuelOrBatteryStateAbsolut} = v;
        const engineUnit = findEngineTypeById(v.engineType)?.name === "Fuel" ? "L" : "kWh";
        v.archive = Boolean(archivedAt);
        v.mappedType = type === 0 ? "Company" : type === 1 ? "Private" : type === 2 ? "Pool" : "";
        v.mappedMileage = v.mileageInMeters ? Math.round(v.mileageInMeters / 10) / 100 + " km" : "";
        if (fuelOrBatteryStateAbsolut) {
            v.fuelStatus = (v?.tankSize - v?.fuelOrBatteryStateAbsolut) + engineUnit;
        } else {
            v.fuelStatus = "Keine Angabe";
        }
        return v;
    });

    return (
        <GenericTable
            data={mappedVehicles}
            columnInformations={columnInformations}
            actions={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? viewerActions : actions}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            onEdit={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? () => console.log("") : onView}
            onRowClick={viewer || !isAuthorized(me, allowedRoles(me.AdminViewer)) ? () => console.log("") : onEdit}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onSelectAllClick={onSelectAll}
            onSelect={onSelect}
            numSelected={selected}
            showActions
            showCheckboxes={!viewer}
        />
    );
};

VehicleTable.defaultProps = {
    page: 0,
};


function mapStateToProp(state) {
    return {
        vehicles: state.vehicles.entities,
        costcenters: state.costcenters.entities,
        selected: state.vehicles.selected,
        total: state.vehicles.total,
        me: state.users.me,
    };
}

export default connect(mapStateToProp)(VehicleTable);
