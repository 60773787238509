import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import DriveboxSelect from "../../common/selects/specificSelects/DriveboxSelect";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import Label from "../../common/views/Label";

import FormModal from "../../common/modals/FormModal";
import VehicleProfileSelect from "../../common/selects/specificSelects/VehicleProfileSelect";

import {
  loadVehicle,
  requestUpdateVehicle
} from "../../../actions/actionVehicle";
import { requestDriveboxes } from "../../../actions/actionDriveboxes";
import { requestCostcenters } from "../../../actions/actionCostcenter";

import {
  getGroupValuesFromIds,
  getIdsFromGroupValues,
} from "../../../managers/groupManager";

import { format } from "date-fns";
import { Input, Switch, Select, Slider } from "antd";
import FormBuilder from "../../../managers/formBuilder";
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  listItem: {
    marginTop: "-21px",
    marginLeft: "-15px",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center"
  },
  inputText: {
    fontSize: 14,
    marginLeft: 13,
    marginBottom: 0
  },
  thresholdLowBox: {
    fontSize: 14,
    marginBottom: 0
  },
  thresholdBox: {
    border: "1px solid #D9D9D9 ",
    borderRadius: 3,
    padding: 3,
    marginRight: 5,
  },
  square: {
    width: 22,
    height: 22,
    border: "1px solid #D9D9D9 ",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    marginLeft: 2
  }
});

const getDefaultInputValue = (vehicle) => ({
  name: vehicle ? vehicle.name : null,
  licensePlate: vehicle ? vehicle.licensePlate : null,
  model: vehicle ? vehicle.model : null,
  engineType: vehicle ? vehicle.engineType : null,
  tankSize: vehicle ? vehicle.tankSize : null,
  consumptionThreshold: vehicle && vehicle.consumptionThresholdLow || vehicle.consumptionThresholdHigh ? [vehicle.consumptionThresholdLow, vehicle.consumptionThresholdHigh] : [10, 25],
  description: vehicle ? vehicle.description : null,
  type: vehicle ? vehicle.type : null,
  groupValue: vehicle ? vehicle.groups : null,
  ownerId: vehicle ? vehicle.owner?.id : null,
  costcenterId: vehicle ? vehicle.costcenterId : null,
  mileageInKm: vehicle ? Math.round(vehicle.mileageInMeters / 1000) : null,
  driveboxId: vehicle ? vehicle.drivebox?.id : null,
  assignTripsToOwner: vehicle ? vehicle.assignTripsToOwner : false,
  isPrivateTripAllowed: vehicle ? vehicle.isPrivateTripAllowed : false,
});

const VehicleModal = (props) => {
  const classes = useStyles();
  const me = useSelector((state) => state.users.me);
  const { vehicle, tree, loading, onClose, setVehicle } = props;
  const { handleInputChange, fields, setFields } = useInputChangeHandler(
    getDefaultInputValue(vehicle)
  );
  const { driveboxUnpluggedAt, drivebox } = vehicle;
  const { t } = useTranslation();

  const {
    driveboxId,
    name,
    licensePlate,
    model,
    engineType,
    tankSize,
    consumptionThreshold,
    description,
    ownerId,
    type,
    groupValue,
    assignTripsToOwner,
    isPrivateTripAllowed,
    costcenterId,
  } = fields;

  const vehicleUnit = engineType === 0 ? "kWh" : "Litres"

  const [consumptionThresholdLow, consumptionThresholdHigh] = consumptionThreshold;

  fields.consumptionThresholdLow = consumptionThresholdLow;
  fields.consumptionThresholdHigh = consumptionThresholdHigh;

  useEffect(() => {
    if (type === 2) {
      setFields({ ...fields, assignTripsToOwner: false });
    } else {
      setFields({ ...fields, isPrivateTripAllowed: false });
    }
  }, [type]);

  useEffect(() => {
    vehicle &&
      setFields({ ...fields, type: vehicle.type, ownerId: ownerId, groupValue: vehicle?.groups?.length && getGroupValuesFromIds(tree, vehicle.groups.map((g) => g.id)) });
  }, [vehicle]);

  useEffect(() => {
    props.requestCostcenters();
  }, []);

  function handleOnSave() {
    const vehicleToSave = { ...vehicle, ...fields };

    const groupIds = getIdsFromGroupValues(tree, fields.groupValue);

    const newVehicle = {
      ...vehicleToSave,
      id: vehicle.id,
      groupIds: groupIds,
      assignedOn: "",
    };

    props.requestUpdateVehicle(newVehicle);

    setVehicle && setVehicle(newVehicle);

    onClose();
  }

  const subGroupGeneral = "subGroupGeneral";
  const subGroupVehicleProfile = "subGroupVehicleProfile";
  const subGroupDrivebox = "subGroupDrivebox";

  const collapseTranslation = {
    subGroupGeneral: t("vehicle.editmodal.general"),
    subGroupVehicleProfile: t("vehicle.editmodal.vehicleProfile"),
    subGroupDrivebox: t("vehicle.editmodal.driveBox"),
  };

  // TODO translations
  let inputs = [
    new FormBuilder.FormInput(
      t("vehicle.editmodal.name"),
      <Input value={name} onChange={(e) => handleInputChange("name", e.target.value)} />
    )
      .withValue(name)
      .withKey("name")
      .withValidationMessage(
        t("vehicle.editmodal.nameValidation")
      )
      .isRequired()
      .withSubGroup(subGroupGeneral)
      .build(),
    new FormBuilder.FormInput(
      t("vehicle.editmodal.licensePlate"),
      <Input value={licensePlate} onChange={(e) => handleInputChange("licensePlate", e.target.value)} />
    )
      .withValue(licensePlate)
      .withKey("licensePlate")
      .withValidationMessage(
        t("vehicle.editmodal.licensePlateValidation")
      )
      .isRequired()
      .withSubGroup(subGroupGeneral)
      .build(),
    new FormBuilder.FormInput(
      t("vehicle.editmodal.model"),
      <Input value={model} onChange={(e) => handleInputChange("model", e.target.value)} />
    )
      .withValue(model)
      .withKey("model")
      .withValidationMessage(
        t("vehicle.editmodal.modelValidation")
      )
      .isRequired()
      .withSubGroup(subGroupGeneral)
      .build(),

    new FormBuilder.FormInput(
      t("vehicle.editmodal.description"),
      <Input.TextArea autosize={{ minRows: 3, maxRows: 5 }} value={description} onChange={(e) => handleInputChange("description", e.target.value)} />
    )
      .withValue(description)
      .withKey("description")
      .withSubGroup(subGroupGeneral)
      .build(),
    new FormBuilder.FormInput(
      t("vehicle.editmodal.vehicleProfile"),
      <VehicleProfileSelect value={type} onChange={(value) => handleInputChange("type", value)} allowClear={false} />
    )
      .withValue(type)
      .withKey("type")
      .withValidationMessage(
        t("vehicle.editmodal.vehicleProfileValidation")
      )
      .isRequired()
      .withSubGroup(subGroupVehicleProfile)
      .build(),
      
    new FormBuilder.FormInput(
      t("vehicle.editmodal.owner"),
          <UserSelect
            onChange={(value) => handleInputChange("ownerId", value)}
            value={ownerId}
            allowClear={type == 2}
          />
    )
      .withValue(ownerId)
      .withKey("ownerId")
      .withValidationMessage(
        t("vehicle.editmodal.ownerValidation")
      )
      .isRequired(type == 0 || type == 1)
      .withSubGroup(subGroupVehicleProfile)
      .build(),
    new FormBuilder.FormInput(
      t("vehicle.editmodal.group"),
      <TreeSelect value={groupValue === 0 ? undefined : groupValue} onChange={(value) => handleInputChange("groupValue", value)} />
    )
      .withValue(groupValue === 0 ? undefined : groupValue)
      .withKey("groupValue")
      .withSubGroup(subGroupVehicleProfile)
      .build(),
  ];

  if (isAuthorized(me, allowedRoles(roles.Admin), [packages.CostCenter]))
    inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.editmodal.costCenter"),
        <CostCenterSelect value={costcenterId} onChange={(value) => handleInputChange("costcenterId", value)} allowClear={true} showArchived={false} />
      )
        //.withValue(costcenters ? costcenters.id : "")
        .withValue(costcenterId)
        .withKey("costcenterId")
        .withSubGroup(subGroupVehicleProfile)
        .build()
    );

  inputs.push(
    new FormBuilder.FormInput(
      t("vehicle.editmodal.assignToOwner"),
      <Switch checked={assignTripsToOwner} onChange={(value) => handleInputChange("assignTripsToOwner", value)} disabled={!ownerId} />
    )
      .withValue(assignTripsToOwner)
      .withKey("assignTripsToOwner")
      .withValuePropName("checked")
      .withSubGroup(subGroupVehicleProfile)
      .build(),
    new FormBuilder.FormInput(
      t("vehicle.editmodal.allowPrivateTrips"),
      <Switch checked={isPrivateTripAllowed} onChange={(value) => handleInputChange("isPrivateTripAllowed", value)} />
    )
      .withValue(isPrivateTripAllowed)
      .withKey("isPrivateTripAllowed")
      .withValuePropName("checked")
      .withSubGroup(subGroupVehicleProfile)
      .hide(type == 2)
      .build()
  );

  if (isAuthorized(me, allowedRoles(roles.AdminViewer)))
    inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.editmodal.driveBox"),
        <DriveboxSelect value={driveboxId} onChange={(value) => handleInputChange("driveboxId", value)} showGroups allowClear />
      )
        .withValue(driveboxId)
        .withKey("driveboxId")
        .withSubGroup(subGroupDrivebox)
        .build()
    );

  inputs.push(
    new FormBuilder.FormInput(
      t("vehicle.editmodal.assignedOn"),
      <Label value={drivebox && drivebox.since ? (
        format(new Date(drivebox?.since), "dd.MM.yy H:mm")
      ) : (
        t("vehicle.editmodal.notAssigned")
      )} />
    )
      .withValue(
        drivebox?.since ? (
          format(new Date(drivebox.since), "dd.MM.yy H:mm")
        ) : (
          t("vehicle.editmodal.notAssigned")
        )
      )
      .withKey("assignedOn")
      .withSubGroup(subGroupDrivebox)
      .build()
  );

  if (driveboxUnpluggedAt && driveboxUnpluggedAt.length > 0)
    inputs.push(
      new FormBuilder.FormInput(
        t("vehicle.editmodal.unpluggedList"),
        (
          <List>
            {driveboxUnpluggedAt.map((ue) => (
              <ListItem className={classes.listItem}>
                {" "}
                <ListItemText
                  primary={format(new Date(ue), "dd.MM.yy H:mm")}
                />{" "}
              </ListItem>
            ))}
          </List>
        )
      )
        .withKey("unpluggedEvents")
        .withSubGroup(subGroupDrivebox)
        .build()
    );

  if (isAuthorized(me, allowedRoles(roles.Admin), [packages.FuelMonitor])) {
    inputs.splice(3, 0,
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page1.driveType"), (<Select value={engineType} onChange={(e) => handleInputChange("engineType", e)} options={[{ value: null, label: 'Not specified' }, { value: 0, label: 'Electric Car' }, { value: 1, label: 'Fuel' }]} />))
        .withValue(engineType)
        .withKey("engineType")
        // .withValidationMessage(<Translate id="vehicle.addmodal.page1.driveTypeValidation" />)
        // .isRequired()
        .withSubGroup(subGroupGeneral)
        .build(),
    )
  }

  if (engineType === 0 || engineType === 1) {
    inputs.splice(4, 0,
      new FormBuilder.FormInput(
        t("vehicle.addmodal.page1.tankSize"), (<div className={classes.inputWrapper} ><Input value={tankSize} onChange={(e) => handleInputChange("tankSize", e.target.value)} /><p className={classes.inputText} >{vehicleUnit}</p></div>))
        .withValue(tankSize)
        .withKey("tankSize")
        .withValidationMessage(t("vehicle.addmodal.page1.tankSizeValidation"))
        .isRequired()
        .withSubGroup(subGroupGeneral)
        .build(),

      new FormBuilder.FormInput(t("vehicle.addmodal.page1.threshold"), (<div className={classes.inputWrapper}><p className={classes.thresholdLowBox}><span className={classes.square}>{consumptionThresholdLow}</span></p><Slider range value={consumptionThreshold} max={40} onChange={(e) => handleInputChange("consumptionThreshold", e)} style={{ flex: 1 }} /><p className={classes.inputText}><span className={classes.thresholdBox}>{consumptionThresholdHigh}</span>{vehicleUnit}/100km</p></div>))
        .withValue(consumptionThreshold)
        .withKey("consumptionThreshold")
        .withValidationMessage(t("vehicle.addmodal.page1.thresholdValidation"))
        .isRequired()
        .withSubGroup(subGroupGeneral)
        .build(),
    )
  }

  return (
    <FormModal
      loading={loading}
      action="update"
      onClose={onClose}
      onSave={handleOnSave}
      title={t("vehicle.editmodal.title")}
      inputs={inputs}
      showSubGroups
      collapseTranslation={collapseTranslation}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestDriveboxes,
      requestUpdateVehicle,
      requestCostcenters,
      loadVehicle,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    loading: state.vehicles.loading,
    costcenters: state.vehicles.costcenters,
    driveboxes: state.driveboxes.entities,
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(VehicleModal);
