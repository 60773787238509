import { getPagedEntities } from "../../managers/reducerManager";

import { receiveMisMatchTrips } from "../../actions/faultyTrips/actionMisMatchTrips";

import { AnyAction } from "redux";

export type MisMatchTripsState = {
  readonly entities: any[];
  readonly total: number;
  readonly loading: boolean;
};

const MISMATCH_TRIPS_INITIAL_STATE: MisMatchTripsState = {
  entities: [],
  total: 0,
  loading: false,
};

export const misMatchTripsReducer = (
  state = MISMATCH_TRIPS_INITIAL_STATE,
  action: AnyAction
): MisMatchTripsState => {
  if (receiveMisMatchTrips.match(action)) {
    return getPagedEntities(state, action.data);
  }

  return state;
};
