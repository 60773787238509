import React, {useEffect} from "react";
import CustomModal from "../../common/modals/CustomModal";
import Grid from "@material-ui/core/Grid";

import PropTypes from "prop-types";
import {DateTimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import {useDispatch, useSelector} from "react-redux";
import {requestUpdateTimeRecordings} from "../../../actions/actionTimeRecording";
import {getNameOfEvent, timeLogEventType} from "../../../models/TimeLogEventType";
import TableTypes from "../../../models/TableTypes";
import {Typography} from "@material-ui/core";
import GenericTable from "../../common/table/GenericTable";
import Comparator from "../../../models/Comparator";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    informationSection: {
        marginTop: "20px",
    }
}));

const TimeEditModal = props => {
    const {onClose, value, onChange, timeLogId, timeLogEventId} = props;
    const dispatch = useDispatch();
    const classes = useStyles();


    const handleSave = data => {
        dispatch(requestUpdateTimeRecordings({
            timeLogId: timeLogId,
            id: timeLogEventId,
            occurredAt: new Date(value).toISOString()
        }));
        onClose();
    }
    const timeLog = useSelector(state => state.timeRecording.current);

    const customCurrentElementComparator = new Comparator(["id"], (id) => id === timeLogEventId);

    const mappedData = [];
    if (timeLog && timeLog.length > 0)
        timeLog.forEach(t => {
            mappedData.push({
                id: t.id,
                eventKey: t.event,
                event: getNameOfEvent(t.event),
                occurredAt: t.occurredAt
            })
        })
    const columnInformations = [
        {
            title: "Bezeichnung", key: "event",
            color: {value: "red", comparator: customCurrentElementComparator},
        },
        {
            title: "Zeit",
            key: "occurredAt",
            type: TableTypes.dateTime,
            color: {value: "red", comparator: customCurrentElementComparator},
        },
    ];

    const currentElement = mappedData.find(m => m.id === timeLogEventId);

    const startOfWork = mappedData.find(m => m.eventKey === timeLogEventType.startOfWork);
    const endOfWork = mappedData.find(m => m.eventKey === timeLogEventType.endOfWork);

    const isValid = startOfWork && endOfWork &&
        (new Date(value) > new Date(startOfWork.occurredAt) || currentElement.id == startOfWork.id) &&
        (new Date(value) < new Date(endOfWork.occurredAt) || currentElement.id == endOfWork.id);

    /*
        minDateTime={startOfWork && currentElement.id !== startOfWork.id ? new Date(startOfWork.occurredAt) : undefined}
        maxDateTime={endOfWork && currentElement.id !== endOfWork.id ? new Date(endOfWork.occurredAt) : undefined}
     */
    const content = [
        <div>
            <Grid container>
                {timeLogId && <Grid item xs={12}>
                    <Typography color={"secondary"} variant={"h6"}>
                        Neues Datum vom {currentElement.event}
                    </Typography>
                </Grid>}

                <Grid item xs={12}>
                    <DateTimePicker
                        autoOk
                        ampm={false}
                        variant="inline"
                        openTo="hours"
                        value={value} 
                        renderInput={props => <TextField style={{width: "100%"}} variant={"outlined"} {...props} />}
                        onChange={onChange}/>
                </Grid>

                {timeLogId && <Grid item xs={12} className={classes.informationSection}>
                    <Typography color={"secondary"} variant={"body1"}>
                        Alte Einträge
                    </Typography>
                </Grid>}

                {timeLogId && <Grid item xs={12}>
                    <GenericTable
                        data={mappedData}
                        columnInformations={columnInformations}
                        disableContainer
                        hover={false}
                    />
                </Grid>}
            </Grid>
        </div>
    ]

    return <CustomModal action={isValid ? "confirm" : "cancel"}
                        onSave={handleSave}
                        onClose={onClose}
                        title={"Lenkprotokoll"}
                        contents={content}/>
}

// TimeEditModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     value: PropTypes.object.isRequired,
//     onChange: PropTypes.func.isRequired,
//     timeLogEventId: PropTypes.object.isRequired,
//     timeLogId: PropTypes.object.isRequired,
// };

export default TimeEditModal;