import React, {useState, useEffect} from 'react' 
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider'
import { formatTimeStamp } from '../../../utils/dateTimeUtils';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

function HistoryTooltip(props) {
    const { historyData } = props;

    const [historyDataState, setHistoryDataState] = useState([]);

    useEffect(() => {
        if(historyData) {
            setHistoryDataState(historyData);
        }
    }, []);

    return (<div className="historyWrapper">
        <List className="historyList">
        {historyDataState.map((h, idx) => {
            return(
                <ListItem className="historyItem" key={h.Username + h.Timestamp}>
                    <ListItemIcon>
                        {idx === 0 ? <AddIcon className="hIcon"></AddIcon> : <EditIcon className="hIcon"></EditIcon>}
                    </ListItemIcon>
                    <div className="hDate">
                        {formatTimeStamp(new Date(h.Timestamp))}
                    </div>
                    <Divider variant="fullWidth" className="hDivider" />
                </ListItem>
            )
        })}
        </List>
    </div>)

}

export default HistoryTooltip;