import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, InputAdornment, Box, Grid, Switch } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import GeofenceVehicles from './GeofenceVehicles/GeofenceVehicles';
import SimpleSelectionMenu from './SimpleSelectionMenu';
import { customColors } from './StylingOptions';
import { useTranslation, withTranslation } from 'react-i18next';

/**
 * open dialog by calling setDialog:
 * 
 * setDialog({
 *      isOpen: true,                           // needed to keep rest of dialog object to prevent display bug
 *      type: 'confirm' | 'prompt' | 'select',  // select is hardcoded for road width atm, could be generalized
 *      danger: true | false                    // turns confirm button red
 *      title: [string],                        // title text of the dialog
 *      label: [string],                        // input label for prompt dialog
 *      defaultText: [string],                  // default input text for prompt dalog
 *      btnConfirm: [string],                   // text displayed in the confirm button
 *      btnCancel: [string],                    // text displayed in the cancel button
 *      confirm: () => { function },            // function called when confirm button is clicked
 *      cancel: () => { function },             // function called when cancel button is clicked
 * })
 */
function CustomStringDialog(props) {
    const { dialog, setDialog } = props;
    const [inputValue, setInputValue] = useState(dialog.defaultText || '');
    const [selectedItem, setSelectedItem] = useState(1);
    const [isAntiTheft, setIsAntiTheft] = useState(dialog.isAntiTheftEnabled);
    const [notificationEmail, setNotificationEmail] = useState(dialog.notificationEmailStr);
    const [notificationMobileNumber, setMobileNumber] = useState(dialog.notificationMobileNumberStr);
    const { t } = useTranslation();

    useEffect(() => {
        setIsAntiTheft(dialog.isAntiTheftEnabled);
    }, [dialog.isAntiTheftEnabled]);

    useEffect(() => {
        setNotificationEmail(dialog.notificationEmailStr);
    }, [dialog.notificationEmailStr]);

    useEffect(() => {
        setMobileNumber(dialog.notificationMobileNumberStr);
    }, [dialog.notificationMobileNumberStr]);

    useEffect(() => {
        setInputValue(dialog.defaultText || '');
    }, [dialog]);

    function onConfirm() {
        if (dialog?.type !== 'confirm' && !inputValue.trim()) {
            return;
        }
        let di = dialog?.confirm(inputValue.trim(), selectedItem, isAntiTheft, notificationEmail, notificationMobileNumber);
        setDialog({ ...dialog, isOpen: false });
        setInputValue('');
    }

    function onCancel() {
        let di = dialog?.cancel();
        setDialog({ ...dialog, isOpen: false });
        setInputValue('');
    }

    let widthTitle = dialog?.type === "select" ? "60%" : "100%";

    return (
        <Dialog open={dialog?.isOpen} fullWidth maxWidth={dialog?.geofenceId ? "md" : "sm"}>
            <DialogTitle>
                {dialog ? dialog.title : 'Dialog'}
            </DialogTitle>

            {dialog?.type !== 'confirm' ?
                <DialogContent dividers>
                    <TextField
                        style={{ width: widthTitle }}
                        autoFocus
                        InputProps={{
                            maxLength: 200,
                        }}
                        label={dialog ? dialog.label : ''}
                        fullWidth={dialog?.type === "select" ? false : true}
                        value={inputValue}
                        onChange={(e) => { setInputValue(e.target.value) }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && !(dialog?.type !== 'confirm' && !inputValue.trim())) {      // Enter key
                                e.preventDefault();
                                onConfirm();
                            } else if (e.keyCode === 27) {                                                      // Escape key
                                e.preventDefault();
                                onCancel();
                            }
                        }}
                    ></TextField>
                    {dialog?.type === "select" ?
                        <TextField
                            autoFocus
                            style={{ width: "37%", marginLeft: "3%" }}
                            InputProps={{
                                //maxLength: 20,
                                endAdornment: <InputAdornment position="start">meters</InputAdornment>,
                            }}
                            type="number"
                            label={props.t('dialogs.roadWidthTitle')}
                            variant="standard"
                            value={selectedItem}
                            onChange={(e) => {
                                setSelectedItem(e.target.value);

                            }}
                        ></TextField>

                        : <></>
                    }

                    {dialog?.geofenceId &&
                        <Box mt={2} mb={2}>
                            <Grid container >
                                <Grid item xs={2}>
                                    {props.t('common.modal.geofenceEditModal.liveNotificationsLabel')}
                                    <Switch
                                        checked={isAntiTheft}
                                        onChange={e => setIsAntiTheft(e.target.checked)}
                                        name="checkedB"
                                        color="primary" />
                                </Grid>
                                <Grid item xs={5}>

                                    {dialog.GeofenceVehicles}
                                    <TextField
                                        InputProps={{
                                            maxLength: 200,
                                            
                                        }}
                                        label={props.t('common.modal.geofenceEditModal.notificationEmail')}
                                        variant="standard"
                                        value={notificationEmail}
                                        onChange={(e) => {
                                            setNotificationEmail(e.target.value);

                                        }}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={5} style={{ visibility: 'hidden' }}>
                                    <TextField 
                                        InputProps={{
                                            maxLength: 200,
                                        }}
                                        label={props.t('common.modal.geofenceEditModal.notificationMobileNumber')}
                                        variant="standard"
                                        value={notificationMobileNumber}
                                        onChange={(e) => {
                                            setMobileNumber(e.target.value);

                                        }}
                                    ></TextField>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {dialog?.geofenceId &&
                        <GeofenceVehicles geofenceId={dialog.geofenceId} />
                    }
                </DialogContent> : <></>
            }

            <DialogActions>
                <Button
                    autoFocus={dialog?.type === 'confirm'}
                    onClick={() => { onConfirm() }}
                    style={{ backgroundColor: ((dialog?.type !== 'confirm' && !inputValue.trim()) ? customColors.disabled : (dialog?.danger ? customColors.danger : customColors.ilogsBlueBright)), color: customColors.bright }}
                    disabled={dialog?.type !== 'confirm' && !inputValue.trim()}
                >
                    {(dialog?.btnConfirm) || t("dialogs.buttons.confirm")}
                </Button>
                <Button onClick={() => { onCancel() }}>
                    {(dialog?.btnCancel) || t("dialogs.buttons.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(CustomStringDialog);
