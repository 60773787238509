import React, {useEffect, useState} from "react";
import Page from "../common/container/Page";
import {menu} from "../../managers/pathManager";
import DayTimeRecordingTable from "./DayTimeRecordingTable";
import MonthTimeRecordingTable from "./MonthTimeRecordingTable";

import DayTimeRecordingToolbar from "./toolbar/DayTimeRecordingToolbar";
import MonthTimeRecordingToolbar from "./toolbar/MonthTimeRecordingToolbar";
import TimeRecordingModal from "./modal/TimeRecordingModal";
import {requestDeleteTimeRecording, requestTimeRecordings} from "../../actions/actionTimeRecording";
import {useDispatch, useSelector} from "react-redux";
import {usePagination} from "../../hooks/usePagination";
import {fromToByPeriod} from "../../managers/timeManager";
import {periods} from "../../models/Period";
import {requestAllUsers} from "../../actions/actionUser";
import {useModalStatus} from "../../hooks/useModalStatus";
import TimeEditModal from "./modal/TimeEditModal";
import TimeRecordingAddModal from "./modal/TimeRecordingAddModal";
import TimeLogExportModal from "../reporting/modals/TimeLogExportModal";
import {allowedRoles, isAuthorized} from "../../managers/authManager";
import {roles} from "../../models/Role";

const pages = {
    day: 0,
    month: 1
}

const TimeRecordingPage = props => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(pages.day);
    const {page: tablePage, rowsPerPage, changePage, changeRowPerPage} = usePagination();
    const [userId, setUserId] = useState();
    const [date, setDate] = useState(fromToByPeriod(periods.day.id, new Date()));
    const users = useSelector(state => state.users.allEntities);

    const {modal: timeRecordingModal, open: openTimeRecordingModal, close: closeTimeRecordingModal} = useModalStatus();
    const {modal: timeRecordingAddModal, open: openTimeRecordingAddModal, close: closeTimeRecordingAddModal} = useModalStatus();

    const {modal: timeEditModal, open: openTimeEditModal, close: closeTimeEditModal} = useModalStatus();
    const {modal: timeExportModal, open: openTimeExportModal, close: closeTimeExportModal} = useModalStatus();


    const [currentTimeRecordingId, setCurrentTimeRecordingId] = useState();
    const [currentTimeLogEventId, setCurrentTimeLogEventId] = useState();
    const [currentTime, setCurrentTime] = useState();
    const [currentUserId, setCurrentCurrentUserId] = useState();
    const me = useSelector(state => state.users.me);

    useEffect(() => {
        if (isAuthorized(me, allowedRoles(roles.GroupLeaderViewer))) {
            dispatch(requestAllUsers({page: 1, perPage: 99999, isArchived: false}));
        }
    }, [])

    useEffect(() => {
        if (!userId && users.length > 0) {
            setUserId(users[0].id);
        }
    }, [users]);

    useEffect(() => {
        if (page === pages.day) dispatch(requestTimeRecordings({
            page: tablePage + 1,
            perPage: rowsPerPage,
            from: date.from.toISOString(),
            to: date.to.toISOString()
        }));

        if (page === pages.month && userId) {
            dispatch(requestTimeRecordings({
                page: tablePage + 1,
                perPage: 9999,
                from: date.from.toISOString(),
                to: date.to.toISOString(),
                userIds: [userId]
            }));
        }

    }, [page, tablePage, rowsPerPage, date, userId]);

    const handleDateChange = (from, to) => {
        setDate({from, to})
    }
    const handleOpenTimeRecordingModal = (data) => {
        setCurrentTimeRecordingId(data.id);
        setCurrentCurrentUserId(data.userId);
        openTimeRecordingModal()
    }


    const handleOpenTimeEditModal = (data) => {
        if (data.id) {
            setCurrentTime(data.occurredAt);
            setCurrentTimeLogEventId(data.id);
            openTimeEditModal();
        }
    }

    const handleRemoveTimeLogEvent = data => {
        if (data.id) {
            setCurrentTimeLogEventId(data.id);
            dispatch(requestDeleteTimeRecording(data.id));
        }
    }

    const toolbar = page === pages.day ?
        <DayTimeRecordingToolbar pages={pages} page={page} setPage={setPage} dateValue={date.from}
                                 onDateChange={handleDateChange} onExport={openTimeExportModal}/> :
        <MonthTimeRecordingToolbar pages={pages} page={page} setPage={setPage} dateValue={date.from}
                                   onDateChange={handleDateChange} userValue={userId} onUserChange={setUserId} onExport={openTimeExportModal}/>;

    return <Page disableErrorToolbar toolbar={toolbar} activePage={menu.timeReporting.key}>
        {page === pages.day &&
        <DayTimeRecordingTable page={tablePage}
                               handleChangePage={changePage}
                               handleChangeRowsPerPage={changeRowPerPage}
                               rowsPerPage={rowsPerPage}
                               onClick={handleOpenTimeRecordingModal}/>}

        {page === pages.month && <MonthTimeRecordingTable page={tablePage}
                                                          handleChangePage={changePage}
                                                          handleChangeRowsPerPage={changeRowPerPage}
                                                          rowsPerPage={rowsPerPage}
                                                          onClick={handleOpenTimeRecordingModal}/>}

        {timeRecordingModal &&
        <TimeRecordingModal
            onAdd={openTimeRecordingAddModal}
            onRemove={handleRemoveTimeLogEvent}
            onEdit={handleOpenTimeEditModal}
            timeRecordingId={currentTimeRecordingId}
            onClose={closeTimeRecordingModal}/>}

        {timeEditModal && <TimeEditModal timeLogEventId={currentTimeLogEventId}
                                         timeLogId={currentTimeRecordingId}
                                         onClose={closeTimeEditModal}
                                         value={currentTime}
                                         onChange={setCurrentTime}/>}
        {timeRecordingAddModal &&
        <TimeRecordingAddModal onClose={closeTimeRecordingAddModal} timeLogEventId={currentTimeLogEventId}
                               timeLogId={currentTimeRecordingId}
                                userId={currentUserId}
        />}
        {timeExportModal && <TimeLogExportModal onClose={closeTimeExportModal}/>}

    </Page>
}

export default TimeRecordingPage;