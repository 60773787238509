import { takeLatest, put, call } from "typed-redux-saga/macro";

import { RequestTripReport,
    RequestTripDaysReport, 
    RequestTripVehiclesReport,
    RequestTripUsersReport,
    RequestTripMileageReport,
    RequestTripIdleTimeReport,
    RequestVehicleReport,
    RequestDriveboxReport,
} from "../types/actions/actionTripReport.types";

import {
    receiveTripDaysReport,
    receiveTripMileageReport,
    receiveTripReport,
    receiveTripUserReport,
    receiveTripVehiclesReport,
    receiveTripIdleTimeReport,
    receiveVehicleReport,
    receiveDriveboxReport,
    REPORT_ACTION_TYPES,
} from "../actions/actionReport";

import {handleError} from "../managers/sagaManager";

import {
    getTripDaysReport,
    getTripMileageReport,
    getTripReport,
    getTripUsersReport,
    getTripVehiclesReport,
    getVehicleReport,
    getDriveboxReport,
    getTripIdleTimeReport,
} from "./api";

function* getTripReportData(action: RequestTripReport) {
    try {
        const response = yield* call(getTripReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveTripReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getTripVehiclesReportData(action: RequestTripVehiclesReport) {
    try {
        const response = yield* call(getTripVehiclesReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveTripVehiclesReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getTripUsersReportData(action: RequestTripUsersReport) {
    try {
        const response = yield* call(getTripUsersReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveTripUserReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getTripDaysReportData(action: RequestTripDaysReport) {
    try {
        const response = yield* call(getTripDaysReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveTripDaysReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getTripMileageReportData(action: RequestTripMileageReport) {
    try {
        const response = yield* call(getTripMileageReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveTripMileageReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getTripIdleTimeReportData(action: RequestTripIdleTimeReport) {
    try {
        const response = yield* call(getTripIdleTimeReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveTripIdleTimeReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getVehicleReportData(action: RequestVehicleReport) {
    try {
        const response = yield* call(getVehicleReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveVehicleReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getDriveboxReportData(action: RequestDriveboxReport) {
    try {
        const response = yield* call(getDriveboxReport, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveDriveboxReport(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}


export default function* sagaLiveLocation() {
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_TRIP_REPORT, getTripReportData);
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_TRIP_VEHICLES_REPORT, getTripVehiclesReportData);
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_TRIP_USERS_REPORT, getTripUsersReportData);
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_TRIP_DAYS_REPORT, getTripDaysReportData);
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_TRIP_MILEAGE_REPORT, getTripMileageReportData);
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_TRIP_IDLETIME_REPORT, getTripIdleTimeReportData);
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_VEHICLE_REPORT, getVehicleReportData);
    yield* takeLatest(REPORT_ACTION_TYPES.REQUEST_DRIVEBOX_REPORT, getDriveboxReportData);

}
