import { AnyAction } from "redux";

import { receiveTracksOfVehicle } from "../actions/actionVehicle";

import { receiveUnAuthoriseUser, receiveLogout } from "../actions/actionAuthentication";

import {
  receiveTrips,
  receiveTripById,
  receiveAddTrip,
  receiveLockTrips,
  receiveUnlockTrips,
  receiveSetReadyToExport,
  receiveUpdateTrip,
  receiveUpdateTrips,
  receiveDeleteTrip,
  receiveTrackOfTrip,
  receiveMileageOfTrip,
  showTrack,
  highlightTrack,
  receiveSplitTrip,
  receiveMergeTrips,
  receiveMergeTripsPreview,
  selectTrip,
  selectAllTrips,
  deselectAllTrips,
  loadTrip,
  toggleTripSuccessMsgVisibility,
} from "../actions/actionTrips";

import {
  addEntity,
  deleteEntity,
  deselectAllEntities,
  getEntityById,
  getEntities,
  getPagedEntities,
  selectAllEntities,
  updateEntities,
  updateEntity,
  updateSelection,
  loadEntities,
} from "../managers/reducerManager";

import {
  TripResponseInfo,
  TripResponseWithMoreInfo,
  MileageInformation,
  TripPosition,
} from "../types/trip/tripResponse.types";

export type TripState = {
  readonly current: TripResponseWithMoreInfo;
  readonly entities: TripResponseInfo[];
  readonly allEntities: TripResponseInfo[];
  readonly loading: boolean;
  readonly pageLoading: boolean;
  readonly isSuccessMsgVisible: boolean;
};

const TRIP_INITIAL_STATE: TripState = {
  current: {
    positions: [] as TripPosition[],
    mileageInformation: {} as MileageInformation,
    id: "",
  } as TripResponseWithMoreInfo,
  entities: [],
  allEntities: [],
  loading: true,
  pageLoading: true,
  isSuccessMsgVisible: false,
};

export const tripsDetail = (
  state = TRIP_INITIAL_STATE,
  action: AnyAction
): TripState => {
  
  if (
    receiveTrips.match(action) ||
    receiveSplitTrip.match(action) ||
    receiveMergeTrips.match(action)
  ) {
    return getPagedEntities(state, action.data);
  }

  if (receiveTripById.match(action)) {
    if (state.current.id === action.data.id) {
      //Save Positions and mileageInformation
      let newState = getEntityById(state, action.data);
      newState.current.positions = state.current.positions;
      newState.current.mileageInformation = state.current.mileageInformation;
      return newState;
    }
    return getEntityById(state, action.data);
  }

  if (receiveMergeTripsPreview.match(action)) {
    return {
      ...state,
      current: action.data,
    };
  }

  if (receiveAddTrip.match(action)) {
    return addEntity(state, action.data);
  }

  if (toggleTripSuccessMsgVisibility.match(action)) {
    return {
      ...state,
      isSuccessMsgVisible: action.data,
    };
  }

  if (receiveUpdateTrips.match(action) || receiveLockTrips.match(action)) {
    return updateEntities(state, action.data);
  }

  if (receiveUnlockTrips.match(action)) {
    return updateEntities(state, action.data);
  }

  if (receiveSetReadyToExport.match(action)) {
    return updateEntities(state, action.data);
  }

  if (selectTrip.match(action)) {
    return updateSelection(state, action.data);
  }

  if (selectAllTrips.match(action)) {
    return selectAllEntities(state);
  }

  if (deselectAllTrips.match(action)) {
    return deselectAllEntities(state);
  }

  if (receiveUpdateTrip.match(action)) {
    if (state.current.id === action.data.id) {
      //Save Positions and mileageInformation
      let newState = updateEntity(state, action.data);
      newState.current.positions = state.current.positions;
      newState.current.mileageInformation = state.current.mileageInformation;
      return newState;
    }
    return updateEntity(state, action.data);
  }

  if (receiveTrackOfTrip.match(action)) {
    let firstDateMs = new Date(action.data.positions[0].createdAt).getTime();
    action.data.positions.forEach((position) => {
      let msDiv = new Date(position.createdAt).getTime() - firstDateMs;
      position.timeFromBeginning = msDiv;
    });
    action.data.positions.reduce(
      (accumulator, position) =>
        (position.distanceFromBeginning =
          accumulator + position.distanceToPreviousInMeters),
      0
    );
    action.data.mileageInformation = state.current.mileageInformation; //Save mileageInformation
    return getEntityById(state, action.data);
  }

  if (receiveMileageOfTrip.match(action)) {
    return {
      ...state,
      current: { ...state.current, mileageInformation: action.data },
    };
  }

  if (showTrack.match(action)) {
    return {
      ...state,
      current: { ...state.current, visibility: action.data.visibility },
      entities: state.entities.map((e) => {
        if (e.id == action.data.id) {
          e.visibility = action.data.visibility;
        }
        return e;
      }),
    };
  }

  if (highlightTrack.match(action)) {
    return {
      ...state,
      current: { ...state.current, highlighted: action.data.highlighted },
      entities: state.entities.map((e) => {
        if (e.id == action.data.id) {
          e.highlighted = action.data.highlighted;
        }
        return e;
      }),
    };
  }

  if (receiveDeleteTrip.match(action)) {
    return deleteEntity(state, action.data);
  }

  if (loadTrip.match(action)) {
    return loadEntities(state);
  }

  if (receiveTracksOfVehicle.match(action)) {
    for (let trip of action.data) {
      let firstDateMs = new Date(trip.positions[0]?.createdAt).getTime();
        trip.positions.forEach((position) => {
          let msDiv = new Date(position?.createdAt).getTime() - firstDateMs;
          position.timeFromBeginning = msDiv;
        });
      trip.positions.reduce(
        (accumulator, position) =>
          (position.distanceFromBeginning =
            accumulator + position.distanceToPreviousInMeters),
        0
      );
    }
    return getEntities(state, action.data);
  }

  if(receiveUnAuthoriseUser.match(action) || receiveLogout.match(action)) {
    return TRIP_INITIAL_STATE;
  }

  return state;
};
