import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Button, Grid } from "@material-ui/core";
import { fromToByPeriod } from "../../../managers/timeManager";
import { periods } from "../../../models/Period";
import { subMonths } from "date-fns";
import { updateFilterAPICall } from "../../../actions/actionFilter";
import { requestVehicleMileageExportPDF } from "../../../actions/actionExport";
import PeriodDatePicker from "../../common/selects/datePickers/PeriodDatePicker";
import VehicleSelect from "../../common/selects/specificSelects/VehicleSelect";
import EditModal from "../../common/modals/CustomModal";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  exportButton: {
    marginTop: theme.spacing(1),
  },
}));

function VehicleMileageExportModal({ onClose, onExport }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter.api);
  const { t } = useTranslation();

  useEffect(() => {
    handleDateChange(periods.month.id, defaultRange.from, defaultRange.to);
  }, []);

  const defaultRange = fromToByPeriod(
    periods.month.id,
    subMonths(new Date(), 1)
  );

  function handleExport() {
    dispatch(
      requestVehicleMileageExportPDF({ ...filter, grouping: undefined })
    );

    onExport();
    onClose();
  }

  function handleDateChange(period, from, to) {
    dispatch(
      updateFilterAPICall({
        period,
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      })
    );
  }

  function handleVehiclesChange(vehicleIds) {
    dispatch(updateFilterAPICall({ vehicleIds }));
  }

  const content = (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        Fahrzeuge
      </Grid>
      <Grid item xs={9}>
        <VehicleSelect
          multiple
          onChange={handleVehiclesChange}
          value={filter.vehicleIds}
        />
      </Grid>
      <Grid item xs={3}>
        Zeitraum
      </Grid>

      <Grid item xs={9}>
        <PeriodDatePicker
          period={filter.period || periods.month.id}
          onDateChange={handleDateChange}
          startDate={
            filter.from
              ? new Date(filter.from).toISOString()
              : defaultRange.from
          }
          endDate={
            filter.to ? new Date(filter.to).toISOString() : defaultRange.to
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleExport}
          disabled={filter.vehicleIds?.length === 0}
          className={classes.exportButton}
          variant="contained"
          color="primary"
          fullWidth
        >
          {t("trip.exportmodal.export")}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <EditModal
      onClose={onClose}
      title={t("trip.exportmodal.title")}
      contents={[content]}
    />
  );
}

export default VehicleMileageExportModal;
