import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import CustomSelect from "../Select";
import { periodArray, periodArraySmall, periods, periodDayAndMonth } from "../../../../models/Period";

const { Option } = Select;

const PeriodSelect = props => {
    const { onChange, value, disabled, small, middle } = props;
    const array = small ? periodArraySmall : middle ? periodDayAndMonth : periodArray;

    return (
        <CustomSelect value={value || periods.none.lable}
                      handleChange={onChange}
                      allowClear={false}
                      disabled={disabled}>
            {array.map(vp => (
                <Option key={vp.id} value={vp.id}>
                    {vp.name}
                </Option>
            ))}
        </CustomSelect>
    );
};

PeriodSelect.defaultProps = {
    disabled: false,
};

// PeriodSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.oneOfType([
//         PropTypes.string.isRequired,
//         PropTypes.number.isRequired
//     ]),
//     disabled: PropTypes.bool,
//     small: PropTypes.bool,
// };

export default PeriodSelect;
