import React, { useEffect} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import RoleSelect from "../../common/selects/specificSelects/RoleSelect";
import FormBuilder from "../../../managers/formBuilder";
import FormModal from "../../common/modals/FormModal";

import { requestUpdateUsers } from "../../../actions/actionUser";
import { getIdsFromGroupValues } from "../../../managers/groupManager";
import { Input } from "antd";
import { allowedRoles, isAuthorized } from "../../../managers/authManager";
import { roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../../common/modals/modal.utils";
import { useTranslation } from "react-i18next";

const DEFAULT_INPUT_VALUES = {
  groupValue: undefined,
  roleId: "",
  description: "",
  costcenterId: "",
}

const UserBulkEditModal = ({
  onClose,
  requestUpdateUsers,
  users,
  tree,
  me,
}) => {
  const { fields, handleInputChange } = useInputChangeHandler(DEFAULT_INPUT_VALUES);
  const { groupValue, roleId, description, costcenterId } = fields;
  const { t } = useTranslation();

  const handleOnSave = () => {

    const objToUpdate = {
      userIds: users.filter((v) => v.isSelected).map((v) => v.id),
    };

    if (groupValue) {
      objToUpdate.groupIds = getIdsFromGroupValues(tree, groupValue);
    }

    if (roleId) {
      objToUpdate.roleId = roleId;
    }

    if (description) {
      objToUpdate.description = description;
    }

    if (costcenterId) {
      objToUpdate.costcenterId = costcenterId;
    }

    requestUpdateUsers(objToUpdate);
    onClose();
  }

  const inputs = [
    new FormBuilder.FormInput("Groups", <TreeSelect value={groupValue} onChange={(value) => handleInputChange("groupValue", value)} />)
      .withKey("groupValue")
      .build(),
    new FormBuilder.FormInput("Roles", <RoleSelect value={roleId} onChange={(value) => handleInputChange("roleId", value)} />)
      .withKey("roleId")
      .build(),
    new FormBuilder.FormInput(
      "Description",
      <Input.TextArea value={description} onChange={(e) => handleInputChange("description", e.target.value)} autosize={{ minRows: 3, maxRows: 5 }} />
    )
      .withKey("description")
      .build(),
  ];

  if (isAuthorized(me, allowedRoles(roles.Driver),[packages.CostCenter])) {
    inputs.push(
      new FormBuilder.FormInput(
          t("trip.bulkeditModal.costCenter") ,
        <CostCenterSelect value={costcenterId} onChange={(value) => handleInputChange("costcenterId", value)} allowClear={false} />
      )
        .withKey("costcenterId")
        .build()
    );
  }

  return (
    <FormModal
      action="update"
      onSave={handleOnSave}
      onClose={onClose}
      formType={MODAL_TYPES.withoutValidation}
      title="Bulk edit"
      inputs={inputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestUpdateUsers,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    users: state.users.entities,
    me: state.users.me,
    tree: state.tree,
  };
}

// UserBulkEditModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
// };

export default connect(mapStateToProp, mapDispatchToProps)(UserBulkEditModal);
