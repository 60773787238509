const dayOfWeek = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7
}

const pastTimeRange = {
    day: 1,
    week: 2,
    month: 3,
    year: 4,
};

const dayOfTheMonth = {
    start: 1,
    middle: 15,
    end: 31,
}
const periods = {
    lastYear: 0,
    lastMonth: 1,
    lastWeek: 2,
}

const pastTimeRangeArray = [
    {id: pastTimeRange.day, name: "Vorherige Tage"},
    {id: pastTimeRange.week, name: "Vorherige Woche"},
    {id: pastTimeRange.month, name: "Vorheriges Monat"},
]

const dayOfTheWeekArray = [
    {id: dayOfWeek.monday, name: "Montag"},
    {id: dayOfWeek.tuesday, name: "Dienstag"},
    {id: dayOfWeek.wednesday, name: "Mittwoch"},
    {id: dayOfWeek.thursday, name: "Donnerstag"},
    {id: dayOfWeek.friday, name: "Freitag"},
    {id: dayOfWeek.saturday, name: "Samstag"},
    {id: dayOfWeek.sunday, name: "Sonntag"},
]

const dayOfTheMonthArray = [
    {id: dayOfTheMonth.start, name: "Erster des Monats"},
    {id: dayOfTheMonth.middle, name: "Mitte des Monats"},
    {id: dayOfTheMonth.end, name: "Letzter des Monats"},
]

const mapPastTimeRangeToPeriodServerEnum = (pastTimeValue) => {
    switch (pastTimeValue) {
        case pastTimeRange.day:
            return undefined; //server handels days with a seperate attribute (lastDays)
        case pastTimeRange.week:
            return periods.lastWeek;
        case pastTimeRange.month:
            return periods.lastMonth;
        case pastTimeRange.year:
            return periods.lastYear;
    }
}

const mapPeriodToPastTimeRange = (period) => {
    switch (period) {
        case periods.lastWeek:
            return pastTimeRange.week;
        case periods.lastMonth:
            return pastTimeRange.month;
        case periods.lastYear:
            return pastTimeRange.year;
        default:
            return pastTimeRange.day;
    }
}

export {
    pastTimeRange,
    dayOfWeek,
    pastTimeRangeArray,
    mapPastTimeRangeToPeriodServerEnum,
    mapPeriodToPastTimeRange,
    dayOfTheWeekArray,
    dayOfTheMonthArray
};
