import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

import CSVReader from "react-csv-reader";
import { Button, Grid } from "@material-ui/core";

import GenericTable from "../table/GenericTable";

import Comparator from "../../../models/Comparator";
import { usePagination } from "../../../hooks/usePagination";
import { clearError } from "../../../actions/actionCommon";
import { Alert, Progress } from "antd";
import MandatorSelect from "../selects/specificSelects/MandatorSelect";

const options = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
};

const status = {
    open: "Offen",
    inProgress: "In Bearbeitung",
    failed: "Fehlgeschlagen",
    added: "Hinzugefügt",
};

const useStyles = makeStyles({
    csvInputField: {
        padding: "10px",
        display: "block",
        margin: "15px auto",
        border: "1px solid #ccc",
        borderRadius: "5px",
    },

    errorAlert: {
        cursor: "pointer",
    },
});

const MandatorUserInsertModal = props => {
    const classes = useStyles();
    const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
    const [finish, setFinish] = useState(false);
    const [currentEntry, setEntry] = useState();
    const [index, setIndex] = useState(-1);
    const [running, isRunning] = useState(false);
    const [data, setData] = useState([]);
    const [failedOnly, showFailedOnly] = useState(false);
    const [mandatorId, setMandatorId] = useState();
    const { identifier, onClose, error, response, columnInformations, onAdd, mandatorSelect, forCostcenter } = props;

    const ignoreLine = row => {
        const newData = data.map(d => {
            if (d[identifier] == row[identifier]) {
                d.lockedAt = true;
            }
            return d;
        });

        setData(newData);
    };

    const useLine = row => {
        const newData = data.map(d => {
            if (d[identifier] == row[identifier]) {
                d.lockedAt = undefined;
            }
            return d;
        });
        setData(newData);
    };

    const setNewIndexForRequest = () => {
        for (let i = index + 1; i < data.length; i++) {
            if (!data[i].lockedAt) {
                changePage(undefined, Math.floor((i) / rowsPerPage));
                setIndex(i);
                setEntry(data[i]);
                break;
            }
        }
        if (index + 1 >= data.length) {
            setFinish(true); //just update the state
        }
    };

    const runAll = () => {
        isRunning(true);
    };

    const updateStatus = (status) => {
        if (index >= 0 && index < data.length) {
            let newData = data;
            newData[index].status = status;
            setData(newData);
        }
    };

    const updateError = (error) => {
        if (index >= 0 && index < data.length) {
            let newData = data;
            newData[index].status = status.failed;
            newData[index].error = error;
            setData(newData);
        }
    };

    useEffect(() => {
        if (running) setNewIndexForRequest();
    }, [running]);

    useEffect(() => {
        if (running && currentEntry) runRow(currentEntry);
    }, [currentEntry]);

    useEffect(() => {
        //only 2 == int vs string
        if (running && currentEntry[identifier] == response[identifier]) {
            updateStatus(status.added);
            setNewIndexForRequest();
        }
    }, [response]);

    useEffect(() => {
        if (error && running) {
            updateError(error.message);
            setNewIndexForRequest();
        }
    }, [error.updatedAt]);

    const runRow = row => {
        if (!row.lockedAt) {
            props.onAdd({ ...row, mandatorId });
        }
    };

    const ignoredComparator = new Comparator(["lockedAt"], (locked) => locked);
    const notIgnoredComparator = new Comparator(["lockedAt"], (locked) => !locked);

    const loadCSV = csv => {
        const newData = csv.map(d => {
            let newObject = {};
            columnInformations.forEach((ci, i) => {
                newObject[ci.key] = d[i];
            });
            newObject.status = status.open;
            newObject.error = "";
            newObject.lockedAt = false;

            return newObject;
        });
        setData(newData);
    };


    const statusColumnInformations = [
        {
            title: "Error",
            key: "error",
            color: { value: "red", comparator: new Comparator(["status"], (s) => s === status.failed) },
        },
        {
            title: "Status",
            key: "status",
            color: { value: "red", comparator: new Comparator(["status"], (s) => s === status.failed) },
        }];

    const columnInformationsWithStatus = [...statusColumnInformations, ...columnInformations];

    const actions = [
        { title: "Use line", event: useLine, comparator: ignoredComparator },
        { title: "Ignore line", event: ignoreLine, comparator: notIgnoredComparator },
    ];

    const csvFileLoaded = data && data.length > 0;

    const count = data.filter(m => !m.lockedAt).length;
    const failedCount = data.filter(m => m.status === status.failed).length;
    const finishedCount = data.filter(m => m.status !== status.open && m.status !== status.inProgress).length;
    const addedCount = data.filter(m => m.status === status.added).length;

    const pageData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const failedPageData = data.filter(d => d.status === status.failed)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    var selectCount = 1;
    if (mandatorSelect) selectCount++;

    console.log(selectCount);

    const content = <Grid container spacing={1}>

        {!csvFileLoaded && forCostcenter && <Grid item xs={12}>
            <p>1. Spalte: Bezeichnung <br />2. Spalte: Beschreibung (optional)</p>
        </Grid>}

        {!csvFileLoaded && mandatorSelect && <Grid item xs={12}>
            <MandatorSelect value={mandatorId} onChange={setMandatorId} allowClear={false}/>
        </Grid>}
        {!csvFileLoaded && (!mandatorSelect || mandatorId) && <Grid item xs={12}>
            <CSVReader
                cssClass={classes.csvInputField}
                onFileLoaded={loadCSV}
                parserOptions={options}
            />
        </Grid>}

        {/*csvFileLoaded && running && !failedOnly && forCostcenter && <Grid item xs={12}>
            <Alert message="Import wurde abgeschlossen. Sie können das Fenster nun schließen"/>
        </Grid>*/}

        {csvFileLoaded && running && !failedOnly && forCostcenter && <Grid item xs={12}>
            <Progress percent={Math.round(100 / count * finishedCount)}/>
        </Grid>}

        {csvFileLoaded && running && !failedOnly && forCostcenter && <Grid item xs={4}>
            <Alert message={"Bearbeitet: " + finishedCount + " / " + count} type="info"/>
        </Grid>}

        {csvFileLoaded && running && !failedOnly && forCostcenter && <Grid item xs={4}>
            <Alert message={"Hinzugefügt: " + addedCount} type="success"/>
        </Grid>}


        {csvFileLoaded && running && !failedOnly && forCostcenter && <Grid item xs={4}>
            <div className={classes.errorAlert} onClick={() => {
                showFailedOnly(true);
                changePage(undefined, 0);
            }}>
                <Alert message={"Fehler: " + failedCount} type="error"/>
            </div>
        </Grid>}


        {csvFileLoaded && running && failedOnly && forCostcenter && <Grid item xs={12}>
            <div className={classes.errorAlert} onClick={() => showFailedOnly(false)}>
                <Alert message={"Fehler: " + failedCount} type="error"/>
            </div>
        </Grid>}

        {csvFileLoaded && <Grid item xs={12}>
            <GenericTable
                data={failedOnly ? failedPageData : pageData}
                columnInformations={columnInformationsWithStatus}
                actions={actions}
                showActions
                pageNavigation
                page={page}
                rowsPerPage={rowsPerPage}
                rowCount={failedOnly ? failedCount : data.length}
                handleChangePage={changePage}
                handleChangeRowsPerPage={changeRowPerPage}/>
        </Grid>}

        {csvFileLoaded && !running && <Grid item xs={3}>
            <Button variant="contained" color="primary" onClick={runAll}>Starte Import</Button>
        </Grid>}


    </Grid>;

    return <CustomModal title="CSV Import" contents={[content]} onClose={onClose} showErrorToolbar={false} largeModal/>;
};

MandatorUserInsertModal.defaultProps = {
    identifier: "id",
};

// MandatorUserInsertModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     columnInformations: PropTypes.array.isRequired,
//     onAdd: PropTypes.func.isRequired,
//     response: PropTypes.object.isRequired,
//     identifier: PropTypes.string,
//     mandatorSelect: PropTypes.bool,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clearError }, dispatch);
}

function mapStateToProp(state) {
    return {
        error: state.error,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MandatorUserInsertModal);
