import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestGeoCode,
  ReceiveGeoCode,
  RequestReverseGeoCode,
  ReceiveReverseGeoCode,
  RequestAutocompleteAddress,
  ReceiveAutoCompleteAddress,
  RequestLocationDetails,
  ReceiveLocationDetails,
  ClearGeoCode,
} from "../types/actions/actionGeoLocation.types";

import {
  GeoCodeReverseGetRequest,
  GeoCodeAutoCompleteAddressRequest,
  GeoCodeLocationDetailsRequest,
} from "../types/geoLocation/geoLocationRequest.types";

export enum GEOCODE_ACTION_TYPES {
  REQUEST_GEOCODE = "REQUEST_GEOCODE",
  RECEIVE_GEOCODE = "RECEIVE_GEOCODE",

  REQUEST_REVERSE_GEOCODE = "REQUEST_REVERSE_GEOCODE",
  RECEIVE_REVERSE_GEOCODE = "RECEIVE_REVERSE_GEOCODE",

  REQUEST_AUTOCOMPLETE_ADDRESS = "REQUEST_AUTOCOMPLETE_ADDRESS",
  RECEIVE_AUTOCOMPLETE_ADDRESS = "RECEIVE_AUTOCOMPLETE_ADDRESS",

  REQUEST_LOCATION_DETAILS = "REQUEST_LOCATION_DETAILS",
  RECEIVE_LOCATION_DETAILS = "RECEIVE_LOCATION_DETAILS",

  CLEAR_GEOCODE = "CLEAR_GEOCODE",
}

export const requestGeoCode = withMatcher((data: any): RequestGeoCode => {
  return createAction(GEOCODE_ACTION_TYPES.REQUEST_GEOCODE, data);
});

export const receiveGeoCode = withMatcher((data: any): ReceiveGeoCode => {
  return createAction(GEOCODE_ACTION_TYPES.RECEIVE_GEOCODE, data);
});

export const requestReverseGeoCode = withMatcher(
  (data: GeoCodeReverseGetRequest): RequestReverseGeoCode => {
    return createAction(GEOCODE_ACTION_TYPES.REQUEST_REVERSE_GEOCODE, data);
  }
);

// this any type should change to more specific type later.
export const receiveReverseGeoCode = withMatcher(
  (data: any): ReceiveReverseGeoCode => {
    return createAction(GEOCODE_ACTION_TYPES.RECEIVE_REVERSE_GEOCODE, data);
  }
);

export const requestAutocompleteAddress = withMatcher(
  (data: GeoCodeAutoCompleteAddressRequest): RequestAutocompleteAddress => {
    return createAction(
      GEOCODE_ACTION_TYPES.REQUEST_AUTOCOMPLETE_ADDRESS,
      data
    );
  }
);

// this any type should change to a more specific type later
export const receiveAutoCompleteAddress = withMatcher(
  (data: any): ReceiveAutoCompleteAddress => {
    return createAction(
      GEOCODE_ACTION_TYPES.RECEIVE_AUTOCOMPLETE_ADDRESS,
      data
    );
  }
);

export const requestLocationDetails = withMatcher(
  (data: GeoCodeLocationDetailsRequest): RequestLocationDetails => {
    return createAction(GEOCODE_ACTION_TYPES.REQUEST_LOCATION_DETAILS, data);
  }
);

export const receiveLocationDetails = withMatcher(
  (data: any): ReceiveLocationDetails => {
    return createAction(GEOCODE_ACTION_TYPES.RECEIVE_LOCATION_DETAILS, data);
  }
);

export const clearGeoCode = withMatcher((): ClearGeoCode => {
  return createAction(GEOCODE_ACTION_TYPES.CLEAR_GEOCODE);
});
