import { languages } from "../models/Language";
import { languageKey } from "../managers/localStorageManager";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";

//load language from localstorage
const language =
  JSON.parse(localStorage.getItem(languageKey)) || languages.German;

export const customTranslation = {
  total: {
    english: "Total",
    german: "Gesamt",
  },
  business: {
    english: "Business",
    german: "Geschäftlich",
  },
  private: {
    english: "Private",
    german: "Privat",
  },
  driveToWork: {
    english: "Drive to work",
    german: "Fahrt zur Arbeit",
  },
  error: {
    internalServerError: {
      english:
        "Sorry something went wrong. We're working on it and we'll get it fixed",
      german:
        "Entschuldigung, etwas ist schief gelaufen. Wir arbeiten daran und werden es reparieren",
    },
  },
};

export const getTranslation = (customTranslationObject, me) => {
  const isLebensHilfeNiederOE =
    me?.mandator.name ===
        "Lebenshilfe Niederösterreich gemeinnützige Ges.m.b.H.";

  const isEwServiceWernerEgger =
        me?.mandator.name ===
        "EW Service Werner Egger";

  if (
    isLebensHilfeNiederOE &&
    customTranslationObject === customTranslation.driveToWork
  ) {
    switch (language) {
      case languages.English:
        return "Trip";
      default:
        return "Tour";
    }
  }

  if (
        isEwServiceWernerEgger &&
        customTranslationObject === customTranslation.driveToWork
    ) {
        switch (language) {
            case languages.English:
                return "Mayor ride";
            default:
                return "Fahrt Bürgermeister";
        }
    }

  if (
    isLebensHilfeNiederOE &&
    customTranslationObject === customTranslation.business
  ) {
    switch (language) {
      case languages.English:
        return "Operational trip";
      default:
        return "Betriebsfahrt";
    }
  }

  switch (language) {
    case languages.English:
      return customTranslationObject.english;
    default:
      return customTranslationObject.german;
  }
};

export const getDateLocale = () => {
  return language === languages.German ? deLocale : enLocale;
};
