import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {
    addWeeks,
    subWeeks,
    endOfWeek,
    getWeek,
    isSameDay,
    isWithinInterval,
    startOfWeek,
} from "date-fns";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import {fromToByPeriod} from "../../../../managers/timeManager";
import {periods} from "../../../../models/Period";
import {useHotkeys} from "react-hotkeys-hook";
import CustomDatePicker from "./CustomDatePicker";
import {DatePicker, PickersDay, PickersDayProps} from "@material-ui/pickers";

const styles = theme => ({
    highlight: {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    firstHighlight: {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
});

// This variable probably need to be removed in the future since is not in use.
let minDateValue = new Date(2016, 0, 1);

const WeekPicker = props => {
    const { value, classes, minDate, disableFuture } = props;

    minDateValue = (typeof minDate !== "undefined") ? minDate : new Date(2016, 0, 1);

    useHotkeys("ctrl+alt+left", () => {
        setDates(subWeeks(value, 1));
    }, [value]);

    useHotkeys("ctrl+alt+right", () => {
        setDates(addWeeks(value, 1));
    }, [value]);


    function setDates(startDate) {
        const weekRange = fromToByPeriod(periods.week.id, startDate);
        props.onDateChange(weekRange.from, weekRange.to);
    }

    function formatWeekSelectLabel(date) {
        let dateClone = new Date(date.getTime());
        return getWeek(dateClone, {weekStartsOn: 1}) + " Kalenderwoche";
    }

    function renderWrappedWeekDay(date,
                                  _selectedDates,
                                  DayComponentProps) {


        const dateClone = new Date(date);
        const selectedDateClone = new Date(value ?? new Date());

        const start = startOfWeek(selectedDateClone, {weekStartsOn: 1});
        const end = endOfWeek(selectedDateClone, {weekStartsOn: 1});

        const dayIsBetween = isWithinInterval(dateClone, {start, end});
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        return (
            <PickersDay
                {...DayComponentProps}
                disableMargin
                className={clsx({
                    [classes.highlight]: dayIsBetween,
                    [classes.firstHighlight]: isFirstDay,
                    [classes.endHighlight]: isLastDay,
                })}
            />


        );
    }

    return <CustomDatePicker views={["date"]}
                             value={value}
                             disableFuture={disableFuture}
                             showDaysOutsideCurrentMonth
                             onChange={setDates}
                             labelFunc={formatWeekSelectLabel}
                             renderDay={renderWrappedWeekDay}
                             inputFormat="'Woche vom' dd MMMM"
                             />
};

WeekPicker.defaultProps = {
    disableFuture: true
};

WeekPicker.propTypes = {
    value: PropTypes.object,
    minDate: PropTypes.object,
    disableFuture: PropTypes.bool,
    onDateChange: PropTypes.func.isRequired,
    disableFuture: PropTypes.bool
};

export default withStyles(styles)(WeekPicker);