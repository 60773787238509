import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import {
    addTimeRecording,
    deleteTimeRecording,
    getTimeRecording,
    getTimeRecordingById,
    getTrackOfTrip,
    updateTimeRecording
} from "./api";

import {handleError} from "../managers/sagaManager";
import {
    receiveAddTimeRecordings,
    receiveDeleteTimeRecording,
    receiveTimeRecordingById,
    receiveTimeRecordings, receiveUpdateTimeRecording, REQUEST_ADD_TIME_RECORDINGS, REQUEST_DELETE_TIME_RECORDINGS,
    REQUEST_TIME_RECORDINGS,
    REQUEST_TIME_RECORDINGS_BY_ID, REQUEST_UPDATE_TIME_RECORDINGS
} from "../actions/actionTimeRecording";

function* getTimeRecordingData(action) {
    try {
        const response = yield call(getTimeRecording, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveTimeRecordings(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* getTimeRecordingByIdData(action) {
    try {
        const response = yield call(getTimeRecordingById, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveTimeRecordingById(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* addTimeRecordingData(action) {
    try {
        yield call(addTimeRecording, action.data);
        if (action.data.timeLogId) {
            const response = yield call(getTimeRecordingById, action.data.timeLogId);
            if (response.status >= 200 && response.status < 300) {
                yield put(receiveAddTimeRecordings(response.data));
            } else {
                throw response;
            }
        }
    } catch (error) {
        yield put(handleError(error));
    }
}


function* updateTimeRecordingData(action) {
    try {
        yield call(updateTimeRecording, action.data);
        const response = yield call(getTimeRecordingById, action.data.timeLogId);

        if (response.status >= 200 && response.status < 300) {
            yield put(receiveUpdateTimeRecording(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* deleteTimeRecordingData(action) {
    try {
        const response = yield call(deleteTimeRecording, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveDeleteTimeRecording({id: action.data}));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}


export default function* sagaTimeRecording() {
    yield takeLatest(REQUEST_TIME_RECORDINGS, getTimeRecordingData);
    yield takeEvery(REQUEST_TIME_RECORDINGS_BY_ID, getTimeRecordingByIdData);
    yield takeLatest(REQUEST_ADD_TIME_RECORDINGS, addTimeRecordingData);
    yield takeEvery(REQUEST_UPDATE_TIME_RECORDINGS, updateTimeRecordingData);
    yield takeEvery(REQUEST_DELETE_TIME_RECORDINGS, deleteTimeRecordingData);


}
