import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Input, Button, Typography } from "antd";
import { makeStyles, Box } from "@material-ui/core";

import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";

import { requestDriveboxes } from "../../../actions/actionDriveboxes";
import { requestSendEmail } from "../../../actions/actionEmail";

import { filterDriveboxesByModel } from "./modal.utils";
import { languages } from "../../../models/Language";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  mailIcon: {
    fontSize: "85px",
    color: theme.palette.primary.main,
  },
  formDescription: {
    fontSize: "13px",
    fontWeight: "bold",
  },

  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "45px",
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
  },

  modal: {
    "& .ant-modal-header": {
      backgroundColor: theme.palette.primary.main,
    },

    "& .ant-modal-title": {
      color: "white"
    },

    "& .ant-modal-close-x": {
      color: "white"
    }
  },
}));

const OrderMoreBoxModal = ({ onClose, isOpen, currentUser }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const language = useSelector((state) => state.language);
  const driveboxes = useSelector((state) => state.driveboxes.entities);
  const driveboxModels = filterDriveboxesByModel(driveboxes);
  const { t } = useTranslation();

  const getFormDescription = (driveboxModels) => {
    let formDescription;

    driveboxModels.forEach((driveboxModel, idx) => {
      formDescription = (
        <>
          {formDescription} {driveboxModel.number} {driveboxModel.model}{" "}
          {idx !== driveboxModels.length - 1 && (
             t("common.modal.OrderBoxModal.modalDescriptionLastPart")
          )}
        </>
      );
    });

    return formDescription;
  };

  const DEFAULT_INPUT_VALUES = {
    emailBody:
      language === languages.German
        ? `Liebes DRIVEBOX Team,\n\nich möchte gerne weitere Lizenzen abonnieren und bitte um Kontaktaufnahme!\n\nMeine Kontaktdaten: \n - ${currentUser.firstName} ${currentUser.lastName}\n - ${currentUser.email}\n\nAnmerkungen:\n\n`
        : `Dear DRIVEBOX Team,\n\nI would like to order more boxes and request contact!\n\nMy contact details:\n - ${currentUser.firstName} ${currentUser.lastName}\n - ${currentUser.email}\n\nAdditional Comments:\n\n`,
  };

  useEffect(() => {
    dispatch(requestDriveboxes());
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then(({ emailBody }) => {
        const emailData = {
          from:"office@drivebox.at",
          to: "sales@drivebox.at",
          cc: ["support@drivebox.at", "accounting@drivebox.at"],
          subject:
            language === languages.German
              ? `office@drivebox.at im Namen von ${currentUser.firstName} ${currentUser.lastName}, ${currentUser.mandator.name}`
              : `office@drivebox.at on behalf of ${currentUser.firstName} ${currentUser.lastName}, ${currentUser.mandator.name}`,
          text: emailBody,
        };

        dispatch(requestSendEmail(emailData));

        onClose();
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Modal
        title={t("common.modal.OrderBoxModal.title")}
        className={classes.modal}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            className={classes.submitButton}
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Send
          </Button>,
        ]}
      >
        <Box className={classes.headerContainer}>
          <DraftsOutlinedIcon className={classes.mailIcon} />
          <Typography.Text className={classes.formDescription} editable={false}>
            {driveboxModels.length > 0 ? (
              <>
                {t("common.modal.OrderBoxModal.modalDescriptionFirstPart")}
                {getFormDescription(driveboxModels)}
              </>
            ) : (
              t("common.modal.OrderBoxModal.noBoxes")
            )}
          </Typography.Text>
        </Box>
        <Form
          initialValues={{ emailBody: DEFAULT_INPUT_VALUES.emailBody }}
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label={t("common.modal.OrderBoxModal.emailBody")}
            name="emailBody"
            rules={[
              {
                required: true,
                message: (
                  t("common.modal.OrderBoxModal.emailBodyValidation")
                ),
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 9, maxRows: 14 }}
              maxLength={400}
              placeholder={"Schreiben Sie uns eine Nachricht"}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OrderMoreBoxModal;
