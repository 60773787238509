// display options (colors) of normal and highlighted polygons
const loadedPolyOptions = { color: 'purple' }
const highlightedPolyOptions = { color: 'green' }

const customColors = {
    bright: '#EFEFEF',
    disabled: '#AAAAAA',
    disabledDark: '#555555',
    danger: '#FF0000',
    dark: '#343434',
    green: '#008000',
    ilogsBlue: '#203159',
    ilogsBlueBright: '#145B99',
    deleteDarkRed: '#B60303'
}

export {
    loadedPolyOptions,
    highlightedPolyOptions,
    customColors,
}