import { mapQueryParams } from "../managers/apiManager";
import { languageKey } from "../managers/localStorageManager";

const axios = require("axios");

function getInstance(response) {
  return axios.create({
    baseURL: response.reportServiceUrl + "/Api/Reports/",
    timeout: 100000,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "Accept-Language": JSON.parse(localStorage.getItem(languageKey)),
      Authorization: response.authorizationToken,
    },
    responseType: "blob",
  });
}

export const getMandatorExportXlsx = async (services, params) => {
  return getInstance(services).post("Mandators/xlsx", JSON.stringify(params));
};

export const getMandatorUserExportXlsx = async (services, params) => {
  return getInstance(services).post("Users/xlsx", JSON.stringify(params));
};

export const getMandatorDriveboxExportXlsx = async (services, params) => {
  return getInstance(services).post("Driveboxes/xlsx", JSON.stringify(params));
};

export const getTripsExportPdf = async (services, params) => {
  return getInstance(services).post("Trips/pdf", JSON.stringify(params));
};

export const getTripsExportXlsx = async (services, params) => {
  return getInstance(services).post("Trips/xlsx", JSON.stringify(params));
};

export const getTripLogExportPdf = async (services, params) => {
  return getInstance(services).post("SteeringLog/pdf", JSON.stringify(params));
};

export const getDayViewExportPdf = async (services, params) => {
  return getInstance(services).post("DayTrips/pdf", JSON.stringify(params));
};

export const getDayViewGraphicExportPdf = async (services, params) => {
  return getInstance(services).post(
    "DayGraphicTrips/pdf",
    JSON.stringify(params)
  );
};

export const getVehiclesExportXlsx = async (services, params) => {
  return getInstance(services).post("Vehicles/xlsx", JSON.stringify(params));
};

export const getUserExportXlsx = async (services, params) => {
  return getInstance(services).post(
    "MandatorUsers/xlsx",
    JSON.stringify(params)
  );
};

export const getDriveboxesExportXlsx = async (services, params) => {
  return getInstance(services).post(
    "MandatorDriveboxes/xlsx",
    JSON.stringify(params)
  );
};

export const getTripGeofenceIntersectionsExportPdf = async (
  services,
  params
) => {
  return getInstance(services).post(
    "TripGeofenceIntersections/pdf",
    JSON.stringify(params)
  );
};

export const getVehicleMileageSummaryExportPdf = async (services, params) => {
  return getInstance(services).post(
    "VehicleMileageSummary/pdf/",
    JSON.stringify(params)
  );
};

export const getExportedMonthlyXlsx = async (services, params) => {
  return getInstance(services).post("tripdata/xlsx", JSON.stringify(params));
}

export const getReservationReport = async (services, params) => {
  return getInstance(services).post("reservationdata/xlsx", JSON.stringify(params));
}

export const getHwnoeVehicleDataReport = async (services, params) => {
  return getInstance(services).post("hwnoevehiclemonthlyreport/xlsx", JSON.stringify(params));
}

export const getCostcenterReport = async (services, params) => {
  return getInstance(services).post("costcenterreport/xlsx", JSON.stringify(params))
}

export const getFuelMonitorReport = async (services, params) => {
  return getInstance(services).post("costcenterreport/xlsx", JSON.stringify(params))
}