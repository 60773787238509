import React, {useState} from "react";
import PropTypes from "prop-types";
import CustomModal from "../common/modals/CustomModal";
import {Box, Button, makeStyles, Typography} from "@material-ui/core";
import CustomSelect from "../common/selects/Select";
import MailsInputField from "../common/inputs/MailsInputField";
import InputField from "../common/inputs/InputField";
import {useDispatch} from "react-redux";
import {requestForgotPassword} from "../../actions/actionUser";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    box: {
        border: "1 solid black",
        margin: "20px 20px"
    },
    resetBtn: {
        marginTop: "5px"
    },
});

const ForgotPasswordModal = props => {
    const [mail, setMail] = useState(props.mail || "");
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const {onClose} = props;
    const content = <div>

        <div className={classes.box}>
            <Typography>{t("login.forgotPasswordModal.infoText")}</Typography>
            <InputField variant="outlined" label={t("login.forgotPasswordModal.mail")}
                        onChange={e => setMail(e.target.value)}
                        value={mail}/>

            <Button disabled={!mail} className={classes.resetBtn} fullWidth variant="contained" color="primary"
                    onClick={() => {
                        dispatch(requestForgotPassword({email: mail}));
                        onClose();
                    }}>
                {t("login.forgotPasswordModal.resetBtn")}
            </Button>
        </div>

    </div>;

    return (
        <CustomModal onClose={onClose} title={t("login.forgotPasswordModal.title")}
                     contents={[content]}/>
    );
};

export default ForgotPasswordModal;
