import React from "react";
import TableTypes from "../../models/TableTypes";
import GenericTable from "../common/table/GenericTable";
import PropTypes from "prop-types";
import {getAllEventsWithDateTime} from "../../models/TimeLogEventType";
import {useSelector} from "react-redux";

const MonthTimeRecordingTable = props => {
    const timeLogs = useSelector(state => state.timeRecording.entities);

    const {onClick} = props;
    const mappedData = [];

    timeLogs[0].timeLog.forEach(l => {
        const paredEvents = JSON.parse(l.stringifiedEvents).sort(function (a, b) {
            return  new Date(a.OccurredAt)-new Date(b.OccurredAt);
        });

        mappedData.push({
            id: l.id,
            date: l.startedAt,
            from: l.startedAt,
            to: l.finishedAt,
            break: getAllEventsWithDateTime(paredEvents)
        })
    })


    const columnInformations = [
        {title: "Datum", key: "date", type: TableTypes.date},
        {title: "Von", key: "from", type: TableTypes.time},
        {title: "Bis", key: "to", type: TableTypes.time},
        {title: "Ruhepause", key: "break"},
    ];

    const actions = [
        {
            title: "Anzeigen",
            event: onClick
        }
    ];

    return <GenericTable
        data={mappedData}
        columnInformations={columnInformations}
        actions={actions}
        onRowClick={onClick}
        showActions
    />


}

// MonthTimeRecordingTable.propTypes = {
//     page: PropTypes.number,
//     rowsPerPage: PropTypes.number.isRequired,
//     handleChangePage: PropTypes.func.isRequired,
//     handleChangeRowsPerPage: PropTypes.func.isRequired,
//     onClick: PropTypes.func.isRequired,

// };

export default MonthTimeRecordingTable;