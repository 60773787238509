import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestInspection,
  ReceiveInspection,
  RequestNextInspection,
  ReceiveNextInspection,
  RequestAddInspection,
  ReceiveAddInspection,
  RequestDeleteInspection,
  ReceiveDeleteInspection
} from "../types/actions/actionVehicleInspection.types";

import { InspectionHistoryRequest } from "../types/vehicleInspection/vehicleInspectionRequest.types";
import { InspectionResponse } from "../types/vehicleInspection/vehicleInspectionResponse.types";

export enum INSPECTION_ACTION_TYPES {
  REQUEST_INSPECTION = "REQUEST_INSPECTION",
  RECEIVE_INSPECTION = "RECEIVE_INSPECTION",

  REQUEST_NEXT_INSPECTION = "REQUEST_NEXT_INSPECTION",
  RECEIVE_NEXT_INSPECTION = "RECEIVE_NEXT_INSPECTION",

  REQUEST_ADD_INSPECTION = "REQUEST_ADD_INSPECTION",
  RECEIVE_ADD_INSPECTION = "RECEIVE_ADD_INSPECTION",

  REQUEST_DELETE_INSPECTION = "REQUEST_DELETE_INSPECTION",
  RECEIVE_DELETE_INSPECTION = "RECEIVE_DELETE_INSPECTION",
}

export const requestInspection = withMatcher(
  (vehicleId: string): RequestInspection => {
    return createAction(INSPECTION_ACTION_TYPES.REQUEST_INSPECTION, vehicleId);
  }
);

export const receiveInspection = withMatcher(
  (data: InspectionResponse[]): ReceiveInspection => {
    return createAction(INSPECTION_ACTION_TYPES.RECEIVE_INSPECTION, data);
  }
);

export const requestNextInspection = withMatcher(
  (vehicleId: string): RequestNextInspection => {
    return createAction(
      INSPECTION_ACTION_TYPES.REQUEST_NEXT_INSPECTION,
      vehicleId
    );
  }
);

export const receiveNextInspection = withMatcher(
  (data: string): ReceiveNextInspection => {
    return createAction(INSPECTION_ACTION_TYPES.RECEIVE_NEXT_INSPECTION, data);
  }
);

export const requestAddInspection = withMatcher(
  (data: InspectionHistoryRequest): RequestAddInspection => {
    return createAction(INSPECTION_ACTION_TYPES.REQUEST_ADD_INSPECTION, data);
  }
);

export const receiveAddInspection = withMatcher(
  (data: InspectionResponse): ReceiveAddInspection => {
    return createAction(INSPECTION_ACTION_TYPES.RECEIVE_ADD_INSPECTION, data);
  }
);

export const requestDeleteInspection = withMatcher(
  (vehicleServiceId: string): RequestDeleteInspection => {
    return createAction(
      INSPECTION_ACTION_TYPES.REQUEST_DELETE_INSPECTION,
      vehicleServiceId
    );
  }
);

export const receiveDeleteInspection = withMatcher(
    (data: InspectionResponse): ReceiveDeleteInspection => {
      return createAction(INSPECTION_ACTION_TYPES.RECEIVE_DELETE_INSPECTION, data);
    }
  );
