import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import InputField from "../inputs/InputField";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    confirmDialog: {
        zIndex: 6000,
    },
});

class ConfirmDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmText: "",
        };
    }

    handleConfirmTextChange = event => {
        this.setState({confirmText: event.target.value});
    };

    render() {
        const {classes, title, text, cancelButton, confirmButton, fullScreen, open, onClose, onConfirm, confirmControlText} = this.props;
        const {confirmText} = this.state;

        return (
            <Dialog
                className={classes.confirmDialog}
                fullScreen={fullScreen}
                fullWidth
                disableBackdropClick
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{text}</DialogContentText>
                    <InputField value={confirmText} onChange={this.handleConfirmTextChange}
                                error={confirmText != confirmControlText}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {cancelButton}
                    </Button>
                    <Button onClick={onConfirm} color="primary" autoFocus disabled={confirmText != confirmControlText}>
                        {confirmButton}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmDialog.defaultProps = {confirmButton: "Confirm", cancelButton: "cancel", open: true};

// ConfirmDialog.propTypes = {
//     open: PropTypes.bool,
//     confirmButton: PropTypes.string,
//     title: PropTypes.string.isRequired,
//     text: PropTypes.string.isRequired,
//     cancelButton: PropTypes.string.isRequired,
//     onClose: PropTypes.func.isRequired,
//     onConfirm: PropTypes.func.isRequired,
//     confirmControlText: PropTypes.string.isRequired,
//     fullScreen: PropTypes.bool.isRequired,
// };

export default withStyles(styles, {withTheme: true})(withMobileDialog()(ConfirmDialog));
