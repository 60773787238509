import React from "react";
import {Select} from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import {tripTypeArray} from "../../../../models/TripType";
import { useTranslation } from "react-i18next";

const {Option} = Select;

const TripTypeSelect = props => {
    const {onChange, value, disabled, mutliple, allowClear, showSearch} = props;
    const { t } = useTranslation();

    if (mutliple) return <MultiSelect placeholder={t("common.select.triptypes")}
                                      selectedArray={value}
                                      array={tripTypeArray}
                                      handleChange={onChange}/>;
    return (
        <CustomSelect value={value} showSearch={showSearch} allowClear={allowClear} placeholder={t("common.select.triptype")}
                      handleChange={onChange}
                      disabled={disabled}>
            {tripTypeArray.map(tt => (
                <Option key={tt.id} value={tt.id}>
                    {t(tt.translateKey)}
                </Option>
            ))}
        </CustomSelect>
    );
};

TripTypeSelect.defaultProps = {
    disabled: false,
    mutliple: false,
};

export default TripTypeSelect;
