import React, {useEffect} from "react";
import GenericTable from "../common/table/GenericTable";
import TableTypes from "../../models/TableTypes";
import {useSelector} from "react-redux";

import PropTypes from "prop-types";
import {getAllEventsWithDateTime, timeLogEventType} from "../../models/TimeLogEventType";

const DayTimeRecordingTable = props => {
    const {onClick} = props;
    const timeLogs = useSelector(state => state.timeRecording.entities);

    const mappedData = [];
    timeLogs.forEach(user => {
        let name = user.user.name;

        if (user.timeLog && user.timeLog.length > 0) {
            user.timeLog.forEach(timeLog => {
                    let errors = [];
                    if (user.timeLog.length > 1) errors.push({name: "Mehrere Startzeitpunkte"});

                    if (timeLog.eventsAfterFinish)
                        errors.push({name: "Einträge nach dem Ende des Arbeitstages"});

                    if (timeLog.eventsBeforeStart)
                        errors.push({name: "Einträge vor dem Beginn des Arbeitstages"});

                    if (timeLog.finishBreakWithoutStart)
                        errors.push({name: "Ruhepause wurde fehlerhaft verwendet"})

                    if (timeLog.finishTripWithoutStart)
                        errors.push({name: "Fahrzeit ohne Start"})

                    const paredEvents = JSON.parse(timeLog.stringifiedEvents).sort(function (a, b) {
                        return new Date(a.OccurredAt - new Date(b.OccurredAt));
                    });

                    mappedData.push({
                        id: timeLog.id,
                        name: name,
                        userId: user.user.id,
                        from: timeLog.startedAt,
                        to: timeLog.finishedAt,
                        valid: errors,
                        break: getAllEventsWithDateTime(paredEvents),
                        drivingBreak: getAllEventsWithDateTime(paredEvents, timeLogEventType.StartOfDrivingBreak, timeLogEventType.EndOfDrivingBreak),
                        trips: getAllEventsWithDateTime(paredEvents, timeLogEventType.StartOfTrip, timeLogEventType.EndOfTrip),
                        description: timeLog.description,
                    });

                }
            )
        } else {

            mappedData.push({
                id: undefined,
                name: name,
                valid: [{name: "Keine Aufzeichnung"}],
                userId: user.user.id,
            })
        }
    })

    const columnInformations = [
        {title: "Mitarbeiter", key: "name"},
        {title: "Von", key: "from", type: TableTypes.dateTime},
        {title: "Bis", key: "to", type: TableTypes.dateTime},
        {title: "Ruhepause", key: "break"},
        {title: "Lenkpause", key: "drivingBreak"},
        {title: "Fahrten", key: "trips"},
        {title: "Anmerkung", key: "description"},
        {title: "Überprüfung", key: "valid", type: TableTypes.group}
    ];

    const actions = [
        {
            title: "Anzeigen",
            event: onClick
        }
    ];

    return <GenericTable
        data={mappedData}
        columnInformations={columnInformations}
        actions={actions}
        showActions
        onRowClick={onClick}
    />


}
// DayTimeRecordingTable.propTypes = {
//     page: PropTypes.number,
//     rowsPerPage: PropTypes.number.isRequired,
//     handleChangePage: PropTypes.func.isRequired,
//     handleChangeRowsPerPage: PropTypes.func.isRequired,
//     onClick: PropTypes.func.isRequired,
// };

export default DayTimeRecordingTable;