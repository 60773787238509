import { AnyAction } from "redux";

import { languages } from "../models/Language";

import { receiveStatus, setLanguage } from "../actions/actionCommon";

export type CommonState = {
  version: number;
};

const COMMON_INITIAL_STATE: CommonState = {
  version: 0,
};

export const status = (state = COMMON_INITIAL_STATE, action: AnyAction) => {
  if (receiveStatus.match(action)) {
    return action.data;
  }

  return state;
};

export const language = (state = languages.German, action: AnyAction) => {
  if (setLanguage.match(action)) {
    return action.data;
  }

  return state;
};
