import React from "react";
import {connect} from "react-redux";
import GenericTable from "../../common/table/GenericTable";
import TableTypes from "../../../models/TableTypes";

const RawDataTripsTable = ({trips}) => {
    const columnInformations = [
        {title: "Carname", key: "vehicle.name"},
        {title: "Driver", key: "driver.name"},
        {title: "StartedAt", key: "startedAt", type: TableTypes.dateTime},
        {title: "FinishedAt", key: "finishedAt", type: TableTypes.dateTime},
        {title: "StartAddress", key: "startAddress"},
        {title: "DestinationAddress", key: "destinationAddress"},
        {title: "Mileage", key: "mileageInMeters"},
        {title: "Type", key: "type"},
        {title: "Agent", key: "agent"},
        {title: "Description", key: "description"},
        {title: "Locked at", key: "lockedAt"},
    ];
    return <GenericTable data={trips} columnInformations={columnInformations}/>;
};

function mapStateToProp(state) {
    return {
        trips: state.rawdata.trips.entities,
    };
}

export default connect(mapStateToProp)(RawDataTripsTable);
