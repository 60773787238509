import { call, select, put, takeLatest, takeEvery } from 'redux-saga/effects';

import {
    receiveGeofencesNameId,
    REQUEST_UPDATE_GEOFENCE_VEHICLES,
    REQUEST_GEOFENCES_NAME_ID,
    REQUEST_GEOFENCE_SUBSCRIPTION_DATE,
    receiveGeofenceSubscriptionDate,
    receiveAssignedGeofenceVehicles,
    receiveUnAssignedGeofenceVehicles,
    REQUEST_ASSIGNED_GEOFENCE_VEHICLES,
    REQUEST_UNASSIGNED_GEOFENCE_VEHICLES,
    receiveGeofencesOfCurrentVehicle,
    REQUEST_GEOFENCES_OF_CURRENT_VEHICLE,
    receiveGeofencesEventsOfCurrentTrip,
    REQUEST_GEOFENCES_EVENTS_OF_CURRENT_TRIP,
    REQUEST_GEOFENCES_OF_CURRENT_TRIP,
    receiveGeofencesOfCurrentTrip,
    REQUEST_GEOFENCES_EVENT_TRIPS,
    receiveGeofenceToggleArchive,
    REQUEST_GEOFENCE_TOGGLE_ARCHIVE,
    receiveGeofenceAnalysisToggleArchive,
    REQUEST_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE,
    REQUEST_CREATE_GEOFENCE_ANALYSIS,
    REQUEST_EDIT_GEOFENCE_ANALYSIS,
    receiveGeofenceAnalyses,
    REQUEST_GEOFENCE_ANALYSES,
    receiveGeofenceAnalysisEvents,
    REQUEST_GEOFENCE_ANALYSIS_EVENTS,
    REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING,
    receiveGeofenceAnalysisVehiclesEntering,
    REQUEST_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED,
    receiveGeofenceAnalysisUniqueVehiclesEntered,
    REQUEST_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS,
    receiveGeofenceAnalysisTotalEntryEvents,
    REQUEST_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE,
    receiveGeofenceAnalysisTotalTimeInside,
    REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION,
    receiveGeofenceAnalysisVehiclesEnteringDuration,
} from "../actions/actionGeofences";
import { receiveTrips } from '../actions/actionTrips';
import { handleError } from '../managers/sagaManager';

import {
    updateGeofenceVehicles,
    getGeofencesNameId,
    getGeofenceSubscriptionDate,
    getAssignedGeofenceVehicles,
    getUnAssignedGeofenceVehicles,
    getGeofencesOfCurrentVehicle,
    getGeofencesEventsOfCurrentTrip,
    getGeofencesOfCurrentTrip,
    getGeofencesEventTrips,
    getGeofenceToggleArchive,
    createGeofenceAnalysis,
    editGeofenceAnalysis,
    getGeofenceAnalyses,
    getGeofenceAnalysisEvents,
    getGeofenceAnalysisVehiclesEntering,
    getGeofenceAnalysisUniqueVehiclesEntered,
    getGeofenceAnalysisTotalEntryEvents,
    getGeofenceAnalysisTotalTimeInside,
    getGeofenceAnalysisToggleArchive,
    getGeofenceAnalysisVehiclesEnteringDuration,
} from "./api";

function* getGeofencesOfCurrentTripData(action) {
    try {
        const response = yield call(getGeofencesOfCurrentTrip, action.data);
        if(response.status >=200 && response.status < 300) {
            yield put(receiveGeofencesOfCurrentTrip(response.data))
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}

function* getGeofencesEventsOfCurrentTripData(action) {
    try {
        const response = yield call(getGeofencesEventsOfCurrentTrip, action.data);
        if(response.status >=200 && response.status < 300) {
            yield put(receiveGeofencesEventsOfCurrentTrip(response.data))
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}


function* getGeofencesOfCurrentVehicleData(action) {
    try {
        const response = yield call(getGeofencesOfCurrentVehicle, action.data);
        if(response.status >=200 && response.status < 300) {
            yield put(receiveGeofencesOfCurrentVehicle(response.data))
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}

function* getGeofencesNameIdData(action) {
    try {
        const response = yield call(getGeofencesNameId, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofencesNameId(response.data))
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}

function* updateGeofenceVehiclesData(action) {
    try {
        const assignedGeofenceVehiclesView = yield select(state => state.geofences.assignedGeofenceVehicles);
        const unAssignedGeofenceVehiclesView = yield select(state => state.geofences.unAssignedGeofenceVehicles);
        const geofenceVehiclesView = assignedGeofenceVehiclesView.concat(unAssignedGeofenceVehiclesView);
        const id = action.data;
        const response = yield call(updateGeofenceVehicles, { geofenceVehiclesView, id });
        if (response.status >= 200 && response.status < 300) {

        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* getGeofenceSubscriptionDateData(action) {
    try {
        const response = yield call(getGeofenceSubscriptionDate, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceSubscriptionDate(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* getAssignedGeofenceVehiclesData(action) {
    try {
        const response = yield call(getAssignedGeofenceVehicles, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveAssignedGeofenceVehicles(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}

function* getUnAssignedGeofenceVehiclesData(action) {
    try {
        const response = yield call(getUnAssignedGeofenceVehicles, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveUnAssignedGeofenceVehicles(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}

function* getGeofencesEventTripsData(action) {
    try {
        const response = yield call(getGeofencesEventTrips, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveTrips(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}

function* getGeofenceToggleArchiveData(action) {
    try {
        const response = yield call(getGeofenceToggleArchive, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceToggleArchive(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
} 

function* getGeofenceAnalysisToggleArchiveData(action) {
    try {
        const response = yield call(getGeofenceAnalysisToggleArchive, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceAnalysisToggleArchive(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* createGeofenceAnalysisData(action) {
    try {
        const response = yield call(createGeofenceAnalysis, action.data );
        if (response.status >= 200 && response.status < 300) {

        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
} 

function* editGeofenceAnalysisData(action) {
    try {
        const response = yield call(editGeofenceAnalysis, action.data);
        if (response.status >= 200 && response.status < 300) {

        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error));
    }
}

function* getGeofenceAnalysesData(action) {
        try {

            const response = yield call(getGeofenceAnalyses, action.data);
            if (response.status >= 200 && response.status < 300) {
                yield put(receiveGeofenceAnalyses(response.data));
            } else {
                throw response;
            }
        } catch (error) {
            yield put(handleError(error))
        }
}

function* getGeofenceAnalysisEventsData(action) {
    try {

        const response = yield call(getGeofenceAnalysisEvents, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceAnalysisEvents(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }
}

function* getGeofenceAnalysisVehiclesEnteringData(action) {
    try {

        const response = yield call(getGeofenceAnalysisVehiclesEntering, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceAnalysisVehiclesEntering(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }

}

function* getGeofenceAnalysisUniqueVehiclesEnteredData(action) {
    try {

        const response = yield call(getGeofenceAnalysisUniqueVehiclesEntered, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceAnalysisUniqueVehiclesEntered(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }

}

function* getGeofenceAnalysisTotalEntryEventsData(action) {
    try {

        const response = yield call(getGeofenceAnalysisTotalEntryEvents, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceAnalysisTotalEntryEvents(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }

}

function* getGeofenceAnalysisTotalTimeInsideData(action) {
    try {

        const response = yield call(getGeofenceAnalysisTotalTimeInside, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceAnalysisTotalTimeInside(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }

}

function* getGeofenceAnalysisVehiclesEnteringDurationData(action) {
    try {

        const response = yield call(getGeofenceAnalysisVehiclesEnteringDuration, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield put(receiveGeofenceAnalysisVehiclesEnteringDuration(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(handleError(error))
    }

}

export default function* sagaGeofences() {
    yield takeLatest(REQUEST_GEOFENCES_OF_CURRENT_TRIP, getGeofencesOfCurrentTripData);
    yield takeLatest(REQUEST_GEOFENCE_SUBSCRIPTION_DATE, getGeofenceSubscriptionDateData);
    yield takeLatest(REQUEST_GEOFENCES_EVENTS_OF_CURRENT_TRIP, getGeofencesEventsOfCurrentTripData);
    yield takeLatest(REQUEST_GEOFENCES_OF_CURRENT_VEHICLE, getGeofencesOfCurrentVehicleData);
    yield takeLatest(REQUEST_ASSIGNED_GEOFENCE_VEHICLES, getAssignedGeofenceVehiclesData);
    yield takeLatest(REQUEST_UNASSIGNED_GEOFENCE_VEHICLES, getUnAssignedGeofenceVehiclesData);
    yield takeLatest(REQUEST_GEOFENCES_NAME_ID, getGeofencesNameIdData);
    yield takeLatest(REQUEST_UPDATE_GEOFENCE_VEHICLES, updateGeofenceVehiclesData);
    yield takeLatest(REQUEST_GEOFENCES_EVENT_TRIPS, getGeofencesEventTripsData);
    yield takeLatest(REQUEST_GEOFENCE_TOGGLE_ARCHIVE, getGeofenceToggleArchiveData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSIS_TOGGLE_ARCHIVE, getGeofenceAnalysisToggleArchiveData);
    yield takeLatest(REQUEST_CREATE_GEOFENCE_ANALYSIS, createGeofenceAnalysisData);
    yield takeLatest(REQUEST_EDIT_GEOFENCE_ANALYSIS, editGeofenceAnalysisData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSES, getGeofenceAnalysesData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSIS_EVENTS, getGeofenceAnalysisEventsData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING, getGeofenceAnalysisVehiclesEnteringData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSIS_UNIQUE_VEHICLES_ENTERED, getGeofenceAnalysisUniqueVehiclesEnteredData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSIS_TOTAL_ENTRY_EVENTS, getGeofenceAnalysisTotalEntryEventsData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSIS_TOTAL_TIME_INSIDE, getGeofenceAnalysisTotalTimeInsideData);
    yield takeLatest(REQUEST_GEOFENCE_ANALYSIS_VEHICLES_ENTERING_DURATION, getGeofenceAnalysisVehiclesEnteringDurationData);
    
}