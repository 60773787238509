import React from "react";
import { Button, Grid } from "@material-ui/core";
import Chunk from "./Chunk";

function DistributorThroughputChunks(props) {
  const handleChange = props.onChange;
  const handleDelete = props.onDelete;
  const handleNewChunk = props.onNewChunk;
  const chunks = props.chunks;

  return (
    <Grid item style={{ margin: "10px" }}>
      Distributor throughputs
      {chunks.map((el) => (
        <Chunk
          key={el.id}
          onChange={(input) => handleChange(input, el.id)}
          onHandleDelete={() => handleDelete(el.id)}
          value={el}
        ></Chunk>
      ))}
      <Button variant="contained" color="secondary" onClick={handleNewChunk}>
        New charging throughput
      </Button>
    </Grid>
  );
}

export default DistributorThroughputChunks;
