export const languageKey = "languageKey";
export const compactModeKey = "compactModeKey";
export const introductionLiveLocationKey = "introductionLiveLocationKey";
export const watched = "watched";
export const geofenceAnalysisKey = "geofenceAnalysisKey";
export const vehicleKey = "vehicleKey";
export const userKey = "userKey";
export const calendarKey = "calendarKey";

export const settingKeys = {
    liveLocation: "setting.liveLocation",
    tripPage: "setting.tripPage",
};


export const clearLocalStorage = () => {
    localStorage.clear();
};

export const resetSessionLocalStorage = () => {
    localStorage.removeItem(geofenceAnalysisKey);
    localStorage.removeItem(vehicleKey);
    localStorage.removeItem(userKey);
};