import React, { useState, useEffect } from "react";
import FormModal from "../common/modals/FormModal";
import FormBuilder from "../../managers/formBuilder";
import VehicleSelect from "../common/selects/specificSelects/VehicleSelect";
import CostCenterSelect from "../common/selects/specificSelects/CostCenterSelect";
import ReservationStatusSelect from "../common/selects/specificSelects/ReservationStatusSelect";
import ArchivedReservationSelect from "../common/selects/specificSelects/ArchivedReservationSelect";
import { archivedStatus } from "../../models/AchrivedReservation";
import { reservationStatus } from "../../models/ReservationStatus";
import {  requestFilterReservations, setIsFilterActive } from "../../actions/actionReservation";
import { useDispatch, useSelector } from "react-redux";
import { fromToByPeriod } from "../../managers/timeManager";
import { periods } from "../../models/Period";
import moment from "moment";
import { useTranslation } from "react-i18next";

const FilterReservationsModal = props => {
    const { onClose } = props;
    const dispatch = useDispatch();
    const [hasDefaultValue, setHasDefaultValue] = useState(false);
    
    const isFilterActive = useSelector((state) => state.reservation.activeFilter);
    const { t } = useTranslation();  
    
  useEffect(() => {
    window.addEventListener("beforeunload", function () {
      localStorage.removeItem("reservationFilterItems");
    });
  }, []);

  const getLocalStoreItems = localStorage.getItem("reservationFilterItems");
  const getFilterReservationItems = JSON.parse(getLocalStoreItems);

  const [inputValues, setInputValues] = useState({
    resStatus:
      getFilterReservationItems && getFilterReservationItems.resStatus
        ? getFilterReservationItems.resStatus
        : reservationStatus.All,
    vehicle:
      getFilterReservationItems && getFilterReservationItems.vehicle
        ? getFilterReservationItems.vehicle
        : undefined,
    achive: getFilterReservationItems
      ? getFilterReservationItems.achive
      : archivedStatus.AktiveReservation,
    costCenter: getFilterReservationItems
      ? getFilterReservationItems.costCenter
      : undefined,
  });

  const startTime = fromToByPeriod(periods.day.id).from;
  const endTime = fromToByPeriod(periods.day.id).to;

  const filter = useSelector((state) => state.filter);

  const addOneMoreDay = moment(filter.api.to)
    .local()
    .add(24, "hours")
    .format("YYYY-MM-DD HH:mm:ss");

  useEffect(() => {
    const { resStatus, vehicle, achive, costCenter } = inputValues;

    resStatus === null &&
    typeof vehicle === "undefined" &&
    achive === false &&
    (typeof costCenter === "undefined" || !costCenter.length)
      ? setHasDefaultValue(true)
      : setHasDefaultValue(false);
  }, [inputValues]);

  useEffect(() => {
    hasDefaultValue ? setIsFilterActive(false) : setIsFilterActive(true);
  }, [hasDefaultValue]);

  function handleInputChange(name, value) {
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  }

    function handleFilter() {
      if(!hasDefaultValue) {
        dispatch(
          requestFilterReservations({
            state: inputValues.resStatus,
            isArchived: inputValues.achive ,
            costcenterIds: inputValues.costCenter,
            vehicleId: inputValues.vehicle,
            from: filter.api.from
            ? new Date(filter.api.from).toISOString()
            : new Date(startTime).toISOString(),
            to: filter.api.to
              ? new Date(addOneMoreDay).toISOString()
              : new Date(endTime).toISOString(),
          }, true)
      );
      localStorage.setItem("reservationFilterItems", JSON.stringify(inputValues));
      onClose();

      } else {
        handleClearFilter();
      }
    }

    function handleClearFilter() {
      dispatch(
        requestFilterReservations({
          IsArchived: false,
          from: filter.api.from
          ? new Date(filter.api.from).toISOString()
          : new Date(startTime).toISOString(),
          to: filter.api.to
            ? new Date(addOneMoreDay).toISOString()
            : new Date(endTime).toISOString(),
        }, false)
      )
    }
  
    const inputs = [
      new FormBuilder.FormInput(t("reservation.filterReservationModal.state"), <ReservationStatusSelect value={inputValues.resStatus} allowClear={false} onChange={(e) => handleInputChange("resStatus", e)}/>)
          .withValue(inputValues.resStatus)
          .withKey("reservationStatus")
          .build(),

      new FormBuilder.FormInput(t("reservation.filterReservationModal.vehicle"), <VehicleSelect value={inputValues.vehicle} onChange={(e) => handleInputChange("vehicle", e)}
          />)
          .withValue(inputValues.vehicle)
          .withKey("vehicleSelect")
          .build(),
      
      new FormBuilder.FormInput(t("reservation.filterReservationModal.costCenter"), <CostCenterSelect value={inputValues.costCenter} multiple showArchived={false} onChange={(e) => handleInputChange("costCenter", e)}/>)
          .withValue(inputValues.costCenter)
          .withKey("costcenters")
          .build(),

      new FormBuilder.FormInput(t("reservation.filterReservationModal.archiv"), <ArchivedReservationSelect
        onChange={(e) => handleInputChange("achive", e)}
        value={inputValues.achive}
          multiple/>)
          .withValue(inputValues.achive)
          .withKey("archivedReservation")
          .build(),
  ];

  return (
    <FormModal
      onClose={onClose}
      inputs={inputs}
      onSave={handleFilter}
      title={t("reservation.filterReservationModal.title")}
      isFilterActive={isFilterActive}
      action={"filter"}
      applyButtonStyle
      clearFilter={handleClearFilter}
    />
  );
};

export default FilterReservationsModal;
