import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  DriveBoxGetRequest,
  DriveBoxUpdateRequest,
} from "../types/drivebox/driveboxRequest.types";

import {
  DriveBoxResponse,
  DriveBoxResponseInfo,
  DriveBoxPluggedEventsResponse,
} from "../types/drivebox/driveboxResponse.types";

import {
  RequestDriveboxes,
  ReceiveDriveboxes,
  RequestDriveboxById,
  ReceiveDriveboxById,
  RequestUpdateDrivebox,
  ReceiveUpdateDrivebox,
  RequestPluggedEvents,
  ReceivePluggedEvents,
  SelectDrivebox,
  SelectAllDriveboxes,
  LoadDrivebox,
} from "../types/actions/actionDrivebox.types";

export enum DRIVEBOX_ACTION_TYPES {
  REQUEST_DRIVEBOXES = "REQUEST_DRIVEBOXES",
  RECEIVE_DRIVEBOXES = "RECEIVE_DRIVEBOXES",

  REQUEST_DRIVEBOX_BY_ID = "REQUEST_DRIVEBOX_BY_ID",
  RECEIVE_DRIVEBOX_BY_ID = "RECEIVE_DRIVEBOX_BY_ID",

  REQUEST_UPDATE_DRIVEBOX = "REQUEST_UPDATE_DRIVEBOX",
  RECEIVE_UPDATE_DRIVEBOX = "RECEIVE_UPDATE_DRIVEBOX",

  REQUEST_PLUGGED_EVENTS = "REQUEST_PLUGGED_EVENTS",
  RECEIVE_PLUGGED_EVENTS = "RECEIVE_PLUGGED_EVENTS",

  SELECT_DRIVEBOX = "SELECT_DRIVEBOX",
  SELECT_ALL_DRIVEBOXES = "SELECT_ALL_DRIVEBOXES",
  LOAD_DRIVEBOX = "LOAD_DRIVEBOX",
}

export const requestDriveboxes = withMatcher(
  (data: DriveBoxGetRequest): RequestDriveboxes => {
    return createAction(DRIVEBOX_ACTION_TYPES.REQUEST_DRIVEBOXES, data);
  }
);
export const receiveDriveboxes = withMatcher(
  (data: DriveBoxResponse): ReceiveDriveboxes => {
    return createAction(DRIVEBOX_ACTION_TYPES.RECEIVE_DRIVEBOXES, data);
  }
);

export const requestDriveboxById = withMatcher(
  (driveboxId: string): RequestDriveboxById => {
    return createAction(
      DRIVEBOX_ACTION_TYPES.REQUEST_DRIVEBOX_BY_ID,
      driveboxId
    );
  }
);

export const receiveDriveboxById = withMatcher(
  (data: DriveBoxResponseInfo): ReceiveDriveboxById => {
    return createAction(DRIVEBOX_ACTION_TYPES.RECEIVE_DRIVEBOX_BY_ID, data);
  }
);

export const requestUpdateDrivebox = withMatcher(
  (data: DriveBoxUpdateRequest): RequestUpdateDrivebox => {
    return createAction(DRIVEBOX_ACTION_TYPES.REQUEST_UPDATE_DRIVEBOX, data);
  }
);

export const receiveUpdateDrivebox = withMatcher(
  (data: DriveBoxResponseInfo): ReceiveUpdateDrivebox => {
    return createAction(DRIVEBOX_ACTION_TYPES.RECEIVE_UPDATE_DRIVEBOX, data);
  }
);

export const requestPluggedEvents = withMatcher(
  (driveboxId: string): RequestPluggedEvents => {
    return createAction(
      DRIVEBOX_ACTION_TYPES.REQUEST_PLUGGED_EVENTS,
      driveboxId
    );
  }
);

export const receivePluggedEvents = withMatcher(
  (data: DriveBoxPluggedEventsResponse[]): ReceivePluggedEvents => {
    return createAction(DRIVEBOX_ACTION_TYPES.RECEIVE_PLUGGED_EVENTS, data);
  }
);

export const selectDrivebox = withMatcher(
  (data: DriveBoxResponseInfo): SelectDrivebox => {
    return createAction(DRIVEBOX_ACTION_TYPES.SELECT_DRIVEBOX, data);
  }
);

export const selectAllDriveboxes = withMatcher((): SelectAllDriveboxes => {
  return createAction(DRIVEBOX_ACTION_TYPES.SELECT_ALL_DRIVEBOXES);
});

export const loadDrivebox = withMatcher((): LoadDrivebox => {
  return createAction(DRIVEBOX_ACTION_TYPES.LOAD_DRIVEBOX);
});
