import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import LoadingIndicator from "../common/LoadingIndicator";
import Typography from "@material-ui/core/Typography";

import {
  requestAuthentication,
  requestLogout,
  receiveLogout,
} from "../../actions/actionAuthentication";
import { setError, clearError } from "../../actions/actionCommon";

import DriveboxLogo from "../../images/DriveboxLogo.svg";
import ErrorToolbar from "../common/toolbar/ErrorToolbar";
import useLoginStyles from "./useLoginStyles";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { useModalStatus } from "../../hooks/useModalStatus";
import { requestStatus } from "../../actions/actionCommon";
import { useTranslation } from "react-i18next";

function getCookie(name) {
  const dc = document.cookie;
  const prefix = name + "=";
  let begin = dc.indexOf("; " + prefix);
  if (begin === -1) {
    begin = dc.indexOf(prefix);
    if (begin !== 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(";", begin);
    if (end === -1) {
      end = dc.length;
    }
  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
}

const LoginPage = (props) => {

  const {
    modal: forgotPasswordModal,
    open: openForgotPasswordModal,
    close: closeForgotPasswordModal,
  } = useModalStatus();

  const { t } = useTranslation();

  const versionStatus = useSelector((state) => state.status);
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [tryToLogin, setTryToLogin] = useState(false);
  const history = useHistory();

  const classes = useLoginStyles(props);
  const { authorised, me, error } = props;
  const { updatedAt, status, message } = error;

  const ShowPasswordForgotten = window.location.href.includes(
    "hilfswerk.at"
  ) ? (
    <></>
  ) : (
    !tryToLogin && (
      <div className={classes.forgotPasswordContainer}>
        <a className={classes.labelText} onClick={openForgotPasswordModal}>
          {t("login.forgotPassword")}
        </a>
      </div>
    )
  );

  useEffect(() => {
    dispatch(requestStatus());
  }, []);

  useState(() => {
    if (getCookie("Identity")) props.requestLogout();
    else props.receiveLogout();
  }, []);

  useEffect(() => {
    if (authorised && me.id) {
      history.push("/");
    }
  }, [authorised]);

  useEffect(() => {
    setTryToLogin(false);
    if (status === 400 && !message)
      props.setError({
        message: t("login.wrongLogin") ,
      });
  }, [updatedAt]);

  function handleLoginOnEnter(event) {
    if (event.keyCode === 13) {
      login(); //ENTER
    }
  }

  function login() {
    props.clearError();
    setTryToLogin(true);
    const data = {
      username: mail,
      password: password,
      rememberMe: rememberMe,
    };
    props.requestAuthentication(data);
  }

  return (
    <div className={classes.page}>
      <main className={classes.main}>
        <div className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <img
              styles={{ maxWidth: "80%", height: "25px" }}
              className={classes.logo}
              src={DriveboxLogo}
              alt="Drivebox"
            />

            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <label htmlFor="email" className={classes.labelText}>
                  {t("login.email")}
                </label>
                <input
                  className={classes.inputLogin}
                  value={mail}
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setMail(e.target.value)}
                  onKeyDown={handleLoginOnEnter}
                ></input>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <label htmlFor="password" className={classes.labelText}>
                  {t("login.password")}
                </label>
                <input
                  className={classes.inputLogin}
                  value={password}
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleLoginOnEnter}
                ></input>
              </FormControl>
              <FormControlLabel
                className={classes.rememberMe}
                control={
                  <Checkbox
                    value="remember"
                    checked={rememberMe}
                    color="primary"
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                }
                label={t("login.rememberMe")}
              />
            </form>
            {tryToLogin && <LoadingIndicator />}
            {!tryToLogin && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={login}
              >
                {t("login.login")}
              </Button>
            )}
            {ShowPasswordForgotten}
            <ErrorToolbar className={classes.error} />
          </Paper>
        </div>
        {forgotPasswordModal && (
          <ForgotPasswordModal onClose={closeForgotPasswordModal} mail={mail} />
        )}
      </main>

      <Paper className={classes.version} elevation={6}>
        <Typography variant="body2">
          {"Version: " + versionStatus.version}
        </Typography>
      </Paper>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestAuthentication,
      requestLogout,
      receiveLogout,
      setError,
      clearError,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    authorised: state.authorised,
    me: state.users.me,
    error: state.error,
  };
}

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(LoginPage);
