import React from "react"
import CustomModal from "../../../common/modals/CustomModal";
import { connect } from "react-redux";
import moment from "moment";

const LastSignalModal = (props) => {

    const {onClose, lastPosition, loading} = props;

    const lastPositionDateTime = moment(lastPosition.timeStamp).format('DD MMMM YYYY, h:mm:ss a');

    const content = [
        <>
            <h6><strong>Date and Time: </strong>{lastPositionDateTime}</h6>
            <h6><strong>Latitude: </strong>{lastPosition.latitude}</h6>
            <h6><strong>Longitude: </strong>{lastPosition.longitude}</h6>
        </>
    ]

    return (
    <CustomModal contents={content} onClose={onClose} title={"Last Signal"} loading={loading}/>
    )}

    function mapStateToProp(state){
        return {
            lastPosition: state.driveboxes.lastSignal,
            loading: state.driveboxes.loading
        }
    }

export default  connect(mapStateToProp)(LastSignalModal);