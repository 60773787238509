import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
//Icon
import DeleteIcon from "@material-ui/icons/Delete";

const MandatorAddModalDriveboxesTable = ({data, onNameChange, onRemove}) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>IMEI</TableCell>
                    <TableCell>VehicleName</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.length && data.map(row => (
                    <TableRow key={row.imei}>
                        <TableCell component="th" scope="row">
                            {row.imei}
                        </TableCell>
                        <TableCell>
                            <TextField value={row.vehicleName} onChange={e => onNameChange(row.imei, e.target.value)}/>
                        </TableCell>
                        <TableCell align="right">
                            <Tooltip title="Delete" aria-label="Delete">
                                <IconButton onClick={e => onRemove(row.imei)} aria-label="Delete">
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

// MandatorAddModalDriveboxesTable.propTypes = {
//     data: PropTypes.isRequired,
//     onNameChange: PropTypes.func.isRequired,
//     onRemove: PropTypes.func.isRequired,
// };

export default MandatorAddModalDriveboxesTable;
