import React, { useEffect } from "react";
import CustomModal from "./CustomModal";
import { Collapse, Form } from "antd";

import { makeStyles } from "@material-ui/core";

import { Box } from "@material-ui/core";

import FormInput from "../inputs/FormInput";
import { useValidateForm } from "../../../hooks/useValidateForm";
import { MODAL_TYPES } from "./modal.utils";

const formItemLayout = {
  labelCol: {
    md: { span: 24 },
    lg: { span: 8 },
  },
  wrapperCol: {
    md: { span: 24 },
    lg: { span: 16 },
  },
};

const formWithIconLayout = {
  labelCol: {
    md: { span: 24 },
    lg: { span: 7 },
  },
  wrapperCol: {
    md: { span: 24 },
    lg: { span: 17 },
  },
};


const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const useStyles = makeStyles({
  center: {
    ...center
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  formStyle: {
    marginTop: "28px",
    marginBottom: "30px"
  },
  textCenter: {
    marginTop: "3px",
    textAlign: "center"
  }
});

const FormModal = (props) => {
  const [form] = Form.useForm();
  const { submittable } = useValidateForm(form);
  const classes = useStyles();

  const {
    title,
    onClose,
    inputs,
    action,
    showSubGroups,
    loading,
    onUpdate,
    collapseTranslation,
    applyButtonStyle,
    clearFilter,
    isFilterActive,
    onSave,
    validateInputs,
    customRuleMessage,
    formType,
    formIcon,
    formDescription
  } = props;

  useEffect(() => {
    updateInputValues();
  }, [inputs]);

  function updateInputValues() {
    if (onUpdate) onUpdate(form.getFieldsValue());

    // TODO danger async zone
    inputs.forEach((input) => {
      if (typeof input.value != "undefined") {
        form && form.setFieldsValue({ [input.key]: input.value });
      }
    });
  }

  const onHandleSave = () => {
    if (submittable || formType === MODAL_TYPES.withoutValidation) {
      onSave();
    } else {
      const { validateFields } = form;
      validateFields({});
    }
  };

  function getFormItem(input, idx) {
    let rules = [];
    if (input.validation.type) rules.push({ type: input.validation.type });
    if (input.validation.required)
      rules.push({
        required: input.validation.required,
        message: input.validation.description,
      });
    if (validateInputs && input.customRule) {
      rules.push(input.customRule);
      return (
        <Form.Item
          name={input.key}
          rules={!input.disabled ? rules : []}
          valuePropName={input.valuePropName || "value"}
          label={input.name}
          trigger={input.triggerName || "onChange"}
          key={`form-modal-${input.key}-${idx}`}
          {...validateInputs(customRuleMessage, "warning")}
        >
          {input.component}
        </Form.Item>
      );
    }

    const component = input.component;

    return input.hide ? (
      <FormInput
        key={`form-modal-${input.key}-${idx}`}
        name={input.key}
        valuePropName={input.valuePropName || "value"}
        rules={!input.disabled ? rules : []}
        trigger={input.triggerName || "onChange"}
        label={input.name}
      >
        {component}
      </FormInput>
    ) : (
      <div />
    );
  }

  function buildWithCollapse(inputs) {
    let collapse = [];
    inputs.forEach((input) => {
      if (input.subGroup) {
        collapse[input.subGroup] = collapse[input.subGroup] || [];
        collapse[input.subGroup].push(input);
      }
    });

    const keys = Object.keys(collapse).map((g, i) => i);
    return (
      <Collapse defaultActiveKey={keys} bordered={false}>
        {Object.keys(collapse).map((group, i) => (
          <Collapse.Panel key={i} header={collapseTranslation[group]}>
            {collapse[group].map((input, idx) => getFormItem(input, idx))}
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  }

  const formWithIcon = [
    <Box className={classes.container}>
      <Box className={classes.center}>{formIcon}</Box>
      <Box className={classes.textCenter}>{formDescription}</Box>
      <Form  form={form}  className={classes.formStyle}>
        {showSubGroups
          ? buildWithCollapse(inputs)
          : inputs.map((i) => getFormItem(i))}
      </Form>
    </Box>,
  ];

  const formWithoutIcon = [
    <Form form={form} {...formItemLayout}>
      {showSubGroups
        ? buildWithCollapse(inputs)
        : inputs.map((i) => getFormItem(i))}
    </Form>,
  ];
  return (
    <CustomModal
      loading={loading}
      action={action || "update"}
      onSave={onHandleSave}
      onClose={onClose}
      title={title}
      contents={formIcon ? formWithIcon : formWithoutIcon}
      applyButtonStyle={applyButtonStyle}
      clearFilter={clearFilter}
      isFilterActive={isFilterActive}
    />
  );
};

export default FormModal;
