import { withMatcher, createAction } from "../../utils/reducer/reducer.utils";

import { RequestChargeStationById, RequestChargeStations, ReceiveChargeStationById, ReceiveChargeStations, RequestStartConnectionToEvo, RequestStopConnectionToEvo } from "../../types/actions/qualityCharging/actionChargeStations.types";

export enum CHARGE_STATION_ACTION_TYPES {
  REQUEST_CHARGE_STATIONS = "REQUEST_CHARGE_STATIONS",
  RECEIVE_CHARGE_STATIONS = "RECEIVE_CHARGE_STATIONS",

  REQUEST_CHARGE_STATION_BY_ID = "REQUEST_CHARGE_STATION_BY_ID",
  RECEIVE_CHARGE_STATION_BY_ID = "RECEIVE_CHARGE_STATION_BY_ID",

  REQUEST_START_CONNECTION_TO_EVO = "REQUEST_START_CONNECTION_TO_EVO",
  REQUEST_STOP_CONNECTION_TO_EVO = "REQUEST_STOP_CONNECTION_TO_EVO"
}

// Type "any" needs to change to a more specific type later
export const requestChargeStations = withMatcher(
  (data: any): RequestChargeStations => {
    return createAction(CHARGE_STATION_ACTION_TYPES.REQUEST_CHARGE_STATIONS, data);
  }
);

export const receiveChargeStations = withMatcher(
  (data: any): ReceiveChargeStations => {
    return createAction(CHARGE_STATION_ACTION_TYPES.RECEIVE_CHARGE_STATIONS, data);
  }
);

export const requestChargeStationById = withMatcher((data: string): RequestChargeStationById => {
  return createAction(CHARGE_STATION_ACTION_TYPES.REQUEST_CHARGE_STATION_BY_ID, data);
});

export const receiveChargeStationById = withMatcher((data: any): ReceiveChargeStationById => {
  return createAction(CHARGE_STATION_ACTION_TYPES.RECEIVE_CHARGE_STATION_BY_ID, data);
});

export const requestStartConnectionToEvo = withMatcher((): RequestStartConnectionToEvo => {
  return createAction(CHARGE_STATION_ACTION_TYPES.REQUEST_START_CONNECTION_TO_EVO);
});

export const requestStopConnectionToEvo = withMatcher((): RequestStopConnectionToEvo => {
  return createAction(CHARGE_STATION_ACTION_TYPES.REQUEST_STOP_CONNECTION_TO_EVO);
});


