import React, { useEffect, useMemo, useRef, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Map from "../common/map/Map";

import { withStyles } from "@material-ui/core/styles";
import { requestVehicleById, selectVehicle } from "../../actions/actionVehicle";
import { requestLiveLocation } from "../../actions/actionLiveLocation";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import Direction from "../../icons/direction.svg";
import LoadingIndicator from "../common/LoadingIndicator";
import VehicleDesignation from "../../models/VehicleDesignation";

const styles = theme => ({});

const LiveLocationMap = props => {
    const { settings, vehicles, requestLiveLocation, filter, me, lastLocations, loading, clickedVehicle } = props;

    const getVehicleIcon = (moving, heading) => {
        return divIcon({
            html: renderToStaticMarkup(
                <div className={moving ? "container-circle pulsating-circle" : "container-circle"}>
                    <img style={{ transform: "rotate(" + heading + "deg)" }} className="navigationIcon" src={Direction} />
                </div>,
            ),
        });
    };

    const isCarDriving = (speed) => {
        return speed > 0;
    };

    useEffect(() => {
        requestLiveLocation(filter);
    }, [filter]);

    useEffect(() => {
        setTimeout(() => requestLiveLocation(filter), me.liveLocationIntervalInSeconds * 1000 || 30000);
    }, [lastLocations]);

    function renderPopupContent(pos) {
        if (clickedVehicle && !loading) {
            let driver = clickedVehicle.driver != null ? clickedVehicle.driver.name : "Kein Fahrer zugeteilt";
            return (
                <div>
                    Fahrzeugname: {clickedVehicle.name} <br />
                    Kennzeichen: {clickedVehicle.licensePlate} <br />
                    Fahrer: {driver} <br />
                    Datum: {new Date(pos.deviceTime).toLocaleString()} <br />
                </div>
            );
        } else {
            return <LoadingIndicator />;
        }
    }

    function getTooltip(pos) {
        const speed = settings.showSpeed && isCarDriving(pos.speed) ? " (" + pos.speed + " km/h)" : "";
        switch (settings.tooltipText) {
            case VehicleDesignation.licensePlate:
                return (
                    <Tooltip direction="bottom" permanent>
                        {pos.licensePlate} {speed}
                    </Tooltip>
                );
            case VehicleDesignation.name:
                return (
                    <Tooltip direction="bottom" permanent>
                        {pos.name} {speed}
                    </Tooltip>
                );
            default:
                return <div />;
        }
    }
    const [isPopupShown, setIsPopupShown] = useState(false);
    // set the popup after the map is refreshed (refreshed becuase we send a new request and we get new data)
    useEffect(() => {
        if (isPopupShown) {
            const markerToOpen = markerRefs.current[clickedVehicle.id];
            var popUpOpened = markerToOpen?.openPopup();
        }
    });

    function onPopupOpen(id) {
        setIsPopupShown(false);
        // send request only if we are clicking another vehicle and not the one that we already have info
        if (clickedVehicle.id !== id) {
            props.requestVehicleById(id);
        }
        setIsPopupShown(true);
    }
    // create refs for markers
    const markerRefs = useRef({});

    return <Map showLiveLocations showLockAction settings={settings} loading={loading}>
        {vehicles.map(pos => {
            var posObj = { lat: pos.latitude, lng: pos.longitude };
            const vehicleIcon = getVehicleIcon(isCarDriving(pos.speed), pos.heading);
            if (settings.showStandingVehicles || isCarDriving(pos.speed))
                return (
                    <Marker key={pos.id} position={posObj} icon={vehicleIcon}
                        ref={(m) => {  // <--- add marker refs to ref each marker so that we can open it again after sending request for fetching data
                            markerRefs.current[pos.id] = m;
                        }}
                    >
                        {getTooltip(pos)}
                        <Popup offset={[0, -23]} autoPan={false} keepInView={true} onClose={() => {
                            setIsPopupShown(false);
                            clickedVehicle.id = null;
                        }}
                            onOpen={() => onPopupOpen(pos.id)}>
                            {renderPopupContent(pos)}
                        </Popup>
                    </Marker>
                );
        })}
    </Map>;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestVehicleById,
            requestLiveLocation,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        lastLocations: state.lastVehiclesLocation.entities,
        loading: state.lastVehiclesLocation.loading,
        filter: state.filter.api,
        clickedVehicle: state.vehicles.current,
        vehicles: state.lastVehiclesLocation.entities,
        me: state.users.me,
    };
}

// LiveLocationMap.propTypes = {
//     settings: PropTypes.object,
// };


export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(LiveLocationMap));
