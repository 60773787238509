import React from "react";
import {connect, useSelector, shallowEqual} from "react-redux";
import GenericTable from "../common/table/GenericTable";
import TableTypes from "../../models/TableTypes";
import Comparator from "../../models/Comparator";
import {allowedRoles, isAuthorized, isViewer} from "../../managers/authManager";
import { useTranslation } from "react-i18next";

const CostCenterTable = props => {
    const costcenters = useSelector(state => state.costcenters.entities);

    const selected = useSelector(state => state.costcenters.selected);
    const total = useSelector(state => state.costcenters.total);
    const me = useSelector(state => state.costcenters.me);

    const {
        archive,
        page,
        rowsPerPage,
        onEdit,
        onSelect,
        onSelectAll,
        onPageChange,
        onRowsPerPageChange,
        onArchive,
        onUnArchive,
    } = props;

    const { t } = useTranslation();

    const viewer = isViewer(me);

    const columnInformations = [
        { title: t("costCenter.table.foreignKey"), key: "foreignId" },
        { title: t("costCenter.table.description"), key: "description" },
    ];

    const archivedColumnInformations = [
        { title: t("costCenter.table.foreignKey"), key: "foreignId" },
        { title: t("costCenter.table.description"), key: "description" },
    ];

    const actions = [
        {title: t("user.table.edit"), event: onEdit},
        {
            title: t("user.table.archive"),
            event: onArchive,
        }
    ];

    const archivedActions = [
        {title: t("user.table.edit"), event: onEdit},
        {
            title: t("user.table.deArchive"),
            event: onUnArchive,
        },
    ]

    return (
        <GenericTable
            data={costcenters}
            columnInformations={archive ? archivedColumnInformations : columnInformations}
            actions={archive ? archivedActions : actions}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            onEdit={onEdit}
            onRowClick={onEdit}
            handleChangePage={onPageChange}
            handleChangeRowsPerPage={onRowsPerPageChange}
            onSelectAllClick={onSelectAll}
            onSelect={onSelect}
            numSelected={selected}
            showActions
            //showCheckboxes
        />        
    );
};

CostCenterTable.defaultProps = {
    page: 0,
};

export default CostCenterTable;
