import { Marker } from "react-leaflet";
import React from 'react';
function PolygonMarker(props) {
    let { icon, position, hidden } = props;

    return !hidden ? <Marker
        position={position}
        icon={icon}
    ></Marker> : <></>
}

export default PolygonMarker;