import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import GenericTable from "../../common/table/GenericTable";

const MandatorUserTable = props => {
    const {users, total, page, rowsPerPage, surrogateUser, handleChangePage, handleChangeRowsPerPage, onWelcomeMail, archived, vehicleRegistration, lastLogIns} = props;
    const columnInformations = [
        {title: "Email", key: "email"},
        {title: "Firstname", key: "firstName"},
        {title: "Lastname", key: "lastName"},
        {title: "Description", key: "description"},
        {title: "Rolle", key: "role.name"},
        {title: "Mandator", key: "mandator.name"},
    ];
    
    const actions = [{title: "Welcome mail", event: onWelcomeMail}, {title: "Surrogate", event: surrogateUser}, {title: "Vehicle Registration", event: vehicleRegistration}, {title: "Last Logins", event: lastLogIns}];
        
    return (
        <GenericTable
            data={users}
            actions={archived ? [] : actions}
            columnInformations={columnInformations}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            showActions
        />
    );
};

// MandatorUserTable.propTypes = {
//     requestMandatorUsers: PropTypes.func,
//     surrogateUser: PropTypes.func.isRequired,
//     handleChangePage: PropTypes.func.isRequired,
//     handleChangeRowsPerPage: PropTypes.func.isRequired,
//     onWelcomeMail: PropTypes.func.isRequired,
//     archived: PropTypes.bool.isRequired,
// };

function mapStateToProp(state) {
    return {
        users: state.users.entities,
        total: state.users.total,
    };
}

export default connect(mapStateToProp)(MandatorUserTable);
