import { withMatcher, createAction } from "../../utils/reducer/reducer.utils";

import { RequestUnrealisticTrips, ReceiveUnrealisticTrips } from "../../types/actions/faultyTrips/actionUnrealisticTrips.types";

export enum UNREALISTIC_TRIPS_ACTION_TYPES {
  REQUEST_UNREALISTIC_TRIPS = "REQUEST_UNREALISTIC_TRIPS",
  RECEIVE_UNREALISTIC_TRIPS = "RECEIVE_UNREALISTIC_TRIPS",
}
 
// Type "any" needs to change to a more specific type later
export const requestUnrealisticTrips = withMatcher(
  (data: any): RequestUnrealisticTrips => {
    return createAction(UNREALISTIC_TRIPS_ACTION_TYPES.REQUEST_UNREALISTIC_TRIPS, data);
  }
);

export const receiveUnrealisticTrips = withMatcher(
  (data: any): ReceiveUnrealisticTrips => {
    return createAction(UNREALISTIC_TRIPS_ACTION_TYPES.RECEIVE_UNREALISTIC_TRIPS, data);
  }
);

