import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";

function Chunk(props) {
  const handleDelete = props.onHandleDelete;

  return (
    <Grid item style={{ marginLeft: "10px" }}>
      <TextField
        id="time"
        label="Start time"
        type="text"
        onChange={props.onChange}
        value={props.value.time}
        style={{ margin: "3px" }}
      />
      <TextField
        id="throughput"
        label="Throughput"
        type="number"
        onChange={props.onChange}
        value={props.value.throughput}
        style={{ margin: "3px" }}
      />
      <Button onClick={handleDelete} variant="contained" color="error">
        Delete
      </Button>
    </Grid>
  );
}

export default Chunk;
