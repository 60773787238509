import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Page from "../common/container/Page";
import Toolbar from "../common/toolbar/Toolbar";
import MonthPicker from "../common/selects/datePickers/MonthPicker";
import { menu } from "../../managers/pathManager";
import { fromToByPeriod } from "../../managers/timeManager";
import { periods } from "../../models/Period";
import DashboardTabs from "../dashboard/dashboardTab/DashboardTabs";
import { useDispatch, useSelector } from "react-redux";
import NoDriveBoxDataTable from "./NoDriveBoxDataTable";
import LockedTripsByGroupTable from "./LockedTripsByGroupTable";
import { requestTripReport } from "../../actions/actionReport";
import WithoutDriverTripsByGroupTable from "./WithoutDriverTripsByGroupTable";
import { updateFilterAPICall } from "../../actions/actionFilter";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: "0 3px 4px 3px",
  },
  card: {
    marginTop: "2px",
    marginBottom: "2px",
  },
}));

function HWNODashboard() {
  const classes = useStyles();
  const [start, setStart] = useState(new Date());
  const me = useSelector((state) => state.users.me);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const requestParams = fromToByPeriod(periods.month.id, new Date(start));
  requestParams.from = requestParams.from.toISOString();
  requestParams.to = requestParams.to.toISOString();

  useEffect(() => {
    dispatch(requestTripReport(requestParams));
  }, [start]);

  function handleDateChange(start, end) {
    setStart(start);
  }

  useEffect(() => {
    dispatch(
      updateFilterAPICall({
        from: requestParams.from
          ? new Date(requestParams.from).toISOString()
          : new Date("1760-01-01T00:00:00Z").toISOString(),
        to: requestParams.to
          ? new Date(requestParams.to).toISOString()
          : new Date("9999-01-01T00:00:00Z").toISOString(),
        period: periods.month.id,
      })
    );
  }, [start]);

  const toolbar = (
    <Toolbar
      title={
        <>
          {t("dashboard.page.welcomeBack")} {me.firstName}
        </>
      }
      controls={[<MonthPicker value={start} onDateChange={handleDateChange} />]}
    />
  );

  return (
    <Page activePage={menu.hwnodashboard.key} toolbar={toolbar}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.contentContainer}
        spacing={1}
      >
        <Grid item xs={12} md={6} className={classes.card}>
          <LockedTripsByGroupTable />
        </Grid>
        <Grid item xs={12} md={6} className={classes.card}>
          <WithoutDriverTripsByGroupTable />
        </Grid>
        <Grid item xs={12} md={6} className={classes.card}>
          <DashboardTabs date={requestParams} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.card}>
          <NoDriveBoxDataTable />
        </Grid>
      </Grid>
    </Page>
  );
}

export default HWNODashboard;
