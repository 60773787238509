import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress'; 

export default function SimpleBackdrop(props) {
  let {isOpen, subText, dockSize} = props;

  return (
    <div>
      <Backdrop style={{ width: (window.innerWidth - dockSize) }} className='materialBackdrop' open={isOpen}>
          <CircularProgress className='materialBackdrop-spinner' color="inherit" />
          <p>{subText}</p>
      </Backdrop>
    </div>
  );
}
