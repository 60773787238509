import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestVehicleService,
  ReceiveVehicleService,
  RequestNextVehicleService,
  ReceiveNextVehicleService,
  RequestAddVehicleService,
  ReceiveAddVehicleService,
  RequestDeleteVehicleService,
  ReceiveDeleteVehicleService,
} from "../types/actions/actionVehicleService.types";

import { VehicleAddServiceRequest } from "../types/vehicleService/vehicleServiceRequest.types";
import { VehicleServiceResponse } from "../types/vehicleService/vehicleServiceResponse.types";

export enum VEHICLE_SERVICE_ACTION_TYPES {
  REQUEST_VEHICLE_SERVICE = "REQUEST_VEHICLE_SERVICE",
  RECEIVE_VEHICLE_SERVICE = "RECEIVE_VEHICLE_SERVICE",

  REQUEST_NEXT_VEHICLE_SERVICE = "REQUEST_NEXT_VEHICLE_SERVICE",
  RECEIVE_NEXT_VEHICLE_SERVICE = "RECEIVE_NEXT_VEHICLE_SERVICE",

  REQUEST_ADD_VEHICLE_SERVICE = "REQUEST_ADD_VEHICLE_SERVICE",
  RECEIVE_ADD_VEHICLE_SERVICE = "RECEIVE_ADD_VEHICLE_SERVICE",

  REQUEST_DELETE_VEHICLE_SERVICE = "REQUEST_DELETE_VEHICLE_SERVICE",
  RECEIVE_DELETE_VEHICLE_SERVICE = "RECEIVE_DELETE_VEHICLE_SERVICE",
}

export const requestVehicleService = withMatcher(
  (data: string): RequestVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.REQUEST_VEHICLE_SERVICE,
      data
    );
  }
);

export const receiveVehicleService = withMatcher(
  (data: VehicleServiceResponse): ReceiveVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.RECEIVE_VEHICLE_SERVICE,
      data
    );
  }
);

export const requestNextVehicleService = withMatcher(
  (data: string): RequestNextVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.REQUEST_NEXT_VEHICLE_SERVICE,
      data
    );
  }
);

export const receiveNextVehicleService = withMatcher(
  (data: number): ReceiveNextVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.RECEIVE_NEXT_VEHICLE_SERVICE,
      data
    );
  }
);

export const requestAddVehicleService = withMatcher(
  (data: VehicleAddServiceRequest): RequestAddVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.REQUEST_ADD_VEHICLE_SERVICE,
      data
    );
  }
);

export const receiveAddVehicleService = withMatcher(
  (data: VehicleServiceResponse): ReceiveAddVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.RECEIVE_ADD_VEHICLE_SERVICE,
      data
    );
  }
);

export const requestDeleteVehicleService = withMatcher(
  (vehicleServiceId: string): RequestDeleteVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.REQUEST_DELETE_VEHICLE_SERVICE,
      vehicleServiceId
    );
  }
);

export const receiveDeleteVehicleService = withMatcher(
  (data: VehicleServiceResponse): ReceiveDeleteVehicleService => {
    return createAction(
      VEHICLE_SERVICE_ACTION_TYPES.RECEIVE_DELETE_VEHICLE_SERVICE,
      data
    );
  }
);
