import React from 'react';
import CustomModal from '../../common/modals/CustomModal';
import { modalActions } from '../../common/modals/CustomModal';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestLockTrips } from '../../../actions/actionTrips';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles({
    textCenter: {
        textAlign: "center"
    }});

const TripLockModal = (props) => {

    const { onClose, title, type, data } = props;
    const { t } = useTranslation();

    const classes = useStyles();
    function lockTrip() {
        props.requestLockTrips({ tripIds: [data.id] });
        onClose();
    }

    const content = [
        <div>
            <h5 className={classes.textCenter} >{t("trip.lockTripModal.lockTripConfirmMsg")}</h5>
            <Trans><p className={classes.textCenter}>{t("trip.lockTripModal.lockTripModalMsg", {mappedDriver: data.mappedDriver, mappedVehicle :data.mappedVehicle, mappedStartAddress: data.mappedStartAddress, mappedDestinationAddress :data.mappedDestinationAddress})}</p></Trans>
        </div>
    ]

    return (
       <CustomModal onClose={onClose} contents={content} title={title} type={type} action={modalActions.confirm} onSave={lockTrip}/>
    )}


    function mapDispatchToProps(dispatch){
        return bindActionCreators({requestLockTrips}, dispatch)
     }

     export default connect(null, mapDispatchToProps)(TripLockModal);
