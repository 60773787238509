import React from "react";
import PropTypes from "prop-types";
import {log, logArray} from "../../models/Log";
import ReportCard from "../common/views/ReportCard";
import Icon from "@material-ui/core/Icon";

const VehicleReportCard = ({title, value, logType, onClick, loading}) => {
    return <ReportCard
        condensed
        onClick={onClick}
        title={value || ""}
        loading={loading}
        value={title}
        icon={
            <Icon color="secondary" style={{fontSize: 30}}>
                {logType === log.SUCCESS ? "done_outline" : logType === log.ERROR ? "error_outline" : logType === log.WARN ? "warning" : "help_outline"}
            </Icon>
        }
    />
};

// VehicleReportCard.propTypes = {
//     title: PropTypes.oneOfType([
//         PropTypes.string.isRequired,
//         PropTypes.object.isRequired
//     ]),
//     value: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.object
//     ]),
//     logType: PropTypes.oneOf(logArray.map(l => l.id)),
//     loading: PropTypes.bool,
//     onClick: PropTypes.func,
// };

export default (VehicleReportCard);
