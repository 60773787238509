import { takeLatest, put, call, takeEvery } from "typed-redux-saga/macro";

import {
    receiveUsers,
    receiveUpdateUserMandator,
    receiveForgotPassword,
    receiveChangePassword,
    receiveResetMomentPassword,
    receiveResetPassword,
    receiveChangePasswordForUser,
    receiveCurrentlyLoggedinUser,
    receiveUsersToggleArchive,
    receiveUserToggleArchive,
    receiveDeleteUser,
    receiveUpdateUsers,
    receiveUpdateUser,
    receiveAddUser,
    receiveUserById,
    receiveAllUsers,
    receiveRoles,
    USER_ACTION_TYPES,
} from "../actions/actionUser";

import {handleError} from "../managers/sagaManager";

import { RequestUsers,
    RequestAllUsers,
    RequestUserById,
    RequestAddUser,
    RequestUpdateUser,
    RequestUpdateUserMandator,
    RequestUpdateUsers, 
    RequestDeleteUser,
    RequestUserToggleArchive,
    RequestUsersToggleArchive, 
    RequestChangePassword,
    RequestChangePasswordForUser, 
    RequestResetPassword,
    RequestResetMomentPassword,
    RequestForgotPassword
} from "../types/actions/actionUser.types";

import {
    getUsers,
    getUserById,
    addUser,
    updateUser,
    updateUsers,
    deleteUser,
    updatUserArchive,
    updatUsersArchive,
    getRoles,
    getCurrentlyLoggedinUser,
    changePasswordForUser,
    resetPassword,
    resetMomentPassword,
    changePassword,
    forgotPassword, updateUserMandator,
} from "./api";

function* getUsersData(action: RequestUsers) {
    try {
        const response = yield* call(getUsers, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUsers(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getAllUsersData(action: RequestAllUsers) {
    try {
        const response = yield* call(getUsers, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveAllUsers(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getUserDataById(action: RequestUserById) {
    try {
        const response = yield* call(getUserById, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUserById(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getCurrentyLoggedinUserData() {
    try {
        const response = yield* call(getCurrentlyLoggedinUser);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveCurrentlyLoggedinUser(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* addUserData(action: RequestAddUser) {
    try {
        const response = yield* call(addUser, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveAddUser(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateUserData(action: RequestUpdateUser) {
    try {
        const response = yield* call(updateUser, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUpdateUser(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateUserMandatorData(action: RequestUpdateUserMandator) {
    try {
        const response = yield* call(updateUserMandator, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUpdateUserMandator(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateUsersData(action: RequestUpdateUsers) {
    try {
        const response = yield* call(updateUsers, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUpdateUsers(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* deleteUserData(action: RequestDeleteUser) {
    try {
        const response = yield* call(deleteUser, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveDeleteUser(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateUserArchiveToggleData(action: RequestUserToggleArchive) {
    try {
        const response = yield* call(updatUserArchive, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUserToggleArchive(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateUsersArchiveToggleData(action: RequestUsersToggleArchive) {
    try {
        const response = yield* call(updatUsersArchive, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUsersToggleArchive(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}


function* getRolesData() {
    try {
        const response = yield* call(getRoles);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveRoles(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* changePasswordData(action: RequestChangePassword) {
    try {
        const response = yield* call(changePassword, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveChangePassword(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* changePasswordForUserData(action: RequestChangePasswordForUser) {
    try {
        const response = yield* call(changePasswordForUser, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveChangePasswordForUser(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* resetPasswordData(action: RequestResetPassword) {
    try {
        const response = yield* call(resetPassword, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveResetPassword());
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* resetMomentPasswordData(action: RequestResetMomentPassword) {
    try {
        const response = yield* call(resetMomentPassword, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveResetMomentPassword(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* forgotPasswordData(action: RequestForgotPassword) {
    try {
        const response = yield* call(forgotPassword, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveForgotPassword());
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

export default function* sagaUser() {
    yield* takeLatest(USER_ACTION_TYPES.REQUEST_CURRENTLY_LOGGEDIN_USER, getCurrentyLoggedinUserData);
    yield takeLatest(USER_ACTION_TYPES.REQUEST_USERS, getUsersData);
    yield takeLatest(USER_ACTION_TYPES.REQUEST_ALL_USERS, getAllUsersData);
    yield takeLatest(USER_ACTION_TYPES.REQUEST_USER_BY_ID, getUserDataById);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_ADD_USER, addUserData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_UPDATE_USER, updateUserData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_UPDATE_USER_Mandator, updateUserMandatorData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_UPDATE_USERS, updateUsersData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_DELETE_USER, deleteUserData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_USER_TOGGLE_ARCHIVE, updateUserArchiveToggleData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_USERS_TOGGLE_ARCHIVE, updateUsersArchiveToggleData);

    yield takeLatest(USER_ACTION_TYPES.REQUEST_ROLES, getRolesData);

    yield takeEvery(USER_ACTION_TYPES.REQUEST_CHANGE_PASSWORD, changePasswordData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_CHANGE_PASSWORD_FOR_USER, changePasswordForUserData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_RESET_PASSWORD, resetPasswordData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_RESET_MOMENTPASSWORD, resetMomentPasswordData);
    yield takeEvery(USER_ACTION_TYPES.REQUEST_FORGOT_PASSWORD, forgotPasswordData);
}
