import {periods} from "../models/Period";
import {addDays, endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear} from "date-fns";
import moment from "moment";

export function serverTimeToSeconds(serverTime) {
    if (!serverTime) return "";

    let times = serverTime.split(":");
    if (!times || times.length !== 3) return "";

    return Math.round(Number(times[0]) * 3600 + Number(times[1]) * 60 + Number(times[2]));
}

export function getNextMonth(currentMonth) {
    return (currentMonth === 12) ? 1 : currentMonth + 1;
}

export function secondsToString(seconds) {
    if (!seconds || seconds === 0) return "";

    let h = Math.floor(seconds / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let percM = Math.round(m / 60 * 100);
    let s = Math.floor(seconds % 3600 % 60);
    let percS = Math.round(s / 60 * 100);

    //let hDisplay = h > 0 ? (h < 10 ? "0" : 0) + h + "," + (percM < 10 ? "0" : "") + percM + " std" : "";
    //let mDisplay = h <= 0 ? (m < 10 ? "0" : 0) + m + "," + (percS < 10 ? "0" : "") + percS + " min" : "";

    return (h < 10 ? "0" : "") + h + ":" + (m < 10 ? "0" : "") + m;
}

export function fromToByPeriod(period, date = new Date(), customEndDate = date) {
    switch (period) {
        case periods.none.id:
            return {from: null, to: null};
        case periods.day.id:
            let day = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            return {
                from: day,
                to: addDays(day, 1),
            };
            break;
        case periods.week.id:
            return {
                from: new Date(startOfWeek(date, {weekStartsOn: 1})),
                to: new Date(endOfWeek(date, {weekStartsOn: 1})),
            };
        case periods.month.id:
            return {from: new Date(startOfMonth(date)), to: new Date(endOfMonth(date))};
        case periods.year.id:
            return {from: new Date(startOfYear(date)), to: new Date(endOfYear(date))};
        default:
            return {
                from: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                to: addDays(new Date(customEndDate.getFullYear(), customEndDate.getMonth(), customEndDate.getDate()), 1),
            };
    }
}

export const TablesFormatedDateAndTime = (dateAndTime) => {
    return moment(dateAndTime).format('DD-MM-YYYY HH:mm');
  }