const grouping = {
  driver: 1,
  vehicle: 2,
  costCenter: 3,
  geofence: 4,
  groups: 5,
};

const groupingArray = [
  { id: grouping.vehicle, name: "Fahrzeug" },
  { id: grouping.driver, name: "Fahrer" },
  { id: grouping.costCenter, name: "Kostenstelle" },
  { id: grouping.groups, name: "Gruppen" },
];

const tripGeofenceGroupingArray = [
  { id: grouping.vehicle, name: "Vehicle" },
  { id: grouping.geofence, name: "Geofence" },
  { id: grouping.driver, name: "Driver" },
];

const mapGroupingToServerGrouping = (groupValue) => {
  switch (groupValue) {
    case grouping.driver:
      return "User";
    case grouping.vehicle:
      return "Vehicle";
    case grouping.costCenter:
      return "CostCenter";
    case grouping.geofence:
      return "Geofence";
    case grouping.groups:
      return "Groups";
    default:
      return "Vehicle";
  }
};

const mapServerGroupingToGrouping = (serverValue) => {
  switch (serverValue) {
    case 0:
      return grouping.vehicle;
    case 1:
      return grouping.driver;
    case 2:
      return grouping.costCenter;
    case 4:
      return grouping.geofence;
    case 5:
      return grouping.groups;
    default:
      return grouping.driver;
  }
};

export {
  grouping,
  groupingArray,
  tripGeofenceGroupingArray,
  mapGroupingToServerGrouping,
  mapServerGroupingToGrouping,
};
