import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const StopoverContents = ({ stopoverValues }) => {

  const { t } = useTranslation();
  
    return stopoverValues.map((stopoverValue, idx) => {
        return (
          stopoverValue.city && (
            <>
              <Grid item xs={5} key={idx}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  style={{ textAlign: "end" }}
                >
                  {t("reservation.addReservationModal.pageThree.stopover", { index: idx + 1 })}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  style={{ fontWeight: "bold" }}
                >
                  {stopoverValues[idx].displayName}
                </Typography>
              </Grid>
            </>
          )
        );
      });

};

export default StopoverContents;
