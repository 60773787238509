import React, { useEffect, useState } from "react";
import L from "leaflet";
import axios from "axios";
import LeafletMap from "./leaflet/LeafletMap";
import GeoFenceList from "./sidebar/geofencelist/GeoFenceList";
import Dock from "react-dock";
import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import GeoFenceButtonBar from "./sidebar/buttonbar/GeoFenceButtonBar";
import SimpleBackdrop from "./generic/SimpleBackdrop";
import RoutingToolBar from "./leaflet/leafletOperations/RoutingToolBar";
import PaginationButtonBar from "./sidebar/paginationbar/PaginationButtonBar";
import CustomStringDialog from "./generic/CustomStringDialog";
import { useCookies } from "react-cookie";
import MetadataSearchBar from "./sidebar/searchbar/MetadataSearchBar";
import { renderToStaticMarkup } from "react-dom/server";
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.css";
import Page from "../common/container/Page";
import { menu } from "../../managers/pathManager";
import { Grid, Card, Paper, Tabs, Tab, Box, Tooltip } from "@material-ui/core";
import FilterToolbar from "../common/toolbar/FilterToolbar";
import { Link } from "react-router-dom";
import { allowedRoles, isAuthorized } from "../../managers/authManager";
import { packages } from "../../models/Package";
import { useSelector } from "react-redux";
import { roles } from "../../models/Role";
import { getGeofencesNameId } from "../../saga/api";

import GeoFenceAnalysisTooltip from "./generic/GeofenceAnalysisTooltip";
import { useTranslation } from "react-i18next";

const defaultMapCenter = [47.787223651378184, 13.745034328747927];
const defaultMapZoom = 8;
const defaultPolygonColor = "#FF00FF";

const baseUrl = window.location.origin + "/api";

function Geofences({
  initialize,
  addTranslationForLanguage,
  setActiveLanguage,
}) {
  const me = useSelector((state) => state.users.me);
  const [cookies, setCookie, removeCookie] = useCookies();
  const { t } = useTranslation();

  const [geoFences, setGeoFences] = useState(new Map());
  const [archivedGeoFences, setArchivedGeoFences] = useState(new Map());
  const [gotGeoFenceDataFromServer, setGotGeoFenceDataFromServer] =
    useState(false);
  const [mapState, setMapState] = useState({
    position: defaultMapCenter,
    zoom: defaultMapZoom,
  });
  const [currentUser, setCurrentUser] = useState(
    "OTE2MTcyNDgtRDFDMy00QzcwLTg0OTYtMEY5QUYwMUI2NDlE"
  );
  const [dockVisible, setDockVisible] = useState(true);
  const [dockSize, setDockSize] = useState(410);
  const [map, setMap] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [routeMode, setRouteMode] = useState(false);
  const [routeController, setRouteController] = useState(null);
  const [currentPage, setCurrentPage] = useState(cookies.page || 1);
  const [pageCount, setPageCount] = useState(1);
  const [size, setSize] = useState(cookies.pageSize || 10);
  const [customDialog, setCustomDialog] = useState({ isOpen: false });
  const [selection, setSelection] = useState([]);
  const [polygonColor, setPolygonColor] = useState({
    color: defaultPolygonColor,
  });
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [geoFenceMetadata, setGeoFenceMetadata] = useState([]);
  const [searchMode, setSearchMode] = useState(0);
  const [visibilityObj, setVisibilityObj] = useState(
    JSON.parse(localStorage.getItem("visibility"))
  );
  const [geoFenceLocks, setGeoFenceLocks] = useState({});

  useEffect(() => {
    //initializeTranslation();
    setPolygonColor({ color: cookies.polygonColor || defaultPolygonColor });
    getGeoFenceLocks();
    getGeoFenceMetadata();
    getGeoFenceData(size, currentPage);
  }, []);

  function getGeoFenceData(size, page) {
    return sendGeoFenceRequest(
      `${baseUrl}/geoFences?size=${size}&page=${page}`
    );
  }

  function getGeoFenceDataWithSearch(category, searchTerm) {
    return sendGeoFenceRequest(
      `${baseUrl}/geoFences/search?searchTerm=${searchTerm}&metadataCategory=${category}`
    );
  }

  async function sendGeoFenceRequest(url) {
    setGotGeoFenceDataFromServer(false);
    await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: currentUser,
      },
    })
      .then((res) => {
        let newGeoFences = new Map();

        setPageCount(res.data.pageCount);
        setSelection([]);
        let tempVisibilityObj = JSON.parse(localStorage.getItem("visibility"));

        for (let elem of res.data.geoJson) {
          let currentGeoFence = JSON.parse(elem);

          // swap lat and long
          for (let subArr of currentGeoFence.Polygon.coordinates) {
            for (let e of subArr) {
              let temp = e[0];
              e[0] = e[1];
              e[1] = temp;
            }
          }

          currentGeoFence.Hidden =
            tempVisibilityObj == null
              ? false
              : tempVisibilityObj[`id_${currentGeoFence.ID}`];
          let newPoly = L.polygon(currentGeoFence.Polygon.coordinates);
          newPoly.geoFence = currentGeoFence;
          newGeoFences.set(currentGeoFence.ID, newPoly);
        }

        // Setting the active and archived geofences
        setGeoFences(new Map(getUnarchivedFromAllGeofences(newGeoFences)));
        setArchivedGeoFences(
          new Map(getArchivedFromAllGeofences(newGeoFences))
        );
        setGotGeoFenceDataFromServer(true);
      })
      .catch((err) => {
        console.log("out", err);

        if (err.response?.data.title === "WrongPageError") {
          setCurrentPage(1);
          setCookie("page", 1, {
            expires: new Date(new Date().getFullYear() + 1, 11, 30),
          });
          getGeoFenceData(size, 1);
        } else {
          alert(
            "Could not load geofences / Geofences konnten nicht geladen werden"
          ); // static text because translation is not loaded yet
          console.log(err.response);
          setGotGeoFenceDataFromServer(false);
        }
      });
  }

  function getUnarchivedFromAllGeofences(geofences) {
    var unarchivedGfs = new Map();
    geofences.forEach((value, key) => {
      if (!value.geoFence.IsArchived) {
        unarchivedGfs.set(key, value);
      }
    });
    return unarchivedGfs;
  }

  function getArchivedFromAllGeofences(geofences) {
    var archivedGfs = new Map();
    geofences.forEach((value, key) => {
      if (value.geoFence.IsArchived) {
        archivedGfs.set(key, value);
      }
    });
    return archivedGfs;
  }

  async function getGeoFenceLocks() {
    await axios({
      method: "get",
      url: `${baseUrl}/geoFences/timelocks`,
      headers: {
        Authorization: currentUser,
      },
    })
      .then((res) => {
        setGeoFenceLocks({ ...res.data });
      })
      .catch((err) => {
        console.log("Could not get geofence locks:\n" + err);
      });
  }

  async function getGeoFenceMetadata() {
    await axios({
      method: "get",
      url: `${baseUrl}/GeoFenceMetadata`,
      headers: {
        Authorization: currentUser,
      },
    })
      .then((res) => {
        setGeoFenceMetadata([...res.data]);
      })
      .catch((err) => {
        console.log("Could not get geofence metadata:\n" + err);
      });
  }

  function addGeoFenceInState(
    newGeoFenceId,
    newTitle,
    latlngs,
    isSystemGeoFence,
    isNotEditable,
    isAntiTheftEnabled,
    isArchived,
    notificationEmail,
    notificationMobileNumber
  ) {
    let newGeoFenceObj = {
      Histories: [
        {
          IdGeoFence: newGeoFenceId,
          Username: currentUser,
          Timestamp: Date.now() / 1000,
        },
      ],
      ID: newGeoFenceId,
      Title: newTitle,
      Polygon: {
        type: "Polygon",
        coordinates: latlngs,
      },
      SystemGeoFence: isSystemGeoFence,
      IsNotEditable: isNotEditable,
      IsAntiTheftEnabled: isAntiTheftEnabled,
      IsArchived: isArchived,
      NotificationEmail: notificationEmail,
      NotificationMobileNumber: notificationMobileNumber,
    };
    let newGeoFences = geoFences;
    let newPoly = L.polygon(newGeoFenceObj.Polygon.coordinates);
    newPoly.geoFence = newGeoFenceObj;
    newGeoFences.set(newGeoFenceId, newPoly);
    setGeoFences(new Map(newGeoFences));
  }

  function setRouteControllerState(ctrl) {
    if (routeController === null) {
      setRouteController(ctrl);
    }
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function closeDock() {
    setDockVisible(false);
  }

  const geofencesReport = (
    <Tab
      aria-label="donate"
      component={Link}
      to={"/geofences/report"}
      label="geofencesReport"
    />
  );
  
  return (
    <Page activePage={menu.geofences.key}>
      <Paper square style={{ marginTop: "50px" }}>
        {isAuthorized(me, allowedRoles(roles.Admin), packages.Geofences) ? (
          <Tabs
            value={value}
            onChange={handleChange} //<Tooltip title={groups} placement="right">   </Tooltip> disabled
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon tabs example"
          >
            <Tab
              aria-label="foldertree"
              component={Link}
              to={"/geofences"}
              label="geofences"
            />
            {geofencesReport}
            <GeoFenceAnalysisTooltip />
          </Tabs>
        ) : null}
      </Paper>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card id="map-card">
            <div id="page-wrap">
              {customDialog?.isOpen ? (
                <CustomStringDialog
                  dialog={customDialog}
                  setDialog={setCustomDialog}
                ></CustomStringDialog>
              ) : (
                <> </>
              )}
              <div className="mapWrapperDiv">
                <button
                  id="btn_toggleDock"
                  onClick={() => {
                    setDockVisible(!dockVisible);
                  }}
                  className="toggleDockButton"
                >
                  <MenuIcon style={{ fontSize: 40 }}></MenuIcon>
                </button>
                <LeafletMap
                  mapState={mapState}
                  setMapState={setMapState}
                  geoFences={geoFences}
                  currentUser={currentUser}
                  map={map}
                  setMap={setMap}
                  selectedRoute={selectedRoute}
                  setSelectedRoute={setSelectedRoute}
                  routeMode={routeMode}
                  setRouteMode={setRouteMode}
                  setRouteController={setRouteControllerState}
                  setDialog={setCustomDialog}
                  polygonColor={polygonColor}
                  visibilityObj={visibilityObj}
                  addGeoFenceInState={addGeoFenceInState}
                ></LeafletMap>
              </div>
            </div>
            <Dock
              dockStyle={{ marginTop: "132px" }}
              fluid={false}
              position="right"
              isVisible={dockVisible}
              size={dockSize}
              dimMode="none"
              zIndex={500}
              onSizeChange={(newDockSize) => {
                if (newDockSize < 410) {
                  setDockSize(410);
                } else if (newDockSize > window.innerWidth - 120) {
                  setDockSize(window.innerWidth - 120);
                } else {
                  setDockSize(newDockSize);
                }
              }}
            >
              <div className="overflowControlWrapper">
                {!gotGeoFenceDataFromServer ? (
                  <SimpleBackdrop
                    isOpen={!gotGeoFenceDataFromServer}
                    subText={t("status.loading")}
                    dockSize={dockSize}
                  ></SimpleBackdrop>
                ) : (
                  <></>
                )}

                <div className="listWrapperDiv">
                  <div
                    id="btn_closeDock"
                    className="divCloseDockButton"
                    onClick={closeDock}
                  >
                    <Close
                      style={{
                        fontSize: 40,
                        marginRight: "100%",
                        marginBottom: "2%",
                        cursor: "pointer",
                      }}
                    ></Close>
                  </div>
                  <GeoFenceList
                    geoFences={geoFences}
                    setGeoFences={setGeoFences}
                    closeDock={closeDock}
                    setMapState={setMapState}
                    map={map}
                    currentUser={currentUser}
                    selection={selection}
                    setSelection={setSelection}
                    polygonColor={polygonColor}
                    geoFenceMetadata={geoFenceMetadata}
                    setGeoFenceMetadata={setGeoFenceMetadata}
                    dockSize={dockSize}
                    getGeoFenceData={getGeoFenceData}
                    getGeoFenceDataWithSearch={getGeoFenceDataWithSearch}
                    searchMode={searchMode}
                    setSearchMode={setSearchMode}
                    currentPage={currentPage}
                    size={size}
                    setDialog={setCustomDialog}
                    visibilityObj={visibilityObj}
                    setVisibilityObj={setVisibilityObj}
                    geoFenceLocks={geoFenceLocks}
                    setGeoFenceLocks={setGeoFenceLocks}
                  ></GeoFenceList>
                </div>

                <MetadataSearchBar
                  dockSize={dockSize}
                  getGeoFenceData={getGeoFenceData}
                  getGeoFenceDataWithSearch={getGeoFenceDataWithSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  currentPage={currentPage}
                  size={size}
                ></MetadataSearchBar>

                <PaginationButtonBar
                  getGeoFenceData={getGeoFenceData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  size={size}
                  setSize={setSize}
                  dockSize={dockSize}
                  searchMode={searchMode}
                  setDialog={setCustomDialog}
                ></PaginationButtonBar>

                <GeoFenceButtonBar
                  geoFences={geoFences}
                  setGeoFences={setGeoFences}
                  selection={selection}
                  setSelection={setSelection}
                  removeFromSelection={() => {
                    let newSelection = selection;
                    for (let i = 0; i < geoFences.length; i++) {
                      let id = geoFences[i].Id;
                      let foundIndex = newSelection.indexOf(id);
                      if (foundIndex > -1) {
                        newSelection.splice(foundIndex, 1);
                      }
                    }
                    setSelection([...newSelection]);
                  }}
                  setPolygonColor={setPolygonColor}
                  colorPickerOpen={colorPickerOpen}
                  setColorPickerOpen={setColorPickerOpen}
                  dockSize={dockSize}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  getGeoFenceData={() => getGeoFenceData(size, currentPage)}
                  setDialog={setCustomDialog}
                  geoFenceLocks={geoFenceLocks}
                  setGeoFenceLocks={setGeoFenceLocks}
                  addGeoFenceInState={addGeoFenceInState}
                  currentUser={currentUser}
                ></GeoFenceButtonBar>
              </div>
            </Dock>

            <RoutingToolBar
              selectedRoute={selectedRoute}
              currentUser={currentUser}
              routeMode={routeMode}
              setRouteMode={setRouteMode}
              routeController={routeController}
              setRouteController={setRouteController}
              setDialog={setCustomDialog}
              addGeoFenceInState={addGeoFenceInState}
            ></RoutingToolBar>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

export default Geofences;
