import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { requestAddMandatorUsers } from "../../../actions/actionMandators";
import GenericCSVInsertModal from "../../common/modals/GenericCSVInsertModal";

const MandatorUserInsertModal = ({ response, onClose, requestAddMandatorUsers }) => {


    const columnInformations = [
        { title: "Personal Number", key: "foreignId" },
        { title: "Firstname", key: "firstName" },
        { title: "Lastname", key: "lastName" },
        { title: "Email", key: "email" },
        { title: "Description", key: "description" },
        { title: "Group", key: "group" },
        { title: "Role", key: "role" },
        { title: "Password", key: "password" },
        { title: "Welcome mail", key: "welcomeMail" },
    ];

    return <GenericCSVInsertModal
        identifier="email"
        columnInformations={columnInformations}
        response={response}
        onAdd={requestAddMandatorUsers}
        onClose={onClose}
        mandatorSelect
    />;
};

// MandatorUserInsertModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ requestAddMandatorUsers }, dispatch);
}

function mapStateToProp(state) {
    return {
        response: state.users.current,
    };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MandatorUserInsertModal);
