import { useState,  } from "react";

import { useTheme } from "@material-ui/core";

import UnrealisticTripsTable from "./UnrealisticTripsTable";
import MultiplePlugoffsTable from "./MultiplePlugoffsTable";
import DestinationMismatchTable from "./DestinationMismatchTable";
import LogsTable from "./LogsTable";

import { menu } from "../../managers/pathManager";
import Page from "../common/container/Page";
import FaultyTripsTabs from "../tabs/FaultyTripsTabs";

import SwipeableViews from "react-swipeable-views";

import { ChangeEvent } from "react";

const FaultyTripsPage = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const theme = useTheme();

  const handleTabChange = (event: ChangeEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleTabChangeIndex = (index: number) => {
    setTabValue(index);
  };

  return (
    <Page
      activePage={menu.faultyTrips.key}
      toolbar={
        <FaultyTripsTabs
          value={tabValue}
          handleTabChange={handleTabChange}
        />
      }
    >
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabValue}
        onChangeIndex={handleTabChangeIndex}
      >
        <>{tabValue === 0 && <UnrealisticTripsTable />}</>
        <>{tabValue === 1 && <MultiplePlugoffsTable />}</>
        <>{tabValue === 2 && <DestinationMismatchTable />}</>
        <>{tabValue === 3 && <LogsTable />}</>
      </SwipeableViews>
    </Page>
  );
};

export default FaultyTripsPage;
