import { useState } from "react";

export function useReservationPage() {
  const pages = {
    availableVehicle: "availableVehicle",
    myReservation: "myReservation",
    archive: "archive",
    planningOverview: "planningOverview",
  };
  const [page, setPage] = useState(pages.myReservation);

  return { page, setPage, pages };
}
