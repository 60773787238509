import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import CustomSelect from "../Select";
import { requestConnectorById } from "../../../../actions/qualityCharging/actionConnectors";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ConnectorSelect = ({
  connectorNumber,
  setConnectorNumber,
  startTime,
  endTime,
  from,
  to,
  chargeStationId
}) => {
  const dispatch = useDispatch();
  const chargeStation = useSelector((state) => state.chargeStations.current);
  const filter = useSelector((state) => state.filter);
  const { t } = useTranslation();

  useEffect(() => {
    if (connectorNumber !== null && chargeStation?.id) {
      dispatch(
        requestConnectorById({
          chargeStationId: chargeStation?.id,
          connectorNumber: connectorNumber,
          from: filter.api.from
            ? new Date(filter.api.from).toISOString()
            : new Date(startTime).toISOString(),
          to: filter.api.to
            ? new Date(filter.api.to).toISOString()
            : new Date(endTime).toISOString(),
        })
      );
    }
  }, [connectorNumber, from, to, chargeStationId]);

  return (
    <CustomSelect
      value={connectorNumber}
      placeholder={t("common.select.connectors")}
      handleChange={setConnectorNumber}
      allowClear={false}
    >
      {chargeStation?.connectors?.map((connector) => (
        <Option key={connector.id} value={connector.number}>
          {connector.number}
        </Option>
      ))}
    </CustomSelect>
  );
};

export default ConnectorSelect;
