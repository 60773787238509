import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';


const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
};

i18n
  // .use(LanguageDetector) we are not using it because we are getting the initial language from Local Storage
  .use(initReactI18next)  // Bind i18next with React
  .init({
    resources,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, 
    },
    detection: {
      order: ['navigator', 'cookie', 'localStorage', 'htmlTag'],
      caches: ['cookie'], 
    },
  });

export default i18n;
