import React, {Component} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { getTranslation } from '../../../translations/translations.js';

import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import InputField from "../inputs/InputField";

import CustomModal from "./CustomModal";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    confirmDialog: {
        zIndex: 6000,
    },
});

class ArchiveModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
        };
    }

    handleCommentChange = event => {
        this.setState({comment: event.target.value});
    };

    render() {
        const {classes, onClose, onArchive, fullScreen, noComment, description, title, elementId} = this.props;
        const { comment } = this.state;
        const contents = noComment ? [] : [<InputField label="Comment" onChange={this.handleCommentChange} value={comment} shrink />];
        if (description) {
            contents.push.apply(contents, [<div>{description}</div>]);

        }

        return (
            <CustomModal
                contents={contents}
                action="confirm"
                onSave={() => {
                    onArchive(elementId);
                    onClose();
                }}
                onClose={onClose}
                title={getTranslation('dialogs.archiveTitle')}
            />
        );
    }
}

// ArchiveModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     onArchive: PropTypes.func.isRequired,
//     title: PropTypes.string.isRequired,
// };

export default withStyles(styles, {withTheme: true})(withMobileDialog()(ArchiveModal));
