import React from "react";
import CustomSelect from "../../common/selects/Select";
import {Select} from "antd";
import {timeRepetitionArray} from "../../../models/TimeRepetition";
import PropTypes from "prop-types";
import SendToPersonGroupSelect from "./SendToPersonGroupSelect";

const {Option} = Select;

const TimeRepetitionSelect = props => {
    const {onChange, value} = props;

    return <CustomSelect value={value}
                         allowClear={false}
                         handleChange={onChange}
                         placeholder="Jeden Tag">
        {timeRepetitionArray.map(t => {
            return <Option key={t.id} value={t.id}>
                {t.name}
            </Option>
        })}
    </CustomSelect>
};

// TimeRepetitionSelect.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string,
// };
export default TimeRepetitionSelect;