import React, { Component } from "react";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    marginTop: "10%",
    width: "400px",
    margin: "0 auto",
  },
});

class PageNotFound extends Component {
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Typography align="center">
          {t("pageNotFound")}
        </Typography>
        <Button component={Link} to="/" fullWidth color="primary">
          {t("goBack")} 
        </Button>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation()(PageNotFound)
);
