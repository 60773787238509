import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import GenericTable from "../../common/table/GenericTable";
//Models
import TableTypes from "../../../models/TableTypes";

const MandatorTable = props => {
    const {mandators, total, onInlineEdit, page, rowsPerPage, surrogateMandator, onArchiveToggle} = props;

    const columnInformations = [
        {title: "Name", key: "name"},
        {title: "Beschreibung", key: "description"},
        {title: "Pakete", key: "packages", type: TableTypes.package},
        {title: "Driveboxen", key: "amountOfDriveboxes"},
        {title: "Preferred Geocoding", key: "preferredGeolocationServiceProvider", type: TableTypes.preferredGeolocationServiceProvider},
        {title: "Fahrzeuge", key: "amountOfVehicles"},
        {title: "Mitarbeiter", key: "amountOfUsers"},
        {title: "Erstellt am", key: "createdAt", type: TableTypes.date},
        {title: "Tester", key:"testerSince", type: TableTypes.date}
    ];

    const actions = [
        {title: "Edit", event: onInlineEdit},
        {title: "Archive", event: onArchiveToggle},
        {title: "Surrogate", event: surrogateMandator},
    ];

    const mappedMandators = mandators.map(m => {
        return m;
    });

    return (
        <GenericTable
            data={mappedMandators}
            columnInformations={columnInformations}
            actions={actions}
            onEdit={onInlineEdit}
            showActions
            onRowClick={onInlineEdit}
            rowCount={total}
            pageNavigation
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={props.handleChangePage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
        />
    );
};

// MandatorTable.propTypes = {
//     classes: PropTypes.object,
//     onInlineEdit: PropTypes.func.isRequired,
//     onArchiveToggle: PropTypes.func.isRequired,
//     surrogateMandator: PropTypes.func.isRequired,
// };

function mapStateToProp(state) {
    return {
        mandators: state.mandators.entities,
        total: state.mandators.total,
    };
}

export default connect(mapStateToProp)(MandatorTable);
