import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Menu, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import {Tree} from "antd";
import {getIdsFromGroupValues} from "../../../managers/groupManager";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {requestMoveGroup, requestGroups, requestGroupById} from "../../../actions/actionGroups"
import {types} from "../../../models/GroupTypes";

import {requestUpdateVehicle} from "../../../actions/actionVehicle";
import {requestUpdateUser} from "../../../actions/actionUser";

const {TreeNode, DirectoryTree} = Tree;

const MoveToContextMenu = props => {
    const {id, anchorEl, onClose, tree, type, user, vehicle, group} = props;

    function renderNode(node) {
        return (
            <TreeNode title={node.name} key={node.key}
                      icon={<FolderIcon fontSize="small"/>}>
                {node.children && node.children.map(childNode => renderNode(childNode))}
            </TreeNode>
        );
    }


    useEffect(() => {
        if (typeof group.id !== "undefined") {
            props.requestGroups(group.id);
            props.requestGroupById(group.id);
        }
    }, [user, vehicle]);

    function handleMoveGroup(value) {
        switch (type) {
            case types.node:
                props.requestMoveGroup({id, parentId: value});
                break;
            case types.user:
                props.requestUpdateUser({
                    ...user, roleId: user.role.id, groupIds: user.groups.map(g => g.id === group.id ? value : g.id)
                });
                break;
            case types.vehicle:
                props.requestUpdateVehicle({
                    ...vehicle,
                    groupIds: vehicle.groups.map(g => g.id === group.id ? value : g.id),
                    driveboxId: vehicle.drivebox ? vehicle.drivebox.id : "",
                    ownerId: vehicle.owner ? vehicle.owner.id : ""
                });
        }

        onClose();
    }

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "left"}}
            transformOrigin={{vertical: "top", horizontal: "left"}}
            open={Boolean(anchorEl)}
            onClose={onClose}>
            <DirectoryTree expandAction="false"
                           defaultExpandAll
                           onSelect={keys => handleMoveGroup(getIdsFromGroupValues(tree, [{value: keys[0]}])[0])}>
                {type === types.node ?
                    <TreeNode title={"Gruppenverwaltung"} key={null} icon={<FolderIcon fontSize="small"/>}>
                        {tree.map(node => renderNode(node))}
                    </TreeNode> : tree.map(node => renderNode(node))}
            </DirectoryTree>
        </Menu>
    );
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        requestMoveGroup,
        requestUpdateVehicle,
        requestUpdateUser,
        requestGroups,
        requestGroupById
    }, dispatch);
}

function mapStateToProp(state) {
    return {
        tree: state.tree,
        vehicle: state.vehicles.current,
        user: state.users.current,
        group: state.group,
    };
}

// MoveToContextMenu.propTypes = {
//     anchorEl: PropTypes.string,
//     onClose: PropTypes.func.isRequired,
//     id: PropTypes.string,
//     type: PropTypes.oneOf(["node", "vehicle", "user"])
// };

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MoveToContextMenu);
