import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Toolbar from "../../common/toolbar/Toolbar";
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";
import UserSelect from "../../common/selects/specificSelects/UserSelect";
import MonthPicker from "../../common/selects/datePickers/MonthPicker";
import {fromToByPeriod} from "../../../managers/timeManager";
import {periods} from "../../../models/Period";

const MonthTimeRecordingToolbar = props => {
    const {pages, page, setPage, dateValue, onDateChange, userValue, onUserChange, onExport} = props;

    useEffect(() => {
        const monthPeriod = fromToByPeriod(periods.month.id, dateValue);
        onDateChange(monthPeriod.from, monthPeriod.to);
    }, [])

    const toggles = [
        {
            icon: <FontAwesomeIcon fontSize={19}
                                   color={page !== pages.day ? "secondary" : undefined}>
                far fa-calendar-day</FontAwesomeIcon>,
            translateKey: "time.page.day",
            active: page === pages.day,
            onClick: () => {
                setPage(pages.day)
            },
        },
        {
            icon: <FontAwesomeIcon fontSize={19} color={page !== pages.month ? "secondary" : undefined}>
                far fa-calendar-alt</FontAwesomeIcon>,
            translateKey: "time.page.month",
            active: page === pages.month,
            onClick: () => {
                setPage(pages.month)
            },
        },]


    const actionButtons = [
        {
            icon: <FontAwesomeIcon minWidth={26} fontSize={17}>far fa-download</FontAwesomeIcon>,
            translateKey: "trip.page.export",
            onClick: onExport,
        }
    ];

    const controls = [
        <MonthPicker value={dateValue} onDateChange={onDateChange}/>,
        <UserSelect showArchived={false}
                    value={userValue}
                    onChange={onUserChange}
                    allowClear={false}/>

    ];


    return <Toolbar title={"Monatsübersicht"} toggles={toggles} controls={controls} actionButtons={actionButtons}/>
}

// MonthTimeRecordingToolbar.propTypes = {
//     pages: PropTypes.array.isRequired,
//     page: PropTypes.number.isRequired,
//     setPage: PropTypes.func.isRequired,
//     dateValue: PropTypes.object.isRequired,
//     onDateChange: PropTypes.func.isRequired,
//     userValue: PropTypes.object.isRequired,
//     onUserChange: PropTypes.func.isRequired,
//     onExport: PropTypes.func.isRequired,
// };


export default MonthTimeRecordingToolbar;