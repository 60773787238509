import { call, put, takeLatest } from "redux-saga/effects";
import filedownload from "js-file-download";

import {
  REQUEST_MANDATOR_EXPORT_XLSX,
  REQUEST_MANDATOR_USER_EXPORT_XLSX,
  REQUEST_MANDATOR_DRIVEBOX_EXPORT_XLSX,
  REQUEST_TRIPS_EXPORT_PDF,
  REQUEST_TRIPS_EXPORT_XLSX,
  REQUEST_USERS_EXPORT_XLSX,
  REQUEST_VEHICLES_EXPORT_XLSX,
  REQUEST_DRIVEBOXES_EXPORT_XLSX,
  REQUEST_DAY_VIEW_EXPORT_PDF,
  REQUEST_DAY_VIEW_GRAPHIC_EXPORT_PDF,
  REQUEST_TIMELOG_EXPORT_PDF,
  REQUEST_TRIP_GEOFENCE_INTERSECTIONS_EXPORT_PDF,
  REQUEST_VEHICE_MILEAGE_EXPORT_PDF,
  REQUEST_MONTHLY_TRIP_REPORT,
  REQUEST_RESERVATION_REPORT,
  REQUEST_HWNOE_VEHICLE_DATA_REPORT,
  REQUEST_COSTCENTER_REPORT,
  REQUEST_FUELMONITOR_REPORT
} from "../actions/actionExport";

import { handleReportError } from "../managers/sagaManager";
import {
  getMandatorExportXlsx,
  getMandatorUserExportXlsx,
  getMandatorDriveboxExportXlsx,
  getTripsExportPdf,
  getTripsExportXlsx,
  getUserExportXlsx,
  getVehiclesExportXlsx,
  getDriveboxesExportXlsx,
  getDayViewExportPdf,
  getDayViewGraphicExportPdf,
  getTripLogExportPdf,
  getTripGeofenceIntersectionsExportPdf,
  getVehicleMileageSummaryExportPdf,
  getExportedMonthlyXlsx,
  getReservationReport,
  getHwnoeVehicleDataReport,
  getCostcenterReport,
  getFuelMonitorReport
} from "./exportApi";
import { getServices } from "./api";

function downloadFile(response, filename) {
  filedownload(response.data, filename);
}

function* getMandatorExportXlsxData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getMandatorExportXlsx,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Mandators.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getMandatorUserExportXlsxData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getMandatorUserExportXlsx,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Users.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getMandatorDriveboxExportXlsxData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getMandatorDriveboxExportXlsx,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Driveboxes.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getTripsExportPdfData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getTripsExportPdf,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Trips.pdf");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getTripsExportXlsxData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getTripsExportXlsx,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Trips.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getTimeLogExportPdfData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getTripLogExportPdf,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Lenkprotokoll.pdf");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getDayViewExportPdfData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getDayViewExportPdf,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Tagesuebersicht.pdf");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getDayViewGraphicExportPdfData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getDayViewGraphicExportPdf,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "TagesuebersichtGraphisch.pdf");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getVehiclesExportXlsxData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getVehiclesExportXlsx,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Vehicles.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getUsersExportXlsxData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getUserExportXlsx,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Users.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getDriveboxesExportXlsxData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getDriveboxesExportXlsx,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "Driveboxes.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getVehicleMileageSummaryExportPdfData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getVehicleMileageSummaryExportPdf,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "VehicleMileage.pdf");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getTripGeofenceIntersectionsExportData(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getTripGeofenceIntersectionsExportPdf,
      serviceResponse.data,
      action.data
    );
    if (response.status >= 200 && response.status < 300)
      downloadFile(response, "TripGeofenceIntersections.pdf");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getExportedMonthlyXlsxReport(action) {
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getExportedMonthlyXlsx,
      serviceResponse.data,
      action.data);
    if (response.status >= 200 && response.status < 300)
    downloadFile(response, "Fahrtenaufzeichnungen.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getReservationXlsxReport(action){
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getReservationReport,
      serviceResponse.data,
      action.data);
    if (response.status >= 200 && response.status < 300)
    downloadFile(response, "Reservierungaufzeichnungen.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getHwnoeVehicleDataXlsxReport(action){
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getHwnoeVehicleDataReport,
      serviceResponse.data,
      action.data);
    if (response.status >= 200 && response.status < 300)
    downloadFile(response, "Fahrzeugbericht.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getCostcenterXlsxReport(action){
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getCostcenterReport,
      serviceResponse.data,
      action.data);
    if (response.status >= 200 && response.status < 300)
    downloadFile(response, "Kostenstellenbericht.xlsx");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

function* getFuelMonitorPdfReport(action){
  try {
    const serviceResponse = yield call(getServices);
    const response = yield call(
      getFuelMonitorReport,
      serviceResponse.data,
      action.data);
    if (response.status >= 200 && response.status < 300)
    downloadFile(response, "Kraftstoffverbrauchsbericht.pdf");
    else throw response;
  } catch (error) {
    yield put(handleReportError(error));
  }
}

export default function* sagaExport() {
  yield takeLatest(REQUEST_MANDATOR_EXPORT_XLSX, getMandatorExportXlsxData);
  yield takeLatest(
    REQUEST_MANDATOR_USER_EXPORT_XLSX,
    getMandatorUserExportXlsxData
  );
  yield takeLatest(
    REQUEST_MANDATOR_DRIVEBOX_EXPORT_XLSX,
    getMandatorDriveboxExportXlsxData
  );
  yield takeLatest(REQUEST_TRIPS_EXPORT_PDF, getTripsExportPdfData);
  yield takeLatest(REQUEST_TRIPS_EXPORT_XLSX, getTripsExportXlsxData);

  yield takeLatest(REQUEST_TIMELOG_EXPORT_PDF, getTimeLogExportPdfData);

  yield takeLatest(REQUEST_DAY_VIEW_EXPORT_PDF, getDayViewExportPdfData);
  yield takeLatest(
    REQUEST_DAY_VIEW_GRAPHIC_EXPORT_PDF,
    getDayViewGraphicExportPdfData
  );

  yield takeLatest(
    REQUEST_TRIP_GEOFENCE_INTERSECTIONS_EXPORT_PDF,
    getTripGeofenceIntersectionsExportData
  );

  yield takeLatest(REQUEST_USERS_EXPORT_XLSX, getUsersExportXlsxData);
  yield takeLatest(REQUEST_VEHICLES_EXPORT_XLSX, getVehiclesExportXlsxData);
  yield takeLatest(REQUEST_DRIVEBOXES_EXPORT_XLSX, getDriveboxesExportXlsxData);

  yield takeLatest(
    REQUEST_VEHICE_MILEAGE_EXPORT_PDF,
    getVehicleMileageSummaryExportPdfData
  );

  yield takeLatest(REQUEST_MONTHLY_TRIP_REPORT, getExportedMonthlyXlsxReport);
  yield takeLatest (REQUEST_RESERVATION_REPORT, getReservationXlsxReport);
  yield takeLatest(REQUEST_HWNOE_VEHICLE_DATA_REPORT, getHwnoeVehicleDataXlsxReport);
  yield takeLatest(REQUEST_COSTCENTER_REPORT, getCostcenterXlsxReport);
  yield takeLatest(REQUEST_FUELMONITOR_REPORT, getFuelMonitorPdfReport);
}
