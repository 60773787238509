import React from "react"

const FuelInCircleIcon = ({color="#757575"}) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="9.5" r="9.5" fill={color}/>
            <path d="M13.151 3L12.6302 3.61607L13.6198 4.55357C13.65 4.58461 13.6911 4.62617 13.724 4.66071L13.5156 4.90179C13.1821 5.24483 13.4214 5.6888 13.5417 5.8125L14.1667 6.45536V11.5714C14.1667 12.4286 13.8399 12.4286 13.75 12.4286C13.6601 12.4286 13.3333 12.4286 13.3333 11.5714V8.14286C13.3333 7.04494 12.5 6.42857 11.6667 6.42857V4.71429C11.6667 4.24174 11.3127 3.85714 10.8333 3.85714H5.83333C5.33396 3.85714 5 4.22119 5 4.71429V15H11.6667V7.28571C11.6667 7.28571 12.5 7.28571 12.5 8.14286V11.5714C12.5 13.2857 13.5331 13.2857 13.75 13.2857C13.9797 13.2857 15 13.2857 15 11.5714V5.57143C15 4.71429 14.7617 4.53496 14.4792 4.25893L13.151 3ZM5.83333 5.57143H10.8333V8.14286H5.83333V5.57143Z" fill="white"/>
        </svg>
        
    )
}

export default FuelInCircleIcon;
