import React from "react";
import { Select } from "antd";
import CustomSelect from "../Select";
import MultiSelect from "../MultiSelect";
import { exportFormatArray } from "../../../../models/ExportFormat";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ExportSelect = (props) => {
  const { onChange, value, disabled, mutliple } = props;
  const { t } = useTranslation();

  if (mutliple)
    return (
      <MultiSelect
        placeholder={t("common.select.exportformat")}
        selectedArray={value}
        array={exportFormatArray}
        handleChange={onChange}
      />
    );
  return (
    <CustomSelect
      value={value}
      placeholder={t("common.select.exportformat")}
      handleChange={onChange}
      allowClear={false}
      disabled={disabled}
    >
      {exportFormatArray.map((tt) => (
        <Option key={tt.id} value={tt.id}>
          {tt.name}
        </Option>
      ))}
    </CustomSelect>
  );
};

ExportSelect.defaultProps = {
  disabled: false,
  mutliple: false,
};

export default ExportSelect;
