import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Select} from "antd";
import { requestDriversByName } from "../../actions/actionDriverIdentification";

import CustomSelect from "../common/selects/Select";
import { UserResponseInfo } from "../../types/user/userResponse.types";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const {Option} = Select;

type DriverSearchBoxProps = {
    selectedDriver: UserResponseInfo | null,
    vehicleId: string,
    setSelectedDriver: (driver: UserResponseInfo) => void,
    customFontSize?: number
}

const DriverSearchBox: React.FC<DriverSearchBoxProps> = ({ vehicleId, customFontSize }) => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const drivers = useSelector((state: RootState) => state.driverIdentification.drivers);
    const { t } = useTranslation();
    const history = useHistory();
    const pathname = `driverIdentification/${vehicleId}`;
    const [ selectedDriver, setSelectedDriver ] = useState<UserResponseInfo | null>(null);

    useEffect(() => {
       if(searchTerm.length > 2) dispatch(requestDriversByName({searchTerm, vehicleId}));
      }, [searchTerm, vehicleId]);

      const handleChange = (value: string) => {
        const newSelectedDriver = drivers.find((driver: UserResponseInfo) => driver.id === value);
        if (newSelectedDriver) {
            setSelectedDriver(newSelectedDriver);
            history.push({ pathname: `/${pathname}/tripType`, state: { selectedDriver: newSelectedDriver, forgotPinPage: true } });
        }
    };
    

    return (
        <CustomSelect value={selectedDriver?.id}
                      placeholder={t("driverIdentification.searchName")}
                      handleChange={handleChange}
                      handleSearch={setSearchTerm}
                      allowClear={false}
                      customFontSize={customFontSize}
                      >
            {drivers.map((driver, idx) => (
                <Option key={`${driver.id}-${idx}`} value={driver.id}>{driver.firstName} {driver.lastName}</Option>
            ))}
        </CustomSelect>
    );

};

export default DriverSearchBox;
