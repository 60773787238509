import React from "react";
import PropTypes from "prop-types";
import {MobileDatePicker, MobileDateTimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";


const CustomDatePicker = ({views, onChange, value, minDate, disableFuture, disablePast, format, renderDay, labelFunc, inputFormat, variant,showDaysOutsideCurrentMonth}) => {
    const viewProp = views && views.length > 0 ? {views: views} : {};
    const { t } = useTranslation();


    return (
        <MobileDatePicker
            {...viewProp}
            value={value}
            minDate={minDate}
            disableFuture={disableFuture}
            toolbarTitle="Datum auswählen"
            onChange={onChange}
            minDateMessage={t("common.datePicker.minDateMessage")}
            maxDateMessage={t("common.datePicker.maxDateMessage")}
            invalidDateMessage={t("common.datePicker.invalidDateMessage")}
            renderDay={renderDay}
            labelFunc={labelFunc}
            format={format}
            showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
            style={{width: "100%"}}
            renderInput={props => <TextField style={{width: "100%"}} variant={variant} {...props} />}
            inputFormat={inputFormat}
            disablePast={disablePast}
            disableCloseOnSelect={false}/>
    );
};
CustomDatePicker.defaultProps = {
    disableFuture: true,
    inputFormat: "dd/MM/yyyy",
    views: undefined,
    variant: "outlined",
    showDaysOutsideCurrentMonth: false
};
CustomDatePicker.propTypes = {
    views: PropTypes.array,
    values: PropTypes.oneOfType([
       PropTypes.string,
       PropTypes.instanceOf(Date),
    ]),
    onChange: PropTypes.func,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    minDate: PropTypes.object,
    disableFuture: PropTypes.bool,
    format: PropTypes.string,
    renderDay: PropTypes.element,
    labelFunc: PropTypes.func,
    inputFormat: PropTypes.string,
    variant: PropTypes.string,
    showDaysOutsideCurrentMonth:PropTypes.bool,
    style: PropTypes.object
};
export default CustomDatePicker;