export const REQUEST_TIME_RECORDINGS = "REQUEST_TIME_RECORDINGS";
export const RECEIVE_TIME_RECORDINGS = "RECEIVE_TIME_RECORDINGS";

export const REQUEST_ADD_TIME_RECORDINGS = "REQUEST_ADD_TIME_RECORDINGS";
export const RECEIVE_ADD_TIME_RECORDINGS = "RECEIVE_ADD_TIME_RECORDINGS";

export const REQUEST_UPDATE_TIME_RECORDINGS = "REQUEST_UPDATE_TIME_RECORDINGS";
export const RECEIVE_UPDATE_TIME_RECORDINGS = "RECEIVE_UPDATE_TIME_RECORDINGS";

export const REQUEST_DELETE_TIME_RECORDINGS = "REQUEST_DELETE_TIME_RECORDINGS";
export const RECEIVE_DELETE_TIME_RECORDINGS = "RECEIVE_DELETE_TIME_RECORDINGS";

export const REQUEST_TIME_RECORDINGS_BY_ID = "REQUEST_TIME_RECORDINGS_BY_ID";
export const RECEIVE_TIME_RECORDINGS_BY_ID = "RECEIVE_TIME_RECORDINGS_BY_ID";

export const requestTimeRecordings = data => ({
    type: REQUEST_TIME_RECORDINGS,
    data,
});
export const receiveTimeRecordings = data => ({
    type: RECEIVE_TIME_RECORDINGS,
    data,
});


export const requestAddTimeRecordings = data => ({
    type: REQUEST_ADD_TIME_RECORDINGS,
    data
});

export const receiveAddTimeRecordings = data => ({
    type: RECEIVE_ADD_TIME_RECORDINGS,
    data
});


export const requestUpdateTimeRecordings = data => ({
    type: REQUEST_UPDATE_TIME_RECORDINGS,
    data
});

export const receiveUpdateTimeRecording = data => ({
    type: RECEIVE_UPDATE_TIME_RECORDINGS,
    data,
});

export const requestDeleteTimeRecording = data => ({
    type: REQUEST_DELETE_TIME_RECORDINGS,
    data
});

export const receiveDeleteTimeRecording = data => ({
   type: RECEIVE_DELETE_TIME_RECORDINGS,
   data 
});

export const requestTimeRecordingById = data => ({
    type: REQUEST_TIME_RECORDINGS_BY_ID,
    data
});

export const receiveTimeRecordingById = data => ({
    type: RECEIVE_TIME_RECORDINGS_BY_ID,
    data
})