import React, { useRef, useEffect } from "react";

import { ChangeEvent } from "react";

import "./PinInput.css";

type PinInputProps = {
  length: number,
  pin: string[],
  currentIndex: number,
  setPin: (pin: string[]) => void,
  setCurrentIndex: (index: number | ((prevIdx: number) => number)) => void;
}

const PinInput: React.FC<PinInputProps> = ({ length, pin, setPin, currentIndex, setCurrentIndex  }) => {
  const inputRefs = useRef<Array<React.RefObject<HTMLInputElement>>>([]);;

  useEffect(() => {
    // Focus on the first input when the component mounts
    if (inputRefs.current[0]) {
      inputRefs.current[0].current?.focus();
    }
  }, []);

  // Ensure the refs array is the correct length
  inputRefs.current = Array(length + 1)
    .fill(null)
    .map((_, i) => inputRefs.current[i] ?? React.createRef());

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement> , index: number) => {
    if (e.key === "Backspace") {
      if (index > 0) {
        if (pin[index] === "") {
          // Move focus to the previous input if current input is empty
          inputRefs.current[index - 1].current?.focus();
        }
        setCurrentIndex((prevIdx: number) => prevIdx - 1);
      }
    }
  };

  const handlePinClick = () => {
    if (currentIndex < length - 1) {
      if (!pin[0]) {
        inputRefs.current[0].current?.focus();
      } else {
        inputRefs.current[currentIndex + 1].current?.focus();
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      // Only allow numeric values
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);
      // Move focus to the next input
      if (value) {
        if (index < length - 1) {
          inputRefs.current[index + 1].current?.focus();
        }

        if (index === length - 1) {
          inputRefs.current[index].current?.blur();
        }

        setCurrentIndex(index);
      }
    }
  };

  return (
    <div className="pin-container" onClick={handlePinClick}>
      {pin.map((digit: string, index: number) => (
        <input
          key={index}
          ref={inputRefs.current[index]}
          type="text"
          maxLength={1}
          value={index < currentIndex ? "*" : digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="pin-input"
          pattern="[0-9]*"
          inputMode="numeric"
        />
      ))}
    </div>
  );
};

export default PinInput;
