import React from "react"
import CustomModal from "../../common/modals/CustomModal";
import {connect} from "react-redux"
import moment from "moment";

const LastLogInsModal = (props) => {
    const {onClose, lastLogIns, loading} = props;
    const noData = lastLogIns.length === 0;
    const lastLoginData = moment(lastLogIns).format('DD MMMM YYYY, h:mm:ss a');

    const content = noData ? [(
        <h6 key="noData">Sorry, there's no login data for this user.</h6>
    )] : [<h6><b>Last Login:</b> {lastLoginData}</h6>];


    return (
        <CustomModal contents={content} onClose={onClose} title={"Last Login"} loading={loading}/>
    )
}

function mapStatetoProp(state){
    return {
        lastLogIns: state.driveboxes.lastLogIns,
        loading: state.driveboxes.loading
    }
}

export default connect(mapStatetoProp)(LastLogInsModal);