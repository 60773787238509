import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  MandatorGetRequest,
  MandatorAddRequest,
  MandatorUpdateByIdRequest,
  MandatorNotificationRequest,
  MandatorDriveboxesGetRequest,
  MandatorDriveboxAddRequest,
  MandatorDriveboxUpdateRequest,
  MandatorDriveboxDeleteRequest,
  MandatorUsersGetRequest,
  MandatorUsersAddRequest,
  MandatorSuperUserGetRequest,
  MandatorSuperUserUpdateRequest,
  MandatorSuperUserAddRequest,
  MandatorSuperUserUpdatePasswordRequest,
  MandatorUsersWelcomeMailRequest,
  DriveBoxPositionsRequest,
  DriveBoxEventsRequest,
  MandatorTripsGetRequest,
} from "../types/mandator/mandatorRequest.types";
import {
  MandatorResponse,
  MandatorResponseInfo,
  MandatorDriveBoxesResponse,
  MandatorDriveBoxesResponseInfo,
  MandatorSuperUserResponse,
  DriveBoxEventsResponse,
  MandatorDriveboxHistoryResponse,
  MandatorVehicleRegistrationResponse,
  MandatorDriveboxLastSignalResponse,
  MandatorLastLoginResponse,
} from "../types/mandator/mandatorResponse.types";

import { DriveBoxPositionResponse } from "../types/vehicle/vehicleResponse.types";

import {
  UserResponseInfo,
  UserResponse,
} from "../types/user/userResponse.types";

import {
  RequestMandators,
  ReceiveMandators,
  RequestMandatorById,
  ReceiveMandatorById,
  RequestAddMandator,
  ReceiveAddMandator,
  RequestUpdateMandator,
  ReceiveUpdateMandator,
  RequestUpdateMandatorNotification,
  ReceiveUpdateMandatorNotification,
  RequestDeleteMandator,
  ReceiveDeleteMandator,
  RequestMandatorDriveboxes,
  ReceiveMandatorDriveboxes,
  RequestMandatorDriveboxToggleArchive,
  ReceiveMandatorDriveboxToggleArchive,
  SelectMandatorDrivebox,
  SelectAllMandatorDriveboxes,
  RequestMandatorDriveboxesByImei,
  ReceiveMandatorDriveboxesByImei,
  RequestAddMandatorDriveboxes,
  ReceiveAddMandatorDriveboxes,
  RequestUpdateMandatorDrivebox,
  ReceiveUpdateMandatorDrivebox,
  RequestDeleteMandatorDrivebox,
  ReceiveDeleteMandatorDrivebox,
  RequestMandatorUsers,
  ReceiveMandatorUsers,
  RequestAddMandatorUsers,
  ReceiveAddMandatorUsers,
  RequestMandatorSuperUsers,
  ReceiveMandatorSuperUsers,
  RequestUpdateMandatorSuperUser,
  ReceiveUpdateMandatorSuperUser,
  RequestAddMandatorSuperUser,
  ReceiveAddMandatorSuperUser,
  RequestDeleteMandatorSuperUser,
  ReceiveDeleteMandatorSuperUser,
  RequestUpdateMandatorSuperUserPassword,
  ReceiveUpdateMandatorSuperUserPassword,
  RequestMandatorToggleArchive,
  ReceiveMandatorToggleArchive,
  RequestMandatorSurrogate,
  ReceiveMandatorSurrogate,
  RequestMandatorUserSurrogate,
  ReceiveMandatorUserSurrogate,
  RequestMandatorUserWelcomeMail,
  ReceiveMandatorUserWelcomeMail,
  RequestMandatorDriveboxPositions,
  ReceiveMandatorDriveboxPositions,
  RequestMandatorDriveboxEvents,
  ReceiveMandatorDriveboxEvents,
  RequestMandatorTrips,
  ReceiveMandatorTrips,
  RequestMandatorDriveBoxHistory,
  ReceiveMandatorDriveBoxHistory,
  LoadDriveBoxHistory,
  RequestVehicleRegistration,
  ReceiveVehicleRegistration,
  RequestDriveBoxLastSignal,
  ReceiveDriveBoxLastSignal,
  RequestLastLogIns,
  ReceiveLastLogIns,
} from "../types/actions/actionMandator.types";

import {
  EntityToggleArchiveRequest,
  EntitiesToggleArchiveRequest,
} from "../types/global.types";
import { TripResponse } from "../types/trip/tripResponse.types";

export enum MANDATOR_ACTION_TYPES {
  REQUEST_MANDATORS = "REQUEST_MANDATORS",
  RECEIVE_MANDATORS = "RECEIVE_MANDATORS",

  REQUEST_MANDATOR_BY_ID = "REQUEST_MANDATOR_BY_ID",
  RECEIVE_MANDATOR_BY_ID = "RECEIVE_MANDATOR_BY_ID",

  REQUEST_ADD_MANDATOR = "REQUEST_ADD_MANDATOR",
  RECEIVE_ADD_MANDATOR = "RECEIVE_ADD_MANDATOR",

  REQUEST_UPDATE_MANDATOR = "REQUEST_UPDATE_MANDATOR",
  RECEIVE_UPDATE_MANDATOR = "RECEIVE_UPDATE_MANDATOR",

  REQUEST_UPDATE_MANDATOR_NOTIFICATION = "REQUEST_UPDATE_MANDATOR_NOTIFICATION",
  RECEIVE_UPDATE_MANDATOR_NOTIFICATION = "RECEIVE_UPDATE_MANDATOR_NOTIFICATION",

  REQUEST_DELETE_MANDATOR = "REQUEST_DELETE_MANDATOR",
  RECEIVE_DELETE_MANDATOR = "RECEIVE_DELETE_MANDATOR",

  REQUEST_MANDATOR_DRIVEBOXES = "REQUEST_MANDATOR_DRIVEBOXES",
  RECEIVE_MANDATOR_DRIVEBOXES = "RECEIVE_MANDATOR_DRIVEBOXES",

  REQUEST_MANDATOR_DRIVEBOXES_BYIMEI = "REQUEST_MANDATOR_DRIVEBOXES_BYIMEI",
  RECEIVE_MANDATOR_DRIVEBOXES_BYIMEI = "RECEIVE_MANDATOR_DRIVEBOXES_BYIMEI",

  REQUEST_ADD_MANDATOR_DRIVEBOXES = "REQUEST_ADD_MANDATOR_DRIVEBOXES",
  RECEIVE_ADD_MANDATOR_DRIVEBOXES = "RECEIVE_ADD_MANDATOR_DRIVEBOXES",

  REQUEST_UPDATE_MANDATOR_DRIVEBOX = "REQUEST_UPDATE_MANDATOR_DRIVEBOX",
  RECEIVE_UPDATE_MANDATOR_DRIVEBOX = "RECEIVE_UPDATE_MANDATOR_DRIVEBOX",

  REQUEST_DELETE_MANDATOR_DRIVEBOX = "REQUEST_DELETE_MANDATOR_DRIVEBOX",
  RECEIVE_DELETE_MANDATOR_DRIVEBOX = "RECEIVE_DELETE_MANDATOR_DRIVEBOX",

  REQUEST_MANDATOR_DRIVEBOX_TOGGLE_ARCHIVE = "REQUEST_MANDATOR_DRIVEBOX_TOGGLE_ARCHIVE",
  RECEIVE_MANDATOR_DRIVEBOX_TOGGLE_ARCHIVE = "RECEIVE_MANDATOR_DRIVEBOX_TOGGLE_ARCHIVE",

  SELECT_MANDATOR_DRIVEBOX = "SELECT_MANDATOR_DRIVEBOX",
  SELECT_ALL_MANDATOR_DRIVEBOXES = "SELECT_ALL_MANDATOR_DRIVEBOXES",

  REQUEST_MANDATOR_USERS = "REQUEST_MANDATOR_USERS",
  RECEIVE_MANDATOR_USERS = "RECEIVE_MANDATOR_USERS",

  REQEUST_ADD_MANDATOR_USERS = "REQEUST_ADD_MANDATOR_USERS",
  RECEIVE_ADD_MANDATOR_USERS = "RECEIVE_ADD_MANDATOR_USERS",

  REQUEST_MANDATOR_SUPERUSER = "REQUEST_MANDATOR_SUPERUSER",
  RECEIVE_MANDATOR_SUPERUSER = "RECEIVE_MANDATOR_SUPERUSER",

  REQEUST_UPDATE_MANDATOR_SUPERUSER = "REQEUST_UPDATE_MANDATOR_SUPERUSER",
  RECEIVE_UPDATE_MANDATOR_SUPERUSER = "RECEIVE_UPDATE_MANDATOR_SUPERUSER",

  REQUEST_ADD_MANDATOR_SUPERUSER = "REQUEST_ADD_MANDATOR_SUPERUSER",
  RECEIVE_ADD_MANDATOR_SUPERUSER = "RECEIVE_ADD_MANDATOR_SUPERUSER",

  REQUEST_DELETE_MANDATOR_SUPERUSER = "REQUEST_DELETE_MANDATOR_SUPERUSER",
  RECEIVE_DELETE_MANDATOR_SUPERUSER = "RECEIVE_DELETE_MANDATOR_SUPERUSER",

  REQUEST_UPDATE_MANDATOR_SUPERUSER_PASSWORD = "REQUEST_UPDATE_MANDATOR_SUPERUSER_PASSWORD",
  RECEIVE_UPDATE_MANDATOR_SUPERUSER_PASSWORD = "RECEIVE_UPDATE_MANDATOR_SUPERUSER_PASSWORD",

  REQUEST_MANDATOR_TOGGLE_ARCHIVE = "REQUEST_MANDATOR_TOGGLE_ARCHIVE",
  RECEIVE_MANDATOR_TOGGLE_ARCHIVE = "RECEIVE_MANDATOR_TOGGLE_ARCHIVE",

  REQUEST_MANDATOR_SURROGATE = "REQUEST_MANDATOR_SURROGATE",
  RECEIVE_MANDATOR_SURROGATE = "RECEIVE_MANDATOR_SURROGATE",

  REQUEST_MANDATOR_USER_SURROGATE = "REQUEST_MANDATOR_USER_SURROGATE",
  RECEIVE_MANDATOR_USER_SURROGATE = "RECEIVE_MANDATOR_USER_SURROGATE",

  REQUEST_MANDATOR_USER_WELCOME_MAIL = "REQUEST_MANDATOR_USER_WELCOME_MAIL",
  RECEIVE_MANDATOR_USER_WELCOME_MAIL = "RECEIVE_MANDATOR_USER_WELCOME_MAIL",

  REQUEST_MANDATOR_DRIVEBOX_POSITIONS = "REQUEST_MANDATOR_DRIVEBOX_POSITIONS",
  RECEIVE_MANDATOR_DRIVEBOX_POSITIONS = "RECEIVE_MANDATOR_DRIVEBOX_POSITIONS",

  REQUEST_MANDATOR_DRIVEBOX_EVENTS = "REQUEST_MANDATOR_DRIVEBOX_EVENTS",
  RECEIVE_MANDATOR_DRIVEBOX_EVENTS = "RECEIVE_MANDATOR_DRIVEBOX_EVENTS",

  REQUEST_MANDATOR_TRIPS = "REQUEST_MANDATOR_TRIPS",
  RECEIVE_MANDATOR_TRIPS = "RECEIVE_MANDATOR_TRIPS",

  REQUEST_DRIVEBOX_HISTORY = "REQUEST_DRIVEBOX_HISTORY",
  RECEIVE_DRIVEBOX_HISTORY = "RECEIVE_DRIVEBOX_HISTORY",

  REQUEST_VEHICLE_REGISTRATION = "REQUEST_VEHICLE_REGISTRATION",
  RECEIVE_VEHICLE_REGISTRATION = "RECEIVE_VEHICLE_REGISTRATION",

  REQUEST_DRIVEBOX_LAST_SIGNAL = "REQUEST_DRIVEBOX_LAST_SIGNAL",
  RECEIVE_DRIVEBOX_LAST_SIGNAL = "RECEIVE_DRIVEBOX_LAST_SIGNAL",

  REQUEST_USER_LAST_LOGIN = "REQUEST_USER_LAST_LOGIN",
  RECEIVE_USER_LAST_LOGIN = "RECEIVE_USER_LAST_LOGIN",

  LOAD_DRIVEBOX_HISTORY = "LOAD_DRIVEBOX_HISTORY",
}

export const requestMandators = withMatcher(
  (data: MandatorGetRequest): RequestMandators => {
    return createAction(MANDATOR_ACTION_TYPES.REQUEST_MANDATORS, data);
  }
);

export const receiveMandators = withMatcher(
  (data: MandatorResponse): ReceiveMandators => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_MANDATORS, data);
  }
);

export const requestMandatorById = withMatcher(
  (mandatorId: string): RequestMandatorById => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_BY_ID,
      mandatorId
    );
  }
);

export const receiveMandatorById = withMatcher(
  (data: MandatorGetRequest): ReceiveMandatorById => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_BY_ID, data);
  }
);

export const requestAddMandator = withMatcher(
  (data: MandatorAddRequest): RequestAddMandator => {
    return createAction(MANDATOR_ACTION_TYPES.REQUEST_ADD_MANDATOR, data);
  }
);

export const receiveAddMandator = withMatcher(
  (data: MandatorResponseInfo): ReceiveAddMandator => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_ADD_MANDATOR, data);
  }
);

export const requestUpdateMandator = withMatcher(
  (data: MandatorUpdateByIdRequest): RequestUpdateMandator => {
    return createAction(MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR, data);
  }
);

export const receiveUpdateMandator = withMatcher(
  (data: MandatorResponseInfo): ReceiveUpdateMandator => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_UPDATE_MANDATOR, data);
  }
);

export const requestUpdateMandatorNotification = withMatcher(
  (data: MandatorNotificationRequest): RequestUpdateMandatorNotification => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR_NOTIFICATION,
      data
    );
  }
);

export const receiveUpdateMandatorNotification = withMatcher(
  (data: UserResponseInfo): ReceiveUpdateMandatorNotification => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_UPDATE_MANDATOR_NOTIFICATION,
      data
    );
  }
);

export const requestDeleteMandator = withMatcher(
  (mandatorId: string): RequestDeleteMandator => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_DELETE_MANDATOR,
      mandatorId
    );
  }
);

export const receiveDeleteMandator = withMatcher(
  (data: MandatorResponseInfo): ReceiveDeleteMandator => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_DELETE_MANDATOR, data);
  }
);

export const requestMandatorDriveboxes = withMatcher(
  (data: MandatorDriveboxesGetRequest): RequestMandatorDriveboxes => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOXES,
      data
    );
  }
);

export const receiveMandatorDriveboxes = withMatcher(
  (data: MandatorDriveBoxesResponse): ReceiveMandatorDriveboxes => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_DRIVEBOXES,
      data
    );
  }
);

export const requestMandatorDriveboxToggleArchive = withMatcher(
  (data: EntityToggleArchiveRequest): RequestMandatorDriveboxToggleArchive => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOX_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const receiveMandatorDriveboxToggleArchive = withMatcher(
  (
    data: MandatorDriveBoxesResponseInfo
  ): ReceiveMandatorDriveboxToggleArchive => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_DRIVEBOX_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const selectMandatorDrivebox = withMatcher(
  (data: MandatorDriveBoxesResponseInfo): SelectMandatorDrivebox => {
    return createAction(MANDATOR_ACTION_TYPES.SELECT_MANDATOR_DRIVEBOX, data);
  }
);

export const selectAllMandatorDriveboxes = withMatcher(
  (): SelectAllMandatorDriveboxes => {
    return createAction(MANDATOR_ACTION_TYPES.SELECT_ALL_MANDATOR_DRIVEBOXES);
  }
);

export const requestMandatorDriveboxesByImei = withMatcher(
  (driveBoxImei: string): RequestMandatorDriveboxesByImei => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOXES_BYIMEI,
      driveBoxImei
    );
  }
);

export const receiveMandatorDriveboxesByImei = withMatcher(
  (data: MandatorDriveBoxesResponseInfo): ReceiveMandatorDriveboxesByImei => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_DRIVEBOXES_BYIMEI,
      data
    );
  }
);

export const requestAddMandatorDriveboxes = withMatcher(
  (data: MandatorDriveboxAddRequest): RequestAddMandatorDriveboxes => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_ADD_MANDATOR_DRIVEBOXES,
      data
    );
  }
);

export const receiveAddMandatorDriveboxes = withMatcher(
  (data: MandatorDriveBoxesResponseInfo): ReceiveAddMandatorDriveboxes => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_ADD_MANDATOR_DRIVEBOXES,
      data
    );
  }
);

export const requestUpdateMandatorDrivebox = withMatcher(
  (data: MandatorDriveboxUpdateRequest): RequestUpdateMandatorDrivebox => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR_DRIVEBOX,
      data
    );
  }
);

export const receiveUpdateMandatorDrivebox = withMatcher(
  (data: MandatorDriveBoxesResponseInfo): ReceiveUpdateMandatorDrivebox => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_UPDATE_MANDATOR_DRIVEBOX,
      data
    );
  }
);

export const requestDeleteMandatorDrivebox = withMatcher(
  (data: MandatorDriveboxDeleteRequest): RequestDeleteMandatorDrivebox => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_DELETE_MANDATOR_DRIVEBOX,
      data
    );
  }
);

export const receiveDeleteMandatorDrivebox = withMatcher(
  (): ReceiveDeleteMandatorDrivebox => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_DELETE_MANDATOR_DRIVEBOX);
  }
);

export const requestMandatorUsers = withMatcher(
  (data: MandatorUsersGetRequest): RequestMandatorUsers => {
    return createAction(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_USERS, data);
  }
);

export const receiveMandatorUsers = withMatcher(
  (data: UserResponse): ReceiveMandatorUsers => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_USERS, data);
  }
);

export const requestAddMandatorUsers = withMatcher(
  (data: MandatorUsersAddRequest): RequestAddMandatorUsers => {
    return createAction(MANDATOR_ACTION_TYPES.REQEUST_ADD_MANDATOR_USERS, data);
  }
);

export const receiveAddMandatorUsers = withMatcher(
  (data: UserResponseInfo): ReceiveAddMandatorUsers => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_ADD_MANDATOR_USERS, data);
  }
);

export const requestMandatorSuperUsers = withMatcher(
  (data: MandatorSuperUserGetRequest): RequestMandatorSuperUsers => {
    return createAction(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_SUPERUSER, data);
  }
);

export const receiveMandatorSuperUsers = withMatcher(
  (data: MandatorSuperUserResponse[]): ReceiveMandatorSuperUsers => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_SUPERUSER, data);
  }
);

export const requestUpdateMandatorSuperUser = withMatcher(
  (data: MandatorSuperUserUpdateRequest): RequestUpdateMandatorSuperUser => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQEUST_UPDATE_MANDATOR_SUPERUSER,
      data
    );
  }
);

export const receiveUpdateMandatorSuperUser = withMatcher(
  (data: MandatorSuperUserResponse): ReceiveUpdateMandatorSuperUser => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_UPDATE_MANDATOR_SUPERUSER,
      data
    );
  }
);

export const requestAddMandatorSuperUser = withMatcher(
  (data: MandatorSuperUserAddRequest): RequestAddMandatorSuperUser => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_ADD_MANDATOR_SUPERUSER,
      data
    );
  }
);

export const receiveAddMandatorSuperUser = withMatcher(
  (data: MandatorSuperUserResponse): ReceiveAddMandatorSuperUser => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_ADD_MANDATOR_SUPERUSER,
      data
    );
  }
);

export const requestDeleteMandatorSuperUser = withMatcher(
  (userId: string): RequestDeleteMandatorSuperUser => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_DELETE_MANDATOR_SUPERUSER,
      userId
    );
  }
);

export const receiveDeleteMandatorSuperUser = withMatcher(
  (data: MandatorSuperUserResponse): ReceiveDeleteMandatorSuperUser => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_DELETE_MANDATOR_SUPERUSER,
      data
    );
  }
);

export const requestUpdateMandatorSuperUserPassword = withMatcher(
  (
    data: MandatorSuperUserUpdatePasswordRequest
  ): RequestUpdateMandatorSuperUserPassword => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_UPDATE_MANDATOR_SUPERUSER_PASSWORD,
      data
    );
  }
);

export const receiveUpdateMandatorSuperUserPassword = withMatcher(
  (data: MandatorSuperUserResponse): ReceiveUpdateMandatorSuperUserPassword => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_UPDATE_MANDATOR_SUPERUSER_PASSWORD,
      data
    );
  }
);

export const requestMandatorToggleArchive = withMatcher(
  (mandatorId: string): RequestMandatorToggleArchive => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_TOGGLE_ARCHIVE,
      mandatorId
    );
  }
);

export const receiveMandatorToggleArchive = withMatcher(
  (data: MandatorResponseInfo): ReceiveMandatorToggleArchive => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const requestMandatorSurrogate = withMatcher(
  (madatorId: string): RequestMandatorSurrogate => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_SURROGATE,
      madatorId
    );
  }
);

export const receiveMandatorSurrogate = withMatcher(
  (data: UserResponseInfo): ReceiveMandatorSurrogate => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_SURROGATE, data);
  }
);

export const requestMandatorUserSurrogate = withMatcher(
  (userId: string): RequestMandatorUserSurrogate => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_USER_SURROGATE,
      userId
    );
  }
);

export const receiveMandatorUserSurrogate = withMatcher(
  (data: UserResponseInfo): ReceiveMandatorUserSurrogate => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_USER_SURROGATE,
      data
    );
  }
);

export const requestMandatorUserWelcomeMail = withMatcher(
  (data: MandatorUsersWelcomeMailRequest): RequestMandatorUserWelcomeMail => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_USER_WELCOME_MAIL,
      data
    );
  }
);

export const receiveMandatorUserWelcomeMail = withMatcher(
  (): ReceiveMandatorUserWelcomeMail => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_USER_WELCOME_MAIL
    );
  }
);

export const requestMandatorDriveboxPositions = withMatcher(
  (data: DriveBoxPositionsRequest): RequestMandatorDriveboxPositions => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOX_POSITIONS,
      data
    );
  }
);

export const receiveMandatorDriveboxPositions = withMatcher(
  (data: DriveBoxPositionResponse): ReceiveMandatorDriveboxPositions => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_DRIVEBOX_POSITIONS,
      data
    );
  }
);

export const requestMandatorDriveboxEvents = withMatcher(
  (data: DriveBoxEventsRequest): RequestMandatorDriveboxEvents => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_DRIVEBOX_EVENTS,
      data
    );
  }
);

export const receiveMandatorDriveboxEvents = withMatcher(
  (data: DriveBoxEventsResponse): ReceiveMandatorDriveboxEvents => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_DRIVEBOX_EVENTS,
      data
    );
  }
);

export const requestMandatorTrips = withMatcher(
  (data: MandatorTripsGetRequest): RequestMandatorTrips => {
    return createAction(MANDATOR_ACTION_TYPES.REQUEST_MANDATOR_TRIPS, data);
  }
);

export const receiveMandatorTrips = withMatcher(
  (data: TripResponse): ReceiveMandatorTrips => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_MANDATOR_TRIPS, data);
  }
);

export const requestMandatorDriveBoxHistory = withMatcher(
  (driveBoxId: string): RequestMandatorDriveBoxHistory => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_DRIVEBOX_HISTORY,
      driveBoxId
    );
  }
);

export const receiveMandatorDriveBoxHistory = withMatcher(
  (data: MandatorDriveboxHistoryResponse[]): ReceiveMandatorDriveBoxHistory => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_DRIVEBOX_HISTORY, data);
  }
);

export const loadDriveBoxHistory = withMatcher((): LoadDriveBoxHistory => {
  return createAction(MANDATOR_ACTION_TYPES.LOAD_DRIVEBOX_HISTORY);
});

export const requestVehicleRegistration = withMatcher(
  (userId: string): RequestVehicleRegistration => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_VEHICLE_REGISTRATION,
      userId
    );
  }
);

export const receiveVehicleRegistration = withMatcher(
  (data: MandatorVehicleRegistrationResponse[]): ReceiveVehicleRegistration => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_VEHICLE_REGISTRATION,
      data
    );
  }
);

export const requestDriveBoxLastSignal = withMatcher(
  (imei: string): RequestDriveBoxLastSignal => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_DRIVEBOX_LAST_SIGNAL,
      imei
    );
  }
);

export const receiveDriveBoxLastSignal = withMatcher(
  (data: MandatorDriveboxLastSignalResponse): ReceiveDriveBoxLastSignal => {
    return createAction(
      MANDATOR_ACTION_TYPES.RECEIVE_DRIVEBOX_LAST_SIGNAL,
      data
    );
  }
);

export const requestLastLogIns = withMatcher(
  (mandatorId: string): RequestLastLogIns => {
    return createAction(
      MANDATOR_ACTION_TYPES.REQUEST_USER_LAST_LOGIN,
      mandatorId
    );
  }
);

export const receiveLastLogIns = withMatcher(
  (data: MandatorLastLoginResponse): ReceiveLastLogIns => {
    return createAction(MANDATOR_ACTION_TYPES.RECEIVE_USER_LAST_LOGIN, data);
  }
);
