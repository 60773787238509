import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { requestUpdateMandatorDrivebox } from "../../../../actions/actionMandators";

import MandatorSelect from "../../../common/selects/specificSelects/MandatorSelect";
import FormModal from "../../../common/modals/FormModal";
import FormBuilder from "../../../../managers/formBuilder";

import { Input } from "antd";
import Label from "../../../common/views/Label";

import { useInputChangeHandler } from "../../../../hooks/useInputChangeHandler";
import { MODAL_TYPES } from "../../../common/modals/modal.utils";

const getDefaultInputValues = (drivebox) => ({
    model: drivebox?.model,
    simCardNumber: drivebox?.simCardNumber,
    phoneNumber: drivebox?.phoneNumber,
    mandatorId: drivebox?.mandator?.id,
});

const MandatorDriveboxModal = (props) => {
    const { onClose, drivebox, archive } = props;
    const { handleInputChange, fields } = useInputChangeHandler(getDefaultInputValues(drivebox));
    const { model, mandatorId, simCardNumber, phoneNumber } = fields;

    const handleOnSave = () => {
        props.requestUpdateMandatorDrivebox({ ...fields, id: drivebox.id });
        onClose();
    };
        const inputs = [
            new FormBuilder.FormInput("IMEI", <Label value={drivebox.imei} />).withValue(drivebox.imei).build(),
            new FormBuilder.FormInput("Model", <Input value={model} onChange={(e) => handleInputChange("model", e.target.value)} />)
                .withValue(model)
                .withKey("model")
                .build(),
            new FormBuilder.FormInput("Sim Card", <Input value={simCardNumber} onChange={(e) => handleInputChange("simCardNumber", e.target.value)} />)
                .withValue(simCardNumber)
                .withKey("simCardNumber")
                .build(),
            new FormBuilder.FormInput("PhoneNumber", <Input value={phoneNumber} onChange={(e) => handleInputChange("phoneNumber", e.target.value)} />)
                .withValue(phoneNumber)
                .withKey("phoneNumber")
                .build(),
            new FormBuilder.FormInput("Vehicle", <Label value={drivebox?.vehicle?.name} />).withValue(drivebox.vehicle && drivebox.vehicle?.name).build(),
            drivebox.vehicle
                ? new FormBuilder.FormInput("Mandator",
                <Label value={drivebox.mandator ? drivebox.mandator?.name : ""} />).withValue(drivebox.mandator ? drivebox?.mandator?.name : "").build()
                : new FormBuilder.FormInput("Mandator", <MandatorSelect value={mandatorId} onChange={(value) => handleInputChange("mandatorId", value)}  disabled={drivebox.vehicle}/>)
                    .withValue(mandatorId)
                    .withKey("mandatorId")
                    .build(),
        ];
        const archiveInputs = [
            new FormBuilder.FormInput("Name", <Label value={drivebox.imei} />).withValue(drivebox.imei).build(),
            new FormBuilder.FormInput("Model", <Input value={model} onChange={(e) => handleInputChange("model", e.target.value)} />)
                .withValue(model)
                .withKey("model")
                .build(),
            new FormBuilder.FormInput("Sim Card", <Input value={simCardNumber} onChange={(e) => handleInputChange("simCardNumber", e.target.value)} />)
                .withValue(simCardNumber)
                .withKey("simCardNumber")
                .build(),
            new FormBuilder.FormInput("PhoneNumber", <Input value={phoneNumber} onChange={(e) => handleInputChange("phoneNumber", e.target.value)} />)
                .withValue(phoneNumber)
                .withKey("phoneNumber")
                .build(),
            new FormBuilder.FormInput("Kommentar", <Label value={drivebox.archiveComment} />).withValue(drivebox.archiveComment).build(),
        ];
    return (
        <FormModal onSave={handleOnSave} formType={MODAL_TYPES.withoutValidation} onClose={onClose} title="Drivebox bearbeiten"
                          inputs={archive ? archiveInputs : inputs}/>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestUpdateMandatorDrivebox,
        },
        dispatch,
    );
}

function mapStateToProp(state) {
    return { mandators: state.mandators.entities };
}

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(MandatorDriveboxModal);
