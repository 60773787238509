import React, { useEffect, useState } from "react";
import { usePagination } from "../../hooks/usePagination";

import Page from "../common/container/Page";
import FilterToolbar from "../common/toolbar/FilterToolbar";
import { menu } from "../../managers/pathManager";
import TripTable from "../trips/TripTable";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid, makeStyles } from "@material-ui/core";
import { requestGroups } from "../../actions/actionGroups";
import {
  requestTrips,
  selectTrip,
  selectAllTrips,
  requestLockTrips,
  requestUnlockTrips,
  deselectAllTrips,
  loadTrip,
} from "../../actions/actionTrips";
import { Redirect } from "react-router-dom";
import { useModalStatus } from "../../hooks/useModalStatus";
import TrackModal from "../trips/tripsModals/TrackModal";
import TripEditModal from "../trips/tripsModals/TripEditModal";
import TripBulkEditModal from "../trips/tripsModals/TripBulkEditModal";
import GroupTripToolbar from "./GroupTripToolbar";
import { periods } from "../../models/Period";
import { fromToByPeriod } from "../../managers/timeManager";

const useStyles = makeStyles((theme) => ({
  vehicleCard: {
    marginRight: "4px",
  },
}));

function GroupTripPage() {
  const classes = useStyles();
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const group = useSelector((state) => state.group);
  const trips = useSelector((state) => state.trips.entities);
  const [trip, setTrip] = useState("");
  const loading = useSelector((state) => state.trips.loading);
  const [initialPageLoading, setInitialPageLoading] = useState(true);

  const { api } = filter;
  const filterGroupName = api.filterGroupName;
  const groupChildren = group.children;
  const foundGroupId = groupChildren?.filter(
    (g) => g.name === filterGroupName
  )[0]?.id;

  const currentDay = fromToByPeriod(periods.month.id, new Date());
  const dateRange = {
    period: periods.month.id,
    from:
      api && api.from && api.period === periods.month.id
        ? new Date(api.from).toISOString()
        : currentDay.from.toISOString(),
    to:
      api && api.to && api.period === periods.month.id
        ? new Date(api.to).toISOString()
        : currentDay.to.toISOString(),
  };

  useEffect(() => {
    dispatch(requestGroups());
  }, []);

  useEffect(() => {
    if (!foundGroupId) return;

    dispatch(loadTrip());
    dispatch(
      requestTrips({
        groupIds: foundGroupId,
        page: page + 1,
        perPage: rowsPerPage,
        from: dateRange.from,
        to: dateRange.to,
        IsLocked: api.filterOnlyOpenTrips === true ? false : undefined,
        WithoutDriver: api.filterOnlyWithoutDrivers === true ? true : undefined,
      })
    );

    setInitialPageLoading(false);
  }, [foundGroupId, page, rowsPerPage]);

  const {
    modal: trackModal,
    open: openTrackModal,
    close: closeTrackModal,
  } = useModalStatus();
  const {
    modal: editModal,
    open: openEditModal,
    close: closeEditModal,
  } = useModalStatus();
  const {
    modal: bulkEditModal,
    open: openBulkEditModal,
    close: closeBulkEditModal,
  } = useModalStatus();

  function handleLockTrip(trip) {
    dispatch(requestLockTrips({ tripIds: [trip.id] }));
  }

  function handleLockTrips() {
    dispatch(
      requestLockTrips({
        tripIds: trips.filter((t) => t.isSelected).map((tr) => tr.id),
      })
    );
  }

  function handleUnlockTrip(trip) {
    dispatch(requestUnlockTrips({ tripIds: [trip.id] }));
  }

  const toolbar = (
    <GroupTripToolbar
      onLock={handleLockTrips}
      onBulkEdit={openBulkEditModal}
      name={filterGroupName}
    />
  );

  if (!filterGroupName) return <Redirect to={menu.hwnodashboard.path} />;

  return (
    <Page activePage={menu.groupTrip.key} toolbar={toolbar}>
      <FilterToolbar />

      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.vehicleCard}>
            <TripTable
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={changePage}
              handleChangeRowsPerPage={changeRowPerPage}
              onSelect={(r) => dispatch(selectTrip(r))}
              onSelectAll={(r) => dispatch(selectAllTrips(r))}
              onLock={handleLockTrip}
              onUnlock={handleUnlockTrip}
              onTrack={(trip) => {
                setTrip(trip);
                openTrackModal();
              }}
              showVehicle
              loading={loading || initialPageLoading}
            />
          </Card>
        </Grid>
      </Grid>
      {trackModal && (
        <TrackModal
          id={trip.id}
          onEdit={openEditModal}
          onClose={closeTrackModal}
        />
      )}
      {editModal && <TripEditModal tripId={trip.id} onClose={closeEditModal} />}
      {bulkEditModal && (
        <TripBulkEditModal
          page={page}
          rowsPerPage={rowsPerPage}
          onClose={() => {
            closeBulkEditModal();
            dispatch(deselectAllTrips());
          }}
        />
      )}
    </Page>
  );
}

// GroupTripPage.propTypes = {};

export default GroupTripPage;
