import { put, call, takeEvery } from "typed-redux-saga/macro";
import {
  addInspection,
  deleteInspection,
  getInspection,
  getNextInspection,
} from "./api";
import { handleError } from "../managers/sagaManager";

import {
  RequestInspection,
  RequestNextInspection,
  RequestAddInspection,
  RequestDeleteInspection,
} from "../types/actions/actionVehicleInspection.types";

import {
  receiveAddInspection,
  receiveDeleteInspection,
  receiveInspection,
  receiveNextInspection,
  INSPECTION_ACTION_TYPES,
} from "../actions/actionInspection";

function* getInspectionData(action: RequestInspection) {
  try {
    const response = yield* call(getInspection, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveInspection(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* getNextInspectionData(action: RequestNextInspection) {
  try {
    const response = yield* call(getNextInspection, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveNextInspection(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* addInspectionData(action: RequestAddInspection) {
  try {
    const response = yield* call(addInspection, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAddInspection(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

function* deleteInspectionData(action: RequestDeleteInspection) {
  try {
    const response = yield* call(deleteInspection, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDeleteInspection(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    error.response?.data?.title && (yield* put(handleError(error)));
  }
}

export default function* sagaVehicleService() {
  yield* takeEvery(
    INSPECTION_ACTION_TYPES.REQUEST_INSPECTION,
    getInspectionData
  );
  yield* takeEvery(
    INSPECTION_ACTION_TYPES.REQUEST_NEXT_INSPECTION,
    getNextInspectionData
  );
  yield* takeEvery(
    INSPECTION_ACTION_TYPES.REQUEST_ADD_INSPECTION,
    addInspectionData
  );
  yield* takeEvery(
    INSPECTION_ACTION_TYPES.REQUEST_DELETE_INSPECTION,
    deleteInspectionData
  );
}
