import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { Tooltip } from '@material-ui/core';
import { customColors } from '../../generic/StylingOptions';
import { Checkbox } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
 
const baseUrl = window.location.origin + "/api";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


function PresetDialog(props) {
  let { addGeoFenceInState, currentUser } = props;
  
  const [open, setOpen] = useState(false);
  const [presetSelection, setPresetSelection] = useState([]);
  const { t } = useTranslation();

  const presetFences = [
    { id: 1, title: props.t('presetFences.lowerAustria') },
    { id: 2, title: props.t('presetFences.easternTyrol') },
    { id: 3, title: props.t('presetFences.burgenland') },
    { id: 4, title: props.t('presetFences.salzburg') },
    { id: 5, title: props.t('presetFences.upperAustria') },
    { id: 6, title: props.t('presetFences.styria') },
    { id: 7, title: props.t('presetFences.vienna') },
    { id: 8, title: props.t('presetFences.tyrol') },
    { id: 9, title: props.t('presetFences.vorarlberg') },
    { id: 10, title: props.t('presetFences.carinthia') },
    { id: 11, title: props.t('presetFences.austria') },
  ]

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setPresetSelection([]);
    setOpen(false);
  };

  function onConfirm() {
    for (let id of presetSelection) {
      axios({
        method: 'post',
        url: `${baseUrl}/geoFences/createPreset?preset=${id}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: currentUser
        }
      }).then(res => {
        addGeoFenceInState(res.data.ID, res.data.Title, res.data.Polygon.coordinates, false, false);
        handleClose();
      }).catch(err => {
        console.log(`Could not create preset ${id}`);
        handleClose();
      })
    }
  }

  function handleChange(elem) {
    if (presetSelection.includes(elem.id)) {
      let foundIndex = presetSelection.indexOf(elem.id);
      if (foundIndex > -1) {
          let newSelection = presetSelection;
          newSelection.splice(foundIndex, 1);
          setPresetSelection([...newSelection]);
      }
    } else {
      setPresetSelection([...presetSelection, elem.id]);
    }
  }

  return (
    <div>
      <Tooltip enterDelay={500} title={props.t('buttonBar.presets')}>
        <IconButton onClick={handleClickOpen} style={{ color: customColors.dark, margin: '2px', marginLeft: '-10px', padding: '15px' }}>
          <LibraryAddIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("buttonBar.presets")}
        </DialogTitle>
        <DialogContent dividers>
          {presetFences.map(elem => {
            return (
              <div key={`cb_preset_${elem.id}`}>
                <Checkbox
                  id={`cb_preset_${elem.id}`}
                  onChange={() => handleChange(elem)}
                  style={{ color: customColors.ilogsBlue }}
                  checked={presetSelection.includes(elem.id)}
                ></Checkbox>
                <div className="presetListTitle">
                  {elem.title}
                </div>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button disabled={presetSelection.length <= 0} onClick={() => { onConfirm() }} style={{ backgroundColor: (presetSelection.length <= 0 ? customColors.disabled : customColors.ilogsBlueBright), color: customColors.bright }}>
              {t("dialogs.buttons.create")}
          </Button>
          <Button onClick={() => { handleClose() }}>
              {t("dialogs.buttons.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(PresetDialog);