import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormModal from "../../common/modals/FormModal";
import VehicleProfileSelect from "../../common/selects/specificSelects/VehicleProfileSelect";
import TripTypeSelect from "../../common/selects/specificSelects/TripTypeSelect";

import { getIdsFromGroupValues } from "../../../managers/groupManager";
import { setFilter } from "../../../actions/actionFilter";
import FormBuilder from "../../../managers/formBuilder";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

const TripFilterModal = props => {
    const {groups, vehicleProfile, tripType, withoutDriver, vehicleId, driverId} = props.filter.initial;
    const {tree} = props;
    const { t } = useTranslation();

    function handleFilter(values) {
        const apiFilter = {
            groupIds: getIdsFromGroupValues(tree, values.groups),
            vehicleType: values.vehicleProfile,
            tripType: values.tripType,
            withoutDriver: values.withoutDriver,
            vehicleIds: values.vehicleId,
            driverIds: values.driverId,
        };

        props.setFilter(values, apiFilter);
        props.onClose();
    }

    const inputs = [
        new FormBuilder.FormInput(t("trip.filtermodal.vehicleprofile"), <VehicleProfileSelect/>)
            .withValue(vehicleProfile)
            .withKey("vehicleProfile")
            .build(),
        new FormBuilder.FormInput(t("trip.filtermodal.tripType"), <TripTypeSelect/>)
            .withValue(tripType)
            .withKey("tripType")
            .build(),
        new FormBuilder.FormInput(t("trip.filtermodal.withoutDriver"), <Switch/>)
            .withValue(withoutDriver)
            .withKey("withoutDriver")
            .withValuePropName("checked")
            .build(),
    ];

    return <FormModal action="filter" inputs={inputs} onSave={handleFilter} onClose={props.onClose}
                      title={t("trip.filtermodal.title")}/>;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFilter}, dispatch);
}

function mapStateToProp(state) {
    return {tree: state.tree, filter: state.filter};
}

export default connect(
    mapStateToProp,
    mapDispatchToProps
)(TripFilterModal);
