import { takeLatest, put, call, takeEvery } from "typed-redux-saga/macro";

import {
    requestVehicleById,
    receiveAddCalibration,
    receiveAddVehicle,
    receiveAddVehicleDriver,
    receiveCalibrations,
    receiveDeleteVehicle,
    receiveDeleteVehicleDriver,
    receiveOpenTrack,
    receiveTracksOfVehicle,
    receiveUpdateVehicle,
    receiveUpdateVehicles,
    receiveVehicleById,
    receiveVehicles,
    receiveAllVehicles,
    receiveVehiclesLastPosition,
    receiveVehicleToggleArchive,
    receiveVehiclesToggleArchive,
    VEHICLE_ACTION_TYPES,

} from "../actions/actionVehicle";

import { handleError } from "../managers/sagaManager";

import { RequestVehicles,
    RequestAllVehicles,
    RequestVehiclesLastPosition,
    RequestVehicleById,
    RequestAddVehicle,
    RequestUpdateVehicle,
    RequestUpdateVehicles,
    RequestDeleteVehicle,
    RequestVehicleToggleArchive,
    RequestVehiclesToggleArchive,
    RequestAddVehicleDriver,
    RequestDeleteVehicleDriver,
    RequestOpenTrack,
    RequestCalibrations,
    RequestAddCalibration,
    RequestTracksOfVehicle
} from "../types/actions/actionVehicle.types";

import {
    addCalibration,
    addVehicle,
    addVehicleDriver,
    deleteCalibration,
    deleteVehicle,
    deleteVehicleDriver,
    getCalibrations,
    getTracksByVehicle,
    getVehicleById,
    getVehicles,
    getVehicleLastPositions,
    updateVehicle,
    updateVehicleArchiveToggle,
    updateVehicles, 
    getOpenTrack, 
    updateVehiclesArchiveToggle,
} from "./api";

function* getVehiclesData(action: RequestVehicles) {
    try {
        const response = yield* call(getVehicles, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveVehicles(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getAllVehicleData(action: RequestAllVehicles) {
    try {
        const response = yield* call(getVehicles, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveAllVehicles(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getVehicleLastPositionsData(action: RequestVehiclesLastPosition) {
    try {
        const response = yield* call(getVehicleLastPositions);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveVehiclesLastPosition(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getVehicleDataById(action: RequestVehicleById) {
    try {
        const response = yield* call(getVehicleById, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveVehicleById(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* addVehicleData(action: RequestAddVehicle) {
    try {
        const response = yield* call(addVehicle, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveAddVehicle(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateVehicleData(action: RequestUpdateVehicle) {
    try {
        const response = yield* call(updateVehicle, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUpdateVehicle(response.data));
            yield* put(requestVehicleById(action.data.id));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateVehiclesData(action: RequestUpdateVehicles) {
    try {
        const response = yield* call(updateVehicles, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveUpdateVehicles(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* deleteVehicleData(action: RequestDeleteVehicle) {
    try {
        const response = yield* call(deleteVehicle, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveDeleteVehicle(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateVehicleArchiveToggleData(action: RequestVehicleToggleArchive) {
    try {
        const response = yield* call(updateVehicleArchiveToggle, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveVehicleToggleArchive(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* updateVehiclesArchiveToggleData(action: RequestVehiclesToggleArchive) {
    try {
        const response = yield* call(updateVehiclesArchiveToggle, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveVehiclesToggleArchive(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}


function* addVehicleDriverData(action: RequestAddVehicleDriver) {
    try {
        const response = yield* call(addVehicleDriver, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveAddVehicleDriver(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* deleteVehicleDriverData(action: RequestDeleteVehicleDriver) {
    try {
        const response = yield* call(deleteVehicleDriver, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveDeleteVehicleDriver());
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getOpenTrackData(action: RequestOpenTrack) {
    try {
        const response = yield* call(getOpenTrack, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveOpenTrack(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* getCalibrationsData(action: RequestCalibrations) {
    try {
        const response = yield* call(getCalibrations, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveCalibrations(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

function* addCalibrationData(action: RequestAddCalibration) {
    try {
        const response = yield* call(addCalibration, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveAddCalibration(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

// function* deleteCalibrationData(action) {
//     try {
//         const response = yield* call(deleteCalibration, action.data);
//         if (response.status >= 200 && response.status < 300) {
//             yield* put(recieveDeleteCalibration(response.data));
//         } else {
//             throw response;
//         }
//     } catch (error: any) {
//         error.response?.data?.title && (yield* put(handleError(error)));
//     }
// }

function* getTracksData(action: RequestTracksOfVehicle) {
    try {
        const response = yield* call(getTracksByVehicle, action.data);
        if (response.status >= 200 && response.status < 300) {
            yield* put(receiveTracksOfVehicle(response.data));
        } else {
            throw response;
        }
    } catch (error: any) {
        error.response?.data?.title && (yield* put(handleError(error)));
    }
}

export default function* sagaVehicle() { 
    yield* takeLatest(VEHICLE_ACTION_TYPES.REQUEST_VEHICLES, getVehiclesData);
    yield* takeLatest(VEHICLE_ACTION_TYPES.REQUEST_ALL_VEHICLES, getAllVehicleData);
    yield* takeLatest(VEHICLE_ACTION_TYPES.REQUEST_VEHICLES_LAST_POSITON, getVehicleLastPositionsData);
    yield* takeLatest(VEHICLE_ACTION_TYPES.REQUEST_VEHICLE_BY_ID, getVehicleDataById);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_ADD_VEHICLE, addVehicleData);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_UPDATE_VEHICLE, updateVehicleData);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_UPDATE_VEHICLES, updateVehiclesData);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_DELETE_VEHICLE, deleteVehicleData);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_VEHICLE_TOGGLE_ARCHIVE, updateVehicleArchiveToggleData);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_VEHICLES_TOGGLE_ARCHIVE, updateVehiclesArchiveToggleData);

    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_ADD_VEHICLE_DRIVER, addVehicleDriverData);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_DELETE_VEHICLE_DRIVER, deleteVehicleDriverData);

    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_OPEN_TRACK, getOpenTrackData);

    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_CALIBRATIONS, getCalibrationsData);
    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_ADD_CALIBRATION, addCalibrationData);
    // yield* takeEvery(VEHICLE_ACTION_TYPES.REQEUST_DELETE_CALIBRATION, deleteCalibrationData);

    yield* takeEvery(VEHICLE_ACTION_TYPES.REQUEST_TRACKS_OF_VEHICLE, getTracksData);
}
