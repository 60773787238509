const geoCoding = {
    Default: null,
    LocationIq: 0,
    Migration: 1,
    Here: 2,
    Google: 3
}

const geoCodingArray = [
    {id: geoCoding.Default, name: "Default"},   
    {id: geoCoding.LocationIq, name: "LocationIq"},
    {id: geoCoding.Migration, name: "Migration"},
    {id: geoCoding.Here, name: "Here"},   
    {id: geoCoding.Google, name: "Google"},   
]

const findGeoCodingById = (id) => {
    return geoCodingArray.find((g) => g.id == id);
}

export { geoCoding, geoCodingArray, findGeoCodingById}