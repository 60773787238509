import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {requestTracksOfVehicle} from "../../actions/actionVehicle";
import Map from "../common/map/Map";

const VehicleDayMap = ({trips, me}) => {

    const mappedTrips = trips.filter(t => t.visibility).map(t => {
        if (!me.canShowTracks) {
            t.positions = [
                {latitude: t.startLatitude, longitude: t.startLongitude},
                {latitude: t.destinationLatitude, longitude: t.destinationLongitude},
            ]
        }
        return t;
    });
    
    return <Map trips={mappedTrips} showTrackStartStopMarker isVehicleDay/>;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {requestTracksOfVehicle},
        dispatch,
    );
}

function mapStateToProp(state) {
    return {
        trips: state.trips.entities,
        me: state.users.me,
    };
}

// VehicleDayMap.propTypes = {};

export default connect(
    mapStateToProp,
    mapDispatchToProps,
)(VehicleDayMap);
