export const REQUEST_SCHEDULED_REPORTS = "REQUEST_SCHEDULED_REPORTS";
export const RECEIVE_SCHEDULED_REPORTS = "RECEIVE_SCHEDULED_REPORTS";

export const REQUEST_SCHEDULED_REPORT_BY_ID = "REQUEST_SCHEDULED_REPORT_BY_ID";
export const RECEIVE_SCHEDULED_REPORT_BY_ID = "RECEIVE_SCHEDULED_REPORT_BY_ID";

export const REQUEST_ADD_SCHEDULED_REPORT = "REQUEST_ADD_SCHEDULED_REPORT";
export const RECEIVE_ADD_SCHEDULED_REPORT = "RECEIVE_ADD_SCHEDULED_REPORT";

export const REQUEST_UPDATE_SCHEDULED_REPORT =
  "REQUEST_UPDATE_SCHEDULED_REPORT";
export const RECEIVE_UPDATE_SCHEDULED_REPORT =
  "RECEIVE_UPDATE_SCHEDULED_REPORT";

export const REQUEST_DELETE_SCHEDULED_REPORT =
  "REQUEST_DELETE_SCHEDULED_REPORT";
export const RECEIVE_DELETE_SCHEDULED_REPORT =
  "RECEIVE_DELETE_SCHEDULED_REPORT";

export const requestScheduledReports = (data) => ({
  type: REQUEST_SCHEDULED_REPORTS,
  data,
});

export const receiveScheduledReports = (data) => ({
  type: RECEIVE_SCHEDULED_REPORTS,
  data,
});

export const requestScheduledReportById = (data) => ({
  type: REQUEST_SCHEDULED_REPORT_BY_ID,
  data,
});

export const receiveScheduledReportById = (data) => ({
  type: RECEIVE_SCHEDULED_REPORT_BY_ID,
  data,
});

export const requestAddScheduledReport = (data) => ({
  type: REQUEST_ADD_SCHEDULED_REPORT,
  data,
});

export const receiveAddScheduledReport = (data) => ({
  type: RECEIVE_ADD_SCHEDULED_REPORT,
  data,
});

export const requestUpdateScheduledReport = (data) => ({
  type: REQUEST_UPDATE_SCHEDULED_REPORT,
  data,
});

export const receiveUpdateScheduledReport = (data) => ({
  type: RECEIVE_UPDATE_SCHEDULED_REPORT,
  data,
});

export const requestDeleteScheduledReport = (data) => ({
  type: REQUEST_DELETE_SCHEDULED_REPORT,
  data,
});

export const receiveDeleteScheduledReport = (data) => ({
  type: RECEIVE_DELETE_SCHEDULED_REPORT,
  data,
});
