import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  UserGetRequest,
  UserAddRequest,
  UserUpdateRequest,
  UsersUpdateRequest,
  UserChangePasswordRequest,
  ChangePasswordForUserRequest,
  UserResetPasswordRequest,
} from "../types/user/userRequest.types";
import {
  UserResponse,
  UserResponseInfo,
} from "../types/user/userResponse.types";
import { MandatorUpdateRequest } from "../types/mandator/mandatorRequest.types";

import {
  EntityToggleArchiveRequest,
  EntitiesToggleArchiveRequest,
  RolesResponse,
} from "../types/global.types";

import {
  RequestUsers,
  ReceiveUsers,
  RequestAllUsers,
  ReceiveAllUsers,
  RequestUserById,
  ReceiveUserById,
  RequestCurrentlyLoggedinUser,
  ReceiveCurrentlyLoggedinUser,
  RequestAddUser,
  ReceiveAddUser,
  RequestUpdateUser,
  ReceiveUpdateUser,
  RequestUpdateUserMandator,
  ReceiveUpdateUserMandator,
  RequestUpdateUsers,
  ReceiveUpdateUsers,
  RequestDeleteUser,
  ReceiveDeleteUser,
  RequestUserToggleArchive,
  ReceiveUserToggleArchive,
  RequestUsersToggleArchive,
  ReceiveUsersToggleArchive,
  RequestRoles,
  ReceiveRoles,
  RequestChangePassword,
  ReceiveChangePassword,
  RequestChangePasswordForUser,
  ReceiveChangePasswordForUser,
  RequestResetPassword,
  ReceiveResetPassword,
  RequestResetMomentPassword,
  ReceiveResetMomentPassword,
  RequestForgotPassword,
  ReceiveForgotPassword,
  SelectUser,
  SelectAllUser,
  DeselectAllUser,
  LoadUser,
} from "../types/actions/actionUser.types";

export enum USER_ACTION_TYPES {
  REQUEST_USERS = "REQUEST_USERS",
  RECEIVE_USERS = "RECEIVE_USERS",

  REQUEST_ALL_USERS = "REQUEST_ALL_USERS",
  RECEIVE_ALL_USERS = "RECEIVE_ALL_USERS",

  REQUEST_USER_BY_ID = "REQUEST_USER_BY_ID",
  RECEIVE_USER_BY_ID = "RECEIVE_USER_BY_ID",

  REQUEST_ADD_USER = "REQUEST_ADD_USER",
  RECEIVE_ADD_USER = "RECEIVE_ADD_USER",

  REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER",
  RECEIVE_UPDATE_USER = "RECEIVE_UPDATE_USER",

  REQUEST_UPDATE_USER_Mandator = "REQUEST_UPDATE_USER_Mandator",
  RECEIVE_UPDATE_USER_Mandator = "RECEIVE_UPDATE_USER_Mandator",

  REQUEST_UPDATE_USERS = "REQUEST_UPDATE_USERS",
  RECEIVE_UPDATE_USERS = "RECEIVE_UPDATE_USERS",

  REQUEST_DELETE_USER = "REQUEST_DELETE_USER",
  RECEIVE_DELETE_USER = "RECEIVE_DELETE_USER",

  REQUEST_USER_TOGGLE_ARCHIVE = "REQUEST_USER_TOGGLE_ARCHIVE",
  RECEIVE_USER_TOGGLE_ARCHIVE = "RECEIVE_USER_TOGGLE_ARCHIVE",

  REQUEST_USERS_TOGGLE_ARCHIVE = "REQUEST_USERS_TOGGLE_ARCHIVE",
  RECEIVE_USERS_TOGGLE_ARCHIVE = "RECEIVE_USERS_TOGGLE_ARCHIVE",

  REQUEST_ROLES = "REQUEST_ROLES",
  RECEIVE_ROLES = "RECEIVE_ROLES",

  REQUEST_CURRENTLY_LOGGEDIN_USER = "REQUEST_CURRENTLY_LOGGEDIN_USER",
  RECEIVE_CURRENTLY_LOGGEDIN_USER = "RECEIVE_CURRENTLY_LOGGEDIN_USER",

  REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD",
  RECEIVE_CHANGE_PASSWORD = "RECEIVE_CHANGE_PASSWORD",

  REQUEST_CHANGE_PASSWORD_FOR_USER = "REQUEST_CHANGE_PASSWORD_FOR_USER",
  RECEIVE_CHANGE_PASSWORD_FOR_USER = "RECEIVE_CHANGE_PASSWORD_FOR_USER",

  REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD",
  RECEIVE_RESET_PASSWORD = "RECEIVE_RESET_PASSWORD",

  REQUEST_RESET_MOMENTPASSWORD = "REQUEST_RESET_MOMENTPASSWORD",
  RECEIVE_RESET_MOMENTPASSWORD = "RECEIVE_RESET_MOMENTPASSWORD",

  REQUEST_FORGOT_PASSWORD = "REQUEST_FORGOT_PASSWORD",
  RECEIVE_FORGOT_PASSWORD = "RECEIVE_FORGOT_PASSWORD",

  SELECT_USER = "SELECT_USER",
  SELECT_ALL_USER = "SELECT_ALL_USER",
  DESELECT_ALL_USER = "DESELECT_ALL_USER",
  LOAD_USER = "LOAD_USER",
}

export const requestUsers = withMatcher(
  (data: UserGetRequest): RequestUsers => {
    return createAction(USER_ACTION_TYPES.REQUEST_USERS, data);
  }
);

export const receiveUsers = withMatcher((data: UserResponse): ReceiveUsers => {
  return createAction(USER_ACTION_TYPES.RECEIVE_USERS, data);
});

export const requestAllUsers = withMatcher(
  (data: UserGetRequest): RequestAllUsers => {
    return createAction(USER_ACTION_TYPES.REQUEST_ALL_USERS, data);
  }
);

export const receiveAllUsers = withMatcher(
  (data: UserResponse): ReceiveAllUsers => {
    return createAction(USER_ACTION_TYPES.RECEIVE_ALL_USERS, data);
  }
);

export const requestUserById = withMatcher((data: string): RequestUserById => {
  return createAction(USER_ACTION_TYPES.REQUEST_USER_BY_ID, data);
});

export const receiveUserById = withMatcher(
  (data: UserResponseInfo): ReceiveUserById => {
    return createAction(USER_ACTION_TYPES.RECEIVE_USER_BY_ID, data);
  }
);

export const requestCurrentlyLoggedinUser = withMatcher(
  (): RequestCurrentlyLoggedinUser => {
    return createAction(USER_ACTION_TYPES.REQUEST_CURRENTLY_LOGGEDIN_USER);
  }
);

export const receiveCurrentlyLoggedinUser = withMatcher(
  (data: UserResponseInfo): ReceiveCurrentlyLoggedinUser => {
    return createAction(
      USER_ACTION_TYPES.RECEIVE_CURRENTLY_LOGGEDIN_USER,
      data
    );
  }
);

export const requestAddUser = withMatcher(
  (data: UserAddRequest): RequestAddUser => {
    return createAction(USER_ACTION_TYPES.REQUEST_ADD_USER, data);
  }
);

export const receiveAddUser = withMatcher(
  (data: UserResponseInfo): ReceiveAddUser => {
    return createAction(USER_ACTION_TYPES.RECEIVE_ADD_USER, data);
  }
);

export const requestUpdateUser = withMatcher(
  (data: UserUpdateRequest): RequestUpdateUser => {
    return createAction(USER_ACTION_TYPES.REQUEST_UPDATE_USER, data);
  }
);

export const receiveUpdateUser = withMatcher(
  (data: UserResponseInfo): ReceiveUpdateUser => {
    return createAction(USER_ACTION_TYPES.RECEIVE_UPDATE_USER, data);
  }
);

export const requestUpdateUserMandator = withMatcher(
  (data: MandatorUpdateRequest): RequestUpdateUserMandator => {
    return createAction(USER_ACTION_TYPES.REQUEST_UPDATE_USER_Mandator, data);
  }
);

export const receiveUpdateUserMandator = withMatcher(
  (data: UserResponseInfo): ReceiveUpdateUserMandator => {
    return createAction(USER_ACTION_TYPES.RECEIVE_UPDATE_USER_Mandator, data);
  }
);

export const requestUpdateUsers = withMatcher(
  (data: UsersUpdateRequest): RequestUpdateUsers => {
    return createAction(USER_ACTION_TYPES.REQUEST_UPDATE_USERS, data);
  }
);

export const receiveUpdateUsers = withMatcher(
  (data: UserResponseInfo[]): ReceiveUpdateUsers => {
    return createAction(USER_ACTION_TYPES.RECEIVE_UPDATE_USERS, data);
  }
);

export const requestDeleteUser = withMatcher(
  (userId: string): RequestDeleteUser => {
    return createAction(USER_ACTION_TYPES.REQUEST_DELETE_USER, userId);
  }
);

export const receiveDeleteUser = withMatcher(
  (data: UserResponseInfo): ReceiveDeleteUser => {
    return createAction(USER_ACTION_TYPES.RECEIVE_DELETE_USER, data);
  }
);

export const requestUserToggleArchive = withMatcher(
  (data: EntityToggleArchiveRequest): RequestUserToggleArchive => {
    return createAction(USER_ACTION_TYPES.REQUEST_USER_TOGGLE_ARCHIVE, data);
  }
);

export const receiveUserToggleArchive = withMatcher(
  (data: UserResponseInfo): ReceiveUserToggleArchive => {
    return createAction(USER_ACTION_TYPES.RECEIVE_USER_TOGGLE_ARCHIVE, data);
  }
);

export const requestUsersToggleArchive = withMatcher(
  (data: EntitiesToggleArchiveRequest): RequestUsersToggleArchive => {
    return createAction(USER_ACTION_TYPES.REQUEST_USERS_TOGGLE_ARCHIVE, data);
  }
);

export const receiveUsersToggleArchive = withMatcher(
  (data: UserResponseInfo[]): ReceiveUsersToggleArchive => {
    return createAction(USER_ACTION_TYPES.RECEIVE_USERS_TOGGLE_ARCHIVE, data);
  }
);

export const requestRoles = withMatcher((): RequestRoles => {
  return createAction(USER_ACTION_TYPES.REQUEST_ROLES);
});

export const receiveRoles = withMatcher(
  (data: RolesResponse[]): ReceiveRoles => {
    return createAction(USER_ACTION_TYPES.RECEIVE_ROLES, data);
  }
);

export const requestChangePassword = withMatcher(
  (data: UserChangePasswordRequest): RequestChangePassword => {
    return createAction(USER_ACTION_TYPES.REQUEST_CHANGE_PASSWORD, data);
  }
);

export const receiveChangePassword = withMatcher(
  (data: UserResponseInfo): ReceiveChangePassword => {
    return createAction(USER_ACTION_TYPES.RECEIVE_CHANGE_PASSWORD, data);
  }
);

export const requestChangePasswordForUser = withMatcher(
  (data: ChangePasswordForUserRequest): RequestChangePasswordForUser => {
    return createAction(
      USER_ACTION_TYPES.REQUEST_CHANGE_PASSWORD_FOR_USER,
      data
    );
  }
);

export const receiveChangePasswordForUser = withMatcher(
  (data: UserResponseInfo): ReceiveChangePasswordForUser => {
    return createAction(
      USER_ACTION_TYPES.RECEIVE_CHANGE_PASSWORD_FOR_USER,
      data
    );
  }
);

export const requestResetPassword = withMatcher(
  (data: UserResetPasswordRequest): RequestResetPassword => {
    return createAction(USER_ACTION_TYPES.REQUEST_RESET_PASSWORD, data);
  }
);

export const receiveResetPassword = withMatcher((): ReceiveResetPassword => {
  return createAction(USER_ACTION_TYPES.RECEIVE_RESET_PASSWORD);
});

export const requestResetMomentPassword = withMatcher(
  (newPassword: string): RequestResetMomentPassword => {
    return createAction(
      USER_ACTION_TYPES.REQUEST_RESET_MOMENTPASSWORD,
      newPassword
    );
  }
);

export const receiveResetMomentPassword = withMatcher(
  (data: UserResponseInfo): ReceiveResetMomentPassword => {
    return createAction(USER_ACTION_TYPES.RECEIVE_RESET_MOMENTPASSWORD, data);
  }
);

export const requestForgotPassword = withMatcher(
  (email: string): RequestForgotPassword => {
    return createAction(USER_ACTION_TYPES.REQUEST_FORGOT_PASSWORD, email);
  }
);

export const receiveForgotPassword = withMatcher((): ReceiveForgotPassword => {
  return createAction(USER_ACTION_TYPES.RECEIVE_FORGOT_PASSWORD);
});

export const selectUser = withMatcher((data: UserResponseInfo): SelectUser => {
  return createAction(USER_ACTION_TYPES.SELECT_USER, data);
});

export const selectAllUser = withMatcher((): SelectAllUser => {
  return createAction(USER_ACTION_TYPES.SELECT_ALL_USER);
});

export const deselectAllUser = withMatcher((): DeselectAllUser => {
  return createAction(USER_ACTION_TYPES.DESELECT_ALL_USER);
});

export const loadUser = withMatcher((): LoadUser => {
  return createAction(USER_ACTION_TYPES.LOAD_USER);
});
