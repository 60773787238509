import React, {Component} from "react";
import PropTypes from "prop-types";
import {Select} from "antd";
import CustomSelect from "../../common/selects/Select";

const {Option} = Select;
const options = [{id: 0, name: "PKW"}, {id: 1, name: "LKW < 3.5t"}, {id: 2, name: "LKW > 3.5t"}];

class VehicleKindSelect extends Component {
    render() {
        const {onChange, value, disabled} = this.props;
        return (
            <CustomSelect allowClear={false} value={value} placeholder="Fahrzeugart" handleChange={onChange}
                          disabled={disabled}>
                {options.map(tt => (
                    <Option key={tt.id} value={tt.id}>
                        {tt.name}
                    </Option>
                ))}
            </CustomSelect>
        );
    }
}

// VehicleKindSelect.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
// };

export default VehicleKindSelect;
