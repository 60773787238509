import { withMatcher, createAction } from "../utils/reducer/reducer.utils";

import {
  RequestCostcenters,
  ReceiveCostcenters,
  RequestAllCostcenters,
  ReceiveAllCostcenters,
  RequestCostcenterById,
  ReceiveCostcenterById,
  RequestAddCostcenter,
  ReceiveAddCostcenter,
  RequestUpdateCostcenter,
  ReceiveUpdateCostcenter,
  RequestUpdateCostcenterMandator,
  ReceiveUpdateCostcenterMandator,
  RequestUpdateCostcenters,
  ReceiveUpdateCostcenters,
  RequestDeleteCostcenter,
  ReceiveDeleteCostcenter,
  RequestCostcenterToggleArchive,
  ReceiveCostcenterToggleArchive,
  RequestCostcentersToggleArchive,
  ReceiveCostcentersToggleArchive,
  RequestRoles,
  ReceiveRoles,
  SelectCostcenter,
  SelectAllCostcenter,
  DeselectAllCostcenter,
  LoadCostcenter
} from "../types/actions/actionCostcenter.types";

import {
  CostcenterGetRequest,
  CostcenterAddRequest,
  CostcenterUpdateRequest,
  CostcentersUpdateRequest,
} from "../types/costcenter/costcenterRequest.types";
import {
  CostcenterResponse,
  CostcenterResponseInfo,
} from "../types/costcenter/costcenterResponse.types";

import { EntityToggleArchiveRequest, EntitiesToggleArchiveRequest, RolesResponse } from "../types/global.types";

export enum COSTCENTER_ACTION_TYPES {
  REQUEST_COSTCENTERS = "REQUEST_COSTCENTERS",
  RECEIVE_COSTCENTERS = "RECEIVE_COSTCENTERS",

  REQUEST_ALL_COSTCENTERS = "REQUEST_ALL_COSTCENTERS",
  RECEIVE_ALL_COSTCENTERS = "RECEIVE_ALL_COSTCENTERS",

  REQUEST_COSTCENTER_BY_ID = "REQUEST_COSTCENTER_BY_ID",
  RECEIVE_COSTCENTER_BY_ID = "RECEIVE_COSTCENTER_BY_ID",

  REQUEST_ADD_COSTCENTER = "REQUEST_ADD_COSTCENTER",
  RECEIVE_ADD_COSTCENTER = "RECEIVE_ADD_COSTCENTER",

  REQUEST_UPDATE_COSTCENTER = "REQUEST_UPDATE_COSTCENTER",
  RECEIVE_UPDATE_COSTCENTER = "RECEIVE_UPDATE_COSTCENTER",

  REQUEST_UPDATE_COSTCENTER_Mandator = "REQUEST_UPDATE_COSTCENTER_Mandator",
  RECEIVE_UPDATE_COSTCENTER_Mandator = "RECEIVE_UPDATE_COSTCENTER_Mandator",

  REQUEST_UPDATE_COSTCENTERS = "REQUEST_UPDATE_COSTCENTERS",
  RECEIVE_UPDATE_COSTCENTERS = "RECEIVE_UPDATE_COSTCENTERS",

  REQUEST_DELETE_COSTCENTER = "REQUEST_DELETE_COSTCENTER",
  RECEIVE_DELETE_COSTCENTER = "RECEIVE_DELETE_COSTCENTER",

  REQUEST_COSTCENTER_TOGGLE_ARCHIVE = "REQUEST_COSTCENTER_TOGGLE_ARCHIVE",
  RECEIVE_COSTCENTER_TOGGLE_ARCHIVE = "RECEIVE_COSTCENTER_TOGGLE_ARCHIVE",

  REQUEST_COSTCENTERS_TOGGLE_ARCHIVE = "REQUEST_COSTCENTERS_TOGGLE_ARCHIVE",
  RECEIVE_COSTCENTERS_TOGGLE_ARCHIVE = "RECEIVE_COSTCENTERS_TOGGLE_ARCHIVE",

  REQUEST_ROLES = "REQUEST_ROLES",
  RECEIVE_ROLES = "RECEIVE_ROLES",

  SELECT_COSTCENTER = "SELECT_COSTCENTER",
  SELECT_ALL_COSTCENTER = "SELECT_ALL_COSTCENTER",
  DESELECT_ALL_COSTCENTER = "DESELECT_ALL_COSTCENTER",
  LOAD_COSTCENTER = "LOAD_COSTCENTER",
}

export const requestCostcenters = withMatcher(
  (data: CostcenterGetRequest): RequestCostcenters => {
    return createAction(COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTERS, data);
  }
);

export const receiveCostcenters = withMatcher(
  (data: CostcenterResponse): ReceiveCostcenters => {
    return createAction(COSTCENTER_ACTION_TYPES.RECEIVE_COSTCENTERS, data);
  }
);

export const requestAllCostcenters = withMatcher((): RequestAllCostcenters => {
  return createAction(COSTCENTER_ACTION_TYPES.REQUEST_ALL_COSTCENTERS);
});

export const receiveAllCostcenters = withMatcher(
  (data: CostcenterResponse): ReceiveAllCostcenters => {
    return createAction(COSTCENTER_ACTION_TYPES.RECEIVE_ALL_COSTCENTERS, data);
  }
);

export const requestCostcenterById = withMatcher(
  (costcenterId: string): RequestCostcenterById => {
    return createAction(
      COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTER_BY_ID,
      costcenterId
    );
  }
);

export const receiveCostcenterById = withMatcher(
  (data: CostcenterResponseInfo): ReceiveCostcenterById => {
    return createAction(COSTCENTER_ACTION_TYPES.RECEIVE_COSTCENTER_BY_ID, data);
  }
);

export const requestAddCostcenter = withMatcher(
  (data: CostcenterAddRequest): RequestAddCostcenter => {
    return createAction(COSTCENTER_ACTION_TYPES.REQUEST_ADD_COSTCENTER, data);
  }
);

export const receiveAddCostcenter = withMatcher(
  (data: CostcenterResponseInfo): ReceiveAddCostcenter => {
    return createAction(COSTCENTER_ACTION_TYPES.RECEIVE_ADD_COSTCENTER, data);
  }
);

export const requestUpdateCostcenter = withMatcher(
  (data: CostcenterUpdateRequest): RequestUpdateCostcenter => {
    return createAction(
      COSTCENTER_ACTION_TYPES.REQUEST_UPDATE_COSTCENTER,
      data
    );
  }
);

export const receiveUpdateCostcenter = withMatcher(
  (data: CostcenterResponseInfo): ReceiveUpdateCostcenter => {
    return createAction(
      COSTCENTER_ACTION_TYPES.RECEIVE_UPDATE_COSTCENTER,
      data
    );
  }
);

export const requestUpdateCostcenterMandator = withMatcher(
  (data: CostcenterUpdateRequest): RequestUpdateCostcenterMandator => {
    return createAction(
      COSTCENTER_ACTION_TYPES.REQUEST_UPDATE_COSTCENTER_Mandator,
      data
    );
  }
);

export const receiveUpdateCostcenterMandator = withMatcher(
  (data: CostcenterResponseInfo): ReceiveUpdateCostcenterMandator => {
    return createAction(
      COSTCENTER_ACTION_TYPES.RECEIVE_UPDATE_COSTCENTER_Mandator,
      data
    );
  }
);

export const requestUpdateCostcenters = withMatcher(
  (data: CostcentersUpdateRequest): RequestUpdateCostcenters => {
    return createAction(
      COSTCENTER_ACTION_TYPES.REQUEST_UPDATE_COSTCENTERS,
      data
    );
  }
);

export const receiveUpdateCostcenters = withMatcher(
  (data: CostcenterResponseInfo[]): ReceiveUpdateCostcenters => {
    return createAction(
      COSTCENTER_ACTION_TYPES.RECEIVE_UPDATE_COSTCENTERS,
      data
    );
  }
);

export const requestDeleteCostcenter = withMatcher(
  (costcenterId: string): RequestDeleteCostcenter => {
    return createAction(
      COSTCENTER_ACTION_TYPES.REQUEST_DELETE_COSTCENTER,
      costcenterId
    );
  }
);

export const receiveDeleteCostcenter = withMatcher(
  (data: CostcenterResponseInfo): ReceiveDeleteCostcenter => {
    return createAction(
      COSTCENTER_ACTION_TYPES.RECEIVE_DELETE_COSTCENTER,
      data
    );
  }
);

export const requestCostcenterToggleArchive = withMatcher(
  (data: EntityToggleArchiveRequest): RequestCostcenterToggleArchive => {
    return createAction(
      COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTER_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const receiveCostcenterToggleArchive = withMatcher(
  (data: CostcenterResponseInfo): ReceiveCostcenterToggleArchive => {
    return createAction(
      COSTCENTER_ACTION_TYPES.RECEIVE_COSTCENTER_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const requestCostcentersToggleArchive = withMatcher(
  (data: EntitiesToggleArchiveRequest): RequestCostcentersToggleArchive => {
    return createAction(
      COSTCENTER_ACTION_TYPES.REQUEST_COSTCENTERS_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const receiveCostcentersToggleArchive = withMatcher(
  (data: CostcenterResponseInfo[]): ReceiveCostcentersToggleArchive => {
    return createAction(
      COSTCENTER_ACTION_TYPES.RECEIVE_COSTCENTERS_TOGGLE_ARCHIVE,
      data
    );
  }
);

export const requestRoles = withMatcher((): RequestRoles => {
  return createAction(COSTCENTER_ACTION_TYPES.REQUEST_ROLES);
});

export const receiveRoles = withMatcher(
  (data: RolesResponse[]): ReceiveRoles => {
    return createAction(COSTCENTER_ACTION_TYPES.RECEIVE_ROLES, data);
  }
);

export const selectCostcenter = withMatcher(
  (data: CostcenterResponseInfo): SelectCostcenter => {
    return createAction(COSTCENTER_ACTION_TYPES.SELECT_COSTCENTER, data);
  }
);

export const selectAllCostcenter = withMatcher((): SelectAllCostcenter => {
  return createAction(COSTCENTER_ACTION_TYPES.SELECT_ALL_COSTCENTER);
});

export const deselectAllCostcenter = withMatcher((): DeselectAllCostcenter => {
  return createAction(COSTCENTER_ACTION_TYPES.DESELECT_ALL_COSTCENTER);
});

export const loadCostcenter = withMatcher((): LoadCostcenter => {
  return createAction(COSTCENTER_ACTION_TYPES.LOAD_COSTCENTER);
});
